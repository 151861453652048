import React, { FC, memo } from 'react';

const ApiDllPage: FC = () => (
  <>
    <h1>API Application Gateway ApiDll ver. 7.79</h1>
    <h2>Software Versions:</h2>
    <div>00&nbsp;&nbsp;SOFTWARE&nbsp;VERSIONS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00&nbsp;&nbsp;Display&nbsp;Welcome&nbsp;Message</strong>
      </div>
      <div>00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/A&nbsp;&nbsp;Display&nbsp;all&nbsp;software&nbsp;versions</strong>
      </div>
      <div>00/A</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/C&nbsp;&nbsp;Display&nbsp;compiler&nbsp;options</strong>
      </div>
      <div>00/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/OM&nbsp;&nbsp;Display&nbsp;OM&nbsp;TCP&nbsp;connections</strong>
      </div>
      <div>00/OM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00+&nbsp;&nbsp;Open&nbsp;GUI&nbsp;Console&nbsp;Window</strong>
      </div>
      <div>00+</div>
      <div />
      <div />
    </div>
    <h2>Gateway Connection:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01&nbsp;&nbsp;Display&nbsp;Gateways&nbsp;Connection&nbsp;Status</strong>
      </div>
      <div>01</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/L&nbsp;&nbsp;Display&nbsp;Gateway&nbsp;Status&nbsp;List</strong>
      </div>
      <div>01/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/+&nbsp;&nbsp;Gateway&nbsp;reconnect&nbsp;request</strong>
      </div>
      <div>Request&nbsp;Gateway&nbsp;reconnect</div>
      <div>For&nbsp;Auto,&nbsp;next&nbsp;in&nbsp;list&nbsp;will&nbsp;be&nbsp;tried</div>
      <div>01/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/C&nbsp;&nbsp;Clear&nbsp;Gateway&nbsp;errors</strong>
      </div>
      <div>01/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/A&nbsp;&nbsp;Auto&nbsp;Gateway&nbsp;select</strong>
      </div>
      <div>01/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/n&nbsp;&nbsp;Forced&nbsp;Gateway&nbsp;select</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Gateway&nbsp;Number&nbsp;(0..9)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>01/_</div>
      <div />
      <div />
    </div>
    <h2>License Dongle:</h2>
    <div>04&nbsp;&nbsp;LICENSE&nbsp;DONGLE</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>04&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Settings</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;settings&nbsp;read&nbsp;from&nbsp;dongle&nbsp;at&nbsp;application&nbsp;startup</div>
      <div>04</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>04/I&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Info</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;info&nbsp;read&nbsp;from&nbsp;dongle&nbsp;at&nbsp;application&nbsp;startup</div>
      <div>04/I</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>04/D&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Memory&nbsp;in&nbsp;Decimal</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;info&nbsp;read&nbsp;from&nbsp;dongle&nbsp;at&nbsp;application&nbsp;startup</div>
      <div>04/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>04/H&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Memory&nbsp;in&nbsp;Hex</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;info&nbsp;read&nbsp;from&nbsp;dongle&nbsp;at&nbsp;application&nbsp;startup</div>
      <div>04/H</div>
      <div />
      <div />
    </div>
    <h2>Node Status:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10&nbsp;&nbsp;Display&nbsp;Nodes</strong>
      </div>
      <div>10</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/c&nbsp;&nbsp;Display&nbsp;Node&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;S:&nbsp;Subscriber&nbsp;Register</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;C:&nbsp;Coordinates&nbsp;(Positions)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;R:&nbsp;Radio&nbsp;Cell</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;V:&nbsp;Voice&nbsp;Gateway</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;P:&nbsp;Packet&nbsp;Date&nbsp;Gateway</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;R:&nbsp;Application&nbsp;Gateway</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/_</div>
      <div />
      <div />
    </div>
    <h2>Subscriber Register:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register</strong>
      </div>
      <div>20</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/RELOAD&nbsp;&nbsp;Subscriber&nbsp;Register&nbsp;reload&nbsp;from&nbsp;Gateway</strong>
      </div>
      <div>20/RELOAD</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/CHECK&nbsp;&nbsp;Subscriber&nbsp;Register&nbsp;consistency&nbsp;check</strong>
      </div>
      <div>20/CHECK</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/QUE&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register&nbsp;Queues</strong>
      </div>
      <div>20/QUE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/TSI/.../L&nbsp;&nbsp;Display&nbsp;TSI&nbsp;Chain&nbsp;List</strong>
      </div>
      <div>NB:&nbsp;For&nbsp;debug&nbsp;only!</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(Mobile&nbsp;Country&nbsp;Code)&nbsp;(0001..1023)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(Mobile&nbsp;Network&nbsp;Code)&nbsp;(0001..16383)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(Short&nbsp;Subscriber&nbsp;Identity&nbsp;(0001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>20/TSI/____:_____:________/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/TSICHAIN/nnnnnn&nbsp;&nbsp;Display&nbsp;TSI&nbsp;Chain&nbsp;List</strong>
      </div>
      <div>NB:&nbsp;For&nbsp;debug&nbsp;only!</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SubIx&nbsp;(000000..nnnnnn)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>20/TSICHAIN/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/TSIIXTB&nbsp;&nbsp;Display&nbsp;TSI&nbsp;Index&nbsp;Table</strong>
      </div>
      <div>NB:&nbsp;For&nbsp;debug&nbsp;only!</div>
      <div>20/TSIIXTB</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Profile Register:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21&nbsp;&nbsp;Display&nbsp;Profile&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn&nbsp;&nbsp;Display&nbsp;Profile</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;Profile&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..50)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>21/____/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/ADD/nnn/cc..c&nbsp;&nbsp;Add/Change&nbsp;Profile</strong>
      </div>
      <div>Command&nbsp;to&nbsp;Add&nbsp;or&nbsp;Change&nbsp;Profile</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Home&nbsp;Organization</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;nnn:&nbsp;000..999&nbsp;(0=All)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;---:&nbsp;Home&nbsp;Organization&nbsp;of&nbsp;Application
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Description
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴───────────────────────</div>
      <div>21/____/ADD/___/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/ASSORG&nbsp;&nbsp;Display&nbsp;Associated&nbsp;Organizations</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/ASSORG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/ASSORG/s&nbsp;&nbsp;Change&nbsp;use&nbsp;of&nbsp;Associated&nbsp;Organizations</strong>
      </div>
      <div>Activates/deactivates&nbsp;the&nbsp;use&nbsp;of&nbsp;Associated&nbsp;Organizations</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Associated&nbsp;Organizations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/ASSORG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/ASSORG/nnn/s&nbsp;&nbsp;Add/Remove&nbsp;Associated&nbsp;Organization</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Associated&nbsp;Organization&nbsp;Number&nbsp;(001..999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Action
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Add
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Remove
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴</div>
      <div>21/____/ASSORG/___/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SSICALL/s&nbsp;&nbsp;Setup&nbsp;SSI&nbsp;Call&nbsp;Permission</strong>
      </div>
      <div>Selects&nbsp;whether&nbsp;Individual&nbsp;Calls&nbsp;are&nbsp;allowed&nbsp;with&nbsp;SSI</div>
      <div>
        If&nbsp;not&nbsp;selected,&nbsp;Individual&nbsp;Calls&nbsp;are&nbsp;only&nbsp;allowed&nbsp;wit&nbsp;User&nbsp;Number
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Allow&nbsp;Individual&nbsp;Calls&nbsp;with&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Only&nbsp;allow&nbsp;Individual&nbsp;Calls&nbsp;with&nbsp;User&nbsp;Number
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/SSICALL/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SSIGROUP/s&nbsp;&nbsp;Use&nbsp;SSI&nbsp;as&nbsp;Talking&nbsp;Party&nbsp;Identification&nbsp;for&nbsp;Group&nbsp;Calls
        </strong>
      </div>
      <div>
        Selects&nbsp;the&nbsp;use&nbsp;of&nbsp;SSI&nbsp;as&nbsp;Talking&nbsp;Party&nbsp;Identification&nbsp;for&nbsp;Group&nbsp;Calls
      </div>
      <div>to&nbsp;bypass&nbsp;the&nbsp;bug&nbsp;in&nbsp;certain&nbsp;mobiles&nbsp;(Motorola)</div>
      <div>
        NB:&nbsp;This&nbsp;flag&nbsp;affects&nbsp;only&nbsp;GSSI's&nbsp;attached&nbsp;to&nbsp;the&nbsp;Profile&nbsp;(not&nbsp;ISSI)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Use&nbsp;SSI&nbsp;as&nbsp;TPI&nbsp;for&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Use&nbsp;User&nbsp;Number&nbsp;as&nbsp;TPI&nbsp;for&nbsp;Group&nbsp;Call&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/SSIGROUP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/AUTH/s&nbsp;&nbsp;Change&nbsp;Authentication&nbsp;capability&nbsp;required&nbsp;flag</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Authentication&nbsp;capability&nbsp;required&nbsp;at&nbsp;registration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Authentication&nbsp;capability&nbsp;not&nbsp;required&nbsp;at&nbsp;registration&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/AUTH/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/AIE/s&nbsp;&nbsp;Change&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;flag
        </strong>
      </div>
      <div>
        If&nbsp;the&nbsp;flag&nbsp;is&nbsp;set&nbsp;a&nbsp;check&nbsp;for&nbsp;match&nbsp;between&nbsp;the&nbsp;Profiles&nbsp;required&nbsp;TEAx
      </div>
      <div>and&nbsp;the&nbsp;mobiles&nbsp;supported&nbsp;TEAx&nbsp;is&nbsp;done.&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;at&nbsp;registration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;capability&nbsp;not&nbsp;required&nbsp;at&nbsp;registration&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/AIE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/E2EE/s&nbsp;&nbsp;Change&nbsp;End-To-End&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;flag
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;End-To-End&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;at&nbsp;registration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;End-To-End&nbsp;Encryption&nbsp;capability&nbsp;not&nbsp;required&nbsp;at&nbsp;registration&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/E2EE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SECMODE/n&nbsp;&nbsp;Setup&nbsp;Security&nbsp;Mode</strong>
      </div>
      <div>
        For&nbsp;optional&nbsp;modes,&nbsp;service&nbsp;will&nbsp;be&nbsp;allowed&nbsp;without&nbsp;Authentication/Air
      </div>
      <div>
        InterFace&nbsp;encryption,&nbsp;when&nbsp;no&nbsp;Security&nbsp;Set&nbsp;is&nbsp;available&nbsp;or&nbsp;when&nbsp;the&nbsp;SSI
      </div>
      <div>
        Authentication&nbsp;and&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;disable&nbsp;flags&nbsp;are&nbsp;set.
      </div>
      <div>
        For&nbsp;mandatory&nbsp;modes,&nbsp;service&nbsp;will&nbsp;only&nbsp;be&nbsp;permitted&nbsp;with&nbsp;full&nbsp;Authentication
      </div>
      <div>and&nbsp;Air&nbsp;Interface&nbsp;Encryption.</div>
      <div>
        Note,&nbsp;that&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;requires,&nbsp;that&nbsp;permission&nbsp;is&nbsp;activated
      </div>
      <div>in&nbsp;the&nbsp;License&nbsp;Dongle.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Mode:</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;Class&nbsp;1,&nbsp;no&nbsp;Auth./AIE
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Class&nbsp;1,&nbsp;Auth.&nbsp;optional,&nbsp;AIE&nbsp;none
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;Class&nbsp;1,&nbsp;Auth.&nbsp;mandatory,&nbsp;AIE&nbsp;none
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;Class&nbsp;3,&nbsp;Aut.&nbsp;optional,&nbsp;AIE&nbsp;optional
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;Class&nbsp;3,&nbsp;Auth.&nbsp;mandatory,&nbsp;AIE&nbsp;mandatory
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/SECMODE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SECKSG/nn&nbsp;&nbsp;Setup&nbsp;Key&nbsp;Stream&nbsp;Generator</strong>
      </div>
      <div>Used&nbsp;with&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;only</div>
      <div>Note,&nbsp;that&nbsp;the&nbsp;TR&nbsp;shall&nbsp;support&nbsp;the&nbsp;selected&nbsp;KSG.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;KSG:</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;TEA1
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;TEA2
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;TEA3
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;TEA4
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04..15:&nbsp;Reserved
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>21/____/SECKSG/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SHORTNO/n/nn..n&nbsp;&nbsp;Setup&nbsp;Short&nbsp;Number&nbsp;Format</strong>
      </div>
      <div>Specifies&nbsp;Short&nbsp;Number&nbsp;format</div>
      <div>
        When&nbsp;a&nbsp;number&nbsp;with&nbsp;a&nbsp;length&nbsp;of&nbsp;'Short&nbsp;Number&nbsp;Length'&nbsp;is&nbsp;dialled,&nbsp;the
      </div>
      <div>
        'Short&nbsp;Number&nbsp;Prefix'&nbsp;is&nbsp;automatically&nbsp;added&nbsp;before&nbsp;the&nbsp;lookup&nbsp;in&nbsp;the
      </div>
      <div>Subscriber&nbsp;Register.</div>
      <div>Leading&nbsp;zeroes&nbsp;in&nbsp;the&nbsp;Prefix&nbsp;are&nbsp;not&nbsp;significant.</div>
      <div>
        The&nbsp;sum&nbsp;of&nbsp;'Short&nbsp;Number&nbsp;Length'&nbsp;and&nbsp;the&nbsp;length&nbsp;of&nbsp;'Short&nbsp;Number&nbsp;Prefix'&nbsp;may
      </div>
      <div>not&nbsp;exceed&nbsp;7&nbsp;digits.</div>
      <div>The&nbsp;function&nbsp;has&nbsp;no&nbsp;effect&nbsp;for&nbsp;PSTN&nbsp;calls.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Short&nbsp;Number&nbsp;Length&nbsp;(0..6)&nbsp;(0=Disabled)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Short&nbsp;Number&nbsp;Prefix&nbsp;(""&nbsp;to&nbsp;"999999")
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴─────</div>
      <div>21/____/SHORTNO/_/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/DISPNO/nnnnnnn&nbsp;&nbsp;Setup&nbsp;Dispatcher&nbsp;User&nbsp;Number</strong>
      </div>
      <div>User&nbsp;Number&nbsp;of&nbsp;the&nbsp;Dispatcher&nbsp;used&nbsp;to&nbsp;authorize&nbsp;calls</div>
      <div>Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Dispatcher&nbsp;User&nbsp;Number&nbsp;(1..9999999,&nbsp;blank&nbsp;or&nbsp;0&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>21/____/DISPNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PRIORITY/nn&nbsp;&nbsp;Select&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Call&nbsp;Priority</div>
      <div>This&nbsp;will&nbsp;overrule&nbsp;Call&nbsp;Priority&nbsp;request&nbsp;1..14&nbsp;from&nbsp;mobiles</div>
      <div>
        Emergency&nbsp;Priority&nbsp;15&nbsp;and&nbsp;16&nbsp;from&nbsp;mobiles&nbsp;will&nbsp;always&nbsp;be&nbsp;passed&nbsp;unchanged
      </div>
      <div>Mobile&nbsp;controlled&nbsp;Priority&nbsp;is&nbsp;default&nbsp;(see&nbsp;this&nbsp;command)</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Priority
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..05:&nbsp;Low&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=0)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06..11:&nbsp;High&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=1)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12..14:&nbsp;Pre-emptive&nbsp;Priority&nbsp;(PTT&nbsp;Priority=2)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>21/____/PRIORITY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PRIORITY/-&nbsp;&nbsp;Select&nbsp;Mobile&nbsp;controlled&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Lets&nbsp;the&nbsp;mobile&nbsp;control&nbsp;the&nbsp;Call&nbsp;and&nbsp;PTT&nbsp;Priority</div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/PRIORITY/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/INDIVTIMEOUT/nn.n&nbsp;&nbsp;Set&nbsp;Individual&nbsp;PTT&nbsp;Call&nbsp;Inactivity&nbsp;Timeout
        </strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;time&nbsp;from&nbsp;last&nbsp;PTT&nbsp;released&nbsp;until&nbsp;Individual&nbsp;PTT&nbsp;Call&nbsp;termination.
      </div>
      <div>Default&nbsp;setting&nbsp;is&nbsp;Disabled.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Individuel&nbsp;PTT&nbsp;Call&nbsp;Inactivity&nbsp;timeout
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Disabled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00.1..25.0:&nbsp;Timeout&nbsp;value&nbsp;[minutes]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───
      </div>
      <div>21/____/INDIVTIMEOUT/__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/INDIVTIMEOUT/-&nbsp;&nbsp;Disable&nbsp;Individual&nbsp;PTT&nbsp;Call&nbsp;Inactivity&nbsp;Timeout
        </strong>
      </div>
      <div>Disables&nbsp;timeout&nbsp;of&nbsp;Individual&nbsp;PTT&nbsp;Call.</div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/INDIVTIMEOUT/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CALLDURMAX/nnn&nbsp;&nbsp;Set&nbsp;max.&nbsp;Call&nbsp;Duration</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;maximum&nbsp;call&nbsp;duration&nbsp;of&nbsp;Individual&nbsp;Calls.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;infinity&nbsp;(see&nbsp;this&nbsp;command)</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max&nbsp;Call&nbsp;Duration&nbsp;(001..250&nbsp;minutes)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>21/____/CALLDURMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CALLDURMAX/-&nbsp;&nbsp;Set&nbsp;Call&nbsp;Duration&nbsp;limit&nbsp;to&nbsp;infinity</strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;maximum&nbsp;call&nbsp;duration&nbsp;of&nbsp;Individual&nbsp;Calls&nbsp;to&nbsp;infinity.
      </div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/CALLDURMAX/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PTTDURMAX/nnn&nbsp;&nbsp;Set&nbsp;max.&nbsp;PTT&nbsp;Duration</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;maximun&nbsp;talking&nbsp;period&nbsp;at&nbsp;Simplex&nbsp;Calls</div>
      <div>
        The&nbsp;default&nbsp;value&nbsp;is&nbsp;disabled&nbsp;(Mobile&nbsp;controlled)&nbsp;(see&nbsp;this&nbsp;command)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max&nbsp;PTT&nbsp;Duration&nbsp;(002..250&nbsp;seconds)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>21/____/PTTDURMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PTTDURMAX/-&nbsp;&nbsp;Disable&nbsp;PTT&nbsp;Duration&nbsp;limit</strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;maximun&nbsp;talking&nbsp;period&nbsp;at&nbsp;Simplex&nbsp;Calls&nbsp;to&nbsp;be&nbsp;controlled
      </div>
      <div>of&nbsp;the&nbsp;Mobile.</div>
      <div>
        The&nbsp;system&nbsp;will&nbsp;still&nbsp;limit&nbsp;the&nbsp;time&nbsp;to&nbsp;maximum&nbsp;310&nbsp;seconds,
      </div>
      <div>
        which&nbsp;is&nbsp;longer&nbsp;than&nbsp;the&nbsp;maximum&nbsp;300&nbsp;second&nbsp;setting&nbsp;for&nbsp;the&nbsp;mobile.
      </div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/PTTDURMAX/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/LATEENTRY/nn&nbsp;&nbsp;Set&nbsp;Late&nbsp;Entry&nbsp;Timer</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Late&nbsp;Entry&nbsp;Timer.</div>
      <div>
        Controls&nbsp;the&nbsp;duraton&nbsp;between&nbsp;active&nbsp;Group&nbsp;announcements&nbsp;on&nbsp;Control&nbsp;Channel.
      </div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;15&nbsp;seconds.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Late&nbsp;Entry&nbsp;Timer&nbsp;(05..60&nbsp;seconds)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>21/____/LATEENTRY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/LATEENTRY/-&nbsp;&nbsp;Disable&nbsp;Late&nbsp;Entry&nbsp;Timer</strong>
      </div>
      <div>Disables&nbsp;Late&nbsp;Entry&nbsp;Timer.</div>
      <div>Stops&nbsp;the&nbsp;active&nbsp;Group&nbsp;announcements&nbsp;on&nbsp;Control&nbsp;Channel.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;15&nbsp;seconds.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/LATEENTRY/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SCCH/-&nbsp;&nbsp;Select&nbsp;Auto&nbsp;SCCH&nbsp;allocation</strong>
      </div>
      <div>Selects&nbsp;automatic&nbsp;allocation&nbsp;SCCH's&nbsp;on&nbsp;BS.</div>
      <div>The&nbsp;actually&nbsp;used&nbsp;Timeslot&nbsp;for&nbsp;Control&nbsp;Channel&nbsp;</div>
      <div>depends&nbsp;on&nbsp;the&nbsp;number&nbsp;of&nbsp;SCCH's&nbsp;setup&nbsp;on&nbsp;the</div>
      <div>BS&nbsp;Main&nbsp;Carrier.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/SCCH/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SCCH/nn&nbsp;&nbsp;Select&nbsp;Manual&nbsp;SCCH&nbsp;allocation</strong>
      </div>
      <div>Selects&nbsp;manually&nbsp;allocation&nbsp;of&nbsp;SCCH's&nbsp;on&nbsp;BS.</div>
      <div>The&nbsp;actually&nbsp;used&nbsp;Timeslot&nbsp;for&nbsp;Control&nbsp;Channel&nbsp;</div>
      <div>depends&nbsp;on&nbsp;the&nbsp;number&nbsp;of&nbsp;SCCH's&nbsp;setup&nbsp;on&nbsp;the</div>
      <div>BS&nbsp;Main&nbsp;Carrier.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MCCH&nbsp;only&nbsp;&nbsp;&nbsp;One&nbsp;SCCH&nbsp;&nbsp;&nbsp;&nbsp;Two&nbsp;SCCH&nbsp;&nbsp;&nbsp;&nbsp;Three&nbsp;SCCH
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;----------&nbsp;&nbsp;----------&nbsp;&nbsp;----------&nbsp;&nbsp;----------
      </div>
      <div>
        00:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;
      </div>
      <div>01:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS2(SCCH1)&nbsp;</div>
      <div>
        02:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS3(SCCH2)&nbsp;
      </div>
      <div>
        03:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS4(SCCH3)&nbsp;
      </div>
      <div>
        04:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS1(MCCH)&nbsp;
      </div>
      <div>05:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS2(SCCH1)&nbsp;</div>
      <div>
        06:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS3(SCCH2)&nbsp;
      </div>
      <div>07:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS4(SCCH3)&nbsp;</div>
      <div>
        08:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS1(MCCH)&nbsp;
      </div>
      <div>
        09:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;
      </div>
      <div>
        10:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS3(SCCH2)&nbsp;
      </div>
      <div>11:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS4(SCCH3)&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SCCH&nbsp;Allocation&nbsp;(00..11)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>21/____/SCCH/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PD/MS/nn/nnn&nbsp;&nbsp;Change&nbsp;PD&nbsp;Channel&nbsp;Mobile&nbsp;allocation</strong>
      </div>
      <div>
        Changes&nbsp;the&nbsp;way&nbsp;Mobiles&nbsp;are&nbsp;allocated&nbsp;to&nbsp;Packed&nbsp;Data&nbsp;Channels.
      </div>
      <div>
        Mobiles&nbsp;only&nbsp;share&nbsp;a&nbsp;PD&nbsp;Channel&nbsp;with&nbsp;Mobiles&nbsp;of&nbsp;same&nbsp;Class.
      </div>
      <div>
        Class&nbsp;00&nbsp;does&nbsp;not&nbsp;allow&nbsp;sharing,&nbsp;but&nbsp;allows&nbsp;use&nbsp;of&nbsp;multi-slot&nbsp;PD&nbsp;Channels.
      </div>
      <div>
        Class&nbsp;01..99&nbsp;allows&nbsp;sharing&nbsp;up&nbsp;to&nbsp;the&nbsp;max&nbsp;number&nbsp;of&nbsp;Mobiles&nbsp;on&nbsp;a&nbsp;single-slot&nbsp;PDCH.
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class:</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Default&nbsp;(No&nbsp;PD&nbsp;Channel&nbsp;sharing,&nbsp;multi-&nbsp;or&nbsp;single-slot)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..99:&nbsp;Class&nbsp;of&nbsp;sharing&nbsp;Mobiles&nbsp;(PD&nbsp;Channel&nbsp;sharing,&nbsp;single-slot&nbsp;only)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Max.&nbsp;no.&nbsp;of&nbsp;Mobiles&nbsp;sharing&nbsp;a&nbsp;PD&nbsp;Channel&nbsp;(001..100)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴──</div>
      <div>21/____/PD/MS/__/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PD/TIMER/nnn&nbsp;&nbsp;Change&nbsp;PD&nbsp;Channel&nbsp;Ready&nbsp;Timer</strong>
      </div>
      <div>Changes&nbsp;the&nbsp;Ready&nbsp;Timer&nbsp;for&nbsp;the&nbsp;Packed&nbsp;Data&nbsp;Channel</div>
      <div>Can&nbsp;increase&nbsp;the&nbsp;Ready&nbsp;Timer&nbsp;for&nbsp;e.g.&nbsp;SCADA&nbsp;systems</div>
      <div>Default:&nbsp;5&nbsp;sec.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Ready&nbsp;Timer&nbsp;(005..250&nbsp;sec.)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>21/____/PD/TIMER/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CLASS/M&nbsp;&nbsp;Setup&nbsp;Subscriber&nbsp;Class&nbsp;Mobile&nbsp;controlled</strong>
      </div>
      <div>See&nbsp;also&nbsp;Subscriber&nbsp;Class&nbsp;in&nbsp;Node&nbsp;configuration</div>
      <div>Mobile&nbsp;controlled&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/CLASS/M</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CLASS/ss...&nbsp;&nbsp;Setup&nbsp;Subscriber&nbsp;Class&nbsp;Network&nbsp;controlled</strong>
      </div>
      <div>Note,&nbsp;that&nbsp;all&nbsp;-&nbsp;means&nbsp;Mobile&nbsp;controlled</div>
      <div>See&nbsp;also&nbsp;Subscriber&nbsp;Class&nbsp;in&nbsp;Node&nbsp;configuration</div>
      <div>Mobile&nbsp;controlled&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class&nbsp;1&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;Class&nbsp;2&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;Class&nbsp;3&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;Class&nbsp;4&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;Class&nbsp;5&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;Class&nbsp;6&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;Class&nbsp;7&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;Class&nbsp;8&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;Class&nbsp;9&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;Class&nbsp;10&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;Class&nbsp;11&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;Class&nbsp;12&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;Class&nbsp;13&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;Class&nbsp;14&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;Class&nbsp;15&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;Class&nbsp;16&nbsp;(+,-)
      </div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1234567890122456</div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴</div>
      <div>21/____/CLASS/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SERVRESTR&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Service&nbsp;Restrictions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/SERVRESTR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SERVRESTR/s&nbsp;&nbsp;Change&nbsp;SSI&nbsp;Service&nbsp;Restriction&nbsp;activation</strong>
      </div>
      <div>Selects&nbsp;activation/deactivation&nbsp;of&nbsp;all&nbsp;SSI&nbsp;Service&nbsp;Restrictions</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Restrictions&nbsp;active&nbsp;according&nbsp;to&nbsp;individual&nbsp;flags
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;All&nbsp;Restrictions&nbsp;disabled&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/SERVRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/SERVPERM/c&nbsp;&nbsp;Display&nbsp;Service&nbsp;Permission&nbsp;flags</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Kind
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;M:&nbsp;Mobile&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;D:&nbsp;Dial-In&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Application&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Terminal&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/SERVPERM/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/M/nn/c&nbsp;&nbsp;Change&nbsp;Mobile&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Mobile&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;09:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;10:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;11:&nbsp;Packet&nbsp;Data&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Service&nbsp;allowed&nbsp;with&nbsp;Dispatcher&nbsp;Authorization&nbsp;(00..07&nbsp;and&nbsp;12..13&nbsp;only)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>21/____/SERVPERM/M/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/D/nn/s&nbsp;&nbsp;Change&nbsp;Dial-In&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Dial-In&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>21/____/SERVPERM/D/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/A/nn/s&nbsp;&nbsp;Change&nbsp;Application&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Application&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>21/____/SERVPERM/A/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/T/nn/s&nbsp;&nbsp;Change&nbsp;Terminal&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Terminal&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>21/____/SERVPERM/T/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/M/cc..c&nbsp;&nbsp;Change&nbsp;all&nbsp;Mobile&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A:&nbsp;Service&nbsp;allowed&nbsp;with&nbsp;Dispatcher&nbsp;Authorization&nbsp;(00..07&nbsp;and&nbsp;12..13&nbsp;only)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;Packet&nbsp;Data&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;Individial&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>21/____/SERVPERM/M/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/D/ss..s&nbsp;&nbsp;Change&nbsp;all&nbsp;Dial-In&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dial-In&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>21/____/SERVPERM/D/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/A/ss..s&nbsp;&nbsp;Change&nbsp;all&nbsp;Application&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Application&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>21/____/SERVPERM/A/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/SERVPERM/T/ss..s&nbsp;&nbsp;Change&nbsp;all&nbsp;Terminal&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terminal&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>21/____/SERVPERM/T/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/GROUPRESTR/s&nbsp;&nbsp;Change&nbsp;Group&nbsp;Call&nbsp;Restriction</strong>
      </div>
      <div>
        The&nbsp;flag&nbsp;restricts&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;to&nbsp;make&nbsp;Group&nbsp;Calls.
      </div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;cleared,&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;make&nbsp;call&nbsp;to&nbsp;all&nbsp;
      </div>
      <div>
        Home&nbsp;and&nbsp;Associated&nbsp;Groups&nbsp;according&nbsp;to&nbsp;each&nbsp;Groups&nbsp;specific&nbsp;restriction.&nbsp;
      </div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;set&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;make&nbsp;call&nbsp;to&nbsp;Groups
      </div>
      <div>listed&nbsp;in&nbsp;the&nbsp;Group&nbsp;Permission&nbsp;table.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Individual&nbsp;Subscribers&nbsp;Group&nbsp;Call&nbsp;Restrictions:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Call&nbsp;allowed&nbsp;to&nbsp;all&nbsp;Groups,&nbsp;only&nbsp;limited&nbsp;by
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the&nbsp;Groups&nbsp;specific&nbsp;restriction.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Call&nbsp;only&nbsp;allowed&nbsp;to&nbsp;Groups&nbsp;listed&nbsp;in&nbsp;the
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Group&nbsp;Permission&nbsp;table.
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/GROUPRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/GROUPPERM&nbsp;&nbsp;Display&nbsp;Group&nbsp;Permission&nbsp;table</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/GROUPPERM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/GROUPPERM/nnnnnnn/ADD/s&nbsp;&nbsp;Add/Change&nbsp;Group&nbsp;Permission&nbsp;table&nbsp;entry
        </strong>
      </div>
      <div>
        The&nbsp;Group&nbsp;Permission&nbsp;Table&nbsp;is&nbsp;used&nbsp;to&nbsp;give&nbsp;Individual&nbsp;Subscribers&nbsp;permission
      </div>
      <div>to&nbsp;make&nbsp;Groups&nbsp;call&nbsp;to&nbsp;specific&nbsp;Groups.</div>
      <div>
        By&nbsp;default&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;make&nbsp;Group&nbsp;Call&nbsp;to&nbsp;all&nbsp;Groups&nbsp;belonging
      </div>
      <div>
        to&nbsp;Home&nbsp;and&nbsp;Associated&nbsp;Organizations&nbsp;independent&nbsp;of&nbsp;the&nbsp;permission&nbsp;in&nbsp;this
      </div>
      <div>table.</div>
      <div>
        Group&nbsp;Calls&nbsp;for&nbsp;the&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;be&nbsp;restricted&nbsp;in
      </div>
      <div>two&nbsp;independet&nbsp;ways:</div>
      <div>1)&nbsp;Profile&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;Profile,&nbsp;all&nbsp;the&nbsp;Profiles
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;make&nbsp;Group&nbsp;Calls&nbsp;to&nbsp;the&nbsp;Groups
      </div>
      <div>&nbsp;&nbsp;&nbsp;included&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>2)&nbsp;Group&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;specific&nbsp;Group&nbsp;SSI,
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;call&nbsp;the&nbsp;Group&nbsp;if&nbsp;it&nbsp;is&nbsp;included
      </div>
      <div>&nbsp;&nbsp;&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>A&nbsp;maximum&nbsp;of&nbsp;16&nbsp;permitted&nbsp;groups&nbsp;can&nbsp;be&nbsp;setup.</div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PTT&nbsp;Permission
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;PTT&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;PTT&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;SDS&nbsp;Permission
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;SDS&nbsp;allowed&nbsp;to&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;SDS&nbsp;not&nbsp;allowed&nbsp;to&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴
      </div>
      <div>21/____/GROUPPERM/_______/ADD/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          21/nnnn/GROUPPERM/nnnnnnn/REM&nbsp;&nbsp;Remove&nbsp;Group&nbsp;Permission&nbsp;table&nbsp;entry
        </strong>
      </div>
      <div>
        The&nbsp;Group&nbsp;Permission&nbsp;Table&nbsp;is&nbsp;used&nbsp;to&nbsp;give&nbsp;Individual&nbsp;Subscribers&nbsp;permission
      </div>
      <div>to&nbsp;make&nbsp;Groups&nbsp;call&nbsp;to&nbsp;specific&nbsp;Groups.</div>
      <div>
        By&nbsp;default&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;make&nbsp;Group&nbsp;Call&nbsp;to&nbsp;all&nbsp;Groups&nbsp;belonging
      </div>
      <div>
        to&nbsp;Home&nbsp;and&nbsp;Associated&nbsp;Organizations&nbsp;independent&nbsp;of&nbsp;the&nbsp;permission&nbsp;in&nbsp;this
      </div>
      <div>table.</div>
      <div>
        Group&nbsp;Calls&nbsp;for&nbsp;the&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;be&nbsp;restricted&nbsp;in
      </div>
      <div>two&nbsp;independet&nbsp;ways:</div>
      <div>1)&nbsp;Profile&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;Profile,&nbsp;all&nbsp;the&nbsp;Profiles
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;make&nbsp;Group&nbsp;Calls&nbsp;to&nbsp;the&nbsp;Groups
      </div>
      <div>&nbsp;&nbsp;&nbsp;included&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>2)&nbsp;Group&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;specific&nbsp;Group&nbsp;SSI,
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;call&nbsp;the&nbsp;Group&nbsp;if&nbsp;it&nbsp;is&nbsp;included
      </div>
      <div>&nbsp;&nbsp;&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>A&nbsp;maximum&nbsp;of&nbsp;16&nbsp;permitted&nbsp;groups&nbsp;can&nbsp;be&nbsp;setup.</div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>21/____/GROUPPERM/_______/REM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CELL&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;Restrictions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/CELL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CELL/s&nbsp;&nbsp;Activate/Deactivate&nbsp;Radio&nbsp;Cell&nbsp;Restrictions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Radio&nbsp;Cell&nbsp;restrictions
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate&nbsp;(Only&nbsp;Permitted&nbsp;Nodes&nbsp;Allowed)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate&nbsp;(All&nbsp;Nodes&nbsp;allowed)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/____/CELL/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/CELL/nnn/ss..s&nbsp;&nbsp;Setup&nbsp;Radio&nbsp;Cell&nbsp;Permissions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;Node&nbsp;Number&nbsp;(000..255)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Permission&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Mobile&nbsp;allowed&nbsp;on&nbsp;Node
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Mobile&nbsp;not&nbsp;allowed&nbsp;on&nbsp;Node
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─────────────────────────────────────────────────
      </div>
      <div>21/____/CELL/___/__________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/VGNODE/n/nnn&nbsp;&nbsp;Setup&nbsp;Voice&nbsp;Gateway&nbsp;Node</strong>
      </div>
      <div>Voice&nbsp;Gateway&nbsp;Nodes&nbsp;used&nbsp;of&nbsp;the&nbsp;Profile&nbsp;members.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;Primary&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Alternate&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..999,&nbsp;000&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──</div>
      <div>21/____/VGNODE/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/PGNODE/n/nnn&nbsp;&nbsp;Setup&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;Node</strong>
      </div>
      <div>Packet&nbsp;Date&nbsp;Gateway&nbsp;Nodes&nbsp;used&nbsp;of&nbsp;the&nbsp;Profile&nbsp;members.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;Primary&nbsp;Packet&nbsp;Data&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Alternate&nbsp;Packet&nbsp;Data&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..999,&nbsp;000&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──</div>
      <div>21/____/PGNODE/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/POSUSERNO/n/nn..n&nbsp;&nbsp;Setup&nbsp;Position&nbsp;Destination&nbsp;User&nbsp;Number</strong>
      </div>
      <div>Terminal&nbsp;Gateway&nbsp;sends&nbsp;Position&nbsp;Report&nbsp;to&nbsp;these&nbsp;Numbers</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;Destination&nbsp;1
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Destination&nbsp;2
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(1..9999999,&nbsp;blank&nbsp;or&nbsp;0&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────</div>
      <div>21/____/POSUSERNO/_/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/DNS/...&nbsp;&nbsp;Setup&nbsp;DNS&nbsp;Server&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>
        DNS&nbsp;Server&nbsp;IP&nbsp;Address&nbsp;given&nbsp;to&nbsp;the&nbsp;Mobile&nbsp;at&nbsp;DHCP&nbsp;Request
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>21/____/DNS/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/STFWDUSERNO/...&nbsp;&nbsp;Setup&nbsp;Store/Forward&nbsp;Center&nbsp;User&nbsp;Number</strong>
      </div>
      <div>User&nbsp;Number&nbsp;for&nbsp;the&nbsp;SDS&nbsp;Store/Forward&nbsp;Center.</div>
      <div>
        If&nbsp;the&nbsp;MS&nbsp;requests&nbsp;a&nbsp;valid&nbsp;number,&nbsp;this&nbsp;will&nbsp;be&nbsp;used&nbsp;instead.
      </div>
      <div>
        All&nbsp;SDS&nbsp;will&nbsp;be&nbsp;forwarded&nbsp;directly&nbsp;to&nbsp;the&nbsp;end&nbsp;destination,&nbsp;if&nbsp;no
      </div>
      <div>valid&nbsp;S/F-Center&nbsp;address&nbsp;is&nbsp;available.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1..9999999:&nbsp;User&nbsp;Number&nbsp;of&nbsp;Store/Forward&nbsp;Center.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Used&nbsp;if&nbsp;a&nbsp;valid&nbsp;number&nbsp;is&nbsp;not&nbsp;received&nbsp;from&nbsp;the&nbsp;MS.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setting&nbsp;up&nbsp;the&nbsp;S/F-Center&nbsp;number&nbsp;here&nbsp;is&nbsp;recommended.&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Blank&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;MS&nbsp;shall&nbsp;provide&nbsp;the&nbsp;Store/Forward&nbsp;Center&nbsp;number.&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Store/Forward&nbsp;Center&nbsp;never&nbsp;used&nbsp;(SDS&nbsp;send&nbsp;directly).&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S21/____/STFWDUSERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/APPLSERVUSERNO/nn..n&nbsp;&nbsp;Setup&nbsp;Application&nbsp;Server&nbsp;User&nbsp;Number
        </strong>
      </div>
      <div>User&nbsp;Number&nbsp;for&nbsp;the&nbsp;Application&nbsp;Server</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(1..9999999,&nbsp;blank&nbsp;or&nbsp;0&nbsp;for&nbsp;none)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S21/____/APPLSERVUSERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/nnnn/DEL&nbsp;&nbsp;Delete&nbsp;Profile</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>21/____/DEL</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Subscriber Register SSI:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>22</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn&nbsp;&nbsp;Display&nbsp;SSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;SSI&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..36)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>22/________/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/USER/nnnnnnn&nbsp;&nbsp;Display&nbsp;User&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>22/USER/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/USER/nnnnnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;User&nbsp;Number&nbsp;list</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..36)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>22/USER/_______/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/IP/nnn.nnn.nnn.nnn&nbsp;&nbsp;Display&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>Only&nbsp;LSB's&nbsp;are&nbsp;significant</div>
      <div>
        Address&nbsp;will&nbsp;be&nbsp;masked&nbsp;with&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;Mask
      </div>
      <div>Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;value&nbsp;is&nbsp;added&nbsp;when&nbsp;displayed</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>22/IP/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/IP/nnn.nnn.nnn.nnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;IP&nbsp;Address&nbsp;list</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;IP&nbsp;Address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..36)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>22/IP/___.___.___.___/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/TSI/nnnn:nnnnn:nnnnnnnn&nbsp;&nbsp;Display&nbsp;TSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;of&nbsp;TSI&nbsp;(0001..1023)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;of&nbsp;TSI&nbsp;(0001..16383)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;of&nbsp;TSI&nbsp;(0001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>22/TSI/____:_____:________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/REG&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Registration</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/REG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/REG/GROUP&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Attached&nbsp;Groups</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/REG/GROUP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/ADD/c/...&nbsp;&nbsp;Add/Change&nbsp;SSI</strong>
      </div>
      <div>User&nbsp;Number:</div>
      <div>&nbsp;&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;With&nbsp;all-zeroes&nbsp;User&nbsp;Number&nbsp;is&nbsp;disabled</div>
      <div>IP&nbsp;Address:</div>
      <div>&nbsp;&nbsp;Only&nbsp;LSB's&nbsp;are&nbsp;significant</div>
      <div>
        &nbsp;&nbsp;Address&nbsp;will&nbsp;be&nbsp;masked&nbsp;with&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;Mask
      </div>
      <div>
        &nbsp;&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;value&nbsp;is&nbsp;added&nbsp;when&nbsp;displayed
      </div>
      <div>&nbsp;&nbsp;With&nbsp;all-zero&nbsp;significant&nbsp;bits&nbsp;IP&nbsp;Address&nbsp;is&nbsp;disabled</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;of&nbsp;Mobile&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;M:&nbsp;Mobile&nbsp;(Tetra)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;DMR
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴───&nbsp;┴──────&nbsp;┴──────────────</div>
      <div>22/________/ADD/_/____/_______/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/ADD/c/...&nbsp;&nbsp;Add/Change&nbsp;SSI</strong>
      </div>
      <div>User&nbsp;Number:</div>
      <div>&nbsp;&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;With&nbsp;all-zeroes&nbsp;User&nbsp;Number&nbsp;is&nbsp;disabled</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Emergency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;D:&nbsp;Dial-In
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Application
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Terminal
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unified
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴───&nbsp;┴──────</div>
      <div>22/________/ADD/_/____/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/DESCR/...&nbsp;&nbsp;Setup&nbsp;SSI&nbsp;Description</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Description
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────</div>
      <div>22/________/DESCR/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/HIDE/s&nbsp;&nbsp;Hide&nbsp;in&nbsp;Phone&nbsp;Book</strong>
      </div>
      <div>
        This&nbsp;command&nbsp;allows&nbsp;the&nbsp;SSI&nbsp;to&nbsp;be&nbsp;hided&nbsp;in&nbsp;the&nbsp;Phone&nbsp;Book.
      </div>
      <div>
        It&nbsp;can&nbsp;e.g.&nbsp;be&nbsp;used&nbsp;to&nbsp;hide&nbsp;SSI's&nbsp;with&nbsp;the&nbsp;Presentation&nbsp;Number&nbsp;setup
      </div>
      <div>
        to&nbsp;point&nbsp;to&nbsp;a&nbsp;Unified&nbsp;Number.&nbsp;In&nbsp;this&nbsp;way&nbsp;only&nbsp;the&nbsp;Unified&nbsp;Number
      </div>
      <div>is&nbsp;displayed&nbsp;in&nbsp;the&nbsp;Phone&nbsp;Book.</div>
      <div>
        It&nbsp;can&nbsp;also&nbsp;be&nbsp;used&nbsp;to&nbsp;hide&nbsp;e.g.&nbsp;a&nbsp;Group&nbsp;Bridge&nbsp;etc.
      </div>
      <div>
        NB:&nbsp;When&nbsp;"display&nbsp;all"&nbsp;is&nbsp;selected&nbsp;in&nbsp;e.g.&nbsp;the&nbsp;Dispatcher,&nbsp;all&nbsp;hidden
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;numbers&nbsp;are&nbsp;displayed.&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Always&nbsp;display&nbsp;in&nbsp;Phone&nbsp;Book
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Hide&nbsp;in&nbsp;Phone&nbsp;Book&nbsp;(if&nbsp;in&nbsp;hide-mode)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/HIDE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/PRESNO/nnnnnnn&nbsp;&nbsp;Setup&nbsp;Presentation&nbsp;Number</strong>
      </div>
      <div>This&nbsp;command&nbsp;changes&nbsp;the&nbsp;presented&nbsp;number&nbsp;for&nbsp;outgoing&nbsp;calls.</div>
      <div>
        It&nbsp;allows&nbsp;the&nbsp;Subscriber&nbsp;to&nbsp;present&nbsp;a&nbsp;different&nbsp;call-back&nbsp;number,
      </div>
      <div>e.g.&nbsp;a&nbsp;Unified&nbsp;Number.</div>
      <div>
        A&nbsp;call-back&nbsp;to&nbsp;the&nbsp;Unified&nbsp;Number&nbsp;will&nbsp;ensure&nbsp;all&nbsp;listed&nbsp;devices&nbsp;is
      </div>
      <div>alerted.</div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;DMR,&nbsp;Dial-In,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI.
      </div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Presentation&nbsp;Number
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0000001..9999999:&nbsp;Setup&nbsp;Presentation&nbsp;Number
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0000000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Clear&nbsp;Presentation&nbsp;Number&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>22/________/PRESNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TSI/...&nbsp;&nbsp;Setup&nbsp;TSI</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;and&nbsp;DMR&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(Mobile&nbsp;Country&nbsp;Code)&nbsp;(0001..1023)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(Mobile&nbsp;Network&nbsp;Code)&nbsp;(0001..16383)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(Short&nbsp;Subscriber&nbsp;Identity&nbsp;(0001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>22/________/TSI/____:_____:________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TSI/-&nbsp;&nbsp;Clear&nbsp;TSI</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;and&nbsp;DMR&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/TSI/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TRUST/c&nbsp;&nbsp;Change&nbsp;SSI&nbsp;Trust&nbsp;Kind</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;N:&nbsp;Normal&nbsp;(fully&nbsp;trusted)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;B:&nbsp;Blocked&nbsp;(no&nbsp;service)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;V:&nbsp;Visitor&nbsp;(verified&nbsp;of&nbsp;SSI's&nbsp;Home&nbsp;Network)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;U:&nbsp;Untrusted&nbsp;(auto-created)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/TRUST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/PINCODE/cc..c&nbsp;&nbsp;Change&nbsp;PIN&nbsp;Code</strong>
      </div>
      <div>PIN&nbsp;Code&nbsp;1..9&nbsp;digits,&nbsp;leading&nbsp;zeroes&nbsp;not&nbsp;significant</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;DialIn,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PIN&nbsp;Code:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1..999999999:&nbsp;Value
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────</div>
      <div>22/________/PINCODE/_________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TEI/...&nbsp;&nbsp;Setup&nbsp;TEI&nbsp;Terminal&nbsp;Equipment&nbsp;Identity</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TEI&nbsp;(15&nbsp;hex&nbsp;digits&nbsp;0..9&nbsp;or&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>22/________/TEI/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TEI/-&nbsp;&nbsp;Clear&nbsp;TEI&nbsp;Terminal&nbsp;Equipment&nbsp;Identity</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/TEI/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/SIM/...&nbsp;&nbsp;Setup&nbsp;SIM&nbsp;ID</strong>
      </div>
      <div>ID&nbsp;of&nbsp;SIM&nbsp;Card&nbsp;if&nbsp;present</div>
      <div>Shall&nbsp;be&nbsp;cleared&nbsp;if&nbsp;not&nbsp;used</div>
      <div>
        If&nbsp;SIM&nbsp;ID&nbsp;is&nbsp;setup,&nbsp;SIM&nbsp;ID&nbsp;is&nbsp;used&nbsp;to&nbsp;find&nbsp;K&nbsp;in&nbsp;Key&nbsp;Register,
      </div>
      <div>else&nbsp;TEI&nbsp;is&nbsp;used.</div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SIM&nbsp;ID&nbsp;(20&nbsp;hex&nbsp;digits&nbsp;0..9&nbsp;or&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────</div>
      <div>22/________/SIM/____________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/SIM/-&nbsp;&nbsp;Clear&nbsp;SIM&nbsp;ID</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/SIM/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/AUTHDIS/s&nbsp;&nbsp;Change&nbsp;Mobile&nbsp;Authentication&nbsp;Disable&nbsp;Flag</strong>
      </div>
      <div>Note,&nbsp;that&nbsp;Authentication&nbsp;disable&nbsp;is&nbsp;not&nbsp;possible&nbsp;in&nbsp;mandatory</div>
      <div>modes&nbsp;selected&nbsp;in&nbsp;the&nbsp;Profile&nbsp;register.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Authentication&nbsp;Disable&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Disable&nbsp;Authentication
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Authentication&nbsp;not&nbsp;disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/AUTHDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          22/nnnnnnnn/AIEDIS/s&nbsp;&nbsp;Change&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;Disable&nbsp;Flag
        </strong>
      </div>
      <div>
        Note,&nbsp;that&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;disable&nbsp;is&nbsp;not&nbsp;possible&nbsp;in&nbsp;mandatory
      </div>
      <div>mode&nbsp;selected&nbsp;in&nbsp;the&nbsp;Profile&nbsp;register.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Group&nbsp;and&nbsp;DMR&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;Disable&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Disable&nbsp;Air&nbsp;Interface&nbsp;Encryption
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;not&nbsp;disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/AIEDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/SECSET/R&nbsp;&nbsp;Renew&nbsp;Security&nbsp;Set&nbsp;from&nbsp;Security&nbsp;Key</strong>
      </div>
      <div>
        The&nbsp;Security&nbsp;Key&nbsp;will&nbsp;be&nbsp;search&nbsp;with&nbsp;the&nbsp;following&nbsp;references:
      </div>
      <div>
        1)&nbsp;If&nbsp;SIM&nbsp;is&nbsp;setup,&nbsp;SIM&nbsp;will&nbsp;be&nbsp;used&nbsp;for&nbsp;Key&nbsp;search
      </div>
      <div>
        2)&nbsp;Else&nbsp;if&nbsp;TEI&nbsp;is&nbsp;setup,&nbsp;TEI&nbsp;will&nbsp;be&nbsp;used&nbsp;for&nbsp;Key&nbsp;search
      </div>
      <div>3)&nbsp;Else&nbsp;no&nbsp;renewal&nbsp;is&nbsp;done</div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/SECSET/R</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/SECSET/...&nbsp;&nbsp;Setup&nbsp;Security&nbsp;Set</strong>
      </div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;manual&nbsp;test&nbsp;only</div>
      <div>
        Security&nbsp;set&nbsp;update&nbsp;should&nbsp;normally&nbsp;be&nbsp;done&nbsp;from&nbsp;Security&nbsp;Key
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Status
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unencrypted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Encrypted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;RS&nbsp;(20&nbsp;hex&nbsp;digits)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;KS&nbsp;(32&nbsp;hex&nbsp;digits)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;KS'&nbsp;(32&nbsp;hex&nbsp;digits)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│9&nbsp;8&nbsp;7&nbsp;6&nbsp;5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;│5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;9&nbsp;8&nbsp;7&nbsp;6&nbsp;5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;│5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;9&nbsp;8&nbsp;7&nbsp;6&nbsp;5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>
        22/________/SECSET/_/____________________/________________________________/________________________________
      </div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/SECSET/C&nbsp;&nbsp;Clear&nbsp;Security&nbsp;Set</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/SECSET/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPTIMEOUT/nnnnn&nbsp;&nbsp;Set&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;Timeout</strong>
      </div>
      <div>
        Changes&nbsp;the&nbsp;time&nbsp;from&nbsp;last&nbsp;PTT&nbsp;released&nbsp;until&nbsp;Group&nbsp;Call&nbsp;termination.
      </div>
      <div>Default&nbsp;value&nbsp;is&nbsp;5&nbsp;seconds.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;timeout&nbsp;(00000..65000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────
      </div>
      <div>22/________/GROUPTIMEOUT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPNOKEYED/s&nbsp;&nbsp;Change&nbsp;Group&nbsp;Number&nbsp;Key-in&nbsp;permission</strong>
      </div>
      <div>
        Allows&nbsp;the&nbsp;Group&nbsp;Number&nbsp;to&nbsp;be&nbsp;keyed&nbsp;in&nbsp;from&nbsp;the&nbsp;Keyboard.
      </div>
      <div>
        When&nbsp;the&nbsp;MS&nbsp;makes&nbsp;a&nbsp;call&nbsp;with&nbsp;a&nbsp;Group&nbsp;Number,&nbsp;a&nbsp;PTP-&gt;PTMP&nbsp;Call&nbsp;Modification
      </div>
      <div>will&nbsp;take&nbsp;place.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Group&nbsp;Call&nbsp;via&nbsp;Keypad&nbsp;NOT&nbsp;allowed&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Group&nbsp;Call&nbsp;via&nbsp;Keypad&nbsp;allowed&nbsp;(PTP-&gt;PTMP)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>22/________/GROUPNOKEYED/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPOWNERSHIP/s&nbsp;&nbsp;Change&nbsp;Group&nbsp;Call&nbsp;Ownership</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;use&nbsp;of&nbsp;Group&nbsp;Ownership&nbsp;for&nbsp;Group&nbsp;Calls</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Group&nbsp;Call&nbsp;Ownership&nbsp;not&nbsp;used&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Group&nbsp;Call&nbsp;Ownership&nbsp;used
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>22/________/GROUPOWNERSHIP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPTEXT/cc..c&nbsp;&nbsp;Setup&nbsp;Group&nbsp;Text&nbsp;Line</strong>
      </div>
      <div>Group&nbsp;Text&nbsp;Line&nbsp;to&nbsp;show&nbsp;on&nbsp;the&nbsp;Terminal&nbsp;Screen</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Text&nbsp;Line
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────
      </div>
      <div>22/________/GROUPTEXT/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPRESTR/s&nbsp;&nbsp;Setup&nbsp;Group&nbsp;Call&nbsp;Restriction</strong>
      </div>
      <div>Flag&nbsp;to&nbsp;restrict&nbsp;access&nbsp;to&nbsp;the&nbsp;Group.</div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;cleared&nbsp;the&nbsp;Group&nbsp;can&nbsp;be&nbsp;called&nbsp;from&nbsp;all&nbsp;Individual
      </div>
      <div>Subscribers&nbsp;according&nbsp;to&nbsp;their&nbsp;Profiles&nbsp;restriction.</div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;set&nbsp;the&nbsp;Group&nbsp;can&nbsp;only&nbsp;be&nbsp;called&nbsp;from&nbsp;Individual
      </div>
      <div>
        Subscribers&nbsp;with&nbsp;the&nbsp;Group&nbsp;included&nbsp;in&nbsp;their&nbsp;Profiles&nbsp;Group&nbsp;Permission
      </div>
      <div>table.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Call&nbsp;Restriction:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Normal&nbsp;Group&nbsp;restriction&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Call&nbsp;only&nbsp;allowed&nbsp;if&nbsp;Group&nbsp;is&nbsp;included&nbsp;in
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the&nbsp;Subscribers&nbsp;Group&nbsp;Permission&nbsp;table.
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/GROUPRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPINCL&nbsp;&nbsp;Display&nbsp;Group&nbsp;Include&nbsp;Table</strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>NB:&nbsp;Only&nbsp;allowed&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/GROUPINCL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPINCL/s&nbsp;&nbsp;Enable/Disable&nbsp;Group&nbsp;Include&nbsp;Table</strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>NB:&nbsp;Only&nbsp;allowed&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Include&nbsp;Table
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/GROUPINCL/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          22/nnnnnnnn/GROUPINCL/nnnnnnn/ADD/s&nbsp;&nbsp;Add/Change&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;entry
        </strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>
        Note&nbsp;that&nbsp;leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant
      </div>
      <div>NB:&nbsp;Only&nbsp;allowed&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Pre-empt&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Do&nbsp;not&nbsp;pre-empt&nbsp;call&nbsp;if&nbsp;busy&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Pre-empt&nbsp;call&nbsp;if&nbsp;busy
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;
      </div>
      <div>22/________/GROUPINCL/_______/ADD/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          22/nnnnnnnn/GROUPINCL/nnnnnnn/REM&nbsp;&nbsp;Remove&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;entry
        </strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>
        Note&nbsp;that&nbsp;leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant
      </div>
      <div>NB:&nbsp;Only&nbsp;allowed&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>22/________/GROUPINCL/_______/REM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/CALLTRF/c&nbsp;&nbsp;Change&nbsp;Call&nbsp;Transfer</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;DialIn,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Transfer&nbsp;Type
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;None
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;Not&nbsp;Reachable&nbsp;(Weak)&nbsp;&nbsp;&nbsp;(Not&nbsp;registered/no&nbsp;radio&nbsp;contact)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;No&nbsp;Answer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Medium)&nbsp;(Incl.&nbsp;Not&nbsp;Reachable)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;Busy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Strong)&nbsp;(Incl.&nbsp;No&nbsp;Answer&nbsp;and&nbsp;Not&nbsp;Reachable)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unconditional&nbsp;(Always)&nbsp;(Excluding&nbsp;Blocked&nbsp;SSI)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;N:&nbsp;Number&nbsp;Change&nbsp;(Always)&nbsp;(Including&nbsp;Blocked&nbsp;SSI)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/CALLTRF/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/CALLTRFNO/cc..c&nbsp;&nbsp;Change&nbsp;Call&nbsp;Transfer&nbsp;Number</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;DialIn,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;(0..9,#,*,+,p&nbsp;or&nbsp;w)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────────
      </div>
      <div>22/________/CALLTRFNO/______________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/CALLTRFTIME/nn&nbsp;&nbsp;Change&nbsp;Call&nbsp;Transfer&nbsp;delay&nbsp;time</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;change&nbsp;the&nbsp;Alerting&nbsp;time&nbsp;before&nbsp;Call&nbsp;Transfer&nbsp;takes&nbsp;place
      </div>
      <div>for&nbsp;"Call&nbsp;Transfer&nbsp;at&nbsp;no&nbsp;B-answer"</div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;DialIn,&nbsp;Application,&nbsp;Terminal&nbsp;and&nbsp;DMR&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Time&nbsp;in&nbsp;seconds&nbsp;(05..60,&nbsp;default&nbsp;30)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─
      </div>
      <div>22/________/CALLTRFTIME/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          22/nnnnnnnn/SUBREG/s&nbsp;&nbsp;Change&nbsp;Subscriber&nbsp;Register&nbsp;change&nbsp;permission
        </strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Subscriber&nbsp;Register&nbsp;changes&nbsp;from&nbsp;Application
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/SUBREG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/DISCREETLIST/s&nbsp;&nbsp;Change&nbsp;Discreet&nbsp;Listening&nbsp;permission</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;for&nbsp;Discreet&nbsp;Listening&nbsp;from&nbsp;the&nbsp;Application.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Discreet&nbsp;Listening
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>22/________/DISCREETLIST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/AMBIENCELIST/s&nbsp;&nbsp;Change&nbsp;Ambience&nbsp;Listening&nbsp;permission</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;for&nbsp;Ambience&nbsp;Listening&nbsp;from&nbsp;the&nbsp;Application.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Ambience&nbsp;Listening
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>22/________/AMBIENCELIST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          22/nnnnnnnn/TEMPENDIS/s&nbsp;&nbsp;Change&nbsp;Temporary&nbsp;Mobile&nbsp;Enable/Disable&nbsp;permission
        </strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;to&nbsp;make&nbsp;Temporary&nbsp;Enable/Disable&nbsp;of&nbsp;a&nbsp;Mobile
      </div>
      <div>from&nbsp;the&nbsp;Application.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Temporary&nbsp;Mobile&nbsp;Enable/Disable
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/TEMPENDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/PERMDIS/s&nbsp;&nbsp;Change&nbsp;Permanent&nbsp;Mobile&nbsp;Disable&nbsp;permission</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;to&nbsp;make&nbsp;Permanant&nbsp;Disable&nbsp;of&nbsp;a&nbsp;Mobile&nbsp;from&nbsp;the&nbsp;Application.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Permanent&nbsp;Mobile&nbsp;Disable
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/PERMDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/GROUPMAN/s&nbsp;&nbsp;Change&nbsp;Mobile&nbsp;Group&nbsp;Management&nbsp;permission</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;to&nbsp;change&nbsp;Group&nbsp;attachment&nbsp;and&nbsp;setup&nbsp;DGNA&nbsp;Groups
      </div>
      <div>in&nbsp;Mobiles&nbsp;from&nbsp;the&nbsp;Application.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Management
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/GROUPMAN/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/NUMBERMOD/s&nbsp;&nbsp;Change&nbsp;Number&nbsp;Modification&nbsp;Permission</strong>
      </div>
      <div>
        Gives&nbsp;permission&nbsp;for&nbsp;the&nbsp;Gateway&nbsp;to&nbsp;present&nbsp;an&nbsp;external&nbsp;provided
      </div>
      <div>number&nbsp;instead&nbsp;of&nbsp;the&nbsp;SSI's&nbsp;User&nbsp;Number.</div>
      <div>
        Shall&nbsp;be&nbsp;set&nbsp;if&nbsp;the&nbsp;SSI&nbsp;is&nbsp;e.g.&nbsp;a&nbsp;Group&nbsp;Bridge&nbsp;or&nbsp;similar.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;Modification&nbsp;Permission
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Number&nbsp;Modification&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Number&nbsp;Modification&nbsp;not&nbsp;allowed
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/NUMBERMOD/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/EMERGPRI/nn&nbsp;&nbsp;Change&nbsp;Emergency&nbsp;Number&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Call&nbsp;Priority&nbsp;of&nbsp;an&nbsp;Emergency&nbsp;SSI</div>
      <div>This&nbsp;will&nbsp;overrule&nbsp;Call&nbsp;Priority&nbsp;request&nbsp;1..14&nbsp;from&nbsp;mobiles</div>
      <div>
        Emergency&nbsp;Priority&nbsp;15&nbsp;and&nbsp;16&nbsp;from&nbsp;mobiles&nbsp;will&nbsp;always&nbsp;be&nbsp;passed&nbsp;unchanged
      </div>
      <div>
        Call&nbsp;Priority&nbsp;15&nbsp;(Emergency&nbsp;Call&nbsp;Priority)&nbsp;is&nbsp;recommended&nbsp;for&nbsp;Emergency&nbsp;Numbers
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Emergency&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Priority
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Mobile&nbsp;Controlled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..05:&nbsp;Low&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=0)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06..11:&nbsp;High&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=1)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12..14:&nbsp;Pre-emptive&nbsp;Priority&nbsp;(PTT&nbsp;Priority=2)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Emergency&nbsp;Call&nbsp;Priority
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>22/________/EMERGPRI/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/ICON/nn&nbsp;&nbsp;Change&nbsp;Icon</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;icon&nbsp;displayed&nbsp;on&nbsp;Clients&nbsp;for&nbsp;a&nbsp;Unified&nbsp;SSI</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Icon
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Unified
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Emergency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Personal
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Multi
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;External
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;GSM
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;PABX
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>22/________/ICON/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/PRIORITY/nn&nbsp;&nbsp;Change&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Call&nbsp;Priority&nbsp;of&nbsp;a&nbsp;Unified&nbsp;SSI</div>
      <div>This&nbsp;will&nbsp;overrule&nbsp;Call&nbsp;Priority&nbsp;request&nbsp;1..14&nbsp;from&nbsp;devices</div>
      <div>
        Emergency&nbsp;Priority&nbsp;15&nbsp;and&nbsp;16&nbsp;from&nbsp;devices&nbsp;will&nbsp;always&nbsp;be&nbsp;passed&nbsp;unchanged
      </div>
      <div>
        Call&nbsp;Priority&nbsp;15&nbsp;(Emergency&nbsp;Call&nbsp;Priority)&nbsp;is&nbsp;recommended&nbsp;for&nbsp;Emergency&nbsp;Numbers
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Priority
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Device&nbsp;Controlled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..05:&nbsp;Low&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=0)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06..11:&nbsp;High&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=1)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12..14:&nbsp;Pre-emptive&nbsp;Priority&nbsp;(PTT&nbsp;Priority=2)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Emergency&nbsp;Call&nbsp;Priority
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>22/________/PRIORITY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TARGET&nbsp;&nbsp;Display&nbsp;Target&nbsp;Table</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/TARGET</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/TARGET/n/s/s/s/nn/cc..c&nbsp;&nbsp;Add/Change/Remove&nbsp;Target</strong>
      </div>
      <div>
        Add,&nbsp;Change&nbsp;or&nbsp;Remove&nbsp;a&nbsp;Target&nbsp;Number&nbsp;for&nbsp;a&nbsp;Unified&nbsp;SSI.
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Target&nbsp;Table&nbsp;index&nbsp;(0..7)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Voice&nbsp;Flag&nbsp;(+/-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;SDS&nbsp;Flag&nbsp;(+/-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Flag&nbsp;(+/-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;Voice&nbsp;Call&nbsp;Delay&nbsp;Time&nbsp;(00..90&nbsp;sec.)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;&nbsp;┌&nbsp;Target&nbsp;Number&nbsp;(0..9,#,*,+,p&nbsp;or&nbsp;w)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴─&nbsp;┴─────────────────────────────
      </div>
      <div>22/________/TARGET/_/_/_/_/__/______________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/RUA/c&nbsp;&nbsp;Setup&nbsp;Radio&nbsp;User&nbsp;Assignment&nbsp;(RUA)&nbsp;Type</strong>
      </div>
      <div>RUA&nbsp;Type&nbsp;(in&nbsp;accordance&nbsp;to&nbsp;TTR&nbsp;001-17)</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;SSI</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RUA&nbsp;Type:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0..7:&nbsp;Requested&nbsp;radio&nbsp;user&nbsp;assignment&nbsp;value&nbsp;(in&nbsp;accordance&nbsp;to&nbsp;TTR&nbsp;001-17)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;:&nbsp;Disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>22/________/RUA/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/nnnnnnnn/DEL&nbsp;&nbsp;Delete&nbsp;SSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>22/________/DEL</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Security Key Register:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25&nbsp;&nbsp;Display&nbsp;Command&nbsp;function</strong>
      </div>
      <div>25</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/nnnnnnnn/...&nbsp;&nbsp;Add/Change&nbsp;SSI&nbsp;Key</strong>
      </div>
      <div>Used&nbsp;to&nbsp;Add/Change&nbsp;SSI&nbsp;Security&nbsp;Key</div>
      <div>
        The&nbsp;Key&nbsp;is&nbsp;Scrambled&nbsp;automatically&nbsp;before&nbsp;it&nbsp;is&nbsp;sent&nbsp;to&nbsp;Key&nbsp;Register
      </div>
      <div>Note:&nbsp;SSI&nbsp;shall&nbsp;be&nbsp;setup&nbsp;before&nbsp;Key&nbsp;is&nbsp;Added/Changed.&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Clear&nbsp;K&nbsp;key&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────────────────────────────</div>
      <div>25/________/________________________________</div>
      <div />
      <div />
    </div>
    <h2>Organization Register:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26&nbsp;&nbsp;Display&nbsp;Organization&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>26</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn&nbsp;&nbsp;Display&nbsp;Organization</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴──</div>
      <div>26/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;Organization&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..50)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>26/___/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/ADD/cc..c&nbsp;&nbsp;Add/Change&nbsp;Organization</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>
        NB:&nbsp;All&nbsp;ranges&nbsp;automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Description</div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────</div>
      <div>26/___/ADD/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/PROFNO/...&nbsp;&nbsp;Change&nbsp;Profile&nbsp;Number&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;Profile&nbsp;Numbers,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        Does&nbsp;not&nbsp;limit&nbsp;the&nbsp;range&nbsp;for&nbsp;an&nbsp;Organization&nbsp;000&nbsp;Application
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)&nbsp;(0=Barred)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴───</div>
      <div>26/___/PROFNO/____/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/SSI/...&nbsp;&nbsp;Change&nbsp;SSI&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;SSIs,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        Does&nbsp;not&nbsp;limit&nbsp;the&nbsp;range&nbsp;for&nbsp;an&nbsp;Organization&nbsp;000&nbsp;Application
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;SSI&nbsp;(00000001..16777215)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;SSI&nbsp;(00000001..16777215)&nbsp;(0=Barred)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────</div>
      <div>26/___/SSI/________/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/USERNO/...&nbsp;&nbsp;Change&nbsp;User&nbsp;Number&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;User&nbsp;Numbers,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        Does&nbsp;not&nbsp;limit&nbsp;the&nbsp;range&nbsp;for&nbsp;an&nbsp;Organization&nbsp;000&nbsp;Application
      </div>
      <div>Leading&nbsp;zeroes&nbsp;in&nbsp;User&nbsp;Numbers&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)&nbsp;(0=Barred)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;┴──────</div>
      <div>26/___/USERNO/_______/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/IPADDR/...&nbsp;&nbsp;Change&nbsp;IP&nbsp;Address&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;IP&nbsp;Addresses,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        Does&nbsp;not&nbsp;limit&nbsp;the&nbsp;range&nbsp;for&nbsp;an&nbsp;Organization&nbsp;000&nbsp;Application
      </div>
      <div>Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;value&nbsp;is&nbsp;added&nbsp;when&nbsp;displayed</div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;IP&nbsp;Address&nbsp;(000..255)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;IP&nbsp;Address&nbsp;(000..255)&nbsp;(0=Barred)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴──────────────
      </div>
      <div>26/___/IPADDR/___.___.___.___/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/PUBLRESTR/s&nbsp;&nbsp;Change&nbsp;Publish&nbsp;Restriction&nbsp;flag</strong>
      </div>
      <div>Restricts&nbsp;the&nbsp;publishing&nbsp;of&nbsp;the&nbsp;Organisation.</div>
      <div>
        Setting&nbsp;this&nbsp;flag&nbsp;will&nbsp;hide&nbsp;the&nbsp;existence&nbsp;of&nbsp;the&nbsp;Organization&nbsp;for&nbsp;all&nbsp;except
      </div>
      <div>Home&nbsp;Organization&nbsp;Applications.</div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Publish&nbsp;Restriction&nbsp;flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Publish&nbsp;to&nbsp;Home&nbsp;Organization&nbsp;Applications&nbsp;only
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Publish&nbsp;to&nbsp;all&nbsp;Applications
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>26/___/PUBLRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/NODERESTR/s&nbsp;&nbsp;Change&nbsp;Node&nbsp;Status&nbsp;Restriction&nbsp;flag</strong>
      </div>
      <div>Restricts&nbsp;the&nbsp;publishing&nbsp;of&nbsp;Node&nbsp;Status.</div>
      <div>
        Setting&nbsp;this&nbsp;flag&nbsp;will&nbsp;hide&nbsp;the&nbsp;Node&nbsp;Status&nbsp;in&nbsp;Applications.
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Status&nbsp;Restriction&nbsp;flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Node&nbsp;Status&nbsp;NOT&nbsp;sent&nbsp;to&nbsp;Applications
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Node&nbsp;Status&nbsp;sent&nbsp;to&nbsp;Applications
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>26/___/NODERESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/nnn/DEL&nbsp;&nbsp;Delete&nbsp;Organization</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;allowed&nbsp;from&nbsp;Organization&nbsp;000&nbsp;Application</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴──</div>
      <div>26/___/DEL</div>
      <div />
      <div />
      <div />
    </div>
    <h2>API Configuration Methods</h2> <div>30&nbsp;&nbsp;API&nbsp;CONFIGURATION&nbsp;METHODS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/CONFIG/GUI&nbsp;&nbsp;Open&nbsp;GUI&nbsp;Window</strong>
      </div>
      <div>Method:&nbsp;ConfigOpenGuiWindow()</div>
      <div>30/CONFIG/GUI</div>
      <div />
      <div />
    </div>
    <h2>API Subscriber Register Methods</h2> <div>30&nbsp;&nbsp;API&nbsp;SUBSCRIBER&nbsp;REGISTER&nbsp;METHODS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/GUI&nbsp;&nbsp;Open&nbsp;GUI&nbsp;Window</strong>
      </div>
      <div>Method:&nbsp;SubscriberOpenGuiWindow()</div>
      <div>30/SUBSCR/GUI</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/USERNO/nnnnnnn&nbsp;&nbsp;Get&nbsp;SSI&nbsp;from&nbsp;User&nbsp;Number</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetSsiFromUserNo()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>30/SUBSCR/USERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/IPADDR/nnn.nnn.nnn.nnn&nbsp;&nbsp;Get&nbsp;SSI&nbsp;from&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetSsiFromIpAddr()</div>
      <div>
        Only&nbsp;LSB's&nbsp;are&nbsp;significant&nbsp;as&nbsp;address&nbsp;will&nbsp;be&nbsp;masked&nbsp;with&nbsp;Subscriber
      </div>
      <div>IP&nbsp;Address&nbsp;Segment&nbsp;Mask</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────
      </div>
      <div>30/SUBSCR/IPADDR/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/ORG1/nnn&nbsp;&nbsp;Get&nbsp;Organization&nbsp;Record&nbsp;ver.&nbsp;1</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetOrganizationRecord1()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>30/SUBSCR/ORG1/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/ORG2/nnn&nbsp;&nbsp;Get&nbsp;Organization&nbsp;Record&nbsp;ver.&nbsp;2</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetOrganizationRecord2()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>30/SUBSCR/ORG2/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/PROF1/nnnn&nbsp;&nbsp;Get&nbsp;Profile&nbsp;Record&nbsp;ver.&nbsp;1</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetProfileRecord1()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/SUBSCR/PROF1/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/PROF2/nnnn&nbsp;&nbsp;Get&nbsp;Profile&nbsp;Record&nbsp;ver.&nbsp;2</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetProfileRecord2()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/SUBSCR/PROF2/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/SSI1/nnnnnnnn&nbsp;&nbsp;Get&nbsp;SSI&nbsp;Record&nbsp;ver.&nbsp;1</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetSsiRecord1()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>30/SUBSCR/SSI1/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/SSI2/nnnnnnnn&nbsp;&nbsp;Get&nbsp;SSI&nbsp;Record&nbsp;ver.&nbsp;2</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetSsiRecord2()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>30/SUBSCR/SSI2/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/SUBSCR/REG1/nnnnnnnn&nbsp;&nbsp;Get&nbsp;Registration&nbsp;Record&nbsp;ver.&nbsp;1</strong>
      </div>
      <div>Method:&nbsp;SubscriberGetRegistrationRecord1()</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>30/SUBSCR/REG1/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          30/SUBSCR/CMD/...&nbsp;&nbsp;Send&nbsp;Command&nbsp;to&nbsp;Master&nbsp;Subscriber&nbsp;Register
        </strong>
      </div>
      <div>Method:&nbsp;SubscriberSendCommand()</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Command</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────────────────────────────────────
      </div>
      <div>30/SUBSCR/CMD/________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>API Node Status Methods</h2> <div>30&nbsp;&nbsp;API&nbsp;NODE&nbsp;STATUS&nbsp;METHODS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/NODE/GUI&nbsp;&nbsp;Open&nbsp;GUI&nbsp;Window</strong>
      </div>
      <div>Method:&nbsp;NodeStatusOpenGuiWindow()</div>
      <div>30/NODE/GUI</div>
      <div />
      <div />
    </div>
    <h2>Gateway Subscriber Register Command</h2>
    <div>51&nbsp;&nbsp;GATEWAY&nbsp;SUBSCRIBER&nbsp;REGISTER&nbsp;COMMAND</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>51&nbsp;&nbsp;Display&nbsp;Command&nbsp;function</strong>
      </div>
      <div>51</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>51/D&nbsp;&nbsp;Display&nbsp;Last&nbsp;Command&nbsp;to&nbsp;Gateway</strong>
      </div>
      <div>51/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>51/cc..c&nbsp;&nbsp;Send&nbsp;Subscriber&nbsp;Register&nbsp;Command&nbsp;to&nbsp;Gateway</strong>
      </div>
      <div>
        Sends&nbsp;Subscriber&nbsp;Register&nbsp;Commands&nbsp;to&nbsp;the&nbsp;Gateway&nbsp;as&nbsp;done&nbsp;from&nbsp;the&nbsp;API
      </div>
      <div>without&nbsp;any&nbsp;syntax&nbsp;check.</div>
      <div>The&nbsp;Gateway&nbsp;accepts&nbsp;the&nbsp;21/...&nbsp;and&nbsp;22/...&nbsp;commands.</div>
      <div>
        All&nbsp;commands&nbsp;can&nbsp;also&nbsp;be&nbsp;executed&nbsp;directly&nbsp;with&nbsp;the&nbsp;21/...&nbsp;and&nbsp;22/...
      </div>
      <div>commands&nbsp;with&nbsp;syntax&nbsp;and&nbsp;permission&nbsp;checks&nbsp;in&nbsp;the&nbsp;DLL.</div>
      <div>See&nbsp;also&nbsp;20/QUE&nbsp;command&nbsp;for&nbsp;the&nbsp;API&nbsp;DLL.</div>
      <div>See&nbsp;also&nbsp;S51/D&nbsp;command&nbsp;for&nbsp;the&nbsp;Application&nbsp;Gateway.</div>
      <div>See&nbsp;also&nbsp;S50/D&nbsp;and&nbsp;S20/QUE&nbsp;for&nbsp;the&nbsp;Master&nbsp;Subscriber&nbsp;Node.</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Command&nbsp;String&nbsp;(21/..&nbsp;and&nbsp;22/..)</div>
      <div>&nbsp;&nbsp;&nbsp;┴────────────────────────────────────────────────────────────────</div>
      <div>51/_________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>CPU Performance:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66&nbsp;&nbsp;Display&nbsp;CPU&nbsp;Performance</strong>
      </div>
      <div>66</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/C&nbsp;&nbsp;Clear&nbsp;Peak&nbsp;Hold&nbsp;Times</strong>
      </div>
      <div>66/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/T&nbsp;&nbsp;Display&nbsp;1msec.&nbsp;timer</strong>
      </div>
      <div>66/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/Q&nbsp;&nbsp;Display&nbsp;Query&nbsp;Performance&nbsp;Counter</strong>
      </div>
      <div>66/Q</div>
      <div />
      <div />
    </div>
    <h2>API DLL Configuration Commands:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70&nbsp;&nbsp;Display&nbsp;last&nbsp;file&nbsp;save&nbsp;result</strong>
      </div>
      <div />
      <div>70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70/SAVE&nbsp;&nbsp;Save&nbsp;DLL&nbsp;API&nbsp;Configuration</strong>
      </div>
      <div>Note:&nbsp;API&nbsp;DLL&nbsp;configurations&nbsp;is&nbsp;saved&nbsp;automatically</div>
      <div>at&nbsp;any&nbsp;change</div>
      <div>70/SAVE</div>
      <div />
      <div />
    </div>
    <h2>General API DLL configuration:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71&nbsp;&nbsp;Display&nbsp;General&nbsp;API&nbsp;DLL&nbsp;configuration</strong>
      </div>
      <div>71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/IDPROMPT/+&nbsp;&nbsp;Prompt&nbsp;User&nbsp;for&nbsp;ID</strong>
      </div>
      <div>71/IDPROMPT/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/IDPROMPT/-&nbsp;&nbsp;Use&nbsp;ID&nbsp;in&nbsp;configuration</strong>
      </div>
      <div>71/IDPROMPT/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/USERNO/nnnnnnn&nbsp;&nbsp;Select/change&nbsp;Own&nbsp;User&nbsp;Number</strong>
      </div>
      <div>Selects&nbsp;User&nbsp;Number&nbsp;as&nbsp;Application&nbsp;ID</div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Own&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>71/USERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/SSI/nnnnnnnn&nbsp;&nbsp;Select/change&nbsp;Own&nbsp;SSI</strong>
      </div>
      <div>Selects&nbsp;SSI&nbsp;as&nbsp;Application&nbsp;ID</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Short&nbsp;Subscriber&nbsp;Identity&nbsp;(00000001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>71/SSI/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/KEY/c/hh..h&nbsp;&nbsp;Add/Change&nbsp;Authentication&nbsp;Key</strong>
      </div>
      <div>Used&nbsp;to&nbsp;Add/Change&nbsp;Secret&nbsp;Authentication&nbsp;Key</div>
      <div>
        When&nbsp;an&nbsp;Unencrypted&nbsp;Key&nbsp;is&nbsp;entered,&nbsp;it&nbsp;is&nbsp;automatically&nbsp;Encrypted&nbsp;before&nbsp;it&nbsp;is&nbsp;saved
      </div>
      <div>
        This&nbsp;command&nbsp;will&nbsp;also&nbsp;change&nbsp;the&nbsp;Key&nbsp;Date/Time&nbsp;(except&nbsp;when&nbsp;reloaded&nbsp;from&nbsp;Config&nbsp;File)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Encryption:</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;C:&nbsp;Unencrypted&nbsp;Key&nbsp;(used&nbsp;when&nbsp;the&nbsp;Key&nbsp;is&nbsp;Added/Changed)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;AES&nbsp;Encrypted&nbsp;Key&nbsp;(used&nbsp;for&nbsp;Config&nbsp;File&nbsp;read-back)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Authentication&nbsp;Key&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F))
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴───────────────────────────────</div>
      <div>71/KEY/_/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/KEY/-&nbsp;&nbsp;Clear&nbsp;Authentication&nbsp;Key</strong>
      </div>
      <div>Used&nbsp;to&nbsp;Clear&nbsp;the&nbsp;Encrypted&nbsp;Authentication&nbsp;Key</div>
      <div>
        This&nbsp;command&nbsp;will&nbsp;also&nbsp;change&nbsp;the&nbsp;Key&nbsp;Date/Time&nbsp;(except&nbsp;when&nbsp;reloaded&nbsp;from&nbsp;Config&nbsp;File)
      </div>
      <div>71/KEY/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/KEY/DATIM/hhhhhhhh&nbsp;&nbsp;Change&nbsp;Authentication&nbsp;Key&nbsp;Date/Time</strong>
      </div>
      <div>
        Reserved&nbsp;command&nbsp;to&nbsp;remember&nbsp;the&nbsp;last&nbsp;Date/Time,&nbsp;when&nbsp;the&nbsp;Authentication
      </div>
      <div>Key&nbsp;was&nbsp;changed.</div>
      <div>NB:</div>
      <div>
        This&nbsp;command&nbsp;is&nbsp;automatically&nbsp;changed&nbsp;in&nbsp;the&nbsp;Config&nbsp;File&nbsp;when&nbsp;the
      </div>
      <div>
        Authentication&nbsp;Key&nbsp;is&nbsp;changed&nbsp;and&nbsp;should&nbsp;never&nbsp;be&nbsp;used&nbsp;manually.
      </div>
      <div>71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/NWAUTH/s&nbsp;&nbsp;Change&nbsp;Network&nbsp;Authentication&nbsp;selection</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;control&nbsp;whether&nbsp;Authentication&nbsp;of&nbsp;the&nbsp;Network&nbsp;is&nbsp;done.
      </div>
      <div>Network&nbsp;Authentication&nbsp;is&nbsp;enabled&nbsp;by&nbsp;default.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Network&nbsp;Authentication:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Enabled</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Disabled</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>71/NWAUTH/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/PIN/PROMPT&nbsp;&nbsp;Prompt&nbsp;User&nbsp;for&nbsp;Pin&nbsp;Code</strong>
      </div>
      <div>71/PIN/PROMPT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/PIN/nnnnnnnnn&nbsp;&nbsp;Setup/Change&nbsp;PIN&nbsp;Code</strong>
      </div>
      <div>PIN&nbsp;Code&nbsp;1..9&nbsp;digits,&nbsp;leading&nbsp;zeroes&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PIN&nbsp;Code&nbsp;(1..999999999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────</div>
      <div>71/PIN/_________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/OMPORT/nnnnn&nbsp;&nbsp;Change&nbsp;OM&nbsp;TCP&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>Command&nbsp;to&nbsp;change&nbsp;the&nbsp;OM&nbsp;TCP&nbsp;listening&nbsp;Port</div>
      <div>The&nbsp;default&nbsp;Port&nbsp;Number&nbsp;of&nbsp;the&nbsp;ApiDll&nbsp;is&nbsp;different&nbsp;from</div>
      <div>
        the&nbsp;BSC&nbsp;to&nbsp;avoid&nbsp;conflict&nbsp;when&nbsp;running&nbsp;on&nbsp;the&nbsp;same&nbsp;PC.
      </div>
      <div>When&nbsp;establishment&nbsp;of&nbsp;a&nbsp;connection&nbsp;is&nbsp;not&nbsp;possible,</div>
      <div>change&nbsp;the&nbsp;port&nbsp;number&nbsp;in&nbsp;the&nbsp;Config.txt&nbsp;file&nbsp;directly.</div>
      <div>Use&nbsp;the&nbsp;newest&nbsp;versions&nbsp;of&nbsp;the&nbsp;TetraOM&nbsp;program&nbsp;to&nbsp;allow</div>
      <div>to&nbsp;setup&nbsp;the&nbsp;Port&nbsp;Number.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;OM&nbsp;TCP&nbsp;Port&nbsp;(01024..65535,&nbsp;default&nbsp;42382)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>71/OMPORT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/OMPIN/nn..n&nbsp;&nbsp;Change&nbsp;OM&nbsp;PIN&nbsp;Code</strong>
      </div>
      <div>Command&nbsp;to&nbsp;change&nbsp;the&nbsp;OM&nbsp;PIN&nbsp;Code</div>
      <div>Used&nbsp;to&nbsp;protect&nbsp;OM&nbsp;access&nbsp;to&nbsp;DLL</div>
      <div>Defaults&nbsp;to&nbsp;a&nbsp;pseudo-random&nbsp;32&nbsp;Bit&nbsp;value</div>
      <div>The&nbsp;value&nbsp;zero&nbsp;disables&nbsp;PIN&nbsp;Code&nbsp;prompt</div>
      <div>To&nbsp;be&nbsp;viewed&nbsp;and&nbsp;changed&nbsp;in&nbsp;Config.txt&nbsp;file&nbsp;directly</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;OM&nbsp;PIN&nbsp;Code&nbsp;(0..4294967295)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────</div>
      <div>71/OMPIN/__________</div>
      <div />
      <div />
    </div>
    <h2>Gateway configuration:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72&nbsp;&nbsp;Display&nbsp;Gateways</strong>
      </div>
      <div>72</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/n/nnn.nnn.nnn.nnn/...&nbsp;&nbsp;Add/Change&nbsp;Gateway</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Gateway&nbsp;Number&nbsp;(0..9)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Description
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────&nbsp;┴───────────────────────</div>
      <div>72/_/___.___.___.___/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/n/-&nbsp;&nbsp;Remove&nbsp;Gateway</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Gateway&nbsp;Number&nbsp;(0..9)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>72/_/-</div>
      <div />
      <div />
    </div>
  </>
);

export default memo(ApiDllPage) as typeof ApiDllPage;
