import styled from 'styled-components';

export { default as apidll } from './apidll';
export { default as bs } from './bs';
export { default as bsc } from './bsc';
export { default as bss } from './bss';
export { default as help } from './help';
export { default as main } from './main';
export { default as node } from './node';
export { default as om } from './om';
export { default as ps411 } from './ps411';
export { default as ps421 } from './ps421';
export { default as tcc411 } from './tcc411';
export { default as tr412 } from './tr412';
export { default as tr421 } from './tr421';

export const pages = {
  apidll: 'API Application Gateway ApiDLL ver. 7.79',
  bs: 'BS - Base Staion ver. 7.82',
  bsc: 'BSC - Base Staion Controller ver. 7.81',
  bss: 'BSS - Base Staion Switch ver. 7.82',
  help: 'HELP files',
  main: 'MAIN - BSC Main ver. 7.82',
  node: 'Node - Node Controller',
  om: 'OM program',
  ps411: 'PS411 - Power Supply ver. 1.00',
  ps421: 'PS421 - Power Supply ver. 1.00',
  tcc411: 'TCC411 - TX Combiner Controller ver. 2.00',
  tr412: 'TR412 - Transceiver ver. 7.73',
  tr421: 'TR421 - Transceiver ver. 7.82',
} as const;

export const PageContainer = styled.article`
  width: 100%;
  height: 100%;
  max-height: 588px;

  overflow: clip scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
