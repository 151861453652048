import { useLocation, useNavigate, useParams } from '@fjedi/react-router-helpers';
import omit from 'lodash/omit';
import React, { FC, memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import { stopEventPropagation } from 'src/functions';
//
import { logger, useApolloError, updateAfterMutation } from '@fjedi/graphql-react-components';
import {
  CreateNodeProfileMutationVariables,
  UpdateNodeProfileMutationVariables,
  useCreateNodeProfileMutation,
  useNodeProfileQuery,
  useRemoveNodeProfileMutation,
  useUpdateNodeProfileMutation,
} from 'src/graphql/generated';
// CSS
// Components
import { Divider, Empty, RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cn from 'classnames';
import Button from 'src/components/ui-kit/buttons';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import Checkbox from 'src/components/ui-kit/checkbox';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import { Col, Row } from 'src/components/ui-kit/grid';
import { Input, InputNumber } from 'src/components/ui-kit/input';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import { RadioGroup } from 'src/components/ui-kit/radio';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import { Select } from 'src/components/ui-kit/select';
import Spinner from 'src/components/ui-kit/spinner';
import { DrawerViewMode } from './drawer-helpers';
import { Actions, Container, Title } from './drawer.sc';

export type ProfileDrawerProps = {};
//
const ProfileDrawer: FC<ProfileDrawerProps> = () => {
  const { profileId } = useParams();
  const { t } = useTranslation();
  const onError = useApolloError();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onDrawerClose = useCallback(() => navigate('../profiles'), [navigate]);
  //
  const mode = useMemo(() => {
    if (!profileId) {
      return null;
    }

    if (profileId === 'new') {
      return DrawerViewMode.Create;
    }

    if (pathname.endsWith(`${profileId}/edit`) || pathname.endsWith(`${profileId}/edit/`)) {
      return DrawerViewMode.Edit;
    }

    if (pathname.endsWith(profileId) || pathname.endsWith(`${profileId}/`)) {
      return DrawerViewMode.View;
    }

    return DrawerViewMode.View;
  }, [profileId, pathname]);
  const [isEmpty, setEmpty] = useState(false);
  const [form] = Form.useForm();
  const { resetFields } = form;
  const onValuesChange = useCallback(
    (changedValues: any) => {
      logger('Profile.onValuesChange', {
        changedValues,
      });
      if ('pdChannelMode' in changedValues) {
        form.setFieldValue('pdChannelMode', changedValues.pdChannelMode);
        form.validateFields();
      }
    },
    [form],
  );

  const { data, loading } = useNodeProfileQuery({
    variables: { id: profileId! },
    skip: !profileId || profileId === 'new',
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      if (res?.nodeProfile) {
        console.info('DATA: ', res.nodeProfile);
      }
    },
  });

  const [createNodeProfile, { loading: creating }] = useCreateNodeProfileMutation({
    onError,
    update: updateAfterMutation('NodeProfile'),
    onCompleted() {
      resetFields();
      onDrawerClose();
    },
  });
  const [updateNodeProfile, { loading: updating }] = useUpdateNodeProfileMutation({
    onError,
    onCompleted() {
      resetFields();
      onDrawerClose();
    },
  });
  const [removeNodeProfile, { loading: removing }] = useRemoveNodeProfileMutation({
    onError,
    variables: { id: profileId! },
    update: updateAfterMutation('NodeProfile'),
    onCompleted(res) {
      if (res?.removeNodeProfile?.id) {
        onDrawerClose();
      }
    },
  });
  const isLoading = useMemo(() => loading || creating || updating || removing, [loading, creating, removing, updating]);
  //
  const onSubmit = useCallback(
    (formData: any) => {
      const onlyChangedFields = true;
      let input = parseFormData(form, formData, onlyChangedFields);
      if (typeof input.assocOrgNumbers === 'string') {
        input = { ...input, assocOrgNumbers: input.assocOrgNumbers.split(', ') };
      }
      if (typeof input.groupPermTable === 'string') {
        input = { ...input, assocOrgNumbers: input.groupPermTable.split(', ') };
      }
      if (input.requiredCapability instanceof Array) {
        input = {
          ...omit(input, 'requiredCapability'),
          flags: { ...(input.flags ?? {}), ...Object.fromEntries(input.requiredCapability.map(flag => [flag, true])) },
        };
      }
      if ('sfCenterAllowed' in input || 'sfUserNo' in input) {
        let storeFwdUserNo = 0;

        if (input.sfCenterAllowed) {
          storeFwdUserNo = input.sfUserNo ? parseInt(input.sfUserNo as string, 10) : 4294967295;
        }

        input = { ...omit(input, ['sfCenterAllowed', 'sfUserNo']), storeFwdUserNo };
      }
      if ('ssiCallAllowed' in input) {
        input.flags = { ...(input.flags ?? {}), ssiCallAllowed: input.ssiCallAllowed === 'true' };
        input = omit(input, 'ssiCallAllowed');
      }
      if ('displaySsiForGroupCalls' in input) {
        input.flags = { ...(input.flags ?? {}), ssiCallAllowed: input.displaySsiForGroupCalls === 'true' };
        input = omit(input, 'displaySsiForGroupCalls');
      }
      if ('pdChannelMode' in input) {
        input = omit(input, 'pdChannelMode');
      }
      //
      logger('Drawer form submit', {
        formData,
        input,
      });
      if (profileId === 'new') {
        const variables: CreateNodeProfileMutationVariables = {
          input,
        };
        createNodeProfile({ variables }).catch(logger);
      }
      const variables: UpdateNodeProfileMutationVariables = {
        id: profileId!,
        input,
      };
      updateNodeProfile({ variables }).catch(logger);
    },
    [form, profileId, createNodeProfile, updateNodeProfile],
  );

  const onReset = useCallback(() => {
    form.resetFields();

    if (mode === DrawerViewMode.Edit) {
      navigate(`./`);
    }

    if (mode === DrawerViewMode.Create) {
      navigate(-1);
    }
  }, [form, mode, navigate]);
  const nodeFormInitialValues = useMemo(() => {
    if (!profileId || isLoading || isEmpty) {
      return null;
    }

    const {
      profNo,
      description,
      homeOrgNo,
      priority,
      indivPttTimeout,
      pttDurMax,
      callDurMax,
      lateEntryTimer,
      pdGw1NodeNo,
      voiceGw1NodeNo,
      voiceGw2NodeNo,
      assocOrgNumbers,
      groupPermTable,
      termServRestr,
      pdClass,
      subscrClass,
      pdGw2NodeNo,
      dnsIpAddr,
      scchAllocation,
      secMode,
      secKsg,
      pdMsMax,
      pdTimer,
      shortNumberFormat,
      dispUserNo,
      posDest1UserNo,
      storeFwdUserNo,
      posDest2UserNo,
      applServerUserNo,
      flags,
    } = data?.nodeProfile ?? {};

    const {
      serviceRestriction,
      groupCallRestriction,
      authCapabilityRequired,
      airInterfaceEncryptionCapabilityRequired,
      endToEndEncryptionCapabilityRequired,
      ssiCallAllowed,
      displaySsiForGroupCalls,
    } = flags ?? {};

    const sfCenterAllowed = storeFwdUserNo !== 0;
    const sfUserNo = storeFwdUserNo === 4294967295 ? '' : storeFwdUserNo;

    const pdChannelMode = pdClass > 0 ? 1 : 0;

    return {
      profNo,
      description,
      homeOrgNo,
      priority,
      indivPttTimeout,
      pttDurMax,
      callDurMax,
      lateEntryTimer,
      pdGw1NodeNo,
      voiceGw1NodeNo,
      voiceGw2NodeNo,
      assocOrgNumbers: assocOrgNumbers?.join(', ') ?? '',
      groupPermTable: groupPermTable?.join(', ') ?? '',
      termServRestr,
      pdClass,
      subscrClass,
      pdGw2NodeNo,
      dnsIpAddr,
      scchAllocation,
      secMode,
      secKsg,
      pdMsMax,
      pdTimer,
      shortNumberFormat: JSON.stringify(shortNumberFormat), // `${shortNumberFormat?.prefix ? shortNumberFormat.prefix : ''}${
      // shortNumberFormat?.length ? 'x'.repeat(shortNumberFormat.length) : ''
      // }`
      dispUserNo,
      posDest1UserNo,
      sfCenterAllowed,
      sfUserNo,
      posDest2UserNo,
      ssiCallAllowed: `${ssiCallAllowed}`,
      displaySsiForGroupCalls: `${displaySsiForGroupCalls}`,
      applServerUserNo,
      serviceRestriction: !!serviceRestriction,
      groupCallRestriction: !!groupCallRestriction,
      requiredCapabilty: {
        authCapabilityRequired,
        airInterfaceEncryptionCapabilityRequired,
        endToEndEncryptionCapabilityRequired,
      },
      pdChannelMode,
    };
  }, [data?.nodeProfile, isEmpty, isLoading, profileId]);

  useLayoutEffect(() => {
    if (isEmpty || profileId !== 'new') {
      form.resetFields();
    }

    if (profileId === 'new') {
      form.resetFields();
      return () => setEmpty(false);
    }

    if (profileId && !isLoading) {
      if (!data) {
        setEmpty(true);
      }

      return () => setEmpty(prev => !prev && !!data && !nodeFormInitialValues);
    }

    return undefined;
  }, [data, form, isEmpty, isLoading, loading, nodeFormInitialValues, profileId]);

  const priorityOptions = useMemo(
    () => [
      { label: '00: Device Controlled', value: 0 },
      { label: '01: Low Priority', value: 1 },
      { label: '02: Low Priority', value: 2 },
      { label: '03: Low Priority', value: 3 },
      { label: '04: Low Priority', value: 4 },
      { label: '05: Low Priority', value: 5 },
      { label: '06: High Priority', value: 6 },
      { label: '07: High Priority', value: 7 },
      { label: '08: High Priority', value: 8 },
      { label: '09: High Priority', value: 9 },
      { label: '10: High Priority', value: 10 },
      { label: '11: High Priority', value: 11 },
      { label: '12: Pre-emptive Priority', value: 12 },
      { label: '13: Pre-emptive Priority', value: 13 },
      { label: '14: Pre-emptive Priority', value: 14 },
      { label: '15: Emergency Call Priority', value: 15 },
    ],
    [],
  );

  const secModeOptions = useMemo(
    () => [
      { label: '0: Class 1, no Auth./AIE', value: 0 },
      { label: '1: Class 1, Auth. optional, AIE none', value: 1 },
      { label: '2: Class 1, Auth. mandatory, AIE none', value: 2 },
      { label: '3: Class 3, Auth. optional, AIE optional', value: 3 },
      { label: '4: Class 3, Auth. mandatory, AIE mandatory', value: 4 },
    ],
    [],
  );

  const secKsgOptions = useMemo(
    () => [
      { label: '0: TEA1', value: 0 },
      { label: '1: TEA2', value: 1 },
      { label: '2: TEA3', value: 2 },
      { label: '3: TEA4', value: 3 },
      { label: '4-15: зарезервировано', value: 4 },
      { label: '4-15: зарезервировано', value: 5 },
      { label: '4-15: зарезервировано', value: 6 },
      { label: '4-15: зарезервировано', value: 7 },
      { label: '4-15: зарезервировано', value: 8 },
      { label: '4-15: зарезервировано', value: 9 },
      { label: '4-15: зарезервировано', value: 10 },
      { label: '4-15: зарезервировано', value: 11 },
      { label: '4-15: зарезервировано', value: 12 },
      { label: '4-15: зарезервировано', value: 13 },
      { label: '4-15: зарезервировано', value: 14 },
      { label: '4-15: зарезервировано', value: 15 },
    ],
    [],
  );

  const callDialingOptions = useMemo(
    () => [
      { label: 'User No. only', value: 'false' },
      { label: 'User No. or SSI', value: 'true' },
    ],
    [],
  );

  const ssiForGroupCallsOptions = useMemo(
    () => [
      { label: 'SSI (For MS compatibility)', value: 'true' },
      { label: 'None', value: 'false' },
    ],
    [],
  );

  const scchAllocationOptions = useMemo(() => [{ label: 'Auto', value: 0 }], []);

  const pdChannelModeOptions = useMemo(
    () => [
      { label: 'Multishot', value: 0 },
      { label: 'Sharing', value: 1 },
    ],
    [],
  );

  const drawerTitle = useMemo(() => {
    switch (mode) {
      case DrawerViewMode.Edit:
        return t('Profile edit/delete');
      case DrawerViewMode.Create:
        return t('Create Profile');
      case DrawerViewMode.View:
      default:
        return t('Profile info');
    }
  }, [mode, t]);

  const [pd, setPd] = useState(form.getFieldValue('pdChannelMode'));
  const [sf, setSf] = useState(form.getFieldValue('sfCenterAllowed'));
  const handlePdChannelModeChange = useCallback((e: RadioChangeEvent) => setPd(e.target.value), []);
  const handleSfCenterAllowedChange = useCallback((e: CheckboxChangeEvent) => setSf(e.target.checked), []);

  const readonly = useMemo(() => mode === DrawerViewMode.View, [mode]);

  return (
    <Container size="large" placement="right" closable={false} onClose={onDrawerClose} visible={!!profileId}>
      <Scrollbar>
        <Title>
          <span>{drawerTitle}</span>
          {readonly && (
            <LinkButton type="primary" to="./edit">
              {t('Изменить')}
            </LinkButton>
          )}
          {readonly && (
            <LinkButton type="primary" to="../profiles/new">
              {t('Доб./Копр.')}
            </LinkButton>
          )}
          {!!data && [DrawerViewMode.Edit, DrawerViewMode.View].includes(mode as DrawerViewMode) && (
            <Popconfirm
              placement="bottomLeft"
              title={`${t('Remove')}?`}
              onClick={stopEventPropagation}
              onConfirm={removeNodeProfile}>
              <Button loading={removing} type="primary" danger>
                {t('Remove')}
              </Button>
            </Popconfirm>
          )}
        </Title>
        <Spinner spinning={isLoading}>
          {isEmpty && <Empty description={t('Failed to fetch user data')} />}
          {!!nodeFormInitialValues && (
            <Form
              form={form}
              onFinish={onSubmit}
              initialValues={nodeFormInitialValues}
              onValuesChange={onValuesChange}
              layout="vertical"
              style={{ paddingBottom: '1.5rem' }}
              className={cn({ readonly })}>
              <Row>
                <Col md={12}>
                  <FormItem name="profNo" label={t('Профиль')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="priority" label={t('Приоритет вызова')}>
                    <Select options={priorityOptions} disabled />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="description" label={t('Название')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="indivPttTimeout" label={t('ИНД. таймер PTT (мин)')}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="0.1" />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="homeOrgNo" label={t('Организация')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="callDurMax" label={t('Макс. длит. вызова (мин)')}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="assocOrgNumbers" label={t('Связные орг.')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="pttDurMax" label={t('Макс. время PTT (сек)')}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="serviceRestriction" label={t('Ограничение услуг')}>
                    <Checkbox disabled={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="lateEntryTimer" label={t('Поздный вход (сек)')}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="groupCallRestriction" valuePropName="checked" label={t('Ограничение групп')}>
                    <Checkbox disabled />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="voiceGw1NodeNo" label={t('Голосовой шл.1')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="groupPermTable" label={t('Групповые правила')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="voiceGw2NodeNo" label={t('Голосовой шл. 2')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>

                {/* //! == == == */}
                <Col md={12}>
                  <FormItem name=" " label={t('Запрет сайтов')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                {/* //! == == == */}

                <Col md={12}>
                  <FormItem name="pdGw1NodeNo" label={t('Шлюз данных 1')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="subscrClass" label={t('Абонентский класс')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="pdGw2NodeNo" label={t('Шлюз данных 2')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="ssiCallAllowed" label={t('Схема набора')}>
                    <Select options={callDialingOptions} disabled />
                  </FormItem>
                </Col>

                {/* //! == == == */}
                <Col md={12}>
                  <FormItem name="dnsIpAddr" label={t('DNS IP Адрес')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                {/* //! == == == */}

                <Col md={12}>
                  <FormItem name="displaySsiForGroupCalls" label={t('Идентиф. в группе')}>
                    <Select options={ssiForGroupCallsOptions} disabled />
                  </FormItem>
                </Col>

                {/* //! == == == */}
                <Col md={12}>
                  <FormItem name="scchAllocation" label={t('Назначение SCCH')}>
                    <Select options={scchAllocationOptions} disabled />
                  </FormItem>
                </Col>
                {/* //! == == == */}

                <Col md={12}>
                  <FormItem name="requiredCapability" label={t('Требуется совместимость')}>
                    <Checkbox.Group>
                      <Checkbox disabled={readonly} value="authCapabilityRequired">
                        {t('Auth')}
                      </Checkbox>
                      <Checkbox disabled={readonly} value="airInterfaceEncryptionCapabilityRequired">
                        {t('AIE')}
                      </Checkbox>
                      <Checkbox disabled={readonly} value="endToEndEncryptionCapabilityRequired">
                        {t('E2EE')}
                      </Checkbox>
                    </Checkbox.Group>
                  </FormItem>
                </Col>

                {/* //! == == == */}
                <Col md={12}>
                  <FormItem
                    name="pdChannelMode"
                    label={t('Режим пакетных данных')}
                    dependencies={['pdClass', 'pdMsMax']}>
                    <RadioGroup
                      disabled={readonly}
                      options={pdChannelModeOptions}
                      onChange={handlePdChannelModeChange}
                    />
                  </FormItem>
                </Col>
                {/* //! == == == */}

                <Col md={12}>
                  <FormItem name="secMode" label={t('Режим безопасности')}>
                    <Select options={secModeOptions} disabled />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="pdClass" label={t('Класс разделения ПД')} dependencies={['pdChannelMode']}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="1" disabled={!pd} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="secKsg" label={t('Шифрофание')}>
                    <Select options={secKsgOptions} disabled />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="pdMsMax" label={t('Макс. №АС в сессии ПД')} dependencies={['pdChannelMode']}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="1" disabled={!pd} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="shortNumberFormat" label={t('Короткий номер')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="pdTimer" label={t('Таймер готовности')}>
                    <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="dispUserNo" label={t('Диспетчер')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="sfCenterAllowed" valuePropName="checked" label={t('S&F Центр использования')}>
                    <Checkbox disabled={readonly} onChange={handleSfCenterAllowedChange} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="posDest1UserNo" label={t('Координатный отчёт 1')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="sfUserNo" label={t('Номер по умолчанию')}>
                    <Input disabled={!sf} readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="posDest2UserNo" label={t('Координатный отчёт 2')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="applServerUserNo" label={t('№ Сервере приложений')}>
                    <Input disabled readOnly={readonly} />
                  </FormItem>
                </Col>
              </Row>

              {mode !== DrawerViewMode.View && (
                <>
                  <Divider />
                  <Actions>
                    <Button htmlType="reset" loading={isLoading} onClick={onReset}>
                      {t('Cancel')}
                    </Button>
                    <SubmitButton loading={isLoading} size="middle">
                      {data ? t('Save') : t('Create')}
                    </SubmitButton>
                  </Actions>
                </>
              )}
            </Form>
          )}
        </Spinner>
      </Scrollbar>
    </Container>
  );
};

export default memo(ProfileDrawer);
