// React
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
//
import getNodeStatusListQuery from 'src/graphql/queries/get-nodes-statuses.graphql';
import nodeStatusCreatedSubscription from 'src/graphql/subscriptions/node-status-created.graphql';
import nodeStatusChangedSubscription from 'src/graphql/subscriptions/node-status-changed.graphql';
//
import Table from 'src/components/ui-kit/table';
import Status from 'src/components/ui-kit/project-specific/online-status-dot';

import { renderConfigured, styleConfigured, statusObjs, subRegModes } from 'src/helpers/tetra';

const NodeStatus = styled.div`
  display: flex;
  align-items: center;
`;

const NodeCell = styled.span`
  color: ${props => `${props.status.color}`};
`;

const SubscriberTab = () => {
  const { t } = useTranslation();
  return (
    <Table
      pagination
      pageSize={40}
      dataType="NodeStatus"
      query={getNodeStatusListQuery}
      variables={{ filter: { tab: 'subscriber' } }}
      sorter={{ columnKey: 'NodeNo', order: 'ascend' }}
      subscriptionQueries={[nodeStatusCreatedSubscription, nodeStatusChangedSubscription]}
      rowKey="id"
      columns={[
        {
          title: t('Node'),
          dataIndex: 'number',
          width: 180,
          render(_, { number, bsc, alarmState }) {
            return (
              <NodeStatus>
                <Status state={alarmState} />
                <span>{`${String(number).padStart(3, '0')} [BSC${bsc + 1}]`}</span>
              </NodeStatus>
            );
          },
        },
        {
          title: t('Description column'),
          dataIndex: 'description',
          render(userAgent) {
            return userAgent || 'Не указано';
          },
        },
        {
          title: t('Mode'),
          dataIndex: 'subRegMode',
          onCell: styleConfigured(),
          render: renderConfigured((_, { subRegMode }) => subRegModes[subRegMode]),
        },
        {
          title: t('Status'),
          dataIndex: 'subRegAlarmState',
          onCell: styleConfigured(({ subRegAlarmState: state }) => {
            const { background } = statusObjs[state];
            return { background };
          }),
          render: renderConfigured((_, { subRegAlarmState: state }) => {
            const status = statusObjs[state];
            return <NodeCell status={status}>{status.label}</NodeCell>;
          }),
        },
        {
          title: t('Nw'),
          dataIndex: 'redBscSubStat',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
        {
          title: t('Org.'),
          dataIndex: 'organizationCount',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
        {
          title: t('Prof'),
          dataIndex: 'profileCount',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
        {
          title: t('Sub'),
          dataIndex: 'subCount',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
        {
          title: t('SSI'),
          dataIndex: 'ssiCount',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
        {
          title: t('User'),
          dataIndex: 'userNoCount',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
        {
          title: t('IP'),
          dataIndex: 'ipAddrCount',
          onCell: styleConfigured(),
          render: renderConfigured(),
        },
      ]}
    />
  );
};

export default SubscriberTab;
