import React, { FC, memo } from 'react';

const MainPage: FC = () => (
  <>
    <h1>MAIN BSC Main ver. 7.82</h1>
    <br />
    <h2>Functional Description</h2>
    <br />
    <br />
    <h2>Address selection</h2>
    <br />
    <div>A&nbsp;&nbsp;ADDRESS&nbsp;SELECTION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A&nbsp;&nbsp;Display&nbsp;Address&nbsp;selection</strong>
      </div>
      <div>Displays&nbsp;selected&nbsp;address&nbsp;for&nbsp;nn&nbsp;commands</div>
      <div>A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>AP&nbsp;&nbsp;Select&nbsp;PS421&nbsp;Address</strong>
      </div>
      <div>
        Deselected&nbsp;again&nbsp;by&nbsp;selecting&nbsp;any&nbsp;other&nbsp;nn&nbsp;command&nbsp;address&nbsp;&nbsp;
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;on&nbsp;BSC421&nbsp;if&nbsp;if&nbsp;PS421&nbsp;is&nbsp;configured!</div>
      <div>AP</div>
      <div />
      <div />
    </div>
    <h2>General commands</h2>
    <br />
    <div>M00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M00&nbsp;&nbsp;Display&nbsp;Welcome&nbsp;Message</strong>
      </div>
      <div>M00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M00/A&nbsp;&nbsp;Display&nbsp;all&nbsp;software&nbsp;versions&nbsp;numbers</strong>
      </div>
      <div>M00/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M00/C&nbsp;&nbsp;Display&nbsp;compiler&nbsp;options</strong>
      </div>
      <div>M00/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M00/OM&nbsp;&nbsp;Display&nbsp;OM&nbsp;TCP&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>M00/OM</div>
      <div />
      <div />
    </div>
    <div>M01&nbsp;&nbsp;DISPLAY&nbsp;DESCRIPTION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M01&nbsp;&nbsp;Display&nbsp;Description</strong>
      </div>
      <div>M01</div>
      <div />
      <div />
    </div>
    <h2>Alarms</h2>
    <br />
    <div>M03&nbsp;&nbsp;ALARMS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M03&nbsp;&nbsp;Display&nbsp;Alarm&nbsp;Ports</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;not&nbsp;available&nbsp;on&nbsp;PC&nbsp;Hardware</div>
      <div>M03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M03/OUT/n&nbsp;&nbsp;Manual&nbsp;Alarm&nbsp;Level&nbsp;Output&nbsp;Control</strong>
      </div>
      <div>Activates&nbsp;Manual&nbsp;Alarm&nbsp;Control&nbsp;of&nbsp;Alarm&nbsp;Output&nbsp;1&nbsp;+&nbsp;2</div>
      <div>Level&nbsp;&nbsp;Out2&nbsp;&nbsp;Out1</div>
      <div>-----&nbsp;&nbsp;----&nbsp;&nbsp;----</div>
      <div>&nbsp;L0:&nbsp;&nbsp;&nbsp;Off&nbsp;&nbsp;&nbsp;Off</div>
      <div>&nbsp;L1:&nbsp;&nbsp;&nbsp;Off&nbsp;&nbsp;&nbsp;On</div>
      <div>&nbsp;L2:&nbsp;&nbsp;&nbsp;On&nbsp;&nbsp;&nbsp;&nbsp;Off</div>
      <div>&nbsp;L3:&nbsp;&nbsp;&nbsp;On&nbsp;&nbsp;&nbsp;&nbsp;On</div>
      <div />
      <div>
        Individual&nbsp;L1,&nbsp;L2&nbsp;and&nbsp;L3&nbsp;Alarms&nbsp;can&nbsp;be&nbsp;achieved&nbsp;by&nbsp;wiring&nbsp;of&nbsp;two&nbsp;external&nbsp;relays:
      </div>
      <div>&nbsp;&nbsp;K1A&nbsp;&nbsp;K1B&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;K2A&nbsp;&nbsp;K2B</div>
      <div>&nbsp;&nbsp;1&nbsp;0&nbsp;&nbsp;1&nbsp;0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;0&nbsp;&nbsp;1&nbsp;0</div>
      <div>&nbsp;&nbsp;┌│┐&nbsp;&nbsp;┌│┐&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌│┐&nbsp;&nbsp;┌│┐</div>
      <div>&nbsp;&nbsp;│││&nbsp;&nbsp;│││&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││&nbsp;&nbsp;│││</div>
      <div>&nbsp;&nbsp;│└┼──┼┼──────┼┘│&nbsp;&nbsp;││</div>
      <div>&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│└──────┼─┼──┼┘</div>
      <div>&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌───┼─┘&nbsp;&nbsp;└─────────┐&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;└───┼───┼────────────┐&nbsp;│&nbsp;</div>
      <div>&nbsp;&nbsp;│&nbsp;└──────┼───┼─┐&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│</div>
      <div>
        &nbsp;&nbsp;│&nbsp;┌──────┘&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;┴&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴
      </div>
      <div>
        &nbsp;&nbsp;L1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L3
      </div>
      <div>&nbsp;</div>
      <div>
        NB:&nbsp;Remember&nbsp;to&nbsp;disable&nbsp;Manual&nbsp;Control&nbsp;again&nbsp;with&nbsp;M03/OUT/-&nbsp;command!&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Alarm&nbsp;Level&nbsp;(0..3)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>M03/OUT/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M03/OUT/-&nbsp;&nbsp;Stop&nbsp;Manual&nbsp;Alarm&nbsp;Output&nbsp;Control</strong>
      </div>
      <div>M03/OUT/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M03/LED&nbsp;&nbsp;Display&nbsp;LEDs</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;not&nbsp;available&nbsp;on&nbsp;PC&nbsp;Hardware</div>
      <div>M03/LED</div>
      <div />
      <div />
    </div>
    <h2>Network Settings</h2>
    <br />
    <div>M06&nbsp;&nbsp;DISPLAY&nbsp;NETWORK&nbsp;PARAMETERS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M06&nbsp;&nbsp;Display&nbsp;Network&nbsp;parameters</strong>
      </div>
      <div>M06</div>
      <div />
      <div />
    </div>
    <h2>Date/Time</h2>
    <br />
    <div>M07&nbsp;&nbsp;DISPLAY&nbsp;DATE&nbsp;AND&nbsp;TIME</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M07&nbsp;&nbsp;Display&nbsp;date&nbsp;and&nbsp;time</strong>
      </div>
      <div>M07</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M07/S&nbsp;&nbsp;Display&nbsp;date/time&nbsp;status</strong>
      </div>
      <div>M07/S</div>
      <div />
      <div />
    </div>
    <h2>PS421 Status</h2>
    <br />
    <div>M16&nbsp;PS421&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M16&nbsp;&nbsp;Display&nbsp;PS421&nbsp;Status</strong>
      </div>
      <div>Displays&nbsp;status&nbsp;of&nbsp;the&nbsp;local&nbsp;connected&nbsp;PS421</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;on&nbsp;BSC421&nbsp;if&nbsp;if&nbsp;PS421&nbsp;is&nbsp;configured!</div>
      <div>M16</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M16/AL&nbsp;&nbsp;Display&nbsp;PS421&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>Displays&nbsp;Alarm&nbsp;Flags&nbsp;for&nbsp;the&nbsp;local&nbsp;connected&nbsp;PS421</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;on&nbsp;BSC421&nbsp;if&nbsp;if&nbsp;PS421&nbsp;is&nbsp;configured!</div>
      <div>M16/AL</div>
      <div />
      <div />
    </div>
    <h2>Debug Queue/File</h2>
    <br />
    <div>M46&nbsp;DEBUG&nbsp;QUEUE/FILE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M46&nbsp;&nbsp;Display&nbsp;Debug&nbsp;Queue/File&nbsp;status</strong>
      </div>
      <div>M46</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M46/C&nbsp;&nbsp;Clear&nbsp;Debug&nbsp;Queue</strong>
      </div>
      <div>M46/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M46/S&nbsp;&nbsp;Save&nbsp;Debug&nbsp;Queue&nbsp;in&nbsp;File</strong>
      </div>
      <div>Debug&nbsp;is&nbsp;save&nbsp;in&nbsp;...\Data\Debug.txt</div>
      <div>M46/S</div>
      <div />
      <div />
    </div>
    <h2>GUI OM Command</h2>
    <br />
    <div>M50&nbsp;GUI&nbsp;OM&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M50&nbsp;&nbsp;Display&nbsp;Last&nbsp;GUI&nbsp;OM&nbsp;Command/Response</strong>
      </div>
      <div>To&nbsp;be&nbsp;used&nbsp;for&nbsp;Software&nbsp;development&nbsp;only</div>
      <div>M50</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M50/cc..c&nbsp;&nbsp;Execute&nbsp;GUI&nbsp;OM&nbsp;Command</strong>
      </div>
      <div>Writes&nbsp;the&nbsp;OM&nbsp;Command&nbsp;to&nbsp;the&nbsp;GUI&nbsp;Shared&nbsp;Memory.</div>
      <div>Multicommands&nbsp;separated&nbsp;with&nbsp;the&nbsp;'|'&nbsp;character&nbsp;are&nbsp;supported.</div>
      <div>
        As&nbsp;'|'&nbsp;however&nbsp;can&nbsp;not&nbsp;been&nbsp;sent&nbsp;as&nbsp;it&nbsp;will&nbsp;be&nbsp;interpreted&nbsp;of&nbsp;the&nbsp;OM&nbsp;Program,
      </div>
      <div>
        the&nbsp;character&nbsp;'^'&nbsp;shall&nbsp;be&nbsp;used&nbsp;instead.&nbsp;The&nbsp;OM&nbsp;F50&nbsp;command&nbsp;interpreter&nbsp;will&nbsp;modify
      </div>
      <div>'^'&nbsp;to&nbsp;'|'&nbsp;before&nbsp;it&nbsp;is&nbsp;written.</div>
      <div>To&nbsp;be&nbsp;used&nbsp;for&nbsp;Software&nbsp;development&nbsp;only</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;OM&nbsp;Command</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴────────────────────────────────────────────────────────────────────────</div>
      <div>M50/_________________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>CPLD</h2>
    <br />
    <div>M62&nbsp;CPLD</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M62&nbsp;&nbsp;Display&nbsp;CPLD&nbsp;Version</strong>
      </div>
      <div>M62</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M62/HWSIG&nbsp;&nbsp;Display&nbsp;CPLD&nbsp;Hardware&nbsp;Signature</strong>
      </div>
      <div>M62/HWSIG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M62/RAM&nbsp;&nbsp;Read&nbsp;CPLD&nbsp;Test&nbsp;RAM</strong>
      </div>
      <div>M62/RAM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M62/RAM/hhhh&nbsp;&nbsp;Write/Read&nbsp;CPLD&nbsp;Test&nbsp;RAM</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Value&nbsp;to&nbsp;write&nbsp;(0000..hhhh&nbsp;hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>M62/RAM/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M62/INPORT&nbsp;&nbsp;Display&nbsp;Input&nbsp;Port</strong>
      </div>
      <div>M62/INPORT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M62/ALPORT&nbsp;&nbsp;Display&nbsp;Alarm&nbsp;Port</strong>
      </div>
      <div>M62/ALPORT</div>
      <div />
      <div />
    </div>
    <h2>CPU load</h2>
    <br />
    <div>M66&nbsp;&nbsp;CPU&nbsp;LOAD&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M66&nbsp;&nbsp;Display&nbsp;CPU&nbsp;load</strong>
      </div>
      <div>M66</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M66/C&nbsp;&nbsp;Clear&nbsp;CPU&nbsp;Load&nbsp;Peak&nbsp;Hold</strong>
      </div>
      <div>M66/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M66/T&nbsp;&nbsp;Display&nbsp;1msec.&nbsp;Timer</strong>
      </div>
      <div>M66/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M66/Q&nbsp;&nbsp;Display&nbsp;QPC&nbsp;Timer</strong>
      </div>
      <div>M66/Q</div>
      <div />
      <div />
    </div>
    <h2>PS421 Program Download</h2>
    <br />
    <div>M68&nbsp;&nbsp;PS421&nbsp;PROGRAM&nbsp;DOWNLOAD</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M68&nbsp;&nbsp;Display&nbsp;Program&nbsp;Download&nbsp;Status</strong>
      </div>
      <div>M68</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M68/PS421&nbsp;&nbsp;Display&nbsp;PS421&nbsp;Program&nbsp;Download&nbsp;Status</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;with&nbsp;BSC421&nbsp;Hardware</div>
      <div>M68/PS421</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M68/PS421/+&nbsp;&nbsp;Start&nbsp;PS421&nbsp;Program&nbsp;Download</strong>
      </div>
      <div>1)&nbsp;Reads&nbsp;...\PGM\PS421\PS421.HEX&nbsp;file&nbsp;from&nbsp;disk</div>
      <div>
        2)&nbsp;Sends&nbsp;68/BOOT&nbsp;command&nbsp;to&nbsp;PS421&nbsp;to&nbsp;restart&nbsp;in&nbsp;boot&nbsp;mode
      </div>
      <div>3)&nbsp;Dowloads&nbsp;Hex&nbsp;Records&nbsp;to&nbsp;PS421</div>
      <div>4)&nbsp;Restarts&nbsp;PS421&nbsp;again</div>
      <div>NB:&nbsp;Boot&nbsp;Part&nbsp;of&nbsp;PS421&nbsp;software&nbsp;is&nbsp;NOT&nbsp;updated.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;Boot&nbsp;version&nbsp;can&nbsp;be&nbsp;displayed&nbsp;with&nbsp;the&nbsp;PS421&nbsp;'68'&nbsp;command.
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;with&nbsp;BSC421&nbsp;Hardware</div>
      <div>M68/PS421/+</div>
      <div />
      <div />
    </div>
    <h2>I/O Memory</h2>
    <br />
    <div>M69&nbsp;&nbsp;I/O&nbsp;MEMORY</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M69&nbsp;&nbsp;Display&nbsp;I/O&nbsp;Memory&nbsp;Configuration</strong>
      </div>
      <div>M69</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M69/hhhh/1&nbsp;&nbsp;Read&nbsp;I/O&nbsp;Memory&nbsp;Byte</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Address&nbsp;Offset&nbsp;(0000..hhhh&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>M69/____/1</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M69/hhhh/2&nbsp;&nbsp;Read&nbsp;I/O&nbsp;Memory&nbsp;16-bit&nbsp;word</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Address&nbsp;Offset&nbsp;(0000..hhhh&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>M69/____/2</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M69/hhhh/hh&nbsp;&nbsp;Write&nbsp;I/O&nbsp;Memory&nbsp;Byte</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Address&nbsp;Offset&nbsp;(0000..hhhh&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Value&nbsp;to&nbsp;write&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴─</div>
      <div>M69/____/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M69/hhhh/hhhh&nbsp;&nbsp;Write&nbsp;I/O&nbsp;Memory&nbsp;16-bit&nbsp;word</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Address&nbsp;Offset&nbsp;(0000..hhhh&nbsp;hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Value&nbsp;to&nbsp;write&nbsp;(0000..FFFF&nbsp;hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴───</div>
      <div>M69/____/____</div>
      <div />
      <div />
    </div>
    <h2>System configuration</h2>
    <br />
    <div>M70&nbsp;MAIN&nbsp;CONFIGURATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M70&nbsp;&nbsp;Display&nbsp;last&nbsp;File&nbsp;Save&nbsp;result</strong>
      </div>
      <div>M70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M70/SAVE&nbsp;&nbsp;Save&nbsp;Main&nbsp;Configuration</strong>
      </div>
      <div>Note&nbsp;that&nbsp;Main&nbsp;Configuration&nbsp;changes&nbsp;are&nbsp;saved&nbsp;automatically</div>
      <div>M70/SAVE</div>
      <div />
      <div />
    </div>
    <h2>Main configurations</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M71&nbsp;&nbsp;Display&nbsp;Main&nbsp;Configuration</strong>
      </div>
      <div>M71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M71/DESCR/cc..c&nbsp;&nbsp;Change&nbsp;description</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Desctiption</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────────────────────</div>
      <div>M71/DESCR/_______________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M71/IPADDR/ccc/...&nbsp;&nbsp;Change&nbsp;IP&nbsp;address</strong>
      </div>
      <div>
        Note,&nbsp;that&nbsp;it&nbsp;shall&nbsp;match&nbsp;the&nbsp;IP&nbsp;Address&nbsp;for&nbsp;an&nbsp;Ethernet&nbsp;adapter.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;LAN:&nbsp;&nbsp;LAN&nbsp;Local&nbsp;Address
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;WAN:&nbsp;&nbsp;WAN&nbsp;Local&nbsp;Address
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴──────────────</div>
      <div>M71/IPADDR/___/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M71/POS/GPS/s&nbsp;&nbsp;Change&nbsp;Position&nbsp;update&nbsp;from&nbsp;GPS</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Position&nbsp;update&nbsp;from&nbsp;GPS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Allow&nbsp;GPS&nbsp;to&nbsp;update&nbsp;position&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;GPS&nbsp;not&nbsp;allowed&nbsp;to&nbsp;change&nbsp;position
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>M71/POS/GPS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M71/POS/...&nbsp;&nbsp;Change&nbsp;Position</strong>
      </div>
      <div>Default&nbsp;position&nbsp;when&nbsp;no&nbsp;GPS&nbsp;signal&nbsp;is&nbsp;received.</div>
      <div>
        Shall&nbsp;be&nbsp;setup&nbsp;only,&nbsp;when&nbsp;no&nbsp;GPS&nbsp;antenna&nbsp;is&nbsp;attached&nbsp;to&nbsp;either&nbsp;a&nbsp;connected&nbsp;BS421
      </div>
      <div>or&nbsp;the&nbsp;BSC412.</div>
      <div>
        Parameter&nbsp;is&nbsp;overwritten,&nbsp;when&nbsp;a&nbsp;valid&nbsp;GPS&nbsp;position&nbsp;is&nbsp;received.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;degree&nbsp;(00..89)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;minutes&nbsp;(00..59)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;seconds&nbsp;(00.00..59.99)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;N/S&nbsp;(N&nbsp;or&nbsp;S)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Longitude&nbsp;degree&nbsp;(000..179)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Longitude&nbsp;minutes&nbsp;(00..59)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Longitude&nbsp;seconds&nbsp;(00.00..59.99)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Longitude&nbsp;E/W&nbsp;(E&nbsp;or&nbsp;W)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;┴────┴&nbsp;┴──&nbsp;┴─&nbsp;┴────┴</div>
      <div>M71/POS/__:__:__.___/___:__:__.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M71/GPSTIME/s&nbsp;&nbsp;Change&nbsp;Date/Time&nbsp;update&nbsp;from&nbsp;GPS</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;with&nbsp;external&nbsp;BSS</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;GPS&nbsp;Date/Time&nbsp;update
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Windows&nbsp;Date/Time&nbsp;updated&nbsp;from&nbsp;GPS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Windows&nbsp;Date/Time&nbsp;not&nbsp;updated&nbsp;from&nbsp;GPS
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>M71/GPSTIME/_</div>
      <div />
      <div />
    </div>
    <h2>PS421 configuration</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M76&nbsp;&nbsp;Display&nbsp;PS421&nbsp;Configuration</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;BSC421!</div>
      <div>M76</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M76/PS421&nbsp;&nbsp;Setup&nbsp;PS421&nbsp;configuration</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;BSC421!</div>
      <div>M76/PS421</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M76/REMOVE&nbsp;&nbsp;Remove&nbsp;PS&nbsp;configuration</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;BSC421!</div>
      <div>M76/REMOVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M76/PSADDR/nn&nbsp;&nbsp;Change&nbsp;PS421&nbsp;Address</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;BSC421!</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS421&nbsp;Address&nbsp;(11..47)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>M76/PSADDR/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M76/BSCn/...&nbsp;&nbsp;Change&nbsp;BSC&nbsp;IP&nbsp;address</strong>
      </div>
      <div>IP&nbsp;Addresses&nbsp;of&nbsp;the&nbsp;BSC&nbsp;for&nbsp;PS421&nbsp;status&nbsp;forwarding</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;&nbsp;BSC1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;&nbsp;BSC2</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────</div>
      <div>M76/BSC_/___.___.___.___</div>
      <div />
      <div />
    </div>
    <h2>Factory configuration commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M90&nbsp;&nbsp;Display&nbsp;Factory&nbsp;Configuration&nbsp;status</strong>
      </div>
      <div>M90</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M90/FACTORYUNLOCK&nbsp;&nbsp;Select&nbsp;Factory&nbsp;Configuration&nbsp;unlock</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UNLOCK</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>M90/FACTORY______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M90-&nbsp;&nbsp;Deselect&nbsp;Factory&nbsp;Configuration&nbsp;unlock</strong>
      </div>
      <div>M90-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M90/SAVE&nbsp;&nbsp;Save&nbsp;Factory&nbsp;Configuration</strong>
      </div>
      <div>Note,&nbsp;that&nbsp;all&nbsp;Factory&nbsp;Configuration&nbsp;changes</div>
      <div>are&nbsp;saved&nbsp;automatically</div>
      <div>M90/SAVE</div>
      <div />
      <div />
    </div>
    <h2>Hardware Versions</h2>
    <br />
    <div>M91&nbsp;&nbsp;HARDWARE&nbsp;VERSIONS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M91&nbsp;&nbsp;Display&nbsp;Hardware&nbsp;Versions</strong>
      </div>
      <div>M91</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M91/ITEM/cc..c&nbsp;&nbsp;Change&nbsp;item&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Item&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>M91/ITEM/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M91/SER/cc..c&nbsp;&nbsp;Change&nbsp;serial&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>M91/SER/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M91/VER/n.nn&nbsp;&nbsp;Change&nbsp;version&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Version&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>M91/VER/_.__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M91/REV/n&nbsp;&nbsp;Change&nbsp;revision&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Revision&nbsp;number&nbsp;(00..99)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>M91/REV/__</div>
      <div />
      <div />
      <div />
    </div>
    <h2>BSC412 Factory Configuration</h2>
    <br />
    <div>M92&nbsp;&nbsp;BSC412&nbsp;FACTORY&nbsp;CONFIGURATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M92&nbsp;&nbsp;Display&nbsp;BSC412&nbsp;Factory&nbsp;Configuration</strong>
      </div>
      <div>M92</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M92/GPSRX/c&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;type</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;GPS&nbsp;RX&nbsp;Type</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;iTrax03</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;6:&nbsp;IT600</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;8:&nbsp;EVA-M8M</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>M92/GPSRX/_</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Restart Commands</h2>
    <br />
    <div>M99&nbsp;&nbsp;RESTART&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M99&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>M99</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M99/STOP&nbsp;&nbsp;Stop&nbsp;BSC&nbsp;Service</strong>
      </div>
      <div>Makes&nbsp;a&nbsp;normal&nbsp;shutdown&nbsp;of&nbsp;the&nbsp;service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>NB:&nbsp;This&nbsp;requires&nbsp;a&nbsp;Remote&nbsp;Desktop&nbsp;connection&nbsp;to&nbsp;get&nbsp;the</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;BSC&nbsp;Service&nbsp;restarted!</div>
      <div>M99/STOP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M99/RESTART&nbsp;&nbsp;Restart&nbsp;BSC&nbsp;service</strong>
      </div>
      <div>Makes&nbsp;a&nbsp;restart&nbsp;of&nbsp;the&nbsp;BSC&nbsp;Service</div>
      <div>1)&nbsp;Copy&nbsp;"Share"&nbsp;content&nbsp;from&nbsp;"Active"</div>
      <div>2)&nbsp;Stop&nbsp;BSC&nbsp;Service</div>
      <div>3)&nbsp;Copy&nbsp;BSC.exe&nbsp;to&nbsp;BSC_.exe</div>
      <div>4)&nbsp;Start&nbsp;BSC&nbsp;Service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>M99/RESTART</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M99/UPDATE/RESTART&nbsp;&nbsp;Restart&nbsp;BSC&nbsp;service&nbsp;with&nbsp;Update</strong>
      </div>
      <div>Updates&nbsp;and&nbsp;Restarts&nbsp;BSC&nbsp;Service</div>
      <div>1)&nbsp;Update&nbsp;"Active"&nbsp;with&nbsp;content&nbsp;of&nbsp;"Update"</div>
      <div>2)&nbsp;Copy&nbsp;"Share"&nbsp;content&nbsp;from&nbsp;"Active"</div>
      <div>3)&nbsp;Stop&nbsp;BSC&nbsp;Service</div>
      <div>4)&nbsp;Copy&nbsp;BSC.exe&nbsp;to&nbsp;BSC_.exe</div>
      <div>5)&nbsp;Start&nbsp;BSC&nbsp;Service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>M99/UPDATE/RESTART</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          M99/BACKUP/UPDATE/RESTART&nbsp;&nbsp;Restart&nbsp;BSC&nbsp;service&nbsp;with&nbsp;Backup&nbsp;and&nbsp;Update
        </strong>
      </div>
      <div>Backup,&nbsp;Updates&nbsp;and&nbsp;Restarts&nbsp;BSC&nbsp;Service</div>
      <div>1)&nbsp;Backup&nbsp;"Active"&nbsp;to&nbsp;"Backup"</div>
      <div>2)&nbsp;Update&nbsp;"Active"&nbsp;with&nbsp;content&nbsp;of&nbsp;"Update"</div>
      <div>3)&nbsp;Copy&nbsp;"Share"&nbsp;content&nbsp;from&nbsp;"Active"</div>
      <div>4)&nbsp;Stop&nbsp;BSC&nbsp;Service</div>
      <div>5)&nbsp;Copy&nbsp;BSC.exe&nbsp;to&nbsp;BSC_.exe</div>
      <div>6)&nbsp;Start&nbsp;BSC&nbsp;Service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>M99/BACKUP/UPDATE/RESTART</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>M99/RESTORE/RESTART&nbsp;&nbsp;Restart&nbsp;BSC&nbsp;service&nbsp;with&nbsp;Restore</strong>
      </div>
      <div>Restores&nbsp;and&nbsp;Restarts&nbsp;BSC&nbsp;Service</div>
      <div>1)&nbsp;Restores&nbsp;"Backup"&nbsp;to&nbsp;"Active"</div>
      <div>2)&nbsp;Copy&nbsp;"Share"&nbsp;content&nbsp;from&nbsp;"Active"</div>
      <div>3)&nbsp;Stop&nbsp;BSC&nbsp;Service</div>
      <div>4)&nbsp;Copy&nbsp;BSC.exe&nbsp;to&nbsp;BSC_.exe</div>
      <div>5)&nbsp;Start&nbsp;BSC&nbsp;Service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>M99/RESTORE/RESTART</div>
      <div />
    </div>
    <br />
  </>
);

export default memo(MainPage) as typeof MainPage;
