import React from 'react';
import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';

export const Tabs = styled(AntTabs)`
  &.ant-tabs,
  &.ant-tabs > .ant-tabs-bar,
  &.ant-tabs > .ant-tabs-bar > .ant-tabs-nav-wrap,
  &.ant-tabs > .ant-tabs-bar > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll {
    overflow: visible;
  }

  &.ant-tabs > .ant-tabs-bar > .ant-tabs-nav-container {
    bottom: -3px;
  }

  &.ant-tabs-line {
    text-align: ${props => (props.centered ? 'center' : 'left')};
  }

  .ant-tabs-bar {
    border-bottom: 4px solid #f3f3f3;

    @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
      width: 100%;
      border-bottom-width: 3px;
    }
  }

  .ant-tabs-top-bar {
    display: inline-block;
    margin: 0 0 4px;
    padding: 0 ${props => (props.centered ? '20px' : 0)};
    margin-bottom: 1rem;

    @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
      padding: 0 ${props => (props.centered ? '10px' : 0)};
    }
  }

  .ant-tabs-content {
    text-align: left;
  }

  .ant-tabs-tab {
    &,
    &.ant-tabs-tab-active {
      font-family: ${props => props.theme['font-family']};
      font-size: 1.2rem;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #0081c7;

      @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
        margin-right: 0.5rem;
      }
    }
  }

  .ant-tabs-tab-active {
    color: #0081c7 !important;
  }

  .ant-tabs-ink-bar {
    background-color: #0081c7 !important;
    height: 4px;

    @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
      height: 3px;
      bottom: 2px;
    }
  }
`;

export const TabPane = styled(AntTabs.TabPane)``;
