import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import pick from 'lodash/pick';
import { Divider } from 'antd';
import { updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { RestrictedArea, useNavigate } from '@fjedi/react-router-helpers';
import { useCreateUserMutation, User, UserInput, useUpdateUserMutation } from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import RoleSelector from 'src/components/common/role-selector';
import MainInfo from 'src/components/routes/private/admin/users/profile/form-main';
import AdditionalInfo from 'src/components/routes/private/admin/users/profile/form-additional';
import { DEFAULT_PHONE_NUMBER_PREFIX } from 'src/components/ui-kit/input';

const Container = styled.div`
  max-width: 100%;
  margin: auto;

  .ant-form-item .ant-form-item-control .ant-input {
    &#comments {
      border-radius: 0.5rem;
    }
  }

  > form > div {
    &:first-child {
      flex-grow: 1;
      margin-right: 1rem;
    }
  }

  > form > section {
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
`;

const ProfileDivider = styled(Divider)`
  margin-top: 1.2rem;
  margin-bottom: 0.75rem;
`;

export type UserProfileCardProps = {
  data?: User;
  userId?: string;
};

const UserProfileCard: FC<UserProfileCardProps> = ({ data, userId }) => {
  const [form] = Form.useForm();
  const { resetFields } = form;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCompleted = useCallback(() => {
    resetFields();
    navigate(-1);
  }, [resetFields, navigate]);
  const onError = useApolloError();
  const [createUser, { loading: creating }] = useCreateUserMutation({
    onCompleted,
    onError,
    update: updateAfterMutation('User', 'users'),
  });
  const [updateUser, { loading: updating }] = useUpdateUserMutation({
    onCompleted,
    onError,
  });
  const loading = creating || updating;

  const onSubmit = useCallback(
    async formData => {
      try {
        logger('Profile.onSubmit', { formData, form });
        // eslint-disable-next-line no-param-reassign
        delete formData.repeatPassword;
        const onlyChangedFields = true;
        const input = parseFormData(form, formData, onlyChangedFields) as Partial<UserInput>;
        logger('UserProfile.onSubmit', { input });
        if (input.phoneNumber) {
          const r = /\D+/gi;
          const phoneNumber = input.phoneNumber.replace(r, '');
          input.phoneNumber = !phoneNumber || phoneNumber === DEFAULT_PHONE_NUMBER_PREFIX ? null : `+${phoneNumber}`;
        }
        logger('Create/Update user submit', {
          formData,
          input,
        });
        if (!userId) {
          await createUser({
            variables: {
              input,
            },
          });
        } else {
          await updateUser({
            variables: {
              id: userId,
              input,
            },
          });
        }
      } catch (error: unknown) {
        logger('Failed to create/update user', error as Error);
      }
    },
    [userId, form, createUser, updateUser],
  );

  return (
    <Container>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={onSubmit}
        initialValues={pick(data, [
          'firstName',
          'lastName',
          'middleName',
          'password',
          'role',
          'email',
          'phoneNumber',
          'comments',
        ])}>
        <Row>
          <Col md={11}>
            <MainInfo />
          </Col>
          <Col md={{ span: 11, offset: 2 }}>
            <AdditionalInfo userId={userId!} />
          </Col>
        </Row>

        <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
          <FormItem shouldUpdate>
            {() => (
              <SubmitButton style={{ margin: '0.75rem auto 1rem' }} loading={loading}>
                {data ? t('Save') : t('Create')}
              </SubmitButton>
            )}
          </FormItem>
        </RestrictedArea>
      </Form>
    </Container>
  );
};

UserProfileCard.displayName = 'UserProfileCard';

export default memo(UserProfileCard);
