import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Divider } from 'antd';
import { ViewerContext, RestrictedArea, ViewerRoleContext } from '@fjedi/react-router-helpers';
import { FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import RoleSelector from 'src/components/common/role-selector';
import { Viewer, ViewerRole } from 'src/graphql/generated';

const ProfileDivider = styled(Divider)`
  margin-top: 1.2rem;
  margin-bottom: 0.75rem;
`;

const UserProfileMain: FC = () => {
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext) as Viewer;
  const viewerRole = useContext(ViewerRoleContext);
  const isAdmin = viewerRole === ViewerRole.Admin;

  return (
    <>
      <RestrictedArea areaType="block" currentRole={viewer.role} allowedRoles={['ADMIN']}>
        <FormItem name="role" label={t('Role')} rules={[{ required: true }]}>
          <RoleSelector />
        </FormItem>
      </RestrictedArea>
      <FormItem
        label={t('Email')}
        name="email"
        rules={[
          {
            required: true,
            message: t('Please fill this field') as string,
          },
        ]}>
        <Input disabled={!isAdmin} />
      </FormItem>
      <FormItem
        label={t('First name')}
        name="firstName"
        rules={[
          {
            required: true,
            message: t('Please fill this field') as string,
          },
        ]}>
        <Input disabled={!isAdmin} />
      </FormItem>
      <FormItem label={t('Last name')} name="lastName">
        <Input disabled={!isAdmin} />
      </FormItem>
      <FormItem label={t('Middle name')} name="middleName">
        <Input disabled={!isAdmin} />
      </FormItem>
    </>
  );
};

export default memo(UserProfileMain);
