/* eslint-disable react/no-danger */
// React
import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { pure, compose } from 'recompose';
import { Popconfirm as AntPopconfirm } from 'antd';

const GlobalStyles = createGlobalStyle`
  .ant-popover .ant-popover-inner {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

const Container = styled(AntPopconfirm)``;

//
const PopconfirmComponent = ({ children, ...props }) => (
  <>
    <GlobalStyles />
    <Container {...props}>{children}</Container>
  </>
);

PopconfirmComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  placement: PropTypes.string,
};
PopconfirmComponent.defaultProps = {
  className: '',
  placement: 'bottom',
};

export default compose(pure)(PopconfirmComponent);
