import { useLocation, useNavigate, useParams } from '@fjedi/react-router-helpers';
import omit from 'lodash/omit';
import React, { FC, memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stopEventPropagation } from 'src/functions';
import { formatDate } from 'src/helpers/time';
import { logger, useApolloError } from '@fjedi/graphql-react-components';
import {
  CreateNodeSubscriberMutationVariables,
  UpdateNodeSubscriberMutationVariables,
  useCreateNodeSubscriberMutation,
  useNodeProfileQuery,
  useNodeSubscriberChangedSubscription,
  useNodeSubscriberQuery,
  useRemoveNodeSubscriberMutation,
  useUpdateNodeSubscriberMutation,
} from 'src/graphql/generated';
// CSS
// Components
import { Divider, Empty } from 'antd';
import cn from 'classnames';
import Button from 'src/components/ui-kit/buttons';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import Checkbox from 'src/components/ui-kit/checkbox';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import { Col, Row } from 'src/components/ui-kit/grid';
import { Input, InputNumber } from 'src/components/ui-kit/input';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import CustomSelect from 'src/components/ui-kit/select';
import Spinner from 'src/components/ui-kit/spinner';
import {
  callTrfOptions,
  DrawerViewMode,
  getTsiFromMobile,
  ssiKindOptions,
  SsiType,
  trustKindOptions,
} from './drawer-helpers';
import { Actions, Container, Title, RSSIContainer } from './drawer.sc';
import RegistrDataForm from './registr-data-form';

// eslint-disable-next-line react/jsx-props-no-spreading
const Select: typeof CustomSelect = props => <CustomSelect fieldNames={{ label: 'text' }} {...props} />;
//
export type SubscriberDrawerProps = {};
//
const SubscriberDrawer: FC<SubscriberDrawerProps> = () => {
  const { subscriberId } = useParams();
  const { t } = useTranslation();
  const onError = useApolloError();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onDrawerClose = useCallback(() => navigate('../ssi'), [navigate]);
  //
  const mode = useMemo(() => {
    if (!subscriberId) {
      return null;
    }

    if (['new', 'create'].includes(subscriberId)) {
      return DrawerViewMode.Create;
    }

    if (pathname.endsWith(`${subscriberId}/edit`) || pathname.endsWith(`${subscriberId}/edit/`)) {
      return DrawerViewMode.Edit;
    }

    if (pathname.endsWith(subscriberId) || pathname.endsWith(`${subscriberId}/`)) {
      return DrawerViewMode.View;
    }

    return DrawerViewMode.View;
  }, [subscriberId, pathname]);
  //
  const [form] = Form.useForm();
  const { resetFields } = form;
  const [isEmpty, setEmpty] = useState(false);
  const [ssiType, setSsiType] = useState<SsiType>(SsiType.Unified);
  const readonly = useMemo(() => mode === DrawerViewMode.View, [mode]);
  const registr = useMemo(() => !!subscriberId && pathname.endsWith('registr'), [pathname, subscriberId]);

  const onValuesChange = useCallback((changedValues: any) => {
    logger('Subscriber.onValuesChange', {
      changedValues,
    });

    if ('ssiKind' in changedValues) {
      setSsiType(changedValues.ssiKind);
    }
  }, []);

  const { data, loading } = useNodeSubscriberQuery({
    variables: { id: subscriberId! },
    skip: !subscriberId,
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      console.log('NodeSubscriber', res?.nodeSubscriber);
    },
  });
  useNodeSubscriberChangedSubscription({
    variables: { filter: { ssi: data?.nodeSubscriber?.ssi } },
    skip: !data?.nodeSubscriber?.ssi,
  });

  const parentProfileNo = useMemo(() => data?.nodeSubscriber?.profileNo, [data?.nodeSubscriber?.profileNo]);
  const { data: parentProfileData, loading: parentProfileLoading } = useNodeProfileQuery({
    variables: { id: parentProfileNo },
    skip: !parentProfileNo || !registr,
    onError,
  });
  const registrData = useMemo(
    () => ({ ...(parentProfileData?.nodeProfile ?? {}), ...(data?.nodeSubscriber ?? {}) }),
    [data?.nodeSubscriber, parentProfileData?.nodeProfile],
  );

  const [createNodeSubscriber, { loading: creating }] = useCreateNodeSubscriberMutation({
    onError,
    onCompleted() {
      resetFields();
      onDrawerClose();
    },
  });
  const [updateNodeSubscriber, { loading: updating }] = useUpdateNodeSubscriberMutation({
    onError,
    onCompleted() {
      resetFields();
      onDrawerClose();
    },
  });
  const [removeNodeSubscriber, { loading: removing }] = useRemoveNodeSubscriberMutation({
    onError,
    variables: { id: subscriberId! },
    onCompleted(res) {
      if (res?.removeNodeSubscriber?.id) {
        onDrawerClose();
      }
    },
  });
  //
  const isLoading = useMemo(
    () => loading || updating || removing || parentProfileLoading,
    [loading, parentProfileLoading, removing, updating],
  );
  //
  const onSubmit = useCallback(
    (formData: any) => {
      const onlyChangedFields = true;
      let input = parseFormData(form, formData, onlyChangedFields);
      if (typeof input.tsi === 'string') {
        const tsiData = input.tsi.split(':');
        input = { mobile: { tsi: { mcc: tsiData[0], mnc: tsiData[1], ssi: tsiData[2] } }, ...omit(input, 'tsi') };
      }
      if ('hiddenPhoneBook' in input) {
        input = { flags: { hiddenPhoneBook: input.hiddenPhoneBook }, ...omit(input, 'hiddenPhoneBook') };
      }
      if ('tei' in input) {
        input.mobile = { ...(input.mobile ?? {}), tei: input.tei };
        input = omit(input, 'tei');
      }
      if ('simId' in input) {
        input.mobile = { ...(input.mobile ?? {}), simId: input.simId };
        input = omit(input, 'simId');
      }
      if ('securitySetStatus' in input) {
        input.mobile = { ...(input.mobile ?? {}), securitySetStatus: input.securitySetStatus };
        input = omit(input, 'securitySetStatus');
      }
      if ('callTrfKind' in input) {
        input.mobile = { ...(input.mobile ?? {}), callTrfKind: input.callTrfKind };
        input = omit(input, 'callTrfKind');
      }
      if ('callTrfNo' in input) {
        input.mobile = { ...(input.mobile ?? {}), callTrfNo: input.callTrfNo };
        input = omit(input, 'callTrfNo');
      }
      if ('callTrfTime' in input) {
        input.mobile = { ...(input.mobile ?? {}), callTrfTime: input.callTrfTime };
        input = omit(input, 'callTrfTime');
      }
      if ('authDisable' in input) {
        input.mobile = {
          ...(input.mobile ?? {}),
          flags: { ...((input.mobile as Record<'flags' | string, any>)?.flags ?? {}), authDisable: !input.authDisable },
        };
        input = omit(input, 'authDisable');
      }

      if (typeof input.groupText === 'string') {
        input.group = {
          ...(input.group ?? {}),
          groupText: input.groupText,
        };
        input = omit(input, 'groupText');
      }

      if ('airInterfaceEncryptionDisable' in input) {
        // If current ssi is a GROUP
        if (ssiType === 2) {
          input.group = {
            ...(input.group ?? {}),
            flags: {
              ...((input.group as Record<'flags' | string, any>)?.flags ?? {}),
              airInterfaceEncryptionDisable: !input.airInterfaceEncryptionDisable,
            },
          };
        } else {
          input.mobile = {
            ...(input.mobile ?? {}),
            flags: {
              ...((input.mobile as Record<'flags' | string, any>)?.flags ?? {}),
              airInterfaceEncryptionDisable: !input.airInterfaceEncryptionDisable,
            },
          };
        }
        input = omit(input, 'airInterfaceEncryptionDisable');
      }
      //
      logger('Drawer form submit', {
        formData,
        input,
      });
      if (subscriberId === 'new') {
        const variables: CreateNodeSubscriberMutationVariables = {
          input,
        };
        createNodeSubscriber({ variables }).catch(logger);
        return;
      }
      const variables: UpdateNodeSubscriberMutationVariables = {
        id: subscriberId!,
        input,
      };
      updateNodeSubscriber({ variables }).catch(logger);
    },
    [createNodeSubscriber, form, subscriberId, updateNodeSubscriber, ssiType],
  );

  const onReset = useCallback(() => {
    form.resetFields();

    if (mode === DrawerViewMode.Edit) {
      navigate(`./`);
    }

    if (mode === DrawerViewMode.Create) {
      navigate(-1);
    }
  }, [form, mode, navigate]);

  const nodeFormInitialValues = useMemo(() => {
    if (!subscriberId || isLoading || isEmpty || registr) {
      return null;
    }

    if (mode === DrawerViewMode.Create && subscriberId === 'new') {
      return {
        ssi: '',
        ssiKind: 1,
        userNo: '',
        presentationNo: '',
        ipAddress: '',
        profileNo: '',
        trustKind: 0,
        description: '',
        hiddenPhoneBook: false,
        tsi: '',
        tei: '',
        simId: '',
        securitySetStatus: '',
        callTrfKind: 0,
        callTrfNo: '',
        callTrfTime: '',
        authDisable: false,
        airInterfaceEncryptionDisable: false,
      };
    }

    const {
      ssi,
      ssiKind,
      userNo,
      presentationNo,
      ipAddress,
      profileNo,
      trustKind,
      description,
      flags = {},
      mobile = {},
      group = {},
      rssi = {},
    } = data?.nodeSubscriber ?? {};

    const { flags: mobileFlags, tei, simId, securitySetStatus, callTrfKind, callTrfNo, callTrfTime } = mobile ?? {};
    const { authDisable, airInterfaceEncryptionDisable: mobileAirInterfaceEncryptionDisable } = mobileFlags ?? {};
    const { flags: groupFlags, groupIncludeTable, groupText, timeout: groupTimeout } = group ?? {};
    const {
      restriction,
      callOwnership,
      keyedInNumberAllowed,
      groupCallIncludeTableEnable,
      airInterfaceEncryptionDisable: groupAirInterfaceEncryptionDisable,
    } = groupFlags ?? {};
    const { hiddenPhoneBook } = flags;

    setSsiType(ssiKind);

    return {
      ssi,
      ssiKind,
      userNo,
      presentationNo,
      ipAddress,
      profileNo,
      trustKind,
      description,
      hiddenPhoneBook,
      tsi: getTsiFromMobile(mobile),
      tei,
      simId: simId?.join('') ?? null,
      securitySetStatus,
      callTrfKind,
      callTrfNo,
      callTrfTime,
      authDisable: !authDisable,
      airInterfaceEncryptionDisable:
        ssiKind === 2 ? !groupAirInterfaceEncryptionDisable : !mobileAirInterfaceEncryptionDisable,
      //
      groupText,
      groupTimeout,
      groupIncludeTable,
    };
  }, [data?.nodeSubscriber, isEmpty, isLoading, mode, registr, subscriberId]);

  useLayoutEffect(() => {
    if (isEmpty || mode !== DrawerViewMode.Create) {
      form.resetFields();
    }

    if (mode === DrawerViewMode.Create) {
      form.resetFields();
      return () => setEmpty(false);
    }

    if (subscriberId && !isLoading) {
      if (!data) {
        setEmpty(true);
      }

      if (registr) {
        return undefined;
      }

      return () => setEmpty(prev => !prev && !!data && !nodeFormInitialValues);
    }

    return undefined;
  }, [data, form, isEmpty, isLoading, loading, mode, nodeFormInitialValues, registr, subscriberId]);

  const drawerTitle = useMemo(() => {
    switch (mode) {
      case DrawerViewMode.Edit:
        return t('SSI edit/delete');
      case DrawerViewMode.Create:
        return subscriberId === 'new' ? t('Create SSI') : t('Bulk Create SSI');
      case DrawerViewMode.View:
      default:
        if (registr) {
          return t('Registration information');
        }
        return t('SSI Info');
    }
  }, [mode, registr, subscriberId, t]);

  const registrDataForm = useMemo(() => <RegistrDataForm data={registrData!} />, [registrData]);

  const [uplinkForm] = Form.useForm();
  const rssi = useMemo(() => {
    if (!data?.nodeSubscriber?.rssi) {
      return {
        dBm: 'Нет данных',
        createdAt: 'Нет данных',
      };
    }
    const { dBm, createdAt } = data.nodeSubscriber.rssi;
    return {
      dBm: `-${dBm} dBm`,
      createdAt: formatDate(createdAt, 'HH:mm DD.MM.YYYY'),
    };
  }, [data]);
  useEffect(() => {
    uplinkForm.setFieldsValue(rssi);
  }, [uplinkForm, rssi]);

  return (
    <Container size="large" placement="right" closable={false} onClose={onDrawerClose} visible={!!subscriberId}>
      <Scrollbar className="drawer-content">
        <Title>
          <span>{drawerTitle}</span>

          {rssi && !registr && (
            <RSSIContainer>
              <Form form={uplinkForm} initialValues={rssi} layout="vertical" className={cn({ readonly: true })}>
                <Row>
                  <Col md={12}>
                    <FormItem name="dBm" label={t('UPLINK')} rules={[{ required: false }]}>
                      <Input readOnly />
                    </FormItem>
                  </Col>
                  <Col md={12}>
                    <FormItem name="createdAt" label={t('Последнее обновление')}>
                      <Input readOnly />
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </RSSIContainer>
          )}

          {readonly && !registr && (
            <LinkButton type="primary" to="./edit">
              {t('Изменить')}
            </LinkButton>
          )}
          {readonly && (
            <LinkButton type="primary" to="../ssi/new">
              {t('Доб./Копр.')}
            </LinkButton>
          )}
          {readonly && (
            <LinkButton type="primary" to="../ssi/create">
              {t('Cозд.пакет')}
            </LinkButton>
          )}
          {!registr && readonly && subscriberId && (
            <LinkButton type="primary" to="./registr">
              {t('Регистр.')}
            </LinkButton>
          )}
          {!!data && [DrawerViewMode.Edit, DrawerViewMode.View].includes(mode as DrawerViewMode) && (
            <Popconfirm
              placement="bottomLeft"
              title={`${t('Remove')}?`}
              onClick={stopEventPropagation}
              onConfirm={removeNodeSubscriber}>
              <Button loading={removing} type="primary" danger>
                {t('Remove')}
              </Button>
            </Popconfirm>
          )}
        </Title>
        <Spinner spinning={isLoading}>
          {isEmpty && <Empty description={t('Failed to fetch user data')} />}
          {!isEmpty && registr && registrDataForm}
          {!!nodeFormInitialValues && (
            <Form
              form={form}
              onFinish={onSubmit}
              onValuesChange={onValuesChange}
              initialValues={nodeFormInitialValues}
              layout="vertical"
              className={cn({ readonly })}>
              <Row>
                <Col md={12}>
                  <FormItem name="ssi" label={t('SSI')} rules={[{ required: true }]}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="ssiKind" label={t('Тип абонента')}>
                    <Select disabled={subscriberId !== 'new'} options={ssiKindOptions} />
                  </FormItem>
                </Col>

                <Divider />

                <Col md={12}>
                  <FormItem name="userNo" label={t('№ Абонента')} rules={[{ required: true }]}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="hiddenPhoneBook" valuePropName="checked" label=" ">
                    <Checkbox disabled={readonly} style={{ marginTop: '0.25rem' }}>
                      {t('Скрывать в Адресной книгу')}
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="description" label={t('Описание')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="trustKind" label={t('Уровень доверия')}>
                    <Select disabled={readonly} options={trustKindOptions} />
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem name="profileNo" label={t('№ Абонента')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>

                {[SsiType.Mobile, SsiType.Application, SsiType.Terminal, SsiType.DialIn].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="presentationNo" label={t('Отображаемый №')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {/* //! */}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem name="groupText" label={t('Group Text')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {/* //! */}
                {ssiType === SsiType.Unified && (
                  <Col md={12}>
                    <FormItem name="callPriority" label={t('Call priority')}>
                      <Select disabled={subscriberId !== 'new'} />
                    </FormItem>
                  </Col>
                )}

                {/* //! */}
                <Col md={12}>
                  <FormItem name="orgNo" label={t('Организация')}>
                    <Input readOnly={readonly} disabled={subscriberId !== 'new'} />
                  </FormItem>
                </Col>

                {[SsiType.Mobile, SsiType.Terminal].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="securitySetStatus" label={t('Ключ шиф')}>
                      <Input readOnly={readonly} disabled={subscriberId !== 'new'} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem name="groupTimeout" label={t('Group timeout [sec]')}>
                      <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                    </FormItem>
                  </Col>
                )}
                {[SsiType.Application, SsiType.DialIn].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="callTrfKind" label={t('Call transfer')}>
                      <Select disabled={readonly} options={callTrfOptions} />
                    </FormItem>
                  </Col>
                )}
                {/* //! */}
                {ssiType === SsiType.Unified && (
                  <Col md={12}>
                    <FormItem name="icon" label={t('Icon')}>
                      <Select disabled={subscriberId !== 'new'} />
                    </FormItem>
                  </Col>
                )}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="ipAddress" label={t('IP адрес')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {/* //! */}
                {[SsiType.Application, SsiType.Terminal, SsiType.DialIn].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="pin" label={t('PIN-код')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && <Col md={12} />}

                {[SsiType.Mobile, SsiType.Terminal].includes(ssiType) && <Col md={12} />}
                {/* //! */}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem name="groupIncludes" label={t('Group includes')}>
                      <Input readOnly={readonly} disabled={subscriberId !== 'new'} />
                    </FormItem>
                  </Col>
                )}
                {[SsiType.Application, SsiType.DialIn].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="callTrfNo" label={t('Transfer no.')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}

                {/* //! */}
                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="ipMask" label={t('IP маска')}>
                      <Input readOnly={readonly} disabled={subscriberId !== 'new'} />
                    </FormItem>
                  </Col>
                )}
                {[SsiType.Group, SsiType.Application, SsiType.Terminal, SsiType.DialIn].includes(ssiType) && (
                  <Col md={12} />
                )}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="authDisable" valuePropName="checked" label={t('Аутентификация')}>
                      <Checkbox disabled={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem
                      name="airInterfaceEncryptionDisable"
                      valuePropName="checked"
                      label={t('Шифрование радио')}>
                      <Checkbox disabled={readonly} />
                    </FormItem>
                  </Col>
                )}
                {[SsiType.Application, SsiType.DialIn].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="callTrfTime" label={t('Transfer time [sec]')}>
                      <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Terminal && (
                  <Col md={12}>
                    <FormItem name="callTrfKind" label={t('Call transfer')}>
                      <Select disabled={readonly} options={callTrfOptions} />
                    </FormItem>
                  </Col>
                )}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="tsi" label={t('TSI')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && <Col md={12} />}
                {[SsiType.Application, SsiType.Terminal].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="authDisable" valuePropName="checked" label={t('Аутентификация')}>
                      <Checkbox disabled={readonly} />
                    </FormItem>
                  </Col>
                )}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem
                      name="airInterfaceEncryptionDisable"
                      valuePropName="checked"
                      label={t('Шифрование радио')}>
                      <Checkbox disabled={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem name="groupRestricted" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Group Restricted (Group Permission Table used)')}</Checkbox>
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Application && (
                  <Col md={12}>
                    <FormItem name="groupRestricted" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Subscriber Register changes allowed')}</Checkbox>
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Terminal && (
                  <Col md={12}>
                    <FormItem name="callTrfNo" label={t('Transfer no.')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}

                {/* //! */}
                {[SsiType.Mobile, SsiType.Application, SsiType.Terminal].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="tei" label={t('TEI')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && <Col md={12} />}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="callTrfKind" label={t('Call transfer')}>
                      <Select disabled={readonly} options={callTrfOptions} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem name="keyedInNumberAllowed" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Keyed-in number allowed')}</Checkbox>
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Application && (
                  <Col md={12}>
                    <FormItem name="discreetListeningAllowed" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Discreet Listening allowed')}</Checkbox>
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Terminal && (
                  <Col md={12}>
                    <FormItem name="callTrfTime" label={t('Transfer time [sec]')}>
                      <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                    </FormItem>
                  </Col>
                )}

                {[SsiType.Mobile, SsiType.Application, SsiType.Terminal].includes(ssiType) && (
                  <Col md={12}>
                    <FormItem name="simId" label={t('SIM')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && <Col md={12} />}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="callTrfNo" label={t('Transfer no.')}>
                      <Input readOnly={readonly} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Group && (
                  <Col md={12}>
                    <FormItem name="assignCallOwnershipToCallingParty" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Assign call ownership to calling party')}</Checkbox>
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Application && (
                  <Col md={12}>
                    <FormItem name="ambienceListeningAllowed" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Ambience Listening allowed')}</Checkbox>
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Terminal && (
                  <Col md={12}>
                    <FormItem name="numberModificationAllowed" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Number Modification allowed')}</Checkbox>
                    </FormItem>
                  </Col>
                )}

                {/* //! */}
                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="rua" label={t('RUA')}>
                      <Select disabled={subscriberId !== 'new'} />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Application && (
                  <Col md={12}>
                    <FormItem name="securitySetStatus" label={t('Ключ шиф')}>
                      <Input readOnly={readonly} disabled />
                    </FormItem>
                  </Col>
                )}

                {ssiType === SsiType.Mobile && (
                  <Col md={12}>
                    <FormItem name="callTrfTime" label={t('Transfer time [sec]')}>
                      <InputNumber readOnly={readonly} size="small" min="0" step="1" />
                    </FormItem>
                  </Col>
                )}
                {ssiType === SsiType.Application && (
                  <Col md={12}>
                    <FormItem name="permanentMobileDisableAllowed" valuePropName="checked" label=" ">
                      <Checkbox disabled={readonly}>{t('Temporary Mobile Enable/Disable allowed')}</Checkbox>
                    </FormItem>
                  </Col>
                )}

                {ssiType === SsiType.Application && (
                  <>
                    <Col md={12} />
                    <Col md={12}>
                      <FormItem name="permanentMobileDisableAllowed" valuePropName="checked" label=" ">
                        <Checkbox disabled={readonly}>{t('Permanent Mobile Disable allowed')}</Checkbox>
                      </FormItem>
                    </Col>
                    <Col md={12} />
                    <Col md={12}>
                      <FormItem name="groupManagementAllowed" valuePropName="checked" label=" ">
                        <Checkbox disabled={readonly}>{t('Group Management allowed')}</Checkbox>
                      </FormItem>
                    </Col>
                    <Col md={12} />
                    <Col md={12}>
                      <FormItem name="numberModificationAllowed" valuePropName="checked" label=" ">
                        <Checkbox disabled={readonly}>{t('Number Modification allowed')}</Checkbox>
                      </FormItem>
                    </Col>
                  </>
                )}
              </Row>

              {mode !== DrawerViewMode.View && (
                <>
                  <Divider />
                  <Actions>
                    <Button htmlType="reset" loading={isLoading} onClick={onReset}>
                      {t('Cancel')}
                    </Button>
                    <SubmitButton loading={isLoading} size="middle">
                      {data ? t('Save') : t('Create')}
                    </SubmitButton>
                  </Actions>
                </>
              )}
            </Form>
          )}
        </Spinner>
      </Scrollbar>
    </Container>
  );
};

export default memo(SubscriberDrawer);
