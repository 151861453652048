import { useApolloError } from '@fjedi/graphql-react-components';
import { useNavigate } from '@fjedi/react-router-helpers';
import { Divider } from 'antd';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/ui-kit/buttons';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Col, Row } from 'src/components/ui-kit/grid';
import { Input } from 'src/components/ui-kit/input';
import { NodeProfile, NodeSubscriber } from 'src/graphql/generated';
import { getTsiFromMobile } from './drawer-helpers';
import { Actions } from './drawer.sc';

type RegistrDataFormProps = {
  data?: Partial<NodeSubscriber & NodeProfile>;
  refetch?: () => Promise<void>;
};

const RegistDataForm: FC<RegistrDataFormProps> = ({ data, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const error = useApolloError();

  const onRefresh = useCallback(async () => {
    setIsLoading(true);
    form.resetFields();

    if (typeof refetch === 'function') {
      await refetch()
        .catch(error)
        .finally(() => setIsLoading(false));
    }

    setTimeout(() => setIsLoading(false), 1000);
  }, [error, form, refetch]);
  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const initialValues = useMemo(() => {
    const { ssi, mobile, userNo, description, ipAddress } = data ?? {};
    const { flags } = mobile ?? {};
    const { airInterfaceEncryptionDisable } = flags ?? {};
    const tsi = getTsiFromMobile(mobile);

    return {
      ssi,
      airInterfaceEncryptionDisable,
      userNo,
      description,
      ipAddress,
      tsi,
    };
  }, [data]);

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" className="readonly">
      <Row>
        <Col md={12}>
          <FormItem name="ssi" label={t('SSI')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="airInterfaceEncryptionDisable" label={t('Шифр. AIE')}>
            <Input readOnly />
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem name="userNo" label={t('№ Абонента')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Класс [HEX]')}>
            <Input readOnly />
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem name="description" label={t('Название')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('SCCH инф.')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Статус регист.')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('CCH канал')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Узел регистр.')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Экономия энергии')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Первая регист')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={6}>
          <FormItem name="" label={t('Запрет передачи')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={6}>
          <FormItem name="" label={t('IP соед')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Изменено')}>
            <Input readOnly />
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem name="ipAddress" label={t('IP адрес')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Сканирование')}>
            <Input readOnly />
          </FormItem>
        </Col>
        <Col md={12}>
          <FormItem name="tsi" label={t('TSI')}>
            <Input readOnly />
          </FormItem>
        </Col>
        {/* //! */}
        <Col md={12}>
          <FormItem name="" label={t('Положение')}>
            <Input readOnly />
          </FormItem>
        </Col>
      </Row>

      <Divider />

      <Actions>
        <Button htmlType="button" type="primary" loading={isLoading} onClick={onRefresh}>
          {t('Обновить')}
        </Button>
        <Button htmlType="button" type="primary" onClick={onClose}>
          {t('Закрыть')}
        </Button>
      </Actions>
    </Form>
  );
};

export default memo(RegistDataForm) as typeof RegistDataForm;
