import { useApolloError } from '@fjedi/graphql-react-components';
import {
  useNodeSubscriberChangedSubscription,
  useNodeSubscriberQuery,
  useUpdateNodeSubscriberMutation,
} from 'src/graphql/generated';
import { Input } from 'src/components/ui-kit/input';
import React, { useState, useCallback, ChangeEvent } from 'react';
import Button from 'src/components/ui-kit/buttons';
import omit from 'lodash/omit';

export default function ChangeGroupNameInput({ subscriberId }: { subscriberId: string }) {
  const [value, setValue] = useState<string>();
  const { data, loading } = useNodeSubscriberQuery({
    variables: { id: subscriberId! },
    skip: !subscriberId,
    fetchPolicy: 'cache-and-network',
    onCompleted(res) {
      console.log('NodeSubscriber', res?.nodeSubscriber);
      setValue(data?.nodeSubscriber?.group?.groupText ?? '');
    },
  });
  useNodeSubscriberChangedSubscription({
    variables: { filter: { ssi: data?.nodeSubscriber?.ssi } },
    skip: !data?.nodeSubscriber?.ssi,
  });

  const onError = useApolloError();
  const [updateNodeSubscriber, { loading: updating }] = useUpdateNodeSubscriberMutation({
    onError,
    onCompleted(res) {
      setValue(res?.updateNodeSubscriber?.group?.groupText ?? '');
    },
  });

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const onSave = useCallback(() => {
    updateNodeSubscriber({
      variables: {
        id: subscriberId,
        input: {
          group: {
            ...omit(data?.nodeSubscriber?.group ?? {}, ['__typename']),
            flags: omit(data?.nodeSubscriber?.group?.flags ?? {}, ['__typename']),
            groupText: value as string,
          },
        },
      },
    });
  }, [data?.nodeSubscriber?.group, subscriberId, updateNodeSubscriber, value]);

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 0 1rem 0',
        gap: '1rem',
      }}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label style={{ minWidth: '9rem' }} htmlFor="change-group-name-input">
        Изменить имя группы
      </label>
      <Input style={{ maxWidth: '15rem' }} id="change-group-name-input" onChange={onChange} value={value} />
      <Button loading={updating || loading} onClick={onSave}>
        Сохранить
      </Button>
    </div>
  );
}
