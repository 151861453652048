import React, { useContext, memo, useMemo, useCallback, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { ViewerContext } from '@fjedi/react-router-helpers';
import SwitchInput from 'src/components/ui-kit/input/switch-input';
import {
  useNotificationProvidersQuery,
  useUpdateNotificationProviderMutation,
  NotificationProviderId,
} from 'src/graphql/generated';
import { logger } from '@fjedi/graphql-react-components';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { useForm } from 'antd/lib/form/Form';

const Container = styled.div``;
const ProvidersContainer = styled.div`
  display: flex;
  margin: 0.25rem auto;
  width: 500px;
  max-width: 100%;
  align-items: center;

  > form.ant-form {
    width: 100%;
  }
`;

type NotificationProvidersStatuses = {
  [NotificationProviderId.Sms]: boolean;
  [NotificationProviderId.Email]: boolean;
  [NotificationProviderId.Web]: boolean;
};

const ProfileSettingsTab = () => {
  const viewer = useContext(ViewerContext);
  const [form] = useForm();
  const { data } = useNotificationProvidersQuery({ skip: !viewer });
  const [updateProvider] = useUpdateNotificationProviderMutation();
  const providers = useMemo(() => data?.notificationProviders ?? [], [data?.notificationProviders]);
  const handleSwitchChange = useCallback(
    (enabled: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { name: id } = event.currentTarget;
      updateProvider({ variables: { id, input: { enabled } } }).catch(logger);
    },
    [updateProvider],
  );
  useEffect(() => {
    const values = {} as NotificationProvidersStatuses;
    providers.forEach(p => {
      values[p.id] = p.id === NotificationProviderId.Web || p.enabled;
    });
    form.setFieldsValue(values);
  }, [form, providers]);
  logger('ProfileSettingsTab', { providers });
  if (!viewer || !providers.length) {
    return null;
  }
  return (
    <Container>
      <ProvidersContainer>
        <Form form={form} layout="vertical" validateTrigger={['onBlur', 'onFocus', 'onChange']}>
          {providers.map(p => (
            <FormItem key={p.id} label={p.name} name={p.id}>
              <SwitchInput disabled={p.id === NotificationProviderId.Web} name={p.id} onChange={handleSwitchChange} />
            </FormItem>
          ))}
        </Form>
      </ProvidersContainer>
    </Container>
  );
};

ProfileSettingsTab.propTypes = {};
ProfileSettingsTab.defaultProps = {};

export default memo(ProfileSettingsTab);
