import React, { FC, memo } from 'react';

const HelpPage: FC = () => (
  <>
    <h1>Help files</h1>
    <br />
    <h2>Special Control Characters</h2>
    <br />
    <div>Color&nbsp;Control&nbsp;characters</div>
    <br />
    <div>
      It&nbsp;is,&nbsp;with&nbsp;the&nbsp;help&nbsp;of&nbsp;special&nbsp;control&nbsp;characters,&nbsp;possible&nbsp;to&nbsp;chance&nbsp;the
    </div>
    <div>color&nbsp;of&nbsp;the&nbsp;help,&nbsp;present&nbsp;on&nbsp;the&nbsp;screen.</div>
    <br />
    <div>
      The&nbsp;color&nbsp;of&nbsp;the&nbsp;text,&nbsp;will&nbsp;take&nbsp;effect&nbsp;after&nbsp;insertion&nbsp;of&nbsp;the&nbsp;control
    </div>
    <div>
      character,&nbsp;and&nbsp;will&nbsp;last&nbsp;until&nbsp;end&nbsp;of&nbsp;line,&nbsp;or&nbsp;until&nbsp;a&nbsp;new&nbsp;color&nbsp;control
    </div>
    <div>
      character&nbsp;is&nbsp;inserted.&nbsp;To&nbsp;return&nbsp;to&nbsp;the&nbsp;previous&nbsp;color,&nbsp;insert&nbsp;the&nbsp;same
    </div>
    <div>control&nbsp;character&nbsp;a&nbsp;second&nbsp;time.</div>
    <br />
    <div>The&nbsp;pallete&nbsp;consists&nbsp;of&nbsp;four&nbsp;colors:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Default&nbsp;:&nbsp;White&nbsp;on&nbsp;Black.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^A&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;White&nbsp;on&nbsp;Cyan.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^B&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Blue&nbsp;on&nbsp;Black.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^C&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Red&nbsp;on&nbsp;Black.
    </div>
    <br />
    <div>Example:</div>
    <div>
      Default&nbsp;[^A]White&nbsp;on&nbsp;Cy.[^B]Blue&nbsp;on&nbsp;bl.&nbsp;[^C]Red&nbsp;on&nbsp;bl.[^C]&nbsp;Return&nbsp;to&nbsp;blue
    </div>
    <br />
    <div>*&nbsp;&nbsp;Color&nbsp;characters</div>
    <br />
    <div>WordPerfect&nbsp;Control&nbsp;characters</div>
    <br />
    <div>
      Special&nbsp;control&nbsp;characters,&nbsp;enables&nbsp;the&nbsp;possibility&nbsp;to&nbsp;chance&nbsp;writing&nbsp;style,
    </div>
    <div>when&nbsp;help&nbsp;files&nbsp;is&nbsp;"printed"&nbsp;to&nbsp;a&nbsp;WordPerfect&nbsp;file.</div>
    <br />
    <div>
      The&nbsp;writing&nbsp;style&nbsp;will&nbsp;take&nbsp;effect&nbsp;after&nbsp;insertion&nbsp;of&nbsp;the&nbsp;control&nbsp;character,
    </div>
    <div>
      and&nbsp;will&nbsp;last&nbsp;until&nbsp;another&nbsp;writing&nbsp;style&nbsp;is&nbsp;selected.&nbsp;If&nbsp;the&nbsp;selected
    </div>
    <div>
      writing&nbsp;style&nbsp;already&nbsp;is&nbsp;current,&nbsp;the&nbsp;control&nbsp;character&nbsp;has&nbsp;no&nbsp;effect.
    </div>
    <br />
    <div>
      Detail&nbsp;description&nbsp;(.H).&nbsp;is&nbsp;default&nbsp;written&nbsp;in&nbsp;Proportional&nbsp;style.&nbsp;The
    </div>
    <div>
      Proportional&nbsp;writing&nbsp;style&nbsp;is&nbsp;left/right&nbsp;margin&nbsp;adjusted,&nbsp;hence&nbsp;a&nbsp;forced&nbsp;new
    </div>
    <div>line&nbsp;should&nbsp;only&nbsp;be&nbsp;inserted&nbsp;at&nbsp;segment&nbsp;end.</div>
    <br />
    <div>
      Command/Description&nbsp;(.C)&nbsp;is&nbsp;default&nbsp;written&nbsp;in&nbsp;non-proportional&nbsp;style,&nbsp;and&nbsp;is
    </div>
    <div>left&nbsp;adjusted</div>
    <br />
    <div>
      It&nbsp;is&nbsp;possible&nbsp;to&nbsp;protect&nbsp;a&nbsp;block&nbsp;of&nbsp;text&nbsp;(i.e.,&nbsp;tables,&nbsp;lists&nbsp;or&nbsp;coherent
    </div>
    <div>
      text),&nbsp;from&nbsp;being&nbsp;split&nbsp;at&nbsp;page-end,&nbsp;this&nbsp;is&nbsp;done&nbsp;with&nbsp;Begin-&nbsp;and&nbsp;End-protect
    </div>
    <div>block.</div>
    <br />
    <div>Five&nbsp;control&nbsp;characters&nbsp;enables&nbsp;these&nbsp;functions:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^N&nbsp;:&nbsp;Non&nbsp;proportional&nbsp;writing&nbsp;style.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^P&nbsp;:&nbsp;Proportional&nbsp;writing&nbsp;Style.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^Q&nbsp;:&nbsp;Forced&nbsp;new&nbsp;line.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^-&nbsp;:&nbsp;Begin&nbsp;-&nbsp;Protect&nbsp;block&nbsp;from&nbsp;auto.&nbsp;new&nbsp;side.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^^&nbsp;:&nbsp;End&nbsp;-&nbsp;Protect&nbsp;block&nbsp;from&nbsp;auto.&nbsp;new&nbsp;side.
    </div>
    <br />
    <div>
      The&nbsp;Special&nbsp;Control&nbsp;characters,&nbsp;is&nbsp;removed&nbsp;from&nbsp;the&nbsp;screen&nbsp;help,&nbsp;hence&nbsp;these
    </div>
    <div>characters&nbsp;must&nbsp;not&nbsp;be&nbsp;used&nbsp;as&nbsp;text.</div>
    <br />
    <div>*&nbsp;&nbsp;WordPerfect&nbsp;Control&nbsp;characters</div>
    <br />
    <h2>Header dot commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          (.N
          <str>)&nbsp;&nbsp;Device&nbsp;name</str>
        </strong>
      </div>
      <div>Device&nbsp;name&nbsp;on&nbsp;device&nbsp;selection&nbsp;list&nbsp;and&nbsp;window&nbsp;top.</div>
      <div>Has&nbsp;same&nbsp;function&nbsp;as&nbsp;.NH&nbsp;and&nbsp;.NL&nbsp;commands&nbsp;together.</div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;┌&nbsp;Device&nbsp;name</div>
      <div>┴&nbsp;&nbsp;┴─────────────────────────────────────────────────</div>
      <div>_N&nbsp;__________________________________________________</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          (.NL
          <str>)&nbsp;&nbsp;Device&nbsp;name&nbsp;on&nbsp;device&nbsp;selection&nbsp;list</str>
        </strong>
      </div>
      <div>The&nbsp;date&nbsp;of&nbsp;the&nbsp;source&nbsp;file&nbsp;is&nbsp;added&nbsp;automatically.</div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;&nbsp;┌&nbsp;Device&nbsp;name&nbsp;on&nbsp;device&nbsp;selection&nbsp;list.</div>
      <div>┴&nbsp;&nbsp;&nbsp;┴─────────────────────────────────────────────────</div>
      <div>_NL&nbsp;__________________________________________________</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          (.NH
          <str>)&nbsp;&nbsp;Device&nbsp;name&nbsp;on&nbsp;window&nbsp;top</str>
        </strong>
      </div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;&nbsp;┌&nbsp;Device&nbsp;name&nbsp;on&nbsp;window&nbsp;top</div>
      <div>┴&nbsp;&nbsp;&nbsp;┴─────────────────────────────────────────────────</div>
      <div>_NH&nbsp;__________________________________________________</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>(.P&lt;nn..n&gt;)&nbsp;&nbsp;Position&nbsp;on&nbsp;device&nbsp;selection&nbsp;list</strong>
      </div>
      <div>
        Devices&nbsp;with&nbsp;lowest&nbsp;position&nbsp;numbers&nbsp;will&nbsp;be&nbsp;first&nbsp;on&nbsp;the&nbsp;device&nbsp;selection
      </div>
      <div>list.</div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;┌&nbsp;Relative&nbsp;position.</div>
      <div>┴&nbsp;&nbsp;┴────</div>
      <div>_P&nbsp;_____</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          (.T
          <str>)&nbsp;&nbsp;Device&nbsp;type</str>
        </strong>
      </div>
      <div>
        Used&nbsp;by&nbsp;OM.EXE&nbsp;identify&nbsp;help&nbsp;file&nbsp;for&nbsp;the&nbsp;selected&nbsp;hardware&nbsp;device.
      </div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;┌&nbsp;Device&nbsp;type.</div>
      <div>┴&nbsp;&nbsp;┴────</div>
      <div>_T&nbsp;_____</div>
      <div />
    </div>
    <h2>Command/Description dot commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          (.B
          <str>)&nbsp;&nbsp;Header&nbsp;on&nbsp;selection&nbsp;list</str>
        </strong>
      </div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;┌&nbsp;Header</div>
      <div>┴&nbsp;&nbsp;┴─────────────────────────────────────────────────</div>
      <div>_B&nbsp;__________________________________________________</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>(.H)&nbsp;&nbsp;Help&nbsp;description</strong>
      </div>
      <div>
        Text&nbsp;following&nbsp;the&nbsp;.H&nbsp;command&nbsp;is&nbsp;shown,&nbsp;when&nbsp;F1&nbsp;is&nbsp;pressed&nbsp;on&nbsp;all&nbsp;.C
      </div>
      <div>commands&nbsp;following&nbsp;until&nbsp;next&nbsp;.H&nbsp;command.</div>
      <div>
        First&nbsp;line&nbsp;is&nbsp;shown&nbsp;as&nbsp;a&nbsp;header&nbsp;line&nbsp;with&nbsp;another&nbsp;color.
      </div>
      <div>┌&nbsp;"."</div>
      <div>┴</div>
      <div>_H</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>(.C)&nbsp;&nbsp;Command/description&nbsp;entry</strong>
      </div>
      <div>Used&nbsp;to&nbsp;make&nbsp;an&nbsp;entry&nbsp;with&nbsp;a&nbsp;command&nbsp;execution&nbsp;window.</div>
      <div>Last&nbsp;.H&nbsp;entry&nbsp;content&nbsp;is&nbsp;used&nbsp;in&nbsp;description&nbsp;window.</div>
      <div>
        First&nbsp;line&nbsp;is&nbsp;used&nbsp;on&nbsp;selector&nbsp;list&nbsp;and&nbsp;as&nbsp;header&nbsp;in&nbsp;the&nbsp;execution&nbsp;window.
      </div>
      <div>
        Last&nbsp;line&nbsp;is&nbsp;the&nbsp;command&nbsp;template,&nbsp;where&nbsp;all&nbsp;"_"&nbsp;characters&nbsp;are&nbsp;variable&nbsp;data
      </div>
      <div>fields.</div>
      <div>┌&nbsp;"."</div>
      <div>┴</div>
      <div>_C</div>
      <div />
      <div />
    </div>
    <h2>Miscelaneous dot commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          (.I
          <str>)&nbsp;&nbsp;Include&nbsp;file</str>
        </strong>
      </div>
      <div>Include&nbsp;another&nbsp;file&nbsp;into&nbsp;the&nbsp;source&nbsp;help-file.</div>
      <div>┌&nbsp;"."</div>
      <div>│&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;file&nbsp;to&nbsp;include</div>
      <div>┴&nbsp;&nbsp;┴───────────────────────────────────────</div>
      <div>_I&nbsp;________________________________________</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>(.END)&nbsp;&nbsp;End&nbsp;of&nbsp;file</strong>
      </div>
      <div>
        All&nbsp;text&nbsp;in&nbsp;the&nbsp;source&nbsp;file&nbsp;after&nbsp;.END&nbsp;will&nbsp;be&nbsp;skipped.
      </div>
      <div>
        The&nbsp;.END&nbsp;command&nbsp;is&nbsp;optional.&nbsp;IF&nbsp;not&nbsp;present,&nbsp;the&nbsp;physical&nbsp;end-of-file&nbsp;is
      </div>
      <div>used.</div>
      <div>┌&nbsp;"."</div>
      <div>┴</div>
      <div>_END</div>
      <div />
    </div>
    <br />
  </>
);

export default memo(HelpPage) as typeof HelpPage;
