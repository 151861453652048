import { logger, useApolloError } from '@fjedi/graphql-react-components';
import { Link, Route, Routes, useLocation, useNavigate } from '@fjedi/react-router-helpers';
import type { DefaultEventsMap } from '@socket.io/component-emitter';
import { Divider, Modal, type ModalProps, type ButtonProps } from 'antd';
import type { RowSelectionType } from 'antd/lib/table/interface';
import React, { createElement, FC, Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import io, { Socket } from 'socket.io-client';
import Button from 'src/components/ui-kit/buttons';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import { Tabs } from 'src/components/ui-kit/tabs';
import { colorTheme } from 'src/components/ui-kit/theme';
import { API_HOST } from 'src/constants';
import styled from 'styled-components';
import { NodeHost, NodeHostInput, NodeSettingsQuery, useUpdateNodeSettingsMutation } from 'src/graphql/generated';
import { Select, SelectOption } from 'src/components/ui-kit/select';
import Spinner from 'src/components/ui-kit/spinner';
import { StyledTable } from 'src/components/ui-kit/table';
import { Text } from 'src/components/ui-kit/typography';
import DeleteButton from 'src/components/ui-kit/buttons/delete';
import UndoButton from 'src/components/ui-kit/buttons/undo';
import * as tetraOmHelp from './tetra-om-help';

const ChooseHostAlert = styled.div`
  width: 50%;
  height: auto;
  margin: -1rem auto auto;
  color: ${props => props.theme.token.colorPrimary};
  font-size: 1rem;
  font-weight: 600;
  text-shadow: none;
`;

const Container = styled(Modal)`
  &.ant-modal > .ant-modal-content {
    border-radius: 0.5rem;
    overflow: hidden;
    min-height: 750px;
    max-height: 810px;
    display: flex;
    flex-direction: column;
    padding: 0;

    & > .ant-modal-header {
      border: 0;
      padding: 1rem 2rem 0;
    }

    & > .ant-modal-body {
      padding: 0;

      & > .ant-tabs {
        & > .ant-tabs-nav {
          margin: 0 0 0.25rem;

          & > .ant-tabs-nav-wrap {
            & > .ant-tabs-nav-list {
              width: 100%;
              display: flex;

              & > .ant-tabs-tab {
                flex-basis: 50%;

                text-align: center;
                align-items: center;
                justify-content: center;

                color: ${colorTheme.dark};
                font-weight: normal;
                font-size: 1rem;
              }
            }
          }
        }

        & > .ant-tabs-content-holder {
          padding: 1rem 2rem;

          display: flex;
          flex-direction: column;

          & > ol {
            padding: 0 calc(40px - 1rem);

            & > li:nth-of-type(3) {
              ${FormItem} {
                margin-top: 0.25rem;
              }
            }
          }
        }
      }
    }

    & > .ant-modal-footer {
      border: 0;
      margin-top: auto;
      padding: 0.25rem 1.25rem 1rem;

      & > .ant-btn.ant-btn-primary {
        display: none;
      }
    }

    .terminal-spinner {
      width: 100%;
    }
  }
`;

interface TetraOMConsoleModalProps extends ModalProps {
  nodeNumber: string;
  nodeSettings?: NodeSettingsQuery['nodeSettings'];
}

const TerminalRowDivider = styled(Divider)`
  &.ant-divider {
    border-top-color: #fff;
  }
`;

const TerminalWindow = styled.div`
  min-width: 600px;
  height: 420px;
  background: #000;
  margin: 0 auto 1rem;
  border-radius: 0.5rem;
  overflow: auto;
  padding: 1rem 1rem 1.5rem;
  color: #ffffff;
`;

const QUICK_COMMANDS = ['M01', 'M03', 'M46', 'M71', 'M76'];

const CommandButton = styled(Button)`
  &.ant-btn {
    margin-right: 0.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
`;

const TetraOMConsoleModal: FC<TetraOMConsoleModalProps> = ({ nodeNumber, nodeSettings }) => {
  const { t } = useTranslation();
  const terminalRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();
  const [visible, setModalVisibility] = useState(false);
  const [selectedHost, setSelectedHost] = useState<NodeHost['ip']>(null);
  const [updatedHosts, setNodeHosts] = useState<NodeHostInput[]>([]);
  const [selectedNodeHosts, setSelectedNodeHosts] = useState<React.Key[]>([]);

  const [shellContent, setShellContent] = useState<string[]>([]);
  const [websocketConnection, setWebsocketConnection] = useState<null | Socket<DefaultEventsMap, DefaultEventsMap>>(
    null,
  );
  const isConnecting = useMemo(
    () => !!(websocketConnection?.active && !websocketConnection?.connected),
    [websocketConnection],
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onOpen = useCallback(() => {
    setModalVisibility(true);
  }, []);

  const onCancel = useCallback(() => {
    setModalVisibility(false);
    navigate('./');
  }, [navigate]);

  const onSubmit = useCallback(
    (values: unknown) => {
      logger('TetraOMConsoleModal.onSubmit', { values });
      const { command } = values as { command: string };
      websocketConnection?.emit('command', { nodeNumber, command: command.toUpperCase() });
      form.resetFields();
    },
    [form, nodeNumber, websocketConnection],
  );

  useEffect(() => {
    if (!visible) {
      setShellContent([]);
      return () => {};
    }
    if (!selectedHost) {
      return () => {};
    }
    // Socket.io connection
    const socket = io(`https://${API_HOST}`, {
      reconnection: true,
      withCredentials: true,
      transports: ['websocket'],
    });
    setWebsocketConnection(socket);

    socket.emit('command', { nodeNumber, host: selectedHost, command: 'M03' });

    socket.on('commandResponse', (res: string) => {
      logger('TetraOMConsole.onResponse', { res });
      setShellContent(content => [...(content ?? []), res]);
      //
      setTimeout(() => {
        const terminalContainer = terminalRef.current;
        if (terminalContainer) {
          terminalContainer.scrollTop = terminalContainer.scrollHeight;
        }
      }, 100);
    });
    return () => {
      setWebsocketConnection(null);
      socket.disconnect();
    };
  }, [visible, nodeNumber, setWebsocketConnection, selectedHost]);

  useEffect(() => {
    const hosts = nodeSettings?.hosts ?? [];
    setNodeHosts(hosts);
    const primaryHost = hosts.find(h => h.isPrimary);
    if (primaryHost) {
      setSelectedHost(primaryHost.ip);
      setSelectedNodeHosts([primaryHost.ip]);
    }
  }, [nodeSettings]);

  useEffect(() => {
    if (selectedHost) {
      form.setFieldsValue({ host: selectedHost });
    }
  }, [form, selectedHost]);

  const sendCommand = useCallback(
    (command: string) => () => {
      websocketConnection!.emit('command', { nodeNumber, host: selectedHost, command });
    },
    [websocketConnection, nodeNumber, selectedHost],
  );

  const mostUsedCommandButtons = useMemo(
    () =>
      QUICK_COMMANDS.map(command => (
        <CommandButton key={command} onClick={sendCommand(command)}>
          {command}
        </CommandButton>
      )),
    [sendCommand],
  );

  const mainTabContent = useMemo(
    () => (
      <Form
        layout="inline"
        onFinish={onSubmit}
        form={form}
        requiredMark={false}
        validateTrigger={['onBlur', 'onFocus', 'onChange']}>
        <FormItem label={t('IP адрес хоста')} name="host">
          <Select onChange={setSelectedHost} disabled={!nodeSettings?.hosts?.length} placeholder={t('Выберите адрес')}>
            {nodeSettings?.hosts?.map(host => (
              <SelectOption key={host.id} value={host.ip}>
                {host.ip}
              </SelectOption>
            ))}
          </Select>
        </FormItem>

        <Spinner
          wrapperClassName="terminal-spinner"
          spinning={!websocketConnection?.connected}
          indicator={websocketConnection?.active ? undefined : <div />}
          tip={
            !websocketConnection?.active && (
              <ChooseHostAlert>Выберите хост в выпадающем списке или добавьте новый в настройках</ChooseHostAlert>
            )
          }>
          <TerminalWindow ref={terminalRef}>
            {shellContent.map((row, rowIndex) => {
              const rowKey = `${rowIndex}-${row.substring(0, 8)}`;
              const strings = row.replace(/(\r)/g, '[[[linebreak]]]').split('[[[linebreak]]]');
              const textContent = strings.map((s, sIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={sIndex}>
                  {sIndex > 1 && sIndex !== strings.length - 1 ? createElement('br') : null}
                  {s}
                </Fragment>
              ));
              return (
                <div key={rowKey}>
                  {rowIndex > 0 && <TerminalRowDivider />}
                  {textContent}
                </div>
              );
            })}
          </TerminalWindow>

          {mostUsedCommandButtons}

          <FormItem label={t('Enter command')} name="command">
            <Input autoFocus autoComplete="off" name="command" />
          </FormItem>
        </Spinner>

        <button type="submit" style={{ display: 'none' }}>
          Отправить
        </button>
      </Form>
    ),
    [form, nodeSettings, mostUsedCommandButtons, onSubmit, shellContent, t, websocketConnection],
  );

  const nodeHosts = useMemo(
    () => uniqBy([...(updatedHosts ?? []), ...(nodeSettings?.hosts ?? [])], 'ip'),
    [nodeSettings, updatedHosts],
  );

  const [addNodeHostForm] = Form.useForm<Pick<NodeHost, 'ip'>>();
  const onAddNodeHost = useCallback(
    (formData: unknown) => {
      const ip = (formData as Pick<NodeHost, 'ip'>).ip.trim();
      if (!updatedHosts.some(h => h.ip === ip)) {
        const newHost = { id: ip, ip, isPrimary: updatedHosts.length === 0 };
        setNodeHosts([...updatedHosts, newHost]);

        if (newHost.isPrimary) {
          setSelectedHost(ip);
          setSelectedNodeHosts([ip]);
        }
      }
      addNodeHostForm.resetFields();
    },
    [addNodeHostForm, setNodeHosts, updatedHosts],
  );
  const onDeleteNodeHost = useCallback(
    (host: NodeHost) => () => {
      const hosts = updatedHosts
        .filter(h => h.id !== host.id)
        .map((h, hostIndex) => ({ ...h, isPrimary: host.isPrimary ? hostIndex === 0 : h.isPrimary }));
      setNodeHosts(hosts);
      const primaryHost = hosts.find(h => h.isPrimary);
      console.log('Hosts', { hosts, host, primaryHost });
      if (primaryHost) {
        setSelectedNodeHosts([primaryHost.ip]);
      }
    },
    [setNodeHosts, updatedHosts],
  );
  const onRestoreHost = useCallback(
    (host: NodeHost) => () => {
      onAddNodeHost(host);
    },
    [onAddNodeHost],
  );

  const nodeHostRowSelection = useMemo(() => {
    const disabled = (updatedHosts ?? []).length < 2;
    return {
      columnTitle: t('Основной'),
      columnWidth: 80,
      selectedRowKeys: selectedNodeHosts,
      onChange: (selectedKeys: React.Key[]) => {
        setSelectedNodeHosts(selectedKeys);
        const hosts = updatedHosts.map(h => ({ ...h, isPrimary: selectedKeys.includes(h.ip) }));
        setNodeHosts(hosts);
      },
      type: 'radio' as RowSelectionType,
      getCheckboxProps: () => ({
        disabled,
      }),
    };
  }, [t, selectedNodeHosts, updatedHosts]);

  const onError = useApolloError();
  const [updateNodeSettings, { loading: updatingNodeSettings }] = useUpdateNodeSettingsMutation({
    onError,
  });

  const settingsTabContent = useMemo(
    () => (
      <div>
        <Form
          layout="inline"
          form={addNodeHostForm}
          onFinish={onAddNodeHost}
          validateTrigger={['onBlur', 'onFocus', 'onChange']}>
          <FormItem label={t('IP адрес хоста')} name="ip">
            <Input placeholder="Введите IP адрес" />
          </FormItem>
          <FormItem>
            <Button htmlType="submit">Добавить новый адрес</Button>
          </FormItem>
        </Form>
        <Divider>Список адресов</Divider>
        <StyledTable
          size="small"
          rowKey="ip"
          dataSource={nodeHosts}
          columns={[
            {
              title: t('IP адрес'),
              key: 'ip',
              dataIndex: 'ip',
              width: 360,
              render: (ip: string) => <Text delete={!updatedHosts?.some(h => h.ip === ip)}>{ip ?? 'Не указано'}</Text>,
            },
            {
              dataIndex: 'id',
              key: 'id',
              width: 90,
              render: (_: any, host: any) =>
                updatedHosts.some(h => h.ip === host.ip) ? (
                  <DeleteButton style={{ display: 'block', margin: 'auto' }} onClick={onDeleteNodeHost(host)} />
                ) : (
                  <UndoButton style={{ display: 'block', margin: 'auto' }} onClick={onRestoreHost(host)} />
                ),
            },
          ]}
          rowSelection={nodeHostRowSelection}
          bordered
        />
        <button type="submit" style={{ display: 'none' }}>
          Отправить
        </button>
      </div>
    ),
    [t, nodeHosts, updatedHosts, addNodeHostForm, nodeHostRowSelection, onAddNodeHost, onRestoreHost, onDeleteNodeHost],
  );

  const helpTabContent = useMemo(
    () => (
      <Routes>
        <Route
          path=""
          element={
            <ol>
              {Object.entries(tetraOmHelp.pages).map(([to, text]) => (
                <li>
                  <Link to={to}>{text}</Link>
                </li>
              ))}
            </ol>
          }
        />
        {Object.keys(tetraOmHelp.pages).map(path => {
          // eslint-disable-next-line import/namespace
          const Component = tetraOmHelp[path as keyof typeof tetraOmHelp] as FC;
          const TetraOmHelpPageContainer = tetraOmHelp.PageContainer;
          const element = (
            <TetraOmHelpPageContainer>
              <Component />
            </TetraOmHelpPageContainer>
          );

          return <Route path={path} element={element} />;
        })}
      </Routes>
    ),
    [],
  );

  const tabs = useMemo(
    () => [
      {
        key: 'main',
        label: t('Main'),
        children: mainTabContent,
      },
      { key: 'settings', label: t('Settings'), children: settingsTabContent },
      { key: 'help', label: t('Help'), children: helpTabContent },
    ],
    [helpTabContent, settingsTabContent, mainTabContent, t],
  );

  const [activeTab, setActiveTab] = useState<string>('main');
  const onTabChange = useCallback(
    (activeTabKey: string) => {
      setActiveTab(activeTabKey);
      navigate('./');
    },
    [navigate],
  );

  const handleCancelClick = useCallback(() => {
    if (activeTab === 'help' && !pathname.endsWith('dashboard') && !pathname.endsWith('dashboard/')) {
      navigate('./');
    } else {
      onCancel();
    }
  }, [activeTab, navigate, onCancel, pathname]);

  const okButtonProps = useMemo(() => {
    return activeTab === 'help'
      ? undefined
      : {
          type: 'link' as ButtonProps['type'],
          disabled: activeTab === 'main' ? !selectedHost || isConnecting || !nodeNumber : false,
          htmlType: 'submit' as ButtonProps['htmlType'],
          loading: updatingNodeSettings,
          onClick() {
            switch (activeTab) {
              case 'main':
                form.submit();
                break;
              case 'settings':
                updateNodeSettings({
                  variables: {
                    nodeNumber: parseInt(nodeNumber, 10),
                    input: {
                      hosts: updatedHosts.map(h => pick(h, ['id', 'ip', 'port', 'isPrimary'])),
                    },
                  },
                }).catch(logger);
                break;
              default:
                console.log('You cannot submit the form on this tab');
            }
          },
        };
  }, [
    onCancel,
    activeTab,
    form,
    updatedHosts,
    nodeNumber,
    updateNodeSettings,
    updatingNodeSettings,
    selectedHost,
    isConnecting,
  ]);

  return (
    <>
      <Button size="small" onClick={onOpen}>
        TetraOM
      </Button>
      <Container
        open={visible}
        onCancel={onCancel}
        title="Терминал TetraOM"
        cancelButtonProps={{ type: 'link', onClick: handleCancelClick }}
        cancelText={t('Закрыть')}
        okButtonProps={okButtonProps}
        okText={activeTab === 'main' ? t('Send') : t('Save')}
        closable={false}
        width={700}
        maskClosable
        keyboard
        centered
        destroyOnClose>
        <Tabs activeKey={activeTab} onChange={onTabChange} items={tabs} tabBarGutter={0} size="small" />
      </Container>
    </>
  );
};

export default memo(TetraOMConsoleModal) as typeof TetraOMConsoleModal;
