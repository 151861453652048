import React, { FC, memo } from 'react';

const TCC411Page: FC = () => (
  <>
    <h1>TCC411 TX Combiner Controller ver. 2.00</h1>
    <br />
    <br />
    <h2>Functional Description</h2>
    <br />
    <br />
    <div>TCC411&nbsp;FUNCTIONAL&nbsp;DESCRIPTION</div>
    <br />
    <div>
      All&nbsp;test&nbsp;commands&nbsp;for&nbsp;the&nbsp;TCC411&nbsp;Transmitter&nbsp;Combiner&nbsp;Controller&nbsp;consist
    </div>
    <div>
      of&nbsp;2-digits&nbsp;in&nbsp;the&nbsp;range&nbsp;00&nbsp;to&nbsp;99&nbsp;with&nbsp;an&nbsp;optionally&nbsp;parameter.&nbsp;Commands&nbsp;
    </div>
    <div>
      without&nbsp;parameters&nbsp;are&nbsp;display-only&nbsp;commands&nbsp;and&nbsp;will&nbsp;not&nbsp;make&nbsp;any&nbsp;change.
    </div>
    <br />
    <div>
      If&nbsp;the&nbsp;first&nbsp;two&nbsp;characters&nbsp;are&nbsp;not&nbsp;representing&nbsp;a&nbsp;valid&nbsp;number&nbsp;in&nbsp;the
    </div>
    <div>00&nbsp;to&nbsp;99&nbsp;range,&nbsp;an&nbsp;error&nbsp;message&nbsp;will&nbsp;be&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;TCC411&nbsp;command
    </div>
    <br />
    <div>
      This&nbsp;is&nbsp;however&nbsp;not&nbsp;the&nbsp;case&nbsp;if&nbsp;the&nbsp;first&nbsp;character&nbsp;is&nbsp;a&nbsp;+&nbsp;,&nbsp;which&nbsp;are&nbsp;reser-
    </div>
    <div>
      ved&nbsp;for&nbsp;commands&nbsp;to&nbsp;the&nbsp;test&nbsp;box.&nbsp;In&nbsp;this&nbsp;case&nbsp;no&nbsp;response&nbsp;will&nbsp;be&nbsp;sent.
    </div>
    <br />
    <div>
      If&nbsp;an&nbsp;unimplemented&nbsp;command&nbsp;is&nbsp;made,&nbsp;the&nbsp;following&nbsp;response&nbsp;is&nbsp;displayed:
    </div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unimplemented&nbsp;TCC411&nbsp;command
    </div>
    <br />
    <div>BLOCKING</div>
    <br />
    <div>
      After&nbsp;power-on,&nbsp;the&nbsp;TCC411&nbsp;starts&nbsp;up&nbsp;in&nbsp;normal&nbsp;operating&nbsp;mode.&nbsp;Some&nbsp;commands
    </div>
    <div>
      requires&nbsp;blocking&nbsp;to&nbsp;be&nbsp;available.&nbsp;If&nbsp;blocking&nbsp;has&nbsp;not&nbsp;been&nbsp;done,the&nbsp;follow-
    </div>
    <div>ing&nbsp;response&nbsp;is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blocking&nbsp;necessary
    </div>
    <br />
    <div>
      Only&nbsp;commands&nbsp;with&nbsp;a&nbsp;parameter&nbsp;are&nbsp;protected.&nbsp;After&nbsp;activation&nbsp;of&nbsp;local
    </div>
    <div>
      blocking&nbsp;(01+),&nbsp;all&nbsp;the&nbsp;commands&nbsp;are&nbsp;available,&nbsp;except&nbsp;calibration&nbsp;commands.
    </div>
    <br />
    <div>
      If&nbsp;a&nbsp;calibration&nbsp;command&nbsp;is&nbsp;executed&nbsp;and&nbsp;calibration&nbsp;unlock&nbsp;has&nbsp;not&nbsp;been
    </div>
    <div>done,&nbsp;the&nbsp;following&nbsp;response&nbsp;is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Calibration&nbsp;unlock&nbsp;necessary
    </div>
    <br />
    <div>PARAMETERS</div>
    <br />
    <div>Two&nbsp;types&nbsp;of&nbsp;parameters&nbsp;are&nbsp;used.</div>
    <br />
    <div>
      Generally&nbsp;+&nbsp;is&nbsp;used&nbsp;as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;on,&nbsp;enable&nbsp;or&nbsp;step&nbsp;up&nbsp;and&nbsp;-&nbsp;is&nbsp;used
    </div>
    <div>as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;off,&nbsp;disable&nbsp;or&nbsp;step&nbsp;down.</div>
    <br />
    <div>
      For&nbsp;more&nbsp;complex&nbsp;functions,&nbsp;a&nbsp;/&nbsp;is&nbsp;used&nbsp;as&nbsp;separator&nbsp;after&nbsp;the&nbsp;command
    </div>
    <div>
      number&nbsp;followed&nbsp;by&nbsp;the&nbsp;necessary&nbsp;number&nbsp;of&nbsp;characters&nbsp;for&nbsp;the&nbsp;actual
    </div>
    <div>function.</div>
    <br />
    <div>
      If&nbsp;the&nbsp;parameter&nbsp;is&nbsp;not&nbsp;valid&nbsp;for&nbsp;the&nbsp;actual&nbsp;command,&nbsp;the&nbsp;following&nbsp;response
    </div>
    <div>is&nbsp;displayed:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;parameter
    </div>
    <br />
    <div>SETTINGS</div>
    <br />
    <div>
      Calibration&nbsp;and&nbsp;system&nbsp;parameters&nbsp;are&nbsp;stored&nbsp;residently&nbsp;in&nbsp;the&nbsp;micro-
    </div>
    <div>
      processors&nbsp;built-in&nbsp;EEPROM.&nbsp;If&nbsp;the&nbsp;programming&nbsp;succeeded,&nbsp;the&nbsp;following
    </div>
    <div>response&nbsp;is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setting&nbsp;stored&nbsp;in&nbsp;EEPROM
    </div>
    <br />
    <div>
      If&nbsp;a&nbsp;failure&nbsp;during&nbsp;programming&nbsp;is&nbsp;detected,&nbsp;the&nbsp;following&nbsp;response&nbsp;is
    </div>
    <div>displayed:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EEPROM&nbsp;programming&nbsp;error
    </div>
    <br />
    <div>
      Warning:&nbsp;The&nbsp;any&nbsp;EEPROM&nbsp;byte&nbsp;is&nbsp;only&nbsp;guaranteed&nbsp;accept&nbsp;erasure&nbsp;and&nbsp;reprogram-
    </div>
    <div>
      ming&nbsp;up&nbsp;to&nbsp;10.000&nbsp;times.&nbsp;Do&nbsp;not&nbsp;use&nbsp;the&nbsp;O&amp;M&nbsp;Continuous&nbsp;and&nbsp;Repeat&nbsp;commands
    </div>
    <div>here.</div>
    <br />
    <div>*&nbsp;&nbsp;Functional&nbsp;description</div>
    <br />
    <h2>Address commands</h2>
    <br />
    <div>ADDRESS&nbsp;COMMANDS</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Address:&nbsp;nc</div>
    <br />
    <div>Description:</div>
    <div>The&nbsp;address&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;address&nbsp;the&nbsp;TCC411</div>
    <br />
    <div>
      The&nbsp;'A'&nbsp;command&nbsp;returns&nbsp;the&nbsp;TCC411&nbsp;address&nbsp;if&nbsp;the&nbsp;unit&nbsp;has&nbsp;previously&nbsp;been
    </div>
    <div>selected&nbsp;with&nbsp;a&nbsp;Anc&nbsp;command.</div>
    <br />
    <div>
      The&nbsp;'Anc'&nbsp;command&nbsp;selects&nbsp;the&nbsp;TCC411&nbsp;if&nbsp;nc&nbsp;both&nbsp;matches&nbsp;the&nbsp;address&nbsp;input&nbsp;pins&nbsp;
    </div>
    <div>
      coming&nbsp;in&nbsp;the&nbsp;cable&nbsp;from&nbsp;the&nbsp;transceiver&nbsp;cassette.&nbsp;If&nbsp;match&nbsp;it&nbsp;returns&nbsp;the&nbsp;
    </div>
    <div>address&nbsp;string.</div>
    <br />
    <div>
      The&nbsp;'Anc'&nbsp;command&nbsp;deselects&nbsp;the&nbsp;TCC411&nbsp;if&nbsp;nc&nbsp;does&nbsp;not&nbsp;match&nbsp;the&nbsp;address
    </div>
    <div>input&nbsp;pins.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A&nbsp;&nbsp;Display&nbsp;address</strong>
      </div>
      <div>A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>Anc&nbsp;&nbsp;Select&nbsp;address</strong>
      </div>
      <div>&nbsp;┌&nbsp;TR&nbsp;Cassette&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;│┌&nbsp;TX&nbsp;combiner&nbsp;module</div>
      <div>&nbsp;││&nbsp;&nbsp;A:&nbsp;TC&nbsp;for&nbsp;position&nbsp;1..4</div>
      <div>&nbsp;││&nbsp;&nbsp;B:&nbsp;TC&nbsp;for&nbsp;position&nbsp;5..8</div>
      <div>&nbsp;┴┴</div>
      <div>A__</div>
      <div />
      <div />
      <div />
    </div>
    <h2>General commands</h2>
    <br />
    <br />
    <div>00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;DAMM&nbsp;TCC411&nbsp;ver.&nbsp;2.00</div>
    <br />
    <div>Description:</div>
    <div>Displayes&nbsp;the&nbsp;software&nbsp;version&nbsp;number&nbsp;of&nbsp;the&nbsp;EPROM.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00&nbsp;&nbsp;Display&nbsp;software&nbsp;version&nbsp;number</strong>
      </div>
      <div>00</div>
      <div />
      <div />
    </div>
    <div>01&nbsp;&nbsp;BLOCKING/DEBLOCKING</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Blocking:&nbsp;Off</div>
    <div>2)&nbsp;Blocking:&nbsp;On</div>
    <div>3)&nbsp;Deblocking&nbsp;executed</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;make&nbsp;operator&nbsp;blocking&nbsp;and&nbsp;deblocking&nbsp;of&nbsp;the
    </div>
    <div>
      TCC411.&nbsp;During&nbsp;normal&nbsp;operation,&nbsp;the&nbsp;combiner&nbsp;units&nbsp;are&nbsp;controlled&nbsp;from
    </div>
    <div>
      the&nbsp;connected&nbsp;transceivers&nbsp;and&nbsp;the&nbsp;TCC411&nbsp;is&nbsp;protected&nbsp;against&nbsp;operator
    </div>
    <div>
      commands&nbsp;changing&nbsp;the&nbsp;frequencies.&nbsp;When&nbsp;operator&nbsp;blocking&nbsp;is&nbsp;made,&nbsp;the
    </div>
    <div>
      combiner&nbsp;is&nbsp;not&nbsp;longer&nbsp;controlled&nbsp;from&nbsp;the&nbsp;transceiver&nbsp;and&nbsp;full&nbsp;operator
    </div>
    <div>control&nbsp;is&nbsp;possible.</div>
    <br />
    <div>
      The&nbsp;01+&nbsp;and&nbsp;01-&nbsp;commands&nbsp;have&nbsp;no&nbsp;effect,&nbsp;if&nbsp;the&nbsp;states&nbsp;are&nbsp;already&nbsp;present.
    </div>
    <div>
      If&nbsp;local&nbsp;deblocking&nbsp;01-&nbsp;is&nbsp;selected&nbsp;from&nbsp;On,&nbsp;a&nbsp;software&nbsp;reset&nbsp;sequence
    </div>
    <div>equivalent&nbsp;to&nbsp;the&nbsp;power-on&nbsp;reset&nbsp;sequence&nbsp;is&nbsp;executed.</div>
    <br />
    <div>Never&nbsp;leave&nbsp;the&nbsp;TCC411&nbsp;in&nbsp;blocked&nbsp;state!!!</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01&nbsp;&nbsp;Display&nbsp;blocking&nbsp;state</strong>
      </div>
      <div>01</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01+&nbsp;&nbsp;Make&nbsp;blocking</strong>
      </div>
      <div>01+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01-&nbsp;&nbsp;Make&nbsp;deblocking</strong>
      </div>
      <div>01-</div>
      <div />
      <div />
    </div>
    <div>02&nbsp;&nbsp;TCC&nbsp;RESET</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;Reset&nbsp;commands&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02&nbsp;response
    </div>
    <div>2)&nbsp;Software&nbsp;reset&nbsp;executed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02+&nbsp;response</div>
    <div>3)&nbsp;Hardware&nbsp;reset&nbsp;executed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02++&nbsp;response</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;reset&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;bring&nbsp;the&nbsp;TCC411&nbsp;back&nbsp;to&nbsp;a&nbsp;known&nbsp;state.
    </div>
    <br />
    <div>
      The&nbsp;02+&nbsp;software&nbsp;reset&nbsp;command&nbsp;forces&nbsp;the&nbsp;processor&nbsp;to&nbsp;start&nbsp;executing&nbsp;codes
    </div>
    <div>
      from&nbsp;the&nbsp;power-on&nbsp;reset&nbsp;entry&nbsp;point.&nbsp;Complete&nbsp;initialization&nbsp;is&nbsp;made,&nbsp;except
    </div>
    <div>that&nbsp;blocking&nbsp;is&nbsp;maintained.</div>
    <br />
    <div>
      The&nbsp;02++&nbsp;hardware&nbsp;reset&nbsp;command&nbsp;will&nbsp;pull&nbsp;the&nbsp;CPU&nbsp;reset&nbsp;output&nbsp;low&nbsp;and
    </div>
    <div>
      make&nbsp;full&nbsp;hardware&nbsp;reset.&nbsp;Note,&nbsp;that&nbsp;blocking&nbsp;will&nbsp;be&nbsp;off&nbsp;after&nbsp;hardware
    </div>
    <div>reset.</div>
    <br />
    <div>
      The&nbsp;hardware&nbsp;reset&nbsp;is&nbsp;made&nbsp;by&nbsp;stopping&nbsp;the&nbsp;trigging&nbsp;of&nbsp;the&nbsp;watchdog.
    </div>
    <div>
      Therefore&nbsp;0.5&nbsp;sec.&nbsp;will&nbsp;elapse&nbsp;from&nbsp;the&nbsp;command&nbsp;until&nbsp;reset&nbsp;is&nbsp;made.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02+&nbsp;&nbsp;Execute&nbsp;software&nbsp;reset</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>02+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02++&nbsp;&nbsp;Execute&nbsp;hardware&nbsp;reset</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>02++</div>
      <div />
      <div />
    </div>
    <h2>Combiner control commands</h2>
    <br />
    <br />
    <div>10&nbsp;&nbsp;CHANNEL&nbsp;NUMBER</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>1)&nbsp;TC1:&nbsp;0246&nbsp;&nbsp;463.0750&nbsp;0727&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10&nbsp;response</div>
    <div>&nbsp;&nbsp;&nbsp;TC2:&nbsp;0260&nbsp;&nbsp;463.2500&nbsp;0760</div>
    <div>&nbsp;&nbsp;&nbsp;TC3:&nbsp;0274&nbsp;&nbsp;463.4250&nbsp;0747</div>
    <div>&nbsp;&nbsp;&nbsp;TC4:&nbsp;0288&nbsp;&nbsp;463.6000&nbsp;0755</div>
    <div>2)&nbsp;TC2:&nbsp;0260&nbsp;&nbsp;463.2500&nbsp;0760&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10/n&nbsp;response</div>
    <div>
      3)&nbsp;TC2:&nbsp;0261&nbsp;&nbsp;463.2625*0762&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10/n/+,&nbsp;10/n/-,&nbsp;10/n/nnnn&nbsp;response
    </div>
    <div>4)&nbsp;TC2:&nbsp;--End&nbsp;stop--&nbsp;&nbsp;*2000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10/n/E&nbsp;response</div>
    <div>
      5)&nbsp;TC2:&nbsp;--Invalid--&nbsp;&nbsp;&nbsp;&nbsp;0270&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Response&nbsp;when&nbsp;20&nbsp;command&nbsp;has&nbsp;been&nbsp;used
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;10&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;read-out&nbsp;and&nbsp;control&nbsp;the&nbsp;channel&nbsp;number&nbsp;settings
    </div>
    <div>of&nbsp;the&nbsp;combiner&nbsp;sections.</div>
    <br />
    <div>The&nbsp;response&nbsp;contains&nbsp;four&nbsp;fields&nbsp;in&nbsp;each&nbsp;line:</div>
    <br />
    <div>&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;The&nbsp;TC&nbsp;section&nbsp;number.</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;The&nbsp;selected&nbsp;channel&nbsp;number.</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;3)&nbsp;The&nbsp;corresponding&nbsp;frequency&nbsp;in&nbsp;MHz.</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;4)&nbsp;The&nbsp;corresponding&nbsp;step&nbsp;position&nbsp;of&nbsp;the&nbsp;stepper&nbsp;motor.
    </div>
    <br />
    <div>
      The&nbsp;channel&nbsp;number&nbsp;is&nbsp;the&nbsp;physical&nbsp;channel&nbsp;number&nbsp;and&nbsp;is&nbsp;different&nbsp;system&nbsp;
    </div>
    <div>
      channel&nbsp;numbers&nbsp;used&nbsp;in&nbsp;TETRA&nbsp;and&nbsp;NMT&nbsp;signalling.&nbsp;The&nbsp;star&nbsp;ahead&nbsp;of&nbsp;the&nbsp;last&nbsp;
    </div>
    <div>
      field&nbsp;indicates,&nbsp;that&nbsp;tuning&nbsp;is&nbsp;still&nbsp;in&nbsp;progress&nbsp;and&nbsp;the&nbsp;final&nbsp;position&nbsp;has&nbsp;
    </div>
    <div>
      not&nbsp;yet&nbsp;been&nbsp;reached.&nbsp;--End&nbsp;stop--&nbsp;is&nbsp;indicated,&nbsp;when&nbsp;the&nbsp;position&nbsp;is&nbsp;at&nbsp;the
    </div>
    <div>
      mechanical&nbsp;end&nbsp;stop.&nbsp;--Invalid--&nbsp;is&nbsp;indicated,&nbsp;when&nbsp;no&nbsp;valid&nbsp;channel&nbsp;number
    </div>
    <div>
      (frequency)&nbsp;is&nbsp;available,&nbsp;e.g&nbsp;when&nbsp;the&nbsp;20&nbsp;command&nbsp;has&nbsp;been&nbsp;used.
    </div>
    <br />
    <div>
      The&nbsp;10/n/+,&nbsp;10/n/-&nbsp;and&nbsp;10/n/nnnn&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;change&nbsp;the&nbsp;channel
    </div>
    <div>numbers.</div>
    <br />
    <div>
      The&nbsp;10/n/E&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;put&nbsp;the&nbsp;combiner&nbsp;into&nbsp;idle&nbsp;position&nbsp;at&nbsp;the
    </div>
    <div>
      mechanical&nbsp;end&nbsp;stop.&nbsp;To&nbsp;initialize&nbsp;to&nbsp;the&nbsp;end&nbsp;stop,&nbsp;use&nbsp;the&nbsp;20/n/E&nbsp;command
    </div>
    <div>instead.</div>
    <br />
    <div>
      The&nbsp;10/n/EE++++&nbsp;command&nbsp;saves&nbsp;the&nbsp;channel&nbsp;number&nbsp;in&nbsp;EEPROM.&nbsp;This&nbsp;feature
    </div>
    <div>
      is&nbsp;only&nbsp;significant,&nbsp;when&nbsp;manual&nbsp;operating&nbsp;mode&nbsp;has&nbsp;been&nbsp;selected&nbsp;with&nbsp;the
    </div>
    <div>11&nbsp;command.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10&nbsp;&nbsp;Display&nbsp;frequencies</strong>
      </div>
      <div>10</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/n&nbsp;&nbsp;Display&nbsp;frequency</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/n/+&nbsp;&nbsp;Step&nbsp;up&nbsp;one&nbsp;channel</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/_/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/n/-&nbsp;&nbsp;Step&nbsp;down&nbsp;one&nbsp;channel</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/_/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/n/nnnn&nbsp;&nbsp;Change&nbsp;channel&nbsp;no.</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Channel&nbsp;number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴───</div>
      <div>10/_/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/n/E&nbsp;&nbsp;Go&nbsp;to&nbsp;end&nbsp;stop</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/_/E</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/n/EE++++&nbsp;&nbsp;Save&nbsp;channel&nbsp;no.&nbsp;in&nbsp;EEPROM</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;++++</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;┴───</div>
      <div>10/_/EE____</div>
      <div />
      <div />
    </div>
    <div>11&nbsp;&nbsp;OPERATING&nbsp;MODE</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Tuning&nbsp;mode:&nbsp;Automatic</div>
    <div>2)&nbsp;Tuning&nbsp;mode:&nbsp;Manual</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;11&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;read-out&nbsp;and&nbsp;control&nbsp;tuning&nbsp;mode&nbsp;of&nbsp;the&nbsp;combiner.
    </div>
    <div>
      When&nbsp;used&nbsp;in&nbsp;a&nbsp;DAMM&nbsp;base&nbsp;station,&nbsp;automatic&nbsp;mode&nbsp;shall&nbsp;be&nbsp;selected.
    </div>
    <br />
    <div>
      In&nbsp;automatic&nbsp;mode,&nbsp;the&nbsp;frequency&nbsp;is&nbsp;controlled&nbsp;of&nbsp;the&nbsp;connected&nbsp;TR411
    </div>
    <div>
      channel&nbsp;unit.&nbsp;After&nbsp;power-up,&nbsp;the&nbsp;combiner&nbsp;will&nbsp;stay&nbsp;in&nbsp;idle&nbsp;position&nbsp;until
    </div>
    <div>
      the&nbsp;channel&nbsp;unit&nbsp;sends&nbsp;channel&nbsp;order&nbsp;to&nbsp;the&nbsp;combiner&nbsp;on&nbsp;the&nbsp;serial&nbsp;interface
    </div>
    <div>
      between&nbsp;each&nbsp;channel&nbsp;unit&nbsp;and&nbsp;the&nbsp;TCC411.&nbsp;The&nbsp;channel&nbsp;unit&nbsp;will&nbsp;continue&nbsp;to
    </div>
    <div>
      update&nbsp;the&nbsp;channel&nbsp;number&nbsp;each&nbsp;10&nbsp;seconds.&nbsp;If&nbsp;the&nbsp;TCC411&nbsp;has&nbsp;not&nbsp;received
    </div>
    <div>
      update&nbsp;in&nbsp;20&nbsp;seconds,&nbsp;it&nbsp;returns&nbsp;the&nbsp;combiner&nbsp;to&nbsp;idle&nbsp;position&nbsp;(e.g.&nbsp;when&nbsp;a
    </div>
    <div>
      channel&nbsp;unit&nbsp;has&nbsp;been&nbsp;removed).&nbsp;For&nbsp;the&nbsp;functionality&nbsp;in&nbsp;the&nbsp;channel&nbsp;unit,
    </div>
    <div>see&nbsp;the&nbsp;desciption&nbsp;for&nbsp;the&nbsp;TR&nbsp;30&nbsp;command.</div>
    <br />
    <div>
      In&nbsp;manual&nbsp;mode,&nbsp;the&nbsp;channel&nbsp;information&nbsp;from&nbsp;the&nbsp;channel&nbsp;unit&nbsp;is&nbsp;not
    </div>
    <div>
      recognized.&nbsp;After&nbsp;power-up,&nbsp;the&nbsp;combiner&nbsp;will&nbsp;be&nbsp;set&nbsp;to&nbsp;the&nbsp;channel&nbsp;numbers
    </div>
    <div>stored&nbsp;in&nbsp;EEPROM&nbsp;with&nbsp;the&nbsp;10&nbsp;commands&nbsp;immediately.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11&nbsp;&nbsp;Display&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>11</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11-&nbsp;&nbsp;Select&nbsp;manual&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>
        Used&nbsp;when&nbsp;the&nbsp;combiner&nbsp;is&nbsp;NOT&nbsp;controlled&nbsp;of&nbsp;the&nbsp;carrier&nbsp;unit.
      </div>
      <div />
      <div>11-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11+&nbsp;&nbsp;Select&nbsp;automatic&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>Used&nbsp;when&nbsp;the&nbsp;combiner&nbsp;is&nbsp;controlled&nbsp;of&nbsp;the&nbsp;carrier&nbsp;unit.</div>
      <div />
      <div>11+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11/EE++++&nbsp;&nbsp;Save&nbsp;operating&nbsp;mode&nbsp;in&nbsp;EEPROM</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;++++</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>11/EE____</div>
      <div />
      <div />
    </div>
    <div>12&nbsp;&nbsp;TEST&nbsp;POINTS</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;TC1&nbsp;TP:&nbsp;0.31&nbsp;VDC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12&nbsp;response
    </div>
    <div>&nbsp;&nbsp;&nbsp;TC2&nbsp;TP:&nbsp;0.12&nbsp;VDC</div>
    <div>&nbsp;&nbsp;&nbsp;TC3&nbsp;TP:&nbsp;0.48&nbsp;VDC</div>
    <div>&nbsp;&nbsp;&nbsp;TC4&nbsp;TP:&nbsp;0.04&nbsp;VDC</div>
    <div>
      2)&nbsp;TC3&nbsp;TP:&nbsp;0.48&nbsp;VDC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12/n&nbsp;response
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;12&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;read-out&nbsp;the&nbsp;measurement&nbsp;values&nbsp;on&nbsp;the&nbsp;test
    </div>
    <div>
      points&nbsp;for&nbsp;the&nbsp;reflected&nbsp;power&nbsp;from&nbsp;the&nbsp;cavity.&nbsp;The&nbsp;same&nbsp;value&nbsp;can&nbsp;be
    </div>
    <div>read-out&nbsp;with&nbsp;the&nbsp;12&nbsp;command&nbsp;on&nbsp;the&nbsp;TR.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12&nbsp;&nbsp;Display&nbsp;all&nbsp;test&nbsp;points</strong>
      </div>
      <div>12</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12/n&nbsp;&nbsp;Display&nbsp;test&nbsp;point</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>12/_</div>
      <div />
      <div />
    </div>
    <div>13&nbsp;&nbsp;FINE&nbsp;ADJUSTMENT</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;TC1&nbsp;fine&nbsp;adjust:&nbsp;+00&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13&nbsp;response
    </div>
    <div>&nbsp;&nbsp;&nbsp;TC2&nbsp;fine&nbsp;adjust:&nbsp;+05</div>
    <div>&nbsp;&nbsp;&nbsp;TC3&nbsp;fine&nbsp;adjust:&nbsp;-03</div>
    <div>&nbsp;&nbsp;&nbsp;TC4&nbsp;fine&nbsp;adjust:&nbsp;+02</div>
    <div>
      2)&nbsp;TC2&nbsp;fine&nbsp;adjust:&nbsp;+05&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13/n..&nbsp;response
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;13&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;fine&nbsp;adjust&nbsp;the&nbsp;cavities&nbsp;of&nbsp;the&nbsp;combiner.&nbsp;The
    </div>
    <div>
      fine&nbsp;adjustment&nbsp;offsets&nbsp;the&nbsp;motor&nbsp;positions&nbsp;with&nbsp;the&nbsp;selected&nbsp;number&nbsp;of
    </div>
    <div>steps.</div>
    <br />
    <div>
      The&nbsp;fine&nbsp;adjustment&nbsp;is&nbsp;set&nbsp;to&nbsp;00,&nbsp;when&nbsp;the&nbsp;combiner&nbsp;is&nbsp;calibrated&nbsp;on&nbsp;the
    </div>
    <div>
      factory&nbsp;and&nbsp;can&nbsp;be&nbsp;changed&nbsp;in&nbsp;the&nbsp;field,&nbsp;when&nbsp;the&nbsp;center&nbsp;frequencies&nbsp;are&nbsp;not
    </div>
    <div>
      longer&nbsp;accurate.&nbsp;It&nbsp;is&nbsp;normally&nbsp;not&nbsp;necessary&nbsp;to&nbsp;use&nbsp;the&nbsp;fine&nbsp;adjustment
    </div>
    <div>after&nbsp;installation.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13&nbsp;&nbsp;Display&nbsp;fine&nbsp;adjustment&nbsp;settings</strong>
      </div>
      <div>13</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/n&nbsp;&nbsp;Display&nbsp;fine&nbsp;adjustment&nbsp;setting</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>13/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/n/+&nbsp;&nbsp;Increment&nbsp;fine&nbsp;adjustment&nbsp;setting</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>13/_/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/n/-&nbsp;&nbsp;Decrement&nbsp;fine&nbsp;adjustment&nbsp;setting</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>13/_/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/n/snn&nbsp;&nbsp;Change&nbsp;fine&nbsp;adjustment&nbsp;setting</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Fine&nbsp;adjustment&nbsp;setting&nbsp;(-99..+99)</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴──</div>
      <div>13/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/n/EE++++&nbsp;&nbsp;Save&nbsp;fine&nbsp;adjustment&nbsp;setting&nbsp;in&nbsp;EEPROM</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;++++</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;┴───</div>
      <div>13/_/EE____</div>
      <div />
      <div />
    </div>
    <h2>Motor control commands</h2>
    <br />
    <div>20&nbsp;&nbsp;MOTOR&nbsp;CONTROL</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>1)&nbsp;TC1:&nbsp;&nbsp;Set=0727&nbsp;&nbsp;Cur=0727&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;response</div>
    <div>&nbsp;&nbsp;&nbsp;TC2:&nbsp;&nbsp;Set=2000&nbsp;&nbsp;Cur=2000</div>
    <div>&nbsp;&nbsp;&nbsp;TC3:&nbsp;&nbsp;Set=0747&nbsp;&nbsp;Cur=0747</div>
    <div>&nbsp;&nbsp;&nbsp;TC4:&nbsp;&nbsp;Set=0755&nbsp;&nbsp;Cur=0755</div>
    <div>2)&nbsp;TC4:&nbsp;&nbsp;Set=0755&nbsp;&nbsp;Cur=0755&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20/n..&nbsp;response</div>
    <div>
      3)&nbsp;TC3:&nbsp;*Set=0425&nbsp;&nbsp;Cur=0741&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20/n..&nbsp;response,&nbsp;tuning
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;20&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;control&nbsp;the&nbsp;stepper&nbsp;motors&nbsp;directly&nbsp;during&nbsp;test
    </div>
    <div>and&nbsp;calibration.</div>
    <br />
    <div>
      The&nbsp;response&nbsp;includes&nbsp;the&nbsp;selected&nbsp;set&nbsp;value&nbsp;and&nbsp;the&nbsp;current&nbsp;position.&nbsp;A&nbsp;*
    </div>
    <div>ahaed&nbsp;of&nbsp;Set&nbsp;indicates,&nbsp;that&nbsp;tuning&nbsp;is&nbsp;active.</div>
    <br />
    <div>
      The&nbsp;20/n/E&nbsp;command&nbsp;initializes&nbsp;the&nbsp;position&nbsp;to&nbsp;the&nbsp;mechanical&nbsp;end&nbsp;stop&nbsp;by
    </div>
    <div>
      stepping&nbsp;upwards&nbsp;steps&nbsp;enough&nbsp;to&nbsp;always&nbsp;reach&nbsp;the&nbsp;end&nbsp;position.&nbsp;This&nbsp;command
    </div>
    <div>
      shall&nbsp;be&nbsp;used,&nbsp;if&nbsp;manual&nbsp;turn&nbsp;of&nbsp;a&nbsp;motor&nbsp;has&nbsp;been&nbsp;made.
    </div>
    <br />
    <div>
      The&nbsp;20/n/++&nbsp;command&nbsp;steps&nbsp;up&nbsp;the&nbsp;motor&nbsp;position&nbsp;even&nbsp;when&nbsp;the&nbsp;end&nbsp;stop&nbsp;
    </div>
    <div>
      position&nbsp;is&nbsp;reached.&nbsp;The&nbsp;command&nbsp;is&nbsp;used&nbsp;during&nbsp;factory&nbsp;test&nbsp;to&nbsp;check&nbsp;the&nbsp;
    </div>
    <div>
      end&nbsp;stop&nbsp;calibration.&nbsp;Afterwards&nbsp;an&nbsp;end&nbsp;stop&nbsp;init&nbsp;shall&nbsp;be&nbsp;made&nbsp;to&nbsp;make&nbsp;the&nbsp;
    </div>
    <div>position&nbsp;valid&nbsp;again.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20&nbsp;&nbsp;Display&nbsp;motor&nbsp;positions</strong>
      </div>
      <div>20</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/n&nbsp;&nbsp;Display&nbsp;motor&nbsp;position</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>20/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/n/+&nbsp;&nbsp;Step&nbsp;up&nbsp;motor&nbsp;position</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>20/_/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/n/-&nbsp;&nbsp;Step&nbsp;down&nbsp;motor&nbsp;position</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>20/_/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/n/nnnn&nbsp;&nbsp;Change&nbsp;motor&nbsp;position</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Motor&nbsp;position&nbsp;(0000..&lt; end&nbsp;stop&gt;)</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴───</div>
      <div>20/_/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/n/++&nbsp;&nbsp;Forced&nbsp;step&nbsp;up&nbsp;motor&nbsp;position</strong>
      </div>
      <div>Steps&nbsp;up&nbsp;one&nbsp;step&nbsp;even&nbsp;if&nbsp;end&nbsp;position&nbsp;was&nbsp;reached</div>
      <div>Blocking&nbsp;necessary</div>
      <div>
        Warning:&nbsp;End&nbsp;position&nbsp;shall&nbsp;be&nbsp;initialized&nbsp;afterwards&nbsp;to&nbsp;make&nbsp;the&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;position&nbsp;valid.</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>20/_/++</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20/n/E&nbsp;&nbsp;Init&nbsp;to&nbsp;end&nbsp;stop</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>20/_/E</div>
      <div />
      <div />
    </div>
    <div>21&nbsp;&nbsp;MOTOR&nbsp;CONTINUOUS&nbsp;UP/DOWN</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>1)&nbsp;Motor&nbsp;Up/Down&nbsp;command&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21&nbsp;response</div>
    <div>2)&nbsp;TC2:&nbsp;Motor&nbsp;Up/Down&nbsp;cont.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21/n/C&nbsp;response</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;21&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;activate&nbsp;continuous&nbsp;tuning&nbsp;between&nbsp;to&nbsp;fixed
    </div>
    <div>positions.</div>
    <br />
    <div>
      The&nbsp;function&nbsp;is&nbsp;used&nbsp;during&nbsp;factory&nbsp;test&nbsp;to&nbsp;check&nbsp;to&nbsp;voltage&nbsp;margin&nbsp;of&nbsp;the
    </div>
    <div>stepper&nbsp;motor.</div>
    <br />
    <div>
      The&nbsp;tuning&nbsp;will&nbsp;stop&nbsp;automatically,&nbsp;when&nbsp;the&nbsp;next&nbsp;command&nbsp;is&nbsp;received.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/n/C&nbsp;&nbsp;Motor&nbsp;continuous&nbsp;up/down</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>Switches&nbsp;between&nbsp;End_stop-50&nbsp;and&nbsp;End_stop-250&nbsp;continuously</div>
      <div>Stops&nbsp;at&nbsp;reception&nbsp;of&nbsp;next&nbsp;character</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/_/C</div>
      <div />
    </div>
    <h2>Test commands</h2>
    <br />
    <div>60&nbsp;&nbsp;TEMPERATURE</div>
    <br />
    <div>Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
    <div>snn&nbsp;Celsius</div>
    <br />
    <div>Description:</div>
    <div>The&nbsp;command&nbsp;displays&nbsp;the&nbsp;actual&nbsp;temperature.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60&nbsp;&nbsp;Display&nbsp;temperature</strong>
      </div>
      <div>60</div>
      <div />
      <div />
    </div>
    <div>67&nbsp;&nbsp;TR&nbsp;SERIAL&nbsp;INPUT&nbsp;PORTS</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;TR&nbsp;ports=bbbb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b=0:&nbsp;Ground,&nbsp;Logical&nbsp;1
    </div>
    <div> &nbsp;&nbsp;&nbsp;b=1:&nbsp;+5V,&nbsp;Logical&nbsp;0</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displayes&nbsp;the&nbsp;physical&nbsp;input&nbsp;level&nbsp;on&nbsp;the&nbsp;four&nbsp;input&nbsp;pins
    </div>
    <div>(PD5..PD2)&nbsp;receiving&nbsp;the&nbsp;channel&nbsp;number&nbsp;from&nbsp;the&nbsp;transceiver.</div>
    <br />
    <div>The&nbsp;command&nbsp;is&nbsp;used&nbsp;during&nbsp;factory&nbsp;test.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>67&nbsp;&nbsp;Display&nbsp;TR&nbsp;serial&nbsp;input&nbsp;port&nbsp;levels</strong>
      </div>
      <div>67</div>
      <div />
      <div />
    </div>
    <div>68&nbsp;&nbsp;INPUT&nbsp;PORT</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;Input&nbsp;port=&nbsp;bbbbbb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b=0:&nbsp;Low
    </div>
    <div> &nbsp;&nbsp;&nbsp;b=1:&nbsp;High</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displayes&nbsp;the&nbsp;physical&nbsp;input&nbsp;levels&nbsp;on&nbsp;the&nbsp;parallel&nbsp;input&nbsp;port
    </div>
    <br />
    <div>&nbsp;&nbsp;&nbsp;1:&nbsp;End&nbsp;Stop&nbsp;4</div>
    <div>&nbsp;&nbsp;&nbsp;2:&nbsp;End&nbsp;Stop&nbsp;3</div>
    <div>&nbsp;&nbsp;&nbsp;3:&nbsp;End&nbsp;Stop&nbsp;2</div>
    <div>&nbsp;&nbsp;&nbsp;4:&nbsp;End&nbsp;Stop&nbsp;1</div>
    <div>&nbsp;&nbsp;&nbsp;5:&nbsp;(spare)</div>
    <div>&nbsp;&nbsp;&nbsp;6:&nbsp;(spare)</div>
    <br />
    <div>The&nbsp;command&nbsp;is&nbsp;used&nbsp;during&nbsp;factory&nbsp;test.</div>
    <br />
    <div>
      NB:&nbsp;The&nbsp;command&nbsp;is&nbsp;only&nbsp;available&nbsp;in&nbsp;software&nbsp;version&nbsp;2.00&nbsp;or&nbsp;later.
    </div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68&nbsp;&nbsp;Display&nbsp;input&nbsp;port</strong>
      </div>
      <div>68</div>
      <div />
      <div />
    </div>
    <div>69&nbsp;&nbsp;READ&nbsp;MEMORY&nbsp;LOCATION</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;$hhhh=$hh&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Normal&nbsp;response
    </div>
    <div>
      2)&nbsp;$hhhh=Protected&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1000&nbsp;to&nbsp;$1FFF&nbsp;address&nbsp;response
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displays&nbsp;the&nbsp;content&nbsp;of&nbsp;a&nbsp;memory&nbsp;location.&nbsp;The&nbsp;I/O&nbsp;area
    </div>
    <div>
      $1000&nbsp;to&nbsp;$1FFF&nbsp;is&nbsp;protected&nbsp;in&nbsp;order&nbsp;not&nbsp;to&nbsp;disturb&nbsp;read&nbsp;handshake&nbsp;on&nbsp;I/O
    </div>
    <div>ports.</div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69&nbsp;&nbsp;Display&nbsp;last&nbsp;selected&nbsp;memory&nbsp;address</strong>
      </div>
      <div>69</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhh&nbsp;&nbsp;Display&nbsp;memory&nbsp;address&nbsp;hhhh</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Memory&nbsp;address&nbsp;($0000..$0FFF&nbsp;and&nbsp;$2000..$FFFF)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>69/____</div>
      <div />
      <div />
    </div>
    <h2>Calibration</h2>
    <br />
    <div>80&nbsp;&nbsp;CALIBRATION&nbsp;UNLOCK</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Calibration&nbsp;unlock:&nbsp;Off</div>
    <div>2)&nbsp;Calibration&nbsp;unlock:&nbsp;On</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;unlock&nbsp;for&nbsp;calibration&nbsp;during&nbsp;factory&nbsp;calibration.
    </div>
    <br />
    <div>
      Unlocking&nbsp;makes&nbsp;it&nbsp;possible&nbsp;to&nbsp;change&nbsp;the&nbsp;calibration&nbsp;values&nbsp;with&nbsp;the
    </div>
    <div>
      8x&nbsp;commands.&nbsp;Never&nbsp;unlock&nbsp;for&nbsp;calibration&nbsp;in&nbsp;the&nbsp;field,&nbsp;as&nbsp;it&nbsp;can&nbsp;be
    </div>
    <div>
      necessary&nbsp;to&nbsp;return&nbsp;the&nbsp;combiner&nbsp;to&nbsp;the&nbsp;factory&nbsp;for&nbsp;calibration,&nbsp;if&nbsp;the
    </div>
    <div>settings&nbsp;are&nbsp;changed!</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>80&nbsp;&nbsp;Display&nbsp;calibration&nbsp;unlock&nbsp;selection</strong>
      </div>
      <div>80</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>80/UNLOCK&nbsp;&nbsp;Unlock&nbsp;for&nbsp;calibration</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>
        Warning:&nbsp;Execute&nbsp;this&nbsp;command&nbsp;only&nbsp;for&nbsp;a&nbsp;complete&nbsp;recalibration&nbsp;of&nbsp;the
      </div>
      <div> &nbsp;combiner&nbsp;module!!!</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;UNLOCK</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>80/______</div>
      <div />
      <div />
    </div>
    <div>81&nbsp;&nbsp;START&nbsp;FREQUENCY</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Start&nbsp;frequency:&nbsp;460MHz</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;setup&nbsp;the&nbsp;start&nbsp;frequency&nbsp;(lowest&nbsp;frequency)&nbsp;of&nbsp;the
    </div>
    <div>combiner.</div>
    <br />
    <div>
      The&nbsp;value&nbsp;is&nbsp;used&nbsp;of&nbsp;the&nbsp;10&nbsp;command,&nbsp;when&nbsp;the&nbsp;frequency&nbsp;for&nbsp;the&nbsp;selected
    </div>
    <div>channel&nbsp;number&nbsp;is&nbsp;displayed.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>81&nbsp;&nbsp;Display&nbsp;start&nbsp;frequency</strong>
      </div>
      <div>81</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>81/nnnn&nbsp;&nbsp;Change&nbsp;start&nbsp;frequency</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Start&nbsp;frequency&nbsp;in&nbsp;MHz&nbsp;(e.g.&nbsp;0935&nbsp;or&nbsp;0460)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>81/____</div>
      <div />
      <div />
    </div>
    <div>82&nbsp;&nbsp;CHANNEL&nbsp;SEPARATION</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Channel&nbsp;spacing:&nbsp;12.5kHz</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;setup&nbsp;channel&nbsp;spacing&nbsp;of&nbsp;the&nbsp;combiner.
    </div>
    <br />
    <div>
      The&nbsp;value&nbsp;is&nbsp;used&nbsp;of&nbsp;the&nbsp;10&nbsp;command,&nbsp;when&nbsp;the&nbsp;frequency&nbsp;for&nbsp;the&nbsp;selected
    </div>
    <div>channel&nbsp;number&nbsp;is&nbsp;displayed.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>82&nbsp;&nbsp;Display&nbsp;channel&nbsp;separation</strong>
      </div>
      <div>82</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>82/nn&nbsp;&nbsp;Change&nbsp;channel&nbsp;separation</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;12:&nbsp;12.5&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;20:&nbsp;20&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;┴─</div>
      <div>82/__</div>
      <div />
      <div />
    </div>
    <div>83&nbsp;&nbsp;CALIBRATION&nbsp;CHANNEL&nbsp;SPACING</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Cal.&nbsp;channel&nbsp;spacing:&nbsp;26</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;setup&nbsp;the&nbsp;number&nbsp;of&nbsp;channels&nbsp;between&nbsp;each&nbsp;calibration
    </div>
    <div>point.</div>
    <br />
    <div>Value&nbsp;shall&nbsp;be:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BW&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;---------------&nbsp;*&nbsp;--
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Channel&nbsp;spacing&nbsp;&nbsp;&nbsp;31
    </div>
    <br />
    <div>
      where&nbsp;BW&nbsp;is&nbsp;the&nbsp;highest&nbsp;minus&nbsp;the&nbsp;lowest&nbsp;frequency&nbsp;in&nbsp;Hz,&nbsp;and&nbsp;channel
    </div>
    <div>
      spacing&nbsp;is&nbsp;in&nbsp;Hz&nbsp;as&nbsp;well.&nbsp;If&nbsp;result&nbsp;is&nbsp;not&nbsp;an&nbsp;integer,&nbsp;next&nbsp;higher&nbsp;value
    </div>
    <div>shall&nbsp;be&nbsp;used.</div>
    <br />
    <div>
      The&nbsp;value&nbsp;shall&nbsp;place&nbsp;all&nbsp;the&nbsp;calibration&nbsp;points&nbsp;over&nbsp;the&nbsp;used&nbsp;frequency
    </div>
    <div>range.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>83&nbsp;&nbsp;Display&nbsp;calibration&nbsp;channel&nbsp;spacing</strong>
      </div>
      <div>83</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>83/nn&nbsp;&nbsp;Change&nbsp;calibration&nbsp;channel&nbsp;spacing</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Calibration&nbsp;channel&nbsp;spacing&nbsp;(01..99)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;NMT450:&nbsp;26&nbsp;&nbsp;(10MHz&nbsp;BW,&nbsp;12.5kHz&nbsp;channel&nbsp;separation)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;NMT470:&nbsp;17&nbsp;&nbsp;(10MHz&nbsp;BW,&nbsp;20kHz&nbsp;channel&nbsp;separation)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴─</div>
      <div>83/__</div>
      <div />
      <div />
    </div>
    <div>84&nbsp;&nbsp;END&nbsp;STOP&nbsp;POSITION</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;End&nbsp;stop&nbsp;position:&nbsp;2000</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;setup&nbsp;the&nbsp;motor&nbsp;position&nbsp;for&nbsp;the&nbsp;mechanical&nbsp;end
    </div>
    <div>stop.</div>
    <br />
    <div>
      The&nbsp;value&nbsp;shall&nbsp;be&nbsp;setup&nbsp;to&nbsp;a&nbsp;value&nbsp;giving&nbsp;a&nbsp;frequency&nbsp;of&nbsp;the&nbsp;combiner&nbsp;at
    </div>
    <div>
      motor&nbsp;position&nbsp;0&nbsp;a&nbsp;few&nbsp;MHz&nbsp;below&nbsp;the&nbsp;lowest&nbsp;used&nbsp;combiner&nbsp;frequency.
    </div>
    <br />
    <div>
      The&nbsp;lowest&nbsp;calibration&nbsp;point&nbsp;shall&nbsp;after&nbsp;calibration&nbsp;then&nbsp;contain&nbsp;a&nbsp;value
    </div>
    <div>of&nbsp;around&nbsp;200..500.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>84&nbsp;&nbsp;Display&nbsp;end&nbsp;stop&nbsp;position</strong>
      </div>
      <div>84</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>84/nnnn&nbsp;&nbsp;Change&nbsp;end&nbsp;stop&nbsp;position</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>Note:&nbsp;Value&nbsp;is&nbsp;truncated&nbsp;to&nbsp;modulo&nbsp;4</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Motor&nbsp;end&nbsp;stop&nbsp;position</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;900MHz:&nbsp;typ.&nbsp;3500</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;450MHz:&nbsp;typ.&nbsp;2000</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>84/____</div>
      <div />
      <div />
    </div>
    <div>85&nbsp;&nbsp;CALIBRATION&nbsp;VALUES</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>1)&nbsp;Calibration&nbsp;commands&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;85&nbsp;response</div>
    <div>
      2)&nbsp;**&nbsp;TC2&nbsp;VALUES&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;85/n&nbsp;response
    </div>
    <div>&nbsp;&nbsp;&nbsp;00:0364&nbsp;&nbsp;16:1001</div>
    <div>&nbsp;&nbsp;&nbsp;01:0403&nbsp;&nbsp;17:1041</div>
    <div>&nbsp;&nbsp;&nbsp;02:0443&nbsp;&nbsp;18:1081</div>
    <div>&nbsp;&nbsp;&nbsp;03:0483&nbsp;&nbsp;19:1121</div>
    <div>&nbsp;&nbsp;&nbsp;04:0523&nbsp;&nbsp;20:1161</div>
    <div>&nbsp;&nbsp;&nbsp;05:0563&nbsp;&nbsp;21:1201</div>
    <div>&nbsp;&nbsp;&nbsp;06:0603&nbsp;&nbsp;22:1241</div>
    <div>&nbsp;&nbsp;&nbsp;07:0643&nbsp;&nbsp;23:1281</div>
    <div>&nbsp;&nbsp;&nbsp;08:0682&nbsp;&nbsp;24:1321</div>
    <div>&nbsp;&nbsp;&nbsp;09:0722&nbsp;&nbsp;25:1361</div>
    <div>&nbsp;&nbsp;&nbsp;10:0762&nbsp;&nbsp;26:1401</div>
    <div>&nbsp;&nbsp;&nbsp;11:0802&nbsp;&nbsp;27:1441</div>
    <div>&nbsp;&nbsp;&nbsp;12:0842&nbsp;&nbsp;28:1481</div>
    <div>&nbsp;&nbsp;&nbsp;13:0881&nbsp;&nbsp;29:1522</div>
    <div>&nbsp;&nbsp;&nbsp;14:0921&nbsp;&nbsp;30:1562</div>
    <div>&nbsp;&nbsp;&nbsp;15:0961&nbsp;&nbsp;31:1603</div>
    <div>
      3)&nbsp;TC2:&nbsp;0523&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;85/n/nn&nbsp;response
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;display&nbsp;and&nbsp;change&nbsp;the&nbsp;calibration&nbsp;tables&nbsp;for&nbsp;the
    </div>
    <div>combiner.</div>
    <br />
    <div>
      The&nbsp;tables&nbsp;are&nbsp;used&nbsp;to&nbsp;convert&nbsp;from&nbsp;channel&nbsp;number&nbsp;to&nbsp;motor&nbsp;position.&nbsp;There
    </div>
    <div>
      are&nbsp;32&nbsp;calibration&nbsp;values&nbsp;for&nbsp;each&nbsp;combiner&nbsp;section.&nbsp;To&nbsp;get&nbsp;the&nbsp;channel
    </div>
    <div>
      number&nbsp;for&nbsp;a&nbsp;specific&nbsp;calibration&nbsp;point,&nbsp;multiply&nbsp;the&nbsp;calibration&nbsp;point
    </div>
    <div>
      number&nbsp;with&nbsp;the&nbsp;value&nbsp;entered&nbsp;with&nbsp;the&nbsp;83&nbsp;command.&nbsp;For&nbsp;channel&nbsp;numbers
    </div>
    <div>between&nbsp;the&nbsp;calibration&nbsp;points,&nbsp;the&nbsp;TCC411&nbsp;makes&nbsp;interpolation.</div>
    <br />
    <div>
      The&nbsp;calibration&nbsp;table&nbsp;compensates&nbsp;for&nbsp;mechanical&nbsp;differencies&nbsp;between&nbsp;the
    </div>
    <div>
      combiner&nbsp;resonators.&nbsp;When&nbsp;the&nbsp;TCC411&nbsp;is&nbsp;exchanged,&nbsp;the&nbsp;calibration&nbsp;values
    </div>
    <div>can&nbsp;be&nbsp;moved&nbsp;to&nbsp;the&nbsp;new&nbsp;TCC411.</div>
    <br />
    <div>
      Calibration&nbsp;is&nbsp;normally&nbsp;done&nbsp;with&nbsp;a&nbsp;computer&nbsp;controlled&nbsp;test&nbsp;setup&nbsp;in&nbsp;the
    </div>
    <div>
      factory.&nbsp;If&nbsp;the&nbsp;necessary&nbsp;measuring&nbsp;equipment&nbsp;is&nbsp;available,&nbsp;calibration&nbsp;can
    </div>
    <div>also&nbsp;be&nbsp;done&nbsp;manually.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>85&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>85</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>85/n&nbsp;&nbsp;Display&nbsp;calibration&nbsp;values</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>85/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>85/n/nn&nbsp;&nbsp;Display&nbsp;calibration&nbsp;value</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Calibration&nbsp;point&nbsp;number&nbsp;(00..31)</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴─</div>
      <div>85/_/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>85/n/nn/SET&nbsp;&nbsp;Set&nbsp;calibration&nbsp;point&nbsp;to&nbsp;current&nbsp;position</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Calibration&nbsp;point&nbsp;number&nbsp;(00..31)</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴─</div>
      <div>85/_/__/SET</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>85/n/nn/nnnn&nbsp;&nbsp;Change&nbsp;calibration&nbsp;point&nbsp;value</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Combiner&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Calibration&nbsp;point&nbsp;number&nbsp;(00..31)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;┌&nbsp;Motor&nbsp;position&nbsp;(0..&lt; end&nbsp;stop&nbsp;position&gt;)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴─&nbsp;┴───</div>
      <div>85/_/__/____</div>
      <div />
      <div />
    </div>
    <h2>Factory settings</h2>
    <br />
    <div>90&nbsp;&nbsp;BS&nbsp;TYPE</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;BS&nbsp;type:&nbsp;Analog&nbsp;[BS451]</div>
    <div>2)&nbsp;BS&nbsp;type:&nbsp;Digital&nbsp;[BS411]</div>
    <br />
    <div>Description:</div>
    <div>The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;select&nbsp;the&nbsp;type&nbsp;of&nbsp;Base&nbsp;Station.</div>
    <br />
    <div>
      When&nbsp;Analog&nbsp;is&nbsp;selected,&nbsp;the&nbsp;O&amp;M&nbsp;serial&nbsp;interface&nbsp;is&nbsp;always&nbsp;addressed&nbsp;and
    </div>
    <div>the&nbsp;serial&nbsp;speed&nbsp;to&nbsp;TR451&nbsp;is&nbsp;150&nbsp;bit/sec.</div>
    <br />
    <div>
      When&nbsp;Digital&nbsp;is&nbsp;selected,&nbsp;the&nbsp;O&amp;M&nbsp;serial&nbsp;interface&nbsp;is&nbsp;addressed&nbsp;with&nbsp;the
    </div>
    <div>"Anc"-command&nbsp;and&nbsp;the&nbsp;serial&nbsp;speed&nbsp;to&nbsp;TR411&nbsp;is&nbsp;200&nbsp;bit/sec.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90&nbsp;&nbsp;Display&nbsp;BS&nbsp;type&nbsp;selection</strong>
      </div>
      <div>90</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/c&nbsp;&nbsp;Change&nbsp;BS&nbsp;type&nbsp;selection</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;BS&nbsp;type</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Analog&nbsp;[TR451]</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;D:&nbsp;Digital&nbsp;[TR411]</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>90/_</div>
      <div />
      <div />
    </div>
    <div>91&nbsp;&nbsp;THERMOMETER&nbsp;SETTING</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Thermometer&nbsp;setting=&nbsp;snn</div>
    <br />
    <div>Description:</div>
    <div>The&nbsp;command&nbsp;is&nbsp;used&nbsp;to&nbsp;calibrate&nbsp;the&nbsp;Thermometer.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91&nbsp;&nbsp;Display&nbsp;Thermometer&nbsp;setting</strong>
      </div>
      <div>91</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/snn&nbsp;&nbsp;Change&nbsp;Thermometer&nbsp;setting</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Thermometer&nbsp;setting&nbsp;(-29..+29)</div>
      <div>&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/___</div>
      <div />
      <div />
    </div>
    <div>95&nbsp;&nbsp;TYPE&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Item&nbsp;no.:&nbsp;cccccccccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;item&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;item&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;12&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall&nbsp;be
    </div>
    <div>
      setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;type&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>95&nbsp;&nbsp;Display&nbsp;Item&nbsp;number</strong>
      </div>
      <div>95</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>95/ccc..c&nbsp;&nbsp;Change&nbsp;Item&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Item&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────────</div>
      <div>95/____________</div>
      <div />
      <div />
    </div>
    <div>96&nbsp;&nbsp;TYPE&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Type&nbsp;no.:&nbsp;cccccccccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;type&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;type&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;12&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall&nbsp;be
    </div>
    <div>
      setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;type&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96&nbsp;&nbsp;Display&nbsp;Type&nbsp;number</strong>
      </div>
      <div>96</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96/ccc..c&nbsp;&nbsp;Change&nbsp;Type&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Type&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────────</div>
      <div>96/____________</div>
      <div />
      <div />
    </div>
    <div>97&nbsp;&nbsp;SERIAL&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Serial&nbsp;no.:&nbsp;nnnnnnnn</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;serial&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;serial&nbsp;number&nbsp;shall&nbsp;consist&nbsp;of&nbsp;8&nbsp;digits&nbsp;and&nbsp;shall&nbsp;be&nbsp;setup&nbsp;to&nbsp;be&nbsp;equal
    </div>
    <div>to&nbsp;the&nbsp;serial&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97&nbsp;&nbsp;Display&nbsp;Serial&nbsp;number</strong>
      </div>
      <div>97</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97/nnnnnn&nbsp;&nbsp;Change&nbsp;6-digit&nbsp;serial&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;number&nbsp;(000000..999999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>97/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97/nnnnnnnn&nbsp;&nbsp;Change&nbsp;8-digit&nbsp;serial&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;number&nbsp;(00000000..99999999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>97/________</div>
      <div />
      <div />
    </div>
    <div>98&nbsp;&nbsp;VERSION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Version&nbsp;no.:&nbsp;cccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;version&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;version&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;6&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall
    </div>
    <div>
      be&nbsp;setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;version&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98&nbsp;&nbsp;Display&nbsp;Version&nbsp;number</strong>
      </div>
      <div>98</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/cc..c&nbsp;&nbsp;Change&nbsp;Version&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Version&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>98/______</div>
      <div />
      <div />
    </div>
    <div>99&nbsp;&nbsp;REVISION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Revision&nbsp;no.:&nbsp;cccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;revision&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;revision&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;6&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall
    </div>
    <div>
      be&nbsp;setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;revision&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99&nbsp;&nbsp;Display&nbsp;Revision&nbsp;number</strong>
      </div>
      <div>99</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99/cc..c&nbsp;&nbsp;Change&nbsp;Revision&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Revision&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>99/______</div>
      <div />
      <div />
    </div>
    <br />
  </>
);

export default memo(TCC411Page) as typeof TCC411Page;
