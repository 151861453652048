// React
import { Navigate, RestrictedArea, Route, Routes } from '@fjedi/react-router-helpers';
import React, { memo } from 'react';
import { setHTMLTitle } from 'src/components/service-routes';
// Main layout and navigation
import AdminLayout from 'src/components/ui-kit/admin-layout';
// User routes
import BSCListPage from 'src/components/routes/private/bsc';
import DashboardPage from 'src/components/routes/private/dashboard';
import DGNAPage from 'src/components/routes/private/dgna';
import ProfilePage from 'src/components/routes/private/profile';
import SubscribersPage from 'src/components/routes/private/subscribers';
import AdminPage from 'src/components/routes/private/admin';

const PrivateRoot = () => {
  return (
    <AdminLayout>
      {setHTMLTitle('Главная')}
      <Routes>
        <Route
          path="dashboard/*"
          element={
            <RestrictedArea areaType="route" restrictedRoles={['ANONYMOUS']}>
              <DashboardPage />
            </RestrictedArea>
          }
        />
        <Route
          path="bsc-list/*"
          element={
            <RestrictedArea areaType="route" restrictedRoles={['ANONYMOUS']}>
              <BSCListPage />
            </RestrictedArea>
          }
        />
        <Route path="bsc-list" element={<Navigate replace to="bsc-list/alarms" />} />
        <Route
          path="subscribers/*"
          element={
            <RestrictedArea areaType="route" restrictedRoles={['ANONYMOUS']}>
              <SubscribersPage />
            </RestrictedArea>
          }
        />
        <Route path="subscribers" element={<Navigate replace to="subscribers/organizations" />} />
        <Route
          path="dgna/*"
          element={
            <RestrictedArea areaType="route" restrictedRoles={['ANONYMOUS']}>
              <DGNAPage />
            </RestrictedArea>
          }
        />
        <Route
          path="profile/*"
          element={
            <RestrictedArea areaType="route" restrictedRoles={['ANONYMOUS']}>
              <ProfilePage />
            </RestrictedArea>
          }
        />
        <Route
          path="admin/*"
          element={
            <RestrictedArea areaType="route" restrictedRoles={['ANONYMOUS', 'USER']}>
              <AdminPage />
            </RestrictedArea>
          }
        />
        <Route path="profile" element={<Navigate replace to="profile/general" />} />
        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Routes>
    </AdminLayout>
  );
};

PrivateRoot.displayName = 'PrivateRoot';

export default memo(PrivateRoot);
