import React from 'react';
import PropTypes from 'prop-types';
// CSS
import styled from 'styled-components';
//
import { Row as AntRow, Col as AntCol } from 'antd';

export const Row = styled(AntRow)`
  & + & {
    margin-top: 1.5rem;
  }
`;
export const Col = styled(AntCol)``;
