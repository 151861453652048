import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//
import Drawer from 'src/components/ui-kit/drawer';
//
const Container = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 800px !important;
    max-width: 50vw;
  }
`;
//
const DetailsDrawer = props => {
  const { children, onClose, open, title, width, extra } = props;
  return (
    <Container
      title={title}
      width={width}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open ?? false}
      extra={extra}>
      {children}
    </Container>
  );
};

DetailsDrawer.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  extra: PropTypes.node,
};
DetailsDrawer.defaultProps = {};

export default memo(DetailsDrawer);
