import React, { FC, memo } from 'react';

const NodePage: FC = () => (
  <>
    <h1>Node Node Controller 8.xx</h1>
    <br />
    <h2>Software Versions:</h2>
    <br />
    <div>S00&nbsp;&nbsp;SOFTWARE&nbsp;VERSIONS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S00&nbsp;&nbsp;Display&nbsp;Software&nbsp;Version</strong>
      </div>
      <div>S00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S00/A&nbsp;&nbsp;Display&nbsp;all&nbsp;Software&nbsp;Versions</strong>
      </div>
      <div>S00/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S00/C&nbsp;&nbsp;Display&nbsp;Compiler&nbsp;Options</strong>
      </div>
      <div>S00/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S00/MAX&nbsp;&nbsp;Display&nbsp;max.&nbsp;values</strong>
      </div>
      <div>S00/MAX</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S00/OM&nbsp;&nbsp;Display&nbsp;OM&nbsp;Connections</strong>
      </div>
      <div>S00/OM</div>
      <div />
      <div />
    </div>
    <h2>Node Status:</h2>
    <br />
    <div>S01&nbsp;&nbsp;NODE&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S01&nbsp;&nbsp;Display&nbsp;Node&nbsp;Status</strong>
      </div>
      <div>S01</div>
      <div />
      <div />
    </div>
    <h2>Node Controller Activation:</h2>
    <br />
    <div>S02&nbsp;&nbsp;NODE&nbsp;CONTROLLER&nbsp;ACTIVATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S02&nbsp;&nbsp;Display&nbsp;Node&nbsp;Controller&nbsp;Activation&nbsp;Status</strong>
      </div>
      <div>S02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S02/ACTIVATE&nbsp;&nbsp;Node&nbsp;Controller&nbsp;Activation&nbsp;Request</strong>
      </div>
      <div>Sends&nbsp;Request&nbsp;to&nbsp;other&nbsp;NC&nbsp;to&nbsp;take&nbsp;over</div>
      <div>S02/ACTIVATE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S02/STANDBY/s&nbsp;&nbsp;Change&nbsp;Node&nbsp;Controller&nbsp;Forced&nbsp;Standby</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;+:&nbsp;Forced&nbsp;Standby
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Normal&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S02/STANDBY/_</div>
      <div />
      <div />
    </div>
    <h2>Node Alarms:</h2>
    <br />
    <div>S03&nbsp;&nbsp;NODE&nbsp;ALARMS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S03&nbsp;&nbsp;Display&nbsp;Node&nbsp;Alarm&nbsp;Status</strong>
      </div>
      <div>S03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S03/C&nbsp;&nbsp;Clear&nbsp;all&nbsp;Node&nbsp;Alarms</strong>
      </div>
      <div>S03/C</div>
      <div />
      <div />
    </div>
    <h2>License Dongle:</h2>
    <br />
    <div>S04&nbsp;&nbsp;LICENSE&nbsp;DONGLE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S04&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Node&nbsp;Block</strong>
      </div>
      <div>Read&nbsp;from&nbsp;License&nbsp;Dongle&nbsp;at&nbsp;NodeService.exe&nbsp;restart</div>
      <div>S04</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S04/M&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Main&nbsp;Block</strong>
      </div>
      <div>Read&nbsp;from&nbsp;License&nbsp;Dongle&nbsp;at&nbsp;NodeService.exe&nbsp;restart</div>
      <div>S04/M</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S04/I&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Info</strong>
      </div>
      <div>Read&nbsp;from&nbsp;License&nbsp;Dongle&nbsp;at&nbsp;NodeService.exe&nbsp;restart</div>
      <div>S04/I</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S04/D&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Memory&nbsp;in&nbsp;Decimal</strong>
      </div>
      <div>Read&nbsp;from&nbsp;License&nbsp;Dongle&nbsp;at&nbsp;NodeService.exe&nbsp;restart</div>
      <div>S04/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S04/H&nbsp;&nbsp;Display&nbsp;License&nbsp;Dongle&nbsp;Memory&nbsp;in&nbsp;Hex</strong>
      </div>
      <div>
        Displays&nbsp;the&nbsp;settings&nbsp;read&nbsp;from&nbsp;License&nbsp;Dongle&nbsp;at&nbsp;NodeService.exe&nbsp;restart
      </div>
      <div>S04/H</div>
      <div />
      <div />
    </div>
    <h2>Windows IP Paramerters:</h2>
    <br />
    <div>S06&nbsp;&nbsp;WINDOWS&nbsp;IP&nbsp;PARAMETERS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S06&nbsp;&nbsp;Display&nbsp;Windows&nbsp;IP&nbsp;Parameters</strong>
      </div>
      <div>S06</div>
      <div />
      <div />
    </div>
    <h2>Date/Time</h2>
    <br />
    <div>S07&nbsp;&nbsp;DISPLAY&nbsp;DATE&nbsp;AND&nbsp;TIME</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S07&nbsp;&nbsp;Display&nbsp;Date&nbsp;and&nbsp;Time</strong>
      </div>
      <div>S07</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S07/S&nbsp;&nbsp;Display&nbsp;Date/Time&nbsp;status</strong>
      </div>
      <div>S07/S</div>
      <div />
      <div />
    </div>
    <h2>System Nodes status:</h2>
    <br />
    <div>S10&nbsp;&nbsp;SYSTEM&nbsp;NODES&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10&nbsp;&nbsp;Display&nbsp;Network&nbsp;Status</strong>
      </div>
      <div>S10</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/c..&nbsp;&nbsp;Display&nbsp;Node&nbsp;Lists</strong>
      </div>
      <div>Displays&nbsp;only&nbsp;first&nbsp;30&nbsp;Node&nbsp;Controllers</div>
      <div>Displays&nbsp;only&nbsp;Active&nbsp;Node&nbsp;Controllers&nbsp;with&nbsp;function&nbsp;configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;List:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;N&nbsp;&nbsp;:&nbsp;Nodes</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;NA&nbsp;:&nbsp;Node&nbsp;Alarms</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;NV&nbsp;:&nbsp;Node&nbsp;Versions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;NC&nbsp;:&nbsp;Node&nbsp;Configuration</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TRC:&nbsp;TR&nbsp;Configurations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TRO:&nbsp;TR&nbsp;Operational&nbsp;Use</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;L&nbsp;&nbsp;:&nbsp;Log&nbsp;Servers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;S&nbsp;&nbsp;:&nbsp;Subscriber&nbsp;Registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TET:&nbsp;Tetra&nbsp;Cell</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TCC:&nbsp;Tetra&nbsp;Cell&nbsp;Configurations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TCN:&nbsp;Tetra&nbsp;Carrier&nbsp;Numbers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TNC:&nbsp;Tetra&nbsp;Neighbour&nbsp;Cells</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;DMR:&nbsp;DMR&nbsp;Cell</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;DCC:&nbsp;DMR&nbsp;Cell&nbsp;Configurations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;DCN:&nbsp;DMR&nbsp;Carrier&nbsp;Numbers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;DNC:&nbsp;DMR&nbsp;Neighbour&nbsp;Cells</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;ANA:&nbsp;Analog&nbsp;Cell</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;ACC:&nbsp;Analog&nbsp;Cell&nbsp;Configurations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;ACN:&nbsp;Analog&nbsp;Carrier&nbsp;Numbers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;VG&nbsp;:&nbsp;Voice&nbsp;Gateways</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;PG&nbsp;:&nbsp;Packet&nbsp;Data&nbsp;Gateways</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;AG&nbsp;:&nbsp;Application&nbsp;Gateways</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TG&nbsp;:&nbsp;Terminal&nbsp;Gateways</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S10/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/nnn/nn/s/c..&nbsp;&nbsp;Display&nbsp;Node&nbsp;Lists</strong>
      </div>
      <div>Displays&nbsp;only&nbsp;Active&nbsp;Node&nbsp;Controllers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;number&nbsp;of&nbsp;Lines&nbsp;(1..50)&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;NCs&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;-:&nbsp;Display&nbsp;only&nbsp;active&nbsp;NCs&nbsp;with&nbsp;function&nbsp;configured&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;List:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;N&nbsp;&nbsp;:&nbsp;Nodes</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;NA&nbsp;:&nbsp;Node&nbsp;Alarms
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;NV&nbsp;:&nbsp;Node&nbsp;Versions
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;NC&nbsp;:&nbsp;Node&nbsp;Configuration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TRC:&nbsp;TR&nbsp;Configurations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TRO:&nbsp;TR&nbsp;Operational&nbsp;Use
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;L&nbsp;&nbsp;:&nbsp;Log&nbsp;Servers
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;S&nbsp;&nbsp;:&nbsp;Subscriber&nbsp;Registers
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TET:&nbsp;Tetra&nbsp;Cells</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TCC:&nbsp;Tetra&nbsp;Cell&nbsp;Configurations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TCN:&nbsp;Tetra&nbsp;Carrier&nbsp;Numbers
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TNC:&nbsp;Tetra&nbsp;Neighbour&nbsp;Cells
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DMR:&nbsp;DMR&nbsp;Cells</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DCC:&nbsp;DMR&nbsp;Cell&nbsp;Configurations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DCN:&nbsp;DMR&nbsp;Carrier&nbsp;Numbers
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DNC:&nbsp;DMR&nbsp;Neighbour&nbsp;Cells
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;ANA:&nbsp;Analog&nbsp;Cells</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;ACC:&nbsp;Analog&nbsp;Cell&nbsp;Configurations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;ACN:&nbsp;Analog&nbsp;Carrier&nbsp;Numbers
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;VG&nbsp;:&nbsp;Voice&nbsp;Gateways
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;PG&nbsp;:&nbsp;Packet&nbsp;Data&nbsp;Gateways
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;AG&nbsp;:&nbsp;Application&nbsp;Gateways
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TG&nbsp;:&nbsp;Terminal&nbsp;Gateways
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─&nbsp;┴&nbsp;┴──</div>
      <div>S10/___/__/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/nnn/c/c..&nbsp;&nbsp;Display&nbsp;one&nbsp;Node&nbsp;Controller</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;BSC:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Active&nbsp;NC</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;NC1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;NC2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Parameter:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;N&nbsp;&nbsp;:&nbsp;Nodes</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;PS&nbsp;:&nbsp;Power&nbsp;Supply</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TR&nbsp;:&nbsp;Physical&nbsp;TR</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;CAR:&nbsp;Logical&nbsp;CAR</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;S&nbsp;&nbsp;:&nbsp;Subscriber&nbsp;Register
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TET:&nbsp;Tetra&nbsp;Cell</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TCN:&nbsp;Tetra&nbsp;Carrier&nbsp;Numbers
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TNC:&nbsp;Tetra&nbsp;Neighbour&nbsp;Cells
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DMR:&nbsp;Dmr&nbsp;Cell</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DCN:&nbsp;Dmr&nbsp;Carrier&nbsp;Numbers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;DNC:&nbsp;Dmr&nbsp;Neighbour&nbsp;Cells</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;ANA:&nbsp;Analog&nbsp;Cell</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;ACN:&nbsp;Analog&nbsp;Carrier&nbsp;Numbers
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;VG&nbsp;:&nbsp;Voice&nbsp;Gateway</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;PG&nbsp;:&nbsp;Packet&nbsp;Data&nbsp;Gateway
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;AG&nbsp;:&nbsp;Application&nbsp;Gateway</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;TG&nbsp;:&nbsp;Terminal&nbsp;Gateway</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴&nbsp;┴──</div>
      <div>S10/___/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/CLEAR&nbsp;&nbsp;Clear&nbsp;Node&nbsp;Message&nbsp;Sequence&nbsp;Error&nbsp;Counters</strong>
      </div>
      <div>S10/CLEAR</div>
      <div />
    </div>
    <h2>Log Server status:</h2>
    <br />
    <div>S10/LS&nbsp;&nbsp;LOG&nbsp;SERVER&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/LS&nbsp;&nbsp;Display&nbsp;Log&nbsp;Servers</strong>
      </div>
      <div>S10/LS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/LS/c..&nbsp;&nbsp;Display&nbsp;Log&nbsp;Server&nbsp;Lists</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;VER:&nbsp;Software&nbsp;Versions/Dates</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;IP&nbsp;:&nbsp;IP&nbsp;Addresses</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S10/LS/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S10/LS/CLEAR&nbsp;&nbsp;Clear&nbsp;Log&nbsp;Server&nbsp;Message&nbsp;Sequence&nbsp;Error&nbsp;Counters
        </strong>
      </div>
      <div>S10/LS/CLEAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S10/QUE&nbsp;&nbsp;Display&nbsp;Node&nbsp;Output&nbsp;Queues</strong>
      </div>
      <div>S10/QUE</div>
      <div />
      <div />
    </div>
    <h2>Node Status:</h2>
    <br />
    <div>S11&nbsp;&nbsp;NODE&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S11&nbsp;&nbsp;Display&nbsp;Node&nbsp;Status</strong>
      </div>
      <div>S11</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S11/A&nbsp;&nbsp;Display&nbsp;Node&nbsp;Alarm&nbsp;Status</strong>
      </div>
      <div>S11/A</div>
      <div />
      <div />
    </div>
    <h2>Radio Cell Status:</h2>
    <br />
    <div>S12&nbsp;&nbsp;RADIO&nbsp;CELL&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;Status</strong>
      </div>
      <div>S12</div>
      <div />
      <div />
    </div>
    <h2>Physical TR Status:</h2>
    <br />
    <div>S12/TR&nbsp;&nbsp;PHYSICAL&nbsp;TR&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/TR&nbsp;&nbsp;Display&nbsp;Physical&nbsp;TR&nbsp;Status</strong>
      </div>
      <div>S12/TR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/TR/LIM&nbsp;&nbsp;Display&nbsp;Physical&nbsp;TR&nbsp;Limits</strong>
      </div>
      <div>S12/TR/LIM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/TR/IP&nbsp;&nbsp;Display&nbsp;Physical&nbsp;TR&nbsp;IP&nbsp;Connection</strong>
      </div>
      <div>S12/TR/IP</div>
      <div />
      <div />
    </div>
    <h2>Logical Carrier Status:</h2>
    <br />
    <div>S12/CAR&nbsp;&nbsp;LOGICAL&nbsp;CARRIER&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/CAR&nbsp;&nbsp;Display&nbsp;Logical&nbsp;Carrier&nbsp;Status</strong>
      </div>
      <div>S12/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/CAR/nn/n/BLOCK/s&nbsp;&nbsp;Carrier&nbsp;Operator&nbsp;Blocking/Deblocking</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;QQ:&nbsp;---Queue???</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;(1..4)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Action:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Block&nbsp;Timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deblock&nbsp;Timeslot
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S12/CAR/__/_/BLOCK/_</div>
      <div />
      <div />
    </div>
    <h2>Timeslot Status:</h2>
    <br />
    <div>S12/TS&nbsp;&nbsp;TIMESLOT&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/TS/nn/n/BLOCK/s&nbsp;&nbsp;Timeslot&nbsp;Operator&nbsp;Blocking/Deblocking</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;QQ:&nbsp;---Queue???</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Action:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Block&nbsp;Timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deblock&nbsp;Timeslot
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S12/TS/__/_/_/BLOCK/_</div>
      <div />
      <div />
    </div>
    <h2>Simulcast Status:</h2>
    <br />
    <div>S12/SIM&nbsp;&nbsp;SIMULCAST&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/SIM&nbsp;&nbsp;Display&nbsp;Simulcast&nbsp;Channel&nbsp;Status</strong>
      </div>
      <div>S12/SIM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/SIM/nnn/CAR&nbsp;&nbsp;Display&nbsp;Simulcast&nbsp;Channel&nbsp;Carriers</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Simulcast&nbsp;Channel&nbsp;Number&nbsp;(001..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S12/SIM/___/CAR</div>
      <div />
      <div />
    </div>
    <h2>TETRA Cell Status:</h2>
    <br />
    <div>S12/T&nbsp;&nbsp;TETRA&nbsp;CELL&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Cell&nbsp;Status</strong>
      </div>
      <div>S12/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/CAR&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Carrier&nbsp;Status</strong>
      </div>
      <div>S12/T/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/CAR/INFO&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Carrier&nbsp;Info</strong>
      </div>
      <div>S12/T/CAR/INFO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/TS&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Timeslot&nbsp;Status</strong>
      </div>
      <div>S12/T/TS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/CNT&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Timeslot&nbsp;Counts</strong>
      </div>
      <div>S12/T/CNT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/NC&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Neighbour&nbsp;Cells</strong>
      </div>
      <div>S12/T/NC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/PD&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Packet&nbsp;Data&nbsp;Counters</strong>
      </div>
      <div>S12/T/PD</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/PD/C&nbsp;&nbsp;Clear&nbsp;TETRA&nbsp;Packet&nbsp;Data&nbsp;Counters</strong>
      </div>
      <div>S12/T/PD/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/T/CCK/c&nbsp;&nbsp;TETRA&nbsp;CCK&nbsp;change&nbsp;request</strong>
      </div>
      <div>Requests&nbsp;immediate&nbsp;update&nbsp;of&nbsp;CCK&nbsp;for&nbsp;all&nbsp;mobiles</div>
      <div>with&nbsp;Air&nbsp;Interface&nbsp;Encryption.</div>
      <div>The&nbsp;Key&nbsp;change&nbsp;is&nbsp;about&nbsp;6&nbsp;seconds&nbsp;after&nbsp;the&nbsp;command.</div>
      <div>See&nbsp;also&nbsp;S12&nbsp;command</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;CCK&nbsp;change&nbsp;request&nbsp;kind
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unannounced</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Announced</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S12/T/CCK/_</div>
      <div />
      <div />
      <div />
    </div>
    <h2>DMR Cell Status:</h2>
    <br />
    <div>S12/D&nbsp;&nbsp;DMR&nbsp;CELL&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/D&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Cell&nbsp;Status</strong>
      </div>
      <div>S12/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/D/CAR&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Carrier&nbsp;Status</strong>
      </div>
      <div>S12/D/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/D/TS&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Timeslot&nbsp;Status</strong>
      </div>
      <div>S12/D/TS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/D/CNT&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Timeslot&nbsp;Counts</strong>
      </div>
      <div>S12/D/CNT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/D/NC&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Neighbour&nbsp;Cells</strong>
      </div>
      <div>S12/D/NC</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Analog Cell Status:</h2>
    <br />
    <div>S12/A&nbsp;&nbsp;ANALOG&nbsp;CELL&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/A&nbsp;&nbsp;Display&nbsp;Analog&nbsp;Cell&nbsp;Status</strong>
      </div>
      <div>S12/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/A/CAR&nbsp;&nbsp;Display&nbsp;Analog&nbsp;Carrier&nbsp;Status</strong>
      </div>
      <div>S12/A/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S12/A/TS&nbsp;&nbsp;Display&nbsp;Analog&nbsp;Timeslot&nbsp;Status</strong>
      </div>
      <div>S12/A/TS</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Voice Gateway Status:</h2>
    <br />
    <div>S13&nbsp;&nbsp;VOICE&nbsp;GATEWAY&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S13&nbsp;&nbsp;Display&nbsp;Voice&nbsp;Gateway&nbsp;Status</strong>
      </div>
      <div>S13</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S13/L&nbsp;&nbsp;Display&nbsp;Voice&nbsp;Gateway&nbsp;Status&nbsp;List</strong>
      </div>
      <div>S13/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S13/nnn&nbsp;&nbsp;Display&nbsp;Voice&nbsp;Gateway&nbsp;Instance&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Voice&nbsp;Gateway&nbsp;Instance&nbsp;Number&nbsp;(000..nnn)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S13/___</div>
      <div />
      <div />
    </div>
    <h2>Packet Data Gateway Status:</h2>
    <br />
    <div>S14&nbsp;&nbsp;PACKET&nbsp;DATA&nbsp;GATEWAY&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S14&nbsp;&nbsp;Display&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;Status</strong>
      </div>
      <div>S14</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S14/N&nbsp;&nbsp;Display&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;Counters</strong>
      </div>
      <div>S14/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S14/C&nbsp;&nbsp;Clear&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;Counters</strong>
      </div>
      <div>S14/C</div>
      <div />
      <div />
    </div>
    <h2>Application Gateway Status:</h2>
    <br />
    <div>S15&nbsp;&nbsp;APPLICATION&nbsp;GATEWAY&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S15&nbsp;&nbsp;Display&nbsp;Application&nbsp;Gateway&nbsp;Status</strong>
      </div>
      <div>S15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S15/L&nbsp;&nbsp;Display&nbsp;Application&nbsp;Gateway&nbsp;Connection&nbsp;List</strong>
      </div>
      <div>S15/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S15/IX/nnn&nbsp;&nbsp;Display&nbsp;Application&nbsp;Gateway&nbsp;Connection&nbsp;(Index)</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Index&nbsp;(000..nnn)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S15/IX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S15/SSI/nnnnnnnn&nbsp;&nbsp;Display&nbsp;Application&nbsp;Gateway&nbsp;Instance&nbsp;(SSI)</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S15/SSI/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S15/USERNO/nn..n&nbsp;&nbsp;Display&nbsp;Application&nbsp;Gateway&nbsp;Connection&nbsp;(User&nbsp;Number)
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>S15/USERNO/_______</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Terminal Gateway Status:</h2>
    <br />
    <div>S16&nbsp;&nbsp;TERMINAL&nbsp;GATEWAY&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S16&nbsp;&nbsp;Display&nbsp;Terminal&nbsp;Gateway&nbsp;Status</strong>
      </div>
      <div>S16</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S16/L&nbsp;&nbsp;Display&nbsp;Terminal&nbsp;Gateway&nbsp;Connection&nbsp;List</strong>
      </div>
      <div>S16/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S16/IX/nnn&nbsp;&nbsp;Display&nbsp;Terminal&nbsp;Gateway&nbsp;Connection&nbsp;(Index)</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Index&nbsp;(000..nnn)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S16/IX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S16/SSI/nnnnnnnn&nbsp;&nbsp;Display&nbsp;Terminal&nbsp;Gateway&nbsp;Connection&nbsp;(SSI)</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S16/SSI/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S16/USERNO/nn..n&nbsp;&nbsp;Display&nbsp;Terminal&nbsp;Gateway&nbsp;Connection&nbsp;(User&nbsp;Number)
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>S16/USERNO/_______</div>
      <div />
      <div />
    </div>
    <h2>Subscriber Register:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register</strong>
      </div>
      <div>S20</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/S&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>S20/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/CNT&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register&nbsp;Counts</strong>
      </div>
      <div>S20/CNT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/CKS&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register&nbsp;Checksums</strong>
      </div>
      <div>S20/CKS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/DL&nbsp;&nbsp;Display&nbsp;Master&nbsp;Subscriber&nbsp;Register&nbsp;Download&nbsp;status</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;status&nbsp;of&nbsp;the&nbsp;Master&nbsp;Subscriber&nbsp;Register</div>
      <div>S20/DL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/REG&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Registration&nbsp;Update&nbsp;status</strong>
      </div>
      <div>Displays&nbsp;status&nbsp;of&nbsp;Subscriber&nbsp;Registration&nbsp;Update</div>
      <div>
        Runs&nbsp;on&nbsp;all&nbsp;active&nbsp;NC's&nbsp;to&nbsp;send&nbsp;Registration&nbsp;status&nbsp;of&nbsp;all
      </div>
      <div>registered&nbsp;SSI&nbsp;on&nbsp;this&nbsp;Node&nbsp;to&nbsp;other&nbsp;nodes.</div>
      <div>S20/REG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/REG/+&nbsp;&nbsp;Start&nbsp;Subscriber&nbsp;Registration&nbsp;Update</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;manually&nbsp;start&nbsp;a&nbsp;complete&nbsp;update&nbsp;of&nbsp;all&nbsp;other&nbsp;Nodes
      </div>
      <div>with&nbsp;registration&nbsp;information&nbsp;of&nbsp;regstered&nbsp;SSI's&nbsp;on&nbsp;this&nbsp;Node.</div>
      <div>NB:&nbsp;Command&nbsp;is&nbsp;for&nbsp;test&nbsp;purposes&nbsp;only.</div>
      <div>S20/REG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/TEI/nnn..n&nbsp;&nbsp;Find&nbsp;TEI&nbsp;Terminal&nbsp;Equipment&nbsp;Identity</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TEI&nbsp;(15&nbsp;digits&nbsp;0..9,&nbsp;A..F)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S20/TEI/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/SIM/nnn..n&nbsp;&nbsp;Find&nbsp;SIM&nbsp;Card&nbsp;Identity</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SIM&nbsp;(20&nbsp;digits&nbsp;0..9,&nbsp;A..F)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────</div>
      <div>S20/SIM/____________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/SECSET&nbsp;&nbsp;Display&nbsp;Security&nbsp;Set&nbsp;Renew&nbsp;Status</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;status&nbsp;of&nbsp;Security&nbsp;Set&nbsp;renewal</div>
      <div>
        NB:&nbsp;Requires&nbsp;Key&nbsp;Register&nbsp;active&nbsp;and&nbsp;Subscriber&nbsp;Register&nbsp;not&nbsp;in&nbsp;slave&nbsp;mode
      </div>
      <div>S20/SECSET</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/SECSET/RENEW&nbsp;&nbsp;Start&nbsp;Security&nbsp;Set&nbsp;Renewal</strong>
      </div>
      <div>Starts&nbsp;renewal&nbsp;of&nbsp;the&nbsp;Security&nbsp;Set&nbsp;for&nbsp;all&nbsp;Individual&nbsp;SSI.</div>
      <div>
        The&nbsp;new&nbsp;Security&nbsp;Sets&nbsp;will&nbsp;be&nbsp;automatically&nbsp;replicated&nbsp;to&nbsp;all&nbsp;Nodes
      </div>
      <div>
        1)&nbsp;If&nbsp;SIM&nbsp;is&nbsp;setup,&nbsp;SIM&nbsp;will&nbsp;be&nbsp;used&nbsp;for&nbsp;Key&nbsp;search
      </div>
      <div>
        2)&nbsp;Else&nbsp;if&nbsp;TEI&nbsp;is&nbsp;setup,&nbsp;TEI&nbsp;will&nbsp;be&nbsp;used&nbsp;for&nbsp;Key&nbsp;search
      </div>
      <div>3)&nbsp;Else&nbsp;no&nbsp;renewal&nbsp;is&nbsp;done</div>
      <div>
        NB:&nbsp;Requires&nbsp;Key&nbsp;Register&nbsp;active&nbsp;and&nbsp;Subscriber&nbsp;Register&nbsp;not&nbsp;in&nbsp;slave&nbsp;mode
      </div>
      <div>S20/SECSET/RENEW</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/SAVE&nbsp;&nbsp;Save&nbsp;Subscriber&nbsp;Register&nbsp;to&nbsp;OM&nbsp;command&nbsp;file</strong>
      </div>
      <div>Saved&nbsp;in&nbsp;"Subscr.txt"&nbsp;file&nbsp;in&nbsp;"Data"&nbsp;directory</div>
      <div>S20/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/READ&nbsp;&nbsp;Read&nbsp;Subscriber&nbsp;Register&nbsp;from&nbsp;OM&nbsp;command&nbsp;file</strong>
      </div>
      <div>Reads&nbsp;and&nbsp;executes&nbsp;"Subscr.txt"&nbsp;file&nbsp;in&nbsp;"Data"&nbsp;directory</div>
      <div>
        Note,&nbsp;that&nbsp;it&nbsp;will&nbsp;add/change&nbsp;to&nbsp;the&nbsp;existing&nbsp;subscriber&nbsp;register.
      </div>
      <div />
      <div>
        The&nbsp;subscriber&nbsp;register&nbsp;can&nbsp;be&nbsp;cleaned&nbsp;before&nbsp;the&nbsp;read-in&nbsp;with&nbsp;the&nbsp;following
      </div>
      <div>procedure:</div>
      <div>&nbsp;&nbsp;1)&nbsp;Stop&nbsp;the&nbsp;NC&nbsp;with&nbsp;Master&nbsp;Subscriber&nbsp;Register</div>
      <div>
        &nbsp;&nbsp;2)&nbsp;Delete&nbsp;manually&nbsp;the&nbsp;files&nbsp;OrgV2.bin,&nbsp;ProfV2.bin&nbsp;and&nbsp;SubscrV2.bin
      </div>
      <div>&nbsp;&nbsp;3)&nbsp;Restart&nbsp;the&nbsp;Node&nbsp;Controller</div>
      <div />
      <div>All&nbsp;Slaves&nbsp;will&nbsp;automatically&nbsp;be&nbsp;synchronized&nbsp;the&nbsp;the&nbsp;Master.</div>
      <div>S20/READ</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/CHECK&nbsp;&nbsp;Subscriber&nbsp;Register&nbsp;consistency&nbsp;check</strong>
      </div>
      <div>S20/CHECK</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/QUE&nbsp;&nbsp;Display&nbsp;Subscriber&nbsp;Register&nbsp;queues</strong>
      </div>
      <div>S20/QUE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/TSICHAIN/nnnnnn&nbsp;&nbsp;Display&nbsp;TSI&nbsp;Chain&nbsp;List</strong>
      </div>
      <div>NB:&nbsp;For&nbsp;debug&nbsp;only!</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SubIx&nbsp;(000000..nnnnnn)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>S20/TSICHAIN/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/TSI/.../L&nbsp;&nbsp;Display&nbsp;TSI&nbsp;Chain&nbsp;List</strong>
      </div>
      <div>NB:&nbsp;For&nbsp;debug&nbsp;only!</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(Mobile&nbsp;Country&nbsp;Code)&nbsp;(0001..1023)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(Mobile&nbsp;Network&nbsp;Code)&nbsp;(0001..16383)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(Short&nbsp;Subscriber&nbsp;Identity&nbsp;(0001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>S20/TSI/____:_____:________/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S20/TSIIXTB&nbsp;&nbsp;Display&nbsp;TSI&nbsp;Index&nbsp;Table</strong>
      </div>
      <div>NB:&nbsp;For&nbsp;debug&nbsp;only!</div>
      <div>S20/TSIIXTB</div>
      <div />
      <div />
    </div>
    <h2>Profile Register:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21&nbsp;&nbsp;Display&nbsp;Profile&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>S21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn&nbsp;&nbsp;Display&nbsp;Profile</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;Profile&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..50)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>S21/____/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/ADD/nnn/cc..c&nbsp;&nbsp;Add/Change&nbsp;Profile</strong>
      </div>
      <div>
        The&nbsp;Home&nbsp;Organization&nbsp;number&nbsp;is&nbsp;used&nbsp;separate&nbsp;subscriber&nbsp;into&nbsp;isolated
      </div>
      <div>organizations.</div>
      <div>
        Note,&nbsp;that&nbsp;Home&nbsp;Organization&nbsp;value&nbsp;0&nbsp;has&nbsp;the&nbsp;special&nbsp;meaning&nbsp;"All"&nbsp;and&nbsp;is
      </div>
      <div>intended&nbsp;to&nbsp;be&nbsp;used&nbsp;for&nbsp;e.g.&nbsp;the&nbsp;system&nbsp;operator.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Home&nbsp;Organization&nbsp;(000..999)&nbsp;(0=All)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Description
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴───────────────────────</div>
      <div>S21/____/ADD/___/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/ASSORG&nbsp;&nbsp;Display&nbsp;Associated&nbsp;Organizations</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/ASSORG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/ASSORG/s&nbsp;&nbsp;Change&nbsp;use&nbsp;of&nbsp;Associated&nbsp;Organizations</strong>
      </div>
      <div>Activates/deactivates&nbsp;the&nbsp;use&nbsp;of&nbsp;Associated&nbsp;Organizations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Associated&nbsp;Organizations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/ASSORG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/ASSORG/nnn/s&nbsp;&nbsp;Add/Remove&nbsp;Associated&nbsp;Organization</strong>
      </div>
      <div>Maximum&nbsp;16&nbsp;Associated&nbsp;Organizations&nbsp;can&nbsp;setup</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Associated&nbsp;Organization&nbsp;Number&nbsp;(001..999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Action
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Add
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Remove
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴</div>
      <div>S21/____/ASSORG/___/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SSICALL/s&nbsp;&nbsp;Setup&nbsp;SSI&nbsp;Call&nbsp;Permission</strong>
      </div>
      <div>Selects&nbsp;whether&nbsp;Individual&nbsp;Calls&nbsp;are&nbsp;allowed&nbsp;with&nbsp;SSI</div>
      <div>
        If&nbsp;not&nbsp;selected,&nbsp;Individual&nbsp;Calls&nbsp;are&nbsp;only&nbsp;allowed&nbsp;wit&nbsp;User&nbsp;Number
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Allow&nbsp;Individual&nbsp;Calls&nbsp;with&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Only&nbsp;allow&nbsp;Individual&nbsp;Calls&nbsp;with&nbsp;User&nbsp;Number
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/SSICALL/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SSIGROUP/s&nbsp;&nbsp;Use&nbsp;SSI&nbsp;as&nbsp;Talking&nbsp;Party&nbsp;Identification&nbsp;for&nbsp;Group&nbsp;Calls
        </strong>
      </div>
      <div>
        Selects&nbsp;the&nbsp;use&nbsp;of&nbsp;SSI&nbsp;as&nbsp;Talking&nbsp;Party&nbsp;Identification&nbsp;for&nbsp;Group&nbsp;Calls
      </div>
      <div>to&nbsp;bypass&nbsp;the&nbsp;bug&nbsp;in&nbsp;certain&nbsp;mobiles&nbsp;(Motorola)</div>
      <div>
        NB:&nbsp;This&nbsp;flag&nbsp;affects&nbsp;only&nbsp;GSSI's&nbsp;attached&nbsp;to&nbsp;the&nbsp;Profile&nbsp;(not&nbsp;ISSI)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Use&nbsp;SSI&nbsp;as&nbsp;TPI&nbsp;for&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Use&nbsp;User&nbsp;Number&nbsp;as&nbsp;TPI&nbsp;for&nbsp;Group&nbsp;Call&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/SSIGROUP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/AUTH/s&nbsp;&nbsp;Change&nbsp;Authentication&nbsp;capability&nbsp;required&nbsp;flag</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Authentication&nbsp;capability&nbsp;required&nbsp;at&nbsp;registration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Authentication&nbsp;capability&nbsp;not&nbsp;required&nbsp;at&nbsp;registration&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/AUTH/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/AIE/s&nbsp;&nbsp;Change&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;flag
        </strong>
      </div>
      <div>
        If&nbsp;the&nbsp;flag&nbsp;is&nbsp;set&nbsp;a&nbsp;check&nbsp;for&nbsp;match&nbsp;between&nbsp;the&nbsp;Profiles&nbsp;required&nbsp;TEAx
      </div>
      <div>and&nbsp;the&nbsp;mobiles&nbsp;supported&nbsp;TEAx&nbsp;is&nbsp;done.&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;at&nbsp;registration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;capability&nbsp;not&nbsp;required&nbsp;at&nbsp;registration&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/AIE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/E2EE/s&nbsp;&nbsp;Change&nbsp;End-To-End&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;flag
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;End-To-End&nbsp;Encryption&nbsp;capability&nbsp;required&nbsp;at&nbsp;registration
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;End-To-End&nbsp;Encryption&nbsp;capability&nbsp;not&nbsp;required&nbsp;at&nbsp;registration&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/E2EE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SECMODE/n&nbsp;&nbsp;Setup&nbsp;Security&nbsp;Mode</strong>
      </div>
      <div>
        For&nbsp;optional&nbsp;modes,&nbsp;service&nbsp;will&nbsp;be&nbsp;allowed&nbsp;without&nbsp;Authentication/Air
      </div>
      <div>
        InterFace&nbsp;encryption,&nbsp;when&nbsp;no&nbsp;Security&nbsp;Set&nbsp;is&nbsp;available&nbsp;or&nbsp;when&nbsp;the&nbsp;SSI
      </div>
      <div>
        Authentication&nbsp;and&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;disable&nbsp;flags&nbsp;are&nbsp;set.
      </div>
      <div>
        For&nbsp;mandatory&nbsp;modes,&nbsp;service&nbsp;will&nbsp;only&nbsp;be&nbsp;permitted&nbsp;with&nbsp;full&nbsp;Authentication
      </div>
      <div>and&nbsp;Air&nbsp;Interface&nbsp;Encryption.</div>
      <div>
        Note,&nbsp;that&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;requires,&nbsp;that&nbsp;permission&nbsp;is&nbsp;activated
      </div>
      <div>in&nbsp;the&nbsp;License&nbsp;Dongle.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Mode:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;Class&nbsp;1,&nbsp;no&nbsp;Auth./AIE
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Class&nbsp;1,&nbsp;Auth.&nbsp;optional,&nbsp;AIE&nbsp;none
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;Class&nbsp;1,&nbsp;Auth.&nbsp;mandatory,&nbsp;AIE&nbsp;none
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;Class&nbsp;3,&nbsp;Aut.&nbsp;optional,&nbsp;AIE&nbsp;optional
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;Class&nbsp;3,&nbsp;Auth.&nbsp;mandatory,&nbsp;AIE&nbsp;mandatory
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/SECMODE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SECKSG/nn&nbsp;&nbsp;Setup&nbsp;Key&nbsp;Stream&nbsp;Generator</strong>
      </div>
      <div>Used&nbsp;with&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;only</div>
      <div>Note,&nbsp;that&nbsp;the&nbsp;TR&nbsp;shall&nbsp;support&nbsp;the&nbsp;selected&nbsp;KSG.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;KSG:</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;TEA1
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;TEA2
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;TEA3
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;TEA4
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04..15:&nbsp;Reserved
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S21/____/SECKSG/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SHORTNO/n/nn..n&nbsp;&nbsp;Setup&nbsp;Short&nbsp;Number&nbsp;Format</strong>
      </div>
      <div>Specifies&nbsp;Short&nbsp;Number&nbsp;format</div>
      <div>
        When&nbsp;a&nbsp;number&nbsp;with&nbsp;a&nbsp;length&nbsp;of&nbsp;'Short&nbsp;Number&nbsp;Length'&nbsp;is&nbsp;dialled&nbsp;from&nbsp;the&nbsp;MS,
      </div>
      <div>
        the&nbsp;'Short&nbsp;Number&nbsp;Prefix'&nbsp;is&nbsp;automatically&nbsp;added&nbsp;before&nbsp;the&nbsp;lookup&nbsp;in&nbsp;the
      </div>
      <div>Subscriber&nbsp;Register.</div>
      <div>Leading&nbsp;zeroes&nbsp;in&nbsp;the&nbsp;Prefix&nbsp;are&nbsp;not&nbsp;significant.</div>
      <div>
        The&nbsp;sum&nbsp;of&nbsp;'Short&nbsp;Number&nbsp;Length'&nbsp;and&nbsp;the&nbsp;length&nbsp;of&nbsp;'Short&nbsp;Number&nbsp;Prefix'&nbsp;may
      </div>
      <div>not&nbsp;exceed&nbsp;7&nbsp;digits.</div>
      <div>The&nbsp;function&nbsp;has&nbsp;no&nbsp;effect&nbsp;for&nbsp;PSTN&nbsp;calls.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Short&nbsp;Number&nbsp;Length&nbsp;(0..6)&nbsp;(0=Disabled)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Short&nbsp;Number&nbsp;Prefix&nbsp;(""&nbsp;to&nbsp;"999999")
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴─────</div>
      <div>S21/____/SHORTNO/_/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/DISPNO/nnnnnnn&nbsp;&nbsp;Setup&nbsp;Dispatcher&nbsp;User&nbsp;Number</strong>
      </div>
      <div>User&nbsp;Number&nbsp;of&nbsp;the&nbsp;Dispatcher&nbsp;used&nbsp;to&nbsp;authorize&nbsp;calls</div>
      <div>Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Dispatcher&nbsp;User&nbsp;Number&nbsp;(1..9999999,&nbsp;blank&nbsp;or&nbsp;0&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>S21/____/DISPNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PRIORITY/nn&nbsp;&nbsp;Select&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Call&nbsp;Priority</div>
      <div>This&nbsp;will&nbsp;overrule&nbsp;Call&nbsp;Priority&nbsp;request&nbsp;1..14&nbsp;from&nbsp;mobiles</div>
      <div>
        Emergency&nbsp;Priority&nbsp;15&nbsp;and&nbsp;16&nbsp;from&nbsp;mobiles&nbsp;will&nbsp;always&nbsp;be&nbsp;passed&nbsp;unchanged
      </div>
      <div>Mobile&nbsp;controlled&nbsp;Priority&nbsp;is&nbsp;default&nbsp;(see&nbsp;this&nbsp;command)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Priority
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..05:&nbsp;Low&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=0)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06..11:&nbsp;High&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=1)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12..14:&nbsp;Pre-emptive&nbsp;Priority&nbsp;(PTT&nbsp;Priority=2)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S21/____/PRIORITY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PRIORITY/-&nbsp;&nbsp;Select&nbsp;Mobile&nbsp;controlled&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Lets&nbsp;the&nbsp;mobile&nbsp;control&nbsp;the&nbsp;Call&nbsp;and&nbsp;PTT&nbsp;Priority</div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/PRIORITY/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/INDIVTIMEOUT/nn.n&nbsp;&nbsp;Set&nbsp;Individual&nbsp;PTT&nbsp;Call&nbsp;Inactivity&nbsp;Timeout
        </strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;time&nbsp;from&nbsp;last&nbsp;PTT&nbsp;released&nbsp;until&nbsp;Individual&nbsp;PTT&nbsp;Call&nbsp;termination.
      </div>
      <div>Default&nbsp;setting&nbsp;is&nbsp;Disabled.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Individuel&nbsp;PTT&nbsp;Call&nbsp;Inactivity&nbsp;timeout
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Disabled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00.1..25.0:&nbsp;Timeout&nbsp;value&nbsp;[minutes]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───
      </div>
      <div>S21/____/INDIVTIMEOUT/__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/INDIVTIMEOUT/-&nbsp;&nbsp;Disable&nbsp;Individual&nbsp;PTT&nbsp;Call&nbsp;Inactivity&nbsp;Timeout
        </strong>
      </div>
      <div>Disables&nbsp;timeout&nbsp;of&nbsp;Individual&nbsp;PTT&nbsp;Call.</div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/INDIVTIMEOUT/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CALLDURMAX/nnn&nbsp;&nbsp;Set&nbsp;max.&nbsp;Call&nbsp;Duration</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;maximum&nbsp;call&nbsp;duration&nbsp;of&nbsp;Individual&nbsp;Calls.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;infinity&nbsp;(see&nbsp;this&nbsp;command)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max&nbsp;Call&nbsp;Duration&nbsp;(001..250&nbsp;minutes)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S21/____/CALLDURMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CALLDURMAX/-&nbsp;&nbsp;Set&nbsp;Call&nbsp;Duration&nbsp;limit&nbsp;to&nbsp;infinity</strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;maximum&nbsp;call&nbsp;duration&nbsp;of&nbsp;Individual&nbsp;Calls&nbsp;to&nbsp;infinity.
      </div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/CALLDURMAX/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PTTDURMAX/nnn&nbsp;&nbsp;Set&nbsp;max.&nbsp;PTT&nbsp;Duration</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;maximun&nbsp;talking&nbsp;period&nbsp;at&nbsp;Simplex&nbsp;Calls</div>
      <div>
        The&nbsp;default&nbsp;value&nbsp;is&nbsp;disabled&nbsp;(Mobile&nbsp;controlled)&nbsp;(see&nbsp;this&nbsp;command)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max&nbsp;PTT&nbsp;Duration&nbsp;(002..250&nbsp;seconds)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S21/____/PTTDURMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PTTDURMAX/-&nbsp;&nbsp;Disable&nbsp;PTT&nbsp;Duration&nbsp;limit</strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;maximun&nbsp;talking&nbsp;period&nbsp;at&nbsp;Simplex&nbsp;Calls&nbsp;to&nbsp;be&nbsp;controlled
      </div>
      <div>of&nbsp;the&nbsp;Mobile.</div>
      <div>
        The&nbsp;system&nbsp;will&nbsp;still&nbsp;limit&nbsp;the&nbsp;time&nbsp;to&nbsp;maximum&nbsp;310&nbsp;seconds,
      </div>
      <div>
        which&nbsp;is&nbsp;longer&nbsp;than&nbsp;the&nbsp;maximum&nbsp;300&nbsp;second&nbsp;setting&nbsp;for&nbsp;the&nbsp;mobile.
      </div>
      <div>This&nbsp;is&nbsp;the&nbsp;default&nbsp;setting.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/PTTDURMAX/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/LATEENTRY/nn&nbsp;&nbsp;Set&nbsp;Late&nbsp;Entry&nbsp;Timer</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Late&nbsp;Entry&nbsp;Timer.</div>
      <div>Controls&nbsp;the&nbsp;duraton&nbsp;between&nbsp;active&nbsp;Group&nbsp;announcements&nbsp;on</div>
      <div>the&nbsp;Control&nbsp;Channel.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;15&nbsp;seconds.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Late&nbsp;Entry&nbsp;Timer&nbsp;(01..60&nbsp;seconds)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S21/____/LATEENTRY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/LATEENTRY/-&nbsp;&nbsp;Disable&nbsp;Late&nbsp;Entry&nbsp;Timer</strong>
      </div>
      <div>Disables&nbsp;Late&nbsp;Entry&nbsp;Timer.</div>
      <div>Stops&nbsp;the&nbsp;active&nbsp;Group&nbsp;announcements&nbsp;on&nbsp;the&nbsp;Control&nbsp;Channel.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/LATEENTRY/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SCCH/-&nbsp;&nbsp;Select&nbsp;Auto&nbsp;SCCH&nbsp;allocation</strong>
      </div>
      <div>Selects&nbsp;automatic&nbsp;allocation&nbsp;SCCH's&nbsp;on&nbsp;BS.</div>
      <div>The&nbsp;actually&nbsp;used&nbsp;Timeslot&nbsp;for&nbsp;Control&nbsp;Channel&nbsp;</div>
      <div>depends&nbsp;on&nbsp;the&nbsp;number&nbsp;of&nbsp;SCCH's&nbsp;setup&nbsp;on&nbsp;the</div>
      <div>BS&nbsp;Main&nbsp;Carrier.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/SCCH/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SCCH/nn&nbsp;&nbsp;Select&nbsp;Manual&nbsp;SCCH&nbsp;allocation</strong>
      </div>
      <div>Selects&nbsp;manually&nbsp;allocation&nbsp;of&nbsp;SCCH's&nbsp;on&nbsp;BS.</div>
      <div>The&nbsp;actually&nbsp;used&nbsp;Timeslot&nbsp;for&nbsp;Control&nbsp;Channel&nbsp;</div>
      <div>depends&nbsp;on&nbsp;the&nbsp;number&nbsp;of&nbsp;SCCH's&nbsp;setup&nbsp;on&nbsp;the</div>
      <div>BS&nbsp;Main&nbsp;Carrier.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MCCH&nbsp;only&nbsp;&nbsp;&nbsp;One&nbsp;SCCH&nbsp;&nbsp;&nbsp;&nbsp;Two&nbsp;SCCH&nbsp;&nbsp;&nbsp;&nbsp;Three&nbsp;SCCH
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;----------&nbsp;&nbsp;----------&nbsp;&nbsp;----------&nbsp;&nbsp;----------
      </div>
      <div>
        00:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;
      </div>
      <div>01:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS2(SCCH1)&nbsp;</div>
      <div>
        02:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS3(SCCH2)&nbsp;
      </div>
      <div>
        03:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS4(SCCH3)&nbsp;
      </div>
      <div>
        04:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS1(MCCH)&nbsp;
      </div>
      <div>05:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS2(SCCH1)&nbsp;</div>
      <div>
        06:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS3(SCCH2)&nbsp;
      </div>
      <div>07:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS4(SCCH3)&nbsp;</div>
      <div>
        08:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS1(MCCH)&nbsp;
      </div>
      <div>
        09:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;
      </div>
      <div>
        10:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS3(SCCH2)&nbsp;
      </div>
      <div>11:&nbsp;&nbsp;TS1(MCCH)&nbsp;&nbsp;&nbsp;TS2(SCCH1)&nbsp;&nbsp;TS3(SCCH2)&nbsp;&nbsp;TS4(SCCH3)&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SCCH&nbsp;Allocation&nbsp;(00..11)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S21/____/SCCH/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PD/MS/nn/nnn&nbsp;&nbsp;Change&nbsp;PD&nbsp;Channel&nbsp;Mobile&nbsp;allocation</strong>
      </div>
      <div>
        Changes&nbsp;the&nbsp;way&nbsp;Mobiles&nbsp;are&nbsp;allocated&nbsp;to&nbsp;Packed&nbsp;Data&nbsp;Channels.
      </div>
      <div>
        Mobiles&nbsp;only&nbsp;share&nbsp;a&nbsp;PD&nbsp;Channel&nbsp;with&nbsp;Mobiles&nbsp;of&nbsp;same&nbsp;Class.
      </div>
      <div>
        Class&nbsp;00&nbsp;does&nbsp;not&nbsp;allow&nbsp;sharing,&nbsp;but&nbsp;allows&nbsp;use&nbsp;of&nbsp;multi-slot&nbsp;PD&nbsp;Channels.
      </div>
      <div>
        Class&nbsp;01..99&nbsp;allows&nbsp;sharing&nbsp;up&nbsp;to&nbsp;the&nbsp;max&nbsp;number&nbsp;of&nbsp;Mobiles&nbsp;on&nbsp;a&nbsp;single-slot&nbsp;PDCH.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class:</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Default&nbsp;(No&nbsp;PD&nbsp;Channel&nbsp;sharing,&nbsp;multi-&nbsp;or&nbsp;single-slot)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..99:&nbsp;Class&nbsp;of&nbsp;sharing&nbsp;Mobiles&nbsp;(PD&nbsp;Channel&nbsp;sharing,&nbsp;single-slot&nbsp;only)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Max.&nbsp;no.&nbsp;of&nbsp;Mobiles&nbsp;sharing&nbsp;a&nbsp;PD&nbsp;Channel&nbsp;(001..100)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴──</div>
      <div>S21/____/PD/MS/__/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PD/TIMER/nnn&nbsp;&nbsp;Change&nbsp;PD&nbsp;Channel&nbsp;Ready&nbsp;Timer</strong>
      </div>
      <div>Changes&nbsp;the&nbsp;Ready&nbsp;Timer&nbsp;for&nbsp;the&nbsp;Packed&nbsp;Data&nbsp;Channel</div>
      <div>Can&nbsp;increase&nbsp;the&nbsp;Ready&nbsp;Timer&nbsp;for&nbsp;e.g.&nbsp;SCADA&nbsp;systems</div>
      <div>Default:&nbsp;5&nbsp;sec.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Ready&nbsp;Timer&nbsp;(005..250&nbsp;sec.)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S21/____/PD/TIMER/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CLASS/M&nbsp;&nbsp;Setup&nbsp;Subscriber&nbsp;Class&nbsp;Mobile&nbsp;controlled</strong>
      </div>
      <div>See&nbsp;also&nbsp;Subscriber&nbsp;Class&nbsp;in&nbsp;Node&nbsp;configuration</div>
      <div>Mobile&nbsp;controlled&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/CLASS/M</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CLASS/ss...&nbsp;&nbsp;Setup&nbsp;Subscriber&nbsp;Class&nbsp;Network&nbsp;controlled</strong>
      </div>
      <div>Note,&nbsp;that&nbsp;all&nbsp;-&nbsp;means&nbsp;Mobile&nbsp;controlled</div>
      <div>See&nbsp;also&nbsp;Subscriber&nbsp;Class&nbsp;in&nbsp;Node&nbsp;configuration</div>
      <div>Mobile&nbsp;controlled&nbsp;is&nbsp;the&nbsp;default&nbsp;setting</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class&nbsp;1&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;Class&nbsp;2&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;Class&nbsp;3&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;Class&nbsp;4&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;Class&nbsp;5&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;Class&nbsp;6&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;Class&nbsp;7&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;Class&nbsp;8&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;Class&nbsp;9&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;Class&nbsp;10&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;Class&nbsp;11&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;Class&nbsp;12&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;Class&nbsp;13&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;Class&nbsp;14&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;Class&nbsp;15&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;Class&nbsp;16&nbsp;(+,-)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1234567890122456</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴</div>
      <div>S21/____/CLASS/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SERVRESTR&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Service&nbsp;Restrictions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/SERVRESTR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SERVRESTR/s&nbsp;&nbsp;Change&nbsp;SSI&nbsp;Service&nbsp;Restriction&nbsp;activation</strong>
      </div>
      <div>Selects&nbsp;activation/deactivation&nbsp;of&nbsp;all&nbsp;SSI&nbsp;Service&nbsp;Restrictions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Restrictions&nbsp;active&nbsp;according&nbsp;to&nbsp;individual&nbsp;flags
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;All&nbsp;Restrictions&nbsp;disabled&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/SERVRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/SERVPERM/c&nbsp;&nbsp;Display&nbsp;Service&nbsp;Permission&nbsp;flags</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Kind
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;M:&nbsp;Mobile&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;D:&nbsp;Dial-In&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Application&nbsp;SSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Terminal&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/SERVPERM/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/M/nn/c&nbsp;&nbsp;Change&nbsp;Mobile&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Mobile&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;09:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;10:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;11:&nbsp;Packet&nbsp;Data&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Service&nbsp;allowed&nbsp;with&nbsp;Dispatcher&nbsp;Authorization&nbsp;(00..07&nbsp;and&nbsp;12..13&nbsp;only)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴
      </div>
      <div>S21/____/SERVPERM/M/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/D/nn/s&nbsp;&nbsp;Change&nbsp;Dial-In&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Dial-In&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴
      </div>
      <div>S21/____/SERVPERM/D/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/A/nn/s&nbsp;&nbsp;Change&nbsp;Application&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Application&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴
      </div>
      <div>S21/____/SERVPERM/A/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/T/nn/s&nbsp;&nbsp;Change&nbsp;Terminal&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flag
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Terminal&nbsp;SSI&nbsp;Permission&nbsp;Flag&nbsp;Number&nbsp;(00..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴
      </div>
      <div>S21/____/SERVPERM/T/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/M/cc..c&nbsp;&nbsp;Change&nbsp;all&nbsp;Mobile&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A:&nbsp;Service&nbsp;allowed&nbsp;with&nbsp;Dispatcher&nbsp;Authorization&nbsp;(00..07&nbsp;and&nbsp;12..13&nbsp;only)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;Individual&nbsp;Duplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;Individual&nbsp;Simplex&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;Packet&nbsp;Data&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>S21/____/SERVPERM/M/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/D/ss..s&nbsp;&nbsp;Change&nbsp;all&nbsp;Dial-In&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dial-In&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>S21/____/SERVPERM/D/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/A/ss..s&nbsp;&nbsp;Change&nbsp;all&nbsp;Application&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Application&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>S21/____/SERVPERM/A/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/SERVPERM/T/ss..s&nbsp;&nbsp;Change&nbsp;all&nbsp;Terminal&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;flags
        </strong>
      </div>
      <div>
        NB:&nbsp;Common&nbsp;Service&nbsp;Restrictions&nbsp;shall&nbsp;be&nbsp;activated&nbsp;for&nbsp;individual&nbsp;flags&nbsp;to&nbsp;be
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terminal&nbsp;SSI&nbsp;Service&nbsp;Permission&nbsp;Flags:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+:&nbsp;Service&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-:&nbsp;Service&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;01:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;02:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;03:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;04:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;05:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Application&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;06:&nbsp;Group&nbsp;Call
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;07:&nbsp;Send&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;08:&nbsp;Receive&nbsp;Individual&nbsp;SDS
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;09:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;10:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;11:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;12:&nbsp;Individual&nbsp;Call&nbsp;to&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;13:&nbsp;Individual&nbsp;Call&nbsp;from&nbsp;Terminal&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;14:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;15:&nbsp;(Spare)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴
      </div>
      <div>S21/____/SERVPERM/T/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/GROUPRESTR/s&nbsp;&nbsp;Change&nbsp;Group&nbsp;Call&nbsp;Restriction</strong>
      </div>
      <div>
        The&nbsp;flag&nbsp;restricts&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;to&nbsp;make&nbsp;Group&nbsp;Calls.
      </div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;cleared,&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;make&nbsp;call&nbsp;to&nbsp;all&nbsp;
      </div>
      <div>
        Home&nbsp;and&nbsp;Associated&nbsp;Groups&nbsp;according&nbsp;to&nbsp;each&nbsp;Groups&nbsp;specific&nbsp;restriction.&nbsp;
      </div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;set&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;make&nbsp;call&nbsp;to&nbsp;Groups
      </div>
      <div>listed&nbsp;in&nbsp;the&nbsp;Group&nbsp;Permission&nbsp;table.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Individual&nbsp;Subscribers&nbsp;Group&nbsp;Call&nbsp;Restrictions:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Call&nbsp;allowed&nbsp;to&nbsp;all&nbsp;Groups,&nbsp;only&nbsp;limited&nbsp;by
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the&nbsp;Groups&nbsp;specific&nbsp;restriction.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Call&nbsp;only&nbsp;allowed&nbsp;to&nbsp;Groups&nbsp;listed&nbsp;in&nbsp;the
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Group&nbsp;Permission&nbsp;table.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/GROUPRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/GROUPPERM&nbsp;&nbsp;Display&nbsp;Group&nbsp;Permission&nbsp;table</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/GROUPPERM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/GROUPPERM/nnnnnnn/ADD/s/s&nbsp;&nbsp;Add/Change&nbsp;Group&nbsp;Permission&nbsp;table&nbsp;entry
        </strong>
      </div>
      <div>
        The&nbsp;Group&nbsp;Permission&nbsp;Table&nbsp;is&nbsp;used&nbsp;to&nbsp;give&nbsp;Individual&nbsp;Subscribers&nbsp;permission
      </div>
      <div>to&nbsp;make&nbsp;Groups&nbsp;call&nbsp;to&nbsp;specific&nbsp;Groups.</div>
      <div>
        By&nbsp;default&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;make&nbsp;Group&nbsp;Call&nbsp;to&nbsp;all&nbsp;Groups&nbsp;belonging
      </div>
      <div>
        to&nbsp;Home&nbsp;and&nbsp;Associated&nbsp;Organizations&nbsp;independent&nbsp;of&nbsp;the&nbsp;permission&nbsp;in&nbsp;this
      </div>
      <div>table.</div>
      <div>
        Group&nbsp;Calls&nbsp;for&nbsp;the&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;be&nbsp;restricted&nbsp;in
      </div>
      <div>two&nbsp;independet&nbsp;ways:</div>
      <div>1)&nbsp;Profile&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;Profile,&nbsp;all&nbsp;the&nbsp;Profiles
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;make&nbsp;Group&nbsp;Calls&nbsp;to&nbsp;the&nbsp;Groups
      </div>
      <div>&nbsp;&nbsp;&nbsp;included&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>2)&nbsp;Group&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;specific&nbsp;Group&nbsp;SSI,
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;call&nbsp;the&nbsp;Group&nbsp;if&nbsp;it&nbsp;is&nbsp;included
      </div>
      <div>&nbsp;&nbsp;&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>A&nbsp;maximum&nbsp;of&nbsp;16&nbsp;permitted&nbsp;groups&nbsp;can&nbsp;be&nbsp;setup.</div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PTT&nbsp;Permission
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;PTT&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;PTT&nbsp;not&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;SDS&nbsp;Permission
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;SDS&nbsp;allowed&nbsp;to&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;SDS&nbsp;not&nbsp;allowed&nbsp;to&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴
      </div>
      <div>S21/____/GROUPPERM/_______/ADD/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/GROUPPERM/nnnnnnn/REM&nbsp;&nbsp;Remove&nbsp;Group&nbsp;Permission&nbsp;table&nbsp;entry
        </strong>
      </div>
      <div>
        The&nbsp;Group&nbsp;Permission&nbsp;Table&nbsp;is&nbsp;used&nbsp;to&nbsp;give&nbsp;Individual&nbsp;Subscribers&nbsp;permission
      </div>
      <div>to&nbsp;make&nbsp;Groups&nbsp;call&nbsp;to&nbsp;specific&nbsp;Groups.</div>
      <div>
        By&nbsp;default&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;make&nbsp;Group&nbsp;Call&nbsp;to&nbsp;all&nbsp;Groups&nbsp;belonging
      </div>
      <div>
        to&nbsp;Home&nbsp;and&nbsp;Associated&nbsp;Organizations&nbsp;independent&nbsp;of&nbsp;the&nbsp;permission&nbsp;in&nbsp;this
      </div>
      <div>table.</div>
      <div>
        Group&nbsp;Calls&nbsp;for&nbsp;the&nbsp;the&nbsp;Profiles&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;be&nbsp;restricted&nbsp;in
      </div>
      <div>two&nbsp;independet&nbsp;ways:</div>
      <div>1)&nbsp;Profile&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;Profile,&nbsp;all&nbsp;the&nbsp;Profiles
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;make&nbsp;Group&nbsp;Calls&nbsp;to&nbsp;the&nbsp;Groups
      </div>
      <div>&nbsp;&nbsp;&nbsp;included&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>2)&nbsp;Group&nbsp;restriction:</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;the&nbsp;Group&nbsp;Restriction&nbsp;flag&nbsp;is&nbsp;set&nbsp;in&nbsp;the&nbsp;specific&nbsp;Group&nbsp;SSI,
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Individual&nbsp;Subscribers&nbsp;can&nbsp;only&nbsp;call&nbsp;the&nbsp;Group&nbsp;if&nbsp;it&nbsp;is&nbsp;included
      </div>
      <div>&nbsp;&nbsp;&nbsp;in&nbsp;this&nbsp;table.</div>
      <div>A&nbsp;maximum&nbsp;of&nbsp;16&nbsp;permitted&nbsp;groups&nbsp;can&nbsp;be&nbsp;setup.</div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>S21/____/GROUPPERM/_______/REM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CELL&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;Restrictions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/CELL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CELL/s&nbsp;&nbsp;Activate/Deactivate&nbsp;Radio&nbsp;Cell&nbsp;Restrictions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Radio&nbsp;Cell&nbsp;restrictions
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate&nbsp;(Only&nbsp;permitted&nbsp;Radio&nbsp;Cells&nbsp;allowed)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate&nbsp;(All&nbsp;Radio&nbsp;Cells&nbsp;allowed)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S21/____/CELL/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/CELL/nnn/ss..s&nbsp;&nbsp;Setup&nbsp;Radio&nbsp;Cell&nbsp;Permissions</strong>
      </div>
      <div>
        For&nbsp;Mobile&nbsp;SSI's&nbsp;the&nbsp;flag&nbsp;gives&nbsp;permission&nbsp;to&nbsp;the&nbsp;Tetra&nbsp;Mobile&nbsp;to&nbsp;register
      </div>
      <div>and&nbsp;use&nbsp;the&nbsp;Radio&nbsp;Cell.</div>
      <div>
        For&nbsp;Group&nbsp;SSI's&nbsp;the&nbsp;flag&nbsp;gives&nbsp;permission&nbsp;for&nbsp;the&nbsp;group&nbsp;to&nbsp;be&nbsp;setup&nbsp;in&nbsp;the
      </div>
      <div>Radio&nbsp;Cell.&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;of&nbsp;first&nbsp;flag&nbsp;(000..999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Permission&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Mobile&nbsp;or&nbsp;Group&nbsp;allowed&nbsp;in&nbsp;Radio&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Mobile&nbsp;or&nbsp;Group&nbsp;not&nbsp;allowed&nbsp;in&nbsp;Radio&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─────────────────────────────────────────────────
      </div>
      <div>S21/____/CELL/___/__________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/VGNODE/n/nnn&nbsp;&nbsp;Setup&nbsp;Voice&nbsp;Gateway&nbsp;Node</strong>
      </div>
      <div>Voice&nbsp;Gateway&nbsp;Nodes&nbsp;used&nbsp;of&nbsp;the&nbsp;Profile&nbsp;members.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;Primary&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Alternate&nbsp;Voice&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..999,&nbsp;000&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──</div>
      <div>S21/____/VGNODE/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/PGNODE/n/nnn&nbsp;&nbsp;Setup&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;Node</strong>
      </div>
      <div>Packet&nbsp;Date&nbsp;Gateway&nbsp;Nodes&nbsp;used&nbsp;of&nbsp;the&nbsp;Profile&nbsp;members.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;Primary&nbsp;Packet&nbsp;Data&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Alternate&nbsp;Packet&nbsp;Data&nbsp;Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..999,&nbsp;000&nbsp;for&nbsp;disabled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──</div>
      <div>S21/____/PGNODE/_/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/POSUSERNO/n/nn..n&nbsp;&nbsp;Setup&nbsp;Position&nbsp;Destination&nbsp;User&nbsp;Number
        </strong>
      </div>
      <div>Terminal&nbsp;Gateway&nbsp;sends&nbsp;Position&nbsp;Report&nbsp;to&nbsp;these&nbsp;Numbers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;Destination&nbsp;1
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Destination&nbsp;2
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(1..9999999,&nbsp;blank&nbsp;or&nbsp;0&nbsp;for&nbsp;disabled)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────
      </div>
      <div>S21/____/POSUSERNO/_/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/DNS/...&nbsp;&nbsp;Setup&nbsp;DNS&nbsp;Server&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>
        DNS&nbsp;Server&nbsp;IP&nbsp;Address&nbsp;given&nbsp;to&nbsp;the&nbsp;Mobile&nbsp;at&nbsp;DHCP&nbsp;Request
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S21/____/DNS/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/STFWDUSERNO/...&nbsp;&nbsp;Setup&nbsp;Store/Forward&nbsp;Center&nbsp;User&nbsp;Number</strong>
      </div>
      <div>User&nbsp;Number&nbsp;for&nbsp;the&nbsp;SDS&nbsp;Store/Forward&nbsp;Center.</div>
      <div>
        If&nbsp;the&nbsp;MS&nbsp;requests&nbsp;a&nbsp;valid&nbsp;number,&nbsp;this&nbsp;will&nbsp;be&nbsp;used&nbsp;instead.
      </div>
      <div>
        All&nbsp;SDS&nbsp;will&nbsp;be&nbsp;forwarded&nbsp;directly&nbsp;to&nbsp;the&nbsp;end&nbsp;destination,&nbsp;if&nbsp;no
      </div>
      <div>valid&nbsp;S/F-Center&nbsp;address&nbsp;is&nbsp;available.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1..9999999:&nbsp;User&nbsp;Number&nbsp;of&nbsp;Store/Forward&nbsp;Center.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Used&nbsp;if&nbsp;a&nbsp;valid&nbsp;number&nbsp;is&nbsp;not&nbsp;received&nbsp;from&nbsp;the&nbsp;MS.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setting&nbsp;up&nbsp;the&nbsp;S/F-Center&nbsp;number&nbsp;here&nbsp;is&nbsp;recommended.&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Blank&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;MS&nbsp;shall&nbsp;provide&nbsp;the&nbsp;Store/Forward&nbsp;Center&nbsp;number.&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Store/Forward&nbsp;Center&nbsp;never&nbsp;used&nbsp;(SDS&nbsp;send&nbsp;directly).&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S21/____/STFWDUSERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S21/nnnn/APPLSERVUSERNO/nn..n&nbsp;&nbsp;Setup&nbsp;Application&nbsp;Server&nbsp;User&nbsp;Number
        </strong>
      </div>
      <div>User&nbsp;Number&nbsp;for&nbsp;the&nbsp;Application&nbsp;Server</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(1..9999999,&nbsp;blank&nbsp;or&nbsp;0&nbsp;for&nbsp;none)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S21/____/APPLSERVUSERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S21/nnnn/DEL&nbsp;&nbsp;Delete&nbsp;Profile</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S21/____/DEL</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Subscriber SSI Register:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>S22</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn&nbsp;&nbsp;Display&nbsp;SSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;SSI&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..45)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>S22/________/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/USER/nnnnnnn&nbsp;&nbsp;Display&nbsp;User&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>S22/USER/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/USER/nnnnnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;User&nbsp;Number&nbsp;list</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..45)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>S22/USER/_______/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/IP/nnn.nnn.nnn.nnn&nbsp;&nbsp;Display&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>Only&nbsp;LSB's&nbsp;are&nbsp;significant</div>
      <div>
        Address&nbsp;will&nbsp;be&nbsp;masked&nbsp;with&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;Mask
      </div>
      <div>Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;value&nbsp;is&nbsp;added&nbsp;when&nbsp;displayed</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S22/IP/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/IP/nnn.nnn.nnn.nnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;IP&nbsp;Address&nbsp;list</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;First&nbsp;IP&nbsp;Address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..45)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>S22/IP/___.___.___.___/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/TSI/nnnn:nnnnn:nnnnnnnn&nbsp;&nbsp;Display&nbsp;TSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;of&nbsp;TSI&nbsp;(0001..1023)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;of&nbsp;TSI&nbsp;(0001..16383)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;of&nbsp;TSI&nbsp;(0001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>S22/TSI/____:_____:________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/REG&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Registration</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/REG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/REG/GROUP&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Attached&nbsp;Groups</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/REG/GROUP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/ADD/c/...&nbsp;&nbsp;Add/Change&nbsp;SSI</strong>
      </div>
      <div>User&nbsp;Number:</div>
      <div>&nbsp;&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;With&nbsp;all-zeroes&nbsp;User&nbsp;Number&nbsp;is&nbsp;disabled</div>
      <div>IP&nbsp;Address:</div>
      <div>&nbsp;&nbsp;Only&nbsp;LSB's&nbsp;are&nbsp;significant</div>
      <div>
        &nbsp;&nbsp;Address&nbsp;will&nbsp;be&nbsp;masked&nbsp;with&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;Mask
      </div>
      <div>
        &nbsp;&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;value&nbsp;is&nbsp;added&nbsp;when&nbsp;displayed
      </div>
      <div>&nbsp;&nbsp;With&nbsp;all-zero&nbsp;significant&nbsp;bits&nbsp;IP&nbsp;Address&nbsp;is&nbsp;disabled</div>
      <div>
        NB:&nbsp;The&nbsp;SSI&nbsp;cannot&nbsp;be&nbsp;setup&nbsp;if&nbsp;it&nbsp;already&nbsp;exists&nbsp;in&nbsp;a&nbsp;TSI&nbsp;with&nbsp;HomeMNI.&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;of&nbsp;Mobile&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;M:&nbsp;Mobile&nbsp;(Tetra)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;DMR
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴───&nbsp;┴──────&nbsp;┴──────────────
      </div>
      <div>S22/________/ADD/_/____/_______/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/ADD/c/...&nbsp;&nbsp;Add/Change&nbsp;SSI</strong>
      </div>
      <div>User&nbsp;Number:</div>
      <div>&nbsp;&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>&nbsp;&nbsp;With&nbsp;all-zeroes&nbsp;User&nbsp;Number&nbsp;is&nbsp;disabled</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Emergency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;D:&nbsp;Dial-In
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Application
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Terminal
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unified
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴───&nbsp;┴──────</div>
      <div>S22/________/ADD/_/____/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/DESCR/...&nbsp;&nbsp;Setup&nbsp;SSI&nbsp;Description</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Description
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────</div>
      <div>S22/________/DESCR/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/HIDE/s&nbsp;&nbsp;Hide&nbsp;in&nbsp;Phone&nbsp;Book</strong>
      </div>
      <div>
        This&nbsp;command&nbsp;allows&nbsp;the&nbsp;SSI&nbsp;to&nbsp;be&nbsp;hided&nbsp;in&nbsp;the&nbsp;Phone&nbsp;Book.
      </div>
      <div>
        It&nbsp;can&nbsp;e.g.&nbsp;be&nbsp;used&nbsp;to&nbsp;hide&nbsp;SSI's&nbsp;with&nbsp;the&nbsp;Presentation&nbsp;Number&nbsp;setup
      </div>
      <div>
        to&nbsp;point&nbsp;to&nbsp;a&nbsp;Unified&nbsp;Number.&nbsp;In&nbsp;this&nbsp;way&nbsp;only&nbsp;the&nbsp;Unified&nbsp;Number
      </div>
      <div>is&nbsp;displayed&nbsp;in&nbsp;the&nbsp;Phone&nbsp;Book.</div>
      <div>
        It&nbsp;can&nbsp;also&nbsp;be&nbsp;used&nbsp;to&nbsp;hide&nbsp;e.g.&nbsp;a&nbsp;Group&nbsp;Bridge&nbsp;etc.
      </div>
      <div>
        NB:&nbsp;When&nbsp;"display&nbsp;all"&nbsp;is&nbsp;selected&nbsp;in&nbsp;e.g.&nbsp;the&nbsp;Dispatcher,&nbsp;all&nbsp;hidden
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;numbers&nbsp;are&nbsp;displayed.&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Always&nbsp;display&nbsp;in&nbsp;Phone&nbsp;Book
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Hide&nbsp;in&nbsp;Phone&nbsp;Book&nbsp;(if&nbsp;in&nbsp;hide-mode)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/HIDE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/PRESNO/nnnnnnn&nbsp;&nbsp;Setup&nbsp;Presentation&nbsp;Number</strong>
      </div>
      <div>This&nbsp;command&nbsp;changes&nbsp;the&nbsp;presented&nbsp;number&nbsp;for&nbsp;outgoing&nbsp;calls.</div>
      <div>
        It&nbsp;allows&nbsp;the&nbsp;Subscriber&nbsp;to&nbsp;present&nbsp;a&nbsp;different&nbsp;call-back&nbsp;number,
      </div>
      <div>e.g.&nbsp;a&nbsp;Unified&nbsp;Number.</div>
      <div>
        A&nbsp;call-back&nbsp;to&nbsp;the&nbsp;Unified&nbsp;Number&nbsp;will&nbsp;ensure&nbsp;all&nbsp;listed&nbsp;devices&nbsp;is
      </div>
      <div>alerted.</div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;DMR,&nbsp;Dial-In,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI.
      </div>
      <div>NB:&nbsp;Leading&nbsp;zeroes&nbsp;are&nbsp;not&nbsp;significant.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Presentation&nbsp;Number
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0000001..9999999:&nbsp;Setup&nbsp;Presentation&nbsp;Number
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0000000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Clear&nbsp;Presentation&nbsp;Number&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>S22/________/PRESNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TSI/...&nbsp;&nbsp;Setup&nbsp;TSI</strong>
      </div>
      <div>
        Note&nbsp;that&nbsp;if&nbsp;the&nbsp;MCC:MNC&nbsp;is&nbsp;equal&nbsp;to&nbsp;the&nbsp;HomeMNI,&nbsp;the&nbsp;TSI&nbsp;can&nbsp;only&nbsp;be&nbsp;setup
      </div>
      <div>if&nbsp;an&nbsp;SSI&nbsp;with&nbsp;the&nbsp;TSI's&nbsp;SSI&nbsp;does&nbsp;not&nbsp;exist.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;and&nbsp;DMR&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(Mobile&nbsp;Country&nbsp;Code)&nbsp;(0001..1023)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(Mobile&nbsp;Network&nbsp;Code)&nbsp;(0001..16383)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(Short&nbsp;Subscriber&nbsp;Identity&nbsp;(0001..16777215)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>S22/________/TSI/____:_____:________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TSI/-&nbsp;&nbsp;Clear&nbsp;TSI</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;and&nbsp;DMR&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/TSI/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TRUST/c&nbsp;&nbsp;Change&nbsp;SSI&nbsp;Trust&nbsp;Kind</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;N:&nbsp;Normal&nbsp;(fully&nbsp;trusted)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;B:&nbsp;Blocked&nbsp;(no&nbsp;service)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;V:&nbsp;Visitor&nbsp;(verified&nbsp;of&nbsp;SSI's&nbsp;Home&nbsp;Network)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;U:&nbsp;Untrusted&nbsp;(auto-created)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/TRUST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/PINCODE/cc..c&nbsp;&nbsp;Change&nbsp;PIN&nbsp;Code</strong>
      </div>
      <div>PIN&nbsp;Code&nbsp;1..9&nbsp;digits,&nbsp;leading&nbsp;zeroes&nbsp;not&nbsp;significant</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;DialIn,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PIN&nbsp;Code:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1..999999999:&nbsp;Value
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────</div>
      <div>S22/________/PINCODE/_________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TEI/...&nbsp;&nbsp;Setup&nbsp;TEI&nbsp;Terminal&nbsp;Equipment&nbsp;Identity</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TEI&nbsp;(15&nbsp;hex&nbsp;digits&nbsp;0..9&nbsp;or&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S22/________/TEI/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TEI/-&nbsp;&nbsp;Clear&nbsp;TEI&nbsp;Terminal&nbsp;Equipment&nbsp;Identity</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/TEI/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/SIM/...&nbsp;&nbsp;Setup&nbsp;SIM&nbsp;ID</strong>
      </div>
      <div>ID&nbsp;of&nbsp;SIM&nbsp;Card&nbsp;if&nbsp;present</div>
      <div>Shall&nbsp;be&nbsp;cleared&nbsp;if&nbsp;not&nbsp;used</div>
      <div>
        If&nbsp;SIM&nbsp;ID&nbsp;is&nbsp;setup,&nbsp;SIM&nbsp;ID&nbsp;is&nbsp;used&nbsp;to&nbsp;find&nbsp;K&nbsp;in&nbsp;Key&nbsp;Register,
      </div>
      <div>else&nbsp;TEI&nbsp;is&nbsp;used.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SIM&nbsp;ID&nbsp;(20&nbsp;hex&nbsp;digits&nbsp;0..9&nbsp;or&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────</div>
      <div>S22/________/SIM/____________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/SIM/-&nbsp;&nbsp;Clear&nbsp;SIM&nbsp;ID</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/SIM/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/AUTHDIS/s&nbsp;&nbsp;Change&nbsp;Mobile&nbsp;Authentication&nbsp;Disable&nbsp;Flag</strong>
      </div>
      <div>Note,&nbsp;that&nbsp;Authentication&nbsp;disable&nbsp;is&nbsp;not&nbsp;possible&nbsp;in&nbsp;mandatory</div>
      <div>modes&nbsp;selected&nbsp;in&nbsp;the&nbsp;Profile&nbsp;register.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Authentication&nbsp;Disable&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Disable&nbsp;Authentication
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Authentication&nbsp;not&nbsp;disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/AUTHDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/AIEDIS/s&nbsp;&nbsp;Change&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;Disable&nbsp;Flag
        </strong>
      </div>
      <div>
        Note,&nbsp;that&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;disable&nbsp;is&nbsp;not&nbsp;possible&nbsp;in&nbsp;mandatory
      </div>
      <div>mode&nbsp;selected&nbsp;in&nbsp;the&nbsp;Profile&nbsp;register.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;and&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;Disable&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Disable&nbsp;Air&nbsp;Interface&nbsp;Encryption
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;not&nbsp;disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/AIEDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/SECSET/R&nbsp;&nbsp;Renew&nbsp;Security&nbsp;Set&nbsp;from&nbsp;Security&nbsp;Key</strong>
      </div>
      <div>
        The&nbsp;Security&nbsp;Key&nbsp;will&nbsp;be&nbsp;search&nbsp;with&nbsp;the&nbsp;following&nbsp;references:
      </div>
      <div>
        1)&nbsp;If&nbsp;SIM&nbsp;is&nbsp;setup,&nbsp;SIM&nbsp;will&nbsp;be&nbsp;used&nbsp;for&nbsp;Key&nbsp;search
      </div>
      <div>
        2)&nbsp;Else&nbsp;if&nbsp;TEI&nbsp;is&nbsp;setup,&nbsp;TEI&nbsp;will&nbsp;be&nbsp;used&nbsp;for&nbsp;Key&nbsp;search
      </div>
      <div>3)&nbsp;Else&nbsp;no&nbsp;renewal&nbsp;is&nbsp;done</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/SECSET/R</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/SECSET/...&nbsp;&nbsp;Setup&nbsp;Security&nbsp;Set</strong>
      </div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;manual&nbsp;test&nbsp;only</div>
      <div>
        Security&nbsp;set&nbsp;update&nbsp;should&nbsp;normally&nbsp;be&nbsp;done&nbsp;from&nbsp;Security&nbsp;Key
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Status
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unencrypted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Encrypted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;RS&nbsp;(20&nbsp;hex&nbsp;digits)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;KS&nbsp;(32&nbsp;hex&nbsp;digits)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;KS'&nbsp;(32&nbsp;hex&nbsp;digits)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│9&nbsp;8&nbsp;7&nbsp;6&nbsp;5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;│5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;9&nbsp;8&nbsp;7&nbsp;6&nbsp;5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;│5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0&nbsp;9&nbsp;8&nbsp;7&nbsp;6&nbsp;5&nbsp;4&nbsp;3&nbsp;2&nbsp;1&nbsp;0
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>
        S22/________/SECSET/_/____________________/________________________________/________________________________
      </div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/SECSET/C&nbsp;&nbsp;Clear&nbsp;Security&nbsp;Set</strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/SECSET/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/GROUPTIMEOUT/nnnnn&nbsp;&nbsp;Set&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;Timeout
        </strong>
      </div>
      <div>
        Changes&nbsp;the&nbsp;time&nbsp;from&nbsp;last&nbsp;PTT&nbsp;released&nbsp;until&nbsp;Group&nbsp;Call&nbsp;termination.
      </div>
      <div>Default&nbsp;value&nbsp;is&nbsp;5&nbsp;seconds.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;timeout&nbsp;(00000..65000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────
      </div>
      <div>S22/________/GROUPTIMEOUT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/GROUPNOKEYED/s&nbsp;&nbsp;Change&nbsp;Group&nbsp;Number&nbsp;Key-in&nbsp;permission
        </strong>
      </div>
      <div>
        Allows&nbsp;the&nbsp;Group&nbsp;Number&nbsp;to&nbsp;be&nbsp;keyed&nbsp;in&nbsp;from&nbsp;the&nbsp;Keyboard.
      </div>
      <div>
        When&nbsp;the&nbsp;MS&nbsp;makes&nbsp;a&nbsp;call&nbsp;with&nbsp;a&nbsp;Group&nbsp;Number,&nbsp;a&nbsp;PTP-&gt;PTMP&nbsp;Call&nbsp;Modification
      </div>
      <div>will&nbsp;take&nbsp;place.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Group&nbsp;Call&nbsp;via&nbsp;Keypad&nbsp;NOT&nbsp;allowed&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Group&nbsp;Call&nbsp;via&nbsp;Keypad&nbsp;allowed&nbsp;(PTP-&gt;PTMP)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S22/________/GROUPNOKEYED/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/GROUPOWNERSHIP/s&nbsp;&nbsp;Change&nbsp;Group&nbsp;Call&nbsp;Ownership</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;use&nbsp;of&nbsp;Group&nbsp;Ownership&nbsp;for&nbsp;Group&nbsp;Calls</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Group&nbsp;Call&nbsp;Ownership&nbsp;not&nbsp;used&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Group&nbsp;Call&nbsp;Ownership&nbsp;used
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S22/________/GROUPOWNERSHIP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/DMRGROUP/s&nbsp;&nbsp;Configure&nbsp;group&nbsp;as&nbsp;static&nbsp;in&nbsp;DMR</strong>
      </div>
      <div>
        Configures&nbsp;the&nbsp;group&nbsp;as&nbsp;static&nbsp;in&nbsp;DMR.&nbsp;Is&nbsp;required&nbsp;when&nbsp;the&nbsp;radio
      </div>
      <div>do&nbsp;not&nbsp;support&nbsp;group&nbsp;attachment&nbsp;signalling.</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Group&nbsp;is&nbsp;dynamic&nbsp;and&nbsp;requires&nbsp;group&nbsp;attachment
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Group&nbsp;is&nbsp;static&nbsp;and&nbsp;does&nbsp;not&nbsp;require&nbsp;group&nbsp;attachment
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/DMRGROUP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/GROUPTEXT/cc..c&nbsp;&nbsp;Setup&nbsp;Group&nbsp;Text&nbsp;Line</strong>
      </div>
      <div>Group&nbsp;Text&nbsp;Line&nbsp;to&nbsp;show&nbsp;on&nbsp;the&nbsp;Terminal&nbsp;Screen</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Text&nbsp;Line
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────
      </div>
      <div>S22/________/GROUPTEXT/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/GROUPRESTR/s&nbsp;&nbsp;Setup&nbsp;Group&nbsp;Call&nbsp;Restriction</strong>
      </div>
      <div>Flag&nbsp;to&nbsp;restrict&nbsp;access&nbsp;to&nbsp;the&nbsp;Group.</div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;cleared&nbsp;the&nbsp;Group&nbsp;can&nbsp;be&nbsp;called&nbsp;from&nbsp;all&nbsp;Individual
      </div>
      <div>Subscribers&nbsp;according&nbsp;to&nbsp;their&nbsp;Profiles&nbsp;restriction.</div>
      <div>
        When&nbsp;the&nbsp;flag&nbsp;is&nbsp;set&nbsp;the&nbsp;Group&nbsp;can&nbsp;only&nbsp;be&nbsp;called&nbsp;from&nbsp;Individual
      </div>
      <div>
        Subscribers&nbsp;with&nbsp;the&nbsp;Group&nbsp;included&nbsp;in&nbsp;their&nbsp;Profiles&nbsp;Group&nbsp;Permission
      </div>
      <div>table.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Call&nbsp;Restriction:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Normal&nbsp;Group&nbsp;restriction&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Call&nbsp;only&nbsp;allowed&nbsp;if&nbsp;Group&nbsp;is&nbsp;included&nbsp;in
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the&nbsp;Subscribers&nbsp;Group&nbsp;Permission&nbsp;table.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S22/________/GROUPRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/GROUPINCL&nbsp;&nbsp;Display&nbsp;Group&nbsp;Include&nbsp;Table</strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/GROUPINCL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/GROUPINCL/s&nbsp;&nbsp;Enable/Disable&nbsp;Group&nbsp;Include&nbsp;Table</strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Table
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/GROUPINCL/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/GROUPINCL/nnnnnnn/ADD/s&nbsp;&nbsp;Add/Change&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;entry
        </strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>
        Note&nbsp;that&nbsp;leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant
      </div>
      <div>NB:&nbsp;Only&nbsp;allowed&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Pre-empt&nbsp;Flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Do&nbsp;not&nbsp;pre-empt&nbsp;call&nbsp;if&nbsp;busy&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Pre-empt&nbsp;call&nbsp;if&nbsp;busy
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;
      </div>
      <div>S22/________/GROUPINCL/_______/ADD/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/GROUPINCL/nnnnnnn/REM&nbsp;&nbsp;Remove&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;entry
        </strong>
      </div>
      <div>
        If&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table&nbsp;is&nbsp;enabled,&nbsp;a&nbsp;call&nbsp;to&nbsp;the&nbsp;group&nbsp;will&nbsp;also
      </div>
      <div>
        include&nbsp;all&nbsp;Groups&nbsp;and&nbsp;Individuals&nbsp;in&nbsp;the&nbsp;Group&nbsp;Include&nbsp;Table.
      </div>
      <div>
        Note&nbsp;that&nbsp;leading&nbsp;zeroes&nbsp;of&nbsp;the&nbsp;User&nbsp;Number&nbsp;are&nbsp;not&nbsp;significant
      </div>
      <div>NB:&nbsp;Only&nbsp;allowed&nbsp;for&nbsp;Group&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S22/________/GROUPINCL/_______/REM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/CALLTRF/c&nbsp;&nbsp;Change&nbsp;Call&nbsp;Transfer</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;DialIn&nbsp;and&nbsp;Terminal&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Transfer&nbsp;Type
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;None
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;Not&nbsp;Reachable&nbsp;(Weak)&nbsp;&nbsp;&nbsp;(Not&nbsp;registered/no&nbsp;radio&nbsp;contact)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;No&nbsp;Answer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Medium)&nbsp;(Incl.&nbsp;Not&nbsp;Reachable)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;Busy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Strong)&nbsp;(Incl.&nbsp;No&nbsp;Answer&nbsp;and&nbsp;Not&nbsp;Reachable)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;Unconditional&nbsp;(Always)&nbsp;(Excluding&nbsp;Blocked&nbsp;SSI)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;N:&nbsp;Number&nbsp;Change&nbsp;(Always)&nbsp;(Including&nbsp;Blocked&nbsp;SSI)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/CALLTRF/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/CALLTRFNO/cc..c&nbsp;&nbsp;Change&nbsp;Call&nbsp;Transfer&nbsp;Number</strong>
      </div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;DialIn,&nbsp;Emergency&nbsp;and&nbsp;Terminal&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;(0..9,#,*,+,p&nbsp;or&nbsp;w)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────────
      </div>
      <div>S22/________/CALLTRFNO/______________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/CALLTRFTIME/nn&nbsp;&nbsp;Change&nbsp;Call&nbsp;Transfer&nbsp;delay&nbsp;time</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;change&nbsp;the&nbsp;Alerting&nbsp;time&nbsp;before&nbsp;Call&nbsp;Transfer&nbsp;takes&nbsp;place
      </div>
      <div>for&nbsp;"Call&nbsp;Transfer&nbsp;at&nbsp;no&nbsp;B-answer"</div>
      <div>
        NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile,&nbsp;Application,&nbsp;DialIn&nbsp;and&nbsp;Terminal&nbsp;SSI
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Time&nbsp;in&nbsp;seconds&nbsp;(05..60,&nbsp;default&nbsp;30)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─
      </div>
      <div>S22/________/CALLTRFTIME/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/SUBREG/s&nbsp;&nbsp;Change&nbsp;Subscriber&nbsp;Register&nbsp;change&nbsp;permission
        </strong>
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Subscriber&nbsp;Register&nbsp;changes&nbsp;from&nbsp;Application
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/SUBREG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/DISCREETLIST/s&nbsp;&nbsp;Change&nbsp;Discreet&nbsp;Listening&nbsp;permission</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;for&nbsp;Discreet&nbsp;Listening&nbsp;from&nbsp;the&nbsp;Application.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Discreet&nbsp;Listening
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S22/________/DISCREETLIST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/AMBIENCELIST/s&nbsp;&nbsp;Change&nbsp;Ambience&nbsp;Listening&nbsp;permission</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;for&nbsp;Ambience&nbsp;Listening&nbsp;from&nbsp;the&nbsp;Application.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Ambience&nbsp;Listening
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S22/________/AMBIENCELIST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/TEMPENDIS/s&nbsp;&nbsp;Change&nbsp;Temporary&nbsp;Mobile&nbsp;Enable/Disable&nbsp;permission
        </strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;to&nbsp;make&nbsp;Temporary&nbsp;Enable/Disable&nbsp;of&nbsp;a&nbsp;Mobile
      </div>
      <div>from&nbsp;the&nbsp;Application.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Temporary&nbsp;Mobile&nbsp;Enable/Disable
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/TEMPENDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/PERMDIS/s&nbsp;&nbsp;Change&nbsp;Permanent&nbsp;Mobile&nbsp;Disable&nbsp;permission
        </strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;to&nbsp;make&nbsp;Permanant&nbsp;Disable&nbsp;of&nbsp;a&nbsp;Mobile&nbsp;from&nbsp;the&nbsp;Application.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Permanent&nbsp;Mobile&nbsp;Disable
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/PERMDIS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S22/nnnnnnnn/GROUPMAN/s&nbsp;&nbsp;Change&nbsp;Mobile&nbsp;Group&nbsp;Management&nbsp;permission
        </strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;permission&nbsp;to&nbsp;change&nbsp;Group&nbsp;attachment&nbsp;and&nbsp;setup&nbsp;DGNA&nbsp;Groups
      </div>
      <div>in&nbsp;Mobiles&nbsp;from&nbsp;the&nbsp;Application.</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Management
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Permitted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;permitted
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/GROUPMAN/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/NUMBERMOD/s&nbsp;&nbsp;Change&nbsp;Number&nbsp;Modification&nbsp;Permission</strong>
      </div>
      <div>
        Gives&nbsp;permission&nbsp;for&nbsp;the&nbsp;Gateway&nbsp;to&nbsp;present&nbsp;an&nbsp;external&nbsp;provided
      </div>
      <div>number&nbsp;instead&nbsp;of&nbsp;the&nbsp;SSI's&nbsp;User&nbsp;Number.</div>
      <div>
        Shall&nbsp;be&nbsp;set&nbsp;if&nbsp;the&nbsp;SSI&nbsp;is&nbsp;e.g.&nbsp;a&nbsp;Group&nbsp;Bridge&nbsp;or&nbsp;similar.
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Application&nbsp;and&nbsp;Terminal&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;Modification&nbsp;Permission
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Number&nbsp;Modification&nbsp;allowed
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Number&nbsp;Modification&nbsp;not&nbsp;allowed
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/NUMBERMOD/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/ICON/nn&nbsp;&nbsp;Change&nbsp;Icon</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;icon&nbsp;displayed&nbsp;on&nbsp;Clients&nbsp;for&nbsp;a&nbsp;Unified&nbsp;SSI</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Icon
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Unified
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;Emergency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;Personal
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;Multi
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;External
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;GSM
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;PABX
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S22/________/ICON/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/PRIORITY/nn&nbsp;&nbsp;Change&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Call&nbsp;Priority&nbsp;of&nbsp;a&nbsp;Unified&nbsp;SSI</div>
      <div>This&nbsp;will&nbsp;overrule&nbsp;Call&nbsp;Priority&nbsp;request&nbsp;1..14&nbsp;from&nbsp;devices</div>
      <div>
        Emergency&nbsp;Priority&nbsp;15&nbsp;and&nbsp;16&nbsp;from&nbsp;devices&nbsp;will&nbsp;always&nbsp;be&nbsp;passed&nbsp;unchanged
      </div>
      <div>
        Call&nbsp;Priority&nbsp;15&nbsp;(Emergency&nbsp;Call&nbsp;Priority)&nbsp;is&nbsp;recommended&nbsp;for&nbsp;Emergency&nbsp;Numbers
      </div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Priority
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Device&nbsp;Controlled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01..05:&nbsp;Low&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=0)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06..11:&nbsp;High&nbsp;Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PTT&nbsp;Priority=1)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12..14:&nbsp;Pre-emptive&nbsp;Priority&nbsp;(PTT&nbsp;Priority=2)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Emergency&nbsp;Call&nbsp;Priority
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S22/________/PRIORITY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TARGET&nbsp;&nbsp;Display&nbsp;Target&nbsp;Table</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/TARGET</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/TARGET/n/s/s/s/nn/cc..c&nbsp;&nbsp;Add/Change/Remove&nbsp;Target</strong>
      </div>
      <div>
        Add,&nbsp;Change&nbsp;or&nbsp;Remove&nbsp;a&nbsp;Target&nbsp;Number&nbsp;for&nbsp;the&nbsp;Unified&nbsp;SSI.
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;for&nbsp;Unified&nbsp;SSI</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Target&nbsp;Table&nbsp;index&nbsp;(0..7)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Voice&nbsp;Flag&nbsp;(+/-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;SDS&nbsp;Flag&nbsp;(+/-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Flag&nbsp;(+/-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;Voice&nbsp;Call&nbsp;Delay&nbsp;Time&nbsp;(00..90&nbsp;sec.)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;&nbsp;┌&nbsp;Target&nbsp;Number&nbsp;(0..9,#,*,+,p&nbsp;or&nbsp;w)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴─&nbsp;┴─────────────────────────────
      </div>
      <div>S22/________/TARGET/_/_/_/_/__/______________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/RUA/c&nbsp;&nbsp;Setup&nbsp;Radio&nbsp;User&nbsp;Assignment&nbsp;(RUA)&nbsp;Type</strong>
      </div>
      <div>RUA&nbsp;Type&nbsp;(in&nbsp;accordance&nbsp;to&nbsp;TTR&nbsp;001-17)</div>
      <div>NB:&nbsp;Only&nbsp;available&nbsp;for&nbsp;Mobile&nbsp;SSI</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RUA&nbsp;Type:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0..7:&nbsp;Requested&nbsp;radio&nbsp;user&nbsp;assignment&nbsp;value&nbsp;(in&nbsp;accordance&nbsp;to&nbsp;TTR&nbsp;001-17)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;:&nbsp;Disabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S22/________/RUA/_</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S22/nnnnnnnn/DEL&nbsp;&nbsp;Delete&nbsp;SSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S22/________/DEL</div>
      <div />
      <div />
    </div>
    <h2>Security Key Register:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25&nbsp;&nbsp;Display&nbsp;Key&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>S25</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/T/hh..h&nbsp;&nbsp;Display&nbsp;TEI&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TEI&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S25/T/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/S/hh..h&nbsp;&nbsp;Display&nbsp;SIM&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SIM&nbsp;ID&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────</div>
      <div>S25/S/____________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/I/nn..n&nbsp;&nbsp;Display&nbsp;SSI&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S25/I/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/T/hhh..h/c/...&nbsp;&nbsp;Add/Change&nbsp;TEI&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TEI&nbsp;(Hex&nbsp;0..9,&nbsp;A..F)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Key&nbsp;Status
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;C:&nbsp;Clear&nbsp;Key&nbsp;(Encrypted&nbsp;at&nbsp;register&nbsp;save)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;AES&nbsp;Encrypted&nbsp;Key&nbsp;(Saved&nbsp;unchanged&nbsp;in&nbsp;KeyV2&nbsp;register)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;S:&nbsp;Scrambled&nbsp;Key&nbsp;(Descrambled&nbsp;and&nbsp;Encrypted&nbsp;at&nbsp;register&nbsp;save)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Encrypted&nbsp;Key&nbsp;(As&nbsp;saved&nbsp;in&nbsp;old&nbsp;Key&nbsp;register)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;K&nbsp;key&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴&nbsp;┴───────────────────────────────</div>
      <div>S25/T/_______________/_/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/S/hhh..h/c/...&nbsp;&nbsp;Add/Change&nbsp;SIM&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SIM&nbsp;ID&nbsp;(Hex&nbsp;0..9,&nbsp;A..F)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Key&nbsp;Status
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;C:&nbsp;Clear&nbsp;Key&nbsp;(Encrypted&nbsp;at&nbsp;register&nbsp;save)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;AES&nbsp;Encrypted&nbsp;Key&nbsp;(Saved&nbsp;unchanged&nbsp;in&nbsp;KeyV2&nbsp;register)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;S:&nbsp;Scrambled&nbsp;Key&nbsp;(Descrambled&nbsp;and&nbsp;Encrypted&nbsp;at&nbsp;register&nbsp;save)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Encrypted&nbsp;Key&nbsp;(As&nbsp;saved&nbsp;in&nbsp;old&nbsp;Key&nbsp;register)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;K&nbsp;key&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────&nbsp;┴&nbsp;┴───────────────────────────────</div>
      <div>S25/S/____________________/_/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/I/nn..n/c/...&nbsp;&nbsp;Add/Change&nbsp;SSI&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Key&nbsp;Status
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;C:&nbsp;Clear&nbsp;Key&nbsp;(Encrypted&nbsp;at&nbsp;register&nbsp;save)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;AES&nbsp;Encrypted&nbsp;Key&nbsp;(Saved&nbsp;unchanged&nbsp;in&nbsp;KeyV2&nbsp;register)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;S:&nbsp;Scrambled&nbsp;Key&nbsp;(Descrambled&nbsp;and&nbsp;Encrypted&nbsp;at&nbsp;register&nbsp;save)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;E:&nbsp;Encrypted&nbsp;Key&nbsp;(As&nbsp;saved&nbsp;in&nbsp;old&nbsp;Key&nbsp;register)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;K&nbsp;key&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴&nbsp;┴───────────────────────────────</div>
      <div>S25/I/________/_/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/T/hhh..h/DEL&nbsp;&nbsp;Delete&nbsp;TEI&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TEI&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S25/T/_______________/DEL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/S/hhh..h/DEL&nbsp;&nbsp;Delete&nbsp;SIM&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SIM&nbsp;ID&nbsp;(Hex&nbsp;(0..9,&nbsp;A..F)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────</div>
      <div>S25/S/____________________/DEL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/I/nn..n/DEL&nbsp;&nbsp;Delete&nbsp;SSI&nbsp;Key</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>S25/I/________/DEL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/RELOAD&nbsp;&nbsp;Reload&nbsp;Key&nbsp;File&nbsp;from&nbsp;Disk</strong>
      </div>
      <div>Reloads&nbsp;the&nbsp;Key&nbsp;Register&nbsp;"Key.bin"&nbsp;file</div>
      <div>NB:&nbsp;Register&nbsp;is&nbsp;cleared&nbsp;before&nbsp;the&nbsp;reload</div>
      <div>S25/RELOAD</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/SAVE&nbsp;&nbsp;Save&nbsp;Key&nbsp;Register&nbsp;in&nbsp;OM&nbsp;command&nbsp;file</strong>
      </div>
      <div>Key&nbsp;Register&nbsp;saved&nbsp;in&nbsp;"Key.txt"&nbsp;file</div>
      <div>S25/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/READ&nbsp;&nbsp;Read&nbsp;Key&nbsp;Register&nbsp;from&nbsp;OM&nbsp;command&nbsp;file</strong>
      </div>
      <div>Reads&nbsp;the&nbsp;"Key.txt"&nbsp;OM&nbsp;Command&nbsp;file</div>
      <div>NB:&nbsp;Makes&nbsp;Add/Change&nbsp;to&nbsp;the&nbsp;existing&nbsp;register&nbsp;content</div>
      <div>S25/READ</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/IMPORT/cc..c&nbsp;&nbsp;Import&nbsp;Mobile&nbsp;Programmer&nbsp;File</strong>
      </div>
      <div>1)&nbsp;Reads&nbsp;REF,K&nbsp;records&nbsp;into&nbsp;the&nbsp;Security&nbsp;Key&nbsp;Register</div>
      <div>
        &nbsp;&nbsp;&nbsp;If&nbsp;REF&nbsp;is&nbsp;existing&nbsp;K&nbsp;is&nbsp;changed,&nbsp;else&nbsp;new&nbsp;REF,K&nbsp;set&nbsp;is&nbsp;added
      </div>
      <div>2)&nbsp;Reads&nbsp;REF,ITSI&nbsp;records</div>
      <div>
        &nbsp;&nbsp;&nbsp;Updates&nbsp;REF&nbsp;(TEI&nbsp;or&nbsp;SIM)&nbsp;in&nbsp;SSI&nbsp;Register&nbsp;if&nbsp;ITSI&nbsp;is&nbsp;existing
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;ITSI&nbsp;with&nbsp;zero&nbsp;or&nbsp;matching&nbsp;MCC&nbsp;and&nbsp;MNC&nbsp;is&nbsp;accepted
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;Deletes&nbsp;SIM&nbsp;if&nbsp;TEI&nbsp;is&nbsp;changed&nbsp;or&nbsp;TEI&nbsp;if&nbsp;SIM&nbsp;is&nbsp;changed
      </div>
      <div>Coding:</div>
      <div>REF(TEI)(19&nbsp;Decimal&nbsp;digits):&nbsp;&nbsp;T0123456789012345678</div>
      <div>REF(TEI)(15&nbsp;Hex&nbsp;digits)....:&nbsp;&nbsp;T0x0123456789ABCDE</div>
      <div>REF(SIM)(20&nbsp;Hex&nbsp;digits)....:&nbsp;&nbsp;S0x0123456789ABCDEF0123</div>
      <div>REF(SSI)(8&nbsp;Decimal&nbsp;digits).:&nbsp;&nbsp;I01234567</div>
      <div>K&nbsp;(32&nbsp;Hex&nbsp;digits)..........:&nbsp;&nbsp;0x0123456789ABCDEF0123456789ABCDEF</div>
      <div>ITSI&nbsp;(15&nbsp;Decimal&nbsp;digits....:&nbsp;&nbsp;012345678901234</div>
      <div>ITSI&nbsp;(12&nbsp;Hex&nbsp;digits).......:&nbsp;&nbsp;0x0123456789AB</div>
      <div>Record&nbsp;examples:</div>
      <div>TEI,K...:&nbsp;&nbsp;T0x000087171809780,0x0E4A01E34CBC5D53AD5D5DECCB11C0EA;</div>
      <div>SIM,K...:&nbsp;&nbsp;S0x12345000087171809780,0x0E4A01E34CBC5D53AD5D5DECCB11C0EA;</div>
      <div>SSI,K...:&nbsp;&nbsp;I01234567,0x0E4A01E34CBC5D53AD5D5DECCB11C0EA;</div>
      <div>TEI,ITSI:&nbsp;&nbsp;T0x000087171809780,0x5DECCB11C0EA;</div>
      <div>SIM,ITSI:&nbsp;&nbsp;S0x12345000087171809780,0x5DECCB11C0EA;</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;File&nbsp;Path&nbsp;+&nbsp;Name&nbsp;on&nbsp;connected&nbsp;Node&nbsp;Controller
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────────────────────────────────────────────
      </div>
      <div>S25/IMPORT/__________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S25/QUE&nbsp;&nbsp;Display&nbsp;File&nbsp;Write&nbsp;Queue</strong>
      </div>
      <div>File&nbsp;write&nbsp;queue&nbsp;for&nbsp;the&nbsp;"Key.bin"&nbsp;file</div>
      <div>S25/QUE</div>
      <div />
      <div />
    </div>
    <h2>Organization Register:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26&nbsp;&nbsp;Display&nbsp;Organization&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>S26</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn&nbsp;&nbsp;Display&nbsp;Organization</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S26/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;Organization&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..50)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>S26/___/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/ADD/cc..c&nbsp;&nbsp;Add/Change&nbsp;Organization</strong>
      </div>
      <div>
        NB:&nbsp;All&nbsp;ranges&nbsp;automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Description</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────</div>
      <div>S26/___/ADD/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/PROFNO/...&nbsp;&nbsp;Change&nbsp;Profile&nbsp;Number&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;Profile&nbsp;Numbers,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;Profile&nbsp;Number&nbsp;(0000..9999)&nbsp;(0=Barred)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴───</div>
      <div>S26/___/PROFNO/____/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/SSI/...&nbsp;&nbsp;Change&nbsp;SSI&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;SSIs,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;SSI&nbsp;(00000001..16777215)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;SSI&nbsp;(00000001..16777215)&nbsp;(0=Barred)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────</div>
      <div>S26/___/SSI/________/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/USERNO/...&nbsp;&nbsp;Change&nbsp;User&nbsp;Number&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;User&nbsp;Numbers,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>Leading&nbsp;zeroes&nbsp;in&nbsp;User&nbsp;Numbers&nbsp;are&nbsp;not&nbsp;significant</div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;User&nbsp;Number&nbsp;(0000001..9999999)&nbsp;(0=Barred)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────&nbsp;┴──────</div>
      <div>S26/___/USERNO/_______/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/IPADDR/...&nbsp;&nbsp;Change&nbsp;IP&nbsp;Address&nbsp;range</strong>
      </div>
      <div>
        Limits&nbsp;the&nbsp;IP&nbsp;Addresses,&nbsp;which&nbsp;can&nbsp;be&nbsp;added&nbsp;via&nbsp;an&nbsp;Application&nbsp;Gateway
      </div>
      <div>Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment&nbsp;value&nbsp;is&nbsp;added&nbsp;when&nbsp;displayed</div>
      <div>NB:&nbsp;Automatically&nbsp;set&nbsp;to&nbsp;full&nbsp;range&nbsp;for&nbsp;Organization&nbsp;000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;permitted&nbsp;IP&nbsp;Address&nbsp;(000..255)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;permitted&nbsp;IP&nbsp;Address&nbsp;(000..255)&nbsp;(0=Barred)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴──────────────
      </div>
      <div>S26/___/IPADDR/___.___.___.___/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/PUBLRESTR/s&nbsp;&nbsp;Change&nbsp;Publish&nbsp;Restriction&nbsp;flag</strong>
      </div>
      <div>Restricts&nbsp;the&nbsp;publishing&nbsp;of&nbsp;the&nbsp;Organisation.</div>
      <div>
        Setting&nbsp;this&nbsp;flag&nbsp;will&nbsp;hide&nbsp;the&nbsp;existence&nbsp;of&nbsp;the&nbsp;Organization&nbsp;for&nbsp;all&nbsp;except
      </div>
      <div>Home&nbsp;Organization&nbsp;Applications.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Publish&nbsp;Restriction&nbsp;flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Publish&nbsp;to&nbsp;Home&nbsp;Organization&nbsp;Applications&nbsp;only
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Publish&nbsp;to&nbsp;all&nbsp;Applications
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S26/___/PUBLRESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/NODERESTR/s&nbsp;&nbsp;Change&nbsp;Node&nbsp;Status&nbsp;Restriction&nbsp;flag</strong>
      </div>
      <div>Restricts&nbsp;the&nbsp;publishing&nbsp;of&nbsp;Node&nbsp;Status.</div>
      <div>
        Setting&nbsp;this&nbsp;flag&nbsp;will&nbsp;hide&nbsp;the&nbsp;Node&nbsp;Status&nbsp;in&nbsp;Applications.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Status&nbsp;Restriction&nbsp;flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Node&nbsp;Status&nbsp;NOT&nbsp;sent&nbsp;to&nbsp;Applications
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Node&nbsp;Status&nbsp;sent&nbsp;to&nbsp;Applications
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S26/___/NODERESTR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S26/nnn/DEL&nbsp;&nbsp;Delete&nbsp;Organization</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Organization&nbsp;Number&nbsp;(000..999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S26/___/DEL</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Network Register:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27&nbsp;&nbsp;Display&nbsp;Network&nbsp;Register&nbsp;Status</strong>
      </div>
      <div>S27</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/L&nbsp;&nbsp;Display&nbsp;Network&nbsp;List</strong>
      </div>
      <div>S27/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnnn&nbsp;&nbsp;Display&nbsp;Network</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────</div>
      <div>S27/____:_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnnn/L/snn/nn&nbsp;&nbsp;Display&nbsp;Network&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Line&nbsp;Offset&nbsp;(-50..+50)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;Lines&nbsp;(01..50)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─</div>
      <div>S27/____:_____/L/___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnnn/ADD/cc..c&nbsp;&nbsp;Add/Change&nbsp;Network</strong>
      </div>
      <div>The&nbsp;Network&nbsp;Code&nbsp;0000:00000&nbsp;acts&nbsp;as&nbsp;"default"&nbsp;Network,</div>
      <div>if&nbsp;the&nbsp;actual&nbsp;MNI&nbsp;(MCC:MNC)&nbsp;is&nbsp;not&nbsp;existing.</div>
      <div>
        NB:&nbsp;Requires,&nbsp;that&nbsp;Networks&nbsp;is&nbsp;not&nbsp;restricted&nbsp;in&nbsp;License&nbsp;Dongle&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Description
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────</div>
      <div>S27/____:_____/ADD/________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnnn/AUTO/c/ALLOW/s&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;Allowed&nbsp;Flag</strong>
      </div>
      <div>
        NB:&nbsp;Requires,&nbsp;that&nbsp;AutoCreate&nbsp;is&nbsp;permitted&nbsp;in&nbsp;License&nbsp;Dongle&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Tetra&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;AutoCreate&nbsp;Allow&nbsp;Flag:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Allow&nbsp;SSI&nbsp;AutoCreate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;No&nbsp;SSI&nbsp;AutoCreate&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S27/____:_____/AUTO/_/ALLOW/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnnn/AUTO/c/PROFILE/nnnn&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;Profile&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Tetra&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───
      </div>
      <div>S27/____:_____/AUTO/_/PROFILE/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S27/nnnn:nnnnn/AUTO/c/SSITSI/s&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;SSI&nbsp;from&nbsp;TSI&nbsp;flag
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Tetra&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;from&nbsp;TSI&nbsp;flag:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Setup&nbsp;SSI&nbsp;from&nbsp;TSI
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Setup&nbsp;SSI&nbsp;from&nbsp;Organization&nbsp;range&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S27/____:_____/AUTO/_/SSITSI/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S27/nnnn:nnnnn/AUTO/c/USERSSI/s&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;UserNo&nbsp;from&nbsp;SSI&nbsp;flag
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Tetra&nbsp;Mobile
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UserNo&nbsp;from&nbsp;SSI&nbsp;flag:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Set&nbsp;User&nbsp;Number&nbsp;to&nbsp;SSI&nbsp;value
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Set&nbsp;User&nbsp;Number&nbsp;from&nbsp;Organization&nbsp;range&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S27/____:_____/AUTO/_/USERSSI/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnnn/AUTO/c/TIMEOUT/nnnnn&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;Timeout</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;timeout&nbsp;(00000..65000sec)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────
      </div>
      <div>S27/____:_____/AUTO/G/TIMEOUT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/nnnn:nnnn/DEL&nbsp;&nbsp;Delete&nbsp;Network</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴────</div>
      <div>S27/____:_____/DEL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S27/AUTO/c/nnnn:nnnnn:nnnnnnnn&nbsp;&nbsp;Setup&nbsp;Autocreated&nbsp;TSI</strong>
      </div>
      <div>
        NB:&nbsp;This&nbsp;command&nbsp;only&nbsp;for&nbsp;test&nbsp;purpose!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group&nbsp;Autocreate</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;T:&nbsp;Tetra&nbsp;Mobile&nbsp;Autocreate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;MCC&nbsp;(0001..1023)(Mobile&nbsp;Country&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MNC&nbsp;(00001..16383)(Mobile&nbsp;Network&nbsp;Code)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;(00000001..16777315)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴───&nbsp;┴────&nbsp;┴───────</div>
      <div>S27/AUTO/c/____:_____:________</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Audio Messages</h2>
    <br />
    <div>S30&nbsp;&nbsp;AUDIO&nbsp;MESSAGES</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S30&nbsp;&nbsp;Display&nbsp;Audio&nbsp;Message&nbsp;status</strong>
      </div>
      <div>
        The&nbsp;Audio&nbsp;Messages&nbsp;are&nbsp;Voice&nbsp;and&nbsp;Tone&nbsp;sequences&nbsp;used&nbsp;in&nbsp;the&nbsp;system&nbsp;as
      </div>
      <div>Audio&nbsp;announcements.</div>
      <div>
        They&nbsp;are&nbsp;read-in&nbsp;from&nbsp;.wav&nbsp;files&nbsp;located&nbsp;in&nbsp;the&nbsp;Audio&nbsp;directory.
      </div>
      <div>Format&nbsp;shall&nbsp;be&nbsp;CCITT&nbsp;A-Law&nbsp;8kHz&nbsp;8-bit&nbsp;mono.</div>
      <div>English&nbsp;versions&nbsp;are&nbsp;delivered&nbsp;from&nbsp;the&nbsp;factory&nbsp;as&nbsp;standard</div>
      <div>
        Messages&nbsp;in&nbsp;different&nbsp;languages&nbsp;and&nbsp;with&nbsp;adjusted&nbsp;content&nbsp;can&nbsp;be
      </div>
      <div>recorded&nbsp;with&nbsp;the&nbsp;Windows&nbsp;"Sound&nbsp;Recorder"&nbsp;tool.</div>
      <div>
        When&nbsp;loaded&nbsp;from&nbsp;the&nbsp;files,&nbsp;versions&nbsp;coded&nbsp;as&nbsp;Tetra&nbsp;Voice&nbsp;are&nbsp;also
      </div>
      <div>generated&nbsp;automatically.</div>
      <div>S30</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S30/R&nbsp;&nbsp;Reload&nbsp;Audio&nbsp;Messages&nbsp;from&nbsp;disk</strong>
      </div>
      <div>Reads&nbsp;all&nbsp;.wav&nbsp;files&nbsp;in&nbsp;the&nbsp;Audio&nbsp;directory.</div>
      <div>Tetra&nbsp;Audio&nbsp;versions&nbsp;are&nbsp;also&nbsp;generated&nbsp;automatically.</div>
      <div>S30/R</div>
      <div />
      <div />
    </div>
    <h2>TetraFlex Shares</h2>
    <br />
    <div>S31&nbsp;&nbsp;TETRAFLEX&nbsp;SHARES</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S31&nbsp;&nbsp;Display&nbsp;TetraFlex&nbsp;Shares</strong>
      </div>
      <div>Displays&nbsp;the&nbsp;list&nbsp;of&nbsp;Version&nbsp;Dates&nbsp;in&nbsp;the&nbsp;TetraFlex&nbsp;share.</div>
      <div>Automatically&nbsp;updated&nbsp;every&nbsp;30&nbsp;second.</div>
      <div>S31</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S31/nn&nbsp;&nbsp;Display&nbsp;TetraFlex&nbsp;Share</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Share&nbsp;Number&nbsp;(00..nn)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S31/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S31/nn/nnnn-nn-nn&nbsp;&nbsp;Change&nbsp;Version&nbsp;Date</strong>
      </div>
      <div>Change&nbsp;File&nbsp;Date&nbsp;to&nbsp;the&nbsp;selected&nbsp;value</div>
      <div>
        Used&nbsp;to&nbsp;set&nbsp;the&nbsp;Version&nbsp;Date&nbsp;to&nbsp;the&nbsp;correct&nbsp;one,&nbsp;if&nbsp;the&nbsp;has&nbsp;been
      </div>
      <div>accidentially&nbsp;modified.&nbsp;</div>
      <div>Time&nbsp;is&nbsp;set&nbsp;to&nbsp;12:00:00&nbsp;UTC</div>
      <div>Note:</div>
      <div>
        The&nbsp;command&nbsp;changes&nbsp;the&nbsp;Date&nbsp;of&nbsp;the&nbsp;file&nbsp;in&nbsp;the&nbsp;Share&nbsp;directory.
      </div>
      <div>
        After&nbsp;execution,&nbsp;the&nbsp;file&nbsp;should&nbsp;be&nbsp;copied&nbsp;back&nbsp;to&nbsp;the&nbsp;Active&nbsp;directory.
      </div>
      <div>
        This&nbsp;is&nbsp;needed&nbsp;in&nbsp;order&nbsp;to&nbsp;keep&nbsp;the&nbsp;Date&nbsp;change&nbsp;after&nbsp;an&nbsp;NC&nbsp;restart.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Share&nbsp;Number&nbsp;(00..nn)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;File&nbsp;Date&nbsp;(e.g.&nbsp;2012-06-27)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─────────</div>
      <div>S31/__/____-__-__</div>
      <div />
      <div />
    </div>
    <h2>Debug Queue/File</h2>
    <br />
    <div>S46&nbsp;DEBUG&nbsp;QUEUE/FILE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S46&nbsp;&nbsp;Display&nbsp;Debug&nbsp;Queue/File&nbsp;status</strong>
      </div>
      <div>S46</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S46/C&nbsp;&nbsp;Clear&nbsp;Debug&nbsp;Queue</strong>
      </div>
      <div>S46/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S46/S&nbsp;&nbsp;Save&nbsp;Debug&nbsp;Queue&nbsp;in&nbsp;File</strong>
      </div>
      <div>Debug&nbsp;is&nbsp;save&nbsp;in&nbsp;...\Data\Debug.txt</div>
      <div>S46/S</div>
      <div />
      <div />
    </div>
    <h2>InterNode OM Command</h2>
    <br />
    <div>S50&nbsp;&nbsp;INTERNODE&nbsp;OM&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S50&nbsp;&nbsp;Display&nbsp;Command&nbsp;function</strong>
      </div>
      <div>S50</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S50/D&nbsp;&nbsp;Display&nbsp;last&nbsp;received&nbsp;OM&nbsp;Command/Response</strong>
      </div>
      <div>S50/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S50/ccc/cc..c&nbsp;&nbsp;Send&nbsp;OM&nbsp;Command&nbsp;to&nbsp;other&nbsp;Node(s)</strong>
      </div>
      <div>See&nbsp;OM&nbsp;Help&nbsp;for&nbsp;available&nbsp;commands</div>
      <div>Multicommands&nbsp;separated&nbsp;with&nbsp;the&nbsp;'|'&nbsp;character&nbsp;is&nbsp;supported.</div>
      <div>
        As&nbsp;'|'&nbsp;however&nbsp;can&nbsp;not&nbsp;been&nbsp;send&nbsp;as&nbsp;it&nbsp;will&nbsp;be&nbsp;interpreted&nbsp;of&nbsp;the&nbsp;OM&nbsp;Program,
      </div>
      <div>
        the&nbsp;character&nbsp;'^'&nbsp;shall&nbsp;be&nbsp;used&nbsp;instead.&nbsp;The&nbsp;OM&nbsp;S50&nbsp;command&nbsp;interpreter&nbsp;will&nbsp;modify
      </div>
      <div>'^'&nbsp;to&nbsp;'|'&nbsp;before&nbsp;it&nbsp;is&nbsp;send.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;number&nbsp;(ALL&nbsp;or&nbsp;001..999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;OM&nbsp;Command&nbsp;String</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─────────────────────────────────────────────────────────────────────</div>
      <div>S50/___/______________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S50/QUE&nbsp;&nbsp;Display&nbsp;OM&nbsp;Command&nbsp;Queue</strong>
      </div>
      <div>S50/QUE</div>
      <div />
      <div />
    </div>
    <h2>Application Gateway Subscriber Register Command</h2>
    <br />
    <div>S51&nbsp;&nbsp;API&nbsp;SUBSCRIBER&nbsp;REGISTER&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S51&nbsp;&nbsp;Display&nbsp;Command&nbsp;function</strong>
      </div>
      <div>S51</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S51/D&nbsp;&nbsp;Display&nbsp;last&nbsp;API&nbsp;Command/Response</strong>
      </div>
      <div>S51/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S51/nnnnnnnn/cc..c&nbsp;&nbsp;Execute&nbsp;API&nbsp;Command</strong>
      </div>
      <div>
        The&nbsp;command&nbsp;simulates&nbsp;commands&nbsp;from&nbsp;the&nbsp;ApiDll&nbsp;to&nbsp;modify&nbsp;the&nbsp;Subscriber
      </div>
      <div>Register.</div>
      <div>
        Commands&nbsp;shall&nbsp;be&nbsp;entered&nbsp;in&nbsp;the&nbsp;ApiDll&nbsp;format&nbsp;("21/...,&nbsp;22/...&nbsp;and&nbsp;26/...).
      </div>
      <div>
        The&nbsp;Gateway&nbsp;will&nbsp;make&nbsp;syntax&nbsp;and&nbsp;permission&nbsp;check&nbsp;on&nbsp;the&nbsp;command,&nbsp;modify
      </div>
      <div>
        it&nbsp;to&nbsp;the&nbsp;NC&nbsp;format&nbsp;(S21/...,&nbsp;S22/...&nbsp;and&nbsp;S26/...)&nbsp;and&nbsp;send&nbsp;it&nbsp;to&nbsp;the
      </div>
      <div>Master&nbsp;Subscriber&nbsp;Register&nbsp;Node.</div>
      <div>See&nbsp;ApiDll&nbsp;Help&nbsp;for&nbsp;command&nbsp;syntax.</div>
      <div>See&nbsp;also&nbsp;20/QUE,&nbsp;51/D&nbsp;and&nbsp;51/...&nbsp;commands&nbsp;for&nbsp;the&nbsp;ApiDll.</div>
      <div>See&nbsp;also&nbsp;S51/D&nbsp;for&nbsp;the&nbsp;Gateway&nbsp;Node.</div>
      <div>
        See&nbsp;also&nbsp;S50/D&nbsp;and&nbsp;S20/QUE&nbsp;commands&nbsp;for&nbsp;the&nbsp;Master&nbsp;Subscriber&nbsp;Register&nbsp;Node.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SSI&nbsp;of&nbsp;of&nbsp;Application&nbsp;DLL&nbsp;(00000001..16777215)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Command&nbsp;String</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴────────────────────────────────────────────────────────────────</div>
      <div>S51/________/_________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>GUI OM Command</h2>
    <br />
    <div>S52&nbsp;GUI&nbsp;OM&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S52&nbsp;&nbsp;Display&nbsp;Last&nbsp;GUI&nbsp;OM&nbsp;Command/Response</strong>
      </div>
      <div>To&nbsp;be&nbsp;used&nbsp;for&nbsp;Software&nbsp;development&nbsp;only</div>
      <div>S52</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S52/cc..c&nbsp;&nbsp;Execute&nbsp;GUI&nbsp;OM&nbsp;Command</strong>
      </div>
      <div>Writes&nbsp;the&nbsp;OM&nbsp;Command&nbsp;to&nbsp;the&nbsp;GUI&nbsp;Shared&nbsp;Memory.</div>
      <div>Multicommands&nbsp;separated&nbsp;with&nbsp;the&nbsp;'|'&nbsp;character&nbsp;are&nbsp;supported.</div>
      <div>
        As&nbsp;'|'&nbsp;however&nbsp;can&nbsp;not&nbsp;been&nbsp;sent&nbsp;as&nbsp;it&nbsp;will&nbsp;be&nbsp;interpreted&nbsp;of&nbsp;the&nbsp;OM&nbsp;Program,
      </div>
      <div>
        the&nbsp;character&nbsp;'^'&nbsp;shall&nbsp;be&nbsp;used&nbsp;instead.&nbsp;The&nbsp;OM&nbsp;F50&nbsp;command&nbsp;interpreter&nbsp;will&nbsp;modify
      </div>
      <div>'^'&nbsp;to&nbsp;'|'&nbsp;before&nbsp;it&nbsp;is&nbsp;written.</div>
      <div>To&nbsp;be&nbsp;used&nbsp;for&nbsp;Software&nbsp;development&nbsp;only</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;OM&nbsp;Command</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴────────────────────────────────────────────────────────────────────────</div>
      <div>S52/_________________________________________________________________________</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Internode Control Message test queue:</h2>
    <br />
    <div>S61&nbsp;&nbsp;INTERNODE&nbsp;CONTROL&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61&nbsp;&nbsp;Display&nbsp;Internode&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>S61</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61/N&nbsp;&nbsp;Display&nbsp;next&nbsp;Internode&nbsp;Control&nbsp;Message</strong>
      </div>
      <div>S61/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;Internode&nbsp;Control&nbsp;Message&nbsp;extended</strong>
      </div>
      <div>S61/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S61/S&nbsp;&nbsp;Set&nbsp;Internode&nbsp;Control&nbsp;Message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start
        </strong>
      </div>
      <div>S61/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61/C&nbsp;&nbsp;Clear&nbsp;Internode&nbsp;Control&nbsp;Message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>S61/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61/s&nbsp;&nbsp;Internode&nbsp;Control&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;Internode&nbsp;Control&nbsp;messages</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;Internode&nbsp;Control&nbsp;messages&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S61/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61/ALL/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Control&nbsp;Message&nbsp;to&nbsp;all&nbsp;Nodes</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S61/ALL/__/__________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S61/SD/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Control&nbsp;Message&nbsp;to&nbsp;Subscriber&nbsp;Register&nbsp;Download&nbsp;Nodes
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S61/SD/__/__________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S61/DL/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Control&nbsp;Message&nbsp;to&nbsp;Discreet&nbsp;Listening&nbsp;Nodes
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S61/DL/__/__________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S61/nnn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Control&nbsp;Message&nbsp;to&nbsp;specific&nbsp;Node</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S61/___/__/__________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S61/nnn.nnn.nnn.nnn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Control&nbsp;Message&nbsp;to&nbsp;specific&nbsp;IP&nbsp;Address
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S61/___.___.___.___/__/______________________________________________________</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Log Server Message test queue:</h2>
    <br />
    <div>S62&nbsp;&nbsp;LOG&nbsp;SERVER&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62&nbsp;&nbsp;Display&nbsp;Log&nbsp;Server&nbsp;Connection&nbsp;Status</strong>
      </div>
      <div>S62</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/N&nbsp;&nbsp;Display&nbsp;next&nbsp;Log&nbsp;Server&nbsp;message</strong>
      </div>
      <div>S62/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;Log&nbsp;Server&nbsp;message&nbsp;extended</strong>
      </div>
      <div>S62/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/S&nbsp;&nbsp;Set&nbsp;Log&nbsp;Server&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>S62/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/C&nbsp;&nbsp;Clear&nbsp;Log&nbsp;Server&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>S62/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/s&nbsp;&nbsp;Log&nbsp;Server&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;Log&nbsp;Server&nbsp;messages</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;Log&nbsp;Server&nbsp;messages&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S62/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/hh/hh..hh&nbsp;&nbsp;Send&nbsp;message&nbsp;to&nbsp;Home&nbsp;Log&nbsp;Servers</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>S62/__/__________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S62/nnn.nnn.nnn.nnn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;message&nbsp;to&nbsp;Log&nbsp;Servers</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;multicast&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(hex)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Parameters&nbsp;(hex)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S62/___.___.___.___/__/__________________________________________________________________</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Redundant NC Message Test</h2>
    <br />
    <div>S64&nbsp;&nbsp;REDUNDANT&nbsp;NC&nbsp;MESSAGE&nbsp;TEST</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S64&nbsp;&nbsp;Display&nbsp;Redundant&nbsp;Node&nbsp;Controller&nbsp;Connection</strong>
      </div>
      <div>S64</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S64/N&nbsp;&nbsp;Display&nbsp;next&nbsp;Redundant&nbsp;Node&nbsp;Controller&nbsp;message</strong>
      </div>
      <div>S64/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S64/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;Redundant&nbsp;Node&nbsp;Controller&nbsp;message&nbsp;extended
        </strong>
      </div>
      <div>S64/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S64/S&nbsp;&nbsp;Set&nbsp;Redundant&nbsp;Node&nbsp;Controller&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start
        </strong>
      </div>
      <div>S64/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S64/C&nbsp;&nbsp;Clear&nbsp;Redundant&nbsp;Node&nbsp;Controller&nbsp;message&nbsp;test&nbsp;queue
        </strong>
      </div>
      <div>S64/C</div>
      <div />
      <div />
    </div>
    <h2>TR Message Test</h2>
    <br />
    <div>S65&nbsp;&nbsp;TR&nbsp;MESSAGE&nbsp;TEST</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65&nbsp;&nbsp;Display&nbsp;TR&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>S65</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/TCP&nbsp;&nbsp;Display&nbsp;TR&nbsp;TCP&nbsp;Connections</strong>
      </div>
      <div>S65/TCP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/N&nbsp;&nbsp;Display&nbsp;next&nbsp;TR&nbsp;message</strong>
      </div>
      <div>S65/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;TR&nbsp;message&nbsp;extended</strong>
      </div>
      <div>S65/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/S&nbsp;&nbsp;Set&nbsp;TR&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>S65/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/C&nbsp;&nbsp;Clear&nbsp;TR&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>S65/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/s&nbsp;&nbsp;TR&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;TR&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;TR&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S65/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S65/nn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;TR</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>S65/__/__/______________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>CPU load</h2>
    <br />
    <div>S66&nbsp;&nbsp;CPU&nbsp;LOAD&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S66&nbsp;&nbsp;Display&nbsp;CPU&nbsp;load</strong>
      </div>
      <div>S66</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S66/C&nbsp;&nbsp;Clear&nbsp;CPU&nbsp;Load&nbsp;Peak&nbsp;Hold</strong>
      </div>
      <div>S66/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S66/T&nbsp;&nbsp;Display&nbsp;1msec.&nbsp;Timer</strong>
      </div>
      <div>S66/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S66/Q&nbsp;&nbsp;Display&nbsp;QPC&nbsp;Timer</strong>
      </div>
      <div>S66/Q</div>
      <div />
      <div />
    </div>
    <h2>Node Configuration Commands:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S70&nbsp;&nbsp;Display&nbsp;last&nbsp;file&nbsp;save&nbsp;result</strong>
      </div>
      <div>S70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S70/SAVE&nbsp;&nbsp;Save&nbsp;Node&nbsp;Configuration</strong>
      </div>
      <div>Note&nbsp;that&nbsp;Node&nbsp;Configuration&nbsp;changes&nbsp;are&nbsp;saved&nbsp;automatically!</div>
      <div>S70/SAVE</div>
      <div />
      <div />
    </div>
    <h2>Local Node Controller configurations:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71&nbsp;&nbsp;Display&nbsp;General&nbsp;Node&nbsp;configuration</strong>
      </div>
      <div>S71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/DESCR/cc..c&nbsp;&nbsp;Change&nbsp;Description</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Desctiption</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────────────────────</div>
      <div>S71/DESCR/_______________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/CNFG/s&nbsp;&nbsp;Change&nbsp;Node&nbsp;Configuraion</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;&nbsp;Activate&nbsp;Node</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;&nbsp;Disable&nbsp;Node</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/CNFG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/NCNO/n&nbsp;&nbsp;Change&nbsp;Node&nbsp;Controller&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Controller&nbsp;Number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;1:&nbsp;&nbsp;NC1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;&nbsp;NC2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/NCNO/_</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IPADDR/ccc/...&nbsp;&nbsp;Change&nbsp;IP&nbsp;address</strong>
      </div>
      <div>
        Note,&nbsp;that&nbsp;it&nbsp;shall&nbsp;match&nbsp;the&nbsp;IP&nbsp;Address&nbsp;for&nbsp;an&nbsp;Ethernet&nbsp;adapter.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;LAN:&nbsp;&nbsp;LAN&nbsp;Local&nbsp;Address
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;WAN:&nbsp;&nbsp;WAN&nbsp;Local&nbsp;Address
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴──────────────</div>
      <div>S71/IPADDR/___/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/POS/...&nbsp;&nbsp;Change&nbsp;Position</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;degree&nbsp;(00..89)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;minutes&nbsp;(00..59)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;seconds&nbsp;(00.00..59.99)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Latitude&nbsp;N/S&nbsp;(N&nbsp;or&nbsp;S)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Longitude&nbsp;degree&nbsp;(000..179)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Longitude&nbsp;minutes&nbsp;(00..59)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Longitude&nbsp;seconds&nbsp;(00.00..59.99)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Longitude&nbsp;E/W&nbsp;(E&nbsp;or&nbsp;W)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;┴────┴&nbsp;┴──&nbsp;┴─&nbsp;┴────┴</div>
      <div>S71/POS/__:__:__.___/___:__:__.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/NODENO/nnn&nbsp;&nbsp;Change&nbsp;Node&nbsp;Number</strong>
      </div>
      <div>Max.&nbsp;node&nbsp;number&nbsp;is&nbsp;limited&nbsp;of:</div>
      <div>a)&nbsp;Compile&nbsp;constant,&nbsp;see&nbsp;S00/C&nbsp;command</div>
      <div>b)&nbsp;License&nbsp;Dongle,&nbsp;see&nbsp;S04&nbsp;command</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..nnn)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S71/NODENO/___</div>
      <div />
      <div />
    </div>
    <h2>Redundant Node Controller configurations:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/REDNC/s&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;Node&nbsp;Controler&nbsp;Selection</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Select&nbsp;Redundant&nbsp;NC
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Deselect&nbsp;Redundant&nbsp;NC
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/REDNC/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/REDNC/...&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;Node&nbsp;Controller&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S71/REDNC/___.___.___.___</div>
      <div />
      <div />
    </div>
    <h2>Internode Connections:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IP/MCADDR/...&nbsp;&nbsp;Change&nbsp;Multicast&nbsp;Base&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>Defines&nbsp;the&nbsp;start&nbsp;address&nbsp;for&nbsp;the&nbsp;Multicast&nbsp;Address&nbsp;block</div>
      <div>The&nbsp;entered&nbsp;address&nbsp;is&nbsp;truncated&nbsp;with&nbsp;a&nbsp;/28&nbsp;mask</div>
      <div>Used&nbsp;addresses&nbsp;(for&nbsp;default&nbsp;Base&nbsp;Address):</div>
      <div>224.239.016.001:&nbsp;&nbsp;Main&nbsp;Internode&nbsp;Control&nbsp;Multicast&nbsp;Address</div>
      <div>224.239.016.003:&nbsp;&nbsp;Discreet&nbsp;Listening&nbsp;Multicast&nbsp;Address</div>
      <div>224.239.016.004:&nbsp;&nbsp;Subscriber&nbsp;Register&nbsp;Download&nbsp;Multicast&nbsp;Address</div>
      <div>Default&nbsp;Base&nbsp;Address:&nbsp;224.239.016.000</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(224.000.002.000..239.255.255.254)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S71/IP/MCADDR/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IP/MCPORT/nnnnn&nbsp;&nbsp;Change&nbsp;Control&nbsp;Multicast&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>UDP&nbsp;Port&nbsp;Number&nbsp;for&nbsp;Multicast&nbsp;communication&nbsp;with&nbsp;other&nbsp;Nodes</div>
      <div>Default&nbsp;Port&nbsp;Number&nbsp;is&nbsp;42400</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Multicast&nbsp;UDP&nbsp;Port&nbsp;(00000..65535)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S71/IP/MCPORT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IP/UCPORT/nnnnn&nbsp;&nbsp;Change&nbsp;Control&nbsp;Unicast&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>UDP&nbsp;Port&nbsp;Number&nbsp;for&nbsp;Unicast&nbsp;communication&nbsp;with&nbsp;other&nbsp;Nodes</div>
      <div>Default&nbsp;Port&nbsp;Number&nbsp;is&nbsp;42401</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Unicast&nbsp;UDP&nbsp;Port&nbsp;(00000..65535)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S71/IP/UCPORT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IP/LOGADDR/...&nbsp;&nbsp;Change&nbsp;Log&nbsp;Multicast&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>IP&nbsp;Multicast&nbsp;Address&nbsp;for&nbsp;the&nbsp;Serving&nbsp;Log&nbsp;Servers</div>
      <div>
        If&nbsp;more&nbsp;than&nbsp;one&nbsp;Log&nbsp;Server&nbsp;Group&nbsp;is&nbsp;used,&nbsp;change&nbsp;the&nbsp;last&nbsp;field
      </div>
      <div>to&nbsp;the&nbsp;actual&nbsp;Log&nbsp;Server&nbsp;Group&nbsp;Number</div>
      <div>Default&nbsp;Address&nbsp;is&nbsp;224.239.017.001</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(224.000.002.000..239.255.255.254)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────
      </div>
      <div>S71/IP/LOGADDR/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IP/LOGPORT/nnnnn&nbsp;&nbsp;Change&nbsp;Log&nbsp;UDP&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>UDP&nbsp;Port&nbsp;Number&nbsp;for&nbsp;Log&nbsp;Servers</div>
      <div>Default&nbsp;Port&nbsp;Number&nbsp;is&nbsp;42402</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UDP&nbsp;Port&nbsp;(00000..65535)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S71/IP/LOGPORT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/IP/CMOIPADDR/...&nbsp;&nbsp;Change&nbsp;CMoIP&nbsp;Multicast&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>Most&nbsp;significant&nbsp;bytes&nbsp;of&nbsp;IP&nbsp;Multicast&nbsp;Address&nbsp;for&nbsp;CMoIP</div>
      <div>Default&nbsp;Address&nbsp;is&nbsp;225.000.x.x</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;CMoIP&nbsp;IP&nbsp;address&nbsp;(225.000..239.255)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S71/IP/CMOIPADDR/___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/UCPORT/nnnnn&nbsp;&nbsp;Change&nbsp;Unicast&nbsp;UDP&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>UDP&nbsp;Port&nbsp;Number&nbsp;for&nbsp;Unicast&nbsp;communication&nbsp;with&nbsp;other&nbsp;Nodes</div>
      <div>Default&nbsp;Port&nbsp;Number&nbsp;is&nbsp;42405</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Unicast&nbsp;UDP&nbsp;Port&nbsp;(00000..65535)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S71/UCPORT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/MCC/nnnn&nbsp;&nbsp;Change&nbsp;Home&nbsp;Mobile&nbsp;Country&nbsp;Code</strong>
      </div>
      <div>MCC&nbsp;part&nbsp;of&nbsp;the&nbsp;HomeMNI</div>
      <div>
        NB:&nbsp;If&nbsp;the&nbsp;TSI&nbsp;count&nbsp;is&nbsp;not&nbsp;zero,&nbsp;this&nbsp;parameter&nbsp;should&nbsp;not&nbsp;be&nbsp;changed
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;without&nbsp;carefully&nbsp;analyzing&nbsp;the&nbsp;impact.&nbsp;&nbsp;</div>
      <div>
        NB:&nbsp;After&nbsp;a&nbsp;change&nbsp;of&nbsp;Home&nbsp;MCC/MNC&nbsp;a&nbsp;restart&nbsp;of&nbsp;the&nbsp;whole&nbsp;system&nbsp;is&nbsp;recommended!
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Home&nbsp;MCC&nbsp;(0001..1023)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S71/MCC/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/MNC/nnnnn&nbsp;&nbsp;Change&nbsp;Home&nbsp;Mobile&nbsp;Network&nbsp;Code</strong>
      </div>
      <div>MNC&nbsp;part&nbsp;of&nbsp;the&nbsp;HomeMNI</div>
      <div>
        NB:&nbsp;If&nbsp;the&nbsp;TSI&nbsp;count&nbsp;is&nbsp;not&nbsp;zero,&nbsp;this&nbsp;parameter&nbsp;should&nbsp;not&nbsp;be&nbsp;changed
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;without&nbsp;carefully&nbsp;analyzing&nbsp;the&nbsp;impact.&nbsp;&nbsp;</div>
      <div>
        NB:&nbsp;After&nbsp;a&nbsp;change&nbsp;of&nbsp;Home&nbsp;MCC/MNC&nbsp;a&nbsp;restart&nbsp;of&nbsp;the&nbsp;whole&nbsp;system&nbsp;is&nbsp;recommended!
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Home&nbsp;MNC&nbsp;(0001..16383)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S71/MNC/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/NODETIMEOUT/nnnn&nbsp;&nbsp;Change&nbsp;Inter-node&nbsp;Timeout</strong>
      </div>
      <div>Changes&nbsp;the&nbsp;timeout&nbsp;of&nbsp;responses&nbsp;from&nbsp;other&nbsp;Nodes.</div>
      <div>
        Shall&nbsp;be&nbsp;at&nbsp;least&nbsp;200&nbsp;msec.&nbsp;larger&nbsp;than&nbsp;the&nbsp;peak&nbsp;roud-trip&nbsp;time&nbsp;to&nbsp;any&nbsp;Node.
      </div>
      <div>Use&nbsp;Ping.exe&nbsp;to&nbsp;determine&nbsp;the&nbsp;actual&nbsp;round-trip&nbsp;time.</div>
      <div>Note,&nbsp;that&nbsp;the&nbsp;last&nbsp;digit&nbsp;will&nbsp;be&nbsp;truncated&nbsp;to&nbsp;zero.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;500&nbsp;msec.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Inter-node&nbsp;Timeout&nbsp;(0200..2550&nbsp;[msec])
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S71/NODETIMEOUT/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/MULTIVOCODER/s&nbsp;&nbsp;Change&nbsp;Multivocoder&nbsp;selection</strong>
      </div>
      <div>---To&nbsp;be&nbsp;always&nbsp;enabled???</div>
      <div>Changes&nbsp;the&nbsp;function&nbsp;of&nbsp;the&nbsp;Vocoders&nbsp;for&nbsp;the&nbsp;Gateways</div>
      <div>Disabled:</div>
      <div>&nbsp;&nbsp;Core&nbsp;network&nbsp;uses&nbsp;always&nbsp;Tetra&nbsp;Coded&nbsp;Voice</div>
      <div>&nbsp;&nbsp;Functionality&nbsp;as&nbsp;in&nbsp;TetraFlex&nbsp;before&nbsp;version&nbsp;7.60</div>
      <div>Enabled:</div>
      <div>&nbsp;&nbsp;Core&nbsp;network&nbsp;uses&nbsp;several&nbsp;different&nbsp;Voice&nbsp;codings</div>
      <div>
        &nbsp;&nbsp;Allows&nbsp;higher&nbsp;quality&nbsp;voice&nbsp;between&nbsp;Appl.,&nbsp;Term.&nbsp;and&nbsp;Voice&nbsp;Gateways&nbsp;
      </div>
      <div>&nbsp;&nbsp;Requirement:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;Vocoders&nbsp;shall&nbsp;be&nbsp;present&nbsp;in&nbsp;BS/TR</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;Faster&nbsp;BS&nbsp;downlink&nbsp;IP&nbsp;connection&nbsp;(2048/512kbit/s&nbsp;for&nbsp;4-carrier&nbsp;BS)&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        NB:&nbsp;This&nbsp;setting&nbsp;shall&nbsp;be&nbsp;the&nbsp;same&nbsp;on&nbsp;all&nbsp;Nodes&nbsp;in&nbsp;the&nbsp;system
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Multivocoder
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Disabled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Enabled
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/MULTIVOCODER/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/VOICEBUF/n&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Buffer&nbsp;length</strong>
      </div>
      <div>Changes&nbsp;the&nbsp;initial&nbsp;length&nbsp;of&nbsp;the&nbsp;Voice&nbsp;Elastic&nbsp;Buffer</div>
      <div>
        This&nbsp;value&nbsp;is&nbsp;used&nbsp;to&nbsp;select&nbsp;a&nbsp;resonable&nbsp;balance&nbsp;between&nbsp;delay&nbsp;and&nbsp;slips.
      </div>
      <div>
        Lower&nbsp;value&nbsp;gives&nbsp;lower&nbsp;average&nbsp;speech&nbsp;delay&nbsp;but&nbsp;more&nbsp;slips.
      </div>
      <div>
        Higher&nbsp;value&nbsp;gives&nbsp;less&nbsp;slips&nbsp;but&nbsp;higher&nbsp;average&nbsp;speech&nbsp;delay.
      </div>
      <div>Default&nbsp;value&nbsp;is&nbsp;2&nbsp;Voice&nbsp;Frames&nbsp;(2x60msec.)&nbsp;</div>
      <div>
        A&nbsp;higher&nbsp;value&nbsp;can&nbsp;improve&nbsp;voice&nbsp;quality&nbsp;if&nbsp;the&nbsp;transmission&nbsp;network&nbsp;has
      </div>
      <div>high&nbsp;jitter&nbsp;and/or&nbsp;End-to-End&nbsp;Encryption&nbsp;is&nbsp;used.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Initial&nbsp;Voice&nbsp;Buffer&nbsp;Length&nbsp;(1..5,&nbsp;default=2)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/VOICEBUF/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/SUBIPADDR/...&nbsp;&nbsp;Change&nbsp;Subscriber&nbsp;IP&nbsp;Address&nbsp;Segment</strong>
      </div>
      <div>IP&nbsp;Address&nbsp;Segment&nbsp;used&nbsp;for&nbsp;Mobiles&nbsp;for&nbsp;Packet&nbsp;Data</div>
      <div>
        NB:&nbsp;The&nbsp;LSB&nbsp;of&nbsp;the&nbsp;Address&nbsp;is&nbsp;automatically&nbsp;cleared&nbsp;by&nbsp;the&nbsp;Mask
      </div>
      <div>Default&nbsp;Address&nbsp;is&nbsp;172.28.0.0/16</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Mask&nbsp;(12..30)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴─
      </div>
      <div>S71/SUBIPADDR/___.___.___.___/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/SUBMODE/c&nbsp;&nbsp;Change&nbsp;Subscriber&nbsp;Register&nbsp;Mode</strong>
      </div>
      <div>Controls&nbsp;replication&nbsp;between&nbsp;Nodes&nbsp;(Node&nbsp;Controllers)</div>
      <div>
        An&nbsp;NC&nbsp;set&nbsp;to&nbsp;Master&nbsp;is&nbsp;automatically&nbsp;running&nbsp;Slave&nbsp;when&nbsp;Standby
      </div>
      <div>The&nbsp;two&nbsp;redundant&nbsp;BSC's&nbsp;shall&nbsp;have&nbsp;same&nbsp;setting</div>
      <div>
        For&nbsp;single&nbsp;node&nbsp;systems&nbsp;with&nbsp;redundant&nbsp;NC's,&nbsp;both&nbsp;shall&nbsp;be&nbsp;set&nbsp;to&nbsp;Master
      </div>
      <div>Warning:&nbsp;Do&nbsp;only&nbsp;select&nbsp;Master&nbsp;Mode&nbsp;on&nbsp;one&nbsp;Node!</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Subscriber&nbsp;Register&nbsp;Mode
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Single&nbsp;(No&nbsp;update&nbsp;to/from&nbsp;other&nbsp;NC's)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;M:&nbsp;Master&nbsp;(Active&nbsp;NC&nbsp;:&nbsp;Sends&nbsp;updates&nbsp;to&nbsp;all&nbsp;slave&nbsp;NC's)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Standby&nbsp;NC:&nbsp;Gets&nbsp;update&nbsp;from&nbsp;Master,&nbsp;no&nbsp;changes)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;S:&nbsp;Slave&nbsp;(Gets&nbsp;update&nbsp;from&nbsp;Master,&nbsp;no&nbsp;OM/NM&nbsp;changes)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/SUBMODE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/KEYREG/s&nbsp;&nbsp;Key&nbsp;Register&nbsp;activation</strong>
      </div>
      <div>Activates&nbsp;the&nbsp;Security&nbsp;Key&nbsp;Register&nbsp;function</div>
      <div>NB:&nbsp;Shall&nbsp;only&nbsp;be&nbsp;activated&nbsp;on&nbsp;NC's&nbsp;with&nbsp;Subscriber</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;Register&nbsp;in&nbsp;Single&nbsp;or&nbsp;Master&nbsp;mode</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Key&nbsp;Register</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activation</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivation</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/KEYREG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/SHARENAME/cc..c&nbsp;&nbsp;Share&nbsp;Name</strong>
      </div>
      <div>
        Sets&nbsp;the&nbsp;Share&nbsp;Name&nbsp;for&nbsp;the&nbsp;File&nbsp;Share&nbsp;for&nbsp;updates&nbsp;for&nbsp;ApiDll,&nbsp;Dispatcher,
      </div>
      <div>Clients&nbsp;etc.</div>
      <div>Shall&nbsp;match&nbsp;the&nbsp;Share&nbsp;Name&nbsp;used&nbsp;for&nbsp;"Share"&nbsp;directory.</div>
      <div>For&nbsp;a&nbsp;single-node&nbsp;PC&nbsp;the&nbsp;default&nbsp;value&nbsp;is&nbsp;"TetraFlex".</div>
      <div>
        For&nbsp;a&nbsp;Multi-node&nbsp;PC&nbsp;the&nbsp;share&nbsp;for&nbsp;each&nbsp;Node&nbsp;shall&nbsp;be&nbsp;setup&nbsp;to&nbsp;be&nbsp;different.
      </div>
      <div />
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Share&nbsp;Name
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────
      </div>
      <div>S71/SHARENAME/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/SHAREUSER/cc..c&nbsp;&nbsp;Share&nbsp;User</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Share&nbsp;User&nbsp;Name&nbsp;for&nbsp;the&nbsp;File&nbsp;Share.</div>
      <div>The&nbsp;User&nbsp;shall&nbsp;have&nbsp;Read&nbsp;access&nbsp;to&nbsp;the&nbsp;Share.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;"Tetra".</div>
      <div />
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Share&nbsp;User
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────
      </div>
      <div>S71/SHAREUSER/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/SHAREPASSWORD/cc..c&nbsp;&nbsp;Share&nbsp;Password</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Share&nbsp;Password&nbsp;for&nbsp;the&nbsp;File&nbsp;Share&nbsp;User.</div>
      <div>The&nbsp;default&nbsp;value&nbsp;is&nbsp;"Tetra".</div>
      <div />
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Share&nbsp;Password
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────
      </div>
      <div>S71/SHAREPASSWORD/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/SNMP/c&nbsp;&nbsp;SNMP&nbsp;Mode</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;SNMP&nbsp;functionality&nbsp;on&nbsp;the&nbsp;Node&nbsp;Controller</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;SNMP&nbsp;Mode</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;SNMP&nbsp;disabled&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;SNMP&nbsp;report&nbsp;from&nbsp;this&nbsp;NC&nbsp;only
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;SNMP&nbsp;report&nbsp;from&nbsp;all&nbsp;NC's
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S71/SNMP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S71/EMERGROUPTIMEOUTMIN/nnnnn&nbsp;&nbsp;Set&nbsp;Emergency&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;Timeout&nbsp;Minimum
        </strong>
      </div>
      <div>
        Sets&nbsp;for&nbsp;Emergency&nbsp;Group&nbsp;Calls&nbsp;the&nbsp;minimum&nbsp;time&nbsp;from&nbsp;the&nbsp;last&nbsp;PTT&nbsp;released
      </div>
      <div>
        until&nbsp;Group&nbsp;Call&nbsp;termination.&nbsp;The&nbsp;value&nbsp;can&nbsp;overrule&nbsp;the&nbsp;timeout&nbsp;set&nbsp;for&nbsp;a
      </div>
      <div>Group&nbsp;SSI.</div>
      <div />
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Timeout&nbsp;(00000..65000&nbsp;[s])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────
      </div>
      <div>S71/EMERGROUPTIMEOUTMIN/_____</div>
      <div />
      <div>S71/PS&nbsp;&nbsp;Display&nbsp;Power&nbsp;Supply&nbsp;configuration</div>
      <div>S71/PS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/PS/nn/+&nbsp;&nbsp;Add&nbsp;PS</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;</div>
      <div>S72/PS/__/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S71/PS/nn/-&nbsp;&nbsp;Remove&nbsp;PS</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S71/PS/__/-</div>
      <div />
      <div />
    </div>
    <h2>Radio Cell configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;configuration</strong>
      </div>
      <div>S72</div>
      <div />
      <div />
    </div>
    <h2>Physical TR configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/TR&nbsp;&nbsp;Display&nbsp;Physical&nbsp;TR&nbsp;configuration</strong>
      </div>
      <div>S72/TR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/TR/nn/ADD/TRnnn&nbsp;&nbsp;Add&nbsp;Physical&nbsp;TR</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Type&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TR422:&nbsp;Multi-Carrier
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TR421:&nbsp;Single-Carrier&nbsp;TETRA&nbsp;only
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;TR412:&nbsp;Single-Carrier&nbsp;TETRA&nbsp;only
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S72/TR/__/ADD/TR___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/TR/nn/REMOVE&nbsp;&nbsp;Remove&nbsp;Physical&nbsp;TR</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/TR/__/REMOVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/TR/nn/HOTSPARE/s&nbsp;&nbsp;Change&nbsp;Physical&nbsp;TR&nbsp;to&nbsp;Hot&nbsp;Spare</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Hot&nbsp;Spare
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Normal
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S72/TR/__/HOTSPARE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/TR/nn/FREQ/...&nbsp;&nbsp;Change&nbsp;Physical&nbsp;TR&nbsp;Frequencies</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;Frequency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;Frequency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────&nbsp;┴─────────
      </div>
      <div>S72/TR/__/FREQ/___.______/___.______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/TR/nn/POWER/nn.n/nn.n&nbsp;&nbsp;Change&nbsp;TR&nbsp;Max.&nbsp;Output&nbsp;Power</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;Normal&nbsp;Output&nbsp;Power&nbsp;(Average)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;Reduced&nbsp;Output&nbsp;Power&nbsp;(Average)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───&nbsp;┴───</div>
      <div>S72/TR/__/POWER/__._/__._</div>
      <div />
      <div />
    </div>
    <h2>Logical Carrier configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/CAR&nbsp;&nbsp;Display&nbsp;Logical&nbsp;Carries</strong>
      </div>
      <div>S72/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/CAR/nn/n/ADD/nn&nbsp;&nbsp;Add/Change&nbsp;Logical&nbsp;Carrier</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Carrier&nbsp;Mode
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;TETRA&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;TEDS&nbsp;25kHz&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;TEDS&nbsp;50kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;TEDS&nbsp;100kHz&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;TEDS&nbsp;150kHz&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;DMR&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;Analog&nbsp;PM&nbsp;6.25kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;Analog&nbsp;PM&nbsp;12.5kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;Analog&nbsp;PM&nbsp;20kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;09:&nbsp;Analog&nbsp;PM&nbsp;25kHz
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/CAR/__/_/ADD/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/CAR/nn/n/REMOVE&nbsp;&nbsp;Remove&nbsp;Logical&nbsp;Carrier</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>S72/CAR/__/_/REMOVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/CAR/nn/n/FREQ/snnn.nnn/snnn.nnn&nbsp;&nbsp;Setup&nbsp;Logical&nbsp;Carrier&nbsp;Frequency&nbsp;Offset
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;Frequency&nbsp;Offset&nbsp;(-150.000..+150.000)&nbsp;[kHz]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;Frequency&nbsp;Offset&nbsp;(-150.000..+150.000)&nbsp;[kHz]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────
      </div>
      <div>S72/CAR/__/_/FREQ/____.___/____.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/CAR/nn/n/POWER/nn.n&nbsp;&nbsp;Setup&nbsp;Logical&nbsp;Carrier&nbsp;Output&nbsp;Power</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Output&nbsp;Power&nbsp;(27.0..47.0)&nbsp;[dBm]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>S72/CAR/__/_/POWER/__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/CAR/nn/n/SIMCAST/ADD/nnn/nnn/n&nbsp;&nbsp;Add&nbsp;Logical&nbsp;Carrier&nbsp;Simulcast</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Number&nbsp;(001..999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Master&nbsp;Priority&nbsp;(000..255)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;TDMA&nbsp;Offset&nbsp;(0..9)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴──&nbsp;┴
      </div>
      <div>S72/CAR/__/_/SIMCAST/ADD/___/___/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/CAR/nn/n/SIMCAST/REMOVE&nbsp;&nbsp;Remove&nbsp;Logical&nbsp;Carrier&nbsp;Simulcast</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      <div>S72/CAR/__/_/SIMCAST/REMOVE</div>
      <div />
      <div />
    </div>
    <h2>Tetra Cell configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T&nbsp;&nbsp;Display&nbsp;Tetra&nbsp;Cell&nbsp;configuration</strong>
      </div>
      <div>S72/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/CAR&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Cell&nbsp;Carriers</strong>
      </div>
      <div>S72/T/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/CNFG/s&nbsp;&nbsp;Tetra&nbsp;Cell&nbsp;Activation/Deactivation</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;&nbsp;Activate&nbsp;TETRA&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;&nbsp;Deactivate&nbsp;TETRA&nbsp;Cell
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/CNFG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/SCCH/n/n&nbsp;&nbsp;Change&nbsp;number&nbsp;of&nbsp;SCCH&nbsp;to&nbsp;setup</strong>
      </div>
      <div>
        A&nbsp;BS&nbsp;can&nbsp;have&nbsp;up&nbsp;to&nbsp;3&nbsp;SCCH,&nbsp;using&nbsp;the&nbsp;available&nbsp;Timeslots&nbsp;on&nbsp;the&nbsp;Main&nbsp;Carrier.
      </div>
      <div>
        When&nbsp;the&nbsp;BS&nbsp;changes&nbsp;to&nbsp;Single-Carrier&nbsp;operation&nbsp;(e.g&nbsp;due&nbsp;to&nbsp;Carrier&nbsp;faults),
      </div>
      <div>the&nbsp;number&nbsp;of&nbsp;SCCH&nbsp;is&nbsp;changed&nbsp;automatically.</div>
      <div>
        This&nbsp;can&nbsp;be&nbsp;used&nbsp;reduce&nbsp;the&nbsp;number&nbsp;of&nbsp;SCCH&nbsp;to&nbsp;free&nbsp;Timeslots&nbsp;for&nbsp;Voice&nbsp;Calls&nbsp;on
      </div>
      <div>the&nbsp;Main&nbsp;Carrier.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Normal&nbsp;SCCH&nbsp;count&nbsp;(0..3)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Single-Carrier&nbsp;SCCH&nbsp;count&nbsp;(0..3)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>S72/T/SCCH/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/PDCH/nn&nbsp;&nbsp;Change&nbsp;minimum&nbsp;number&nbsp;of&nbsp;PDCH</strong>
      </div>
      <div>
        Command&nbsp;used&nbsp;to&nbsp;reserve&nbsp;a&nbsp;number&nbsp;of&nbsp;timeslots&nbsp;exclusively&nbsp;for
      </div>
      <div>
        Packet&nbsp;Data.&nbsp;These&nbsp;wil&nbsp;not&nbsp;be&nbsp;pre-empted&nbsp;for&nbsp;voice&nbsp;communication.
      </div>
      <div>Default&nbsp;is&nbsp;0.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Minimum&nbsp;number&nbsp;of&nbsp;PDCH&nbsp;(00..99)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/PDCH/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/FALLBACK/s&nbsp;&nbsp;Change&nbsp;Fallback&nbsp;Mode&nbsp;Indication</strong>
      </div>
      <div>Changes&nbsp;Fallback&nbsp;Mode&nbsp;indication&nbsp;to&nbsp;the&nbsp;mobiles</div>
      <div>
        If&nbsp;activated,&nbsp;fallback&nbsp;mode&nbsp;will&nbsp;be&nbsp;indicated&nbsp;status&nbsp;update&nbsp;are&nbsp;not
      </div>
      <div>received&nbsp;from&nbsp;other&nbsp;Nodes.</div>
      <div>
        Fallback&nbsp;mode&nbsp;indicates&nbsp;to&nbsp;mobiles,&nbsp;that&nbsp;full&nbsp;network&nbsp;service&nbsp;are
      </div>
      <div>not&nbsp;available.</div>
      <div>Shall&nbsp;be&nbsp;disabled&nbsp;for&nbsp;single-site&nbsp;systems.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Fallback&nbsp;Mode&nbsp;Indication
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Disable
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/FALLBACK/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/MSPWR/n&nbsp;&nbsp;Change&nbsp;max.&nbsp;MS&nbsp;TX&nbsp;Power</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;MS&nbsp;TX&nbsp;Power&nbsp;Level&nbsp;(0..7)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;Spare</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;+15dBm&nbsp;(30mW)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;+20dBm&nbsp;(100mW)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;+25dBm&nbsp;(300mW)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;+30dBm&nbsp;(1W)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;5:&nbsp;+35dBm&nbsp;(3W)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;6:&nbsp;+40dBm&nbsp;(10W)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;7:&nbsp;+45dBm&nbsp;(30W)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/MSPWR/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/MSACC/nn&nbsp;&nbsp;Change&nbsp;min.&nbsp;MS&nbsp;RX&nbsp;Access&nbsp;Level</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Min.&nbsp;MS&nbsp;RX&nbsp;Access&nbsp;Level&nbsp;(0..17)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;-125dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;-120dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;-115dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;-110dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;-105dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;-100dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;-95dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;-90dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;..:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15:&nbsp;-50dBm</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/MSACC/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/ACCPAR/nn&nbsp;&nbsp;Radio&nbsp;Access&nbsp;Parameter</strong>
      </div>
      <div>
        Controls&nbsp;when&nbsp;the&nbsp;mobile&nbsp;starts&nbsp;to&nbsp;reduce&nbsp;its&nbsp;output&nbsp;power&nbsp;at&nbsp;high
      </div>
      <div>received&nbsp;signal&nbsp;strength.</div>
      <div>
        For&nbsp;a&nbsp;1W&nbsp;mobile,&nbsp;output&nbsp;power&nbsp;will&nbsp;start&nbsp;to&nbsp;decrease&nbsp;at&nbsp;-67dBm&nbsp;input
      </div>
      <div>signal&nbsp;strength&nbsp;with&nbsp;the&nbsp;default&nbsp;setting.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Radio&nbsp;Access&nbsp;Parameter
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;-53dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;-51dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;-49dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;-47dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;-45dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;-43dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;-41dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;-39dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;-37dBm&nbsp;(Default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;09:&nbsp;-35dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;10:&nbsp;-33dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;11:&nbsp;-31dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;-29dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;-27dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;14:&nbsp;-25dBm
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15:&nbsp;-23dBm
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/ACCPAR/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/DLTO/nn&nbsp;&nbsp;Change&nbsp;Radio&nbsp;Downlink&nbsp;Timeout</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Radio&nbsp;Downlink&nbsp;Timeout
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;00:&nbsp;Disabled</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;01:&nbsp;144&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;02:&nbsp;288&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;432&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;576&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;05:&nbsp;720&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;864&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;1008&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;1152&nbsp;timeslot&nbsp;(Default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;09:&nbsp;1296&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;10:&nbsp;1440&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;11:&nbsp;1584&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;1728&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;1872&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;14:&nbsp;2016&nbsp;timeslot
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15:&nbsp;2160&nbsp;timeslot
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/DLTO/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/CONNTIMEOUT/n&nbsp;&nbsp;Change&nbsp;Connection&nbsp;Timeout</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;timeout&nbsp;Tetra&nbsp;Mobile&nbsp;LA&nbsp;Registration&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;LA&nbsp;Registration&nbsp;Timeout
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;Disabled
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;10&nbsp;min.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;30&nbsp;min.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;1&nbsp;hour
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;2&nbsp;hour
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;5:&nbsp;4&nbsp;hour
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;6:&nbsp;8&nbsp;hour
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;7:&nbsp;24&nbsp;hour
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S72/T/CONNTIMEOUT/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/CLASS/ss...&nbsp;&nbsp;Change&nbsp;Subscriber&nbsp;Class</strong>
      </div>
      <div>See&nbsp;also&nbsp;Subscriber&nbsp;Class&nbsp;in&nbsp;Profile&nbsp;register</div>
      <div>Default&nbsp;value&nbsp;is&nbsp;all&nbsp;enabled</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class&nbsp;1&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;Class&nbsp;2&nbsp;(+,-)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;Class&nbsp;3&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;Class&nbsp;4&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││┌&nbsp;Class&nbsp;5&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││┌&nbsp;Class&nbsp;6&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││┌&nbsp;Class&nbsp;7&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││┌&nbsp;Class&nbsp;8&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││┌&nbsp;Class&nbsp;9&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││┌&nbsp;Class&nbsp;10&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││┌&nbsp;Class&nbsp;11&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││┌&nbsp;Class&nbsp;12&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││┌&nbsp;Class&nbsp;13&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││┌&nbsp;Class&nbsp;14&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││││││││││││││┌&nbsp;Class&nbsp;15&nbsp;(+,-)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││││││││││││││┌&nbsp;Class&nbsp;16&nbsp;(+,-)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1234567890122456</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴┴</div>
      <div>S72/T/CLASS/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/AIE/s&nbsp;&nbsp;Change&nbsp;Air&nbsp;Interface&nbsp;Encryption&nbsp;activation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Air&nbsp;Interface&nbsp;Encryption</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Deactivate</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/AIE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/MIGRATION/s&nbsp;&nbsp;Change&nbsp;Migration&nbsp;support</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;broadcast&nbsp;bit&nbsp;indicating&nbsp;support&nbsp;for</div>
      <div>registration&nbsp;of&nbsp;mobiles&nbsp;with&nbsp;MCC/MNC&nbsp;different&nbsp;from</div>
      <div>Home&nbsp;MCC/MNC.&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Migration&nbsp;support:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Yes
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;No
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/MIGRATION/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/NCSYNC/c&nbsp;&nbsp;Change&nbsp;Neighbour&nbsp;Cell&nbsp;Synchronization</strong>
      </div>
      <div>
        Controls&nbsp;the&nbsp;reported&nbsp;Neighbour&nbsp;Cell&nbsp;Synchronization&nbsp;for&nbsp;the&nbsp;Node
      </div>
      <div>Used&nbsp;of&nbsp;Mobiles&nbsp;to&nbsp;allow&nbsp;Seamless&nbsp;Handover</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Neighbour&nbsp;Cell&nbsp;Synchronization
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Automatic&nbsp;(GPS&nbsp;sync&nbsp;controlled)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;No</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Yes</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/NCSYNC/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/SRT/nn&nbsp;&nbsp;Change&nbsp;Slow&nbsp;Reselect&nbsp;Threshold&nbsp;above&nbsp;Fast</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Slow&nbsp;Reselect&nbsp;Threshold&nbsp;above&nbsp;Fast&nbsp;(00..15)(0..30dB)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/SRT/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/FRT/nn&nbsp;&nbsp;Change&nbsp;Fast&nbsp;Reselect&nbsp;Threshold</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Fast&nbsp;Reselect&nbsp;Threshold&nbsp;(00..15)(0..30dB)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/FRT/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/SRH/nn&nbsp;&nbsp;Change&nbsp;Slow&nbsp;Reselect&nbsp;Hysteresis</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Slow&nbsp;Reselect&nbsp;Hysteresis&nbsp;(00..15)(0..30dB)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/SRH/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/FRH/nn&nbsp;&nbsp;Change&nbsp;Fast&nbsp;Reselect&nbsp;Hysteresis</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Fast&nbsp;Reselect&nbsp;Hysteresis&nbsp;(00..15)(0..30dB)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S72/T/FRH/__</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/MAXCELLRADIUS/nn&nbsp;&nbsp;Change&nbsp;max.&nbsp;cell&nbsp;radius</strong>
      </div>
      <div>Default&nbsp;is&nbsp;58km.</div>
      <div>
        For&nbsp;AGA&nbsp;(Air-Ground-Air)&nbsp;/&nbsp;A2G&nbsp;(Air&nbsp;to&nbsp;Ground)&nbsp;cells,&nbsp;up&nbsp;to&nbsp;83km&nbsp;is&nbsp;permitted.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;cell&nbsp;radius&nbsp;in&nbsp;km&nbsp;(01..83)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─
      </div>
      <div>S72/T/MAXCELLRADIUS/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/T/NOISE/MODE/n&nbsp;&nbsp;Setup&nbsp;Noise&nbsp;Detection&nbsp;Mode&nbsp;for&nbsp;Interference&nbsp;Detection
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;0:&nbsp;Off
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Warn
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;Warn&nbsp;and&nbsp;restart&nbsp;Carrier
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/T/NOISE/MODE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/T/NOISE/RSSI/-nnn&nbsp;&nbsp;Setup&nbsp;Noise&nbsp;Threshold&nbsp;for&nbsp;Interference&nbsp;Detection
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RSSI&nbsp;Level&nbsp;(-127..-70&nbsp;[dBm])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───
      </div>
      <div>S72/T/NOISE/RSSI/-___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/T/CAR/nn/n/AUTO&nbsp;&nbsp;Auto&nbsp;Setup&nbsp;TETRA&nbsp;Carrier&nbsp;Number&nbsp;and&nbsp;Band&nbsp;Info
        </strong>
      </div>
      <div>When&nbsp;executed&nbsp;the&nbsp;TETRA&nbsp;primitives&nbsp;are&nbsp;calculated&nbsp;from</div>
      <div>the&nbsp;Logical&nbsp;Carrier&nbsp;RX+TX&nbsp;Frequencies.</div>
      <div>
        I&nbsp;no&nbsp;ETSI&nbsp;defined&nbsp;relationship&nbsp;exists,&nbsp;an&nbsp;Error&nbsp;will&nbsp;be&nbsp;displayed
      </div>
      <div>and&nbsp;manual&nbsp;setup&nbsp;is&nbsp;then&nbsp;needed.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>S72/T/CAR/__/_/AUTO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/T/CAR/nn/n/nnnn/nn/n/n/s&nbsp;&nbsp;Change&nbsp;manually&nbsp;TETRA&nbsp;Car.&nbsp;No.&nbsp;and&nbsp;Band&nbsp;Info
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Carrier&nbsp;Number:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;0000..3999:&nbsp;Tetra&nbsp;Carrier&nbsp;Number
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;Band&nbsp;(0..15)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03:&nbsp;300MHZ
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;04:&nbsp;400MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;800MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Offset&nbsp;(0..3)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;0kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;+6.25kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;-6.25kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;+12.5kHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Duplex&nbsp;Spacing&nbsp;(0..7)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=1:&nbsp;0:&nbsp;1.6MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:&nbsp;4.5MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=2:&nbsp;0:&nbsp;10MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=3:&nbsp;0:&nbsp;10MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:&nbsp;36MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3:&nbsp;8MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:&nbsp;18MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=4:&nbsp;0:&nbsp;10MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1:&nbsp;7MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3:&nbsp;8MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:&nbsp;5MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5:&nbsp;9.5MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=5:&nbsp;0:&nbsp;10MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=6:&nbsp;0:&nbsp;10MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:&nbsp;30MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=7:&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:&nbsp;30MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=8:&nbsp;1:&nbsp;45MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3:&nbsp;18MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;FB=9:&nbsp;1:&nbsp;45MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2:&nbsp;0MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3:&nbsp;18MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4:&nbsp;39MHz
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;Reverse&nbsp;Operation
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Normal&nbsp;Operation&nbsp;(BS&nbsp;TX&nbsp;higher&nbsp;than&nbsp;BS&nbsp;RX)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Reverse&nbsp;Operation&nbsp;(BS&nbsp;TX&nbsp;lower&nbsp;than&nbsp;BS&nbsp;RX)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;┴───&nbsp;┴─&nbsp;┴&nbsp;┴&nbsp;┴
      </div>
      <div>S72/T/CAR/__/_/____/__/_/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/NC&nbsp;&nbsp;Display&nbsp;Tetra&nbsp;Neighbour&nbsp;Cell&nbsp;configuration&nbsp;list</strong>
      </div>
      <div>S72/T/NC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/NC/+&nbsp;&nbsp;Activate&nbsp;Tetra&nbsp;Neighbour&nbsp;Cell&nbsp;configuration&nbsp;list</strong>
      </div>
      <div>
        Only&nbsp;active&nbsp;Cells&nbsp;from&nbsp;the&nbsp;list&nbsp;will&nbsp;be&nbsp;included&nbsp;in&nbsp;Neighbour&nbsp;Cell&nbsp;broadcast
      </div>
      <div>This&nbsp;shall&nbsp;be&nbsp;selected&nbsp;for&nbsp;bigger&nbsp;systems</div>
      <div>S72/T/NC/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/NC/-&nbsp;&nbsp;De-activate&nbsp;Tetra&nbsp;Neighbour&nbsp;Cell&nbsp;List</strong>
      </div>
      <div>
        All&nbsp;active&nbsp;Cells&nbsp;will&nbsp;be&nbsp;included&nbsp;in&nbsp;Neighbour&nbsp;Cell&nbsp;broadcast
      </div>
      <div>This&nbsp;is&nbsp;for&nbsp;small&nbsp;systems&nbsp;only!</div>
      <div>S72/T/NC/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/NC/nnn/+&nbsp;&nbsp;Add&nbsp;Tetra&nbsp;Neighbour&nbsp;Cell&nbsp;to&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S72/T/NC/___/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/T/NC/nnn/-&nbsp;&nbsp;Remove&nbsp;Tetra&nbsp;Neighbour&nbsp;Cell&nbsp;from&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S72/T/NC/___/-</div>
      <div />
      <div />
    </div>
    <h2>DMR Cell configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Cell&nbsp;configuration</strong>
      </div>
      <div>S72/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/CAR&nbsp;&nbsp;Display&nbsp;DMR&nbsp;Cell&nbsp;Carriers</strong>
      </div>
      <div>S72/D/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/CNFG/s&nbsp;&nbsp;DMR&nbsp;Cell&nbsp;Activation/Deactivation</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;&nbsp;Activate&nbsp;DMR&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;&nbsp;Deactivate&nbsp;DMR&nbsp;Cell
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/D/CNFG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/D/SYSCODE/n/nnn/nnnn&nbsp;&nbsp;Change&nbsp;DMR&nbsp;System&nbsp;Identity&nbsp;Code:&nbsp;MODEL/NET/SITE
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Network&nbsp;MODEL&nbsp;(0..3)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;0:&nbsp;Tiny&nbsp;&nbsp;(MaxNetId=511,&nbsp;MaxSiteId=0007)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;1:&nbsp;Small&nbsp;(MaxNetId=127,&nbsp;MaxSiteId=0031)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Large&nbsp;(MaxNetId=015,&nbsp;MaxSiteId=0255)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;3:&nbsp;Huge&nbsp;&nbsp;(MaxNetId=003,&nbsp;MaxSiteId=1023)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;NET&nbsp;Identity
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;SITE&nbsp;Identity
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;0000..MaxSite:&nbsp;Specific&nbsp;SITE&nbsp;Identity
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;AUTO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Automatic,&nbsp;SITE&nbsp;Identity&nbsp;equal&nbsp;to&nbsp;NodeNo
      </div>
      <div>S72/D/SYSCODE/_/___/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/D/TSCC/n/n&nbsp;&nbsp;Change&nbsp;number&nbsp;of&nbsp;Secondary&nbsp;TSCC&nbsp;to&nbsp;setup
        </strong>
      </div>
      <div>
        A&nbsp;BS&nbsp;can&nbsp;have&nbsp;up&nbsp;to&nbsp;1&nbsp;Secondary&nbsp;TSCC,&nbsp;using&nbsp;the&nbsp;available&nbsp;Timeslots&nbsp;on&nbsp;the&nbsp;Main&nbsp;Carrier.
      </div>
      <div>
        When&nbsp;the&nbsp;BS&nbsp;changes&nbsp;to&nbsp;Single-Carrier&nbsp;operation&nbsp;(e.g&nbsp;due&nbsp;to&nbsp;Carrier&nbsp;faults),
      </div>
      <div>the&nbsp;number&nbsp;of&nbsp;Secondary&nbsp;TSCC&nbsp;is&nbsp;changed&nbsp;automatically.</div>
      <div>
        This&nbsp;can&nbsp;be&nbsp;used&nbsp;reduce&nbsp;the&nbsp;number&nbsp;of&nbsp;TSCC&nbsp;to&nbsp;free&nbsp;Timeslots&nbsp;for&nbsp;Voice&nbsp;Calls&nbsp;on
      </div>
      <div>the&nbsp;Main&nbsp;Carrier.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Normal&nbsp;Secondary&nbsp;TSCC&nbsp;count&nbsp;(0..1)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Single-Carrier&nbsp;Secondary&nbsp;TSCC&nbsp;count&nbsp;(0..1)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>S72/D/TSCC/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/MASSREG/-&nbsp;Disable&nbsp;MassRegistration</strong>
      </div>
      <div>Disables&nbsp;the&nbsp;periodic&nbsp;DMR&nbsp;MassRegistration</div>
      <div>S72/D/MASSREG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/MASSREG/nn&nbsp;Enable&nbsp;MassRegistration&nbsp;and&nbsp;set&nbsp;interval</strong>
      </div>
      <div>Controls&nbsp;the&nbsp;periodic&nbsp;DMR&nbsp;MassRegistration&nbsp;interval</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;MassRegistration&nbsp;Interval
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;06:&nbsp;20&nbsp;seconds&nbsp;(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;07:&nbsp;30&nbsp;seconds&nbsp;(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;08:&nbsp;2&nbsp;minutes&nbsp;&nbsp;(&nbsp;&nbsp;&nbsp;&nbsp;100&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;09:&nbsp;5&nbsp;minutes&nbsp;&nbsp;(&nbsp;&nbsp;&nbsp;&nbsp;300&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;10:&nbsp;17&nbsp;minutes&nbsp;(&nbsp;&nbsp;1.000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;11:&nbsp;50&nbsp;minutes&nbsp;(&nbsp;&nbsp;3.000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;12:&nbsp;3&nbsp;hours&nbsp;&nbsp;&nbsp;&nbsp;(&nbsp;10.000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;13:&nbsp;8&nbsp;hours&nbsp;&nbsp;&nbsp;&nbsp;(&nbsp;30.000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;14:&nbsp;1&nbsp;day&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(100.000&nbsp;seconds)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;15:&nbsp;2&nbsp;days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(200.000&nbsp;seconds)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/D/MASSREG/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/FALLBACK/s&nbsp;&nbsp;Change&nbsp;Fallback&nbsp;Mode&nbsp;Indication</strong>
      </div>
      <div>Changes&nbsp;Fallback&nbsp;Mode&nbsp;indication&nbsp;to&nbsp;the&nbsp;mobiles</div>
      <div>
        If&nbsp;activated,&nbsp;fallback&nbsp;mode&nbsp;will&nbsp;be&nbsp;indicated&nbsp;status&nbsp;update&nbsp;are&nbsp;not
      </div>
      <div>received&nbsp;from&nbsp;other&nbsp;Nodes.</div>
      <div>
        Fallback&nbsp;mode&nbsp;indicates&nbsp;to&nbsp;mobiles,&nbsp;that&nbsp;full&nbsp;network&nbsp;service&nbsp;are
      </div>
      <div>not&nbsp;available.</div>
      <div>Shall&nbsp;be&nbsp;disabled&nbsp;for&nbsp;single-site&nbsp;systems.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Fallback&nbsp;Mode&nbsp;Indication
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Activate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Disable
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/D/FALLBACK/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/CAR/nn/n/nnnn&nbsp;&nbsp;Change&nbsp;manually&nbsp;DMR&nbsp;Car.&nbsp;No.</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Carrier&nbsp;Number:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;0001..4094:&nbsp;DMR&nbsp;Carrier&nbsp;Number
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;┴───</div>
      <div>S72/D/CAR/__/_/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/NC&nbsp;&nbsp;Display&nbsp;Dmr&nbsp;Neighbour&nbsp;Cell&nbsp;configuration&nbsp;list</strong>
      </div>
      <div>S72/D/NC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/NC/+&nbsp;&nbsp;Activate&nbsp;Dmr&nbsp;Neighbour&nbsp;Cell&nbsp;configuration&nbsp;list</strong>
      </div>
      <div>
        Only&nbsp;active&nbsp;Cells&nbsp;from&nbsp;the&nbsp;list&nbsp;will&nbsp;be&nbsp;included&nbsp;in&nbsp;Neighbour&nbsp;Cell&nbsp;broadcast
      </div>
      <div>This&nbsp;shall&nbsp;be&nbsp;selected&nbsp;for&nbsp;bigger&nbsp;systems</div>
      <div>S72/D/NC/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/NC/-&nbsp;&nbsp;De-activate&nbsp;Dmr&nbsp;Neighbour&nbsp;Cell&nbsp;List</strong>
      </div>
      <div>
        All&nbsp;active&nbsp;Cells&nbsp;will&nbsp;be&nbsp;included&nbsp;in&nbsp;Neighbour&nbsp;Cell&nbsp;broadcast
      </div>
      <div>This&nbsp;is&nbsp;for&nbsp;small&nbsp;systems&nbsp;only!</div>
      <div>S72/D/NC/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/NC/nnn/+&nbsp;&nbsp;Add&nbsp;Dmr&nbsp;Neighbour&nbsp;Cell&nbsp;to&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S72/D/NC/___/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/NC/nnn/-&nbsp;&nbsp;Remove&nbsp;Dmr&nbsp;Neighbour&nbsp;Cell&nbsp;from&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Node&nbsp;Number&nbsp;(001..NodeMax)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S72/D/NC/___/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/AUTO/c/ALLOW/s&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;Allowed&nbsp;Flag</strong>
      </div>
      <div>
        NB:&nbsp;Requires,&nbsp;that&nbsp;AutoCreate&nbsp;is&nbsp;permitted&nbsp;in&nbsp;License&nbsp;Dongle&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;Dmr&nbsp;Mobile</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;AutoCreate&nbsp;Allow&nbsp;Flag:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Allow&nbsp;SSI&nbsp;AutoCreate
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;No&nbsp;SSI&nbsp;AutoCreate&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S72/D/AUTO/_/ALLOW/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/AUTO/c/PROFILE/nnnn&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;Profile&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;Dmr&nbsp;Mobile</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Profile&nbsp;Number&nbsp;(0..9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───
      </div>
      <div>S72/D/AUTO/_/PROFILE/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/D/AUTO/c/USERSSI/s&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;UserNo&nbsp;from&nbsp;SSI&nbsp;flag
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Kind:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;Group</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;R:&nbsp;Dmr&nbsp;Mobile</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UserNo&nbsp;from&nbsp;SSI&nbsp;flag:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Set&nbsp;User&nbsp;Number&nbsp;to&nbsp;SSI&nbsp;value
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Set&nbsp;User&nbsp;Number&nbsp;from&nbsp;Organization&nbsp;range&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>S72/D/AUTO/_/USERSSI/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/AUTO/G/TIMEOUT/nnnnn&nbsp;&nbsp;Change&nbsp;Autocreate&nbsp;Timeout</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Group&nbsp;Call&nbsp;Inactivity&nbsp;timeout&nbsp;(00000..65000sec)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────
      </div>
      <div>S72/D/AUTO/G/TIMEOUT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/D/AUTO/G/DMRGROUP/s&nbsp;&nbsp;Configure&nbsp;group&nbsp;as&nbsp;static&nbsp;in&nbsp;DMR</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Group&nbsp;is&nbsp;dynamic&nbsp;and&nbsp;requires&nbsp;group&nbsp;attachment
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;+:&nbsp;Group&nbsp;is&nbsp;static&nbsp;and&nbsp;does&nbsp;not&nbsp;require&nbsp;group&nbsp;attachment
      </div>
      <div>S72/D/AUTO/G/DMRGROUP/_</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/D/EMERGPREEMPT/s&nbsp;&nbsp;Configure&nbsp;that&nbsp;a&nbsp;new&nbsp;emergency&nbsp;call&nbsp;will&nbsp;preeemt&nbsp;all&nbsp;other&nbsp;ongoing&nbsp;DMR&nbsp;calls
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Do&nbsp;not&nbsp;preempt&nbsp;ongoing&nbsp;calls
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;+:&nbsp;Preempt&nbsp;all&nbsp;ongoing&nbsp;DMR&nbsp;calls&nbsp;if&nbsp;a&nbsp;new&nbsp;emergency&nbsp;call&nbsp;is&nbsp;started
      </div>
      <div>S72/D/EMERGPREEMPT/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/D/NOISE/MODE/n&nbsp;&nbsp;Setup&nbsp;Noise&nbsp;Detection&nbsp;Mode&nbsp;for&nbsp;Interference&nbsp;Detection
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;0:&nbsp;Off
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Warn
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;Warn&nbsp;and&nbsp;restart&nbsp;Carrier
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/D/NOISE/MODE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S72/D/NOISE/RSSI/-nnn&nbsp;&nbsp;Setup&nbsp;Noise&nbsp;Threshold&nbsp;for&nbsp;Interference&nbsp;Detection
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RSSI&nbsp;Level&nbsp;(-127..-70&nbsp;[dBm])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───
      </div>
      <div>S72/D/NOISE/RSSI/-___</div>
      <div />
      <div />
    </div>
    <h2>Analog Cell configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A&nbsp;&nbsp;Display&nbsp;Analog&nbsp;Cell&nbsp;configuration</strong>
      </div>
      <div>S72/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR&nbsp;&nbsp;Display&nbsp;Analog&nbsp;Cell&nbsp;Carriers</strong>
      </div>
      <div>S72/A/CAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CNFG/s&nbsp;&nbsp;Analog&nbsp;Cell&nbsp;Activation/Deactivation</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;&nbsp;Activate&nbsp;Analog&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;&nbsp;Deactivate&nbsp;Analog&nbsp;Cell
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S72/A/CNFG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/GRPUSERNO/nn..n&nbsp;&nbsp;Group&nbsp;UserNo</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;User&nbsp;Number&nbsp;(0..9999999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────
      </div>
      <div>S72/A/CAR/__/_/GRPUSERNO/_______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/RX/RSSI/-nnn.n&nbsp;&nbsp;Setup&nbsp;RX&nbsp;RSSI&nbsp;Squelch</strong>
      </div>
      <div>Nominal:&nbsp;-125.0&nbsp;[dBm]</div>
      <div>NB:&nbsp;Value&nbsp;is&nbsp;RSSI&nbsp;without&nbsp;Branching&nbsp;Filter&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RSSI&nbsp;Level&nbsp;(-130.0..-100.0&nbsp;[dBm])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────
      </div>
      <div>S72/A/CAR/__/_/RX/RSSI/-___._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/RX/RSSI/-&nbsp;&nbsp;Disable&nbsp;RX&nbsp;RSSI&nbsp;Squelch</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>S72/A/CAR/__/_/RX/RSSI/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/RX/NOISE/-nn.n&nbsp;&nbsp;Setup&nbsp;RX&nbsp;Noise&nbsp;Squelch</strong>
      </div>
      <div>Nominal:&nbsp;-06.0&nbsp;[dB]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Noise&nbsp;Squelch&nbsp;Level&nbsp;(-00.1&nbsp;-&gt;&nbsp;-20.0&nbsp;[dB])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────
      </div>
      <div>S72/A/CAR/__/_/RX/NOISE/-__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/RX/NOISE/-&nbsp;&nbsp;Disable&nbsp;RX&nbsp;Noise&nbsp;Squelch</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>S72/A/CAR/__/_/RX/NOISE/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/RX/CTCSS/nnn.n/nnn&nbsp;&nbsp;Setup&nbsp;RX&nbsp;CTCSS&nbsp;Squelch</strong>
      </div>
      <div>Deviation&nbsp;Limit&nbsp;is&nbsp;threshold&nbsp;for&nbsp;no-Tone&nbsp;detection</div>
      <div>Shall&nbsp;be&nbsp;set&nbsp;to&nbsp;e.g.&nbsp;50%&nbsp;of&nbsp;the&nbsp;TX&nbsp;Tone&nbsp;Deviation</div>
      <div>For&nbsp;a&nbsp;10%&nbsp;System&nbsp;Deviation&nbsp;the&nbsp;50%&nbsp;Threshold&nbsp;gives:</div>
      <div>&nbsp;&nbsp;PM&nbsp;25.00kHz:&nbsp;&nbsp;+/-250Hz</div>
      <div>&nbsp;&nbsp;PM&nbsp;20.00kHz:&nbsp;&nbsp;+/-200Hz</div>
      <div>&nbsp;&nbsp;PM&nbsp;12.50kHz:&nbsp;&nbsp;+/-125Hz</div>
      <div>&nbsp;&nbsp;PM&nbsp;&nbsp;6.25kHz:&nbsp;&nbsp;+/-&nbsp;62Hz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;(050..260&nbsp;[Hz])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Deviation&nbsp;Limit&nbsp;(001..999&nbsp;[Hz])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────&nbsp;┴──
      </div>
      <div>S72/A/CAR/__/_/RX/CTCSS/___._/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/RX/CTCSS/-&nbsp;&nbsp;Disable&nbsp;RX&nbsp;CTCSS&nbsp;Squelch</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>S72/A/CAR/__/_/RX/CTCSS/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/TX/CTCSS/nnn.n/nnn&nbsp;&nbsp;Setup&nbsp;TX&nbsp;CTCSS&nbsp;Tone</strong>
      </div>
      <div>TX&nbsp;Tone&nbsp;Deviation&nbsp;may&nbsp;be&nbsp;10%&nbsp;of&nbsp;max.&nbsp;System&nbsp;Deviation:</div>
      <div>&nbsp;&nbsp;PM&nbsp;25.00kHz:&nbsp;&nbsp;+/-500Hz</div>
      <div>&nbsp;&nbsp;PM&nbsp;20.00kHz:&nbsp;&nbsp;+/-400Hz</div>
      <div>&nbsp;&nbsp;PM&nbsp;12.50kHz:&nbsp;&nbsp;+/-250Hz</div>
      <div>&nbsp;&nbsp;PM&nbsp;&nbsp;6.25kHz:&nbsp;&nbsp;+/-125Hz</div>
      <div>NB:</div>
      <div>
        &nbsp;&nbsp;The&nbsp;TX&nbsp;Tone&nbsp;Deviation&nbsp;automatically&nbsp;reduces&nbsp;the&nbsp;maximum&nbsp;TX&nbsp;Voice&nbsp;Deviation.
      </div>
      <div>&nbsp;&nbsp;The&nbsp;nominal&nbsp;TX&nbsp;Voice&nbsp;Deviation&nbsp;is&nbsp;not&nbsp;changed.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;(050..260&nbsp;[Hz])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Deviation&nbsp;(001..999&nbsp;[Hz])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────&nbsp;┴──
      </div>
      <div>S72/A/CAR/__/_/TX/CTCSS/___._/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S72/A/CAR/nn/n/TX/CTCSS/-&nbsp;&nbsp;Disable&nbsp;TX&nbsp;CTCSS&nbsp;Tone</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Logical&nbsp;Carrier&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>S72/A/CAR/__/_/TX/CTCSS/-</div>
      <div />
      <div />
    </div>
    <h2>Voice Gateway configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73&nbsp;&nbsp;Display&nbsp;Voice&nbsp;Gateway&nbsp;configuration</strong>
      </div>
      <div>S73</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/CNFG/+&nbsp;&nbsp;Activate&nbsp;Voice&nbsp;Gateway</strong>
      </div>
      <div>Only&nbsp;possible&nbsp;if&nbsp;allowed&nbsp;of&nbsp;License&nbsp;Dongle</div>
      <div>S73/CNFG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/CNFG/-&nbsp;&nbsp;Deactivate&nbsp;Voice&nbsp;Gateway</strong>
      </div>
      <div>S73/CNFG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/ORG/ccc&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Organization&nbsp;Number</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Calls&nbsp;allowed&nbsp;to/from&nbsp;all&nbsp;Home&nbsp;Organizations
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;001..999:&nbsp;Calls&nbsp;restricted&nbsp;to/from&nbsp;this&nbsp;Home&nbsp;Organization
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+++&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Activates&nbsp;use&nbsp;of&nbsp;Organization&nbsp;Number&nbsp;Prefix
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Outgoing:&nbsp;3-digit&nbsp;Prefix&nbsp;is&nbsp;added&nbsp;to&nbsp;B-number.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Call&nbsp;from&nbsp;all&nbsp;Organizations&nbsp;are&nbsp;allowed.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Incoming:&nbsp;3-digit&nbsp;Prefix&nbsp;shall&nbsp;be&nbsp;present&nbsp;on&nbsp;B-number.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Calls&nbsp;restricted&nbsp;to&nbsp;Prefix&nbsp;Home&nbsp;Organization&nbsp;(All&nbsp;for&nbsp;000)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Incoming&nbsp;Prefix&nbsp;is&nbsp;deleted.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S73/ORG/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S73/CALLMAX/nnn&nbsp;&nbsp;Change&nbsp;max.&nbsp;number&nbsp;of&nbsp;Voice&nbsp;Gateway&nbsp;calls
        </strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;reduce&nbsp;the&nbsp;maximum&nbsp;number&nbsp;of&nbsp;simultaneous&nbsp;Voice&nbsp;Gateway
      </div>
      <div>calls&nbsp;to&nbsp;less&nbsp;than&nbsp;defined&nbsp;in&nbsp;the&nbsp;License&nbsp;Dongle.</div>
      <div>The&nbsp;value&nbsp;is&nbsp;limited&nbsp;to&nbsp;the&nbsp;License&nbsp;Dongle&nbsp;value.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;simultaneous&nbsp;calls&nbsp;(001..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S73/CALLMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/PRIORITY/nn&nbsp;&nbsp;Change&nbsp;PABX-&gt;Tetra&nbsp;Call&nbsp;Priority</strong>
      </div>
      <div>Priority&nbsp;of&nbsp;the&nbsp;Tetra&nbsp;call&nbsp;at&nbsp;call&nbsp;setup&nbsp;from&nbsp;PSTN</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Call&nbsp;Priority&nbsp;(00..15)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>S73/PRIORITY/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/REG/s&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Registration&nbsp;Selection</strong>
      </div>
      <div>
        Activates&nbsp;the&nbsp;use&nbsp;of&nbsp;SIP&nbsp;registration&nbsp;to&nbsp;the&nbsp;remote&nbsp;PABX/Gateway
      </div>
      <div>It&nbsp;is&nbsp;recommended&nbsp;to&nbsp;use&nbsp;registration</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Use&nbsp;SIP&nbsp;registration&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Do&nbsp;not&nbsp;use&nbsp;SIP&nbsp;registration
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S73/REG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/NAME/cc..c&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Registration&nbsp;Name</strong>
      </div>
      <div>Name&nbsp;to&nbsp;use&nbsp;for&nbsp;Registration&nbsp;on&nbsp;remote&nbsp;PABX/Gateway</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;(0..64&nbsp;characters)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────────────────────────────────────
      </div>
      <div>S73/NAME/________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/PASSWORD/cc..c&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Registration&nbsp;Name</strong>
      </div>
      <div>Name&nbsp;to&nbsp;use&nbsp;for&nbsp;Registration&nbsp;on&nbsp;remote&nbsp;PABX/Gateway</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Password&nbsp;(0..32&nbsp;characters)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────
      </div>
      <div>S73/PASSWORD/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/IPADDR/...&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Remote&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>
        IP&nbsp;Address&nbsp;of&nbsp;the&nbsp;PABX&nbsp;or&nbsp;SIP&nbsp;proxy&nbsp;conneced&nbsp;to&nbsp;the&nbsp;Node&nbsp;Controller
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>S73/IPADDR/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/PORT/LOC/nnnnn&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Local&nbsp;IP&nbsp;Port</strong>
      </div>
      <div>Defalut&nbsp;value&nbsp;is&nbsp;05060</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Local&nbsp;IP&nbsp;Port&nbsp;(00001..65535)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S73/PORT/LOC/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/PORT/REM/nnnnn&nbsp;&nbsp;Change&nbsp;Voice&nbsp;Gateway&nbsp;Remote&nbsp;IP&nbsp;Port</strong>
      </div>
      <div>Defalut&nbsp;value&nbsp;is&nbsp;05060</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Remote&nbsp;IP&nbsp;Port&nbsp;(00001..65535)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S73/PORT/REM/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S73/PORT/RTP/nnnnn&nbsp;Change&nbsp;base&nbsp;port</strong>
      </div>
      <div>Defines&nbsp;the&nbsp;start&nbsp;port&nbsp;number&nbsp;for&nbsp;the&nbsp;RTP&nbsp;connections</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Base&nbsp;port&nbsp;00000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Use&nbsp;random&nbsp;ports&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;00002..65534:&nbsp;Even&nbsp;start&nbsp;port&nbsp;number&nbsp;of&nbsp;the&nbsp;block&nbsp;of&nbsp;ports
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S73/PORT/RTP/_____</div>
      <div />
      <div />
    </div>
    <h2>Packet Data Gateway configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S74&nbsp;&nbsp;Display&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;configuration</strong>
      </div>
      <div>S74</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S74/CNFG/+&nbsp;&nbsp;Activate&nbsp;Packet&nbsp;Data&nbsp;Gateway</strong>
      </div>
      <div>Only&nbsp;possible&nbsp;if&nbsp;allowed&nbsp;of&nbsp;License&nbsp;Dongle</div>
      <div>S74/CNFG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S74/CNFG/-&nbsp;&nbsp;Deactivate&nbsp;Packet&nbsp;Data&nbsp;Gateway</strong>
      </div>
      <div>S74/CNFG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S74/RIP/s&nbsp;&nbsp;Change&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;RIP&nbsp;selection</strong>
      </div>
      <div>
        Controls&nbsp;output&nbsp;of&nbsp;Routing&nbsp;Information&nbsp;Protocol&nbsp;ver.&nbsp;2&nbsp;according
      </div>
      <div>to&nbsp;RFC2453.</div>
      <div>
        If&nbsp;activated,&nbsp;RIP&nbsp;message&nbsp;with&nbsp;IP&nbsp;Address&nbsp;and&nbsp;Mask&nbsp;will&nbsp;be
      </div>
      <div>
        send&nbsp;every&nbsp;30&nbsp;second&nbsp;to&nbsp;multicast&nbsp;address&nbsp;224.0.0.9&nbsp;UDP&nbsp;port&nbsp;520.
      </div>
      <div>
        To&nbsp;be&nbsp;received&nbsp;of&nbsp;the&nbsp;default&nbsp;gateway&nbsp;for&nbsp;the&nbsp;IP&nbsp;segment,&nbsp;where
      </div>
      <div>RIPv2&nbsp;shall&nbsp;be&nbsp;activated.</div>
      <div>
        When&nbsp;RIP&nbsp;is&nbsp;activated,&nbsp;a&nbsp;static&nbsp;route&nbsp;in&nbsp;the&nbsp;default&nbsp;gateway
      </div>
      <div>pointing&nbsp;to&nbsp;the&nbsp;Packet&nbsp;Data&nbsp;Gateway&nbsp;is&nbsp;no&nbsp;longer&nbsp;needed.</div>
      <div>
        Note,&nbsp;that&nbsp;RIP&nbsp;is&nbsp;needed&nbsp;for&nbsp;switching&nbsp;between&nbsp;redundant&nbsp;Node&nbsp;Controllers.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Activate&nbsp;RIPv2&nbsp;output&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Disable&nbsp;RIPv2&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S74/RIP/_</div>
      <div />
      <div />
    </div>
    <h2>Application Gateway configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S75&nbsp;&nbsp;Display&nbsp;Application&nbsp;Gateway&nbsp;configuration</strong>
      </div>
      <div>S75</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S75/CNFG/+&nbsp;&nbsp;Activate&nbsp;Application&nbsp;Gateway</strong>
      </div>
      <div>Only&nbsp;possible&nbsp;if&nbsp;allowed&nbsp;of&nbsp;License&nbsp;Dongle</div>
      <div>S75/CNFG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S75/CNFG/-&nbsp;&nbsp;Deactivate&nbsp;Application&nbsp;Gateway</strong>
      </div>
      <div>S75/CNFG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S75/CONNMAX/nnn&nbsp;&nbsp;Change&nbsp;max.&nbsp;Application&nbsp;Gateway&nbsp;connections</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;reduce&nbsp;the&nbsp;maximum&nbsp;number&nbsp;of&nbsp;simultaneous&nbsp;connected&nbsp;Applications&nbsp;to&nbsp;a
      </div>
      <div>lower&nbsp;value&nbsp;as&nbsp;the&nbsp;limit&nbsp;in&nbsp;the&nbsp;License&nbsp;Dongle.</div>
      <div>
        The&nbsp;value&nbsp;is&nbsp;automatically&nbsp;limited&nbsp;to&nbsp;the&nbsp;lowest&nbsp;of&nbsp;the&nbsp;compiled
      </div>
      <div>maximum&nbsp;value&nbsp;and&nbsp;the&nbsp;License&nbsp;Dongle&nbsp;value.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;Application&nbsp;Gateway&nbsp;connections&nbsp;(001..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S75/CONNMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S75/STREAMMAX/nnn&nbsp;&nbsp;Change&nbsp;max.&nbsp;Application&nbsp;Gateway&nbsp;Circuit&nbsp;Mode&nbsp;Streams
        </strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;reduce&nbsp;the&nbsp;maximum&nbsp;total&nbsp;number&nbsp;of&nbsp;simultaneous&nbsp;Circuit&nbsp;Mode
      </div>
      <div>
        streams&nbsp;for&nbsp;the&nbsp;Application&nbsp;Gateway&nbsp;to&nbsp;lower&nbsp;value&nbsp;than&nbsp;the&nbsp;compiled&nbsp;maximum.
      </div>
      <div>The&nbsp;value&nbsp;is&nbsp;automatically&nbsp;limited&nbsp;to&nbsp;the&nbsp;compiled&nbsp;maximum.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;Application&nbsp;Gateway&nbsp;Circuit&nbsp;Mode&nbsp;Streams&nbsp;(001..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S75/STREAMMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S75/CONNTIMEOUT/nnn&nbsp;&nbsp;Connection&nbsp;Timeout</strong>
      </div>
      <div>Timeout&nbsp;for&nbsp;the&nbsp;Application&nbsp;connection.</div>
      <div>Used&nbsp;to&nbsp;timeout&nbsp;connection&nbsp;at&nbsp;missing&nbsp;Application&nbsp;response.</div>
      <div>Default&nbsp;value&nbsp;is&nbsp;2&nbsp;minutes.&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Connection&nbsp;Timeout&nbsp;(001..255&nbsp;[minutes])
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S75/CONNTIMEOUT/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S75/DISCRLIST/s&nbsp;&nbsp;Change&nbsp;Application&nbsp;Gateway&nbsp;Discreet&nbsp;Listening</strong>
      </div>
      <div>Controls&nbsp;Discreet&nbsp;Listening&nbsp;activation&nbsp;in&nbsp;Application&nbsp;Gateway</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Activate&nbsp;Discreet&nbsp;Listening&nbsp;support
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Disable&nbsp;Discreet&nbsp;Listening&nbsp;support&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>S75/DISCRLIST/_</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Terminal Gateway configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S76&nbsp;&nbsp;Display&nbsp;Terminal&nbsp;Gateway&nbsp;configuration</strong>
      </div>
      <div>S76</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S76/CNFG/+&nbsp;&nbsp;Activate&nbsp;Terminal&nbsp;Gateway</strong>
      </div>
      <div>Only&nbsp;possible&nbsp;if&nbsp;allowed&nbsp;of&nbsp;License&nbsp;Dongle</div>
      <div>S76/CNFG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S76/CNFG/-&nbsp;&nbsp;Deactivate&nbsp;Terminal&nbsp;Gateway</strong>
      </div>
      <div>S76/CNFG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S76/CONNMAX/nnn&nbsp;&nbsp;Change&nbsp;max.&nbsp;Terminal&nbsp;Gateway&nbsp;connections</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;reduce&nbsp;the&nbsp;maximum&nbsp;number&nbsp;of&nbsp;simultaneous&nbsp;connected&nbsp;Terminals&nbsp;to&nbsp;a
      </div>
      <div>lower&nbsp;value&nbsp;as&nbsp;the&nbsp;limit&nbsp;in&nbsp;the&nbsp;License&nbsp;Dongle.</div>
      <div>
        The&nbsp;value&nbsp;is&nbsp;automatically&nbsp;limited&nbsp;to&nbsp;the&nbsp;lowest&nbsp;of&nbsp;the&nbsp;compiled
      </div>
      <div>maximum&nbsp;value&nbsp;and&nbsp;the&nbsp;License&nbsp;Dongle&nbsp;value.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;Terminal&nbsp;Gateway&nbsp;connections&nbsp;(001..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S76/CONNMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          S76/STREAMMAX/nnn&nbsp;&nbsp;Change&nbsp;max.&nbsp;Terminal&nbsp;Gateway&nbsp;Circuit&nbsp;Mode&nbsp;Streams
        </strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;reduce&nbsp;the&nbsp;maximum&nbsp;total&nbsp;number&nbsp;of&nbsp;simultaneous&nbsp;Circuit&nbsp;Mode
      </div>
      <div>
        streams&nbsp;for&nbsp;the&nbsp;Terminal&nbsp;Gateway&nbsp;to&nbsp;lower&nbsp;value&nbsp;than&nbsp;the&nbsp;compiled&nbsp;maximum.
      </div>
      <div>The&nbsp;value&nbsp;is&nbsp;automatically&nbsp;limited&nbsp;to&nbsp;the&nbsp;compiled&nbsp;maximum.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Max.&nbsp;Terminal&nbsp;Gateway&nbsp;Circuit&nbsp;Mode&nbsp;Streams&nbsp;(001..999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S76/STREAMMAX/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S76/CONNTIMEOUT/nnn&nbsp;&nbsp;Connection&nbsp;Timeout</strong>
      </div>
      <div>Timeout&nbsp;for&nbsp;the&nbsp;Terminal&nbsp;connection.</div>
      <div>Used&nbsp;to&nbsp;timeout&nbsp;connection&nbsp;at&nbsp;missing&nbsp;Terminal&nbsp;response.</div>
      <div>Default&nbsp;value&nbsp;is&nbsp;2&nbsp;minutes.&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Connection&nbsp;Timeout&nbsp;(001..255&nbsp;[minutes])
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>S76/CONNTIMEOUT/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S76/PORT/nnnnn&nbsp;Change&nbsp;base&nbsp;port</strong>
      </div>
      <div>Defines&nbsp;the&nbsp;start&nbsp;port&nbsp;number&nbsp;for&nbsp;the&nbsp;external&nbsp;connections</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Base&nbsp;port&nbsp;00000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;Use&nbsp;random&nbsp;ports&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;00002..65534:&nbsp;Even&nbsp;start&nbsp;port&nbsp;number&nbsp;of&nbsp;the&nbsp;block&nbsp;of&nbsp;ports
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>S76/PORT/_____</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Restart Commands</h2>
    <br />
    <div>S99&nbsp;&nbsp;RESTART&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S99&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>S99</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S99/STOP&nbsp;&nbsp;Stop&nbsp;Node&nbsp;Service</strong>
      </div>
      <div>Makes&nbsp;a&nbsp;normal&nbsp;shutdown&nbsp;of&nbsp;the&nbsp;service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>NB:&nbsp;This&nbsp;requires&nbsp;a&nbsp;Remote&nbsp;Desktop&nbsp;connection&nbsp;to&nbsp;get&nbsp;the</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;Node&nbsp;Service&nbsp;restarted!</div>
      <div>S99/STOP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>S99/RESTART&nbsp;&nbsp;Restart&nbsp;Node&nbsp;service</strong>
      </div>
      <div>Makes&nbsp;a&nbsp;restart&nbsp;of&nbsp;the&nbsp;Node&nbsp;Service</div>
      <div>NB:&nbsp;O&amp;M&nbsp;connection&nbsp;will&nbsp;be&nbsp;lost!!!</div>
      <div>S99/RESTART</div>
      <div />
    </div>
    <br />
  </>
);

export default memo(NodePage) as typeof NodePage;
