import React from 'react';
import styled from 'styled-components';
import { Radio as AntRadio } from 'antd';

export const RadioGroup = styled(AntRadio.Group)``;

export const Radio = styled(AntRadio)`
  &.ant-radio-wrapper {
    display: inline-flex;
    align-items: center;

    .ant-radio {
      margin: 0.25rem 0 0 0;
      top: 0;

      &,
      .ant-radio-input,
      .ant-radio-inner {
        height: 1.75rem;
        width: 1.75rem;
      }

      .ant-radio-inner {
        width: 1.75rem;
        background-color: #fff;

        &:after {
          width: 0.65rem;
          height: 0.65rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
        }
      }
      .ant-radio-input:hover + .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: #7fc4fd;
      }
    }

    &:hover,
    &:focus {
    }

    &.ant-radio-wrapper-checked {
      .ant-radio {
        .ant-radio-inner {
          background-color: #2699fb;
        }
      }
    }
    p {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export default Radio;
