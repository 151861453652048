import React, { FC, memo } from 'react';

const BSCPage: FC = () => (
  <>
    <h1>BSC Base Station Controller ver. 7.81</h1>
    <br />
    <h2>Functional Description</h2>
    <br />
    <div>BSC421&nbsp;LED&nbsp;description</div>
    <br />
    <div>LED's&nbsp;on&nbsp;top&nbsp;of&nbsp;BSC421&nbsp;cover:</div>
    <br />
    <div>LED&nbsp;&nbsp;Color&nbsp;&nbsp;&nbsp;Marking&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indication</div>
    <div>---&nbsp;&nbsp;------&nbsp;&nbsp;-----------&nbsp;&nbsp;------------------------------</div>
    <div>D1&nbsp;&nbsp;&nbsp;Yellow&nbsp;&nbsp;BSC&nbsp;POWER&nbsp;&nbsp;&nbsp;&nbsp;+5V&nbsp;BSC421&nbsp;present</div>
    <div>
      D2&nbsp;&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;BSC&nbsp;ACTIVE&nbsp;&nbsp;&nbsp;BSC&nbsp;is&nbsp;active&nbsp;(not&nbsp;standby)
    </div>
    <div>
      D3&nbsp;&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BSC&nbsp;NETWORK&nbsp;&nbsp;Network&nbsp;Alarm&nbsp;(fall-back&nbsp;mode)
    </div>
    <div>
      D4&nbsp;&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BSC&nbsp;BSS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BSS&nbsp;Software&nbsp;Alarm
    </div>
    <div>
      D5&nbsp;&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BSC&nbsp;BSC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BSC&nbsp;Software&nbsp;Alarm
    </div>
    <div>
      D6&nbsp;&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BSC&nbsp;ALARM&nbsp;&nbsp;&nbsp;&nbsp;BSC421&nbsp;Alarm
    </div>
    <div>
      D7&nbsp;&nbsp;&nbsp;Yellow&nbsp;&nbsp;PS&nbsp;LINK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PS421&nbsp;Communication&nbsp;Link&nbsp;OK
    </div>
    <div>
      D8&nbsp;&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;PS&nbsp;MAINS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PS421&nbsp;Mains&nbsp;Voltage&nbsp;OK
    </div>
    <div>D9&nbsp;&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;PS&nbsp;CHARGE&nbsp;&nbsp;&nbsp;&nbsp;Battery&nbsp;Charge</div>
    <div>
      D10&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PS&nbsp;DISCH.&nbsp;&nbsp;&nbsp;&nbsp;Battery&nbsp;Discharge
    </div>
    <div>
      D11&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PS&nbsp;ALARM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PS421&nbsp;Alarm
    </div>
    <div>D12&nbsp;&nbsp;Yellow&nbsp;&nbsp;TR1&nbsp;LINK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR1&nbsp;Link&nbsp;OK</div>
    <div>
      D13&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;TR1&nbsp;ACTIVE&nbsp;&nbsp;&nbsp;TR1&nbsp;Active&nbsp;(Tetra&nbsp;Mode)
    </div>
    <div>D14&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR1&nbsp;ALARM&nbsp;&nbsp;&nbsp;&nbsp;TR1&nbsp;Alarm</div>
    <div>D15&nbsp;&nbsp;Yellow&nbsp;&nbsp;TR2&nbsp;LINK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR2&nbsp;Link&nbsp;OK</div>
    <div>
      D16&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;TR2&nbsp;ACTIVE&nbsp;&nbsp;&nbsp;TR2&nbsp;Active&nbsp;(Tetra&nbsp;Mode)
    </div>
    <div>D17&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR2&nbsp;ALARM&nbsp;&nbsp;&nbsp;&nbsp;TR2&nbsp;Alarm</div>
    <div>D18&nbsp;&nbsp;Yellow&nbsp;&nbsp;TR3&nbsp;LINK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR3&nbsp;Link&nbsp;OK</div>
    <div>
      D19&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;TR3&nbsp;ACTIVE&nbsp;&nbsp;&nbsp;TR3&nbsp;Active&nbsp;(Tetra&nbsp;Mode)
    </div>
    <div>D20&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR3&nbsp;ALARM&nbsp;&nbsp;&nbsp;&nbsp;TR3&nbsp;Alarm</div>
    <div>D21&nbsp;&nbsp;Yellow&nbsp;&nbsp;TR4&nbsp;LINK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR4&nbsp;Link&nbsp;OK</div>
    <div>
      D22&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;TR4&nbsp;ACTIVE&nbsp;&nbsp;&nbsp;TR4&nbsp;Active&nbsp;(Tetra&nbsp;Mode)
    </div>
    <div>D23&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR4&nbsp;ALARM&nbsp;&nbsp;&nbsp;&nbsp;TR4&nbsp;Alarm</div>
    <br />
    <br />
    <div>BSC421&nbsp;J31&nbsp;+&nbsp;J32&nbsp;connectors:</div>
    <br />
    <div>LED&nbsp;&nbsp;Color&nbsp;&nbsp;&nbsp;Marking&nbsp;&nbsp;Indication</div>
    <div>---&nbsp;&nbsp;------&nbsp;&nbsp;-------&nbsp;&nbsp;--------------------------</div>
    <div>
      J31&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;TR1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR1&nbsp;Ethernet&nbsp;Link/Activity
    </div>
    <div>
      J31&nbsp;&nbsp;Yellow&nbsp;&nbsp;TR2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TR2&nbsp;Ethernet&nbsp;Link/Activity
    </div>
    <div>
      J32&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;LAN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LAN&nbsp;Ethernet&nbsp;Link/Activity
    </div>
    <div>
      J32&nbsp;&nbsp;Yellow&nbsp;&nbsp;WAN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WAN&nbsp;Ethernet&nbsp;Link/Activity
    </div>
    <br />
    <br />
    <div>J52&nbsp;connector:</div>
    <br />
    <div>LED&nbsp;&nbsp;Color&nbsp;&nbsp;&nbsp;Indication</div>
    <div>---&nbsp;&nbsp;------&nbsp;&nbsp;----------------------------</div>
    <div>J52&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;LAN&nbsp;Ethernet&nbsp;Link&nbsp;Connection</div>
    <div>J52&nbsp;&nbsp;Yellow&nbsp;&nbsp;LAN&nbsp;Ethernet&nbsp;100MBit</div>
    <br />
    <div>*&nbsp;&nbsp;LED&nbsp;description</div>
    <br />
    <br />
    <h2>General commands</h2>
    <br />
    <div>F00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F00&nbsp;&nbsp;Display&nbsp;Software&nbsp;Version</strong>
      </div>
      <div>F00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F00/BSC&nbsp;&nbsp;Display&nbsp;OM&nbsp;Red.&nbsp;BSC&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>F00/BSC</div>
      <div />
      <div />
    </div>
    <h2>BSC Activation Control</h2>
    <br />
    <div>F02&nbsp;&nbsp;BSC&nbsp;ACTIVATION&nbsp;CONTROL</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F02&nbsp;&nbsp;Display&nbsp;BSC&nbsp;Activation&nbsp;Status</strong>
      </div>
      <div>F02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F02/ACTIVATE&nbsp;&nbsp;BSC&nbsp;Activation&nbsp;Request</strong>
      </div>
      <div>Sends&nbsp;Activation&nbsp;Request&nbsp;to&nbsp;other&nbsp;BSC</div>
      <div>F02/ACTIVATE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F02/STANDBY/s&nbsp;&nbsp;Change&nbsp;Forced&nbsp;BSC&nbsp;Standby</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;+:&nbsp;Forced&nbsp;Standby
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Normal&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F02/STANDBY/_</div>
      <div />
      <div />
    </div>
    <h2>Alarms</h2>
    <br />
    <div>F03&nbsp;&nbsp;ALARMS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F03&nbsp;&nbsp;Display&nbsp;BS&nbsp;Alarm&nbsp;state</strong>
      </div>
      <div>F03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F03/C&nbsp;&nbsp;Clear&nbsp;all&nbsp;BS&nbsp;alarms</strong>
      </div>
      <div>F03/C</div>
      <div />
      <div />
    </div>
    <h2>Radio Cell Status</h2>
    <br />
    <div>F13&nbsp;RADIO&nbsp;CELL&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F13&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;Status</strong>
      </div>
      <div>F13</div>
      <div />
      <div />
    </div>
    <h2>Power Supply Status</h2>
    <br />
    <div>F14&nbsp;POWER&nbsp;SUPPLY&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F14&nbsp;&nbsp;Display&nbsp;Power&nbsp;Supply&nbsp;Status</strong>
      </div>
      <div>F14</div>
      <div />
      <div />
    </div>
    <h2>TR Status</h2>
    <br />
    <div>F15&nbsp;TR&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F15&nbsp;&nbsp;Display&nbsp;TR&nbsp;Status</strong>
      </div>
      <div>F15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F15/c&nbsp;&nbsp;Display&nbsp;TR&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;F:&nbsp;Frequencies/Power</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;P:&nbsp;GPS&nbsp;Positions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;V:&nbsp;Versions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;L:&nbsp;Frequency&nbsp;and&nbsp;Power&nbsp;Limits</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;E:&nbsp;External&nbsp;Alarms</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F15/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F15/nn/A&nbsp;&nbsp;Display&nbsp;TR&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F15/__/A</div>
      <div />
      <div />
    </div>
    <h2>PS411 Status</h2>
    <br />
    <div>F16&nbsp;PS&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F16&nbsp;&nbsp;Display&nbsp;PS&nbsp;Status</strong>
      </div>
      <div>F16</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F16/nn&nbsp;&nbsp;Display&nbsp;PS&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..47)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F16/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F16/AL&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>F16/AL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F16/REC&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Rectifier&nbsp;status</strong>
      </div>
      <div>F16/REC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F16/OUT&nbsp;&nbsp;Display&nbsp;PS411&nbsp;+14V/+26V&nbsp;status</strong>
      </div>
      <div>F16/OUT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F16/LED&nbsp;&nbsp;Display&nbsp;PS411&nbsp;LED&nbsp;status</strong>
      </div>
      <div>F16/LED</div>
      <div />
      <div />
    </div>
    <h2>BSS Message test queue</h2>
    <br />
    <div>F63&nbsp;&nbsp;BSS&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63&nbsp;&nbsp;Display&nbsp;BSS&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>F63</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63/N&nbsp;&nbsp;Display&nbsp;next&nbsp;BSS&nbsp;message</strong>
      </div>
      <div>F63/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;BSS&nbsp;message&nbsp;extended</strong>
      </div>
      <div>F63/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63/S&nbsp;&nbsp;Set&nbsp;BSS&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>F63/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63/C&nbsp;&nbsp;Clear&nbsp;BSS&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>F63/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63/s&nbsp;&nbsp;BSS&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;BSS&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;BSS&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F63/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F63/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;BSS</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>F63/__/______________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>UDP Message test queue</h2>
    <br />
    <div>F64&nbsp;&nbsp;UDP&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64&nbsp;&nbsp;Display&nbsp;UDP&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>F64</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64/N&nbsp;&nbsp;Display&nbsp;next&nbsp;UDP&nbsp;message</strong>
      </div>
      <div>F64/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;UDP&nbsp;message&nbsp;extended</strong>
      </div>
      <div>F64/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64/S&nbsp;&nbsp;Set&nbsp;UDP&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>F64/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64/C&nbsp;&nbsp;Clear&nbsp;UDP&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>F64/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64/s&nbsp;&nbsp;UDP&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;UDP&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;UDP&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F64/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F64/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;Redundant&nbsp;BSC</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>F64/__/______________________________________________________________________</div>
      <div />
      <div />
      <div />
    </div>
    <h2>TR Message test queue</h2>
    <br />
    <div>F65&nbsp;&nbsp;TR&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65&nbsp;&nbsp;Display&nbsp;TR&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>F65</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/TCP&nbsp;&nbsp;Display&nbsp;TR&nbsp;TCP&nbsp;Connections</strong>
      </div>
      <div>F65/TCP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/N&nbsp;&nbsp;Display&nbsp;next&nbsp;TR&nbsp;message</strong>
      </div>
      <div>F65/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;TR&nbsp;message&nbsp;extended</strong>
      </div>
      <div>F65/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/S&nbsp;&nbsp;Set&nbsp;TR&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>F65/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/C&nbsp;&nbsp;Clear&nbsp;TR&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>F65/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/s&nbsp;&nbsp;TR&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;TR&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;TR&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F65/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F65/nn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;TR</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>F65/__/__/______________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>BSC configuration</h2>
    <br />
    <div>F70&nbsp;BSC&nbsp;CONFIGURATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F70&nbsp;&nbsp;Display&nbsp;last&nbsp;File&nbsp;Save&nbsp;result</strong>
      </div>
      <div>F70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F70/SAVE&nbsp;&nbsp;Save&nbsp;BSC&nbsp;Configuration</strong>
      </div>
      <div>Note&nbsp;that&nbsp;BSC&nbsp;Configuration&nbsp;changes&nbsp;are&nbsp;saved&nbsp;automatically</div>
      <div>F70/SAVE</div>
      <div />
      <div />
    </div>
    <h2>System configurations</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71&nbsp;&nbsp;Display&nbsp;Common&nbsp;Configurations</strong>
      </div>
      <div>F71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/CNFG/+&nbsp;&nbsp;Activate&nbsp;BSC</strong>
      </div>
      <div>F71/CNFG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/CNFG/-&nbsp;&nbsp;Deactivate&nbsp;BSC</strong>
      </div>
      <div>F71/CNFG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/BSCNO/n&nbsp;&nbsp;Change&nbsp;BSC&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;Number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;BSC1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;BSC2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F71/BSCNO/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/REDBSC/s&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;BSC&nbsp;Selection</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Select&nbsp;Redundant&nbsp;BSC
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Deselect&nbsp;Redundant&nbsp;BSC
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F71/REDBSC/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/REDBSC/...&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;BSC&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>F71/REDBSC/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/REDBSC/nn&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;BSC&nbsp;Timeout</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Redundant&nbsp;BSC&nbsp;Timeout
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03..15s</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F71/REDBSC/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/BSS/...&nbsp;&nbsp;Change&nbsp;BSS&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;in&nbsp;OEM&nbsp;Base&nbsp;Stations</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(000..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>F71/BSS/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/BSS/nnnnn&nbsp;&nbsp;Change&nbsp;BSS&nbsp;Remote&nbsp;Port</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;in&nbsp;OEM&nbsp;Base&nbsp;Stations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Port&nbsp;(Default:&nbsp;42395)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>F71/BSS/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F71/BSS/c&nbsp;&nbsp;Change&nbsp;BSS&nbsp;Message&nbsp;Protocol</strong>
      </div>
      <div>TCP&nbsp;Server&nbsp;is&nbsp;the&nbsp;default&nbsp;recommended&nbsp;protocol</div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;in&nbsp;OEM&nbsp;Base&nbsp;Stations</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSS&nbsp;Message&nbsp;Protocol</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;U:&nbsp;UDP</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;S:&nbsp;TCP&nbsp;Server&nbsp;(recommended)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;C:&nbsp;TCP&nbsp;Client</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>F71/BSS/_</div>
      <div />
      <div />
    </div>
    <h2>TR Configuration</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F75&nbsp;&nbsp;Display&nbsp;TR&nbsp;Configuration</strong>
      </div>
      <div>F75</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F75/nn/TR412&nbsp;&nbsp;Add&nbsp;TR412&nbsp;Transceiver</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F75/__/TR412</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F75/nn/TR421&nbsp;&nbsp;Add&nbsp;TR421&nbsp;Transceiver</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F75/__/TR421</div>
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F75/nn/REMOVE&nbsp;&nbsp;Remove&nbsp;TR</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F75/__/REMOVE</div>
      <div />
      <div />
    </div>
    <h2>PS Configuration</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F76&nbsp;&nbsp;Display&nbsp;PS&nbsp;Configuration</strong>
      </div>
      <div>F76</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F76/nn/PS411&nbsp;&nbsp;Add&nbsp;PS411&nbsp;Power&nbsp;Supply</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..47)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F76/__/PS411</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F76/nn/PS421&nbsp;&nbsp;Add&nbsp;PS421&nbsp;Power&nbsp;Supply</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..47)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F76/__/PS421</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>F76/nn/REMOVE&nbsp;&nbsp;Remove&nbsp;PS</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..47)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>F76/__/REMOVE</div>
      <div />
      <div />
    </div>
    <br />
  </>
);

export default memo(BSCPage) as typeof BSCPage;
