import { NodeSubscriber } from 'src/graphql/generated';

/* eslint-disable no-shadow */
export const enum DrawerViewMode {
  Create = 'Create',
  Edit = 'Edit',
  View = 'View',
}
export const enum SsiType {
  'Mobile' = 1,
  'Group' = 2,
  'Application' = 5,
  'DialIn' = 6,
  'Emergency' = 7,
  'Terminal' = 8,
  'Unified' = 9,
}
export const ssiKindOptions = formatOptions([
  { text: 'Mobile', value: SsiType.Mobile },
  { text: 'Group', value: SsiType.Group },
  { text: 'Application', value: SsiType.Application },
  { text: 'Dial-In', value: SsiType.DialIn },
  { text: 'Emergency', value: SsiType.Emergency },
  { text: 'Terminal', value: SsiType.Terminal },
  { text: 'Unified', value: SsiType.Unified },
]);
export const trustKindOptions = formatOptions([
  { text: 'Normal (fully trusted)', value: 0 },
  { text: 'Blocked (no service)', value: 1 },
  { text: "Visitor (verified of SSI's Home Network)", value: 2 },
  { text: 'Untrusted (auto-created)', value: 3 },
]);
export const callTrfOptions = formatOptions([
  { text: 'None', value: 0 },
  { text: 'Not Reachable (Weak) (Not registered/no radio contact)', value: 1 },
  { text: 'No Answer (Medium) (Incl. Not Reachable)', value: 2 },
  { text: 'Busy (Strong) (Incl. No Answer and Not Reachable)', value: 3 },
  { text: 'Unconditional (Always) (Excluding Blocked SSI)', value: 4 },
  { text: 'Number Change (Always) (Including Blocked SSI)', value: 5 },
]);
export type Options = Array<{ text: string; value: number }>;
export function formatOptions(options: Readonly<Options>): Options {
  return options.map(({ text, value }) => ({ text: `${value}: ${text}`, value }));
}
export const numberSorter = (a: number, b: number) => a - b;
export const stringSorter = (a: string, b: string) => (a < b ? -1 : Number(a > b));

export function getTsiFromMobile(subscriberMobile: NodeSubscriber['mobile']): string {
  const { tsi } = subscriberMobile ?? {};
  const { mcc = 0, mnc = 0, ssi = 0 } = tsi ?? {};

  return [mcc, mnc, ssi].join(':');
}
