import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { Divider, Modal, type ModalProps } from 'antd';
import { Input } from 'src/components/ui-kit/input';
import { Form, FormItem, FormLabel } from 'src/components/ui-kit/form';
import Button from 'src/components/ui-kit/buttons/index';
import {
  NodeAlertLevel,
  NodeAlertSettings,
  NodeSettings,
  UpdateNodeSettingsInput,
  useNodeSettingsQuery,
  useUpdateNodeSettingsMutation,
} from 'src/graphql/generated';
import { Select, SelectOption } from 'src/components/ui-kit/select';
import { logger } from '@fjedi/graphql-react-components';
import { alertFieldMap, NODE_ALERT_LEVELS } from './constants';

const Container = styled(Modal)`
  &.ant-modal > .ant-modal-content {
    border-radius: 0.5rem;
    overflow: hidden;

    & > .ant-modal-header {
      border: 0;
    }

    & > .ant-modal-body {
      padding: 0.25rem 0;

      display: flex;
      flex-direction: column;

      & > ol {
        padding: 0 calc(40px - 1rem);

        & > li:nth-of-type(3) {
          ${FormItem} {
            margin-top: 0.25rem;
          }
        }
      }
    }

    & > .ant-modal-footer {
      border: 0;

      & > .ant-btn {
        padding-right: 0;
      }

      & > .ant-btn.ant-btn-primary {
        display: none;
      }
    }
  }
`;

const FormDivider = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text {
    font-size: 0.9rem;
    margin-top: 1.5rem;

    > .ant-divider-inner-text {
      padding: 0;
    }
  }
`;

const EditButton = styled(Button).attrs({ type: 'primary' })`
  &.ant-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface EditNodeSettingsModalProps extends ModalProps {
  nodeNumber: string;
}

const EditNodeSettingsModal: FC<EditNodeSettingsModalProps> = ({ nodeNumber }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setModalVisibility] = useState(false);
  const onOpen = useCallback(() => {
    setModalVisibility(true);
  }, []);
  const onCancel = useCallback(() => {
    setModalVisibility(false);
  }, []);
  const [updateNodeSettings, { loading: updatingNodeSettings }] = useUpdateNodeSettingsMutation();
  const onSubmit = useCallback(
    (values: unknown) => {
      logger('EditNodeSettingsModal.onSubmit', { values });
      const { timeoutBeforeAlert, notificationInterval, ...alertSettings } = values as Pick<
        NodeSettings,
        'timeoutBeforeAlert' | 'notificationInterval'
      > &
        Omit<NodeAlertSettings, '__typename'>;
      const input: UpdateNodeSettingsInput = {
        timeoutBeforeAlert,
        notificationInterval: notificationInterval * 60,
        alertSettings,
      };
      updateNodeSettings({
        variables: {
          nodeNumber: parseInt(nodeNumber, 10),
          input,
        },
      })
        .then(onCancel)
        .catch(logger);
    },
    [nodeNumber, onCancel, updateNodeSettings],
  );
  const initialValues = useMemo(() => ({}), []);
  const { data } = useNodeSettingsQuery({
    variables: {
      nodeNumber,
    },
    skip: !nodeNumber,
    fetchPolicy: 'cache-only',
  });
  useEffect(() => {
    if (!data?.nodeSettings) {
      return;
    }
    logger('Set initial form values', data.nodeSettings);
    const { timeoutBeforeAlert, notificationInterval, alertSettings } = data.nodeSettings;
    form.setFieldsValue({
      timeoutBeforeAlert,
      notificationInterval: notificationInterval / 60,
      ...alertSettings,
    });
  }, [form, data?.nodeSettings]);
  const alertFields = useMemo(
    () =>
      Object.keys(omit(data?.nodeSettings.alertSettings, ['__typename'])).map(alertField => {
        const f = alertField as unknown as keyof NodeAlertSettings;
        const formLabel = <FormLabel text={t(alertFieldMap.get(f)!)} help="Some very useful tooltip" />;
        return (
          <FormItem key={alertField} label={formLabel} name={alertField}>
            <Select>
              {NODE_ALERT_LEVELS.map(level => (
                <SelectOption key={level} value={level}>
                  {level === NodeAlertLevel.Ignore ? 'Игнорировать' : level}
                </SelectOption>
              ))}
            </Select>
          </FormItem>
        );
      }),
    [t, data?.nodeSettings],
  );
  //
  if (!data?.nodeSettings) {
    return null;
  }
  return (
    <>
      <EditButton onClick={onOpen}>Редактировать</EditButton>
      <Container
        open={visible}
        onCancel={onCancel}
        title="Настройки уведомлений БС"
        cancelButtonProps={{ type: 'link' }}
        cancelText={t('Cancel')}
        okButtonProps={{
          type: 'link',
          disabled: !nodeNumber,
          htmlType: 'submit',
          loading: updatingNodeSettings,
          onClick() {
            form.submit();
          },
        }}
        okText={t('Save')}
        closable={false}
        width={500}
        maskClosable
        keyboard
        centered
        destroyOnClose>
        <Form
          layout="vertical"
          onFinish={onSubmit}
          initialValues={initialValues}
          form={form}
          requiredMark={false}
          validateTrigger={['onBlur', 'onFocus', 'onChange']}>
          <FormItem
            label={<FormLabel text="Задержка отправки уведомлений (сек)" help="Some very useful tooltip" />}
            name="timeoutBeforeAlert"
            rules={[{ pattern: /^\d+$/, message: t('Please enter only digits') as string }]}>
            <Input name="timeoutBeforeAlert" />
          </FormItem>
          <FormItem
            label={<FormLabel text="Периодичность отправки уведомлений (в минутах)" help="Some very useful tooltip" />}
            name="notificationInterval"
            rules={[{ pattern: /^\d+$/, message: t('Please enter only digits') as string }]}>
            <Input name="notificationInterval" />
          </FormItem>

          <FormDivider>Уровни аварии, при которых следует уведомлять</FormDivider>
          {alertFields}

          <button type="submit" style={{ display: 'none' }}>
            Сохранить
          </button>
        </Form>
      </Container>
    </>
  );
};

export default memo(EditNodeSettingsModal) as typeof EditNodeSettingsModal;
