import React, { FC } from 'react';
import styled from 'styled-components';
//
import { Drawer as AntDrawer, DrawerProps } from 'antd';

const StyledDrawer = styled(AntDrawer)`
  height: 100%;

  .ant-drawer-body {
    padding: 1rem;
  }
`;

const Drawer: FC<DrawerProps> = props => <StyledDrawer {...props} />;

export default React.memo(Drawer);
