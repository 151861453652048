import { useLocation, useNavigate, useParams } from '@fjedi/react-router-helpers';
import omit from 'lodash/omit';
import React, { FC, memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { logger, useApolloError, updateAfterMutation } from '@fjedi/graphql-react-components';
import {
  CreateNodeOrganizationMutation,
  CreateNodeOrganizationMutationVariables,
  NodeOrganization,
  UpdateNodeOrganizationMutation,
  UpdateNodeOrganizationMutationVariables,
  useCreateNodeOrganizationMutation,
  useNodeOrganizationQuery,
  useRemoveNodeOrganizationMutation,
  useUpdateNodeOrganizationMutation,
} from 'src/graphql/generated';
// CSS
// Components
//
import { Divider, Empty } from 'antd';
import cn from 'classnames';
import Button from 'src/components/ui-kit/buttons';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import Checkbox from 'src/components/ui-kit/checkbox';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import { Col, Row } from 'src/components/ui-kit/grid';
import { Input } from 'src/components/ui-kit/input';
import Popconfirm from 'src/components/ui-kit/popconfirm';
import Scrollbar from 'src/components/ui-kit/scrollbar';
import Spinner from 'src/components/ui-kit/spinner';
import { stopEventPropagation } from 'src/functions';
import { DrawerViewMode } from './drawer-helpers';
import { Actions, Container, Title } from './drawer.sc';

export type OrganizationDrawerProps = {};
//

const OrganizationDrawer: FC<OrganizationDrawerProps> = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const onError = useApolloError();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onDrawerClose = useCallback(() => navigate('../organizations'), [navigate]);
  //
  const [isEmpty, setEmpty] = useState(false);
  const [form] = Form.useForm();
  //
  const mode = useMemo(() => {
    if (!organizationId) {
      return null;
    }

    if (organizationId === 'new') {
      return DrawerViewMode.Create;
    }

    if (pathname.endsWith(`${organizationId}/edit`) || pathname.endsWith(`${organizationId}/edit/`)) {
      return DrawerViewMode.Edit;
    }

    if (pathname.endsWith(organizationId) || pathname.endsWith(`${organizationId}/`)) {
      return DrawerViewMode.View;
    }

    return DrawerViewMode.View;
  }, [organizationId, pathname]);

  const { data, loading } = useNodeOrganizationQuery({
    variables: { id: organizationId! },
    skip: !organizationId || organizationId === 'new',
    fetchPolicy: 'cache-and-network',
  });
  const nodeOrganization: Partial<NodeOrganization> = useMemo(
    () => (organizationId === 'new' ? {} : data?.nodeOrganization ?? {}),
    [data?.nodeOrganization, organizationId],
  );

  const [createNodeOrganization, { loading: creating }] = useCreateNodeOrganizationMutation({
    onError,
    update: updateAfterMutation('NodeOrganization'),
    onCompleted(res: CreateNodeOrganizationMutation) {
      if (res.createNodeOrganization?.id) {
        form.resetFields();
        onDrawerClose();
      }
    },
  });
  const [updateNodeOrganization, { loading: updating }] = useUpdateNodeOrganizationMutation({
    onError,
    onCompleted(res: UpdateNodeOrganizationMutation) {
      if (res.updateNodeOrganization?.id) {
        form.resetFields();
        onDrawerClose();
      }
    },
  });
  const [removeNodeOrganization, { loading: removing }] = useRemoveNodeOrganizationMutation({
    onError,
    variables: { id: organizationId! },
    update: updateAfterMutation('NodeOrganization'),
    onCompleted(res) {
      if (res?.removeNodeOrganization?.id) {
        onDrawerClose();
      }
    },
  });
  const isLoading = useMemo(() => loading || creating || updating || removing, [loading, creating, removing, updating]);
  //
  const onValuesChange = useCallback((changedValues: any) => {
    logger('Organization.onValuesChange', {
      changedValues,
    });
  }, []);

  const onSubmit = useCallback(
    (formData: any) => {
      const onlyChangedFields = true;
      let input = parseFormData(form, formData, onlyChangedFields);
      if (typeof input.publishingRestriction === 'boolean') {
        input = {
          flags: { publishingRestriction: input.publishingRestriction },
          ...omit(input, 'publishingRestriction'),
        };
      }
      if (typeof input.nodeStatusRestriction === 'boolean') {
        input = {
          flags: { ...(input.flags ?? {}), nodeStatusRestriction: input.nodeStatusRestriction },
          ...omit(input, 'nodeStatusRestriction'),
        };
      }
      if (organizationId === 'new') {
        const variables: CreateNodeOrganizationMutationVariables = {
          input,
        };
        createNodeOrganization({ variables }).catch(logger);
        return;
      }
      const variables: UpdateNodeOrganizationMutationVariables = {
        id: organizationId!,
        input,
      };
      updateNodeOrganization({ variables }).catch(logger);
    },
    [form, organizationId, createNodeOrganization, updateNodeOrganization],
  );
  const onReset = useCallback(() => {
    if (!isEmpty && !isLoading && organizationId) {
      form.resetFields();
    }

    if (!organizationId) {
      onDrawerClose();
    }

    if (mode === DrawerViewMode.Edit) {
      navigate(`./`);
    }

    if (mode === DrawerViewMode.Create) {
      navigate(-1);
    }
  }, [form, isEmpty, isLoading, mode, navigate, onDrawerClose, organizationId]);

  const nodeFormInitialValues = useMemo(() => {
    if (!organizationId || isLoading || isEmpty) {
      return null;
    }

    const {
      orgNo,
      configFlag,
      description,
      flags,
      ipAddrMin,
      ipAddrMax,
      profNoMin,
      profNoMax,
      ssiMin,
      ssiMax,
      userNoMin,
      userNoMax,
    } = nodeOrganization;

    const { publishingRestriction, nodeStatusRestriction } = flags ?? {};

    return {
      orgNo,
      configFlag,
      description,
      publishingRestriction: !publishingRestriction,
      nodeStatusRestriction: !nodeStatusRestriction,
      ipAddrMin,
      ipAddrMax,
      profNoMin,
      profNoMax,
      ssiMin,
      ssiMax,
      userNoMin,
      userNoMax,
    };
  }, [isEmpty, isLoading, nodeOrganization, organizationId]);

  useLayoutEffect(() => {
    if (isEmpty || organizationId !== 'new') {
      form.resetFields();
    }

    if (organizationId === 'new') {
      form.resetFields();
      return () => setEmpty(false);
    }

    if (organizationId && !isLoading) {
      if (!data) {
        setEmpty(true);
      }

      return () => setEmpty(prev => !prev && !!data && !nodeFormInitialValues);
    }

    return undefined;
  }, [data, form, isEmpty, isLoading, loading, nodeFormInitialValues, organizationId]);
  //
  const drawerTitle = useMemo(() => {
    switch (mode) {
      case DrawerViewMode.Edit:
        return t('Organization edit/delete');
      case DrawerViewMode.Create:
        return t('Create Organization');
      case DrawerViewMode.View:
      default:
        return t('Organization info');
    }
  }, [mode, t]);

  const readonly = useMemo(() => mode === DrawerViewMode.View, [mode]);

  return (
    <Container size="large" placement="right" closable={false} onClose={onDrawerClose} open={!!organizationId}>
      <Scrollbar>
        <Title>
          <span>{drawerTitle}</span>
          {readonly && (
            <LinkButton type="primary" to="./edit">
              {t('Изменить')}
            </LinkButton>
          )}
          {readonly && (
            <LinkButton type="primary" to="../organizations/new">
              {t('Доб./Копр.')}
            </LinkButton>
          )}
          {!!data && [DrawerViewMode.Edit, DrawerViewMode.View].includes(mode as DrawerViewMode) && (
            <Popconfirm
              placement="bottomLeft"
              title={`${t('Remove')}?`}
              onClick={stopEventPropagation}
              onConfirm={removeNodeOrganization}>
              <Button loading={removing} type="primary" danger>
                {t('Remove')}
              </Button>
            </Popconfirm>
          )}
        </Title>
        <Spinner spinning={isLoading}>
          {isEmpty && <Empty description={t('Failed to fetch user data')} />}
          {!!nodeFormInitialValues && (
            <Form
              form={form}
              onFinish={onSubmit}
              initialValues={nodeFormInitialValues}
              onValuesChange={onValuesChange}
              layout="vertical"
              className={cn({ readonly })}>
              <Row>
                <Col md={12}>
                  <FormItem name="orgNo" label={t('№ Организации')} rules={[{ required: true }]}>
                    <Input readOnly={readonly} />
                  </FormItem>
                  <FormItem name="description" label={t('Название')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>

                <Col md={12}>
                  <FormItem name="publishingRestriction" valuePropName="checked" label=" ">
                    <Checkbox disabled={readonly}>{t('Показывать')}</Checkbox>
                  </FormItem>
                  <FormItem name="nodeStatusRestriction" valuePropName="checked">
                    <Checkbox disabled={readonly}>{t('Показывать статус Узлов')}</Checkbox>
                  </FormItem>
                </Col>

                <Col md={9}>
                  <FormItem name="profNoMin" label={t('№ Профилей от')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={9}>
                  <FormItem name="profNoMax" label={t('До')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={6}>
                  <FormItem name="configFlag" valuePropName="checked" label={t('Запрет')}>
                    <Checkbox disabled={readonly} />
                  </FormItem>
                </Col>

                <Col md={9}>
                  <FormItem name="ssiMin" label={t('№ SSI от')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={9}>
                  <FormItem name="ssiMax" label={t('До')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={6}>
                  <FormItem name="configFlag" valuePropName="checked" label={t('Запрет')}>
                    <Checkbox disabled={readonly} />
                  </FormItem>
                </Col>

                <Col md={9}>
                  <FormItem name="userNoMin" label={t('№ Абонентов от')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={9}>
                  <FormItem name="userNoMax" label={t('До')}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={6}>
                  <FormItem name="configFlag" valuePropName="checked" label={t('Запрет')}>
                    <Checkbox disabled={readonly} />
                  </FormItem>
                </Col>

                <Col md={9}>
                  <FormItem
                    name="ipAddrMin"
                    label={t('IP адреса от')}
                    rules={[
                      {
                        required: true,
                        message: t('Укажите начало диапазона IP адресов') as string,
                      },
                    ]}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={9}>
                  <FormItem
                    name="ipAddrMax"
                    label={t('До')}
                    rules={[
                      {
                        required: true,
                        message: t('Укажите конец диапазона IP адресов') as string,
                      },
                    ]}>
                    <Input readOnly={readonly} />
                  </FormItem>
                </Col>
                <Col md={6}>
                  <FormItem name="configFlag" valuePropName="checked" label={t('Запрет')}>
                    <Checkbox disabled={readonly} />
                  </FormItem>
                </Col>

                {mode !== DrawerViewMode.View && (
                  <>
                    <Divider />
                    <Actions>
                      <Button htmlType="reset" loading={isLoading} onClick={onReset}>
                        {t('Cancel')}
                      </Button>
                      <SubmitButton loading={isLoading} size="middle">
                        {data ? t('Save') : t('Create')}
                      </SubmitButton>
                    </Actions>
                  </>
                )}
              </Row>
            </Form>
          )}
        </Spinner>
      </Scrollbar>
    </Container>
  );
};

export default memo(OrganizationDrawer);
