import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import axios from 'axios';
import { initReactI18next } from 'react-i18next';
import { API_BASE_URL } from 'src/constants';

export { I18nextProvider, withSSR, useSSR, withTranslation } from 'react-i18next';

export const supportedLangs = {
  ru: 'ru',
  en: 'en',
};
export const fallbackLang = supportedLangs.ru;
export const langRoutePrefix = `/:lang(${Object.keys(supportedLangs).join('|')})?`;

export default function init({ languageDetector, detectionProps = {} } = {}) {
  const baseURL = API_BASE_URL || 'http://localhost:4000';
  //
  const httpClient = axios.create({
    withCredentials: true,
    baseURL,
  });

  //
  i18n.use(Backend);
  if (languageDetector) {
    i18n.use(languageDetector);
  }
  i18n.use(initReactI18next);

  // initialize if not already initialized
  if (!i18n.isInitialized) {
    i18n.init({
      load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
      preload: Object.keys(supportedLangs),
      fallbackLng: fallbackLang,
      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',
      fallbackNS: 'translations',

      // debug: !SERVER && NODE_ENV === 'development',
      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react
      },

      react: {
        // wait MUST be turned OFF on server-side in order SSR could work properly
        wait: !SERVER,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: false,
      },

      detection: {
        // order and from where user language should be detected
        order: ['cookie', 'querystring', 'session', 'navigator'],

        // keys or params to lookup language from
        lookupQuerystring: 'lang',
        lookupSession: 'lang',
        lookupCookie: 'lang',
        // lookupPath: 'lang',
        // lookupFromPathIndex: 0,
        // lookupLocalStorage: 'lang',

        // cache user language on
        caches: ['cookie'],
        //
        ...detectionProps,
      },

      // Backend fetch options
      backend: {
        parse(data) {
          return data;
        },
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: '/api/{{ns}}/{{lng}}.json',
        // allow cross domain requests
        // crossDomain: false,
        // allow credentials on cross domain requests
        // withCredentials: false,
        ajax(url, options, callback) {
          httpClient
            .get(url.toLowerCase(), {
              validateStatus() {
                return true;
              },
            })
            .then(res => {
              callback(res.data, res);
            })
            .catch(res => {
              console.log('ErrorTranslation', res);
              callback(res.data, res);
            });
        },
      },
    });
  }

  i18n.getLanguageOnly = () => i18n.language.split('-')[0];

  return i18n;
}
