import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  // text-transform: uppercase;
  text-align: ${props => (props.centered ? 'center' : 'left')};
  position: relative;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: static;
    width: 1.2rem;
    height: 4px;
    background-color: ${props => props.theme.token.colorPrimary};
    vertical-align: middle;
    margin-bottom: 0.18em;
  }

  &:before {
    display: ${props => (props.centered ? 'inline-block' : 'none')};
    margin-right: 0.5rem;
  }

  &:after {
    margin-left: 0.5rem;
  }

  h1&.ant-typography {
    text-align: center;
    font-family: 'Montserrat', 'Open Sans', sans-serif;

    &:after {
      display: none;
    }
  }
  h2&.ant-typography {
    @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
      display: block;
      line-height: 1.6;
      margin-top: 1rem;
      font-size: 1.8rem;
      // color: #000;
    }
  }

  h4&.ant-typography {
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Montserrat', 'Open Sans', sans-serif;
  }
`;
export const Text = styled(Typography.Text)``;
export const Paragraph = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom: 1.5em;
  }
`;
