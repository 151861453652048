import React, { FC, memo, useCallback, useContext, useMemo, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useApolloError } from '@fjedi/graphql-react-components';
import {
  useEnableGoogleAuthenticatorMutation,
  useDisableGoogleAuthenticatorMutation,
  Viewer,
  useChangePasswordMutation,
} from 'src/graphql/generated';
import { ViewerContext } from '@fjedi/react-router-helpers';
import logger from 'src/helpers/logger';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, InputPrefixLabel } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import { LockOutlined } from 'src/components/ui-kit/icons';
import HelpIndicator from './help-indicator';
import SwitchInput from './switch-input';
import ConnectAuthenticatorModal, { ConnectAuthenticatorFormValues } from './connect-authenticator-modal';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 8rem;

  & > form.ant-form {
    flex-basis: 45%;

    & > .ant-form-item.authenticator {
      position: relative;

      &
        > .ant-form-item-control
        > .ant-form-item-control-input
        > .ant-form-item-control-input-content
        > svg.icon.help-indicator {
        position: absolute;
        width: 1rem;
        height: 1rem;
        top: -1.25rem;
        right: 0;
      }
    }
  }
`;

const DisconnectAuthenticatorModal = styled(Modal).attrs({ className: '2fa-modal' })`
  &.ant-modal > .ant-modal-content {
    border-radius: 0.5rem;
    overflow: hidden;

    & > .ant-modal-header {
      border: 0;
    }

    & > .ant-modal-body {
      padding: 0.5rem 2rem;

      display: flex;
      flex-direction: column;
      align-items: center;

      & > form.ant-form {
        align-self: stretch;
      }
    }

    & > .ant-modal-footer {
      border: 0;
    }
  }
`;

export const UserProfileSecurityPage: FC = () => {
  const { t } = useTranslation();

  const [passwordForm] = Form.useForm();
  const [connect2faForm] = Form.useForm();
  const [disconnect2faForm] = Form.useForm<ConnectAuthenticatorFormValues>();

  const [isConnect2faModalVisible, setIsConnect2faModalVisible] = useState(false);
  const [isDisconnect2faModalVisible, setIsDisconnect2faModalVisible] = useState(false);

  const openDisconnect2faModal = useCallback(() => setIsDisconnect2faModalVisible(true), []);
  const closeDisconnect2faModal = useCallback(() => {
    setIsDisconnect2faModalVisible(false);
    disconnect2faForm.resetFields();
  }, [disconnect2faForm]);

  const openConnect2faModal = useCallback(() => setIsConnect2faModalVisible(true), []);
  const closeConnect2faModal = useCallback(() => {
    setIsConnect2faModalVisible(false);
    connect2faForm.resetFields();
  }, [connect2faForm]);

  const viewer = useContext(ViewerContext) as Viewer;
  const [isGaEnabled, setIsGaEnabled] = useState(viewer?.gaEnabled ?? false);

  const onError = useApolloError();
  const [changePassword, { loading: changingPassword }] = useChangePasswordMutation({
    onError,
    onCompleted() {
      Modal.info({
        content: 'Пароль успешно изменен',
      });
      passwordForm.resetFields();
    },
  });

  useLayoutEffect(() => {
    if (viewer.id) {
      setIsGaEnabled(viewer.gaEnabled);
    }
  }, [viewer.gaEnabled, viewer.id]);

  const connect2faFormInitialValues = useMemo(
    () => ({
      enabled: isGaEnabled,
      code: undefined,
      secret: '',
    }),
    [isGaEnabled],
  );

  const disconnect2faFormInitialValues = useMemo(() => ({ password: '' }), []);

  const submitPasswordForm = useCallback(
    (variables: any) => {
      changePassword({
        variables,
      }).catch(logger);
    },
    [changePassword],
  );

  const [enableGoogleAuthenticator, { loading: enablingGoogleAuthenticator }] = useEnableGoogleAuthenticatorMutation();
  const [disableGoogleAuthenticator, { loading: disablingGoogleAuthenticator }] =
    useDisableGoogleAuthenticatorMutation();
  //
  const submitConnect2faForm = useCallback(
    (values: unknown) => {
      const { secret, code } = values as { secret: string; code: string };

      enableGoogleAuthenticator({ variables: { input: { secret, code } } }).then(res => {
        const { enableGoogleAuthenticator: success = false } = res?.data ?? {};
        const { errors = [] } = res;

        if (success) {
          closeConnect2faModal();
        } else {
          if (errors.length) {
            errors.forEach(err => Modal.info({ type: 'error', content: err.message }));
          }
          Modal.info({ type: 'warn', content: t('Error connecting Google Authenticator!') });
        }

        setIsGaEnabled(success);
        connect2faForm.setFieldsValue({ enabled: success });
      }, logger);
    },
    [enableGoogleAuthenticator, connect2faForm, closeConnect2faModal, t],
  );

  const submitDisconnect2faForm = useCallback(
    (values: unknown) => {
      const { password } = values as { password: string };

      disableGoogleAuthenticator({ variables: { input: { password } } }).then(res => {
        const { disableGoogleAuthenticator: success = false } = res?.data ?? {};
        const { errors = [] } = res;

        if (success) {
          closeDisconnect2faModal();
        } else {
          if (errors.length) {
            errors.forEach(err => Modal.info({ type: 'error', content: err.message }));
          }
          Modal.info({ type: 'warn', content: t('Error disconnecting Google Authenticator!') });
        }

        setIsGaEnabled(!success);
        connect2faForm.setFieldsValue({ enable: !success });
      }, logger);
    },
    [closeDisconnect2faModal, connect2faForm, disableGoogleAuthenticator, t],
  );

  const handleSwitchChange = useCallback(
    (enabled: boolean) => {
      if (enabled && !isGaEnabled) {
        openConnect2faModal();
      }

      if (!enabled && isGaEnabled) {
        openDisconnect2faModal();
      }

      connect2faForm.setFieldsValue({ enabled });
    },
    [connect2faForm, isGaEnabled, openConnect2faModal, openDisconnect2faModal],
  );

  const onConnect2faModalCancel = useCallback(() => {
    connect2faForm.setFieldsValue({ enabled: false });
    closeConnect2faModal();
  }, [connect2faForm, closeConnect2faModal]);

  const onDisconnect2faModalCancel = useCallback(() => {
    connect2faForm.setFieldsValue({ enabled: true });
    closeDisconnect2faModal();
  }, [connect2faForm, closeDisconnect2faModal]);

  const addonBeforePassInput = useMemo(
    () => (
      <InputPrefixLabel>
        <LockOutlined />
      </InputPrefixLabel>
    ),
    [],
  );

  return (
    <Container>
      <Form layout="vertical" onFinish={submitPasswordForm} form={passwordForm}>
        <FormItem
          label={t('Old password')}
          name="oldPassword"
          rules={[
            {
              required: true,
              message: t('Enter_your_current_password') as string,
            },
          ]}>
          <Input type="password" placeholder="" autoComplete="current-password" />
        </FormItem>
        <FormItem
          label={t('New password')}
          name="newPassword"
          rules={[{ required: true, message: t('Enter_new_password') as string }]}>
          <Input type="password" placeholder="" autoComplete="new-password" />
        </FormItem>
        <FormItem
          label={t('Repeat password')}
          name="passwordConfirmation"
          rules={[{ required: true, message: t('Repeat_new_password') as string }]}>
          <Input type="password" placeholder="" autoComplete="new-password" />
        </FormItem>
        <Button block loading={changingPassword} type="primary" size="large" htmlType="submit">
          {t('Save')}
        </Button>
      </Form>
      <Form
        layout="vertical"
        onFinish={submitConnect2faForm}
        initialValues={connect2faFormInitialValues}
        form={connect2faForm}
        validateTrigger={['onBlur', 'onFocus', 'onChange']}>
        <FormItem label={t('Google Authenticator')} name="enabled" className="authenticator">
          <HelpIndicator text={t('Two factor authentication with Google Authenticator') as string} />
          <SwitchInput name="enabled" onChange={handleSwitchChange} value={isGaEnabled} />
        </FormItem>
        <ConnectAuthenticatorModal
          open={isConnect2faModalVisible}
          onCancel={onConnect2faModalCancel}
          formInstance={connect2faForm}
          isGaEnabled={isGaEnabled}
          isLoading={enablingGoogleAuthenticator}
        />
      </Form>
      <DisconnectAuthenticatorModal
        visible={isDisconnect2faModalVisible}
        onCancel={onDisconnect2faModalCancel}
        title={t('Disconnect Google Authenticator')}
        okButtonProps={{
          type: 'link',
          htmlType: 'submit',
          loading: disablingGoogleAuthenticator,
          onClick() {
            disconnect2faForm.submit();
          },
        }}
        cancelButtonProps={{ type: 'link' }}
        okText={t('Finish')}
        width={468}
        closable={false}
        maskClosable
        keyboard
        centered
        destroyOnClose>
        <p>{t("Enter your account's password in order to confirm disabling Google Authenticator")}</p>
        <Form
          layout="vertical"
          onFinish={submitDisconnect2faForm}
          initialValues={disconnect2faFormInitialValues}
          form={disconnect2faForm}>
          <FormItem name="password">
            <Input type="password" placeholder={t('Password') as string} addonBefore={addonBeforePassInput} />
          </FormItem>
        </Form>
      </DisconnectAuthenticatorModal>
    </Container>
  );
};

UserProfileSecurityPage.displayName = 'UserProfileSecurityPage';

export default memo(UserProfileSecurityPage) as typeof UserProfileSecurityPage;
