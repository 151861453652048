import React, { FC, memo } from 'react';

const OMPage: FC = () => (
  <>
    <h1>OM program</h1>
    <br />
    <h2>OM</h2>
    <br />
    <div>About&nbsp;the&nbsp;main&nbsp;window</div>
    <br />
    <div>
      The&nbsp;main&nbsp;window&nbsp;is&nbsp;a&nbsp;little&nbsp;window&nbsp;containing&nbsp;5&nbsp;big&nbsp;buttons,&nbsp;a&nbsp;connection-button&nbsp;
    </div>
    <div>and&nbsp;a&nbsp;menu.</div>
    <br />
    <div>
      The&nbsp;5&nbsp;big&nbsp;buttons&nbsp;control&nbsp;the&nbsp;visabillity&nbsp;of&nbsp;5&nbsp;windows:&nbsp;The&nbsp;terminal&nbsp;window,&nbsp;the&nbsp;
    </div>
    <div>
      device&nbsp;window,&nbsp;the&nbsp;editor&nbsp;window,&nbsp;the&nbsp;fileview&nbsp;window&nbsp;and&nbsp;the&nbsp;help&nbsp;window.&nbsp;You&nbsp;can&nbsp;
    </div>
    <div>
      find&nbsp;help&nbsp;for&nbsp;these&nbsp;windows&nbsp;in&nbsp;the&nbsp;main&nbsp;menu&nbsp;of&nbsp;this&nbsp;help&nbsp;file.
    </div>
    <br />
    <br />
    <div>The&nbsp;menu</div>
    <br />
    <br />
    <div>Logging&nbsp;menu</div>
    <br />
    <div>
      The&nbsp;"Logging"&nbsp;menu&nbsp;will&nbsp;open&nbsp;a&nbsp;logging&nbsp;window,&nbsp;where&nbsp;you&nbsp;can&nbsp;select&nbsp;a&nbsp;file&nbsp;to&nbsp;save&nbsp;
    </div>
    <div>
      the&nbsp;log-text&nbsp;to.&nbsp;You&nbsp;can&nbsp;type&nbsp;in&nbsp;a&nbsp;filename&nbsp;or&nbsp;browse&nbsp;to&nbsp;one.
    </div>
    <br />
    <div>
      After&nbsp;selecting&nbsp;what&nbsp;to&nbsp;save&nbsp;in&nbsp;your&nbsp;log-file,&nbsp;you&nbsp;can&nbsp;either&nbsp;choose&nbsp;to&nbsp;append
    </div>
    <div>
      text&nbsp;to&nbsp;the&nbsp;file&nbsp;or&nbsp;create,&nbsp;(and&nbsp;thereby&nbsp;emptying),&nbsp;the&nbsp;file.
    </div>
    <br />
    <div>
      After&nbsp;the&nbsp;logging&nbsp;is&nbsp;started&nbsp;you&nbsp;can&nbsp;close&nbsp;the&nbsp;window&nbsp;and&nbsp;then&nbsp;later&nbsp;return&nbsp;to
    </div>
    <div>
      stop&nbsp;the&nbsp;logging&nbsp;with&nbsp;the&nbsp;"Stop&nbsp;logging"&nbsp;button,&nbsp;and&nbsp;start&nbsp;a&nbsp;new&nbsp;logging&nbsp;in&nbsp;
    </div>
    <div>another&nbsp;file.</div>
    <br />
    <div>
      You&nbsp;can&nbsp;also&nbsp;control&nbsp;the&nbsp;logging&nbsp;from&nbsp;the&nbsp;command&nbsp;line.&nbsp;Look&nbsp;for&nbsp;the&nbsp;!LOG&nbsp;commands&nbsp;
    </div>
    <div>for&nbsp;a&nbsp;description&nbsp;of&nbsp;how&nbsp;to&nbsp;do&nbsp;that.</div>
    <br />
    <br />
    <div>Options&nbsp;menu</div>
    <br />
    <div>
      The&nbsp;Options-window&nbsp;gives&nbsp;you&nbsp;the&nbsp;possibility&nbsp;to&nbsp;set&nbsp;these&nbsp;options:
    </div>
    <br />
    <div>
      1.&nbsp;Should&nbsp;commands&nbsp;and&nbsp;responses&nbsp;from&nbsp;your&nbsp;current&nbsp;device&nbsp;be&nbsp;shown&nbsp;in&nbsp;the&nbsp;terminal
    </div>
    <div>window.</div>
    <br />
    <div>
      2.&nbsp;Should&nbsp;commands&nbsp;and&nbsp;responses&nbsp;from&nbsp;your&nbsp;scripts&nbsp;be&nbsp;shown&nbsp;in&nbsp;the&nbsp;terminal&nbsp;
    </div>
    <div>window.</div>
    <br />
    <div>3.&nbsp;What&nbsp;should&nbsp;the&nbsp;timeout&nbsp;and&nbsp;line-delay&nbsp;values&nbsp;be.</div>
    <br />
    <div>
      4.&nbsp;How&nbsp;many&nbsp;characters&nbsp;should&nbsp;the&nbsp;terminal&nbsp;window&nbsp;hold,&nbsp;and&nbsp;what&nbsp;character&nbsp;size&nbsp;
    </div>
    <div>should&nbsp;be&nbsp;used.</div>
    <br />
    <div>5.&nbsp;What&nbsp;system&nbsp;should&nbsp;be&nbsp;used.</div>
    <br />
    <br />
    <div>About&nbsp;menu</div>
    <br />
    <div>
      In&nbsp;the&nbsp;about&nbsp;window&nbsp;there&nbsp;is&nbsp;a&nbsp;copyright&nbsp;message&nbsp;and&nbsp;other&nbsp;information&nbsp;about&nbsp;the
    </div>
    <div>OM&nbsp;program.</div>
    <br />
    <br />
    <div>The&nbsp;connection&nbsp;button</div>
    <br />
    <div>
      At&nbsp;the&nbsp;bottom&nbsp;of&nbsp;the&nbsp;main&nbsp;window,&nbsp;is&nbsp;the&nbsp;"connection&nbsp;button".&nbsp;If&nbsp;you&nbsp;are&nbsp;not&nbsp;
    </div>
    <div>
      connected&nbsp;it&nbsp;will&nbsp;state:&nbsp;"No&nbsp;connection".&nbsp;By&nbsp;pressing&nbsp;the&nbsp;button&nbsp;you&nbsp;will&nbsp;get&nbsp;a&nbsp;
    </div>
    <div>
      "Connection&nbsp;list"&nbsp;window.&nbsp;Here&nbsp;you&nbsp;can&nbsp;specify&nbsp;how&nbsp;the&nbsp;OM&nbsp;should&nbsp;connect&nbsp;to&nbsp;your
    </div>
    <div>equipment.</div>
    <br />
    <div>
      You&nbsp;can&nbsp;specify&nbsp;that&nbsp;the&nbsp;OM&nbsp;should&nbsp;connect&nbsp;through&nbsp;a&nbsp;RS232&nbsp;cable,&nbsp;or&nbsp;via&nbsp;a&nbsp;LAN.
    </div>
    <br />
    <div>
      After&nbsp;typing&nbsp;in&nbsp;the&nbsp;connection&nbsp;information,&nbsp;you&nbsp;can&nbsp;choose&nbsp;the&nbsp;line&nbsp;in&nbsp;the&nbsp;list
    </div>
    <div>
      and&nbsp;press&nbsp;the&nbsp;"connect"&nbsp;button.&nbsp;The&nbsp;OM&nbsp;will&nbsp;then&nbsp;try&nbsp;to&nbsp;connect.&nbsp;After&nbsp;a&nbsp;succesful&nbsp;
    </div>
    <div>
      connection,&nbsp;the&nbsp;"Connection&nbsp;list"&nbsp;window&nbsp;will&nbsp;close,&nbsp;the&nbsp;terminal&nbsp;window&nbsp;will&nbsp;
    </div>
    <div>
      open,&nbsp;and&nbsp;you&nbsp;will&nbsp;be&nbsp;able&nbsp;to&nbsp;see&nbsp;the&nbsp;connection&nbsp;made&nbsp;in&nbsp;the&nbsp;caption&nbsp;of&nbsp;the&nbsp;
    </div>
    <div>"connection&nbsp;button"</div>
    <br />
    <div>
      After&nbsp;a&nbsp;connection&nbsp;through&nbsp;a&nbsp;RS232&nbsp;cable&nbsp;via&nbsp;COM1&nbsp;at&nbsp;speed&nbsp;9600&nbsp;Baud&nbsp;to&nbsp;a&nbsp;
    </div>
    <div>
      connection&nbsp;you&nbsp;have&nbsp;specified&nbsp;as&nbsp;a&nbsp;"Base&nbsp;station"&nbsp;-connection,&nbsp;the&nbsp;button&nbsp;will&nbsp;
    </div>
    <div>read:&nbsp;</div>
    <br />
    <div>Direct&nbsp;connection:&nbsp;Base&nbsp;station&nbsp;-&gt;&nbsp;COM1&nbsp;9600</div>
    <br />
    <div>*&nbsp;&nbsp;About&nbsp;the&nbsp;main&nbsp;window</div>
    <br />
    <br />
    <br />
    <div>About&nbsp;the&nbsp;terminal</div>
    <br />
    <div>Terminal&nbsp;commands</div>
    <br />
    <div>
      Commands&nbsp;typed&nbsp;in&nbsp;the&nbsp;terminal&nbsp;window&nbsp;are&nbsp;usually&nbsp;send&nbsp;directly&nbsp;to&nbsp;the&nbsp;device
    </div>
    <div>
      you&nbsp;are&nbsp;connected&nbsp;to.&nbsp;Some&nbsp;commands&nbsp;however,&nbsp;are&nbsp;interpreted&nbsp;by&nbsp;the&nbsp;OM.&nbsp;Most&nbsp;of&nbsp;
    </div>
    <div>
      these&nbsp;commands&nbsp;start&nbsp;with&nbsp;a&nbsp;"!"&nbsp;sign,&nbsp;except&nbsp;the&nbsp;"C/"&nbsp;and&nbsp;the&nbsp;"R/"&nbsp;commands,
    </div>
    <div>
      which&nbsp;continue&nbsp;or&nbsp;repeats&nbsp;to&nbsp;execute&nbsp;the&nbsp;same&nbsp;line,&nbsp;with&nbsp;or&nbsp;without&nbsp;scrolling
    </div>
    <div>the&nbsp;screen.</div>
    <br />
    <div>
      The&nbsp;terminal&nbsp;comes&nbsp;in&nbsp;handy&nbsp;whenever&nbsp;you&nbsp;need&nbsp;a&nbsp;fast&nbsp;answer.&nbsp;For&nbsp;instance:&nbsp;most
    </div>
    <div>
      devices&nbsp;will&nbsp;respond&nbsp;with&nbsp;name&nbsp;and&nbsp;version&nbsp;when&nbsp;the&nbsp;00&nbsp;command&nbsp;is&nbsp;send
    </div>
    <br />
    <div>
      The&nbsp;repeat&nbsp;command&nbsp;(R/&nbsp;or&nbsp;R|)&nbsp;is&nbsp;often&nbsp;used&nbsp;when&nbsp;you&nbsp;need&nbsp;to&nbsp;see&nbsp;a&nbsp;change&nbsp;in&nbsp;the
    </div>
    <div>respone&nbsp;from&nbsp;your&nbsp;device.</div>
    <div>
      If&nbsp;you&nbsp;know&nbsp;a&nbsp;sertain&nbsp;command&nbsp;will&nbsp;output&nbsp;a&nbsp;different&nbsp;result&nbsp;when&nbsp;a&nbsp;sertain&nbsp;event
    </div>
    <div>
      happens&nbsp;you&nbsp;can&nbsp;issue&nbsp;the&nbsp;repeat&nbsp;command&nbsp;and&nbsp;wait&nbsp;for&nbsp;the&nbsp;response&nbsp;to&nbsp;change.
    </div>
    <br />
    <div>
      With&nbsp;the&nbsp;mouse&nbsp;you&nbsp;can&nbsp;mark&nbsp;areas&nbsp;of&nbsp;the&nbsp;terminal.&nbsp;By&nbsp;either&nbsp;using&nbsp;the&nbsp;edit&nbsp;menu
    </div>
    <div>
      at&nbsp;the&nbsp;top&nbsp;of&nbsp;the&nbsp;terminal,&nbsp;or&nbsp;the&nbsp;popupmenu&nbsp;(right&nbsp;mouse-button&nbsp;click),&nbsp;you&nbsp;can
    </div>
    <div>copy&nbsp;the&nbsp;marked&nbsp;text.</div>
    <br />
    <br />
    <div>The&nbsp;terminal&nbsp;menus</div>
    <br />
    <div>Menu:&nbsp;Edit</div>
    <br />
    <div>
      In&nbsp;the&nbsp;Edit-menu&nbsp;you&nbsp;will&nbsp;find&nbsp;menues&nbsp;for&nbsp;copying&nbsp;and&nbsp;pasting&nbsp;text,&nbsp;for&nbsp;makeing&nbsp;a
    </div>
    <div>
      print&nbsp;of&nbsp;the&nbsp;terminal,&nbsp;and&nbsp;a&nbsp;menu&nbsp;for&nbsp;saveing&nbsp;the&nbsp;terminal-text&nbsp;to&nbsp;a&nbsp;file.
    </div>
    <br />
    <div>
      By&nbsp;right-clicking&nbsp;the&nbsp;mouse&nbsp;in&nbsp;the&nbsp;terminal,&nbsp;you&nbsp;get&nbsp;the&nbsp;edit&nbsp;menu,&nbsp;as&nbsp;a&nbsp;popup-
    </div>
    <div>menu</div>
    <br />
    <br />
    <div>Menu:&nbsp;Scripts</div>
    <br />
    <div>
      The&nbsp;Scripts-menu&nbsp;has&nbsp;a&nbsp;sub-menu&nbsp;called&nbsp;"Local&nbsp;scripts",&nbsp;this&nbsp;menu&nbsp;holds&nbsp;all&nbsp;the
    </div>
    <div>
      script-files&nbsp;found.&nbsp;If&nbsp;you&nbsp;saved&nbsp;your&nbsp;scripts&nbsp;in&nbsp;a&nbsp;directory,&nbsp;that&nbsp;is&nbsp;not&nbsp;your
    </div>
    <div>
      current&nbsp;directory,&nbsp;you&nbsp;can&nbsp;use&nbsp;the&nbsp;"Locate&nbsp;scripts...",&nbsp;to&nbsp;change&nbsp;directory.
    </div>
    <br />
    <div>
      With&nbsp;the&nbsp;"Run&nbsp;disk-file"&nbsp;menu,&nbsp;you&nbsp;can&nbsp;locate&nbsp;a&nbsp;file&nbsp;to&nbsp;run&nbsp;as&nbsp;a&nbsp;script.
    </div>
    <br />
    <div>
      The&nbsp;"New&nbsp;script..."&nbsp;will&nbsp;open&nbsp;up&nbsp;the&nbsp;editor,&nbsp;and&nbsp;let&nbsp;you&nbsp;type&nbsp;in&nbsp;a&nbsp;new&nbsp;script.
    </div>
    <br />
    <div>
      The&nbsp;"Options...",&nbsp;will&nbsp;show&nbsp;the&nbsp;Options-window&nbsp;with&nbsp;the&nbsp;Script-page&nbsp;selected.
    </div>
    <br />
    <br />
    <div>Menu:&nbsp;Terminal</div>
    <br />
    <div>
      The&nbsp;"Options...",&nbsp;will&nbsp;show&nbsp;the&nbsp;Options-window&nbsp;with&nbsp;the&nbsp;Terminal-page&nbsp;selected.
    </div>
    <br />
    <div>*&nbsp;&nbsp;About&nbsp;the&nbsp;terminal&nbsp;</div>
    <br />
    <br />
    <br />
    <div>About&nbsp;the&nbsp;device</div>
    <br />
    <div>
      In&nbsp;the&nbsp;Device&nbsp;form,&nbsp;you&nbsp;can&nbsp;get&nbsp;an&nbsp;overview&nbsp;of&nbsp;sertain&nbsp;parts&nbsp;of&nbsp;your&nbsp;equipment
    </div>
    <div>
      presented&nbsp;in&nbsp;a&nbsp;graphical&nbsp;way.&nbsp;For&nbsp;instance&nbsp;you&nbsp;can&nbsp;look&nbsp;at&nbsp;a&nbsp;line&nbsp;unit,&nbsp;reading
    </div>
    <div>
      the&nbsp;states&nbsp;of&nbsp;it's&nbsp;test-points&nbsp;and&nbsp;viewing&nbsp;settings.&nbsp;Most&nbsp;presentations&nbsp;have
    </div>
    <div>
      popup-menus,&nbsp;that&nbsp;enable&nbsp;the&nbsp;user&nbsp;to&nbsp;select&nbsp;settings.&nbsp;For&nbsp;instance&nbsp;you&nbsp;can&nbsp;click
    </div>
    <div>
      a&nbsp;test-point&nbsp;and&nbsp;a&nbsp;popupmenu&nbsp;will&nbsp;apear&nbsp;giving&nbsp;you&nbsp;the&nbsp;chance&nbsp;to&nbsp;select&nbsp;between
    </div>
    <div>viewing&nbsp;the&nbsp;result&nbsp;in&nbsp;either&nbsp;volt&nbsp;or&nbsp;dBm.</div>
    <br />
    <div>
      In&nbsp;the&nbsp;statusline&nbsp;of&nbsp;the&nbsp;device&nbsp;form,&nbsp;you&nbsp;can&nbsp;see&nbsp;the&nbsp;device&nbsp;you&nbsp;are&nbsp;looking&nbsp;at,
    </div>
    <div>
      a&nbsp;led&nbsp;indicating&nbsp;the&nbsp;status,&nbsp;and&nbsp;the&nbsp;result&nbsp;of&nbsp;a&nbsp;00&nbsp;command.
    </div>
    <br />
    <div>
      The&nbsp;device&nbsp;menu&nbsp;lets&nbsp;you&nbsp;print&nbsp;a&nbsp;"screen-dump"&nbsp;of&nbsp;the&nbsp;device,&nbsp;and&nbsp;open&nbsp;the&nbsp;Options
    </div>
    <div>window,&nbsp;with&nbsp;the&nbsp;Device&nbsp;page&nbsp;selected.</div>
    <br />
    <div>
      After&nbsp;selecting&nbsp;a&nbsp;device,&nbsp;an&nbsp;updating&nbsp;loop&nbsp;is&nbsp;started.&nbsp;To&nbsp;stop&nbsp;the&nbsp;updating&nbsp;and
    </div>
    <div>return&nbsp;to&nbsp;the&nbsp;"No&nbsp;device",&nbsp;use&nbsp;the&nbsp;Stop&nbsp;button.</div>
    <br />
    <div>*&nbsp;&nbsp;About&nbsp;the&nbsp;device</div>
    <br />
    <br />
    <br />
    <div>About&nbsp;the&nbsp;editor</div>
    <br />
    <div>
      The&nbsp;editor&nbsp;can&nbsp;be&nbsp;used&nbsp;to&nbsp;edit&nbsp;and&nbsp;run&nbsp;scripts.&nbsp;It&nbsp;can&nbsp;also&nbsp;be&nbsp;used&nbsp;as&nbsp;a&nbsp;simple
    </div>
    <div>text&nbsp;editor.</div>
    <br />
    <div>The&nbsp;editor&nbsp;has&nbsp;functions&nbsp;that&nbsp;let&nbsp;you:</div>
    <br />
    <div>x)&nbsp;cut,&nbsp;copy&nbsp;and&nbsp;paste&nbsp;text.&nbsp;</div>
    <div>x)&nbsp;make&nbsp;a&nbsp;search&nbsp;and&nbsp;a&nbsp;search/replace.</div>
    <div>x)&nbsp;print&nbsp;the&nbsp;text.</div>
    <br />
    <div>*&nbsp;&nbsp;About&nbsp;the&nbsp;editor</div>
    <br />
    <br />
    <br />
    <div>About&nbsp;the&nbsp;fileview</div>
    <br />
    <div>
      The&nbsp;fileviewer&nbsp;let&nbsp;you&nbsp;quickly&nbsp;open&nbsp;up&nbsp;readable&nbsp;files&nbsp;for&nbsp;examination.&nbsp;Further&nbsp;
    </div>
    <div>
      more&nbsp;you&nbsp;can&nbsp;use&nbsp;it&nbsp;to&nbsp;track&nbsp;changes&nbsp;of&nbsp;files.&nbsp;You&nbsp;can&nbsp;for&nbsp;instance&nbsp;open&nbsp;up&nbsp;a&nbsp;log
    </div>
    <div>
      file,&nbsp;and&nbsp;let&nbsp;the&nbsp;fileviewer&nbsp;watch&nbsp;for&nbsp;changes.&nbsp;Whenever&nbsp;a&nbsp;new&nbsp;line&nbsp;is&nbsp;added&nbsp;to&nbsp;
    </div>
    <div>
      the&nbsp;log&nbsp;file&nbsp;the&nbsp;fileviewer&nbsp;will&nbsp;automatically&nbsp;load&nbsp;the&nbsp;line&nbsp;and&nbsp;insert&nbsp;it&nbsp;into
    </div>
    <div>the&nbsp;fileviewer.</div>
    <br />
    <div>
      You&nbsp;can&nbsp;put&nbsp;files&nbsp;in&nbsp;a&nbsp;list,&nbsp;which&nbsp;will&nbsp;be&nbsp;saved&nbsp;in&nbsp;the&nbsp;settings&nbsp;file&nbsp;of&nbsp;the&nbsp;OM.
    </div>
    <br />
    <div>
      If&nbsp;a&nbsp;file&nbsp;is&nbsp;not&nbsp;in&nbsp;the&nbsp;list&nbsp;it&nbsp;is&nbsp;possible&nbsp;to&nbsp;browse&nbsp;your&nbsp;drives&nbsp;for&nbsp;the&nbsp;file.
    </div>
    <div>
      The&nbsp;browsed&nbsp;files&nbsp;will&nbsp;be&nbsp;put&nbsp;in&nbsp;"Files&nbsp;/&nbsp;Browsed"&nbsp;menu.&nbsp;The&nbsp;next&nbsp;time&nbsp;you&nbsp;want&nbsp;to
    </div>
    <div>
      view&nbsp;the&nbsp;same&nbsp;file&nbsp;you&nbsp;can&nbsp;select&nbsp;it&nbsp;in&nbsp;"Files&nbsp;/&nbsp;Browsed"&nbsp;menu.
    </div>
    <br />
    <div>
      With&nbsp;the&nbsp;"Edit&nbsp;/&nbsp;Edit&nbsp;browsed&nbsp;/&nbsp;Add&nbsp;item&nbsp;to&nbsp;list..."&nbsp;you&nbsp;can&nbsp;put&nbsp;browsed&nbsp;files
    </div>
    <div>into&nbsp;the&nbsp;"listed&nbsp;files"-list.</div>
    <br />
    <div>
      The&nbsp;menu&nbsp;will&nbsp;let&nbsp;you&nbsp;open&nbsp;up&nbsp;another&nbsp;fileviewer,&nbsp;print&nbsp;the&nbsp;text,&nbsp;and&nbsp;save&nbsp;it&nbsp;
    </div>
    <div>into&nbsp;another&nbsp;file.</div>
    <br />
    <div>
      In&nbsp;the&nbsp;statusline&nbsp;you&nbsp;can&nbsp;see&nbsp;if&nbsp;tracking&nbsp;is&nbsp;turned&nbsp;on&nbsp;or&nbsp;off,&nbsp;how&nbsp;large&nbsp;the&nbsp;file
    </div>
    <div>is,&nbsp;the&nbsp;files&nbsp;date&nbsp;and&nbsp;time&nbsp;settings,&nbsp;and&nbsp;the&nbsp;filename.</div>
    <br />
    <div>*&nbsp;&nbsp;About&nbsp;the&nbsp;fileview</div>
    <br />
    <br />
    <br />
    <div>About&nbsp;the&nbsp;help</div>
    <br />
    <div>
      The&nbsp;help&nbsp;contains&nbsp;3&nbsp;levels&nbsp;of&nbsp;information.&nbsp;When&nbsp;you&nbsp;first&nbsp;display&nbsp;the&nbsp;help&nbsp;form,
    </div>
    <div>
      you&nbsp;will&nbsp;see&nbsp;a&nbsp;list&nbsp;of&nbsp;help&nbsp;files&nbsp;-&nbsp;this&nbsp;is&nbsp;the&nbsp;first&nbsp;level.&nbsp;Next&nbsp;level&nbsp;is&nbsp;a&nbsp;list
    </div>
    <div>
      of&nbsp;either&nbsp;commands&nbsp;or&nbsp;help-texts.&nbsp;You&nbsp;reach&nbsp;this&nbsp;level&nbsp;by&nbsp;choosing&nbsp;a&nbsp;help&nbsp;file,&nbsp;
    </div>
    <div>
      and&nbsp;pressing&nbsp;either&nbsp;enter&nbsp;or&nbsp;double-clicking&nbsp;it&nbsp;with&nbsp;the&nbsp;mouse.&nbsp;The&nbsp;last&nbsp;level&nbsp;is&nbsp;
    </div>
    <div>a&nbsp;command&nbsp;description&nbsp;or&nbsp;the&nbsp;help-text.</div>
    <br />
    <div>You&nbsp;can&nbsp;step&nbsp;through&nbsp;the&nbsp;help&nbsp;by&nbsp;using&nbsp;either:</div>
    <br />
    <div>1)&nbsp;the&nbsp;mouse&nbsp;and&nbsp;the&nbsp;buttons:&nbsp;"Back"&nbsp;and&nbsp;"Description".</div>
    <div>2)&nbsp;the&nbsp;arrow&nbsp;buttons,&nbsp;the&nbsp;enter&nbsp;and&nbsp;the&nbsp;escape&nbsp;key.&nbsp;</div>
    <br />
    <div>
      The&nbsp;enter&nbsp;key&nbsp;is&nbsp;the&nbsp;same&nbsp;as&nbsp;a&nbsp;mouse&nbsp;double-click,&nbsp;the&nbsp;escape&nbsp;key&nbsp;is&nbsp;the&nbsp;same&nbsp;as
    </div>
    <div>the&nbsp;Back&nbsp;button.</div>
    <br />
    <div>
      On&nbsp;some&nbsp;commands&nbsp;or&nbsp;texts&nbsp;you&nbsp;can&nbsp;get&nbsp;a&nbsp;description.&nbsp;For&nbsp;instance,&nbsp;you&nbsp;can&nbsp;get&nbsp;a&nbsp;
    </div>
    <div>
      "Address&nbsp;selection"-description.&nbsp;If&nbsp;there&nbsp;is&nbsp;a&nbsp;such&nbsp;description&nbsp;for&nbsp;a&nbsp;given&nbsp;
    </div>
    <div>
      command,&nbsp;the&nbsp;Description&nbsp;button&nbsp;will&nbsp;be&nbsp;enabled.&nbsp;You&nbsp;can&nbsp;view&nbsp;the&nbsp;description&nbsp;by
    </div>
    <div>clicking&nbsp;the&nbsp;Description&nbsp;button&nbsp;or&nbsp;pressing&nbsp;Alt-D.</div>
    <br />
    <div>
      When&nbsp;you&nbsp;reach&nbsp;the&nbsp;command-level,&nbsp;the&nbsp;Execute&nbsp;button&nbsp;will&nbsp;be&nbsp;enabled,&nbsp;and&nbsp;if&nbsp;any
    </div>
    <div>
      parameters&nbsp;are&nbsp;allowed&nbsp;you&nbsp;can&nbsp;insert&nbsp;these&nbsp;and&nbsp;execute&nbsp;the&nbsp;command.
    </div>
    <br />
    <div>
      In&nbsp;the&nbsp;status&nbsp;line&nbsp;you&nbsp;can&nbsp;see&nbsp;where&nbsp;you&nbsp;are&nbsp;in&nbsp;the&nbsp;help,&nbsp;and&nbsp;where&nbsp;the&nbsp;output
    </div>
    <div>
      from&nbsp;an&nbsp;execute&nbsp;will&nbsp;go&nbsp;to.&nbsp;If&nbsp;the&nbsp;status&nbsp;line&nbsp;states:&nbsp;Output=Terminal,&nbsp;then&nbsp;the
    </div>
    <div>
      commands&nbsp;you&nbsp;choose&nbsp;to&nbsp;execute&nbsp;will&nbsp;be&nbsp;send&nbsp;to&nbsp;your&nbsp;device&nbsp;attached&nbsp;and&nbsp;the&nbsp;
    </div>
    <div>
      command&nbsp;and&nbsp;the&nbsp;result&nbsp;from&nbsp;the&nbsp;command&nbsp;will&nbsp;be&nbsp;showed&nbsp;in&nbsp;the&nbsp;terminal.&nbsp;If&nbsp;the&nbsp;
    </div>
    <div>
      status&nbsp;line&nbsp;states:&nbsp;Output=Editor,&nbsp;then&nbsp;the&nbsp;command&nbsp;will&nbsp;be&nbsp;send&nbsp;to&nbsp;the&nbsp;editor&nbsp;as
    </div>
    <div>a&nbsp;text&nbsp;string.</div>
    <div>
      This&nbsp;is&nbsp;very&nbsp;handy&nbsp;when&nbsp;you&nbsp;write&nbsp;scripts.&nbsp;If&nbsp;you&nbsp;dont&nbsp;remember&nbsp;a&nbsp;sertain&nbsp;command,
    </div>
    <div>
      you&nbsp;can&nbsp;press&nbsp;F1&nbsp;to&nbsp;open&nbsp;the&nbsp;help.&nbsp;Then&nbsp;you&nbsp;find&nbsp;the&nbsp;command&nbsp;in&nbsp;the&nbsp;help,&nbsp;write&nbsp;in
    </div>
    <div>
      any&nbsp;parameters&nbsp;and&nbsp;press&nbsp;enter.&nbsp;The&nbsp;help&nbsp;window&nbsp;will&nbsp;then&nbsp;be&nbsp;closed,&nbsp;and&nbsp;the&nbsp;
    </div>
    <div>
      editor&nbsp;window&nbsp;will&nbsp;be&nbsp;shown.&nbsp;The&nbsp;command&nbsp;has&nbsp;now&nbsp;been&nbsp;inserted&nbsp;at&nbsp;the&nbsp;position
    </div>
    <div>where&nbsp;you&nbsp;choosed&nbsp;to&nbsp;open&nbsp;the&nbsp;help.</div>
    <br />
    <div>
      In&nbsp;the&nbsp;menu:&nbsp;Output&nbsp;you&nbsp;can&nbsp;select&nbsp;where&nbsp;the&nbsp;output&nbsp;should&nbsp;go&nbsp;to.&nbsp;When&nbsp;writing&nbsp;
    </div>
    <div>
      scripts,&nbsp;that&nbsp;means&nbsp;you&nbsp;can&nbsp;open&nbsp;the&nbsp;help&nbsp;with&nbsp;F1,&nbsp;select&nbsp;Terminal&nbsp;as&nbsp;output&nbsp;and
    </div>
    <div>
      try&nbsp;the&nbsp;command.&nbsp;If&nbsp;the&nbsp;result&nbsp;is&nbsp;OK,&nbsp;you&nbsp;open&nbsp;the&nbsp;help&nbsp;again&nbsp;(F1),&nbsp;select&nbsp;Editor
    </div>
    <div>
      as&nbsp;output&nbsp;and&nbsp;press&nbsp;enter&nbsp;to&nbsp;insert&nbsp;the&nbsp;command&nbsp;into&nbsp;the&nbsp;editors&nbsp;text.
    </div>
    <br />
    <div></div>
    <br />
    <div>*&nbsp;&nbsp;About&nbsp;the&nbsp;help</div>
    <br />
    <br />
    <br />
    <h2>TetraOM command line parameters</h2>
    <br />
    <div>You&nbsp;can&nbsp;call&nbsp;the&nbsp;program&nbsp;with&nbsp;these&nbsp;3&nbsp;parameters:</div>
    <br />
    <div>/CONFIGPATH=path</div>
    <div>/HELPPATH=path</div>
    <div>/STARTUPPATH=path</div>
    <br />
    <br />
    <div>CONFIGPATH</div>
    <br />
    <div>
      With&nbsp;/CONFIGPATH&nbsp;you&nbsp;tell&nbsp;the&nbsp;program&nbsp;where&nbsp;to&nbsp;look&nbsp;after&nbsp;the&nbsp;settings.wom&nbsp;file
    </div>
    <div>which&nbsp;contains&nbsp;the&nbsp;programs&nbsp;settings.</div>
    <br />
    <div>F.x.:&nbsp;/CONFIGPATH=c:\TetraOM\Settings\Admin</div>
    <br />
    <br />
    <div>HELPPATH</div>
    <br />
    <div>
      With&nbsp;/HELPPATH&nbsp;you&nbsp;tell&nbsp;the&nbsp;program&nbsp;where&nbsp;to&nbsp;look&nbsp;after&nbsp;the&nbsp;help&nbsp;files.
    </div>
    <br />
    <div>F.x.:&nbsp;/HELPPATH=c:\TetraOM\HelpFiles</div>
    <br />
    <br />
    <div>STARTUPPATH</div>
    <br />
    <div>With&nbsp;/STARTUPPATH&nbsp;you&nbsp;can&nbsp;make&nbsp;the&nbsp;program&nbsp;change&nbsp;directory.</div>
    <br />
    <div>F.x.:&nbsp;/STARTUPPATH=c:\MyWorkingDir</div>
    <br />
    <br />
    <div>
      You&nbsp;can&nbsp;use&nbsp;1,2&nbsp;or&nbsp;all&nbsp;of&nbsp;the&nbsp;parameters&nbsp;here,&nbsp;but&nbsp;you&nbsp;dont&nbsp;have&nbsp;to&nbsp;use&nbsp;any&nbsp;of&nbsp;the
    </div>
    <div>
      parameters&nbsp;listed&nbsp;here&nbsp;to&nbsp;make&nbsp;the&nbsp;program&nbsp;run&nbsp;proberly.&nbsp;If&nbsp;no&nbsp;parameters&nbsp;are
    </div>
    <div>
      given&nbsp;to&nbsp;the&nbsp;program,&nbsp;it&nbsp;will&nbsp;simply&nbsp;try&nbsp;to&nbsp;find&nbsp;the&nbsp;configuration&nbsp;file&nbsp;and&nbsp;the
    </div>
    <div>help&nbsp;files&nbsp;in&nbsp;the&nbsp;directory&nbsp;where&nbsp;the&nbsp;exe-file&nbsp;is&nbsp;located.</div>
    <br />
    <div>*&nbsp;&nbsp;Command&nbsp;line&nbsp;parameters</div>
    <br />
    <h2>Commands</h2>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>C/...&nbsp;&nbsp;Continuous&nbsp;command</strong>
      </div>
      <div>Command&nbsp;send&nbsp;continuously&nbsp;until&nbsp;any&nbsp;key&nbsp;is&nbsp;pressed</div>
      <div>Screen&nbsp;is&nbsp;scrolled</div>
      <div>&nbsp;&nbsp;┌&nbsp;Command&nbsp;send&nbsp;continuously</div>
      <div>&nbsp;&nbsp;┴───────────────────────────────────────────────────────────</div>
      <div>C/____________________________________________________________</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>R/...&nbsp;&nbsp;Repeat&nbsp;command</strong>
      </div>
      <div>Command&nbsp;repeated&nbsp;until&nbsp;any&nbsp;key&nbsp;is&nbsp;pressed</div>
      <div>Screen&nbsp;is&nbsp;not&nbsp;scrolled</div>
      <div>&nbsp;&nbsp;┌&nbsp;Command&nbsp;to&nbsp;repeat</div>
      <div>&nbsp;&nbsp;┴───────────────────────────────────────────────────────────</div>
      <div>R/____________________________________________________________</div>
      <div />
    </div>
    <br />
    <div>
      <cmd1>
        |
        <cmd2>
          |...|
          <cmdn>&nbsp;&nbsp;Multicommand</cmdn>
        </cmd2>
      </cmd1>
    </div>
    <div>All&nbsp;commands&nbsp;are&nbsp;send&nbsp;sequentially</div>
    <div>
      C/
      <multicmd>
        &nbsp;and&nbsp;R/
        <multicmd>&nbsp;will&nbsp;continuously&nbsp;repeat&nbsp;the&nbsp;whole&nbsp;sequence</multicmd>
      </multicmd>
    </div>
    <div>until&nbsp;any&nbsp;key&nbsp;is&nbsp;pressed</div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!WAIT(hh:mm)&nbsp;&nbsp;Wait&nbsp;until&nbsp;a&nbsp;specific&nbsp;time</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Hour&nbsp;(00..23)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Minute&nbsp;(00..59)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─</div>
      <div>!WAIT(__:__)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          !DELAY(delay)&nbsp;&nbsp;Insert&nbsp;a&nbsp;delay,&nbsp;in&nbsp;steps&nbsp;of&nbsp;1/10&nbsp;of&nbsp;a&nbsp;second
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Delay-time&nbsp;in&nbsp;steps&nbsp;of&nbsp;1/10&nbsp;of&nbsp;a&nbsp;second
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>!DELAY(_____)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!KEY&nbsp;&nbsp;Wait&nbsp;for&nbsp;keypress.</strong>
      </div>
      <div>!KEY</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          !ECHO(comments)&nbsp;&nbsp;Comments&nbsp;to&nbsp;be&nbsp;displayed&nbsp;with&nbsp;highlighted&nbsp;text.
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Comments&nbsp;to&nbsp;be&nbsp;highlighted</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────────────────────────────────</div>
      <div>!ECHO(_____________________________________________________________)</div>
      <div />
    </div>
    <h2>Logging</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!LOG&nbsp;&nbsp;Show&nbsp;logging&nbsp;settings</strong>
      </div>
      <div>!LOG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!LOG(c,r,a,filename)&nbsp;&nbsp;Open&nbsp;or&nbsp;create&nbsp;logfile.&nbsp;</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Terminal&nbsp;command&nbsp;logging&nbsp;('Y','N')</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Terminal&nbsp;response&nbsp;logging&nbsp;('Y','N')</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;Append&nbsp;to&nbsp;existing&nbsp;file&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;name&nbsp;of&nbsp;logfile.&nbsp;May&nbsp;include&nbsp;complete&nbsp;path.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴───────────────────────────────────────</div>
      <div>!LOG(_,_,_,________________________________________)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          !LOG(CREATE,filename)&nbsp;&nbsp;Create&nbsp;and&nbsp;open&nbsp;logfile.&nbsp;Empty&nbsp;if&nbsp;already&nbsp;exists.
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;logfile.&nbsp;May&nbsp;include&nbsp;complete&nbsp;path.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────────</div>
      <div>!LOG(CREATE,______________________________)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!LOG(OPEN,filename)&nbsp;&nbsp;Open&nbsp;logfile.&nbsp;Append&nbsp;if&nbsp;exists.</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;logfile.&nbsp;May&nbsp;include&nbsp;complete&nbsp;path.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────────</div>
      <div>!LOG(OPEN,______________________________)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!LOG(CLOSE)&nbsp;&nbsp;Close&nbsp;logfile</strong>
      </div>
      <div>!LOG(CLOSE)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!LOG(SET,t,t,s,s,d,d)&nbsp;Enable/disable&nbsp;command/response&nbsp;logging</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Terminal&nbsp;command&nbsp;logging&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Terminal&nbsp;response&nbsp;logging&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;Script&nbsp;command&nbsp;logging&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;Script&nbsp;response&nbsp;logging&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;Device&nbsp;command&nbsp;logging&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;Device&nbsp;response&nbsp;logging&nbsp;('Y','N')
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴</div>
      <div>!LOG(SET,_,_,_,_,_,_)</div>
      <div />
    </div>
    <h2>Options</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!OPT&nbsp;&nbsp;Show&nbsp;options&nbsp;settings</strong>
      </div>
      <div>!OPT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!OPT(TIMEOUT,seconds)&nbsp;&nbsp;Set&nbsp;timeout&nbsp;in&nbsp;seconds</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Timeout&nbsp;in&nbsp;seconds
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>!OPT(TIMEOUT,__)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!OPT(LINEDELAY,ms)&nbsp;&nbsp;Set&nbsp;linedelay&nbsp;in&nbsp;milliseconds</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Linedelay&nbsp;in&nbsp;milliseconds&nbsp;(0..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>!OPT(LINEDELAY,____)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!OPT(ECHO,s,s,d,d)&nbsp;&nbsp;Enable/disable&nbsp;echoing&nbsp;in&nbsp;terminal</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Echo&nbsp;script&nbsp;commands&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Echo&nbsp;script&nbsp;responses&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;Echo&nbsp;device&nbsp;commands&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;┌&nbsp;Echo&nbsp;device&nbsp;responses&nbsp;('Y','N')
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴&nbsp;┴</div>
      <div>!LOG(ECHO,_,_,_,_)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!OPT(TERMWIN,xxx,yyy)&nbsp;&nbsp;Set&nbsp;terminal&nbsp;window&nbsp;size</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;X-size&nbsp;(80..160)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Y-size&nbsp;(25..100)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──&nbsp;┴──</div>
      <div>!OPT(TERMWIN,___,___)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!OPT(TERMFONTSIZE,s)&nbsp;&nbsp;Set&nbsp;terminal&nbsp;font&nbsp;size</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Size&nbsp;of&nbsp;font&nbsp;(0..4)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴
      </div>
      <div>!OPT(TERMFONTSIZE,_)</div>
      <div />
    </div>
    <h2>Connections</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!CONN&nbsp;&nbsp;Show&nbsp;number&nbsp;of&nbsp;items&nbsp;in&nbsp;connection&nbsp;list</strong>
      </div>
      <div>!CONN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!CONN(D,co,ba,a,n)&nbsp;&nbsp;Direct</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;COM&nbsp;port&nbsp;(1..18)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Baud&nbsp;speed&nbsp;(03,06,12,24,48,96,19,38,57,11)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Send&nbsp;A900+&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Name&nbsp;of&nbsp;connection
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;┴&nbsp;┴─────────────────────────────</div>
      <div>!CONN(D,__,__,_,______________________________)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!CONN(M,co,ba,a,n)&nbsp;&nbsp;Direct&nbsp;modem</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;COM&nbsp;port&nbsp;(1..18)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Baud&nbsp;speed&nbsp;(03,06,12,24,48,96,19,38,57,11)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Send&nbsp;A900+&nbsp;('Y','N')&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Name&nbsp;of&nbsp;connection
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;┴&nbsp;┴─────────────────────────────</div>
      <div>!CONN(M,__,__,_,______________________________)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!CONN(U,co,ba,ph,da,dti,rw,ati,a,n)&nbsp;&nbsp;Dialup&nbsp;modem</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;COM&nbsp;port&nbsp;(1..18)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Baud&nbsp;speed&nbsp;(03,06,12,24,48,96,19,38,57,11)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Phonenumber</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Dial&nbsp;attempts
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Dial&nbsp;timeout
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Retry&nbsp;wait
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Answer&nbsp;timeout
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Send&nbsp;A900+&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Name&nbsp;of&nbsp;connection
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;┴───────────────────&nbsp;┴─&nbsp;┴──&nbsp;┴─&nbsp;┴──&nbsp;┴&nbsp;┴────────────────────
      </div>
      <div>!CONN(U,__,__,____________________,__,___,__,___,_,_____________________)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!CONN(N,ip,a,n)&nbsp;&nbsp;Network</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(xxx.xxx.xxx.xxx)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Send&nbsp;A900+&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Name&nbsp;of&nbsp;connection
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴&nbsp;┴─────────────────────────────
      </div>
      <div>!CONN(N,___.___.___.___,_,______________________________)</div>
      <div />
    </div>
    <h2>Connect</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!CONNECT(xxx.xxx.xxx.xxx)&nbsp;Connects&nbsp;to&nbsp;a&nbsp;new&nbsp;BSC</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;address&nbsp;(xxx.xxx.xxx.xxx)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>!CONNECT(___.___.___.___)</div>
      <div />
    </div>
    <h2>Disconnect</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!DISCONNECT&nbsp;Disconnects&nbsp;from&nbsp;the&nbsp;current&nbsp;BSC</strong>
      </div>
      <div>!DISCONNECT</div>
      <div />
    </div>
    <h2>Fileview settings</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          !FILEVIEW&nbsp;&nbsp;Show&nbsp;number&nbsp;of&nbsp;items&nbsp;in&nbsp;the&nbsp;file&nbsp;view&nbsp;list
        </strong>
      </div>
      <div>!FILEVIEW</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>!FILEVIEW(name,teof,file)&nbsp;&nbsp;Insert&nbsp;item&nbsp;in&nbsp;file&nbsp;view&nbsp;list</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;(fx.:&nbsp;Config)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Track&nbsp;End-Of-File&nbsp;('Y','N')
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Filename&nbsp;(fx.:&nbsp;c:\config.sys)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────&nbsp;┴&nbsp;┴───────────────────────────────
      </div>
      <div>!FILEVIEW(____________________,_,________________________________)</div>
      <div />
    </div>
    <h2>Editor commands</h2>
    <br />
    <div>EDITING&nbsp;COMMANDS</div>
    <br />
    <div>
      The&nbsp;editing&nbsp;commands&nbsp;supported&nbsp;by&nbsp;the&nbsp;editor&nbsp;are&nbsp;arranged&nbsp;by&nbsp;category&nbsp;in&nbsp;the
    </div>
    <div>
      list&nbsp;below.&nbsp;In&nbsp;each&nbsp;case&nbsp;the&nbsp;first&nbsp;column&nbsp;give&nbsp;a&nbsp;brief&nbsp;description&nbsp;of&nbsp;the
    </div>
    <div>
      command.&nbsp;The&nbsp;following&nbsp;column&nbsp;descripes&nbsp;the&nbsp;key(s)&nbsp;to&nbsp;wich&nbsp;a&nbsp;command&nbsp;is
    </div>
    <div>assigned.</div>
    <br />
    <br />
    <div>CURSER&nbsp;MOVEMENT:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;left&nbsp;one&nbsp;character&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Left&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;right&nbsp;one&nbsp;character&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Right&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;up&nbsp;one&nbsp;line&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Up&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;Down&nbsp;one&nbsp;line&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Down&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;left&nbsp;one&nbsp;word&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+Left&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;right&nbsp;one&nbsp;word&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+Right&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;to&nbsp;begining&nbsp;of&nbsp;line&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Home&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;to&nbsp;end&nbsp;of&nbsp;line&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; End&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;to&nbsp;top&nbsp;of&nbsp;edit&nbsp;window&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+PageUp&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;to&nbsp;end&nbsp;of&nbsp;edit&nbsp;window&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+PageDown&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;to&nbsp;beginning&nbsp;of&nbsp;file&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+Home&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Curser&nbsp;to&nbsp;end&nbsp;of&nbsp;file&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+End&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scroll&nbsp;display&nbsp;one&nbsp;page&nbsp;up&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; PageUp&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scroll&nbsp;display&nbsp;one&nbsp;page&nbsp;down&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; PageDown&gt;
    </div>
    <br />
    <div>INSERTION&nbsp;AND&nbsp;DELETION:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delete&nbsp;character&nbsp;at&nbsp;cursor&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <del />
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delete&nbsp;character&nbsp;to&nbsp;left&nbsp;of&nbsp;cursor&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <bksp />
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Start&nbsp;a&nbsp;new&nbsp;line&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Enter&gt;
    </div>
    <br />
    <div>BLOCK&nbsp;COMMANDS:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mark&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Shift&gt;&nbsp;+&nbsp;cursor&nbsp;movemen t
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unmark&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cursor&nbsp;movement
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copy&nbsp;marked&nbsp;block&nbsp;to&nbsp;clipboard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+C&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cut&nbsp;marked&nbsp;block&nbsp;to&nbsp;clipboard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+X&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insert&nbsp;block&nbsp;from&nbsp;clipboard&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl+V&gt;
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delete&nbsp;marked&nbsp;block&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <del />
    </div>
    <br />
    <div>SEARCH&nbsp;AND&nbsp;REPLACE:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Search&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Alt+S&gt;+
      <f />
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Search&nbsp;and&nbsp;replace&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Alt+S&gt;+
      <r />
    </div>
    <br />
    <div>MODE&nbsp;TOGGLES:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toggle&nbsp;insert&nbsp;mode&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <ins />
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toggle&nbsp;Upper/Lower&nbsp;case&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Caps&nbsp;Lock&gt;
    </div>
    <br />
    <div>UNDO:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Undo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &lt; Ctrl-Z&gt;
    </div>
    <br />
    <div>*&nbsp;&nbsp;Editor&nbsp;commands</div>
    <br />
    <h2>SET command</h2>
    <br />
    <br />
    <div>
      The&nbsp;SET&nbsp;command&nbsp;creates&nbsp;variables&nbsp;in&nbsp;a&nbsp;seperate&nbsp;array.&nbsp;You&nbsp;can&nbsp;only&nbsp;use&nbsp;the&nbsp;
    </div>
    <div>
      variables&nbsp;in&nbsp;scripts.&nbsp;The&nbsp;variables&nbsp;can&nbsp;have&nbsp;the&nbsp;same&nbsp;names&nbsp;as&nbsp;variables&nbsp;used&nbsp;in
    </div>
    <div>the&nbsp;':FOR'&nbsp;/&nbsp;':NEXT'&nbsp;commands.</div>
    <br />
    <div>
      The&nbsp;SET-variables&nbsp;are&nbsp;case&nbsp;sensitive,&nbsp;this&nbsp;means&nbsp;that&nbsp;'thisvar'&nbsp;and&nbsp;'ThisVar'&nbsp;are
    </div>
    <div>two&nbsp;different&nbsp;variables.</div>
    <br />
    <div>
      You&nbsp;can&nbsp;use&nbsp;hexadecimal&nbsp;numbers&nbsp;by&nbsp;inserting&nbsp;a&nbsp;'$'&nbsp;in&nbsp;front&nbsp;of&nbsp;the&nbsp;value.
    </div>
    <br />
    <div>Creating&nbsp;variables:</div>
    <br />
    <div>x)&nbsp;:SET(y,1)&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;y&nbsp;with&nbsp;a&nbsp;value&nbsp;of&nbsp;1</div>
    <div>x)&nbsp;:SET(y,$A)&nbsp;&nbsp;&nbsp;-&nbsp;y&nbsp;with&nbsp;a&nbsp;value&nbsp;of&nbsp;10</div>
    <div>x)&nbsp;:SET(y,=2)&nbsp;&nbsp;&nbsp;-&nbsp;y&nbsp;with&nbsp;a&nbsp;value&nbsp;of&nbsp;2</div>
    <div>x)&nbsp;:SET(y,=$AA)&nbsp;-&nbsp;y&nbsp;with&nbsp;a&nbsp;value&nbsp;of&nbsp;170</div>
    <br />
    <div>
      You&nbsp;can&nbsp;change&nbsp;the&nbsp;value&nbsp;of&nbsp;the&nbsp;variables&nbsp;using&nbsp;the&nbsp;'+','-'&nbsp;and&nbsp;'='&nbsp;signs&nbsp;in&nbsp;a
    </div>
    <div>:SET-command.</div>
    <br />
    <div>x)&nbsp;:SET(y,+10)&nbsp;&nbsp;-&nbsp;add&nbsp;10&nbsp;to&nbsp;the&nbsp;value&nbsp;of&nbsp;y.</div>
    <div>x)&nbsp;:SET(y,-$10)&nbsp;-&nbsp;subtract&nbsp;16&nbsp;from&nbsp;the&nbsp;value&nbsp;of&nbsp;y.</div>
    <div>x)&nbsp;:SET(y,=10)&nbsp;&nbsp;-&nbsp;set&nbsp;y-varaible&nbsp;to&nbsp;10.</div>
    <br />
    <br />
    <div>
      You&nbsp;use&nbsp;variables&nbsp;by&nbsp;inserting&nbsp;them&nbsp;in&nbsp;a&nbsp;pair&nbsp;of&nbsp;'%'-signs:
    </div>
    <br />
    <div>:SET(x,=10)</div>
    <div>%x%</div>
    <br />
    <div>
      the&nbsp;output&nbsp;of&nbsp;the&nbsp;second&nbsp;line&nbsp;is&nbsp;'10'.&nbsp;If&nbsp;the&nbsp;output&nbsp;should&nbsp;be&nbsp;hexadecimal&nbsp;you&nbsp;
    </div>
    <div>can&nbsp;insert&nbsp;a&nbsp;'$'.&nbsp;&nbsp;1%$x%&nbsp;will&nbsp;output:&nbsp;&nbsp;'1A'.</div>
    <br />
    <div>You&nbsp;can&nbsp;left&nbsp;pad.&nbsp;1%$x,3%&nbsp;will&nbsp;output:&nbsp;'100A'.</div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:SET(var,value)&nbsp;&nbsp;Set&nbsp;variable&nbsp;value</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;variable</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Value</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴──────────&nbsp;</div>
      <div>:SET(________,___________)</div>
      <div />
    </div>
    <h2>For - Next Loops</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          :FOR(var,start,end)&nbsp;&nbsp;Define&nbsp;the&nbsp;start&nbsp;of&nbsp;a&nbsp;FOR/NEXT&nbsp;loop.
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;counter&nbsp;variable</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Start&nbsp;value</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;End&nbsp;value
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴────&nbsp;┴────</div>
      <div>:FOR(________,_____,_____)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          :FOR(var,start,end,stepsize)&nbsp;&nbsp;Define&nbsp;the&nbsp;start&nbsp;of&nbsp;a&nbsp;FOR/NEXT&nbsp;loop.
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;counter&nbsp;variable</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Start&nbsp;value</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;End&nbsp;value
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Steps&nbsp;for&nbsp;counting&nbsp;from&nbsp;
        <start>
          &nbsp;to&nbsp;
          <end />
        </start>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴────&nbsp;┴────&nbsp;┴────</div>
      <div>:FOR(________,_____,_____,_____)</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:NEXT(var)&nbsp;&nbsp;Repeat&nbsp;or&nbsp;end&nbsp;last&nbsp;defined&nbsp;FOR&nbsp;loop</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;counter&nbsp;variable</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>:NEXT(________)</div>
      <div />
      <div />
    </div>
    <h2>Labels, jumps, conditional jumps.</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:LABEL(labelname)&nbsp;&nbsp;Set&nbsp;destination&nbsp;of&nbsp;jumps.</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;label</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>:LABEL(________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:GOTO(labelname)&nbsp;&nbsp;Continue&nbsp;processing&nbsp;at&nbsp;a&nbsp;label.</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;label&nbsp;to&nbsp;jump&nbsp;to.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>:GOTO(________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          :IFNORESP(nextlabel)&nbsp;&nbsp;Jump,&nbsp;if&nbsp;no&nbsp;response&nbsp;on&nbsp;communication-port.
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Label&nbsp;to&nbsp;continue&nbsp;at,&nbsp;if&nbsp;a&nbsp;"NO&nbsp;xx&nbsp;RESPONSE"&nbsp;was&nbsp;received&nbsp;on&nbsp;the
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;communication&nbsp;port.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>:IFNORESP(________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          :IFTIMEOUT(nextlabel)&nbsp;&nbsp;Jump,&nbsp;if&nbsp;general&nbsp;fault,&nbsp;on&nbsp;communication-port.
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Label&nbsp;to&nbsp;continue&nbsp;at,&nbsp;if&nbsp;a&nbsp;timeout,&nbsp;or&nbsp;out&nbsp;fatal&nbsp;error
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;occoured&nbsp;on&nbsp;the&nbsp;communication&nbsp;port.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────</div>
      <div>:IFTIMEOUT(_________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:IFINVALID(nextlabel)&nbsp;&nbsp;Jump,&nbsp;if&nbsp;reponse&nbsp;contained&nbsp;'INVALID'</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Label&nbsp;to&nbsp;continue&nbsp;at,&nbsp;if&nbsp;the&nbsp;last&nbsp;received&nbsp;string&nbsp;contained
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;the&nbsp;substring&nbsp;'INVALID'.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────</div>
      <div>:IFINVALID(_________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          :IFEOD(nextlabel)&nbsp;&nbsp;Jump,&nbsp;if&nbsp;end&nbsp;of&nbsp;data-marker&nbsp;was&nbsp;read.
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Label&nbsp;to&nbsp;continue&nbsp;at,&nbsp;if&nbsp;the&nbsp;first&nbsp;three&nbsp;characters&nbsp;in&nbsp;the&nbsp;last
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;received&nbsp;response&nbsp;on&nbsp;the&nbsp;communication-port,&nbsp;was&nbsp;"***".
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>:IFEOD(________)</div>
      <div />
    </div>
    <h2>Special commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:TIMEOUT(time)&nbsp;&nbsp;Setup&nbsp;timeout&nbsp;time&nbsp;on&nbsp;RS232&nbsp;port</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Timeout&nbsp;in&nbsp;seconds.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>:TIMEOUT(__)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:EXECUTE(filename)&nbsp;&nbsp;Send&nbsp;file&nbsp;to&nbsp;port.</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;file&nbsp;to&nbsp;send&nbsp;to&nbsp;port,&nbsp;line&nbsp;by&nbsp;line.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────</div>
      <div>:EXECUTE(__________________________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:INCLUDE(filename)&nbsp;&nbsp;Include&nbsp;file&nbsp;into&nbsp;command-list.</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Name&nbsp;of&nbsp;file&nbsp;to&nbsp;include&nbsp;in&nbsp;command-list.&nbsp;All&nbsp;command&nbsp;will&nbsp;be
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;interpreted&nbsp;as&nbsp;if&nbsp;they&nbsp;where&nbsp;located&nbsp;within&nbsp;the&nbsp;editor.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────────────────────</div>
      <div>:INCLUDE(_________________________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>:SEND(....)&nbsp;&nbsp;Send&nbsp;command&nbsp;containing&nbsp;variables.</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Commands&nbsp;to&nbsp;send.&nbsp;Variables&nbsp;from&nbsp;:FOR-statements&nbsp;must&nbsp;be&nbsp;specified
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;as&nbsp;%Name,Length%&nbsp;where&nbsp;Name&nbsp;is&nbsp;the&nbsp;variable&nbsp;identifier,&nbsp;and&nbsp;Length
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;is&nbsp;the&nbsp;lenght&nbsp;of&nbsp;the&nbsp;desired&nbsp;string,&nbsp;to&nbsp;be&nbsp;padded&nbsp;with&nbsp;leading&nbsp;zeros
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────────────────────────────────────────</div>
      <div>:SEND(___________________________________________________)</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          <cmd>&nbsp;&nbsp;Send&nbsp;command</cmd>
        </strong>
      </div>
      <div>┌&nbsp;Command&nbsp;to&nbsp;send.</div>
      <div>
        │&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Optional&nbsp;remarks&nbsp;can&nbsp;be&nbsp;entered
      </div>
      <div>
        │&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;after&nbsp;a&nbsp;";"
      </div>
      <div>┴──────────────────────────────────&nbsp;&nbsp;&nbsp;┴────────────────────────</div>
      <div>___________________________________&nbsp;;&nbsp;_________________________</div>
      <div />
    </div>
    <br />
  </>
);

export default memo(OMPage) as typeof OMPage;
