import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import time, { formatDate } from 'src/helpers/time';
import getLogServersQuery from 'src/graphql/queries/get-log-servers.graphql';
import logServerCreatedSubscription from 'src/graphql/subscriptions/log-server-created.graphql';
import logServerChangedSubscription from 'src/graphql/subscriptions/log-server-changed.graphql';
import { Title } from 'src/components/ui-kit/typography';
import Table from 'src/components/ui-kit/table';
import { LogServer } from 'src/graphql/generated';

const Container = styled.div`
  padding-bottom: 3rem;
`;

const LogServers: FC = () => {
  const { t } = useTranslation();
  //
  return (
    <Container>
      <Title level={4}>{t('Log Servers')}</Title>
      <Table
        pagination
        pageSize={40}
        dataType="LogServer"
        query={getLogServersQuery}
        variables={{}}
        subscriptionQueries={[logServerCreatedSubscription, logServerChangedSubscription]}
        rowKey="id"
        columns={[
          {
            title: t('Node'),
            dataIndex: 'nodeNumber',
            width: 60,
          },
          {
            title: t('Description'),
            dataIndex: 'description',
            render(userAgent: LogServer['description']) {
              return userAgent || 'Не указано';
            },
          },
          {
            title: t('Version'),
            dataIndex: 'serverVersion',
          },
          {
            title: t('Last Update'),
            dataIndex: 'updateTime',
            render(_: unknown, { updateTime }: LogServer) {
              return formatDate(updateTime, 'D MMMM YYYY, HH:mm:ss');
            },
          },
        ]}
      />
    </Container>
  );
};
LogServers.propTypes = {};

export default memo(LogServers);
