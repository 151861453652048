// React
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
//
import getNodeStatusListQuery from 'src/graphql/queries/get-nodes-statuses.graphql';
import nodeStatusCreatedSubscription from 'src/graphql/subscriptions/node-status-created.graphql';
import nodeStatusChangedSubscription from 'src/graphql/subscriptions/node-status-changed.graphql';
//
import Table from 'src/components/ui-kit/table';
import Status from 'src/components/ui-kit/project-specific/online-status-dot';

import { renderConfigured, styleConfigured, statusObjs } from 'src/helpers/tetra';

const NodeStatus = styled.div`
  display: flex;
  align-items: center;
`;

const NodeCell = styled.span`
  color: ${props => `${props.status.color}`};
`;

const PdGWTab = () => {
  const { t } = useTranslation();
  return (
    <Table
      pagination
      pageSize={40}
      dataType="NodeStatus"
      query={getNodeStatusListQuery}
      variables={{ filter: { tab: 'pd-gw' } }}
      sorter={{ columnKey: 'NodeNo', order: 'ascend' }}
      subscriptionQueries={[nodeStatusCreatedSubscription, nodeStatusChangedSubscription]}
      rowKey="number"
      columns={[
        {
          title: t('Node'),
          dataIndex: 'number',
          width: 180,
          render(_, { number, bsc, alarmState }) {
            return (
              <NodeStatus>
                <Status state={alarmState} />
                <span>{`${String(number).padStart(3, '0')} [BSC${bsc + 1}]`}</span>
              </NodeStatus>
            );
          },
        },
        {
          title: t('Description column'),
          dataIndex: 'description',
          render(userAgent) {
            return userAgent || 'Не указано';
          },
        },
        {
          title: t('Config'),
          dataIndex: 'pdGwAlarmState',
          onCell: styleConfigured(null, 'pdGwAlarmState'),
          render: renderConfigured((_, { pdGwAlarmState: state }) => (state >= 255 ? 'No' : 'Yes')),
        },
        {
          title: t('Status'),
          dataIndex: 'pdGwAlarmState',
          onCell: styleConfigured(({ pdGwAlarmState: state }) => {
            const { background } = statusObjs[state];
            return { background };
          }, 'pdGwAlarmState'),
          render: renderConfigured((_, { pdGwAlarmState: state }) => {
            const status = statusObjs[state];
            return <NodeCell status={status}>{status.label}</NodeCell>;
          }),
        },
      ]}
    />
  );
};

export default PdGWTab;
