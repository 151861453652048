import compactArray from 'lodash/compact';
import React, { memo, useContext } from 'react';
import { ViewerContext, Link } from '@fjedi/react-router-helpers';
import PropTypes from 'prop-types';
import { withProps, compose, getContext } from 'recompose';
import styled from 'styled-components';

// Inner components
import { Breadcrumb } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import UserProfile from 'src/components/common/user-profile-widget';
import Notifications from 'src/components/common/notifications-center';

const Header = styled.header`
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.86);
  // background-color: rgb(38, 153, 251);
  background-color: ${props => props.theme.token.colorPrimary};
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: #fff;

  .firstRow {
    display: flex;
    align-items: center;
    height: 64px;
    //background: #fff;
    //box-shadow: 0 1px 4px rgba(0,21,41,.08);
    position: relative;
    padding: 0 1rem 0 0;

    .leftColumn {
      flex-grow: 1;
      display: flex;
      align-items: center;

      h1 {
        color: #fff;
        font-size: 1.4rem;
        margin-left: 1rem;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    .rightColumn {
      float: right;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .secondRow {
    border: 0;
    border-radius: 0;
    padding: 0 16px 0.5rem;
    //background-color: #fff;
  }
`;
const MenuFoldIcon = styled(MenuFoldOutlined)`
  &.anticon {
    font-size: 1.2rem;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
`;
const MenuUnfoldIcon = styled(MenuUnfoldOutlined)`
  &.anticon {
    font-size: 1.2rem;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
`;

const HeaderComponent = props => {
  const { toggleSider, siderCollapsed, breadcrumbItems } = props;
  const viewer = useContext(ViewerContext);
  if (!viewer) {
    return null;
  }

  return (
    <Header>
      <div className="firstRow">
        <div className="leftColumn">
          {siderCollapsed ? <MenuFoldIcon onClick={toggleSider} /> : <MenuUnfoldIcon onClick={toggleSider} />}
          <h1>АО «РадиоТел-СПб»</h1>
        </div>
        <div className="rightColumn">
          <Notifications />
          <UserProfile />
        </div>
      </div>
    </Header>
  );
};

HeaderComponent.propTypes = {
  siderCollapsed: PropTypes.bool.isRequired,
  toggleSider: PropTypes.func.isRequired,
};
HeaderComponent.defaultProps = {};

export default compose(
  getContext({
    currentPageBreadcrumb: PropTypes.object,
    breadcrumbNameMap: PropTypes.object.isRequired,
  }),
  // withProps(({ location, breadcrumbNameMap, currentPageBreadcrumb }) => {
  //   const pathSnippets = location.pathname.split('/').filter(i => i);
  //   const lastBreadcrumb = currentPageBreadcrumb ? (
  //     <Breadcrumb.Item key={currentPageBreadcrumb.url}>
  //       <Link to={currentPageBreadcrumb.url}>{currentPageBreadcrumb.title}</Link>
  //     </Breadcrumb.Item>
  //   ) : null;
  //   const breadcrumbItems = compactArray(
  //     pathSnippets
  //       .map((_, index) => {
  //         const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
  //         if (url === '/' || !breadcrumbNameMap.has(url)) {
  //           return null;
  //         }
  //         return (
  //           <Breadcrumb.Item key={url}>
  //             <Link to={url}>{breadcrumbNameMap.get(url)}</Link>
  //           </Breadcrumb.Item>
  //         );
  //       })
  //       .concat([lastBreadcrumb]),
  //   );
  //   return {
  //     breadcrumbItems,
  //   };
  // }),
  memo,
)(HeaderComponent);
