import React, { FC, memo } from 'react';

const TR421Page: FC = () => (
  <>
    <h1>TR421 Transceiver ver. 7.82</h1>
    <br />
    <br />
    <h2>Functional Description:</h2>
    <br />
    <div>FUNCTIONAL&nbsp;DESCRIPTION</div>
    <br />
    <div>All&nbsp;test&nbsp;commands&nbsp;for&nbsp;the&nbsp;TR421&nbsp;Transceiver&nbsp;consist&nbsp;of&nbsp;a</div>
    <div>
      2-digit&nbsp;command&nbsp;in&nbsp;the&nbsp;range&nbsp;00&nbsp;to&nbsp;99&nbsp;with&nbsp;an&nbsp;optionally&nbsp;parameter.&nbsp;Commands
    </div>
    <div>
      without&nbsp;parameters&nbsp;are&nbsp;display-only&nbsp;commands&nbsp;and&nbsp;will&nbsp;not&nbsp;make&nbsp;any&nbsp;change
    </div>
    <div>in&nbsp;the&nbsp;TR.</div>
    <br />
    <div>
      The&nbsp;commands&nbsp;are&nbsp;divided&nbsp;into&nbsp;groups,&nbsp;covering&nbsp;the&nbsp;different&nbsp;functions&nbsp;in
    </div>
    <div>
      the&nbsp;TR.&nbsp;For&nbsp;example,&nbsp;all&nbsp;the&nbsp;TX&nbsp;commands&nbsp;are&nbsp;in&nbsp;the&nbsp;10&nbsp;to&nbsp;19&nbsp;group.
    </div>
    <br />
    <div>
      If&nbsp;the&nbsp;first&nbsp;two&nbsp;characters&nbsp;are&nbsp;not&nbsp;representing&nbsp;a&nbsp;valid&nbsp;number&nbsp;in&nbsp;the&nbsp;00&nbsp;to
    </div>
    <div>99&nbsp;range,&nbsp;an&nbsp;error&nbsp;message&nbsp;will&nbsp;be&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;TR421&nbsp;command
    </div>
    <br />
    <div>
      If&nbsp;an&nbsp;unimplemented&nbsp;TR&nbsp;command&nbsp;is&nbsp;called,&nbsp;the&nbsp;following&nbsp;response&nbsp;is
    </div>
    <div>displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unimplemented&nbsp;TR421&nbsp;command
    </div>
    <br />
    <div>OPERATOR&nbsp;BLOCKING&nbsp;MODE</div>
    <br />
    <div>
      After&nbsp;power-on,&nbsp;the&nbsp;TR&nbsp;always&nbsp;starts&nbsp;in&nbsp;normal&nbsp;operating&nbsp;mode.&nbsp;If&nbsp;a&nbsp;given
    </div>
    <div>
      command&nbsp;requires&nbsp;operator&nbsp;blocking,&nbsp;it&nbsp;is&nbsp;shown&nbsp;in&nbsp;the&nbsp;help&nbsp;for&nbsp;the&nbsp;command.
    </div>
    <div>
      Commands&nbsp;that&nbsp;require&nbsp;operator&nbsp;blocking&nbsp;are&nbsp;not&nbsp;available&nbsp;during&nbsp;normal
    </div>
    <div>
      operation&nbsp;for&nbsp;operator&nbsp;interference.&nbsp;If&nbsp;a&nbsp;protected&nbsp;command&nbsp;is&nbsp;called,
    </div>
    <div>the&nbsp;following&nbsp;response&nbsp;is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Operator&nbsp;blocking&nbsp;necessary
    </div>
    <br />
    <div>
      Only&nbsp;commands&nbsp;with&nbsp;a&nbsp;parameter&nbsp;are&nbsp;protected.&nbsp;After&nbsp;activation&nbsp;of
    </div>
    <div>
      operator&nbsp;blocking&nbsp;(01+),&nbsp;all&nbsp;commands&nbsp;except&nbsp;factory&nbsp;configuration&nbsp;commands
    </div>
    <div>are&nbsp;available.</div>
    <br />
    <br />
    <div>PARAMETERS</div>
    <br />
    <div>Two&nbsp;types&nbsp;of&nbsp;parameters&nbsp;are&nbsp;used.</div>
    <br />
    <div>
      Generally&nbsp;+&nbsp;is&nbsp;used&nbsp;as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;on,&nbsp;enable&nbsp;or&nbsp;step&nbsp;up&nbsp;and&nbsp;-&nbsp;is&nbsp;used
    </div>
    <div>as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;off,&nbsp;disable&nbsp;or&nbsp;step&nbsp;down.</div>
    <br />
    <div>
      For&nbsp;more&nbsp;complex&nbsp;functions,&nbsp;a&nbsp;/&nbsp;is&nbsp;used&nbsp;as&nbsp;separator&nbsp;after&nbsp;the&nbsp;command&nbsp;no.
    </div>
    <div>
      followed&nbsp;by&nbsp;the&nbsp;necessary&nbsp;no.&nbsp;of&nbsp;characters&nbsp;for&nbsp;the&nbsp;actual&nbsp;function.
    </div>
    <br />
    <div>
      If&nbsp;the&nbsp;parameter&nbsp;is&nbsp;not&nbsp;valid&nbsp;for&nbsp;the&nbsp;actual&nbsp;command,&nbsp;the&nbsp;following&nbsp;response
    </div>
    <div>is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;TR421&nbsp;parameter
    </div>
    <br />
    <div>CONFIGURATION</div>
    <br />
    <div>Many&nbsp;calibration&nbsp;and&nbsp;system&nbsp;parameters&nbsp;are&nbsp;stored&nbsp;residently.</div>
    <div>At&nbsp;power-up&nbsp;and&nbsp;reset&nbsp;all&nbsp;settings&nbsp;are&nbsp;copied&nbsp;into&nbsp;the</div>
    <div>
      workspace&nbsp;RAM,&nbsp;from&nbsp;where&nbsp;the&nbsp;different&nbsp;software&nbsp;tasks&nbsp;takes&nbsp;the&nbsp;settings.
    </div>
    <br />
    <div>
      Any&nbsp;change&nbsp;in&nbsp;the&nbsp;configuration&nbsp;is&nbsp;only&nbsp;made&nbsp;in&nbsp;the&nbsp;workspace&nbsp;RAM.&nbsp;To&nbsp;store
    </div>
    <div>
      the&nbsp;new&nbsp;configuration&nbsp;residently,&nbsp;a&nbsp;save&nbsp;command&nbsp;shall&nbsp;be&nbsp;made.
    </div>
    <br />
    <div>The&nbsp;configuration&nbsp;is&nbsp;divided&nbsp;into&nbsp;two&nbsp;separate&nbsp;entities:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;System&nbsp;configuration
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;Factory&nbsp;configuration
    </div>
    <br />
    <div>
      System&nbsp;configuration&nbsp;parameters&nbsp;can&nbsp;be&nbsp;changed&nbsp;with&nbsp;the&nbsp;7x&nbsp;commands.&nbsp;To&nbsp;save
    </div>
    <div>
      the&nbsp;system&nbsp;configuration&nbsp;resistently,&nbsp;the&nbsp;command&nbsp;"70/SAVE"&nbsp;shall&nbsp;be
    </div>
    <div>entered.</div>
    <br />
    <br />
    <div>
      Factory&nbsp;configuration&nbsp;parameters&nbsp;can&nbsp;be&nbsp;changed&nbsp;with&nbsp;the&nbsp;9x&nbsp;commands.&nbsp;To
    </div>
    <div>
      protect&nbsp;the&nbsp;operator&nbsp;from&nbsp;accidentially&nbsp;changing&nbsp;the&nbsp;factory&nbsp;configuration
    </div>
    <div>
      parameters,&nbsp;an&nbsp;access&nbsp;lock&nbsp;is&nbsp;implemented,&nbsp;giving&nbsp;the&nbsp;following&nbsp;message:
    </div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Factory&nbsp;configuration&nbsp;unlocking&nbsp;necessary
    </div>
    <br />
    <div>
      To&nbsp;get&nbsp;access&nbsp;to&nbsp;factory&nbsp;configuration&nbsp;changes,&nbsp;it&nbsp;is&nbsp;necessary&nbsp;to&nbsp;enter
    </div>
    <div>
      the&nbsp;command&nbsp;"90/FACTORYUNLOCK".&nbsp;Unlocking&nbsp;can&nbsp;be&nbsp;turned&nbsp;off&nbsp;again&nbsp;with&nbsp;the
    </div>
    <div>"90-"&nbsp;command.</div>
    <br />
    <div>
      To&nbsp;save&nbsp;the&nbsp;factory&nbsp;configuration,&nbsp;the&nbsp;command&nbsp;"90/SAVE"&nbsp;shall&nbsp;be&nbsp;entered.
    </div>
    <br />
    <div>
      The&nbsp;resident&nbsp;configuration&nbsp;is&nbsp;restored&nbsp;into&nbsp;the&nbsp;workspace&nbsp;RAM&nbsp;at&nbsp;software
    </div>
    <div>
      reset&nbsp;(02+)&nbsp;or&nbsp;local&nbsp;deblocking&nbsp;(01-)&nbsp;if&nbsp;re-booting&nbsp;take&nbsp;place.
    </div>
    <br />
    <div>*&nbsp;&nbsp;Functional&nbsp;description</div>
    <br />
    <div>LED&nbsp;description</div>
    <br />
    <div>The&nbsp;BS421&nbsp;has&nbsp;the&nbsp;following&nbsp;internal&nbsp;LEDs:</div>
    <br />
    <div>LED&nbsp;&nbsp;&nbsp;Color&nbsp;&nbsp;&nbsp;Marking&nbsp;&nbsp;Function</div>
    <div>----&nbsp;&nbsp;------&nbsp;&nbsp;-------&nbsp;&nbsp;--------------------</div>
    <div>D408&nbsp;&nbsp;Yellow&nbsp;&nbsp;Pwr&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Power</div>
    <div>D407&nbsp;&nbsp;Green&nbsp;&nbsp;&nbsp;TX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;keyed</div>
    <div>D406&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Oper&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Operator&nbsp;Blocking</div>
    <div>D405&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RFTL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RFTL&nbsp;activated</div>
    <div>
      D404&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;Alarm
    </div>
    <div>
      D403&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RX&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RX&nbsp;Alarm
    </div>
    <div>
      D402&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CU&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Control&nbsp;Unit&nbsp;Alarm
    </div>
    <div>D401&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DSP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DSP&nbsp;alarm</div>
    <div>D400&nbsp;&nbsp;Red&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sync&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sync&nbsp;Alarm</div>
    <br />
    <div>
      Individual&nbsp;alarm&nbsp;flags&nbsp;can&nbsp;be&nbsp;displayed&nbsp;with&nbsp;the&nbsp;03&nbsp;commands.
    </div>
    <br />
    <div>*&nbsp;&nbsp;LED&nbsp;description</div>
    <br />
    <br />
    <div>Boot&nbsp;Loader&nbsp;Reprogramming</div>
    <br />
    <div>The&nbsp;BS421&nbsp;contains&nbsp;a&nbsp;Boot&nbsp;Loader&nbsp;in&nbsp;the&nbsp;on-board&nbsp;Flash.</div>
    <div>
      The&nbsp;Boot&nbsp;Loader&nbsp;starts&nbsp;execution&nbsp;after&nbsp;Power-on&nbsp;or&nbsp;Hardware&nbsp;Reset.
    </div>
    <div>
      The&nbsp;Boot&nbsp;Loader&nbsp;reads&nbsp;the&nbsp;Windows&nbsp;CE&nbsp;operation&nbsp;system&nbsp;contained&nbsp;in&nbsp;the
    </div>
    <div>
      NK.bin&nbsp;file&nbsp;on&nbsp;the&nbsp;CF-Card&nbsp;into&nbsp;memory&nbsp;and&nbsp;starts&nbsp;its&nbsp;execution.
    </div>
    <div>
      From&nbsp;version&nbsp;7.30&nbsp;the&nbsp;version&nbsp;of&nbsp;the&nbsp;Boot&nbsp;Loader&nbsp;is&nbsp;shown&nbsp;at&nbsp;TR421&nbsp;startup
    </div>
    <div>and&nbsp;with&nbsp;the&nbsp;00/A&nbsp;command.</div>
    <div>
      From&nbsp;version&nbsp;7.30&nbsp;the&nbsp;Boot&nbsp;Loader&nbsp;can&nbsp;also&nbsp;be&nbsp;reprogrammed&nbsp;of&nbsp;TR421.exe.
    </div>
    <div>
      To&nbsp;update&nbsp;the&nbsp;Boot&nbsp;Loader,&nbsp;put&nbsp;the&nbsp;Boot.bin&nbsp;file&nbsp;in&nbsp;the&nbsp;root&nbsp;directory&nbsp;of
    </div>
    <div>the&nbsp;CF-card&nbsp;(together&nbsp;with&nbsp;NK.bin)&nbsp;and&nbsp;restart&nbsp;the&nbsp;TR421.</div>
    <div>
      The&nbsp;Boot&nbsp;Loader&nbsp;reprogramming&nbsp;will&nbsp;be&nbsp;shown&nbsp;on&nbsp;the&nbsp;screen&nbsp;at&nbsp;startup.
    </div>
    <div>
      After&nbsp;the&nbsp;update&nbsp;the&nbsp;Boot.bin&nbsp;file&nbsp;shall&nbsp;be&nbsp;removed&nbsp;again&nbsp;to&nbsp;prevent
    </div>
    <div>re-programming&nbsp;at&nbsp;every&nbsp;restart.</div>
    <div>
      Warning:&nbsp;Do&nbsp;not&nbsp;turn&nbsp;off&nbsp;power&nbsp;of&nbsp;BS421&nbsp;during&nbsp;update&nbsp;as&nbsp;this&nbsp;will
    </div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;corrupt&nbsp;the&nbsp;BS421&nbsp;Boot&nbsp;Loader!</div>
    <br />
    <div>*&nbsp;&nbsp;Boot&nbsp;Loader&nbsp;Reprogramming</div>
    <br />
    <br />
    <h2>Software Version:</h2>
    <br />
    <div>00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00&nbsp;&nbsp;Display&nbsp;software&nbsp;version</strong>
      </div>
      <div>00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/A&nbsp;&nbsp;Display&nbsp;all&nbsp;software&nbsp;version&nbsp;numbers</strong>
      </div>
      <div>00/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/OM&nbsp;&nbsp;Display&nbsp;OM&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>00/OM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/BSC&nbsp;&nbsp;Display&nbsp;BSC&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>00/BSC</div>
      <div />
      <div />
    </div>
    <h2>Operator Blocking:</h2>
    <br />
    <div>01&nbsp;&nbsp;OPERATOR&nbsp;BLOCKING/DEBLOCKING</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01&nbsp;&nbsp;Display&nbsp;Operator&nbsp;Blocking&nbsp;selection</strong>
      </div>
      <div>01</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01+&nbsp;&nbsp;Operator&nbsp;Blocking&nbsp;request</strong>
      </div>
      <div>01+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01-&nbsp;&nbsp;Operator&nbsp;Blocking&nbsp;off</strong>
      </div>
      <div>Makes&nbsp;a&nbsp;restart&nbsp;of&nbsp;the&nbsp;TR421&nbsp;service</div>
      <div>01-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/DEBUG&nbsp;&nbsp;Display&nbsp;Debug&nbsp;Commands&nbsp;Enable&nbsp;selection</strong>
      </div>
      <div>01/DEBUG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/DEBUG/+&nbsp;&nbsp;Enable&nbsp;Debug&nbsp;Commands</strong>
      </div>
      <div>NB:&nbsp;Should&nbsp;only&nbsp;be&nbsp;used&nbsp;of&nbsp;software&nbsp;developers!</div>
      <div>01/DEBUG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/DEBUG/-&nbsp;&nbsp;Disable&nbsp;Debug&nbsp;Commands</strong>
      </div>
      <div>01/DEBUG/-</div>
      <div />
      <div />
    </div>
    <h2>Operating Mode:</h2>
    <br />
    <div>02&nbsp;&nbsp;TR&nbsp;OPERATING&nbsp;MODE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02&nbsp;&nbsp;Display&nbsp;TR&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02/c&nbsp;&nbsp;Change&nbsp;TR&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Test</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;A:&nbsp;Analog</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;TETRA</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>02/_</div>
      <div />
      <div />
    </div>
    <h2>Alarms:</h2>
    <br />
    <div>03&nbsp;&nbsp;ALARMS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03&nbsp;&nbsp;Display&nbsp;Alarm&nbsp;State</strong>
      </div>
      <div>03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/A&nbsp;&nbsp;Display&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>03/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/E&nbsp;&nbsp;Display&nbsp;BSC&nbsp;External&nbsp;Alarms</strong>
      </div>
      <div>03/E</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/C&nbsp;&nbsp;Clear&nbsp;Alarms</strong>
      </div>
      <div>03/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/LED&nbsp;&nbsp;Display&nbsp;LED&nbsp;State</strong>
      </div>
      <div>03/LED</div>
      <div />
      <div />
    </div>
    <h2>BSC:</h2>
    <br />
    <div>05&nbsp;&nbsp;BSC</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>05&nbsp;&nbsp;Display&nbsp;BSC&nbsp;status/configuration</strong>
      </div>
      <div>05</div>
      <div />
      <div />
    </div>
    <h2>MAC Address:</h2>
    <br />
    <div>06&nbsp;&nbsp;NETWORK&nbsp;ADDRESSES</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06&nbsp;&nbsp;Display&nbsp;Network&nbsp;settings</strong>
      </div>
      <div>06</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/MAC&nbsp;&nbsp;Display&nbsp;MAC&nbsp;Address</strong>
      </div>
      <div>06/MAC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/SYNC&nbsp;&nbsp;Synchronize&nbsp;Flash&nbsp;and&nbsp;Registry&nbsp;Settings</strong>
      </div>
      <div>
        If&nbsp;Registry&nbsp;settings&nbsp;are&nbsp;valid,&nbsp;Flash&nbsp;is&nbsp;updated&nbsp;with&nbsp;Registry&nbsp;values,
      </div>
      <div>
        else&nbsp;Registry&nbsp;settings&nbsp;are&nbsp;created&nbsp;from&nbsp;Flash&nbsp;values&nbsp;(if&nbsp;valid).
      </div>
      <div>
        The&nbsp;synchronization&nbsp;is&nbsp;also&nbsp;done&nbsp;automatically&nbsp;at&nbsp;startup&nbsp;of&nbsp;TR421.dll.
      </div>
      <div>
        The&nbsp;Flash&nbsp;mirror&nbsp;ensures,&nbsp;that&nbsp;Network&nbsp;Settings&nbsp;are&nbsp;not&nbsp;lost&nbsp;at&nbsp;update
      </div>
      <div>
        of&nbsp;the&nbsp;WinCE&nbsp;Operating&nbsp;System&nbsp;(NK.bin),&nbsp;where&nbsp;a&nbsp;new&nbsp;registry&nbsp;i&nbsp;created.
      </div>
      <div>06/SYNC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/NAME/cc.c&nbsp;&nbsp;Setup&nbsp;Host&nbsp;Name</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;Windows&nbsp;CE&nbsp;Host&nbsp;Name.</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;both&nbsp;Flash&nbsp;and&nbsp;Registry.</div>
      <div>
        Recommended&nbsp;Name&nbsp;is&nbsp;"Nnnn-BS1-TRx",&nbsp;where&nbsp;nnn&nbsp;is&nbsp;Node&nbsp;Number&nbsp;and
      </div>
      <div>x&nbsp;is&nbsp;TR&nbsp;number.</div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Host&nbsp;Name</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>06/NAME/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/DHCP/s&nbsp;&nbsp;Setup&nbsp;DHCP</strong>
      </div>
      <div>Sets&nbsp;to&nbsp;use&nbsp;DHCP&nbsp;or&nbsp;Fixed&nbsp;Addresses.</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;both&nbsp;Flash&nbsp;and&nbsp;Registry.</div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;DHCP&nbsp;selection</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;DHCP&nbsp;Server&nbsp;provides&nbsp;IP&nbsp;Address/Mask/Gateway
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Manually&nbsp;entered&nbsp;IP&nbsp;Address/Mask/Gateway
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>06/DHCP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/IPADDR/...&nbsp;&nbsp;Setup&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;IP&nbsp;Address.</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;both&nbsp;Flash&nbsp;and&nbsp;Registry.</div>
      <div>
        Recommended&nbsp;IP&nbsp;Address&nbsp;is&nbsp;172.016.nnn.hhh,&nbsp;where&nbsp;nnn&nbsp;is&nbsp;Node&nbsp;Number
      </div>
      <div>and&nbsp;hhh&nbsp;is&nbsp;011..018&nbsp;for&nbsp;TR1&nbsp;to&nbsp;TR8.</div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(0..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>06/IPADDR/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/IPMASK/...&nbsp;&nbsp;Setup&nbsp;IP&nbsp;Mask</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;IP&nbsp;Mask.</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;both&nbsp;Flash&nbsp;and&nbsp;Registry.</div>
      <div>IP&nbsp;Mask&nbsp;shall&nbsp;normally&nbsp;be&nbsp;255.255.255.000.</div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Mask&nbsp;(0..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>06/IPMASK/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/IPGW/...&nbsp;&nbsp;Setup&nbsp;IP&nbsp;Default&nbsp;Gateway</strong>
      </div>
      <div>Sets&nbsp;the&nbsp;IP&nbsp;Default&nbsp;Gateway.</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;both&nbsp;Flash&nbsp;and&nbsp;Registry.</div>
      <div>
        IP&nbsp;Gateway&nbsp;shall&nbsp;normally&nbsp;be&nbsp;172.016.nnn.001&nbsp;(the&nbsp;VPN&nbsp;Router).&nbsp;
      </div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Gateway&nbsp;(0..255&nbsp;in&nbsp;each&nbsp;field)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>06/IPGW/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/MAC/hh-hh-hh&nbsp;&nbsp;Setup&nbsp;MAC&nbsp;Address</strong>
      </div>
      <div>Sets&nbsp;up&nbsp;the&nbsp;MAC&nbsp;Physical&nbsp;address&nbsp;of&nbsp;the&nbsp;Ethernet&nbsp;Port.</div>
      <div>The&nbsp;3&nbsp;entered&nbsp;bytes&nbsp;are&nbsp;the&nbsp;least&nbsp;significant&nbsp;bytes.</div>
      <div>The&nbsp;3&nbsp;most&nbsp;significant&nbsp;bytes&nbsp;are&nbsp;automaticalle&nbsp;set&nbsp;to</div>
      <div>00h+0Fh+E3h,&nbsp;the&nbsp;OUI&nbsp;for&nbsp;Damm&nbsp;Cellular&nbsp;Systems&nbsp;A/S.&nbsp;&nbsp;</div>
      <div>
        The&nbsp;value&nbsp;is&nbsp;setup&nbsp;in&nbsp;factory&nbsp;and&nbsp;should&nbsp;never&nbsp;be&nbsp;changed.
      </div>
      <div>Shall&nbsp;be&nbsp;setup&nbsp;at&nbsp;change&nbsp;of&nbsp;Flash&nbsp;IC&nbsp;on&nbsp;Main&nbsp;Board.</div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>NB:&nbsp;Command&nbsp;only&nbsp;available&nbsp;from&nbsp;Console.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Last&nbsp;bytes&nbsp;of&nbsp;MAC&nbsp;Address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>06/MAC/__-__-__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>06/10MBIT/s&nbsp;&nbsp;Change&nbsp;10MBit/s&nbsp;Forced&nbsp;Flag</strong>
      </div>
      <div>Forces&nbsp;the&nbsp;speed&nbsp;on&nbsp;the&nbsp;Ethernet&nbsp;Port&nbsp;to&nbsp;10MBit/s.</div>
      <div>
        Default&nbsp;value&nbsp;is&nbsp;10MBit/s&nbsp;to&nbsp;allow&nbsp;for&nbsp;up&nbsp;to&nbsp;150m&nbsp;LAN&nbsp;cable.
      </div>
      <div>
        If&nbsp;the&nbsp;length&nbsp;of&nbsp;the&nbsp;LAN&nbsp;cable&nbsp;is&nbsp;less&nbsp;than&nbsp;100m,&nbsp;the&nbsp;flag&nbsp;can&nbsp;be
      </div>
      <div>cleared&nbsp;to&nbsp;allow&nbsp;autonegotiation&nbsp;to&nbsp;100MBit/s.</div>
      <div>Value&nbsp;is&nbsp;saved&nbsp;in&nbsp;Flash&nbsp;on&nbsp;Main&nbsp;Board</div>
      <div>
        NB:&nbsp;Reboot&nbsp;of&nbsp;WinCE&nbsp;is&nbsp;needed&nbsp;afterwards&nbsp;(see&nbsp;99/HWRESET&nbsp;command).
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Ethernet&nbsp;Port&nbsp;10MBit&nbsp;forced&nbsp;flag
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;&nbsp;Forced&nbsp;10MBit/s
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;&nbsp;Autoselect&nbsp;10/100&nbsp;MBit/s
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>06/10MBIT/_</div>
      <div />
      <div />
    </div>
    <h2>Date/Time:</h2>
    <br />
    <div>07&nbsp;&nbsp;DATE/TIME</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>07&nbsp;&nbsp;Display&nbsp;Current&nbsp;Local&nbsp;Date/Time</strong>
      </div>
      <div>07</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>07/S&nbsp;&nbsp;Display&nbsp;Date/Time&nbsp;status</strong>
      </div>
      <div>07/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>07/T&nbsp;&nbsp;Display&nbsp;DSP&nbsp;TETRA&nbsp;Counters</strong>
      </div>
      <div>07/T</div>
      <div />
      <div />
    </div>
    <h2>TX Key:</h2>
    <br />
    <div>10&nbsp;&nbsp;TX&nbsp;KEY</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10&nbsp;&nbsp;Display&nbsp;TX&nbsp;key&nbsp;state</strong>
      </div>
      <div>10</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10+&nbsp;&nbsp;Turn&nbsp;on&nbsp;TX</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>10+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10-&nbsp;&nbsp;Turn&nbsp;off&nbsp;TX</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>10-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/T&nbsp;&nbsp;Display&nbsp;timeslot&nbsp;key</strong>
      </div>
      <div>Only&nbsp;available&nbsp;in&nbsp;Tetra&nbsp;mode</div>
      <div>10/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/T/ssss&nbsp;&nbsp;Change&nbsp;TX&nbsp;timeslot&nbsp;key</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>Only&nbsp;available&nbsp;in&nbsp;Tetra&nbsp;mode</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Timeslot&nbsp;1&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;Timeslot&nbsp;2&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;Timeslot&nbsp;3&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;Timeslot&nbsp;4&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴</div>
      <div>10/T/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/AUTO&nbsp;&nbsp;Display&nbsp;Auto&nbsp;TX-off&nbsp;mode</strong>
      </div>
      <div>Only&nbsp;significant&nbsp;in&nbsp;Tetra&nbsp;mode&nbsp;without&nbsp;Operator&nbsp;Blocking</div>
      <div>10/AUTO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/AUTO/c&nbsp;&nbsp;Change&nbsp;Auto&nbsp;TX-off&nbsp;mode</strong>
      </div>
      <div>Has&nbsp;priority&nbsp;over&nbsp;control&nbsp;from&nbsp;L3</div>
      <div>Only&nbsp;significant&nbsp;in&nbsp;Tetra&nbsp;mode&nbsp;without&nbsp;Operator&nbsp;Blocking</div>
      <div>L3&nbsp;control&nbsp;is&nbsp;default</div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Controlled&nbsp;of&nbsp;L3</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;D:&nbsp;Automatic&nbsp;TX-off&nbsp;disabled</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;C:&nbsp;Automatic&nbsp;Carrier-off&nbsp;(TX-off&nbsp;if&nbsp;all&nbsp;Timeslots&nbsp;idle)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;Automatic&nbsp;Timeslot-off&nbsp;(TX-off&nbsp;of&nbsp;idle&nbsp;Timeslots)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/AUTO/_</div>
      <div />
      <div />
    </div>
    <h2>TX Output Power:</h2>
    <br />
    <div>11&nbsp;&nbsp;DISPLAY&nbsp;TX&nbsp;OUTPUT&nbsp;AND&nbsp;REFLECTED&nbsp;POWER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11&nbsp;&nbsp;Display&nbsp;TX&nbsp;output&nbsp;and&nbsp;reflected&nbsp;power</strong>
      </div>
      <div>11</div>
      <div />
      <div />
    </div>
    <h2>TX Current/Power consumption:</h2>
    <br />
    <div>12&nbsp;&nbsp;DISPLAY&nbsp;TX&nbsp;CURRENT/POWER&nbsp;CONSUMPTION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12&nbsp;&nbsp;Display&nbsp;TX&nbsp;Current/Power&nbsp;consumption</strong>
      </div>
      <div>12</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12/P&nbsp;&nbsp;Display&nbsp;PA&nbsp;Power</strong>
      </div>
      <div>12/P</div>
      <div />
      <div />
    </div>
    <h2>TX Output Power selection:</h2>
    <br />
    <div>13&nbsp;&nbsp;TX&nbsp;OUTPUT&nbsp;POWER&nbsp;SELECTION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13&nbsp;&nbsp;Display&nbsp;TX&nbsp;output&nbsp;power&nbsp;selection</strong>
      </div>
      <div>13</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/+nn.n&nbsp;&nbsp;Change&nbsp;TX&nbsp;Normal&nbsp;Output&nbsp;Power&nbsp;setting</strong>
      </div>
      <div>Normal&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;in&nbsp;TETRA&nbsp;mode</div>
      <div>This&nbsp;value&nbsp;is&nbsp;used&nbsp;if&nbsp;Alarm&nbsp;8&nbsp;input&nbsp;is&nbsp;not&nbsp;activated</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;0.5&nbsp;(-3dB)&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Analog&nbsp;Mode,&nbsp;the&nbsp;output&nbsp;power&nbsp;is&nbsp;2&nbsp;times&nbsp;the&nbsp;selected&nbsp;power
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Normal&nbsp;Output&nbsp;power&nbsp;setting&nbsp;(27.0&nbsp;to&nbsp;40.0&nbsp;dBm)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>13/+__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/MIN&nbsp;&nbsp;Set&nbsp;TX&nbsp;Normal&nbsp;Output&nbsp;Power&nbsp;to&nbsp;Minimum</strong>
      </div>
      <div>Normal&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;in&nbsp;TETRA&nbsp;mode</div>
      <div>This&nbsp;value&nbsp;is&nbsp;used&nbsp;if&nbsp;Alarm&nbsp;8&nbsp;input&nbsp;is&nbsp;not&nbsp;activated</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;0.5&nbsp;(-3dB)&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Analog&nbsp;Mode,&nbsp;the&nbsp;output&nbsp;power&nbsp;is&nbsp;2&nbsp;times&nbsp;the&nbsp;selected&nbsp;power
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>13/MIN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/MAX&nbsp;&nbsp;Set&nbsp;TX&nbsp;Normal&nbsp;Output&nbsp;Power&nbsp;to&nbsp;Maximum</strong>
      </div>
      <div>Normal&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;in&nbsp;TETRA&nbsp;mode</div>
      <div>This&nbsp;value&nbsp;is&nbsp;used&nbsp;if&nbsp;Alarm&nbsp;8&nbsp;input&nbsp;is&nbsp;not&nbsp;activated</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;0.5&nbsp;(-3dB)&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Analog&nbsp;Mode,&nbsp;the&nbsp;output&nbsp;power&nbsp;is&nbsp;2&nbsp;times&nbsp;the&nbsp;selected&nbsp;power
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>13/MAX</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/RED/+nn.n&nbsp;&nbsp;Change&nbsp;Reduced&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;setting</strong>
      </div>
      <div>Reduced&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;setting&nbsp;in&nbsp;TETRA&nbsp;mode</div>
      <div>This&nbsp;value&nbsp;is&nbsp;used&nbsp;if&nbsp;Alarm&nbsp;8&nbsp;input&nbsp;is&nbsp;activated</div>
      <div>Maxim&nbsp;Peak&nbsp;Envelope&nbsp;Power&nbsp;is&nbsp;2.4&nbsp;times&nbsp;(3.8dB)&nbsp;higher</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;0.5&nbsp;(-3dB)&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Analog&nbsp;Mode,&nbsp;the&nbsp;output&nbsp;power&nbsp;is&nbsp;2&nbsp;times&nbsp;the&nbsp;selected&nbsp;power
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Reduced&nbsp;Output&nbsp;Power&nbsp;setting&nbsp;(27.0&nbsp;to&nbsp;40.0&nbsp;dBm)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>13/RED/+__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/RED/MIN&nbsp;&nbsp;Set&nbsp;TX&nbsp;Reduced&nbsp;Output&nbsp;Power&nbsp;to&nbsp;Minimum</strong>
      </div>
      <div>Reduced&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;in&nbsp;TETRA&nbsp;mode</div>
      <div>This&nbsp;value&nbsp;is&nbsp;used&nbsp;if&nbsp;Alarm&nbsp;8&nbsp;input&nbsp;is&nbsp;not&nbsp;activated</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;0.5&nbsp;(-3dB)&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Analog&nbsp;Mode,&nbsp;the&nbsp;output&nbsp;power&nbsp;is&nbsp;2&nbsp;times&nbsp;the&nbsp;selected&nbsp;power
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>13/RED/MIN</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/RED/MAX&nbsp;&nbsp;Set&nbsp;TX&nbsp;Reduced&nbsp;Output&nbsp;Power&nbsp;to&nbsp;Maximum</strong>
      </div>
      <div>Reduced&nbsp;TX&nbsp;Output&nbsp;Power&nbsp;in&nbsp;TETRA&nbsp;mode</div>
      <div>This&nbsp;value&nbsp;is&nbsp;used&nbsp;if&nbsp;Alarm&nbsp;8&nbsp;input&nbsp;is&nbsp;not&nbsp;activated</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;0.5&nbsp;(-3dB)&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Analog&nbsp;Mode,&nbsp;the&nbsp;output&nbsp;power&nbsp;is&nbsp;2&nbsp;times&nbsp;the&nbsp;selected&nbsp;power
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>13/RED/MAX</div>
      <div />
      <div />
    </div>
    <h2>TX PA DC Bias:</h2>
    <br />
    <div>14&nbsp;&nbsp;TX&nbsp;BIAS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>14&nbsp;&nbsp;Display&nbsp;TX&nbsp;Bias</strong>
      </div>
      <div>14</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>14+&nbsp;&nbsp;Select&nbsp;TX&nbsp;Bias&nbsp;Forced&nbsp;On</strong>
      </div>
      <div>14+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>14-&nbsp;&nbsp;Select&nbsp;TX&nbsp;Bias&nbsp;Off&nbsp;(DSP&nbsp;Controlled)</strong>
      </div>
      <div>14-</div>
      <div />
      <div />
    </div>
    <h2>TX modulation:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15&nbsp;&nbsp;Display&nbsp;TX&nbsp;test&nbsp;modulation&nbsp;selection</strong>
      </div>
      <div>15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/ZERO&nbsp;&nbsp;Select&nbsp;zero&nbsp;modulation</strong>
      </div>
      <div>
        I&nbsp;and&nbsp;Q&nbsp;DAC&nbsp;set&nbsp;to&nbsp;zero&nbsp;(with&nbsp;zero&nbsp;offset&nbsp;compensation)
      </div>
      <div>15/ZERO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation</strong>
      </div>
      <div>Power&nbsp;level&nbsp;as&nbsp;in&nbsp;Analog&nbsp;Mode&nbsp;(5/6&nbsp;of&nbsp;PEP)</div>
      <div>15/CW</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/PEP&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation&nbsp;PEP&nbsp;value</strong>
      </div>
      <div>Power&nbsp;level&nbsp;is&nbsp;PEP-value&nbsp;of&nbsp;selected&nbsp;power</div>
      <div>15/CW/PEP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/cs&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I:&nbsp;I&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Q:&nbsp;Q&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;+:&nbsp;Positive&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││&nbsp;-:&nbsp;Negative&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴</div>
      <div>15/CW/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/IQ/snnnnn/snnnnn&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation&nbsp;with&nbsp;I/Q&nbsp;values</strong>
      </div>
      <div>
        NB:&nbsp;I&nbsp;and&nbsp;Q&nbsp;values&nbsp;are&nbsp;modified&nbsp;with&nbsp;power&nbsp;setting&nbsp;and&nbsp;I/Q-zero
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;quadrature&nbsp;settings&nbsp;before&nbsp;reaching&nbsp;the&nbsp;DACs.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I-value&nbsp;(-32768..+32767)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Q-value&nbsp;(-32768..+32767)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────&nbsp;┴─────</div>
      <div>15/CW/IQ/______/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/PRBS9&nbsp;&nbsp;Select&nbsp;TETRA&nbsp;PRBS-9&nbsp;modulation</strong>
      </div>
      <div>15/PRBS9</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/PRBS15&nbsp;&nbsp;Select&nbsp;TETRA&nbsp;PRBS-15&nbsp;modulation</strong>
      </div>
      <div>15/PRBS15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/DSB/c/n&nbsp;&nbsp;Select&nbsp;DSB&nbsp;modulation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I:&nbsp;I&nbsp;generated&nbsp;DSB&nbsp;modulation</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Q:&nbsp;Q&nbsp;generated&nbsp;DSB&nbsp;modulation</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;2:&nbsp;2.25&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;4:&nbsp;4.50&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;9:&nbsp;9.00&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>15/DSB/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/cSB/n&nbsp;&nbsp;Select&nbsp;SSB&nbsp;modulation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;L:&nbsp;LSB&nbsp;(Lower&nbsp;Side&nbsp;Band)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;U:&nbsp;USB&nbsp;(Upper&nbsp;Side&nbsp;Band)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;2:&nbsp;2.25&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;4:&nbsp;4.50&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;9:&nbsp;9.00&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;┴</div>
      <div>15/_SB/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/FM/3&nbsp;&nbsp;Select&nbsp;FM&nbsp;1kHz&nbsp;+/-3kHz</strong>
      </div>
      <div>15/FM/3</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/MULTI&nbsp;&nbsp;Select&nbsp;DSB&nbsp;multitone&nbsp;modulation</strong>
      </div>
      <div>15/MULTI</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/PH45&nbsp;&nbsp;Select&nbsp;TETRA&nbsp;+/-45deg.&nbsp;modulation</strong>
      </div>
      <div>15/PH45</div>
      <div />
      <div />
    </div>
    <h2>TX modulation filter:</h2>
    <br />
    <div>16&nbsp;&nbsp;TX&nbsp;FILTER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>16&nbsp;&nbsp;Display&nbsp;TX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>16</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>16/n&nbsp;&nbsp;Change&nbsp;TX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;filter&nbsp;type</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;TETRA&nbsp;25kHz&nbsp;ch.&nbsp;spacing</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Flat&nbsp;(for&nbsp;test)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>16/_</div>
      <div />
      <div />
    </div>
    <h2>TX status:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>17&nbsp;&nbsp;Display&nbsp;TX&nbsp;status</strong>
      </div>
      <div>17</div>
      <div />
      <div />
    </div>
    <h2>TX Cartesial Loop:</h2>
    <br />
    <div>18&nbsp;&nbsp;TX&nbsp;CARTESIAN&nbsp;LOOP</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18&nbsp;&nbsp;Display&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;selections</strong>
      </div>
      <div>18</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18-&nbsp;&nbsp;Select&nbsp;open&nbsp;cartesian&nbsp;loop</strong>
      </div>
      <div>18-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18+&nbsp;&nbsp;Select&nbsp;closed&nbsp;cartesian&nbsp;loop</strong>
      </div>
      <div>18+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/P&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase&nbsp;measurement</strong>
      </div>
      <div>18/P</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/A&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase/ampl.&nbsp;measurement</strong>
      </div>
      <div>18/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          18/FREQ&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase&nbsp;vs&nbsp;frequency&nbsp;measurement
        </strong>
      </div>
      <div>18/FREQ</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/PWR&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase&nbsp;vs.&nbsp;power&nbsp;measurement</strong>
      </div>
      <div>18/PWR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/LIN&nbsp;&nbsp;TX&nbsp;linearity&nbsp;measurement</strong>
      </div>
      <div>Measures&nbsp;TX&nbsp;delta&nbsp;Gain&nbsp;and&nbsp;delta&nbsp;Phase</div>
      <div>at&nbsp;output&nbsp;power&nbsp;change&nbsp;from&nbsp;+27dBm&nbsp;to&nbsp;+40dBm</div>
      <div>18/LIN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/ADJ&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;open&nbsp;loop&nbsp;phase&nbsp;adjust</strong>
      </div>
      <div>WARNING:&nbsp;Factory&nbsp;adjust&nbsp;command,&nbsp;factory&nbsp;unlock&nbsp;necessary</div>
      <div>Changes&nbsp;same&nbsp;parameter&nbsp;as&nbsp;the&nbsp;91/PH0/snnn&nbsp;command</div>
      <div>18/ADJ</div>
      <div />
      <div />
    </div>
    <h2>TX utility commands:</h2>
    <br />
    <div>19&nbsp;&nbsp;TX&nbsp;UTILITY&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>19</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19/F&nbsp;&nbsp;Display&nbsp;TX&nbsp;PLL&nbsp;voltage&nbsp;vs&nbsp;frequency</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>19/F</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19+&nbsp;&nbsp;Switch&nbsp;TX&nbsp;on&nbsp;and&nbsp;off</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>19+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19/nnnn&nbsp;&nbsp;Switch&nbsp;TX&nbsp;channel&nbsp;to&nbsp;nnnn&nbsp;and&nbsp;back&nbsp;again</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>NB:&nbsp;TX&nbsp;is&nbsp;turned&nbsp;on&nbsp;and&nbsp;off&nbsp;at&nbsp;both&nbsp;channels</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>19/____</div>
      <div />
      <div />
      <div />
    </div>
    <h2>RX Local Oscillators:</h2>
    <br />
    <div>20&nbsp;&nbsp;RX&nbsp;LO</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20&nbsp;&nbsp;Display&nbsp;RX&nbsp;LO&nbsp;status</strong>
      </div>
      <div>20</div>
      <div />
      <div />
    </div>
    <h2>RX RSSI:</h2>
    <br />
    <div>21&nbsp;&nbsp;DISPLAY&nbsp;RX&nbsp;RSSI</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21&nbsp;&nbsp;Display&nbsp;RX&nbsp;RSSI</strong>
      </div>
      <div>With&nbsp;5&nbsp;msec.&nbsp;settling&nbsp;time</div>
      <div>21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/c&nbsp;&nbsp;Display&nbsp;RX&nbsp;RSSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;RSSI&nbsp;option</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;100&nbsp;msec.&nbsp;settling&nbsp;time</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;1&nbsp;sec.&nbsp;settling&nbsp;time</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/NF&nbsp;&nbsp;Display&nbsp;RX&nbsp;Noise&nbsp;Figure</strong>
      </div>
      <div />
      <div>Displays&nbsp;the&nbsp;RSSI&nbsp;value&nbsp;as&nbsp;equivalent&nbsp;Noise&nbsp;Figure</div>
      <div>The&nbsp;reference&nbsp;depends&nbsp;on&nbsp;the&nbsp;actual&nbsp;IF&nbsp;filter</div>
      <div>bandwidth,&nbsp;see&nbsp;the&nbsp;26&nbsp;command</div>
      <div />
      <div>21/NF</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/FFT&nbsp;&nbsp;Display&nbsp;FFT&nbsp;result</strong>
      </div>
      <div>NB:&nbsp;Select&nbsp;RX-A&nbsp;or&nbsp;RX-B&nbsp;with&nbsp;the&nbsp;25/FFT/c&nbsp;command</div>
      <div>21/FFT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/FFT/A&nbsp;&nbsp;Display&nbsp;FFT&nbsp;Adjust&nbsp;results</strong>
      </div>
      <div>NB:&nbsp;Select&nbsp;RX-A&nbsp;or&nbsp;RX-B&nbsp;FFT&nbsp;with&nbsp;the&nbsp;25/FFT/c&nbsp;command</div>
      <div>Response&nbsp;is:</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:-6.75&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:-2.25&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;0.00&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:+2.25&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:+6.75&nbsp;kHz&nbsp;[dBm]</div>
      <div>21/FFT/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/FREQ&nbsp;&nbsp;Display&nbsp;RX&nbsp;Signal&nbsp;Frequency&nbsp;Offset</strong>
      </div>
      <div>NB:&nbsp;Select&nbsp;RX-A&nbsp;or&nbsp;RX-B&nbsp;with&nbsp;the&nbsp;25/FREQ/c&nbsp;command</div>
      <div>21/FREQ</div>
      <div />
      <div />
    </div>
    <h2>RX Diversity:</h2>
    <br />
    <div>22&nbsp;&nbsp;RX&nbsp;DIVERSITY</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22&nbsp;&nbsp;Display&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>22</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/A&nbsp;&nbsp;A-forced&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>22/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/B&nbsp;&nbsp;B-forced&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>22/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22-&nbsp;&nbsp;Automatic&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>22-</div>
      <div />
      <div />
    </div>
    <h2>RX Antenna test:</h2>
    <br />
    <div>23&nbsp;&nbsp;RX&nbsp;ANTENNA&nbsp;TEST</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>23</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23+&nbsp;&nbsp;RX&nbsp;antenna&nbsp;measurement&nbsp;command</strong>
      </div>
      <div>23+</div>
      <div />
      <div />
    </div>
    <h2>RX-B Input:</h2>
    <br />
    <div>24&nbsp;&nbsp;RX-B&nbsp;INPUT</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>24&nbsp;&nbsp;Display&nbsp;RX-B&nbsp;Input&nbsp;Selection</strong>
      </div>
      <div>24</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>24+&nbsp;&nbsp;Select&nbsp;RX-B&nbsp;input&nbsp;Cascaded</strong>
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>24+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>24-&nbsp;&nbsp;Deselect&nbsp;RX-B&nbsp;input&nbsp;Cascaded</strong>
      </div>
      <div>NB:&nbsp;This&nbsp;setting&nbsp;is&nbsp;saved&nbsp;in&nbsp;the&nbsp;System&nbsp;Configuration&nbsp;File</div>
      <div>24-</div>
      <div />
      <div />
    </div>
    <h2>RX demodulation mode:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25&nbsp;&nbsp;Display&nbsp;RX&nbsp;test&nbsp;demodulation&nbsp;mode</strong>
      </div>
      <div>25</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/PM&nbsp;&nbsp;Select&nbsp;PM&nbsp;demodulation</strong>
      </div>
      <div>25/PM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/FFT/A&nbsp;&nbsp;Select&nbsp;RX-A&nbsp;FFT&nbsp;demodulation</strong>
      </div>
      <div>25/FFT/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/FFT/B&nbsp;&nbsp;Select&nbsp;RX-B&nbsp;FFT&nbsp;demodulation</strong>
      </div>
      <div>25/FFT/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/FREQ/A&nbsp;&nbsp;Select&nbsp;RX-A&nbsp;Frequency&nbsp;Measurement</strong>
      </div>
      <div>25/FREQ/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/FREQ/B&nbsp;&nbsp;Select&nbsp;RX-B&nbsp;Frequency&nbsp;Measurement</strong>
      </div>
      <div>25/FREQ/B</div>
      <div />
      <div />
    </div>
    <h2>RX IF filter:</h2>
    <br />
    <div>26&nbsp;&nbsp;RX&nbsp;FILTER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26&nbsp;&nbsp;Display&nbsp;RX&nbsp;filter&nbsp;selection</strong>
      </div>
      <div>26</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/n&nbsp;&nbsp;Change&nbsp;RX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;filter&nbsp;type</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;TETRA&nbsp;25kHz&nbsp;ch.&nbsp;spacing</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;PM&nbsp;25kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-7.5kHz)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;PM&nbsp;25kHz&nbsp;ch.&nbsp;spacing&nbsp;interleaved&nbsp;(+/-6.5kHz)
      </div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;PM&nbsp;20kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-6kHz)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;PM&nbsp;12.5kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-3.75kHz)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;5:&nbsp;PM&nbsp;6.25kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-1.9kHz)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;6:&nbsp;Flat&nbsp;(for&nbsp;test)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>26/_</div>
      <div />
      <div />
    </div>
    <h2>RX Signal Display:</h2>
    <br />
    <div>27&nbsp;&nbsp;RX&nbsp;SIGNAL&nbsp;DISPLAY&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>27&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>27</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>27/CP/c&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;Constellation&nbsp;Points</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;path:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;RX-A</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;RX-B</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>27/CP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>27/ADC&nbsp;&nbsp;Display&nbsp;ADC&nbsp;peak-peak&nbsp;input&nbsp;levels</strong>
      </div>
      <div>27/ADC</div>
      <div />
      <div />
    </div>
    <h2>RX adjust commands:</h2>
    <br />
    <div>28&nbsp;&nbsp;RX&nbsp;ADJUST&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>28&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>28</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>28/LO&nbsp;&nbsp;Display&nbsp;LO1&nbsp;injection</strong>
      </div>
      <div>28/LO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>28/BPF&nbsp;&nbsp;Display&nbsp;RSSI&nbsp;vs.&nbsp;Frequency</strong>
      </div>
      <div>Can&nbsp;be&nbsp;used&nbsp;for&nbsp;RX&nbsp;BPF&nbsp;adjustment</div>
      <div>Uses&nbsp;TX&nbsp;+&nbsp;RFTL&nbsp;as&nbsp;signal&nbsp;source&nbsp;</div>
      <div>28/BPF</div>
      <div />
      <div />
    </div>
    <h2>RX utility commands:</h2>
    <br />
    <div>29&nbsp;&nbsp;RX&nbsp;UTILITY&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>29&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>29</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>29/F&nbsp;&nbsp;Display&nbsp;RX&nbsp;PLL&nbsp;voltage&nbsp;vs&nbsp;frequency</strong>
      </div>
      <div>29/F</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>29/nnnn&nbsp;&nbsp;Switch&nbsp;RX&nbsp;to&nbsp;channel&nbsp;nnnn&nbsp;and&nbsp;back&nbsp;again</strong>
      </div>
      <div>Operator&nbsp;blocking&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>29/____</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Channel/Frequency:</h2>
    <br />
    <div>30&nbsp;&nbsp;CHANNEL&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30&nbsp;&nbsp;Display&nbsp;RX+TX&nbsp;channel&nbsp;numbers&nbsp;and&nbsp;frequencies</strong>
      </div>
      <div>30</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/F&nbsp;&nbsp;Display&nbsp;Hardware&nbsp;Frequency&nbsp;Range</strong>
      </div>
      <div>30/F</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/nnnn&nbsp;&nbsp;Change&nbsp;RX+TX&nbsp;channel&nbsp;number</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/R/nnnn&nbsp;&nbsp;Change&nbsp;RX&nbsp;channel&nbsp;number</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/R/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/T/nnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;channel&nbsp;number</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/T/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/R/nnn.nnnnnn&nbsp;&nbsp;Change&nbsp;RX&nbsp;frequency</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;[MHz]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────</div>
      <div>30/R/___.______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/T/nnn.nnnnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;frequency</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;[MHz]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────</div>
      <div>30/T/___.______</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Synchronization</h2>
    <br />
    <div>31&nbsp;&nbsp;OCXO&nbsp;SYNCHRONIZATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31&nbsp;&nbsp;Display&nbsp;Sync&nbsp;status</strong>
      </div>
      <div>31</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/IN&nbsp;&nbsp;Display&nbsp;Sync&nbsp;Input&nbsp;status</strong>
      </div>
      <div>31/IN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/IN/c&nbsp;&nbsp;Change&nbsp;Sync&nbsp;Input&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Auto&nbsp;Select</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;F:&nbsp;Forced&nbsp;Free&nbsp;Running</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;G:&nbsp;Forced&nbsp;GPS&nbsp;RX&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;1:&nbsp;Forced&nbsp;External&nbsp;1&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Forced&nbsp;External&nbsp;2&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>31/IN/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          31/SET&nbsp;&nbsp;Set&nbsp;OCXO&nbsp;Free&nbsp;Run&nbsp;adjust&nbsp;value&nbsp;to&nbsp;current&nbsp;DAC&nbsp;value
        </strong>
      </div>
      <div>31/SET</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/SET/nn.nnn&nbsp;&nbsp;Set&nbsp;OCXO&nbsp;Free&nbsp;Run&nbsp;value</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00.000&nbsp;to&nbsp;99.999&nbsp;[%]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>31/SET/__.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/SAVE&nbsp;&nbsp;Save&nbsp;OCXO&nbsp;Free&nbsp;Run&nbsp;adjust&nbsp;value&nbsp;in&nbsp;Flash</strong>
      </div>
      <div>31/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/PPS&nbsp;&nbsp;Display&nbsp;1&nbsp;PPS&nbsp;inputs</strong>
      </div>
      <div>31/PPS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/HIST&nbsp;&nbsp;Display&nbsp;OCXO&nbsp;Sync&nbsp;history</strong>
      </div>
      <div>31/HIST</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31/STEP/nn.nnn&nbsp;&nbsp;Simulate&nbsp;a&nbsp;PLL&nbsp;Frequency&nbsp;Step</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00.000&nbsp;to&nbsp;99.999&nbsp;[%]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>31/STEP/__.___</div>
      <div />
      <div />
    </div>
    <h2>RFTL:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32&nbsp;&nbsp;Display&nbsp;RFTL&nbsp;selection</strong>
      </div>
      <div>32</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32+&nbsp;&nbsp;Turn&nbsp;on&nbsp;RFTL&nbsp;Forward</strong>
      </div>
      <div>32+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32/B&nbsp;&nbsp;Turn&nbsp;on&nbsp;RFTL&nbsp;Backward</strong>
      </div>
      <div>32/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32-&nbsp;&nbsp;Turn&nbsp;off&nbsp;RFTL</strong>
      </div>
      <div>32-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32/nnn&nbsp;&nbsp;Select&nbsp;RFTL&nbsp;Forward&nbsp;Level</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;122&nbsp;to&nbsp;104&nbsp;[-dBm]</div>
      <div>&nbsp;&nbsp;&nbsp;┴──</div>
      <div>32/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32/ATT&nbsp;&nbsp;Display&nbsp;RFTL&nbsp;Attenuator&nbsp;value</strong>
      </div>
      <div>32/ATT</div>
      <div />
      <div />
    </div>
    <h2>Duplex Filter:</h2>
    <br />
    <div>33&nbsp;&nbsp;DUPLEX&nbsp;FILTER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>33&nbsp;&nbsp;Display&nbsp;Duplex&nbsp;Filter&nbsp;Selection</strong>
      </div>
      <div>33</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>33+&nbsp;&nbsp;Select&nbsp;Duplex&nbsp;Filter</strong>
      </div>
      <div>33+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>33-&nbsp;&nbsp;Deselect&nbsp;Duplex&nbsp;Filter</strong>
      </div>
      <div>
        To&nbsp;be&nbsp;selected&nbsp;during&nbsp;test,&nbsp;when&nbsp;no&nbsp;Duplex&nbsp;Filter&nbsp;is&nbsp;attached
      </div>
      <div>Modifies&nbsp;the&nbsp;following&nbsp;item:</div>
      <div>
        &nbsp;&nbsp;1)&nbsp;Disables&nbsp;channel&nbsp;range&nbsp;check&nbsp;against&nbsp;Duplex&nbsp;Filter&nbsp;limits
      </div>
      <div>&nbsp;&nbsp;2)&nbsp;Disables&nbsp;RSSI&nbsp;compensation&nbsp;for&nbsp;Duplex&nbsp;Filter&nbsp;Loss</div>
      <div>
        &nbsp;&nbsp;3)&nbsp;Disables&nbsp;RX&nbsp;Antenna&nbsp;Return&nbsp;Loss&nbsp;measurement&nbsp;compensation
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for&nbsp;Duplex&nbsp;Filter&nbsp;Loss</div>
      <div>
        &nbsp;&nbsp;4)&nbsp;Disables&nbsp;TX&nbsp;Power&nbsp;Meter&nbsp;compensation&nbsp;for&nbsp;Duplex&nbsp;Filter&nbsp;Loss&nbsp;&nbsp;&nbsp;
      </div>
      <div>33-</div>
      <div />
      <div />
    </div>
    <h2>CMoIP:</h2>
    <br />
    <div>34&nbsp;&nbsp;CMoIP</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34&nbsp;&nbsp;Display&nbsp;all&nbsp;CMoIP&nbsp;Connections</strong>
      </div>
      <div>34</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34/n&nbsp;&nbsp;Display&nbsp;one&nbsp;CMoIP&nbsp;Connection</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>34/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34/n/...&nbsp;&nbsp;CMoIP&nbsp;Multicast&nbsp;Connect</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Multicast&nbsp;IP&nbsp;Address&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────</div>
      <div>34/_/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34/n/REL&nbsp;&nbsp;CMoIP&nbsp;Multicast&nbsp;Release</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>34/_/REL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34/C&nbsp;&nbsp;Clear&nbsp;CMoIP&nbsp;Downlink&nbsp;Statistics&nbsp;Couters</strong>
      </div>
      <div>34/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34/Q&nbsp;&nbsp;Display&nbsp;CMoIP&nbsp;DSP&nbsp;queues</strong>
      </div>
      <div>34/Q</div>
      <div />
      <div />
    </div>
    <h2>MAC</h2>
    <br />
    <div>43&nbsp;MAC</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43&nbsp;&nbsp;Display&nbsp;Command&nbsp;Function</strong>
      </div>
      <div>43</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/TS&nbsp;&nbsp;Display&nbsp;Timeslot&nbsp;Status</strong>
      </div>
      <div>43/TS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/CONST/n/c&nbsp;&nbsp;Display&nbsp;Constellation&nbsp;Points</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;Number&nbsp;(1..4)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;A:&nbsp;RX&nbsp;A</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;B:&nbsp;RX&nbsp;B</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>43/CONST/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/LMAC&nbsp;&nbsp;Display&nbsp;LMAC&nbsp;Message&nbsp;Statistics</strong>
      </div>
      <div>43/LMAC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/LMAC/C&nbsp;&nbsp;Clear&nbsp;LMAC&nbsp;Message&nbsp;Counter</strong>
      </div>
      <div />
      <div>43/LMAC/C</div>
      <div />
      <div />
    </div>
    <h2>BSC Message Count:</h2>
    <br />
    <div>47&nbsp;BSC&nbsp;MESSAGE&nbsp;COUNT</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>47&nbsp;&nbsp;Display&nbsp;BSC&nbsp;message&nbsp;count</strong>
      </div>
      <div>47</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>47/C&nbsp;&nbsp;Clear&nbsp;BSC&nbsp;message&nbsp;count</strong>
      </div>
      <div>47/C</div>
      <div />
      <div />
    </div>
    <h2>Message Trace:</h2>
    <br />
    <div>48&nbsp;MESSAGE&nbsp;TRACE</div>
    <br />
    <div>
      The&nbsp;48&nbsp;Commands&nbsp;controls&nbsp;the&nbsp;the&nbsp;build-in&nbsp;Signalling&nbsp;Trace&nbsp;functionality.
    </div>
    <br />
    <div>
      For&nbsp;each&nbsp;of&nbsp;the&nbsp;4&nbsp;Tetra&nbsp;timeslots&nbsp;a&nbsp;testpoint&nbsp;can&nbsp;be&nbsp;activated&nbsp;to&nbsp;make&nbsp;it
    </div>
    <div>
      possible&nbsp;to&nbsp;monitor&nbsp;Downlink&nbsp;and&nbsp;Uplink&nbsp;traffic&nbsp;på&nbsp;the&nbsp;Transceiver,&nbsp;either
    </div>
    <div>below&nbsp;or&nbsp;above&nbsp;Air&nbsp;Interface&nbsp;Encryption.</div>
    <br />
    <div>
      The&nbsp;output&nbsp;from&nbsp;the&nbsp;Test&nbsp;Points&nbsp;can&nbsp;be&nbsp;output&nbsp;realtime&nbsp;in&nbsp;UDP&nbsp;messages,&nbsp;and&nbsp;can
    </div>
    <div>
      also&nbsp;be&nbsp;written&nbsp;to&nbsp;a&nbsp;file,&nbsp;either&nbsp;on&nbsp;the&nbsp;BS421&nbsp;or&nbsp;on&nbsp;a&nbsp;remote&nbsp;Computer.
    </div>
    <br />
    <div>
      The&nbsp;output&nbsp;can&nbsp;be&nbsp;analyzed&nbsp;with&nbsp;the&nbsp;Tetra&nbsp;Protocol&nbsp;Analyzer&nbsp;from&nbsp;fjord-e-design.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48&nbsp;&nbsp;Display&nbsp;message&nbsp;trace</strong>
      </div>
      <div>48</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48+&nbsp;&nbsp;Start&nbsp;Trace&nbsp;of&nbsp;all&nbsp;TS&nbsp;to&nbsp;UDP</strong>
      </div>
      <div>48+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48-&nbsp;&nbsp;Stop&nbsp;Trace&nbsp;of&nbsp;all&nbsp;TS&nbsp;to&nbsp;UDP</strong>
      </div>
      <div>48-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/UDP/+&nbsp;&nbsp;Activate&nbsp;trace&nbsp;output&nbsp;to&nbsp;UDP</strong>
      </div>
      <div>UDP&nbsp;output&nbsp;counter&nbsp;will&nbsp;be&nbsp;cleared</div>
      <div>48/UDP/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/UDP/-&nbsp;&nbsp;Stop&nbsp;trace&nbsp;output&nbsp;to&nbsp;UDP</strong>
      </div>
      <div>48/UDP/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/UDP/nnn...&nbsp;&nbsp;Change&nbsp;trace&nbsp;UDP&nbsp;address+port</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UDP&nbsp;IP&nbsp;address</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UDP&nbsp;port&nbsp;number&nbsp;(default&nbsp;42397)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴────</div>
      <div>48/UDP/___.___.___.___/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/FILE/+&nbsp;&nbsp;Open&nbsp;trace&nbsp;output&nbsp;file</strong>
      </div>
      <div>File&nbsp;will&nbsp;be&nbsp;cleared&nbsp;if&nbsp;existing</div>
      <div>File&nbsp;output&nbsp;counter&nbsp;will&nbsp;be&nbsp;cleared</div>
      <div />
      <div>48/FILE/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/FILE/-&nbsp;&nbsp;Close&nbsp;trace&nbsp;output&nbsp;file</strong>
      </div>
      <div>48/FILE/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/FILE/cc..c&nbsp;&nbsp;Change&nbsp;trace&nbsp;filename</strong>
      </div>
      <div>File&nbsp;will&nbsp;be&nbsp;closed&nbsp;if&nbsp;open</div>
      <div>
        NB:&nbsp;TR&nbsp;shall&nbsp;have&nbsp;write&nbsp;access&nbsp;permission&nbsp;to&nbsp;the&nbsp;file&nbsp;location
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Filename&nbsp;(e.g.&nbsp;\CF-Card\Tetra\Work\Trace1.lan)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────────────────────────────────────────────────────
      </div>
      <div>48/FILE/_________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/n/c&nbsp;&nbsp;Start/Stop&nbsp;Timeslot&nbsp;Trace</strong>
      </div>
      <div>
        NB:&nbsp;If&nbsp;encryption&nbsp;is&nbsp;not&nbsp;used,&nbsp;the&nbsp;two&nbsp;Test&nbsp;Points&nbsp;are&nbsp;identical
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Timeslot&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Test&nbsp;Point</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Stop&nbsp;Trace</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;U:&nbsp;Unencrypted&nbsp;(above&nbsp;UMAC&nbsp;encryption/decryption)
      </div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;E:&nbsp;Encrypted&nbsp;(LMAC/UMAC&nbsp;interface)</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>48/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48/C&nbsp;&nbsp;Clear&nbsp;Trace&nbsp;Counters</strong>
      </div>
      <div>48/C</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Tetra Channel Test:</h2>
    <br />
    <div>49&nbsp;TETRA&nbsp;CHANNEL&nbsp;TEST</div>
    <br />
    <div>
      The&nbsp;49&nbsp;commands&nbsp;controls&nbsp;the&nbsp;BS421&nbsp;build-in&nbsp;TETRA&nbsp;channel&nbsp;test&nbsp;features.
    </div>
    <br />
    <div>
      When&nbsp;channel&nbsp;test&nbsp;is&nbsp;activated,&nbsp;a&nbsp;test&nbsp;point&nbsp;at&nbsp;the&nbsp;output&nbsp;from&nbsp;or&nbsp;input&nbsp;to
    </div>
    <div>
      the&nbsp;receiver&nbsp;is&nbsp;selected.&nbsp;Received&nbsp;bursts&nbsp;including&nbsp;status&nbsp;will&nbsp;be&nbsp;analyzed
    </div>
    <div>and&nbsp;BER/MER&nbsp;calculated.</div>
    <br />
    <div>
      When&nbsp;channel&nbsp;test&nbsp;is&nbsp;activated,&nbsp;the&nbsp;BS421&nbsp;TX&nbsp;switches&nbsp;to&nbsp;uplink&nbsp;mode&nbsp;and
    </div>
    <div>
      generates&nbsp;the&nbsp;selected&nbsp;channel&nbsp;type.&nbsp;When&nbsp;TX&nbsp;is&nbsp;keyed&nbsp;and&nbsp;RFTL&nbsp;activated,
    </div>
    <div>an&nbsp;RX&nbsp;input&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;for&nbsp;the&nbsp;testing.</div>
    <br />
    <div>
      Alternatively,&nbsp;a&nbsp;test&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;from&nbsp;an&nbsp;external&nbsp;source,&nbsp;e.g.
    </div>
    <div>an&nbsp;R&amp;S&nbsp;SMIQ&nbsp;or&nbsp;Anritsu&nbsp;signal&nbsp;generator</div>
    <br />
    <div>
      The&nbsp;uplink&nbsp;test&nbsp;point&nbsp;is&nbsp;intended&nbsp;for&nbsp;normal&nbsp;receiver&nbsp;channel&nbsp;tests.
    </div>
    <br />
    <div>
      The&nbsp;downlink&nbsp;test&nbsp;point&nbsp;is&nbsp;intented&nbsp;for&nbsp;test&nbsp;of&nbsp;received&nbsp;traffic&nbsp;from&nbsp;the
    </div>
    <div>lines.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49&nbsp;&nbsp;Display&nbsp;Tetra&nbsp;channel&nbsp;test&nbsp;selection</strong>
      </div>
      <div>49</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49/nn/n&nbsp;&nbsp;Activate&nbsp;Tetra&nbsp;channel&nbsp;test</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Uplink&nbsp;test&nbsp;point&nbsp;channel&nbsp;types:</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;07:&nbsp;TCH/7.2</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;08:&nbsp;SCH/F</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;09:&nbsp;STCH</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;10:&nbsp;TCH/2.4&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;11:&nbsp;SCH/HU</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;16:&nbsp;TCH/S</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;18:&nbsp;TCH/4.8&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;Downlink&nbsp;test&nbsp;point&nbsp;channel&nbsp;types:</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;57:&nbsp;TCH/7.2</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;60:&nbsp;TCH/2.4&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;66:&nbsp;TCH/S</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;68:&nbsp;TCH/4.8&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>49/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49-&nbsp;&nbsp;Stop&nbsp;Tetra&nbsp;channel&nbsp;test</strong>
      </div>
      <div>49-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49/c&nbsp;&nbsp;Change&nbsp;Sync&nbsp;Mode</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;A:&nbsp;PRBS&nbsp;Auto&nbsp;Sync</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;M:&nbsp;PRBS&nbsp;Forced&nbsp;Multiframe&nbsp;Sync</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;PRBS&nbsp;Forced&nbsp;Timeslot&nbsp;Sync</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>49/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49/CNTR&nbsp;&nbsp;Display&nbsp;Counters</strong>
      </div>
      <div>49/CNTR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49/CLEAR&nbsp;&nbsp;Clear&nbsp;Counter&nbsp;Values</strong>
      </div>
      <div>49/CLEAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49/DATA&nbsp;&nbsp;Display&nbsp;Received&nbsp;Data</strong>
      </div>
      <div>49/DATA</div>
      <div />
      <div />
    </div>
    <h2>DC Voltmeter:</h2>
    <br />
    <div>60&nbsp;&nbsp;DC&nbsp;VOLTMETER</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displays&nbsp;the&nbsp;DC&nbsp;voltage&nbsp;on&nbsp;one&nbsp;of&nbsp;the&nbsp;DC&nbsp;test&nbsp;points.
    </div>
    <br />
    <div>
      The&nbsp;displayed&nbsp;reading&nbsp;is&nbsp;taken&nbsp;from&nbsp;a&nbsp;RAM&nbsp;register&nbsp;array,&nbsp;which&nbsp;is&nbsp;updated
    </div>
    <div>
      every&nbsp;24/1000&nbsp;sec.&nbsp;Therefore,&nbsp;some&nbsp;delay&nbsp;can&nbsp;be&nbsp;necessary&nbsp;in&nbsp;connection
    </div>
    <div>with&nbsp;automatic&nbsp;test&nbsp;equipment.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60&nbsp;&nbsp;Display&nbsp;DC&nbsp;test&nbsp;points</strong>
      </div>
      <div>60</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/nn&nbsp;&nbsp;Display&nbsp;one&nbsp;DC&nbsp;test&nbsp;point</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TP&nbsp;number&nbsp;(00..19)</div>
      <div>&nbsp;&nbsp;&nbsp;┴─</div>
      <div>60/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/T&nbsp;&nbsp;Display&nbsp;temperature</strong>
      </div>
      <div>60/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/ADC&nbsp;&nbsp;Display&nbsp;voltmeter&nbsp;ADC&nbsp;values</strong>
      </div>
      <div>60/ADC</div>
      <div />
      <div />
    </div>
    <h2>DSP:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61&nbsp;&nbsp;Display&nbsp;DSP&nbsp;state</strong>
      </div>
      <div>61</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61-&nbsp;&nbsp;Stop&nbsp;DSP&nbsp;execution</strong>
      </div>
      <div>Activates&nbsp;DSP&nbsp;hardware&nbsp;reset</div>
      <div>61-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61+&nbsp;&nbsp;Restart&nbsp;DSP</strong>
      </div>
      <div>Makes:</div>
      <div>&nbsp;&nbsp;1)&nbsp;Hardware&nbsp;reset&nbsp;on</div>
      <div>&nbsp;&nbsp;2)&nbsp;Hardware&nbsp;reset&nbsp;off</div>
      <div>&nbsp;&nbsp;3)&nbsp;DSP&nbsp;memory&nbsp;clear</div>
      <div>&nbsp;&nbsp;4)&nbsp;DSP&nbsp;code&nbsp;installation</div>
      <div>&nbsp;&nbsp;5)&nbsp;Interface&nbsp;register&nbsp;init</div>
      <div>&nbsp;&nbsp;6)&nbsp;Program&nbsp;execution&nbsp;start</div>
      <div>61+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/B&nbsp;&nbsp;Select&nbsp;DSP&nbsp;Boot&nbsp;State</strong>
      </div>
      <div>Makes:</div>
      <div>&nbsp;&nbsp;1)&nbsp;Hardware&nbsp;reset&nbsp;on</div>
      <div>&nbsp;&nbsp;2)&nbsp;Hardware&nbsp;reset&nbsp;off</div>
      <div>Afterward,&nbsp;manual&nbsp;R/W&nbsp;can&nbsp;be&nbsp;done</div>
      <div>61/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/S&nbsp;&nbsp;Select&nbsp;DSP&nbsp;Setup&nbsp;State</strong>
      </div>
      <div>Makes:</div>
      <div>&nbsp;&nbsp;1)&nbsp;Hardware&nbsp;reset&nbsp;on</div>
      <div>&nbsp;&nbsp;2)&nbsp;Hardware&nbsp;reset&nbsp;off</div>
      <div>&nbsp;&nbsp;3)&nbsp;DSP&nbsp;memory&nbsp;clear</div>
      <div>&nbsp;&nbsp;4)&nbsp;DSP&nbsp;code&nbsp;installation</div>
      <div>&nbsp;&nbsp;5)&nbsp;Interface&nbsp;register&nbsp;init</div>
      <div>
        Afterward,&nbsp;manual&nbsp;R/W&nbsp;and&nbsp;program&nbsp;execution&nbsp;start&nbsp;can&nbsp;be&nbsp;done
      </div>
      <div>61/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/R&nbsp;&nbsp;Start&nbsp;DSP&nbsp;program&nbsp;run</strong>
      </div>
      <div>DSP&nbsp;booted&nbsp;state&nbsp;necessary</div>
      <div>61/R</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhhh&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;word</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(Hex&nbsp;32-bit&nbsp;aligned)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhh0/1&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;8-bit&nbsp;wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/_______0/1</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhh0/2&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;16-bit&nbsp;wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/_______0/2</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhh0/4&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;32-bit&nbsp;wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/_______0/4</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhhh/hhhhhhhh&nbsp;&nbsp;Change&nbsp;DSP&nbsp;memory</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(Hex&nbsp;32-bit&nbsp;aligned)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Data&nbsp;to&nbsp;write&nbsp;(Hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────</div>
      <div>61/________/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/REG/nn&nbsp;&nbsp;Display&nbsp;DSP&nbsp;interface&nbsp;registers</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1st&nbsp;register&nbsp;to&nbsp;display&nbsp;(00..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>61/REG/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/REG/nn/nn&nbsp;&nbsp;Display&nbsp;DSP&nbsp;interface&nbsp;registers</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1st&nbsp;register&nbsp;to&nbsp;display&nbsp;(00..48)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;registers&nbsp;to&nbsp;display&nbsp;(01..48)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─</div>
      <div>61/REG/__/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/HPIA/hhhhhhhh&nbsp;&nbsp;DSP&nbsp;HPIA&nbsp;write/read&nbsp;test</strong>
      </div>
      <div>NB:&nbsp;Bit&nbsp;1+0&nbsp;always&nbsp;read&nbsp;0</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Value&nbsp;to&nbsp;write&nbsp;to&nbsp;HPIA&nbsp;(hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/HPIA/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/HPIC&nbsp;&nbsp;Display&nbsp;HPI&nbsp;Control&nbsp;Register</strong>
      </div>
      <div>61/HPIC</div>
      <div />
      <div />
    </div>
    <h2>CPLD:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>62&nbsp;&nbsp;Display&nbsp;CPLD&nbsp;version</strong>
      </div>
      <div>62</div>
      <div />
      <div />
    </div>
    <h2>Internal GPS:</h2>
    <br />
    <div>63&nbsp;&nbsp;INTERNAL&nbsp;GPS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63&nbsp;&nbsp;Display&nbsp;Internal&nbsp;GPS&nbsp;Status</strong>
      </div>
      <div>63</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63/VER&nbsp;&nbsp;Display&nbsp;GPS&nbsp;Module&nbsp;Version</strong>
      </div>
      <div>63/VER</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63/N&nbsp;&nbsp;Display&nbsp;next&nbsp;Internal&nbsp;GPS&nbsp;message</strong>
      </div>
      <div>Hint:&nbsp;Use&nbsp;the&nbsp;C/63/N&nbsp;command&nbsp;to&nbsp;display&nbsp;continuously</div>
      <div>63/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;Internal&nbsp;GPS&nbsp;RX&nbsp;extended&nbsp;message</strong>
      </div>
      <div>Hint:&nbsp;Use&nbsp;the&nbsp;C/63/N+&nbsp;command&nbsp;to&nbsp;display&nbsp;continuously</div>
      <div>63/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63/SETUP&nbsp;&nbsp;Setup&nbsp;GPS&nbsp;RX&nbsp;Module</strong>
      </div>
      <div>
        Setup&nbsp;the&nbsp;Internal&nbsp;GPS&nbsp;RX&nbsp;Module&nbsp;and&nbsp;saves&nbsp;the&nbsp;configuration&nbsp;in&nbsp;GPS&nbsp;RX&nbsp;flash&nbsp;
      </div>
      <div>
        This&nbsp;command&nbsp;shall&nbsp;be&nbsp;executed&nbsp;when&nbsp;an&nbsp;GPS&nbsp;RX&nbsp;module&nbsp;is&nbsp;installed&nbsp;first&nbsp;time
      </div>
      <div>63/SETUP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63/SEND/cc..c&nbsp;&nbsp;Send&nbsp;Command&nbsp;to&nbsp;GPS&nbsp;RX&nbsp;Module</strong>
      </div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;testing&nbsp;only</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;NMEA&nbsp;command</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────────────────────────────────────────
      </div>
      <div>63/SEND/____________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          63/SEND/UBX/hh/hh/hh..hh&nbsp;&nbsp;Send&nbsp;UBX&nbsp;Message&nbsp;to&nbsp;GPS&nbsp;RX&nbsp;Module
        </strong>
      </div>
      <div>Command&nbsp;to&nbsp;manully&nbsp;send&nbsp;UBX&nbsp;Message&nbsp;to&nbsp;GPS&nbsp;RX&nbsp;&nbsp;</div>
      <div>Sync,&nbsp;Length&nbsp;and&nbsp;Checksum&nbsp;(6&nbsp;Bytes)&nbsp;are&nbsp;added&nbsp;automatically.</div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;testing&nbsp;only</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class&nbsp;(00h..FFh)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;ID&nbsp;(00h..FFh)&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Data&nbsp;(hex)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34&nbsp;&nbsp;36&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>63/SEND/UBX/__/__/__________________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63/RESET/s&nbsp;&nbsp;GPS&nbsp;RX&nbsp;Module&nbsp;Reset</strong>
      </div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;testing&nbsp;only</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;GPS&nbsp;RX&nbsp;Reset:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Turn&nbsp;on&nbsp;reset</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Turn&nbsp;off&nbsp;reset&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>63/RESET/_</div>
      <div />
      <div />
    </div>
    <h2>Message Test Queue:</h2>
    <br />
    <div>65&nbsp;&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65&nbsp;&nbsp;Display&nbsp;BSC&nbsp;Link&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>65</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/N&nbsp;&nbsp;Display&nbsp;next&nbsp;message</strong>
      </div>
      <div>65/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/C&nbsp;&nbsp;Clear&nbsp;test&nbsp;queue</strong>
      </div>
      <div>65/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/S&nbsp;&nbsp;Set&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>65/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/s&nbsp;&nbsp;Message&nbsp;display&nbsp;suppress&nbsp;control</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;70h+F0h&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>65/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/c/hh/hh..hh&nbsp;&nbsp;Send&nbsp;message</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Destination</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;Active&nbsp;BSC</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;BSC1</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;BSC2</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;ID&nbsp;of&nbsp;Message&nbsp;(00h..FFh)&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Data&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>65/_/__/__________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>Host Performance:</h2>
    <br />
    <div>66&nbsp;&nbsp;HOST&nbsp;CPU&nbsp;PERFORMANCE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66&nbsp;&nbsp;Display&nbsp;Host&nbsp;CPU&nbsp;task&nbsp;load</strong>
      </div>
      <div>66</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/C&nbsp;&nbsp;Clear&nbsp;Host&nbsp;CPU&nbsp;Load&nbsp;Peak&nbsp;Hold</strong>
      </div>
      <div>66/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/T&nbsp;&nbsp;Display&nbsp;1msec.&nbsp;Timer</strong>
      </div>
      <div>66/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/Q&nbsp;&nbsp;Display&nbsp;QPC&nbsp;Counter</strong>
      </div>
      <div>66/Q</div>
      <div />
      <div />
    </div>
    <h2>Real Time Clock:</h2>
    <br />
    <div>68&nbsp;&nbsp;RTC&nbsp;NVRAM</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68&nbsp;&nbsp;Display&nbsp;RTC&nbsp;NVRAM</strong>
      </div>
      <div>68</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68/hh/hh&nbsp;&nbsp;Change&nbsp;RTC&nbsp;NVRAM</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;NVRAM&nbsp;Address&nbsp;[00..FFh]</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;NVRAM&nbsp;Data&nbsp;[00..FFh]</div>
      <div>&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─</div>
      <div>68/__/__</div>
      <div />
      <div />
    </div>
    <h2>Host Memory:</h2>
    <br />
    <div>69&nbsp;&nbsp;HOST&nbsp;MEMORY</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>69</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhhhhhh&nbsp;&nbsp;Display&nbsp;Host&nbsp;memory&nbsp;word</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Host&nbsp;memory&nbsp;address&nbsp;(Hex&nbsp;32-bit&nbsp;aligned)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>69/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhhhhh0/1&nbsp;&nbsp;Display&nbsp;Host&nbsp;memory&nbsp;8-bit&nbsp;wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Host&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>69/_______0/1</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhhhhh0/2&nbsp;&nbsp;Display&nbsp;Host&nbsp;memory&nbsp;16-bit&nbsp;wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Host&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>69/_______0/2</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhhhhh0/4&nbsp;&nbsp;Display&nbsp;Host&nbsp;memory&nbsp;32-bit&nbsp;wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Host&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>69/_______0/4</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhhhhhh/hhhhhhhh&nbsp;&nbsp;Change&nbsp;Host&nbsp;memory</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Host&nbsp;memory&nbsp;address&nbsp;(Hex&nbsp;32-bit&nbsp;aligned)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Data&nbsp;to&nbsp;write&nbsp;(Hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────</div>
      <div>69/________/________</div>
      <div />
      <div />
    </div>
    <h2>System configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70/SAVE&nbsp;&nbsp;Save&nbsp;System&nbsp;Configuration</strong>
      </div>
      <div>File:&nbsp;\CF-Card\Tetra\Data\ConfigSys.txt</div>
      <div>NB:&nbsp;From&nbsp;version&nbsp;7.50&nbsp;all&nbsp;changes&nbsp;are&nbsp;saved&nbsp;automatically</div>
      <div>70/SAVE</div>
      <div />
      <div />
    </div>
    <h2>Common system configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71&nbsp;&nbsp;Display&nbsp;Common&nbsp;System&nbsp;configuration</strong>
      </div>
      <div>71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/ID/cc..c&nbsp;&nbsp;Change&nbsp;TR&nbsp;ID&nbsp;String</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;ID&nbsp;String</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────</div>
      <div>71/ID/________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/TRADDR/nn&nbsp;&nbsp;Change&nbsp;TR&nbsp;Address</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>71/TRADDR/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/MSGPORT/nnnnn&nbsp;&nbsp;Change&nbsp;Local&nbsp;Message&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Local&nbsp;Message&nbsp;Port&nbsp;Number&nbsp;(00000..65535,&nbsp;default&nbsp;42391)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>71/MSGPORT/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/BSC/n/s&nbsp;&nbsp;Change&nbsp;BSC&nbsp;Configuration</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;BSC&nbsp;Configuration&nbsp;Flag</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Select&nbsp;BSC</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Deselect&nbsp;BSC</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>71/BSC/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/BSC/n/nnn.nnn.nnn.nnn&nbsp;&nbsp;Change&nbsp;BSC&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────</div>
      <div>71/BSC/_/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/BSC/n/s&nbsp;&nbsp;Change&nbsp;BSC&nbsp;Connection&nbsp;Protocol</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;BSC&nbsp;Connection&nbsp;Protocol</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;U:&nbsp;UDP</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;S:&nbsp;TCP&nbsp;Server&nbsp;(BSC&nbsp;shall&nbsp;be&nbsp;Client)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;C:&nbsp;TCP&nbsp;Client&nbsp;(Default)&nbsp;(BSC&nbsp;shall&nbsp;be&nbsp;Server)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>71/BSC/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/BSC/n/nnnnn&nbsp;&nbsp;Change&nbsp;BSC&nbsp;Remote&nbsp;Port&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;number&nbsp;(1..2)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Port&nbsp;Number&nbsp;(00000..65535,&nbsp;default&nbsp;42392)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴────</div>
      <div>71/BSC/_/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/CMOIP/BSS/s&nbsp;&nbsp;Change&nbsp;CMoIP&nbsp;to&nbsp;BSS&nbsp;selection</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;CMoIP&nbsp;Selection
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;CMoIP&nbsp;Multicast
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;CMoIP&nbsp;to&nbsp;BSS
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>71/CMOIP/BSS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/CMOIP/BSS/n/nnn.nnn.nnn.nnn&nbsp;&nbsp;Change&nbsp;CMoIP&nbsp;BSS&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Active&nbsp;BSC&nbsp;number&nbsp;(1..2)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────</div>
      <div>71/CMOIP/BSS/_/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/TXREFLWAR/c&nbsp;&nbsp;Change&nbsp;TX&nbsp;Reflected&nbsp;Alarm&nbsp;selection</strong>
      </div>
      <div>Can&nbsp;be&nbsp;set&nbsp;to&nbsp;avoid&nbsp;blocking&nbsp;alarm&nbsp;at&nbsp;poor&nbsp;TX&nbsp;antenna</div>
      <div>Blocking&nbsp;Alarm:</div>
      <div>&nbsp;&nbsp;Should&nbsp;normally&nbsp;be&nbsp;selected.</div>
      <div>&nbsp;&nbsp;Will&nbsp;the&nbsp;TR&nbsp;with&nbsp;bad&nbsp;antenna&nbsp;out&nbsp;of&nbsp;operation</div>
      <div>&nbsp;&nbsp;Ensures&nbsp;startup&nbsp;of&nbsp;MCCH&nbsp;on&nbsp;another&nbsp;TR</div>
      <div>Warning-only&nbsp;Alarm:</div>
      <div>&nbsp;&nbsp;Can&nbsp;be&nbsp;selected&nbsp;for&nbsp;single&nbsp;carrier&nbsp;systems</div>
      <div>
        &nbsp;&nbsp;Can&nbsp;be&nbsp;selected&nbsp;if&nbsp;there&nbsp;is&nbsp;a&nbsp;risk,&nbsp;that&nbsp;all&nbsp;antennas
      </div>
      <div>&nbsp;&nbsp;are&nbsp;bad&nbsp;(e.g.&nbsp;ice&nbsp;on&nbsp;antennas)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;TX&nbsp;Reflected&nbsp;Blocking&nbsp;Alarm&nbsp;(default)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;TX&nbsp;Reflected&nbsp;Warning-only&nbsp;alarm
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;</div>
      <div>71/TXREFLWAR/_</div>
      <div />
      <div />
    </div>
    <h2>Synchronization configurations</h2>
    <br />
    <div>72&nbsp;&nbsp;Synchronisation</div>
    <br />
    <div>
      The&nbsp;TETRA&nbsp;Base&nbsp;Station&nbsp;TR's&nbsp;need&nbsp;to&nbsp;be&nbsp;time&nbsp;synchronized&nbsp;to&nbsp;operate&nbsp;correctly.
    </div>
    <br />
    <div>
      Most&nbsp;importantly,&nbsp;all&nbsp;TR's&nbsp;in&nbsp;a&nbsp;radio&nbsp;cell&nbsp;shall&nbsp;be&nbsp;time&nbsp;synchronized&nbsp;together&nbsp;to&nbsp;
    </div>
    <div>
      ensure&nbsp;correct&nbsp;timing&nbsp;for&nbsp;the&nbsp;mobiles,&nbsp;when&nbsp;switching&nbsp;from&nbsp;the&nbsp;control&nbsp;channel&nbsp;to
    </div>
    <div>a&nbsp;traffic&nbsp;channel&nbsp;on&nbsp;another&nbsp;TR.</div>
    <br />
    <div>
      For&nbsp;a&nbsp;multi-cell&nbsp;systems,&nbsp;all&nbsp;radio&nbsp;cells&nbsp;are&nbsp;also&nbsp;recommended&nbsp;to&nbsp;be&nbsp;time&nbsp;synchronized&nbsp;together
    </div>
    <div>to&nbsp;enable&nbsp;use&nbsp;of&nbsp;seamless&nbsp;handover&nbsp;(cell&nbsp;re-selection).</div>
    <br />
    <div>
      The&nbsp;Damm&nbsp;BS&nbsp;system&nbsp;is&nbsp;provided&nbsp;with&nbsp;a&nbsp;flexible&nbsp;system&nbsp;to&nbsp;provide&nbsp;synchronization
    </div>
    <div>
      between&nbsp;individual&nbsp;Transceivers.&nbsp;Build-in&nbsp;GPS&nbsp;Receivers&nbsp;give&nbsp;absolute&nbsp;time
    </div>
    <div>
      synchronization&nbsp;to&nbsp;the&nbsp;radio&nbsp;cells.&nbsp;External&nbsp;Inputs&nbsp;can&nbsp;also&nbsp;be&nbsp;marked&nbsp;as&nbsp;Primary
    </div>
    <div>Sync&nbsp;References.&nbsp;&nbsp;</div>
    <br />
    <div>
      When&nbsp;a&nbsp;BS&nbsp;is&nbsp;Network&nbsp;locked,&nbsp;automatic&nbsp;frequency&nbsp;correction&nbsp;of&nbsp;the&nbsp;Master
    </div>
    <div>
      Oscillator&nbsp;(RX&nbsp;and&nbsp;TX&nbsp;carrier&nbsp;frequencies)&nbsp;are&nbsp;provided,&nbsp;and&nbsp;Seamless&nbsp;Handover&nbsp;to
    </div>
    <div>other&nbsp;Network&nbsp;Synchronized&nbsp;Cells&nbsp;are&nbsp;activated.</div>
    <br />
    <div>
      The&nbsp;BS&nbsp;can&nbsp;be&nbsp;setup&nbsp;to&nbsp;run&nbsp;as&nbsp;either&nbsp;Sync&nbsp;Master&nbsp;0..4&nbsp;or&nbsp;Slave.&nbsp;The&nbsp;Masters
    </div>
    <div>
      shall&nbsp;preferably&nbsp;be&nbsp;provided&nbsp;with&nbsp;GPS&nbsp;antennas&nbsp;or&nbsp;an&nbsp;External&nbsp;Primary&nbsp;Sync&nbsp;source.
    </div>
    <div>
      The&nbsp;remaining&nbsp;BS's&nbsp;shall&nbsp;be&nbsp;setup&nbsp;as&nbsp;Slave&nbsp;and&nbsp;do&nbsp;not&nbsp;need&nbsp;a&nbsp;GPS&nbsp;antenna.
    </div>
    <br />
    <div>
      The&nbsp;BS421&nbsp;is&nbsp;provided&nbsp;with&nbsp;one&nbsp;External&nbsp;1PPS&nbsp;(1&nbsp;Pulse&nbsp;Per&nbsp;Second)&nbsp;output&nbsp;and
    </div>
    <div>
      two&nbsp;external&nbsp;1PPS&nbsp;inputs.&nbsp;&nbsp;The&nbsp;Slaves&nbsp;shall&nbsp;have&nbsp;their&nbsp;external&nbsp;1PPS&nbsp;inputs
    </div>
    <div>connected&nbsp;to&nbsp;Masters.</div>
    <br />
    <div>
      In&nbsp;addition&nbsp;to&nbsp;the&nbsp;hardware&nbsp;1PPS,&nbsp;sync&nbsp;messages&nbsp;are&nbsp;send&nbsp;every&nbsp;second&nbsp;on&nbsp;IP
    </div>
    <div>from&nbsp;the&nbsp;Masters&nbsp;to&nbsp;attached&nbsp;BS's.</div>
    <br />
    <div>A&nbsp;Master&nbsp;will&nbsp;indicate&nbsp;Master&nbsp;Mode,&nbsp;if:</div>
    <br />
    <div>&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;Synchronized&nbsp;to&nbsp;the&nbsp;internal&nbsp;GPS&nbsp;RX&nbsp;or</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;Synchronized&nbsp;to&nbsp;an&nbsp;External&nbsp;Input&nbsp;marked&nbsp;as&nbsp;Primary&nbsp;and&nbsp;indicating
    </div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Master&nbsp;Mode</div>
    <br />
    <div>Master&nbsp;0&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div>
    <br />
    <div>Master&nbsp;1&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div>
    <br />
    <div>Master&nbsp;2&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1&nbsp;</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div>
    <br />
    <div>Master&nbsp;3&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1&nbsp;</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;2&nbsp;</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div>
    <br />
    <div>Master&nbsp;4&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1&nbsp;</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;2&nbsp;</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;3&nbsp;</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div>
    <br />
    <div>Slave&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;2</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;3</div>
    <div>&nbsp;&nbsp;10)Ext.&nbsp;Master&nbsp;4</div>
    <div>&nbsp;&nbsp;11)Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;12)Int.&nbsp;Free-Run</div>
    <br />
    <div>
      If&nbsp;no&nbsp;GPS&nbsp;synchronized&nbsp;Master&nbsp;or&nbsp;higher&nbsp;prioritized&nbsp;free-running&nbsp;Master&nbsp;is
    </div>
    <div>
      available&nbsp;at&nbsp;re-start,&nbsp;Masters&nbsp;will&nbsp;try&nbsp;initially&nbsp;to&nbsp;synchronize&nbsp;to&nbsp;lower
    </div>
    <div>prioritized&nbsp;free-running&nbsp;Masters.</div>
    <br />
    <div>
      A&nbsp;BS&nbsp;will&nbsp;resync&nbsp;automatically&nbsp;after&nbsp;a&nbsp;short&nbsp;time,&nbsp;if&nbsp;Century&nbsp;Second
    </div>
    <div>
      and&nbsp;Phase&nbsp;Detector&nbsp;are&nbsp;out&nbsp;of&nbsp;sync&nbsp;with&nbsp;a&nbsp;valid&nbsp;sync&nbsp;source.
    </div>
    <br />
    <div>
      Masters&nbsp;shall&nbsp;be&nbsp;setup&nbsp;even&nbsp;if&nbsp;GPS&nbsp;antennas&nbsp;are&nbsp;not&nbsp;used&nbsp;and&nbsp;internal&nbsp;GPS&nbsp;RX
    </div>
    <div>
      are&nbsp;deselected,&nbsp;to&nbsp;ensure&nbsp;proper&nbsp;synchronization&nbsp;between&nbsp;all&nbsp;TR's.
    </div>
    <br />
    <div>Typical&nbsp;1-carrier&nbsp;BS421&nbsp;configuration:</div>
    <div>&nbsp;&nbsp;TR11:&nbsp;Master&nbsp;1</div>
    <br />
    <div>Typical&nbsp;2-carrier&nbsp;BS421&nbsp;configuration:</div>
    <div>&nbsp;&nbsp;TR11:&nbsp;Master&nbsp;1</div>
    <div>&nbsp;&nbsp;TR12:&nbsp;Master&nbsp;2</div>
    <div>TR11-OUT&nbsp;connected&nbsp;to&nbsp;TR12-IN1&nbsp;&nbsp;</div>
    <div>TR12-OUT&nbsp;connected&nbsp;to&nbsp;TR11-IN1&nbsp;&nbsp;</div>
    <br />
    <div>Typical&nbsp;3&nbsp;o4&nbsp;4-carrier&nbsp;BS421&nbsp;configuration:</div>
    <div>&nbsp;&nbsp;TR11:&nbsp;Master&nbsp;1&nbsp;(Powered&nbsp;of&nbsp;SB421-1)</div>
    <div>&nbsp;&nbsp;TR12:&nbsp;Slave&nbsp;&nbsp;&nbsp;&nbsp;(Powered&nbsp;of&nbsp;SB421-1)&nbsp;&nbsp;</div>
    <div>&nbsp;&nbsp;TR13:&nbsp;Master&nbsp;2&nbsp;(Powered&nbsp;of&nbsp;SB421-2)</div>
    <div>&nbsp;&nbsp;TR14:&nbsp;Slave&nbsp;&nbsp;&nbsp;&nbsp;(Powered&nbsp;of&nbsp;SB421-2)</div>
    <div>TR11-OUT&nbsp;connected&nbsp;to&nbsp;TR12-IN1,&nbsp;TR13-IN1&nbsp;and&nbsp;TR14-IN1&nbsp;&nbsp;</div>
    <div>TR13-OUT&nbsp;connected&nbsp;to&nbsp;TR11-IN1,&nbsp;TR12-IN2&nbsp;and&nbsp;TR14-IN2&nbsp;&nbsp;</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72&nbsp;&nbsp;Display&nbsp;Sync&nbsp;selection</strong>
      </div>
      <div>72</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/MASTER/c&nbsp;&nbsp;Change&nbsp;Master&nbsp;Priority</strong>
      </div>
      <div>Changes&nbsp;the&nbsp;Synchronization&nbsp;Priority&nbsp;for&nbsp;the&nbsp;BS</div>
      <div>
        Master&nbsp;1&nbsp;shall&nbsp;be&nbsp;selected&nbsp;for&nbsp;the&nbsp;Primary&nbsp;Master&nbsp;in&nbsp;a&nbsp;cell.
      </div>
      <div>
        Master&nbsp;2&nbsp;shall&nbsp;be&nbsp;selected&nbsp;for&nbsp;the&nbsp;Backup&nbsp;Master&nbsp;in&nbsp;a&nbsp;cell.
      </div>
      <div>
        Master&nbsp;0&nbsp;shall&nbsp;only&nbsp;be&nbsp;selected&nbsp;if&nbsp;the&nbsp;BS&nbsp;is&nbsp;Global&nbsp;Master&nbsp;used&nbsp;to
      </div>
      <div>synchronize&nbsp;other&nbsp;cells.</div>
      <div>
        Warning:&nbsp;The&nbsp;Master&nbsp;selections&nbsp;shall&nbsp;be&nbsp;unique&nbsp;within&nbsp;a&nbsp;Radio&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0:&nbsp;Master&nbsp;0&nbsp;(For&nbsp;Global&nbsp;Master)&nbsp;(Highest&nbsp;Priority)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;1:&nbsp;Master&nbsp;1&nbsp;(For&nbsp;Primary&nbsp;Master&nbsp;in&nbsp;a&nbsp;Cell)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Master&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;3:&nbsp;Master&nbsp;3</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;4:&nbsp;Master&nbsp;4&nbsp;(Lowest&nbsp;Priority)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;S:&nbsp;Slave</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;</div>
      <div>72/MASTER/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/CENTSECOFS/snnnnnnnnnn&nbsp;&nbsp;Change&nbsp;Century&nbsp;Second&nbsp;offset</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;change&nbsp;the&nbsp;Century&nbsp;Second&nbsp;offset,&nbsp;where&nbsp;all&nbsp;TETRA&nbsp;counters&nbsp;started
      </div>
      <div>For&nbsp;standard&nbsp;Damm&nbsp;TetraFlex&nbsp;this&nbsp;value&nbsp;shall&nbsp;be&nbsp;0.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Century&nbsp;Second&nbsp;Offset
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;DAMM:&nbsp;+0000000000&nbsp;[2000-01-01&nbsp;00:00:00&nbsp;UTC]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;EADS:&nbsp;+0031536013&nbsp;[1999-01-01&nbsp;00:00:00&nbsp;GPS]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────</div>
      <div>72/CENTSECOFS/___________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/CENTSECOFS/DAMM&nbsp;&nbsp;Change&nbsp;Century&nbsp;Second&nbsp;offset&nbsp;to&nbsp;DAMM</strong>
      </div>
      <div>Used&nbsp;to&nbsp;clear&nbsp;Century&nbsp;Seconds&nbsp;offset&nbsp;for&nbsp;DAMM&nbsp;timing</div>
      <div>Value:&nbsp;0&nbsp;for&nbsp;2000-01-01&nbsp;00:00:00&nbsp;UTC</div>
      <div>72/CENTSECOFS/DAMM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/CENTSECOFS/EADS&nbsp;&nbsp;Change&nbsp;Century&nbsp;Second&nbsp;offset&nbsp;to&nbsp;EADS</strong>
      </div>
      <div>Used&nbsp;to&nbsp;setup&nbsp;the&nbsp;Centrury&nbsp;Seconds&nbsp;offset&nbsp;for&nbsp;EADS&nbsp;timing</div>
      <div>
        Value:&nbsp;+31536013&nbsp;(1&nbsp;year&nbsp;+&nbsp;13&nbsp;leap&nbsp;seconds)&nbsp;for&nbsp;1999-01-01&nbsp;00:00:00&nbsp;GPS
      </div>
      <div>72/CENTSECOFS/EADS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/GPSRXOFS/snnn.n&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;offset</strong>
      </div>
      <div>Used&nbsp;to&nbsp;change&nbsp;the&nbsp;GPS&nbsp;RX&nbsp;time&nbsp;offset</div>
      <div>For&nbsp;standard&nbsp;Damm&nbsp;TetraFlex&nbsp;this&nbsp;value&nbsp;shall&nbsp;be&nbsp;0.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;GPS&nbsp;RX&nbsp;offset</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;DAMM:&nbsp;+000.0&nbsp;[usec.]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;EADS:&nbsp;+111.1&nbsp;[usec.]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>72/GPSRXOFS/____._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/GPSRXOFS/DAMM&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;to&nbsp;DAMM</strong>
      </div>
      <div>Used&nbsp;to&nbsp;clear&nbsp;the&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;for&nbsp;DAMM&nbsp;timing</div>
      <div>Value:&nbsp;+000.0&nbsp;userc.</div>
      <div>72/GPSRXOFS/DAMM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/GPSRXOFS/EADS&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;to&nbsp;EADS</strong>
      </div>
      <div>Used&nbsp;to&nbsp;setup&nbsp;the&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;for&nbsp;EADS&nbsp;timing</div>
      <div>Value:&nbsp;-055.6&nbsp;usec.</div>
      <div>72/GPSRXOFS/EADS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/IN/n/s&nbsp;&nbsp;Activate/Deactivate&nbsp;Sync&nbsp;Input</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Input</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;GPS&nbsp;RX</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Ext.&nbsp;1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;Ext.&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Activate/Deactivate</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>72/IN/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/IN/n/...&nbsp;&nbsp;Setup&nbsp;External&nbsp;Sync&nbsp;Input</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Input</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;External&nbsp;1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;External&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Primary&nbsp;Flag</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;Primary&nbsp;Input&nbsp;(Normal)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Primary&nbsp;Input&nbsp;(For&nbsp;Remote&nbsp;Primary&nbsp;Reference&nbsp;only)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(000..255)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Cable&nbsp;Delay&nbsp;in&nbsp;usec.&nbsp;(000.0..999.9)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴──────────────&nbsp;┴────</div>
      <div>72/IN/_/_/___.___.___.___/___._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/OUT&nbsp;&nbsp;Display&nbsp;Sync&nbsp;Message&nbsp;Output&nbsp;Table</strong>
      </div>
      <div>72/OUT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/OUT/...&nbsp;&nbsp;Add/Remove&nbsp;Sync&nbsp;Message&nbsp;Output</strong>
      </div>
      <div>
        All&nbsp;units&nbsp;connected&nbsp;to&nbsp;the&nbsp;1PPS&nbsp;output&nbsp;shall&nbsp;be&nbsp;added&nbsp;here
      </div>
      <div>Max.&nbsp;30&nbsp;IP&nbsp;Addresses&nbsp;can&nbsp;be&nbsp;configured</div>
      <div>Outputs&nbsp;are&nbsp;NOT&nbsp;active&nbsp;if&nbsp;Sync&nbsp;Slave&nbsp;Priority</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(000..255)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Action:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Add
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Remove
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴</div>
      <div>72/OUT/___.___.___.___/_</div>
      <div />
      <div />
    </div>
    <h2>Factory configuration:</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90&nbsp;&nbsp;Display&nbsp;factory&nbsp;lock&nbsp;status</strong>
      </div>
      <div>90</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/FACTORYUNLOCK&nbsp;&nbsp;Select&nbsp;factory&nbsp;configuration&nbsp;unlock</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UNLOCK</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>90/FACTORY______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90-&nbsp;&nbsp;Deselect&nbsp;factory&nbsp;configuration&nbsp;unlock</strong>
      </div>
      <div>90-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/CNFG&nbsp;&nbsp;Return&nbsp;current&nbsp;Factory&nbsp;Configuration</strong>
      </div>
      <div>90/CNFG</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/FLASH&nbsp;&nbsp;Return&nbsp;Factory&nbsp;Configuration&nbsp;in&nbsp;Flash</strong>
      </div>
      <div>90/FLASH</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/SAVE&nbsp;&nbsp;Save&nbsp;Factory&nbsp;Configuration&nbsp;in&nbsp;Flash</strong>
      </div>
      <div>90/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/CLEAR&nbsp;&nbsp;Clear&nbsp;Flash&nbsp;Factory&nbsp;Configuration</strong>
      </div>
      <div>90/CLEAR</div>
      <div />
      <div />
    </div>
    <h2>TX factory configuration:</h2>
    <br />
    <div>91&nbsp;&nbsp;TX&nbsp;SETTINGS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91&nbsp;&nbsp;Display&nbsp;TX&nbsp;factory&nbsp;configuration</strong>
      </div>
      <div>91</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/PWRADJ/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Output&nbsp;Power</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.700..1.400</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/PWRADJ/_.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/BIASDR/nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Driver&nbsp;Bias</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;000..255</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/BIASDR/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/BIASPA/nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;PA&nbsp;Bias</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;000..255</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/BIASPA/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/IZEROOL/snnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Open&nbsp;Loop&nbsp;I-zero</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-9999..+9999</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/IZEROOL/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/QZEROOL/snnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Open&nbsp;Loop&nbsp;Q-zero</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-9999..+9999</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/QZEROOL/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/LOOPGAIN/nnn&nbsp;&nbsp;Change&nbsp;Loop&nbsp;Gain</strong>
      </div>
      <div>Used&nbsp;to&nbsp;reduce&nbsp;DAC&nbsp;output&nbsp;level&nbsp;at&nbsp;open&nbsp;loop</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;010..200</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/LOOPGAIN/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/QAMPL/n.nnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Q-amplitude&nbsp;balance</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.9000..1.1000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>91/QAMPL/_.____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/QPHASE/sn.nnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Q-phase&nbsp;quadrature</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-0.2500..+0.2500</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>91/QPHASE/__.____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/PH0/nnn&nbsp;&nbsp;Select&nbsp;Cartesian&nbsp;Loop&nbsp;channel&nbsp;0&nbsp;phase&nbsp;adjust&nbsp;value
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;0&nbsp;phase&nbsp;adjust&nbsp;value&nbsp;in&nbsp;degree&nbsp;(000..359)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/PH0/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/PHSTEP/snnn&nbsp;&nbsp;Change&nbsp;Cartesian&nbsp;Loop&nbsp;channel&nbsp;phase&nbsp;step&nbsp;value
        </strong>
      </div>
      <div>Typical&nbsp;value:</div>
      <div>&nbsp;&nbsp;400MHz:&nbsp;+055</div>
      <div>&nbsp;&nbsp;800MHz:&nbsp;+030</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Phase&nbsp;change&nbsp;per&nbsp;channel&nbsp;in&nbsp;millidegree&nbsp;(-999..+999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>91/PHSTEP/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/ZERO&nbsp;&nbsp;Display&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table</strong>
      </div>
      <div>91/ZERO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/ZERO/nnn&nbsp;&nbsp;Setup&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table&nbsp;channel&nbsp;step
        </strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;max.&nbsp;channel&nbsp;number&nbsp;divided&nbsp;by&nbsp;8</div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;400MHz&nbsp;range:&nbsp;200&nbsp;&nbsp;(1600/8)</div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;800MHz&nbsp;range:&nbsp;400&nbsp;&nbsp;(3200/8)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;channels&nbsp;between&nbsp;table&nbsp;entries&nbsp;(010..500)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/ZERO/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/ZERO/n/snnnn/snnnn&nbsp;&nbsp;Setup&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table&nbsp;value
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Table&nbsp;entry&nbsp;position&nbsp;(0..8)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;I&nbsp;zero&nbsp;adjust&nbsp;value&nbsp;(-9999..+9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Q&nbsp;zero&nbsp;adjust&nbsp;value&nbsp;(-9999..+9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴────&nbsp;┴────</div>
      <div>91/ZERO/_/_____/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/PMOUT/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Power&nbsp;Meter&nbsp;output&nbsp;setting</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.700..1.400</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/PMOUT/_.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/PMREFL/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Power&nbsp;Meter&nbsp;reflected&nbsp;setting</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.700..1.400</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/PMREFL/_.___</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Common factory configuration:</h2>
    <br />
    <div>93&nbsp;&nbsp;COMMON&nbsp;SETTINGS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93&nbsp;&nbsp;Display&nbsp;common&nbsp;factory&nbsp;settings</strong>
      </div>
      <div>93</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHIFOFS/snnnn&nbsp;&nbsp;Change&nbsp;1st&nbsp;LO&nbsp;IF&nbsp;channel&nbsp;offset</strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;(1st&nbsp;IF&nbsp;frequency)/6.25kHz</div>
      <div>Typical&nbsp;value:&nbsp;-7200&nbsp;for&nbsp;-45MHz</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1st&nbsp;LO&nbsp;IF&nbsp;offset&nbsp;(-9999..+9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-45MHz:&nbsp;-7200</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>93/CHIFOFS/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHRX0/nnnnnn&nbsp;&nbsp;Change&nbsp;RX&nbsp;channel&nbsp;zero</strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;(RX&nbsp;channel&nbsp;0&nbsp;frequency)/6.25kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;channel&nbsp;0</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;300MHz:&nbsp;048000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;350MHz:&nbsp;056000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;380MHz:&nbsp;060800</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;410MHz:&nbsp;065600</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;450MHz:&nbsp;072000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;805MHz:&nbsp;128800</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>93/CHRX0/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHTX0/nnnnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;channel&nbsp;zero</strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;(TX&nbsp;channel&nbsp;0&nbsp;frequency)/6.25kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;channel&nbsp;0</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;336MHz:&nbsp;053760</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;360MHz:&nbsp;057600</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;390MHz:&nbsp;062400</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;420MHz:&nbsp;067200</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;460MHz:&nbsp;073600</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;850MHz:&nbsp;136000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>93/CHTX0/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHTRMIN/nnnn&nbsp;&nbsp;Change&nbsp;lowest&nbsp;Transceiver&nbsp;channel&nbsp;number</strong>
      </div>
      <div>Normal&nbsp;setting:&nbsp;0000</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>93/CHTRMIN/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHTRMAX/nnnn&nbsp;&nbsp;Change&nbsp;highest&nbsp;Transceiver&nbsp;channel&nbsp;number</strong>
      </div>
      <div>Normal&nbsp;400MHz&nbsp;setting:&nbsp;1600</div>
      <div>Normal&nbsp;800MHz&nbsp;setting:&nbsp;3200</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>93/CHTRMAX/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHDFMIN/nnnn&nbsp;&nbsp;Change&nbsp;lowest&nbsp;Duplex&nbsp;Filter&nbsp;channel&nbsp;number</strong>
      </div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;400MHz&nbsp;with&nbsp;5MHz&nbsp;bandwidth:</div>
      <div>&nbsp;&nbsp;Low&nbsp;band&nbsp;:&nbsp;0000</div>
      <div>&nbsp;&nbsp;High&nbsp;band:&nbsp;0800</div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;800MHz&nbsp;with&nbsp;14MHz&nbsp;bandwidth:</div>
      <div>&nbsp;&nbsp;Low&nbsp;band&nbsp;:&nbsp;0000</div>
      <div>&nbsp;&nbsp;High&nbsp;band:&nbsp;0960</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>93/CHDFMIN/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHDFMAX/nnnn&nbsp;&nbsp;Change&nbsp;highest&nbsp;Duplex&nbsp;Filter&nbsp;channel&nbsp;number</strong>
      </div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;400MHz&nbsp;with&nbsp;5MHz&nbsp;bandwidth:</div>
      <div>&nbsp;&nbsp;Low&nbsp;band&nbsp;:&nbsp;0800</div>
      <div>&nbsp;&nbsp;High&nbsp;band:&nbsp;1600</div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;800MHz&nbsp;with&nbsp;14MHz&nbsp;bandwidth:</div>
      <div>&nbsp;&nbsp;Low&nbsp;band&nbsp;:&nbsp;2240</div>
      <div>&nbsp;&nbsp;High&nbsp;band:&nbsp;3200</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>93/CHDFMAX/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/DFTXLOSS/n.n&nbsp;&nbsp;Duplex&nbsp;Filter&nbsp;TX&nbsp;Path&nbsp;Loss</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Loss&nbsp;in&nbsp;dB&nbsp;[0.0..3.0]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>93/DFTXLOSS/_._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/DFRXALOSS/n.n&nbsp;&nbsp;Duplex&nbsp;Filter&nbsp;RX-A&nbsp;Path&nbsp;Loss</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Loss&nbsp;in&nbsp;dB&nbsp;[0.0..3.0]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>93/DFRXALOSS/_._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/DFRXBLOSS/n.n&nbsp;&nbsp;Duplex&nbsp;Filter&nbsp;RX-B&nbsp;Path&nbsp;Loss</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Loss&nbsp;in&nbsp;dB&nbsp;[0.0..3.0]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>93/DFRXBLOSS/_._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/SYNCEXT2/c&nbsp;&nbsp;Sync&nbsp;External&nbsp;2&nbsp;input&nbsp;selection</strong>
      </div>
      <div>Shall&nbsp;be&nbsp;set&nbsp;according&nbsp;to&nbsp;actual&nbsp;hardware&nbsp;configuration</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BS421&nbsp;LAN&nbsp;Cable&nbsp;Second&nbsp;Out/In2:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;Auto&nbsp;select
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Out&nbsp;(Soldered&nbsp;jumper)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;In2&nbsp;(Soldered&nbsp;jumper)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>93/SYNCEXT2/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/GPSRX/c&nbsp;&nbsp;GPS&nbsp;RX&nbsp;Type&nbsp;selection</strong>
      </div>
      <div>Shall&nbsp;be&nbsp;set&nbsp;to&nbsp;installed&nbsp;GPS&nbsp;Receiver&nbsp;type</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;GPS&nbsp;RX&nbsp;type:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;iTrax02&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;iTrax03</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;6:&nbsp;IT600</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;8:&nbsp;EVA-M8M</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>93/GPSRX/_</div>
      <div />
      <div />
    </div>
    <h2>Hardware identity:</h2>
    <br />
    <div>98&nbsp;&nbsp;HARDWARE&nbsp;ID</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98&nbsp;&nbsp;Display&nbsp;Hardware&nbsp;Identity</strong>
      </div>
      <div>98</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/ITEM/cc..c&nbsp;&nbsp;Change&nbsp;item&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Item&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────</div>
      <div>98/ITEM/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/SER/cc..c&nbsp;&nbsp;Change&nbsp;serial&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────</div>
      <div>98/SER/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/VER/cc..c&nbsp;&nbsp;Change&nbsp;version&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Version&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────</div>
      <div>98/VER/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/REV/cc..c&nbsp;&nbsp;Change&nbsp;revisions</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Revisions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────</div>
      <div>98/REV/________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/DFSER/cc..c&nbsp;&nbsp;Change&nbsp;Duplex&nbsp;Filter&nbsp;Serial&nbsp;No.</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Duplex&nbsp;Filter&nbsp;Serial&nbsp;No.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────</div>
      <div>98/DFSER/________________</div>
      <div />
      <div />
    </div>
    <h2>TR421 Control Commands</h2>
    <br />
    <div>99&nbsp;&nbsp;TR421&nbsp;CONTROL&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>99</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99/STOP&nbsp;&nbsp;Stop&nbsp;TR421&nbsp;service</strong>
      </div>
      <div>Makes&nbsp;a&nbsp;normal&nbsp;shutdown&nbsp;of&nbsp;the&nbsp;service</div>
      <div>99/STOP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99/RESTART&nbsp;&nbsp;Restart&nbsp;TR421&nbsp;service</strong>
      </div>
      <div>Makes:</div>
      <div>1)&nbsp;Shutdown&nbsp;of&nbsp;TR421&nbsp;Service</div>
      <div>2)&nbsp;Copy&nbsp;of&nbsp;TR421.dll&nbsp;to&nbsp;TR421_.dll</div>
      <div>3)&nbsp;Startup&nbsp;of&nbsp;TR421&nbsp;Service&nbsp;again</div>
      <div>99/RESTART</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99/HWRESET&nbsp;&nbsp;Make&nbsp;BS421&nbsp;Hardware&nbsp;Reset</strong>
      </div>
      <div>Uses&nbsp;Watchdog&nbsp;in&nbsp;RTC</div>
      <div>99/HWRESET</div>
      <div />
    </div>
    <br />
  </>
);

export default memo(TR421Page) as typeof TR421Page;
