/* eslint-disable react/jsx-props-no-spreading */
import { BaseOptionType, DefaultOptionType } from 'antd/lib/cascader';
import type { SelectProps as AntSelectProps } from 'antd/lib/select';
import AntSelect, { RefSelectProps } from 'antd/lib/select';
import type { FC } from 'react';
import React, { memo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 0.5rem;
  }
`;

type SelectProps = AntSelectProps<any, Partial<DefaultOptionType> & BaseOptionType>;

export const StyledSelect = styled<React.ComponentType<SelectProps & React.RefAttributes<RefSelectProps>>>(
  AntSelect,
)<SelectProps>`
  &.ant-select {
    display: block;

    .ant-select-selector {
      //height: 42px;
      //padding-top: 6px;
      //padding-bottom: 6px;
      border-radius: 0.5rem;
    }

    &:hover,
    &:focus {
      border-color: ${props => props.theme.token.colorPrimary};
    }
  }
`;
export const Select: FC<SelectProps> = props => (
  <>
    <StyledSelect {...props} />
    <GlobalStyles />
  </>
);

export const SelectOption = styled(AntSelect.Option)``;

type TagRenderProps = Parameters<Required<Pick<SelectProps, 'tagRender'>>['tagRender']>[0];

export default memo(Select) as typeof Select;
export type { SelectProps, TagRenderProps };
