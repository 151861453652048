// React
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import time from 'src/helpers/time';
//
import getNodeStatusListQuery from 'src/graphql/queries/get-nodes-statuses.graphql';
import nodeStatusCreatedSubscription from 'src/graphql/subscriptions/node-status-created.graphql';
import nodeStatusChangedSubscription from 'src/graphql/subscriptions/node-status-changed.graphql';
//
import Table from 'src/components/ui-kit/table';
import Status from 'src/components/ui-kit/project-specific/online-status-dot';

import { renderConfigured, softwareVersions } from 'src/helpers/tetra';

const NodeStatus = styled.div`
  display: flex;
  align-items: center;
`;

const StatusTab = () => {
  const { t } = useTranslation();
  return (
    <Table
      pagination
      pageSize={40}
      dataType="NodeStatus"
      query={getNodeStatusListQuery}
      variables={{ filter: { tab: 'status' } }}
      sorter={{ columnKey: 'NodeNo', order: 'ascend' }}
      subscriptionQueries={[nodeStatusCreatedSubscription, nodeStatusChangedSubscription]}
      rowKey="id"
      columns={[
        {
          title: t('Node'),
          dataIndex: 'number',
          width: 180,
          render(_, { number, bsc, alarmState }) {
            return (
              <NodeStatus>
                <Status state={alarmState} />
                <span>{`${String(number).padStart(3, '0')} [BSC${bsc + 1}]`}</span>
              </NodeStatus>
            );
          },
        },
        {
          title: t('Description column'),
          dataIndex: 'description',
          render(userAgent) {
            return userAgent || 'Не указано';
          },
        },
        {
          title: t('HW Type'),
          dataIndex: 'hwType',
          render: renderConfigured((_, { hwType }) => ['PC', 'BSC421', 'BSC412'][hwType]),
        },
        {
          title: t('Software version'),
          dataIndex: 'softwareVersion',
          render: renderConfigured(
            (_, { softwareVersion, softwareDate }) =>
              `${softwareVersions[softwareVersion] || softwareVersion} ${time(softwareDate, 'YYYYMMDD').format(
                'YYYY-MM-DD',
              )}`,
          ),
        },
        {
          title: t('Nodes'),
          dataIndex: 'nodeCount',
          render: renderConfigured((_, { nodeCount }) => nodeCount),
        },
        {
          title: t('Last msg.'),
          dataIndex: 'timestamp',
          render(_, { timestamp }) {
            return `${time(timestamp).format('YYYY-MM-DD HH:mm:ss')}`;
          },
        },
      ]}
    />
  );
};

export default StatusTab;
