/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';

export const Switch = styled(AntSwitch)`
  &.ant-switch {
    & + & {
      margin-left: 0.5rem;
    }

    height: 2rem;
    font-size: 1rem;
    &:after {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 0.1rem;
      left: calc(100% - 2px);
    }
    .ant-switch-inner {
      font-size: 0.85rem;
      margin-left: 0.75rem;
      margin-right: 2rem;
    }

    &:not(.ant-switch-checked) {
      &:after {
        left: 3px;
      }

      .ant-switch-inner {
        margin-right: 0.75rem;
        margin-left: 2rem;
      }
    }
  }
`;
