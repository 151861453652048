import React, { memo, useMemo, useCallback, useState, useEffect, FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSubscribeToMore, getListKeyFromDataType, logger, compareIds } from '@fjedi/graphql-react-components';
import { useLocation, useNavigate, useParams } from '@fjedi/react-router-helpers';
import styled from 'styled-components';
import Spinner from 'src/components/ui-kit/spinner';

interface HeaderProps {
  rawData: {
    rows: unknown[];
    count: number;
    [k: string]: unknown;
  };
  rows: unknown[];
  count: number;
  hasMore: boolean;
}
//
interface ScrollPaginationProp {
  startPage?: number;
  useURLNavigation?: boolean;
  query: any;
  subscriptionQueries?: any[];
  subscriptionId?: string;
  dataType: string;
  variables: any;
  renderRow: (row: any) => React.ReactElement;
  renderHeader?: (data: HeaderProps) => React.ReactElement;
  // skip?: boolean;
  rowsPerPage?: number;
  noMoreDataComponent?: React.ReactElement;
  scrollThreshold?: string | number;
}

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_SCROLL_THRESHOLD = 0.9;

const StyledSpinner = styled(Spinner)``;

export const SpinnerComponent = memo<{ hasNextPage?: boolean; loading?: boolean }>(({ loading, hasNextPage }) => (
  <div
    className="next-page-loader"
    style={{
      visibility: loading ? 'visible' : 'hidden',
      display: hasNextPage ? 'flex' : 'none',
      width: '100%',
      justifyContent: 'center',
    }}>
    <StyledSpinner />
  </div>
));

const ScrollPagination: FC<ScrollPaginationProp> = props => {
  const navigate = useNavigate();
  const queryParams = useParams();
  const { pathname } = useLocation();
  const {
    query,
    subscriptionQueries,
    subscriptionId,
    dataType,
    variables,
    renderRow,
    renderHeader,
    startPage,
    rowsPerPage = DEFAULT_ROWS_PER_PAGE,
    noMoreDataComponent,
    scrollThreshold,
    useURLNavigation = false,
  } = props;
  const [page, setPage] = useState(startPage ?? 1);
  const { data, fetchMore, subscribeToMore } = query;
  const subscriptionProps = useMemo(
    () => ({
      dataType,
      subscriptionQueries,
      subscribeToMore,
      variables,
      subscriptionId,
    }),
    [variables, subscribeToMore, subscriptionQueries, dataType, subscriptionId],
  );
  // @ts-ignore
  useSubscribeToMore(subscriptionProps);
  //
  const listKey = getListKeyFromDataType(dataType);
  const paginatedList = data?.[listKey];
  const rows = useMemo(() => paginatedList?.rows ?? [], [paginatedList]);
  const count = paginatedList?.count ?? 0;
  const hasMore = rows.length < count;
  const headerProps = useMemo(
    () => ({ rows, count, rawData: paginatedList, hasMore }),
    [rows, count, hasMore, paginatedList],
  );
  //
  const getNextPage = useCallback(() => {
    if (hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
    }
  }, [page, setPage, hasMore, count, rows]);
  //
  useEffect(() => {
    if (page <= 1 && !queryParams.page) {
      return;
    }
    if (useURLNavigation && !compareIds(queryParams.page, page)) {
      // !ToDo: use navigate instead of next/router logic
      // navigate({ pathname, query: { ...queryParams, page } }, { pathname, query: { ...queryParams, page } });
    }
  }, [useURLNavigation, queryParams, page, pathname]);
  //
  useEffect(() => {
    if (!page) {
      return;
    }
    const v = {
      pagination: {
        offset: page <= 1 ? 0 : (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      },
    };
    logger('ScrollPagination.fetchMore', v);
    fetchMore({
      variables: v,
    }).catch(logger);
  }, [page, rowsPerPage, fetchMore]);
  //
  return (
    <>
      {typeof renderHeader === 'function' && renderHeader(headerProps)}
      <InfiniteScroll
        className={`${dataType.toLowerCase()}-list`}
        dataLength={rows.length}
        next={getNextPage}
        hasMore={hasMore}
        scrollThreshold={scrollThreshold}
        loader={<StyledSpinner />}
        endMessage={noMoreDataComponent}>
        {rows.map((row: unknown) => renderRow(row))}
      </InfiniteScroll>
    </>
  );
};

export default memo(ScrollPagination);
