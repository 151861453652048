import React, { memo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { Select, SelectOption } from 'src/components/ui-kit/select';

const roles = new Map([
  ['USER', 'Пользователь'],
  // ["OWNER", 'Владелец'],
  ['ADMIN', 'Администратор'],
  // ["MODERATOR", 'Модератор'],
]);

const RoleSelector = memo(props => {
  const { t } = useTranslation();
  const { value, style, className, disabled } = props;
  const [selectedRole, setRole] = useState(capitalize(value));

  useEffect(() => {
    if (value !== selectedRole) {
      setRole(value);
    }
  }, [value]);

  const onChange = useCallback(
    v => {
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setRole(v);
      }
    },
    [selectedRole],
  );

  return (
    <Select
      disabled={disabled}
      style={style}
      className={className}
      value={selectedRole}
      onChange={onChange}
      placeholder={t('Select a role')}>
      {[...roles.entries()].map(([role, label]) => {
        return (
          <SelectOption value={role} key={role}>
            {label}
          </SelectOption>
        );
      })}
    </Select>
  );
});
RoleSelector.propTypes = {
  value: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
RoleSelector.defaultProps = {
  value: null,
  style: {},
  className: '',
  disabled: false,
};
RoleSelector.displayName = 'RoleSelector';

export default RoleSelector;
