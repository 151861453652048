/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { useNavigate } from '@fjedi/react-router-helpers';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import getNodeOrganizationsQuery from 'src/graphql/queries/get-node-organizations.graphql';
//
import { SearchOutlined } from '@ant-design/icons';
import Table from 'src/components/ui-kit/table';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';
import { NodeOrganization } from 'src/graphql/generated';
import { numberSorter } from './drawer-helpers';
import OrganizationDrawer from './organization-drawer';

const OrganizationsTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRow = useCallback(
    ({ id }: NodeOrganization) => ({
      onClick: () => navigate(id),
    }),
    [navigate],
  );

  const filterIcon = useMemo(
    () => (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    [],
  );

  const filterDropdown = useMemo(() => (filterProps: any) => <FilterDropdown {...filterProps} />, []);

  const columns = useMemo(
    () => [
      {
        width: 180,
        title: t('Org.'),
        dataIndex: 'orgNo',
        sorterKey: 'orgNo',
        columnKey: 'orgNo',
        key: 'orgNo',
        sorter: numberSorter,
        filterDropdown,
        filterIcon,
      },
      {
        title: t('Name'),
        dataIndex: 'description',
        sorterKey: 'description',
        columnKey: 'description',
        key: 'description',
        sorter: numberSorter,
        filterDropdown,
        filterIcon,
      },
      {
        title: t('Home'),
        dataIndex: 'userNoMin',
      },
      {
        title: t('Ass'),
        dataIndex: 'userNoMax',
      },
    ],
    [filterDropdown, filterIcon, t],
  );

  return (
    <>
      <OrganizationDrawer />
      <Table
        rowKey="id"
        dataType="NodeOrganization"
        pageSize={10}
        onRow={handleRow}
        query={getNodeOrganizationsQuery}
        columns={columns}
        pagination
      />
    </>
  );
};

export default OrganizationsTab;
