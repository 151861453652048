// React
import React from 'react';
import PropTypes from 'prop-types';
// <Helmet> component for setting the page title/meta tags
import styled from 'styled-components';

import { statusColors } from 'src/helpers/tetra';

const Dot = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${({ color }) => `${color || 'green'}`};
`;

const OnlineStatusDot = props => {
  //
  const { state } = props;
  const color = statusColors[state];
  //
  return <Dot color={color} />;
};
OnlineStatusDot.propTypes = {
  state: PropTypes.number.isRequired,
};

export default React.memo(OnlineStatusDot);
