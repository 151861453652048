import React, { FC } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import time from 'src/helpers/time';
import { Form as AntForm, FormInstance } from 'antd';
import HelpIndicator from 'src/components/ui-kit/form/help-indicator';

export type FormSubmitResult = { [k: string]: unknown };

export const parseFormData = (form: FormInstance, formData: FormSubmitResult, onlyChanged = false) => {
  const input: FormSubmitResult = {};
  //
  Object.keys(formData).forEach(field => {
    if (onlyChanged && !form.isFieldTouched(field)) {
      return;
    }
    // eslint-disable-next-line security/detect-object-injection
    const value = formData[field];
    if (field === 'location') {
      const { latitude, longitude } = get(value, 'value', {});
      input.coordinates = [latitude, longitude];
    } else if (time.isDayjs(value)) {
      input.startTime = value.toISOString();
    } else {
      // eslint-disable-next-line security/detect-object-injection
      input[field] = value;
    }
  });

  return input;
};

export const Form = styled(AntForm)`
  &.ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;

    > label {
      width: 100%;
      margin-bottom: 0.25rem;
    }
  }
`;

export const FormItem = styled(AntForm.Item)`
  &.ant-form-item {
    margin-bottom: 0.5rem;

    // &:first-of-type {
    //   margin-top: 1rem;
    // }

    // &:last-of-type {
    //   margin-top: 1.5rem;
    // }

    .ant-form-item-label {
      line-height: 1.5rem;
    }
  }
`;

export const FormLabelContainer = styled.div`
  display: flex;
  width: 100%;

  > div:first-child {
    flex-grow: 1;
  }
`;

export type FormLabelProps = { text: string; help?: string };

export const FormLabel: FC<FormLabelProps> = ({ text, help }) => {
  return (
    <FormLabelContainer>
      <div>{text}</div>
      {help && <HelpIndicator text={help} />}
    </FormLabelContainer>
  );
};
FormLabel.defaultProps = { help: '' };
FormLabel.displayName = 'FormLabel';
