import PropTypes from 'prop-types';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
// CSS styles merging
import { Link, RestrictedArea, useLocation, ViewerRoleContext } from '@fjedi/react-router-helpers';
import { Layout as AntLayout, Menu as AntMenu } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Helmet from 'src/components/ui-kit/helmet-head';
import { AntennaIcon, DashboardIcon, SubscriberIcon, UsersIcon, DGNAIcon } from 'src/components/ui-kit/icons';
import Header from './header';

const { Sider: AntSider, Content: AntContent } = AntLayout;

const Layout = styled(AntLayout)`
  &.ant-layout {
    //max-height: 92vh;
    //overflow-y: auto;
  }
`;

const SideMenu = styled.div`
  .ant-menu {
    &.ant-menu-dark {
      background: none;
    }

    > li {
      margin-top: 0;
    }
  }
`;

const Sider = styled(AntSider)`
  &.ant-layout-sider {
    // background: ${props => props.theme.token.colorPrimary};
    background-color: #fff;

    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    z-index: 10;

    &.ant-layout-sider-collapsed {
      width: 4rem !important;
      min-width: 4rem !important;
      max-width: 4rem !important;
      flex-basis: 4rem !important;

      .ant-menu-inline-collapsed {
        width: 3.8rem !important;
      }
    }
  }
`;

const LogoContainer = styled.div`
  > img {
    object-fit: contain;
    display: block;
    max-width: 80%;
    height: 130px;
  }
`;

const Content = styled(AntContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem;
  position: relative;
  background-color: #fafafa;

  > div {
    flex-grow: 1;
  }
`;

const StyledLayout = styled(Layout)`
  height: 100%;

  &.ant-layout {
    // min-height: 100vh;
  }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .sider {
    min-width: 16rem;
  }

  .siderToggle {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .header {
    @include flex();
    height: 64px;
    padding: 0 12px 0 0;
    background: #fff;
    //box-shadow: 0 1px 4px rgba(0,21,41,.08);
    position: relative;
  }
`;

const Menu = styled(AntMenu)`
  &.ant-menu,
  &.ant-menu.ant-menu-dark {
    margin-top: 1rem;

    .ant-menu-item {
      font-size: 0.9rem;
      //font-weight: 600;
      span,
      a {
        display: inline-block;
      }

      &,
      > span,
      > a {
        color: #7788be;

        vertical-align: middle;
      }

      .icon {
        font-size: 1.1rem;
        margin-right: 1rem;
        //color: #dbdfee;
      }

      &:hover,
      &.ant-menu-item-selected {
        background-color: inherit;

        &,
        .icon,
        > span,
        > a {
          color: #1d3893;
        }
      }
    }
  }
`;
const MenuItem = styled(AntMenu.Item)``;

const menuItems = [
  {
    id: 'main',
    icon: DashboardIcon,
    name: 'Dashboard',
    route: '/',
    allowedRoles: ['USER', 'ADMIN'],
  },
  {
    id: 'bsc-list',
    icon: AntennaIcon,
    name: 'BSC List',
    route: '/bsc-list',
    allowedRoles: ['USER', 'ADMIN'],
  },
  {
    id: 'login',
    icon: 'user',
    name: 'Login',
    route: '/login',
    allowedRoles: ['ANONYMOUS'],
  },
  {
    id: 'subscribers',
    icon: SubscriberIcon,
    name: 'Subscribers',
    route: '/subscribers',
    allowedRoles: ['ADMIN', 'USER'],
  },
  {
    id: 'dgna',
    icon: DGNAIcon,
    name: 'DGNA',
    route: '/dgna',
    allowedRoles: ['ADMIN', 'USER'],
  },
  {
    id: 'admin',
    icon: UsersIcon,
    name: 'Users',
    route: '/admin',
    allowedRoles: ['ADMIN'],
  },
];

const MainContainer = props => {
  const { children, style, className, setCaption, logo } = props;
  // const viewer = useContext(ViewerContext);
  const role = useContext(ViewerRoleContext);
  const { t } = useTranslation();
  const location = useLocation();
  // Определяем выбранный пункт меню на основе свойств Router'а
  const rootPath = `/${location.pathname.split('/')[1] ? location.pathname.split('/')[1] : ''}`;
  const activeMenuIndex = menuItems.findIndex(i => i.route === rootPath);

  const [siderCollapsed, setSiderStatus] = useState(true);
  useEffect(() => {
    setSiderStatus(!!parseInt(sessionStorage.getItem('sideMenuIsCollapsed'), 10));
  }, []);
  useEffect(() => {
    sessionStorage.setItem('sideMenuIsCollapsed', `${siderCollapsed ? 1 : 0}`);
  }, [siderCollapsed]);
  const toggleSider = useCallback(() => {
    setSiderStatus(!siderCollapsed);
  }, [siderCollapsed, setSiderStatus]);

  return (
    <>
      <Helmet />
      <Header toggleSider={toggleSider} siderCollapsed={siderCollapsed} />
      <StyledLayout hasSider style={style} className={classNames('main-container', className)}>
        <Sider trigger={null} collapsible collapsed={siderCollapsed}>
          <SideMenu id="side-menu">
            {!!logo && <LogoContainer>{logo}</LogoContainer>}

            <Menu
              theme="dark"
              mode="inline"
              onClick={setCaption}
              defaultSelectedKeys={[menuItems[0].id]}
              selectedKeys={[menuItems[activeMenuIndex === -1 ? 0 : activeMenuIndex].id]}>
              {menuItems.map(item => (
                <RestrictedArea
                  areaType="block"
                  key={item.id}
                  currentRole={role}
                  allowedRoles={item.allowedRoles}
                  restrictedRoles={item.restrictedRoles}>
                  <MenuItem title={t(item.name)}>
                    <item.icon />
                    <span>{t(item.name)}</span>
                    <Link to={item.route} />
                  </MenuItem>
                </RestrictedArea>
              ))}
            </Menu>
          </SideMenu>
        </Sider>
        <Layout>
          <Content id="layout-content">{children}</Content>
        </Layout>
      </StyledLayout>
    </>
  );
};

MainContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  setCaption: PropTypes.func,
  logo: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
};

MainContainer.defaultProps = {
  children: undefined,
  className: 'layout main-container',
  setCaption: undefined,
  logo: null,
};

export default memo(MainContainer);
