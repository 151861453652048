/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import getNodeSubscribersQuery from 'src/graphql/queries/get-node-subscribers.graphql';
//
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from '@fjedi/react-router-helpers';
import type { ColumnsType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import Table from 'src/components/ui-kit/table';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';
import { NodeSubscriber } from 'src/graphql/generated';
import { getTsiFromMobile, numberSorter, ssiKindOptions, stringSorter } from './drawer-helpers';
import SubscriberDrawer from './subscriber-drawer';

const SubscribersTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRow = useCallback(
    ({ id }: NodeSubscriber) => ({
      onClick: () => navigate(id),
    }),
    [navigate],
  );

  const filterIcon = useMemo(
    () => (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    [],
  );

  const filterDropdown = useMemo(() => (filterProps: FilterDropdownProps) => <FilterDropdown {...filterProps} />, []);

  const columns = useMemo(
    () =>
      [
        {
          title: t('SSI'),
          key: 'ssi',
          dataIndex: 'ssi',
          sorterKey: 'ssi',
          columnKey: 'ssi',
          sorter: numberSorter,
          filterDropdown,
          filterIcon,
        },
        {
          title: t('SSI Type'),
          key: 'ssiKind',
          dataIndex: 'ssiKind',
          sorterKey: 'ssiKind',
          columnKey: 'ssiKind',
          filters: ssiKindOptions,
          filterMultiple: false,
          sorter: numberSorter,
          render: (ssiType: NodeSubscriber['ssiKind']) =>
            ssiKindOptions.find(({ value }) => value === ssiType)?.text ?? ssiType,
        },
        {
          title: t('Description'),
          dataIndex: 'description',
          sorterKey: 'description',
          columnKey: 'description',
          key: 'description',
          sorter: stringSorter,
          filterDropdown,
          filterIcon,
          onFilter: (filter: string, { description }: NodeSubscriber) =>
            description?.toLowerCase().includes(filter.toString()) ?? false,
        },
        {
          title: t('Org.'),
          dataIndex: 'orgNo',
          sorterKey: 'orgNo',
          columnKey: 'orgNo',
          key: 'orgNo',
          sorter: numberSorter,
          filterDropdown,
          filterIcon,
        },
        {
          title: t('Profile'),
          dataIndex: 'profileNo',
          sorterKey: 'profileNo',
          columnKey: 'profileNo',
          key: 'profileNo',
          sorter: numberSorter,
          filterDropdown,
          filterIcon,
        },
        {
          title: t('User №'),
          dataIndex: 'userNo',
          sorterKey: 'userNo',
          key: 'userNo',
          columnKey: 'userNo',
          sorter: numberSorter,
          filterDropdown,
          filterIcon,
        },
        {
          title: t('IP Address'),
          dataIndex: 'ipAddress',
        },
        {
          title: t('TSI'),
          dataIndex: 'mobile',
          render: getTsiFromMobile,
        },
        {
          title: t('Blocked'),
          dataIndex: 'trustKind',
          render: (trustKind: NodeSubscriber['trustKind']) => (trustKind === 1 ? 'YES' : 'NO'),
        },
      ] as ColumnsType<NodeSubscriber>,
    [filterDropdown, filterIcon, t],
  );

  return (
    <>
      <SubscriberDrawer />
      <Table
        rowKey="id"
        dataType="NodeSubscriber"
        pageSize={10}
        onRow={handleRow}
        query={getNodeSubscribersQuery}
        columns={columns}
        pagination
      />
    </>
  );
};

export default SubscribersTab;
