// React
import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@fjedi/react-router-helpers';
import { logger, useApolloError } from '@fjedi/graphql-react-components';
// GraphQL Queries
import getNodeStatusListQuery from 'src/graphql/queries/get-nodes-statuses.graphql';
import nodeStatusCreatedSubscription from 'src/graphql/subscriptions/node-status-created.graphql';
import nodeStatusChangedSubscription from 'src/graphql/subscriptions/node-status-changed.graphql';

import { Button as AntButton } from 'antd';
import Button from 'src/components/ui-kit/buttons';
import { CloseCircleOutlined } from 'src/components/ui-kit/icons';
import { Title } from 'src/components/ui-kit/typography';
import Table from 'src/components/ui-kit/table';
import GlassButton from 'src/components/ui-kit/buttons/glass';

import { renderConfigured, styleConfigured, statusObjs } from 'src/helpers/tetra';
import { useUpdateAccidentMutation, Accident, NodeStatus } from 'src/graphql/generated';

const NodeCell = styled.span<{ status: { color: string; background: string } }>`
  color: ${props => `${props.status.color}`};
  background-color: ${props => `${props.status.background}`};
`;

const RemoveAccidentConfirmation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 124px;
  flex-wrap: wrap;
  margin: auto;

  > span {
    vertical-align: middle;
    font-size: 0.85rem;
    line-height: 1;
  }
  > .ant-btn.ant-btn-circle {
    border: 0;
    box-shadow: none;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
`;

export type NodeListProps = {};

const NodeList: FC<NodeListProps> = props => {
  //
  const onError = useApolloError();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onView = useCallback(
    (nodeRecord: NodeStatus) => () => {
      navigate(`/dashboard/${nodeRecord.number}`);
    },
    [navigate],
  );
  const [updateAccident] = useUpdateAccidentMutation({ onError });
  const toggleAccident = useCallback(
    (accident: Accident) => () => {
      logger('toggleAccident', { accident });
      const confirmed = !accident?.confirmed;
      updateAccident({ variables: { id: accident.id, input: { confirmed } } }).catch(logger);
    },
    [updateAccident],
  );
  //
  return (
    <>
      <Title level={4}>{t('Node list')}</Title>
      <Table
        pagination
        pageSize={40}
        dataType="NodeStatus"
        query={getNodeStatusListQuery}
        variables={{}}
        sorter={{ columnKey: 'NodeNo', order: 'ascend' }}
        subscriptionQueries={[nodeStatusCreatedSubscription, nodeStatusChangedSubscription]}
        rowKey="id"
        columns={[
          {
            title: t('Node'),
            dataIndex: 'number',
            width: 180,
            render(_: unknown, { number, bsc }: NodeStatus) {
              return `${String(number).padStart(3, '0')} [BSC${bsc! + 1}]`;
            },
          },
          {
            title: t('Status'),
            dataIndex: 'alarmState',
            width: 100,
            onCell: styleConfigured(({ alarmState: state }: NodeStatus) => {
              // @ts-ignore
              const { background } = statusObjs[state];
              return { background };
            }),
            // eslint-disable-next-line react/no-unstable-nested-components
            render: renderConfigured((_: unknown, { alarmState: state }: NodeStatus) => {
              // @ts-ignore
              const status = statusObjs[state];
              return <NodeCell status={status}>{status.label}</NodeCell>;
            }),
          },
          {
            title: t('Description'),
            dataIndex: 'description',
            width: 240,
            render(description: string) {
              return description || 'Не указано';
            },
          },
          {
            title: t('Details'),
            dataIndex: 'number',
            width: 100,
            style: {
              textAlign: 'center',
            },
            render(_: unknown, record: NodeStatus) {
              return <GlassButton onClick={onView(record)} />;
            },
          },
          {
            title: t('Accident confirmation'),
            dataIndex: 'alarmConfirmed',
            width: 180,
            style: {
              textAlign: 'center',
            },
            render(_: unknown, record: NodeStatus) {
              const { accident } = record;
              if (!accident) {
                return '';
              }
              if (accident.confirmed) {
                return (
                  <RemoveAccidentConfirmation>
                    <span>Подтверждено</span>
                    <AntButton
                      type="primary"
                      ghost
                      shape="circle"
                      icon={<CloseCircleOutlined />}
                      onClick={toggleAccident(accident)}
                    />
                  </RemoveAccidentConfirmation>
                );
              }
              return (
                <Button onClick={toggleAccident(accident)} size="small">
                  Подтвердить
                </Button>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default memo(NodeList);
