export const gray = '#cccccc';
export const red = 'red';

// 0=OK, 1=Warning, 2=Alarm, 3=Blocked, 255=Not configured
export const statusColors = {
  0: '#009900',
  1: '#fde910',
  2: 'red',
  3: 'red',
  255: gray,
  257: gray,
};

type StatusObjData = {
  dotColor: string;
  label: 'OK' | 'Alarm' | 'Blocked' | 'Warning' | 'Down';
  background: string;
  color: string;
  boxBackground: string;
  boxColor: string;
  boxLabel?: string;
};

export const statusObjs: Record<number, StatusObjData> = {
  0: {
    dotColor: '#009900',
    label: 'OK',
    background: 'white',
    color: 'black',
    boxBackground: 'white',
    boxColor: 'black',
  },
  1: {
    dotColor: '#fde910',
    label: 'Warning',
    background: '#fde910',
    color: 'black',
    boxBackground: '#fde910',
    boxColor: 'black',
  },
  2: { dotColor: red, label: 'Alarm', background: red, color: 'white', boxBackground: red, boxColor: 'white' },
  3: { dotColor: red, label: 'Blocked', background: red, color: 'black', boxBackground: red, boxColor: 'black' },
  255: {
    dotColor: gray,
    label: 'Down',
    boxLabel: '',
    background: red,
    color: 'black',
    boxBackground: gray,
    boxColor: 'black',
  },
  257: {
    dotColor: gray,
    label: 'Down',
    boxLabel: '',
    background: red,
    color: 'black',
    boxBackground: gray,
    boxColor: 'black',
  },
};

export const stateLabels = {
  alarmState: 'Node',
  commonAlarmState: 'Common',
  subRegAlarmState: 'Subscr.',
  radioAlarmState: 'Radio',
  voiceGwAlarmState: 'Voice GW',
  pdGwAlarmState: 'PD GW',
  apiGwAlarmState: 'App. GW',
  termGwAlarmState: 'Term. GW',
};

export const subRegModes = {
  0: '',
  1: 'Master',
  2: 'Slave',
};

export const softwareVersions = {
  859322144: '7.83',
  959919904: '7.79',
  842544928: '7.82',
  825767712: '7.81',
  808990496: '7.80',
};

export const styleConfigured =
  (render, field = 'alarmState') =>
  record => {
    return render
      ? {
          style: render(record),
        }
      : {};
  };

export const renderConfigured = (render, field = 'alarmState') =>
  function renderValue(value, record) {
    return render ? render(value, record) : value;
  };
