import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Route, Navigate, useLocation, Routes } from '@fjedi/react-router-helpers';
import { setHTMLTitle } from 'src/components/service-routes';
import { ContentCard } from 'src/components/ui-kit/card/main-content-card';
//
import AlarmsTab from './tabs/alarms';
import StatusTab from './tabs/status';
// import ConfigTab from './tabs/config';
import SubscriberTab from './tabs/subscriber';
import RadioStatusTab from './tabs/radio-status';
import VoiceGWTab from './tabs/voice-gw';
import PdGWTab from './tabs/pd-gw';
import AppGWTab from './tabs/app-gw';
import TerminalGWTab from './tabs/terminal-gw';

const Container = styled(ContentCard)`
  //padding: 1rem;
`;

const BSCPage = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const tabList = useMemo(
    () => [
      {
        key: 'alarms',
        tab: t('Alarms'),
      },
      {
        key: 'status',
        tab: t('Status'),
      },
      // {
      //   key: 'config',
      //   tab: t('Config'),
      // },
      {
        key: 'subscriber',
        tab: t('Subscriber'),
      },
      {
        key: 'radio-status',
        tab: t('Radio Status'),
      },
      {
        key: 'voice-gw',
        tab: t('Voice GW'),
      },
      {
        key: 'pd-gw',
        tab: t('PD GW'),
      },
      {
        key: 'app-gw',
        tab: t('App. GW'),
      },
      {
        key: 'terminal-gw',
        tab: t('Terminal GW'),
      },
    ],
    [t],
  );

  return (
    <Container tabList={tabList} tabURLNavigation>
      {setHTMLTitle(t('BSC List'))}
      <Routes key={location.key}>
        <Route path="alarms" element={<AlarmsTab />} />
        <Route path="status" element={<StatusTab />} />
        {/* <Route path={`${path}/config`} element={ConfigTab} /> */}
        <Route path="subscriber" element={<SubscriberTab />} />
        <Route path="radio-status" element={<RadioStatusTab />} />
        <Route path="voice-gw" element={<VoiceGWTab />} />
        <Route path="pd-gw" element={<PdGWTab />} />
        <Route path="app-gw" element={<AppGWTab />} />
        <Route path="terminal-gw" element={<TerminalGWTab />} />
        <Route path="*" element={<Navigate replace to="alarms" />} />
      </Routes>
    </Container>
  );
});

BSCPage.propTypes = {};
BSCPage.defaultProps = {};

export default BSCPage;
