import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Routes, Route } from '@fjedi/react-router-helpers';
//
import Card from 'src/components/ui-kit/card';
import { Row, Col } from 'src/components/ui-kit/grid';
//
import LogServers from './log-servers';
import NodeList from './node-list';
import NodeStatus from './node-status';

const Container = styled(Card)``;

const DashboardPage = () => {
  return (
    <Container>
      <Row gutter={16}>
        <Col md={{ span: 12 }}>
          <LogServers />
          <NodeList />
        </Col>
        <Col md={{ span: 2 }} />
        <Col md={{ span: 10 }}>
          <Routes>
            <Route path=":nodeNumber/*" element={<NodeStatus />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(DashboardPage);
