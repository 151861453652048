import drawer from 'src/components/ui-kit/drawer';
import { colorTheme, colorGrays } from 'src/components/ui-kit/theme';
import styled from 'styled-components';

export const Container = styled(drawer)`
  max-width: 100%;
  margin: auto;

  .ant-drawer-content-wrapper {
    width: 800px !important;
    max-width: 80%;
  }

  .ant-drawer-body {
    padding: 0.5rem 0 0 0;
    width: 100%;
  }

  > div > form > div {
    &:first-child {
      flex-grow: 1;
      margin-right: 1rem;
    }
  }

  > div > form > section {
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }

  .drawer-content > div {
    height: 100%;
  }

  form.ant-form {
    padding: 0 1.5rem;

    & > .ant-row {
      & > .ant-col {
        padding: 0.3725rem;

        & > .ant-form-item .ant-form-item-label {
          text-transform: uppercase;
        }

        & > .ant-form-item label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
          &::after,
          & > .ant-checkbox,
          .ant-checkbox-inner,
          .ant-checkbox-checked::after {
            border-radius: 0.5rem;
            top: 0;
            margin-top: 0;
          }
        }

        .ant-input-number {
          width: 100%;
          height: 32px;
          min-height: 32px;
          line-height: 1;
          padding: 0.375rem 0 0 0.375rem;
        }

        .ant-input,
        .ant-select-selector {
          height: 32px;
          min-height: 32px !important;
          line-height: 1;
          padding: 0 0.75rem;
        }

        .ant-checkbox.ant-checkbox-disabled + span {
          color: ${colorTheme.secondary}75;
        }

        .ant-input.ant-input-disabled,
        .ant-input-number-disabled,
        .ant-select.ant-select-disabled .ant-select-selector,
        .ant-checkbox.ant-checkbox-disabled .ant-checkbox-inner {
          background: ${colorGrays.gray400};
          color: ${colorGrays.gray900};
        }

        .ant-form-item-required::before {
          display: none;
        }

        .ant-input[aria-required='true'] {
          background: ${colorTheme.warning};
        }

        .ant-select {
          width: 100%;
        }
      }

      & + .ant-divider {
        max-width: calc(100% - 1rem);
      }
    }

    &.readonly {
      .ant-input,
      .ant-select-selector,
      .ant-input-number {
        background: ${colorGrays.gray400};
        color: ${colorGrays.gray900};
        border: 1px solid #d9d9d9 !important;
        outline: none !important;
        box-shadow: unset !important;
        cursor: default;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid #d9d9d9 !important;
          outline: none !important;
          box-shadow: unset !important;
          cursor: default;
        }

        .ant-input-number-input {
          cursor: default;
        }
      }
    }
  }

  .ant-divider {
    min-width: auto;
    margin: 0.25rem;
  }

  .ant-spin-nested-loading {
    height: calc(100% - 5rem);

    & > div {
      height: 100%;

      & > .ant-spin.ant-spin-spinning {
        height: 100%;
        max-height: 100%;
      }
    }
  }
`;

export const Title = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 0.25rem;

  & > span:first-child {
    display: inline-block;
    flex-grow: 1;
    flex-basis: 100%;
    margin-bottom: 0.25rem;
  }

  .ant-btn {
    border-radius: 0.5rem;
    font-weight: bold;

    &.ant-btn-primary {
      flex-shrink: 1;

      &:not(:first-of-type) {
        margin-left: 0.5rem;
      }
    }

    &.ant-btn-dangerous {
      color: #fff !important;
      background-color: ${colorTheme.danger};
      border-color: ${colorTheme.danger};
      box-shadow: unset;
      opacity: 0.9;
      transition: all 0.2s linear;

      &:active,
      &:hover,
      &:focus {
        background-color: ${colorTheme.danger};
        border-color: ${colorTheme.danger};
        opacity: 1;
      }

      &:not(:only-of-type) {
        margin-left: auto;
      }
    }
  }
`;

export const Actions = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-top: 1rem;

  :first-child {
    margin-left: auto;
  }

  .ant-btn {
    color: #fff;
    flex: 0 0 100px;
    margin: 0;
    box-shadow: unset;
    transition: all 0.2s linear;

    &[type='submit'] {
      font-weight: bold;
      margin: 0 0 0 1rem;
    }

    &[type='reset'] {
      background-color: #0000 !important;
      color: ${colorTheme.primary} !important;
      opacity: 0.8;

      &:active,
      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &[type='button'] {
      margin: 0 0.5rem 0 0;

      &:first-of-type {
        margin-left: auto;
      }
    }
  }
`;

export const RSSIContainer = styled.div`
  width: calc(100% + 2rem);
  background-color: #6c757d25;
  border-radius: 0.5rem;
  margin: 1rem -1rem;
  padding: 0.5rem 0.75rem;

  > form.ant-form {
    padding: 0;
  }
`;
