import React, { FC, memo, createContext, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation, Link } from '@fjedi/react-router-helpers';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { RiArrowLeftSLine } from 'react-icons/ri';

import Button from 'src/components/ui-kit/buttons';
import { Text } from 'src/components/ui-kit/typography';
import { colorTheme } from 'src/components/ui-kit/theme';

export const BreadcrumbContext = createContext<{ [k: string]: string }>({});

export const Breadcrumb = styled(AntBreadcrumb)`
  background-color: transparent;

  span:last-child {
    font-weight: 600;
    font-size: 1rem;
  }

  .ant-breadcrumb-separator {
    margin: 0 0.25rem;
  }
`;

export const BreadcrumbItem = styled(AntBreadcrumb.Item)``;

const DisplayFlex = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const HeaderBack = styled(Button)`
  &.ant-btn-icon-only {
    margin-left: -0.875rem;
    margin-top: -0.125rem;
    color: ${colorTheme.dark};
  }
`;

export type HeaderBreadcrumbProps = { title?: string; showBackButton?: boolean };

export const HeaderBreadcrumb: FC<HeaderBreadcrumbProps> = ({ title, showBackButton }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbNameMap = useContext(BreadcrumbContext);
  //
  const pathSnippets = location.pathname.split('/').filter((i: string) => i.trim());
  const breadcrumbItems = pathSnippets.map((_: string, index: number) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    // eslint-disable-next-line security/detect-object-injection
    const text: string = breadcrumbNameMap[url] || title || '';

    return !text ? null : (
      <BreadcrumbItem key={url}>{index > 0 ? <Link to={url}>{text}</Link> : <Text>{text}</Text>}</BreadcrumbItem>
    );
  });
  // const breadcrumbItems = [
  //   <BreadcrumbItem key="home">
  //     <Link to="/">Home</Link>
  //   </BreadcrumbItem>,
  // ].concat(extraBreadcrumbItems);
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate]);

  return (
    <DisplayFlex>
      {showBackButton && <HeaderBack type="link" icon={<RiArrowLeftSLine />} onClick={handleBackButtonClick} />}
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </DisplayFlex>
  );
};

HeaderBreadcrumb.defaultProps = { title: undefined, showBackButton: false };

export default memo(HeaderBreadcrumb);
