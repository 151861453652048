/* eslint-disable max-len */
import React, { FC, memo } from 'react';

const BSPage: FC = () => (
  <>
    <h1>BS Base Station ver. 7.82</h1>
    <h2>Functional Description</h2>
    <div>FUNCTIONAL&nbsp;DESCRIPTION</div>
    <div>
      All&nbsp;Commands&nbsp;in&nbsp;this&nbsp;Help&nbsp;File&nbsp;are&nbsp;only&nbsp;available&nbsp;when&nbsp;the&nbsp;BSC.exe&nbsp;is
    </div>
    <div>running&nbsp;on&nbsp;a&nbsp;BSC412&nbsp;Hardware&nbsp;Platform.&nbsp;</div>
    <h2>BS Address selection</h2>
    <h2>BSC41x UART Address selection</h2>
    <div>AU&nbsp;&nbsp;BSC41x&nbsp;UART&nbsp;ADDRESS&nbsp;SELECTION</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>AU&nbsp;&nbsp;Display&nbsp;BSC41x&nbsp;UART&nbsp;selection</strong>
      </div>
      <div>AU</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>AU+&nbsp;&nbsp;Select&nbsp;BSC41x&nbsp;UART&nbsp;Address</strong>
      </div>
      <div>AU+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>AU-&nbsp;&nbsp;Deselect&nbsp;BSC41x&nbsp;UART&nbsp;Address</strong>
      </div>
      <div>AU-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>AU/FORCED&nbsp;&nbsp;Forced&nbsp;BSC41x&nbsp;UART&nbsp;Address&nbsp;select</strong>
      </div>
      <div>Warning:&nbsp;This&nbsp;will&nbsp;disconnect&nbsp;existing&nbsp;user!!!</div>
      <div>AU/FORCED</div>
      <div />
      <div />
    </div>
    <h2>Device Address selection</h2> <div>A&nbsp;&nbsp;DEVICE&nbsp;ADDRESS&nbsp;COMMAND</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A&nbsp;&nbsp;Display&nbsp;Address</strong>
      </div>
      <div>
        NB:&nbsp;If&nbsp;BSC41x&nbsp;UART&nbsp;is&nbsp;selected,&nbsp;command&nbsp;is&nbsp;send&nbsp;on&nbsp;UART
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;response&nbsp;is&nbsp;coming&nbsp;from&nbsp;selected&nbsp;Device</div>
      <div>A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>Ann&nbsp;&nbsp;Select&nbsp;BS41x&nbsp;TR&nbsp;Address</strong>
      </div>
      <div>&nbsp;┌&nbsp;TR&nbsp;Address</div>
      <div>&nbsp;│&nbsp;&nbsp;11..48:&nbsp;TR11..TR48&nbsp;Transceiver</div>
      <div>&nbsp;┴─</div>
      <div>A__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>APnn&nbsp;&nbsp;Select&nbsp;BS41x&nbsp;PS&nbsp;Address</strong>
      </div>
      <div>&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address</div>
      <div>&nbsp;&nbsp;│&nbsp;&nbsp;11..47:&nbsp;PS11..PS47&nbsp;Power&nbsp;Supply</div>
      <div>&nbsp;&nbsp;┴─</div>
      <div>AP__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>ATnn&nbsp;&nbsp;Select&nbsp;BS41x&nbsp;TC&nbsp;Address</strong>
      </div>
      <div>&nbsp;&nbsp;┌&nbsp;TC&nbsp;Address</div>
      <div>&nbsp;&nbsp;│&nbsp;&nbsp;11..42:&nbsp;TC11..TC12&nbsp;TX&nbsp;Combiner</div>
      <div>&nbsp;&nbsp;┴─</div>
      <div>AT__</div>
      <div />
      <div />
    </div>
    <h2>Software Version:</h2> <div>H00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H00&nbsp;&nbsp;Display&nbsp;software&nbsp;version</strong>
      </div>
      <div>H00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H00/A&nbsp;&nbsp;Display&nbsp;all&nbsp;software&nbsp;version&nbsp;numbers</strong>
      </div>
      <div>H00/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H00/C&nbsp;&nbsp;Display&nbsp;compiler&nbsp;options</strong>
      </div>
      <div>H00/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H00/OM&nbsp;&nbsp;Display&nbsp;OM&nbsp;TCP&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>H00/OM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H00/BSC&nbsp;&nbsp;Display&nbsp;OM&nbsp;BSC&nbsp;Connection&nbsp;status</strong>
      </div>
      <div>H00/BSC</div>
      <div />
      <div />
    </div>
    <h2>BS Activation Control</h2> <div>H02&nbsp;&nbsp;BS&nbsp;ACTIVATION&nbsp;CONTROL</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H02&nbsp;&nbsp;Display&nbsp;BS&nbsp;Activation&nbsp;Status</strong>
      </div>
      <div>H02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H02/ACTIVATE&nbsp;&nbsp;BS&nbsp;Activation&nbsp;Request</strong>
      </div>
      <div>Sends&nbsp;Activation&nbsp;Request&nbsp;to&nbsp;other&nbsp;BS</div>
      <div>H02/ACTIVATE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H02/STANDBY/s&nbsp;&nbsp;Change&nbsp;Forced&nbsp;BSC&nbsp;Standby</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;&nbsp;+:&nbsp;Forced&nbsp;Standby
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Normal&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H02/STANDBY/_</div>
      <div />
      <div />
    </div>
    <h2>Alarms</h2> <div>H03&nbsp;&nbsp;ALARMS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H03&nbsp;&nbsp;Display&nbsp;BS&nbsp;Alarm&nbsp;state</strong>
      </div>
      <div>H03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H03/A&nbsp;&nbsp;Display&nbsp;BSC&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>H03/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H03/IN&nbsp;&nbsp;Display&nbsp;Alarm&nbsp;Inputs</strong>
      </div>
      <div>H03/IN</div>
      <div />
      <div />
    </div>
    <h2>Synchronization Status</h2> <div>H12&nbsp;SYNCHRONIZATION&nbsp;STATUS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H12&nbsp;&nbsp;Display&nbsp;Synchronization&nbsp;Status</strong>
      </div>
      <div>H12</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H12/IN&nbsp;&nbsp;Display&nbsp;Sync&nbsp;Inputs</strong>
      </div>
      <div>H12/IN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H12/MASTER&nbsp;&nbsp;Display&nbsp;Master&nbsp;Table</strong>
      </div>
      <div>H12/MASTER</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H12/PPS&nbsp;&nbsp;Display&nbsp;1PPS&nbsp;Inputs</strong>
      </div>
      <div>H12/PPS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H12/HIST&nbsp;&nbsp;Display&nbsp;OCXO&nbsp;Sync&nbsp;History</strong>
      </div>
      <div>H12/HIST</div>
      <div />
      <div />
    </div>
    <h2>BS Antenna Status</h2> <div>H13&nbsp;BS&nbsp;ANTENNA&nbsp;STATUS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H13&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;Antenna&nbsp;Status</strong>
      </div>
      <div>H13</div>
      <div />
      <div />
    </div>
    <h2>TR Status</h2> <div>H15&nbsp;TR&nbsp;STATUS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H15&nbsp;&nbsp;Display&nbsp;TR&nbsp;Status</strong>
      </div>
      <div>H15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H15/c&nbsp;&nbsp;Display&nbsp;TR&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;F:&nbsp;Frequencies/Power</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;P:&nbsp;GPS&nbsp;Positions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;V:&nbsp;Versions</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;L:&nbsp;Frequency&nbsp;and&nbsp;Power&nbsp;Limits</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;E:&nbsp;External&nbsp;Alarms</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H15/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H15/nn/A&nbsp;&nbsp;Display&nbsp;TR&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>H15/__/A</div>
      <div />
      <div />
    </div>
    <h2>PS Status</h2> <div>H16&nbsp;PS&nbsp;STATUS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H16&nbsp;&nbsp;Display&nbsp;PS&nbsp;Status</strong>
      </div>
      <div>H16</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H16/AL&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Alarm&nbsp;Flags</strong>
      </div>
      <div>H16/AL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H16/REC&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Rectifier&nbsp;status</strong>
      </div>
      <div>H16/REC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H16/OUT&nbsp;&nbsp;Display&nbsp;PS411&nbsp;+14V/+26V&nbsp;status</strong>
      </div>
      <div>H16/OUT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H16/LED&nbsp;&nbsp;Display&nbsp;PS411&nbsp;LED&nbsp;status</strong>
      </div>
      <div>H16/LED</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H16/nn&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..47)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>H16/__</div>
      <div />
      <div />
    </div>
    <h2>OCXO:</h2> <div>H31&nbsp;&nbsp;OCXO&nbsp;SYNC</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H31&nbsp;&nbsp;Display&nbsp;OCXO&nbsp;Sync</strong>
      </div>
      <div>H31</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H31/IN/c&nbsp;&nbsp;Change&nbsp;Sync&nbsp;Input&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Auto&nbsp;Select</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;F:&nbsp;Forced&nbsp;Free&nbsp;Running</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;G:&nbsp;Forced&nbsp;GPS&nbsp;RX&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;0:&nbsp;Forced&nbsp;External&nbsp;0&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;1:&nbsp;Forced&nbsp;External&nbsp;1&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Forced&nbsp;External&nbsp;2&nbsp;Lock</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H31/IN/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          H31/SET&nbsp;&nbsp;Set&nbsp;OCXO&nbsp;Free&nbsp;Run&nbsp;adjust&nbsp;value&nbsp;to&nbsp;current&nbsp;DAC&nbsp;value
        </strong>
      </div>
      <div>H31/SET</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H31/SET/nn.nnn&nbsp;&nbsp;Set&nbsp;OCXO&nbsp;Free&nbsp;Run&nbsp;value</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00.000&nbsp;to&nbsp;99.999&nbsp;[%]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>H31/SET/__.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H31/SAVE&nbsp;&nbsp;Save&nbsp;OCXO&nbsp;Free&nbsp;Run&nbsp;adjust&nbsp;value&nbsp;in&nbsp;Flash</strong>
      </div>
      <div>H31/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H31/STEP/nn.nnn&nbsp;&nbsp;Simulate&nbsp;a&nbsp;PLL&nbsp;Frequency&nbsp;Step</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;00.000&nbsp;to&nbsp;99.999&nbsp;[%]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>H31/STEP/__.___</div>
      <div />
      <div />
    </div>
    <h2>Internal GPS RX:</h2> <div>H32&nbsp;&nbsp;INTERNAL&nbsp;GPS</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H32&nbsp;&nbsp;Display&nbsp;Internal&nbsp;GPS&nbsp;RX&nbsp;Status</strong>
      </div>
      <div>H32</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H32/VER&nbsp;&nbsp;Display&nbsp;GPS&nbsp;Module&nbsp;Version</strong>
      </div>
      <div>H32/VER</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H32/N&nbsp;&nbsp;Display&nbsp;next&nbsp;Internal&nbsp;GPS&nbsp;message</strong>
      </div>
      <div>Hint:&nbsp;Use&nbsp;the&nbsp;C/H32/N&nbsp;command&nbsp;to&nbsp;display&nbsp;continuously</div>
      <div>H32/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H32/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;Internal&nbsp;GPS&nbsp;RX&nbsp;extended&nbsp;message</strong>
      </div>
      <div>Hint:&nbsp;Use&nbsp;the&nbsp;C/H32/N+&nbsp;command&nbsp;to&nbsp;display&nbsp;continuously</div>
      <div>H32/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H32/SETUP&nbsp;&nbsp;Setup&nbsp;GPS&nbsp;RX&nbsp;Module</strong>
      </div>
      <div>
        Setup&nbsp;the&nbsp;Internal&nbsp;GPS&nbsp;RX&nbsp;Module&nbsp;and&nbsp;saves&nbsp;the&nbsp;configuration&nbsp;in&nbsp;GPS&nbsp;RX&nbsp;flash
      </div>
      <div>
        This&nbsp;command&nbsp;shall&nbsp;be&nbsp;executed&nbsp;when&nbsp;a&nbsp;new&nbsp;GPS&nbsp;RX&nbsp;module&nbsp;is&nbsp;installed
      </div>
      <div>H32/SETUP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H32/SEND/cc..c&nbsp;&nbsp;Send&nbsp;Command&nbsp;to&nbsp;GPS&nbsp;RX&nbsp;Module</strong>
      </div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;testing&nbsp;only</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;NMEA&nbsp;command</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────────────────────────────────────────────────────────────────
      </div>
      <div>H32/SEND/____________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          H32/SEND/UBX/hh/hh/hh..hh&nbsp;&nbsp;Send&nbsp;UBX&nbsp;Message&nbsp;to&nbsp;GPS&nbsp;RX&nbsp;Module
        </strong>
      </div>
      <div>Command&nbsp;to&nbsp;manully&nbsp;send&nbsp;UBX&nbsp;Message&nbsp;to&nbsp;GPS&nbsp;RX&nbsp;&nbsp;</div>
      <div>Sync,&nbsp;Length&nbsp;and&nbsp;Checksum&nbsp;(6&nbsp;Bytes)&nbsp;are&nbsp;added&nbsp;automatically.</div>
      <div>This&nbsp;command&nbsp;is&nbsp;for&nbsp;testing&nbsp;only</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Class&nbsp;(00h..FFh)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;ID&nbsp;(00h..FFh)&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Optional&nbsp;Data&nbsp;(hex)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34&nbsp;&nbsp;36&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>H32/SEND/UBX/__/__/__________________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>CMoIP Switch</h2> <div>H34&nbsp;&nbsp;CMOIP&nbsp;SWITCH</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H34&nbsp;&nbsp;Display&nbsp;all&nbsp;CMoIP&nbsp;Connections</strong>
      </div>
      <div>H34</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H34/nn/n&nbsp;&nbsp;Display&nbsp;one&nbsp;CMoIP&nbsp;Connection</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>H34/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H34/nn/n/...&nbsp;&nbsp;CMoIP&nbsp;Multicast&nbsp;Connect</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Multicast&nbsp;IP&nbsp;Address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;┴──────────────</div>
      <div>H34/__/_/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H34/nn/n/REL&nbsp;&nbsp;CMoIP&nbsp;Multicast&nbsp;Release</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>H34/__/_/REL</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H34/C&nbsp;&nbsp;Clear&nbsp;Message&nbsp;Counters</strong>
      </div>
      <div>H34/C</div>
      <div />
      <div />
    </div>
    <h2>Message Trace:</h2> <div>H48&nbsp;MESSAGE&nbsp;TRACE</div>
    <div>The&nbsp;H48&nbsp;Commands&nbsp;control&nbsp;the&nbsp;TR&nbsp;Signalling&nbsp;Trace&nbsp;functionality.</div>
    <div>
      Up&nbsp;to&nbsp;4&nbsp;different&nbsp;Test&nbsp;Points&nbsp;can&nbsp;be&nbsp;activated&nbsp;on&nbsp;any&nbsp;TR&nbsp;and&nbsp;timeslot&nbsp;in&nbsp;total.
    </div>
    <div>
      Both&nbsp;Downlink&nbsp;and&nbsp;Uplink&nbsp;traffic&nbsp;is&nbsp;monitored&nbsp;on&nbsp;the&nbsp;TR's,&nbsp;and&nbsp;excludes&nbsp;or&nbsp;includes
    </div>
    <div>Air&nbsp;Interface&nbsp;Encryption.</div>
    <div>
      The&nbsp;output&nbsp;from&nbsp;the&nbsp;Test&nbsp;Points&nbsp;can&nbsp;be&nbsp;output&nbsp;realtime&nbsp;in&nbsp;UDP&nbsp;messages,&nbsp;and&nbsp;can
    </div>
    <div>
      also&nbsp;be&nbsp;written&nbsp;to&nbsp;a&nbsp;file,&nbsp;either&nbsp;located&nbsp;locally&nbsp;or&nbsp;on&nbsp;a&nbsp;remote&nbsp;Computer.
    </div>
    <div>
      The&nbsp;output&nbsp;can&nbsp;be&nbsp;analyzed&nbsp;with&nbsp;the&nbsp;Tetra&nbsp;Protocol&nbsp;Analyzer&nbsp;from&nbsp;fjord-e-design.
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48&nbsp;&nbsp;Display&nbsp;Message&nbsp;Trace</strong>
      </div>
      <div>H48</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48-&nbsp;&nbsp;Stop&nbsp;all&nbsp;Message&nbsp;Traces</strong>
      </div>
      <div>H48-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/C&nbsp;&nbsp;Clear&nbsp;Trace&nbsp;Counters</strong>
      </div>
      <div>H48/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/UDP/+&nbsp;&nbsp;Activate&nbsp;Trace&nbsp;output&nbsp;to&nbsp;UDP</strong>
      </div>
      <div>UDP&nbsp;output&nbsp;counter&nbsp;will&nbsp;be&nbsp;cleared</div>
      <div>H48/UDP/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/UDP/-&nbsp;&nbsp;Stop&nbsp;Trace&nbsp;output&nbsp;to&nbsp;UDP</strong>
      </div>
      <div>H48/UDP/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/UDP/nnn...&nbsp;&nbsp;Change&nbsp;Trace&nbsp;UDP&nbsp;address+port</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UDP&nbsp;IP&nbsp;address</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UDP&nbsp;port&nbsp;number&nbsp;(default&nbsp;42397)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴────</div>
      <div>H48/UDP/___.___.___.___/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/FILE/+&nbsp;&nbsp;Open&nbsp;Trace&nbsp;output&nbsp;file</strong>
      </div>
      <div>File&nbsp;will&nbsp;be&nbsp;cleared&nbsp;if&nbsp;existing</div>
      <div>File&nbsp;output&nbsp;counter&nbsp;will&nbsp;be&nbsp;cleared</div>
      <div>H48/FILE/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/FILE/-&nbsp;&nbsp;Close&nbsp;Trace&nbsp;output&nbsp;file</strong>
      </div>
      <div>H48/FILE/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/FILE/cc..c&nbsp;&nbsp;Change&nbsp;Trace&nbsp;filename</strong>
      </div>
      <div>File&nbsp;will&nbsp;be&nbsp;closed&nbsp;if&nbsp;open</div>
      <div>
        NB:&nbsp;There&nbsp;shall&nbsp;be&nbsp;write&nbsp;access&nbsp;permission&nbsp;to&nbsp;the&nbsp;file&nbsp;location
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Filename&nbsp;(e.g.&nbsp;C:\Tetra\Work\Trace1.lan)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────────────────────────────────────────────────────────
      </div>
      <div>H48/FILE/_________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/n/s&nbsp;&nbsp;Start/Stop&nbsp;Trace</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Trace&nbsp;Number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Action</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Start&nbsp;Trace</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Stop&nbsp;Trace</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>H48/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H48/n/nn/n/c&nbsp;&nbsp;Setup&nbsp;Trace&nbsp;Test&nbsp;Point</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Trace&nbsp;Number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;TR&nbsp;(11..18)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;┌&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Test&nbsp;Point</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;U:&nbsp;Unencrypted</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;E:&nbsp;Encrypted&nbsp;(unencrypted&nbsp;if&nbsp;no&nbsp;AIE)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴─&nbsp;┴&nbsp;┴</div>
      <div>H48/_/__/_/_</div>
      <div />
      <div />
    </div>
    <h2>Tetra Channel Test:</h2> <div>H49&nbsp;TETRA&nbsp;CHANNEL&nbsp;TEST</div>
    <div>
      The&nbsp;H49&nbsp;commands&nbsp;controls&nbsp;the&nbsp;build-in&nbsp;TETRA&nbsp;channel&nbsp;test&nbsp;features.
    </div>
    <div>
      When&nbsp;channel&nbsp;test&nbsp;is&nbsp;activated,&nbsp;a&nbsp;test&nbsp;point&nbsp;at&nbsp;the&nbsp;output&nbsp;from&nbsp;or&nbsp;input&nbsp;to
    </div>
    <div>
      the&nbsp;receiver&nbsp;is&nbsp;selected.&nbsp;Received&nbsp;bursts&nbsp;including&nbsp;status&nbsp;will&nbsp;be&nbsp;analyzed
    </div>
    <div>and&nbsp;BER/MER&nbsp;calculated.</div>
    <div>
      When&nbsp;channel&nbsp;test&nbsp;is&nbsp;activated,&nbsp;the&nbsp;BS421&nbsp;TX&nbsp;switches&nbsp;to&nbsp;uplink&nbsp;mode&nbsp;and
    </div>
    <div>
      generates&nbsp;the&nbsp;selected&nbsp;channel&nbsp;type.&nbsp;When&nbsp;TX&nbsp;is&nbsp;keyed&nbsp;and&nbsp;RFTL&nbsp;activated,
    </div>
    <div>an&nbsp;RX&nbsp;input&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;for&nbsp;the&nbsp;testing.</div>
    <div>
      Alternatively,&nbsp;a&nbsp;test&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;from&nbsp;an&nbsp;external&nbsp;source,&nbsp;e.g.
    </div>
    <div>an&nbsp;R&amp;S&nbsp;SMIQ&nbsp;or&nbsp;Anritsu&nbsp;signal&nbsp;generator</div>
    <div>
      The&nbsp;uplink&nbsp;test&nbsp;point&nbsp;is&nbsp;intended&nbsp;for&nbsp;normal&nbsp;receiver&nbsp;channel&nbsp;tests.
    </div>
    <div>
      The&nbsp;downlink&nbsp;test&nbsp;point&nbsp;is&nbsp;intented&nbsp;for&nbsp;test&nbsp;of&nbsp;received&nbsp;traffic&nbsp;from&nbsp;the
    </div>
    <div>lines.</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49&nbsp;&nbsp;Display&nbsp;Tetra&nbsp;channel&nbsp;test&nbsp;selection</strong>
      </div>
      <div>H49</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49/c&nbsp;&nbsp;Change&nbsp;Sync&nbsp;Mode</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;A:&nbsp;PRBS&nbsp;Auto&nbsp;Sync</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;M:&nbsp;PRBS&nbsp;Forced&nbsp;Multiframe&nbsp;Sync</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;PRBS&nbsp;Forced&nbsp;Timeslot&nbsp;Sync</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H49/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49/n&nbsp;&nbsp;Change&nbsp;Sync&nbsp;Output&nbsp;Frequency</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1:&nbsp;1&nbsp;second</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;17&nbsp;second&nbsp;(TDMA&nbsp;frame&nbsp;start)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;3:&nbsp;51&nbsp;second&nbsp;(Multiframe&nbsp;start)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H49/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49/nn/n/nn&nbsp;&nbsp;Select&nbsp;Logical&nbsp;Channel&nbsp;to&nbsp;evaluate</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Tetra&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;┌&nbsp;Uplink&nbsp;test&nbsp;point&nbsp;channel&nbsp;types:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;07:&nbsp;TCH/7.2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;08:&nbsp;SCH/F</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;09:&nbsp;STCH</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;10:&nbsp;TCH/2.4&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;11:&nbsp;SCH/HU</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;16:&nbsp;TCH/S</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;18:&nbsp;TCH/4.8&nbsp;N=1</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;Downlink&nbsp;test&nbsp;point&nbsp;channel&nbsp;types:
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;57:&nbsp;TCH/7.2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;60:&nbsp;TCH/2.4&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;66:&nbsp;TCH/S</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;68:&nbsp;TCH/4.8&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴&nbsp;┴─&nbsp;</div>
      <div>H49/__/_/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49/CNTR&nbsp;&nbsp;Display&nbsp;Counters</strong>
      </div>
      <div>H49/CNTR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49/CLEAR&nbsp;&nbsp;Clear&nbsp;Counter&nbsp;Values</strong>
      </div>
      <div>H49/CLEAR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H49/DATA&nbsp;&nbsp;Display&nbsp;data&nbsp;from&nbsp;TR</strong>
      </div>
      <div>H49/DATA</div>
      <div />
      <div />
    </div>
    <h2>DC Voltmeter</h2> <div>H60&nbsp;DC&nbsp;VOLTMETER</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H60&nbsp;&nbsp;Display&nbsp;DC&nbsp;Voltmeter</strong>
      </div>
      <div>H60</div>
      <div />
      <div />
    </div>
    <h2>Co-Processor</h2> <div>*H61&nbsp;CO-PROCESSOR</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H61&nbsp;&nbsp;Display&nbsp;Co-Processor&nbsp;Status</strong>
      </div>
      <div>H61</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H61/Fhhh0&nbsp;&nbsp;Display&nbsp;DPRAM</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;DPRAM&nbsp;Address&nbsp;(F8000..FFFF0)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>H61/F___0</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H61/MEM/hhhhh&nbsp;&nbsp;Read&nbsp;CP&nbsp;Memory</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;CP&nbsp;Memory&nbsp;Address&nbsp;(00000..FFFFF)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>H61/MEM/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H61/PORT/hhhhh&nbsp;&nbsp;Read&nbsp;CP&nbsp;Port</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;CP&nbsp;Port&nbsp;Address&nbsp;(0000..FFFF)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>H61/PORT/____</div>
      <div />
      <div />
    </div>
    <h2>BS&lt;-&gt;BSC Message test queue</h2>
    <div>H63&nbsp;&nbsp;BS&lt;-&gt;BSC&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63&nbsp;&nbsp;Display&nbsp;BSC&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>H63</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63/N&nbsp;&nbsp;Display&nbsp;next&nbsp;BSC&nbsp;message</strong>
      </div>
      <div>H63/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;BSC&nbsp;message&nbsp;extended</strong>
      </div>
      <div>H63/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63/S&nbsp;&nbsp;Set&nbsp;BSC&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>H63/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63/C&nbsp;&nbsp;Clear&nbsp;BSC&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>H63/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63/s&nbsp;&nbsp;BSC&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;BSC&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;BSC&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H63/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H63/n/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;BSC</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;Number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>H63/_/__/______________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>UDP Message test queue</h2> <div>H64&nbsp;&nbsp;UDP&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64&nbsp;&nbsp;Display&nbsp;UDP&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>H64</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64/N&nbsp;&nbsp;Display&nbsp;next&nbsp;UDP&nbsp;message</strong>
      </div>
      <div>H64/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;UDP&nbsp;message&nbsp;extended</strong>
      </div>
      <div>H64/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64/S&nbsp;&nbsp;Set&nbsp;UDP&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>H64/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64/C&nbsp;&nbsp;Clear&nbsp;UDP&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>H64/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64/s&nbsp;&nbsp;UDP&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;UDP&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;UDP&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H64/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H64/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;Redundant&nbsp;BS</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>H64/__/______________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>BS&lt;-&gt;TR Message test queue</h2> <div>H65&nbsp;&nbsp;BS&lt;-&gt;TR&nbsp;MESSAGE&nbsp;TEST&nbsp;QUEUE</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65&nbsp;&nbsp;Display&nbsp;TR&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>H65</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/PS&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Link&nbsp;Status</strong>
      </div>
      <div>H65/PS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/N&nbsp;&nbsp;Display&nbsp;next&nbsp;TR&nbsp;message</strong>
      </div>
      <div>H65/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/N+&nbsp;&nbsp;Display&nbsp;next&nbsp;TR&nbsp;message&nbsp;extended</strong>
      </div>
      <div>H65/N+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/S&nbsp;&nbsp;Set&nbsp;TR&nbsp;message&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>H65/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/C&nbsp;&nbsp;Clear&nbsp;TR&nbsp;message&nbsp;test&nbsp;queue</strong>
      </div>
      <div>H65/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/s&nbsp;&nbsp;TR&nbsp;Message&nbsp;suppression</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;TR&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;some&nbsp;TR&nbsp;messages&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H65/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/nn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;TR</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;Address&nbsp;(11..48)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>H65/__/__/______________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H65/PS/nn/hh/hh..hh&nbsp;&nbsp;Send&nbsp;Message&nbsp;to&nbsp;PS411</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;PS&nbsp;Address&nbsp;(11..47)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Message&nbsp;ID&nbsp;(00..FF&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Payload&nbsp;(hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│0&nbsp;&nbsp;02&nbsp;&nbsp;04&nbsp;&nbsp;06&nbsp;&nbsp;08&nbsp;&nbsp;10&nbsp;&nbsp;12&nbsp;&nbsp;14&nbsp;&nbsp;16&nbsp;&nbsp;18&nbsp;&nbsp;20&nbsp;&nbsp;22&nbsp;&nbsp;24&nbsp;&nbsp;26&nbsp;&nbsp;28&nbsp;&nbsp;30&nbsp;&nbsp;32&nbsp;&nbsp;34
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐
      </div>
      <div>H65/PS/__/__/______________________________________________________________________</div>
      <div />
      <div />
    </div>
    <h2>Program Download</h2> <div>H68&nbsp;&nbsp;PROGRAM&nbsp;DOWNLOAD</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H68&nbsp;&nbsp;Display&nbsp;Program&nbsp;Download&nbsp;Status</strong>
      </div>
      <div>H68</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H68/TR412&nbsp;&nbsp;Display&nbsp;TR412&nbsp;Program&nbsp;Download&nbsp;Status</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;Command&nbsp;only&nbsp;available&nbsp;on&nbsp;active&nbsp;BSC</div>
      <div>H68/TR412</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H68/TR412/cc&nbsp;&nbsp;TR412&nbsp;Program&nbsp;Download&nbsp;command</strong>
      </div>
      <div>1)&nbsp;Sends&nbsp;Program&nbsp;Download&nbsp;command&nbsp;to&nbsp;TR412</div>
      <div>
        2)&nbsp;TR412&nbsp;restarts&nbsp;in&nbsp;Boot&nbsp;Mode,&nbsp;erases&nbsp;the&nbsp;flash&nbsp;program&nbsp;area
      </div>
      <div>&nbsp;&nbsp;&nbsp;and&nbsp;sends&nbsp;download&nbsp;request&nbsp;to&nbsp;BSC.</div>
      <div>3)&nbsp;BSC&nbsp;reads&nbsp;...\Pgm\TR412\TR412.bin&nbsp;file&nbsp;from&nbsp;disk</div>
      <div>4)&nbsp;BSC&nbsp;starts&nbsp;download&nbsp;to&nbsp;reprogram&nbsp;TR412&nbsp;Flash</div>
      <div>5)&nbsp;TR412&nbsp;restarts&nbsp;again&nbsp;and&nbsp;resumes&nbsp;normal&nbsp;operation</div>
      <div>NB:&nbsp;Boot&nbsp;Part&nbsp;of&nbsp;TR412&nbsp;software&nbsp;is&nbsp;NOT&nbsp;updated.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;Boot&nbsp;version&nbsp;can&nbsp;be&nbsp;displayed&nbsp;with&nbsp;the&nbsp;TR412&nbsp;'00/A'&nbsp;command.
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;Command&nbsp;only&nbsp;available&nbsp;on&nbsp;active&nbsp;BSC</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;All&nbsp;TR
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;11..48:&nbsp;TR11..TR48</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>H68/TR412/__</div>
      <div />
      <div />
    </div>
    <h2>BS configuration:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H70&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>H70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H70/SAVE&nbsp;&nbsp;Save&nbsp;BS&nbsp;configuration&nbsp;in&nbsp;file</strong>
      </div>
      <div>Note&nbsp;that&nbsp;BS&nbsp;Configuration&nbsp;changes&nbsp;are&nbsp;saved&nbsp;automatically</div>
      <div>H70/SAVE</div>
      <div />
      <div />
    </div>
    <h2>Common system configuration:</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71&nbsp;&nbsp;Display&nbsp;Common&nbsp;BS&nbsp;configuration</strong>
      </div>
      <div>H71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/CNFG/+&nbsp;&nbsp;Activate&nbsp;BS</strong>
      </div>
      <div>H71/CNFG/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/CNFG/-&nbsp;&nbsp;Deactivate&nbsp;BS</strong>
      </div>
      <div>H71/CNFG/-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/BSNO/n&nbsp;&nbsp;Change&nbsp;BS&nbsp;Number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BS&nbsp;Number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H71/BSNO/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/RACKNO/n&nbsp;&nbsp;Change&nbsp;Rack&nbsp;Number</strong>
      </div>
      <div>Rack&nbsp;number&nbsp;(1st&nbsp;digit&nbsp;of&nbsp;Address)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Rack&nbsp;Number&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H71/RACKNO/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/REDBS/s&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;BS&nbsp;Configuration</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Redundant&nbsp;BS&nbsp;Configuration&nbsp;Flag
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H71/REDBS/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/REDBS/...&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;BS&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>H71/REDBS/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/REDBS/nn&nbsp;&nbsp;Change&nbsp;Redundant&nbsp;BS&nbsp;Timeout</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Redundant&nbsp;BS&nbsp;Timeout</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;03..15s</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─</div>
      <div>H71/REDBS/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/BSC/n/s&nbsp;&nbsp;Change&nbsp;BSC&nbsp;Configuration</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;BSC&nbsp;Configuration&nbsp;Flag</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Select&nbsp;BSC</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Deselect&nbsp;BSC</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>H71/BSC/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/BSC/n/nnn.nnn.nnn.nnn&nbsp;&nbsp;Change&nbsp;BSC&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;BSC&nbsp;number&nbsp;(1..2)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────</div>
      <div>H71/BSC/_/___.___.___.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/CMOIP&nbsp;&nbsp;Display&nbsp;CMoIP&nbsp;selection</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;not&nbsp;available&nbsp;in&nbsp;Damm&nbsp;TetraFlex</div>
      <div>H71/CMOIP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/CMOIP/s&nbsp;&nbsp;Change&nbsp;CMoIP&nbsp;selection</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;not&nbsp;available&nbsp;in&nbsp;Damm&nbsp;TetraFlex</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;CMoIP&nbsp;Selection</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;CMoIP&nbsp;Multicast</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;CMoIP&nbsp;to&nbsp;BSS</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H71/CMOIP/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H71/CMOIP/n/...&nbsp;&nbsp;Change&nbsp;CMoIP&nbsp;BSS&nbsp;IP&nbsp;Address</strong>
      </div>
      <div>NB:&nbsp;Command&nbsp;not&nbsp;available&nbsp;in&nbsp;Damm&nbsp;TetraFlex</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Active&nbsp;BSS&nbsp;number&nbsp;(1..2)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴──────────────</div>
      <div>H71/CMOIP/_/___.___.___.___</div>
      <div />
      <div />
    </div>
    <h2>Synchronization configurations</h2> <div>H72&nbsp;&nbsp;Synchronisation</div>
    <div>
      The&nbsp;TETRA&nbsp;Base&nbsp;Station&nbsp;TR's&nbsp;need&nbsp;to&nbsp;be&nbsp;time&nbsp;synchronized&nbsp;to&nbsp;operate&nbsp;correctly.
    </div>
    <div>
      Most&nbsp;importantly,&nbsp;all&nbsp;TR's&nbsp;in&nbsp;a&nbsp;radio&nbsp;cell&nbsp;shall&nbsp;be&nbsp;time&nbsp;synchronized&nbsp;together&nbsp;to&nbsp;
    </div>
    <div>
      ensure&nbsp;correct&nbsp;timing&nbsp;for&nbsp;the&nbsp;mobiles,&nbsp;when&nbsp;switching&nbsp;from&nbsp;the&nbsp;control&nbsp;channel&nbsp;to
    </div>
    <div>a&nbsp;traffic&nbsp;channel&nbsp;on&nbsp;another&nbsp;TR.</div>
    <div>
      For&nbsp;a&nbsp;multi-cell&nbsp;systems,&nbsp;all&nbsp;radio&nbsp;cells&nbsp;are&nbsp;also&nbsp;recommended&nbsp;to&nbsp;be&nbsp;time&nbsp;synchronized&nbsp;together
    </div>
    <div>to&nbsp;enable&nbsp;use&nbsp;of&nbsp;seamless&nbsp;handover&nbsp;(cell&nbsp;re-selection).</div>
    <div>
      The&nbsp;Damm&nbsp;BS&nbsp;system&nbsp;is&nbsp;provided&nbsp;with&nbsp;a&nbsp;flexible&nbsp;system&nbsp;to&nbsp;provide&nbsp;synchronization
    </div>
    <div>
      between&nbsp;individual&nbsp;Transceivers.&nbsp;Build-in&nbsp;GPS&nbsp;Receivers&nbsp;give&nbsp;absolute&nbsp;time
    </div>
    <div>
      synchronization&nbsp;to&nbsp;the&nbsp;radio&nbsp;cells.&nbsp;External&nbsp;Inputs&nbsp;can&nbsp;also&nbsp;be&nbsp;marked&nbsp;as&nbsp;Primary
    </div>
    <div>Sync&nbsp;References.&nbsp;&nbsp;</div>
    <div>
      When&nbsp;as&nbsp;BS&nbsp;is&nbsp;Network&nbsp;locked,&nbsp;automatic&nbsp;frequency&nbsp;correction&nbsp;of&nbsp;the&nbsp;Master
    </div>
    <div>
      Oscillator&nbsp;(RX&nbsp;and&nbsp;TX&nbsp;carrier&nbsp;frequencies)&nbsp;are&nbsp;provided,&nbsp;and&nbsp;Seamless&nbsp;Handover&nbsp;to
    </div>
    <div>other&nbsp;Network&nbsp;Synchronized&nbsp;Cells&nbsp;are&nbsp;activated.</div>
    <div>
      The&nbsp;BS&nbsp;can&nbsp;be&nbsp;setup&nbsp;to&nbsp;run&nbsp;as&nbsp;either&nbsp;Sync&nbsp;Master&nbsp;0..4&nbsp;or&nbsp;Slave.&nbsp;The&nbsp;Masters
    </div>
    <div>
      shall&nbsp;preferably&nbsp;be&nbsp;provided&nbsp;with&nbsp;GPS&nbsp;antennas&nbsp;or&nbsp;an&nbsp;External&nbsp;Primary&nbsp;Sync&nbsp;source.
    </div>
    <div>
      The&nbsp;remaining&nbsp;BS's&nbsp;shall&nbsp;be&nbsp;setup&nbsp;as&nbsp;Slave&nbsp;and&nbsp;do&nbsp;not&nbsp;need&nbsp;a&nbsp;GPS&nbsp;antenna.
    </div>
    <div>
      The&nbsp;BS&nbsp;is&nbsp;provided&nbsp;with&nbsp;one&nbsp;External&nbsp;1PPS&nbsp;(1&nbsp;Pulse&nbsp;Per&nbsp;Second)&nbsp;output&nbsp;and
    </div>
    <div>
      three&nbsp;external&nbsp;1PPS&nbsp;inputs.&nbsp;&nbsp;The&nbsp;Slaves&nbsp;shall&nbsp;have&nbsp;their&nbsp;external&nbsp;1PPS&nbsp;inputs
    </div>
    <div>connected&nbsp;to&nbsp;Masters.</div>
    <div>
      In&nbsp;addition&nbsp;to&nbsp;the&nbsp;hardware&nbsp;1PPS,&nbsp;sync&nbsp;messages&nbsp;are&nbsp;send&nbsp;every&nbsp;second&nbsp;on&nbsp;IP
    </div>
    <div>from&nbsp;the&nbsp;Masters&nbsp;to&nbsp;attached&nbsp;BS's.</div>
    <div>A&nbsp;Master&nbsp;will&nbsp;indicate&nbsp;Master&nbsp;Mode,&nbsp;if:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;Synchronized&nbsp;to&nbsp;the&nbsp;internal&nbsp;GPS&nbsp;RX&nbsp;or</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;Synchronized&nbsp;to&nbsp;an&nbsp;External&nbsp;Input&nbsp;marked&nbsp;as&nbsp;Primary&nbsp;and&nbsp;indicating
    </div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Master&nbsp;Mode</div> <div>Master&nbsp;0&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div> <div>Master&nbsp;1&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div> <div>Master&nbsp;2&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1&nbsp;</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div> <div>Master&nbsp;3&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1&nbsp;</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;2&nbsp;</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Resync-only</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div> <div>Master&nbsp;4&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0&nbsp;</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1&nbsp;</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;2&nbsp;</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;3&nbsp;</div>
    <div>&nbsp;&nbsp;10)Int.&nbsp;Free-Run</div> <div>Slave&nbsp;priority:</div>
    <div>&nbsp;&nbsp;1)&nbsp;Ext.&nbsp;Master&nbsp;0,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;2)&nbsp;Ext.&nbsp;Master&nbsp;1,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;3)&nbsp;Ext.&nbsp;Master&nbsp;2,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;4)&nbsp;Ext.&nbsp;Master&nbsp;3,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;5)&nbsp;Ext.&nbsp;Master&nbsp;4,&nbsp;Master&nbsp;Mode</div>
    <div>&nbsp;&nbsp;6)&nbsp;Ext.&nbsp;Master&nbsp;0</div>
    <div>&nbsp;&nbsp;7)&nbsp;Ext.&nbsp;Master&nbsp;1</div>
    <div>&nbsp;&nbsp;8)&nbsp;Ext.&nbsp;Master&nbsp;2</div>
    <div>&nbsp;&nbsp;9)&nbsp;Ext.&nbsp;Master&nbsp;3</div>
    <div>&nbsp;&nbsp;10)Ext.&nbsp;Master&nbsp;4</div>
    <div>&nbsp;&nbsp;11)Int.&nbsp;GPS&nbsp;RX</div>
    <div>&nbsp;&nbsp;12)Int.&nbsp;Free-Run</div>
    <div>
      If&nbsp;no&nbsp;GPS&nbsp;synchronized&nbsp;Master&nbsp;or&nbsp;higher&nbsp;prioritized&nbsp;free-running&nbsp;Master&nbsp;is
    </div>
    <div>
      available&nbsp;at&nbsp;re-start,&nbsp;Masters&nbsp;will&nbsp;try&nbsp;initially&nbsp;to&nbsp;synchronize&nbsp;to&nbsp;lower
    </div>
    <div>prioritized&nbsp;free-running&nbsp;Masters.</div>
    <div>
      A&nbsp;BS&nbsp;will&nbsp;resync&nbsp;automatically&nbsp;after&nbsp;a&nbsp;short&nbsp;time,&nbsp;if&nbsp;Century&nbsp;Second
    </div>
    <div>
      and&nbsp;Phase&nbsp;Detector&nbsp;are&nbsp;out&nbsp;of&nbsp;sync&nbsp;with&nbsp;a&nbsp;valid&nbsp;sync&nbsp;source.
    </div>
    <div>
      Masters&nbsp;shall&nbsp;be&nbsp;setup&nbsp;even&nbsp;if&nbsp;GPS&nbsp;antennas&nbsp;are&nbsp;not&nbsp;used&nbsp;and&nbsp;internal&nbsp;GPS&nbsp;RX
    </div>
    <div>
      are&nbsp;deselected,&nbsp;to&nbsp;ensure&nbsp;proper&nbsp;synchronization&nbsp;between&nbsp;all&nbsp;TR's.
    </div>
    <div>Typical&nbsp;BS41x&nbsp;configuration&nbsp;without&nbsp;redundant&nbsp;BSC412:</div>
    <div>&nbsp;&nbsp;BS&nbsp;activated&nbsp;on&nbsp;BSC412:&nbsp;Sync&nbsp;Master&nbsp;1</div>
    <div>Typical&nbsp;BS41x&nbsp;configuration&nbsp;with&nbsp;redundant&nbsp;BSC412:</div>
    <div>&nbsp;&nbsp;BSC412-11:&nbsp;Sync&nbsp;Master&nbsp;1</div>
    <div>&nbsp;&nbsp;BSC412-12:&nbsp;Sync&nbsp;Master&nbsp;2</div>
    <div>
      Typical&nbsp;dual-rack&nbsp;BS41x&nbsp;configuration&nbsp;with&nbsp;redundant&nbsp;BSC412&nbsp;in&nbsp;each&nbsp;rack:
    </div>
    <div>&nbsp;&nbsp;BSC412-11:&nbsp;Sync&nbsp;Master&nbsp;1</div>
    <div>&nbsp;&nbsp;BSC412-12:&nbsp;Sync&nbsp;Master&nbsp;2</div>
    <div>&nbsp;&nbsp;BSC412-21:&nbsp;Sync&nbsp;Master&nbsp;3</div>
    <div>&nbsp;&nbsp;BSC412-22:&nbsp;Sync&nbsp;Master&nbsp;4</div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72&nbsp;&nbsp;Display&nbsp;BSC412&nbsp;Sync&nbsp;selection</strong>
      </div>
      <div>H72</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/MASTER/c&nbsp;&nbsp;Change&nbsp;Master&nbsp;Priority</strong>
      </div>
      <div>Changes&nbsp;the&nbsp;Synchronization&nbsp;Priority&nbsp;for&nbsp;the&nbsp;BS</div>
      <div>
        Master&nbsp;1&nbsp;shall&nbsp;be&nbsp;selected&nbsp;for&nbsp;the&nbsp;Primary&nbsp;Master&nbsp;in&nbsp;a&nbsp;cell.
      </div>
      <div>
        Master&nbsp;2..4&nbsp;shall&nbsp;be&nbsp;selected&nbsp;for&nbsp;the&nbsp;Backup&nbsp;Masters&nbsp;in&nbsp;a&nbsp;cell.
      </div>
      <div>
        Master&nbsp;0&nbsp;shall&nbsp;only&nbsp;be&nbsp;selected&nbsp;if&nbsp;the&nbsp;BS&nbsp;is&nbsp;Global&nbsp;Master&nbsp;used&nbsp;to
      </div>
      <div>synchronize&nbsp;other&nbsp;cells.</div>
      <div>
        Warning:&nbsp;The&nbsp;Master&nbsp;selections&nbsp;shall&nbsp;be&nbsp;unique&nbsp;within&nbsp;a&nbsp;Radio&nbsp;Cell
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0:&nbsp;Master&nbsp;0&nbsp;(For&nbsp;Global&nbsp;Master)&nbsp;(Highest&nbsp;Priority)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;1:&nbsp;Master&nbsp;1&nbsp;(For&nbsp;Primary&nbsp;Master&nbsp;in&nbsp;a&nbsp;Cell)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;2:&nbsp;Master&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;3:&nbsp;Master&nbsp;3</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;4:&nbsp;Master&nbsp;4&nbsp;(Lowest&nbsp;Priority)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;S:&nbsp;Slave</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;</div>
      <div>H72/MASTER/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/CENTSECOFS/snnnnnnnnnn&nbsp;&nbsp;Change&nbsp;Century&nbsp;Second&nbsp;offset</strong>
      </div>
      <div>
        Used&nbsp;to&nbsp;change&nbsp;the&nbsp;Century&nbsp;Second&nbsp;offset,&nbsp;where&nbsp;all&nbsp;TETRA&nbsp;counters&nbsp;started
      </div>
      <div>For&nbsp;standard&nbsp;Damm&nbsp;TetraFlex&nbsp;this&nbsp;value&nbsp;shall&nbsp;be&nbsp;0.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Century&nbsp;Second&nbsp;Offset
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;DAMM:&nbsp;+0000000000&nbsp;[2000-01-01&nbsp;00:00:00&nbsp;UTC]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;EADS:&nbsp;+0031536013&nbsp;[1999-01-01&nbsp;00:00:00&nbsp;GPS]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────</div>
      <div>H72/CENTSECOFS/___________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/CENTSECOFS/DAMM&nbsp;&nbsp;Change&nbsp;Century&nbsp;Second&nbsp;offset&nbsp;to&nbsp;DAMM</strong>
      </div>
      <div>Used&nbsp;to&nbsp;clear&nbsp;Century&nbsp;Seconds&nbsp;offset&nbsp;for&nbsp;DAMM&nbsp;timing</div>
      <div>Value:&nbsp;0&nbsp;for&nbsp;2000-01-01&nbsp;00:00:00&nbsp;UTC</div>
      <div>H72/CENTSECOFS/DAMM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/CENTSECOFS/EADS&nbsp;&nbsp;Change&nbsp;Century&nbsp;Second&nbsp;offset&nbsp;to&nbsp;EADS</strong>
      </div>
      <div>Used&nbsp;to&nbsp;setup&nbsp;the&nbsp;Centrury&nbsp;Seconds&nbsp;offset&nbsp;for&nbsp;EADS&nbsp;timing</div>
      <div>
        Value:&nbsp;+31536013&nbsp;(1&nbsp;year&nbsp;+&nbsp;13&nbsp;leap&nbsp;seconds)&nbsp;for&nbsp;1999-01-01&nbsp;00:00:00&nbsp;GPS
      </div>
      <div>H72/CENTSECOFS/EADS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/GPSRXOFS/snnn.n&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;offset</strong>
      </div>
      <div>Used&nbsp;to&nbsp;change&nbsp;the&nbsp;GPS&nbsp;RX&nbsp;time&nbsp;offset</div>
      <div>For&nbsp;standard&nbsp;Damm&nbsp;TetraFlex&nbsp;this&nbsp;value&nbsp;shall&nbsp;be&nbsp;0.</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;GPS&nbsp;RX&nbsp;offset
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;DAMM:&nbsp;+000.0&nbsp;[usec.]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;EADS:&nbsp;+111.1&nbsp;[usec.]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>H72/GPSRXOFS/____._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/GPSRXOFS/DAMM&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;to&nbsp;DAMM</strong>
      </div>
      <div>Used&nbsp;to&nbsp;clear&nbsp;the&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;for&nbsp;DAMM&nbsp;timing</div>
      <div>Value:&nbsp;+000.0&nbsp;userc.</div>
      <div>H72/GPSRXOFS/DAMM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/GPSRXOFS/EADS&nbsp;&nbsp;Change&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;to&nbsp;EADS</strong>
      </div>
      <div>Used&nbsp;to&nbsp;setup&nbsp;the&nbsp;GPS&nbsp;RX&nbsp;offset&nbsp;for&nbsp;EADS&nbsp;timing</div>
      <div>Value:&nbsp;-055.6&nbsp;usec.</div>
      <div>H72/GPSRXOFS/EADS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/IN/n/s&nbsp;&nbsp;Activate/Deactivate&nbsp;BSC412&nbsp;Sync&nbsp;Input</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Input</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;G:&nbsp;GPS&nbsp;RX</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;Ext.&nbsp;0&nbsp;(Other&nbsp;BSC)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Ext.&nbsp;1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;Ext.&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Activate/Deactivate</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;configured</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>H72/IN/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/IN/n/...&nbsp;&nbsp;Setup&nbsp;BSC412&nbsp;External&nbsp;Sync&nbsp;Input</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Input</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;External&nbsp;1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;External&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;Primary&nbsp;Flag</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;-:&nbsp;Not&nbsp;Primary&nbsp;Input&nbsp;(Normal)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;+:&nbsp;Primary&nbsp;Input&nbsp;(For&nbsp;Remote&nbsp;Primary&nbsp;Reference&nbsp;only)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;┌&nbsp;IP&nbsp;Address</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Cable&nbsp;Delay&nbsp;in&nbsp;usec.&nbsp;(000.0..999.9)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴&nbsp;┴──────────────&nbsp;┴────</div>
      <div>H72/IN/_/_/___.___.___.___/___._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/OUT&nbsp;&nbsp;Display&nbsp;BSC412&nbsp;Sync&nbsp;Message&nbsp;Output&nbsp;Table</strong>
      </div>
      <div>H72/OUT</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H72/OUT/...&nbsp;&nbsp;Add/Remove&nbsp;BSC412&nbsp;Sync&nbsp;Message&nbsp;Output</strong>
      </div>
      <div>
        All&nbsp;units&nbsp;connected&nbsp;to&nbsp;the&nbsp;1PPS&nbsp;output&nbsp;shall&nbsp;be&nbsp;added&nbsp;here
      </div>
      <div>Max.&nbsp;30&nbsp;IP&nbsp;Addresses&nbsp;can&nbsp;be&nbsp;configured</div>
      <div>Outputs&nbsp;are&nbsp;NOT&nbsp;active&nbsp;if&nbsp;Sync&nbsp;Slave&nbsp;Priority</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;IP&nbsp;Address&nbsp;(000..255)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Action:
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Add
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;Remove
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────&nbsp;┴</div>
      <div>H72/OUT/___.___.___.___/_</div>
      <div />
      <div />
    </div>
    <h2>Radio Cell configuration</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H73&nbsp;&nbsp;Display&nbsp;Radio&nbsp;Cell&nbsp;configuration</strong>
      </div>
      <div>H73</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H73/RXDIV/c&nbsp;&nbsp;Change&nbsp;RX&nbsp;Diversity&nbsp;configuration</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;diversity&nbsp;configuration</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;D:&nbsp;Diversity</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;RX-A&nbsp;antenna&nbsp;only
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;RX-B&nbsp;antenna&nbsp;only
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H73/RXDIV/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H73/RXPREGAIN/n.n&nbsp;&nbsp;Change&nbsp;RX&nbsp;Pre-Gain</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TMA&nbsp;pregain&nbsp;in&nbsp;dB&nbsp;(0.0..9.9,&nbsp;nom.&nbsp;6.0)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>H73/RXPREGAIN/_._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H73/RXBAND/...&nbsp;&nbsp;Change&nbsp;RX&nbsp;frequency&nbsp;band</strong>
      </div>
      <div>Define&nbsp;the&nbsp;RX&nbsp;frequency&nbsp;band&nbsp;of&nbsp;the&nbsp;cell</div>
      <div>Shall&nbsp;match&nbsp;the&nbsp;TMA&nbsp;filter&nbsp;adjustment</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;low&nbsp;frequency</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;nnn.nnnnnn:&nbsp;Frequency&nbsp;[MHz]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;000.000000:&nbsp;Not&nbsp;setup
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;high&nbsp;frequency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;nnn.nnnnnn:&nbsp;Frequency&nbsp;[MHz]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;000.000000:&nbsp;Not&nbsp;setup
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────&nbsp;┴─────────</div>
      <div>H73/RXBAND/___.______/___.______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H73/TXBAND/...&nbsp;&nbsp;Change&nbsp;TX&nbsp;frequency&nbsp;band</strong>
      </div>
      <div>Define&nbsp;the&nbsp;TX&nbsp;frequency&nbsp;band&nbsp;of&nbsp;the&nbsp;cell</div>
      <div>Shall&nbsp;match&nbsp;the&nbsp;TX&nbsp;filter&nbsp;adjustment</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;low&nbsp;frequency</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;nnn.nnnnnn:&nbsp;Frequency&nbsp;[MHz]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;000.000000:&nbsp;Not&nbsp;setup
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;high&nbsp;frequency
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;nnn.nnnnnn:&nbsp;Frequency&nbsp;[MHz]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;000.000000:&nbsp;Not&nbsp;setup
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────────&nbsp;┴─────────</div>
      <div>H73/TXBAND/___.______/___.______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H73/TXANT/...&nbsp;&nbsp;Change&nbsp;TX&nbsp;antenna&nbsp;alarm&nbsp;settings</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;antenna&nbsp;L1&nbsp;alarm&nbsp;setting&nbsp;(06..16&nbsp;[dB&nbsp;Return&nbsp;Loss])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;TX&nbsp;antenna&nbsp;L2&nbsp;alarm&nbsp;setting&nbsp;(06..16&nbsp;[dB&nbsp;Return&nbsp;Loss])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Minimum&nbsp;forward&nbsp;power&nbsp;(0.2..9.9&nbsp;[W])
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Minimum&nbsp;reflected&nbsp;power&nbsp;(0.01..0.99&nbsp;[W])
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴─&nbsp;┴──&nbsp;┴───</div>
      <div>H73/TXANT/__/__/_._/0.__</div>
      <div />
      <div />
    </div>
    <h2>Power Supply configuration</h2>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H74&nbsp;&nbsp;Display&nbsp;PS411&nbsp;Power&nbsp;Supply&nbsp;configuration</strong>
      </div>
      <div>H74</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H74/AC/s&nbsp;&nbsp;Change&nbsp;PS411&nbsp;AC&nbsp;Input&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;AC&nbsp;input</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;AC&nbsp;input&nbsp;provided</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;No&nbsp;AC&nbsp;input&nbsp;provided&nbsp;(Mains&nbsp;missing&nbsp;alarm&nbsp;suppressed)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H74/AC/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H74/CHARGE/s&nbsp;&nbsp;Change&nbsp;PS411&nbsp;Battery&nbsp;Charging&nbsp;selection</strong>
      </div>
      <div>NB:&nbsp;This&nbsp;is&nbsp;only&nbsp;significant,&nbsp;if&nbsp;AC&nbsp;Input&nbsp;is&nbsp;selected</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Battery&nbsp;charging</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;+:&nbsp;Battery&nbsp;charging&nbsp;used
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;-:&nbsp;No&nbsp;Battery&nbsp;charging
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>H74/CHARGE/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H74/CHGVOLT/nn.n&nbsp;&nbsp;Change&nbsp;PS411&nbsp;Charging&nbsp;Voltage</strong>
      </div>
      <div>
        NB:&nbsp;This&nbsp;is&nbsp;only&nbsp;significant,&nbsp;if&nbsp;AC&nbsp;Input&nbsp;and&nbsp;Charging&nbsp;is&nbsp;selected
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Charging&nbsp;voltage&nbsp;at&nbsp;25&nbsp;Celsius&nbsp;in&nbsp;Volt&nbsp;(52.8..55.2)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>H74/CHGVOLT/__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>H74/CHGCOMP/nnn&nbsp;&nbsp;Change&nbsp;PS411&nbsp;Charging&nbsp;Compensation</strong>
      </div>
      <div>
        NB:&nbsp;This&nbsp;is&nbsp;only&nbsp;significant,&nbsp;if&nbsp;AC&nbsp;Input&nbsp;and&nbsp;Charging&nbsp;is&nbsp;selected
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Charging&nbsp;temperature&nbsp;compensation&nbsp;(48..120)&nbsp;[-mV/C]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>H74/CHGCOMP/___</div>
      <div />
    </div>
  </>
);

export default memo(BSPage) as typeof BSPage;
