import get from 'lodash/get';
import last from 'lodash/last';
import { API_HOST, APP_VERSION, SENTRY_ENV, SENTRY_DSN, NODE_ENV } from 'src/constants';

//
// if (SERVER) {
//   process.on('uncaughtException', exception => {
//     console.error(exception);
//     //
//     if (typeof get(Sentry, 'captureException') === 'function') {
//       Sentry.captureException(exception);
//     }
//     //
//     process.exit(1);
//   });

//   process.on('unhandledRejection', promiseRejection => {
//     console.error(promiseRejection);
//     //
//     if (typeof get(Sentry, 'captureException') === 'function') {
//       Sentry.captureException(promiseRejection);
//     }
//     //
//     process.exit(1);
//   });
// }

const beforeSend = event => {
  const lastBreadcrumb = last(get(event, 'breadcrumbs', []));
  const category = get(lastBreadcrumb, 'category', '');
  const statusCode = String(get(lastBreadcrumb, 'data.status_code', ''));
  if (category === 'xhr' && statusCode === '400') {
    return null;
  }
  return event;
};

export default function initSentry(sentry, params = {}) {
  const {
    release = `radiotel_operator_web@${APP_VERSION}`,
    enabled = true,
    dsn = SENTRY_DSN,
    environment = SENTRY_ENV,
    serverName = API_HOST,
    maxBreadcrumbs = 25,
    sendDefaultPii = true,
    attachStacktrace = true,
    debug = false,
    logLevel = NODE_ENV !== 'production' ? 2 : 0,
  } = params;

  sentry.init({
    release,
    dsn,
    enabled,
    environment,
    // debug: process.env.NODE_ENV !== 'production',
    debug,
    // None = 0, // No logs will be generated
    // Error = 1, // Only SDK internal errors will be logged
    // Debug = 2, // Information useful for debugging the SDK will be logged
    // Verbose = 3 // All SDK actions will be logged
    logLevel,
    serverName,
    sendDefaultPii,
    attachStacktrace,
    maxBreadcrumbs,
    beforeSend,
    /*
    Configures the sample rate as a percentage of events
    to be sent in the range of 0.0 to 1.0. The default is
    1.0 which means that 100% of events are sent. If set
    to 0.1 only 10% of events will be sent. Events are
    picked randomly.
    */

    // sampleRate: 1,
    // ...
  });
  //
  if (SERVER) {
    global.Sentry = sentry;
  } else {
    window.Sentry = sentry;
  }
}

export async function sendErrorToSentry(error, args = {}) {
  if (!SENTRY_DSN) {
    return;
  }
  //
  const clientIP = get(args, 'clientIP') || get(args, 'user.ip') || get(args, 'state.clientIP');
  //
  const request = get(args, 'request', {});
  //
  const body = (request.body && { ...request.body }) || {};
  // Remove private data from our logs
  if (body.password) body.password = '[Filtered]';
  if (body.key) body.key = '[Filtered]';
  if (body.token) body.token = '[Filtered]';

  //
  Sentry.captureException(
    error,
    {
      req: {
        ...request,
        body,
      },
      user: {
        ip_address: clientIP,
      },
    },
    (sendErr, eventId) => {
      if (sendErr) {
        // do not log whole 'sentry' error to stdout, as it could be very huge
        console.error('Failed to send captured exception to Sentry', error);
      } else {
        console.info(`Captured exception (ID: ${eventId}) and send to Sentry successfully`);
      }
    },
  );
}
