import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';
import { useParams } from '@fjedi/react-router-helpers';
import { Row, Col } from 'antd';
import { Title } from 'src/components/ui-kit/typography';
import { StyledTable } from 'src/components/ui-kit/table';
import NodeIndications from 'src/components/common/node-indications';
import {
  NodeAlertLevel,
  NodeAlertSettings,
  NodeStatus,
  useNodeSettingsQuery,
  useNodeStatusQuery,
} from 'src/graphql/generated';
import { statusObjs } from 'src/helpers/tetra';
import Spinner from 'src/components/ui-kit/spinner';
import { alertFieldMap, boxes } from './constants';
import EditNodeSettingsModal from './edit-node-settings-modal';
import TetraOmConsoleModal from './tetra-om-console';
import RDPClientModal from './rdp-client';

const Container = styled.div`
  position: sticky;
  top: 2rem;
`;

const Line = styled(Row)`
  margin-top: 1rem;

  .ant-col {
    text-align: center;
  }
`;

const Box = styled.div<{ status: { boxColor: string; boxBackground: string } }>`
  border: 1px solid black;
  border-radius: 6px;
  padding: 0.5rem;

  background: ${({ status: { boxBackground } }) => `${boxBackground}`};
  color: ${({ status: { boxColor } }) => `${boxColor}`};
`;

const getLabel = (state: keyof typeof statusObjs) => {
  const status = statusObjs[state];

  const suffix = state > 0 && state < 255 ? ` [L${state}]` : '';
  return status && (status.label || status.boxLabel) ? `${status.label || status.boxLabel}${suffix}` : '-';
};

const NodeInfo: FC = props => {
  const { t } = useTranslation();
  const { nodeNumber } = useParams();
  const { data: nodeStatusData, loading: nodeStatusLoading } = useNodeStatusQuery({
    variables: {
      nodeNumber: nodeNumber!,
    },
    skip: !nodeNumber,
  });
  const nodeStatus = useMemo(() => nodeStatusData?.nodeStatus, [nodeStatusData]);
  //
  const { data: nodeSettingsData, loading: nodeSettingsLoading } = useNodeSettingsQuery({
    variables: {
      nodeNumber: nodeNumber!,
    },
    skip: !nodeNumber,
  });
  const nodeSettings = useMemo(() => nodeSettingsData?.nodeSettings, [nodeSettingsData]);
  const columns = useMemo(() => {
    if (!nodeSettings) {
      return [];
    }
    const { timeoutBeforeAlert, alertSettings, notificationInterval } = nodeSettings;
    const cols = [
      { option: 'Задержка отправки уведомлений', value: `${timeoutBeforeAlert} сек` },
      { option: 'Периодичность отправки уведомлений', value: `${notificationInterval / 60} минут` },
    ];
    Object.keys(omit(alertSettings, ['__typename'])).forEach(alertField => {
      const f = alertField as unknown as keyof NodeAlertSettings;
      const v = alertSettings[f];
      cols.push({
        option: `Уровень аварии для ${t(alertFieldMap.get(f)!)}`,
        value: v === NodeAlertLevel.Ignore ? 'Игнорировать' : v,
      });
    });
    return cols;
  }, [t, nodeSettings]);

  return (
    <Spinner spinning={nodeStatusLoading}>
      <Container>
        <Title level={4}>{t('Node status')}</Title>
        {!nodeNumber && t('Select node')}
        {!!nodeNumber && !!nodeStatus && (
          <>
            <h2>{`N${String(nodeNumber).padStart(3, '0')}: ${nodeStatus.description}`}</h2>
            {boxes.map((bb, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Line gutter={16} key={`line-${i}`}>
                {bb.map(([label, key]) => {
                  const state = nodeStatus[key] as keyof typeof statusObjs;
                  const status = statusObjs[state];

                  return i === boxes.length - 1 ? (
                    <Col key={key} className="gutter-row" span={6}>
                      <Box status={status}>{getLabel(state)}</Box>
                      <div>{t(label)}</div>
                    </Col>
                  ) : (
                    <Col key={key} className="gutter-row" span={6}>
                      <div>{t(label)}</div>
                      <Box status={status}>{getLabel(state)}</Box>
                    </Col>
                  );
                })}
              </Line>
            ))}

            <NodeIndications nodeNumber={nodeNumber}>
              <TetraOmConsoleModal nodeNumber={nodeNumber} nodeSettings={nodeSettings} />
              <hr />
              <RDPClientModal nodeNumber={nodeNumber} />
            </NodeIndications>

            <Title style={{ marginTop: '2.4rem', lineHeight: 1.6 }} level={4}>
              {t('Notifications')}
              <EditNodeSettingsModal nodeNumber={nodeNumber} />
            </Title>

            <StyledTable
              rowKey="id"
              columns={[
                {
                  title: t('Option'),
                  dataIndex: 'option',
                },
                {
                  title: t('Value'),
                  dataIndex: 'value',
                  render(description: string) {
                    return description || 'Не указано';
                  },
                },
              ]}
              bordered
              loading={nodeSettingsLoading}
              dataSource={columns}
            />
          </>
        )}
      </Container>
    </Spinner>
  );
};

export default memo(NodeInfo);
