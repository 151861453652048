import { NodeAlertLevel, NodeAlertSettings } from 'src/graphql/generated';

export const alertFieldMap: Map<keyof NodeAlertSettings, string> = new Map([
  ['alarmState', 'Node'],
  ['commonAlarmState', 'Common'],
  ['subRegAlarmState', 'Subscr.'],
  ['radioAlarmState', 'Tetra'],
  ['voiceGwAlarmState', 'Voice GW'],
  ['pdGwAlarmState', 'PD GW'],
  ['apiGwAlarmState', 'App. GW'],
  ['termGwAlarmState', 'Term. GW'],
]);

export const NODE_ALERT_LEVELS = [NodeAlertLevel.Ignore, NodeAlertLevel.L1, NodeAlertLevel.L2, NodeAlertLevel.L3];

export const boxes: [
  [string, keyof Omit<NodeAlertSettings, '__typename'>][],
  [string, keyof Omit<NodeAlertSettings, '__typename'>][],
] = [
  [
    ['Node', 'alarmState'],
    ['Common', 'commonAlarmState'],
    ['Subscr.', 'subRegAlarmState'],
    ['Tetra', 'radioAlarmState'],
  ],
  [
    ['Voice GW', 'voiceGwAlarmState'],
    ['PD GW', 'pdGwAlarmState'],
    ['App. GW', 'apiGwAlarmState'],
    ['Term. GW', 'termGwAlarmState'],
  ],
];
