import camelCase from 'lodash/camelCase';
import toUpper from 'lodash/toUpper';
import logger from 'src/helpers/logger';
import React, { CSSProperties } from 'react';
import { Viewer } from 'src/graphql/generated';

export function timeout(ms: number) {
  return new Promise(resolve => {
    setTimeout(() => resolve(undefined), ms);
  });
}

export function rawMarkup(__html: string) {
  return {
    __html,
  };
}

export function getBase64FromFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function isEmpty(v: string | Array<unknown> | Object) {
  if (Array.isArray(v)) {
    return v.length === 0;
  }
  if (!v) {
    return true;
  }
  if (typeof v === 'object') {
    return Object.keys(v).length === 0;
  }
  return false;
}

export function formatPropForReact(css: any): CSSProperties {
  if (!css) {
    return {};
  }
  const s: CSSProperties = {};
  Object.keys(css).forEach(cssProp => {
    const field = camelCase(cssProp) as keyof CSSProperties;
    // @ts-ignore
    // eslint-disable-next-line security/detect-object-injection
    s[field] = css[cssProp];
  });
  return s;
}

// export function getRole(params: { viewer: Viewer; viewerRole: UserRoleRole }) {
//   const { viewer, viewerRole } = params;
//   let role: UserRoleRole | null = null;
//   const adminInCompanies: Company[] = [];
//
//   (viewer?.roles ?? []).forEach(r => {
//     if (!r.company.id) {
//       return;
//     }
//     if (r.role !== UserRoleRole.Admin) {
//       return;
//     }
//     adminInCompanies.push(r.company);
//     if (!role) {
//       role = viewerRole === UserRoleRole.Admin ? viewerRole : r.role || UserRoleRole.User;
//     }
//   });
//
//   return {
//     role: viewerRole === UserRoleRole.Admin ? viewerRole : role || UserRoleRole.User,
//     adminInCompanies,
//   };
// }

export const stopEventPropagation = (event?: Event | React.MouseEvent<Element, MouseEvent>): false => {
  logger('Stopped event propagation', { event });
  event?.preventDefault();
  event?.stopPropagation();

  return false;
};

export function pascalCase(str: string) {
  return camelCase(str).replace(/^(.)/, toUpper);
}
