import React, { useRef, FC, memo, useMemo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Guacamole from 'guacamole-common-js';
import { Modal, type ModalProps } from 'antd';
import Button from 'src/components/ui-kit/buttons';
import { API_HOST } from 'src/constants';
import { useNodeSettingsQuery } from 'src/graphql/generated';
import { logger } from '@fjedi/graphql-react-components';
import { Select, SelectOption } from 'src/components/ui-kit/select';

const Container = styled(Modal)`
  &.ant-modal > .ant-modal-content {
    border-radius: 0.5rem;
    overflow: hidden;

    & > .ant-modal-header {
      border: 0;
    }

    & > .ant-modal-footer {
      border: 0;

      & > .ant-btn {
        padding-right: 0;
      }

      & > .ant-btn.ant-btn-primary {
        display: none;
      }
    }
  }
`;

const ChooseResolutionAlert = styled.h3`
  margin: 5rem auto;
  text-align: center;
  font-weight: 600;
  color: #333;
`;

interface RemoteDesktopModalProps extends ModalProps {
  nodeNumber: string;
}

const RemoteDesktopWindow = styled.div`
  min-width: 800px;
  min-height: 600px;
  //background: #000;
  margin: 0 auto 0;
  border-radius: 0.5rem;
  //overflow: scroll;
  //padding: 1rem 1rem 1.5rem;
  color: #ffffff;

  canvas {
    z-index: 100 !important;
  }
`;

const rdpTunnel = new Guacamole.WebSocketTunnel(
  API_HOST === 'localhost' || API_HOST === '127.0.0.1' ? `ws://${API_HOST}:5052` : `wss://${API_HOST}/rdp`,
);
const rdpClient = new Guacamole.Client(rdpTunnel);

function mouseProxy(mouseState: Guacamole.Mouse.State) {
  logger('RDP.mouseProxy', { mouseState });
  rdpClient.sendMouseState(mouseState);
}
function onKeyUpProxy(keysym: number) {
  logger('RDP.onKeyUpProxy', { keysym });
  rdpClient.sendKeyEvent(0, keysym);
}
function onKeyDownProxy(keysym: number) {
  logger('RDP.onKeyDownProxy', { keysym });
  rdpClient.sendKeyEvent(1, keysym);
}

const SUPPORTED_RESOLUTIONS: { id: number; width: number; height: number }[] = [
  { id: 1, width: 800, height: 600 },
  { id: 2, width: 1024, height: 768 },
  { id: 3, width: 1280, height: 1024 },
  { id: 4, width: 1366, height: 768 },
  { id: 5, width: 1440, height: 900 },
  { id: 6, width: 1600, height: 900 },
  { id: 7, width: 1600, height: 1200 },
  { id: 8, width: 1680, height: 1050 },
  { id: 9, width: 1920, height: 1080 },
];

const RemoteDesktopModal: FC<RemoteDesktopModalProps> = ({ nodeNumber }) => {
  const rdpWindowRef = useRef<HTMLDivElement>(null);
  const [resolutionId, setResolution] = useState<number>();
  const { width, height } = useMemo(
    () => SUPPORTED_RESOLUTIONS.find(r => r.id === resolutionId) ?? SUPPORTED_RESOLUTIONS[0],
    [resolutionId],
  );
  const [visible, setModalVisibility] = useState(false);
  const onOpen = useCallback(() => {
    setModalVisibility(true);
  }, []);
  const onCancel = useCallback(() => {
    setModalVisibility(false);
  }, []);

  const { data } = useNodeSettingsQuery({
    variables: {
      nodeNumber: `${nodeNumber}`,
    },
    skip: !nodeNumber,
    fetchPolicy: 'cache-only',
  });
  const primaryHost = useMemo(() => data?.nodeSettings?.hosts?.find(h => h.isPrimary), [data]);

  useEffect(() => {
    if (!primaryHost || !rdpWindowRef?.current || !visible || !resolutionId) {
      return () => {};
    }
    rdpWindowRef.current.innerHTML = '';

    // We use this set-interval-based hack to be sure that we will have valid instance of
    // rdp-display to attach it to the DOM
    setTimeout(() => {
      rdpClient.connect(`token=${primaryHost.rdpToken}&width=${width}&height=${height}`);
      const root = rdpClient.getDisplay().getElement();

      const mouse = new Guacamole.Mouse(root);
      mouse.onmousedown = mouseProxy;
      mouse.onmouseup = mouseProxy;
      mouse.onmousemove = mouseProxy;

      const keyboard = new Guacamole.Keyboard(document);
      keyboard.onkeyup = onKeyUpProxy;
      keyboard.onkeydown = onKeyDownProxy;

      rdpWindowRef.current!.appendChild(root);

      root.setAttribute('tabindex', '0');
      root.focus();
    }, 500);
    //
    return () => {
      rdpClient.disconnect();
    };
  }, [resolutionId, visible, primaryHost, rdpWindowRef, width, height]);

  return (
    <>
      <Button size="small" onClick={onOpen}>
        RDP
      </Button>
      <Container
        open={visible}
        onCancel={onCancel}
        title={
          primaryHost ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ flexGrow: 1 }}>{`Удаленный рабочий стол [${primaryHost.ip}]`}</span>
              <label
                htmlFor="resolution"
                style={{
                  marginLeft: '1rem',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-end',
                }}>
                Разрешение экрана:
                <Select
                  id="resolution"
                  style={{ marginLeft: '0.5rem', minWidth: '150px' }}
                  value={resolutionId}
                  onChange={setResolution}>
                  {SUPPORTED_RESOLUTIONS.map(({ id, width: w, height: h }) => (
                    <SelectOption key={id} value={id}>{`${w}x${h}`}</SelectOption>
                  ))}
                </Select>
              </label>
            </div>
          ) : null
        }
        cancelButtonProps={{ type: 'link' }}
        cancelText="Закрыть"
        closable={false}
        width={width + 50}
        maskClosable
        keyboard
        centered>
        {!resolutionId && (
          <ChooseResolutionAlert>
            Для подключения к удаленному рабочему столу выберите разрешение экрана
          </ChooseResolutionAlert>
        )}
        {!!resolutionId && <RemoteDesktopWindow ref={rdpWindowRef} />}
      </Container>
    </>
  );
};

export default memo(RemoteDesktopModal) as typeof RemoteDesktopModal;
