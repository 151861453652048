import React from 'react';
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';

export const CheckboxGroup = styled(AntCheckbox.Group)``;

export const Checkbox = styled(AntCheckbox)`
  &.ant-checkbox-wrapper {
    display: inline-flex;
    align-items: center;

    .ant-checkbox {
      margin: 0.25rem 0 0 0;
      top: 0;

      &,
      .ant-checkbox-input,
      .ant-checkbox-inner {
        height: 1.5rem;
      }

      .ant-checkbox-inner {
        width: 1.5rem;

        &:after {
          top: 43%;
          width: 11px;
          height: 18px;
        }
      }
    }
    p {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export default Checkbox;
