import React, { useMemo, useState, useEffect, StrictMode } from 'react';
import { useTranslation } from 'react-i18next';
import { fallbackLang, supportedLangs, withSSR } from 'kit/lib/intl';
import { useViewerQuery } from 'src/graphql/generated';
//
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'src/components/ui-kit/global-styles';
import { theme } from 'config/theme';

// Routing via React Router
import {
  Routes,
  Route,
  Navigate,
  ViewerContext,
  ViewerRoleProvider,
  AuthModalProvider,
  ViewerType,
  ViewerRole,
} from '@fjedi/react-router-helpers';
import Spinner from 'src/components/ui-kit/spinner';
//
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';
import en from 'antd/lib/locale/en_US';

// Routes
import PrivateRoot from 'src/components/routes/private';
import Login from 'src/components/routes/auth/login';
import ForgotPassword from 'src/components/routes/auth/forgot-password';
import ResetPassword from 'src/components/routes/auth/reset-password';

const locales = { ru, en };

const Root = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n?.language || fallbackLang);
  const { data: viewerRes, loading } = useViewerQuery({
    errorPolicy: 'all',
  });
  useEffect(() => {
    i18n.changeLanguage(supportedLangs.ru).catch(console.error);

    i18n.on('languageChanged', l => {
      if (l.length > 2) {
        //
        i18n.changeLanguage(l.substring(0, 2)).catch(console.error);
      } else {
        setLang(l);
      }
    });
    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);
  //
  const viewer = viewerRes?.viewer;
  const viewerId = viewerRes?.viewer?.id;
  const ForgotPasswordPage = useMemo(() => (viewerId ? <Navigate replace to="/" /> : <ForgotPassword />), [viewerId]);
  const ResetPasswordPage = useMemo(() => (viewerId ? <Navigate replace to="/" /> : <ResetPassword />), [viewerId]);

  if (!viewerId && loading) {
    return (
      <Spinner
        style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        spinning
      />
    );
  }
  return (
    <ConfigProvider theme={theme} locale={locales[lang as keyof typeof locales]}>
      <ThemeProvider theme={theme}>
        <ViewerContext.Provider value={viewer as ViewerType}>
          <ViewerRoleProvider value={viewer?.role as ViewerRole}>
            <AuthModalProvider>
              <StrictMode>
                <>
                  <Routes>
                    <Route path="forgot-password" element={ForgotPasswordPage} />
                    <Route path="reset-password/:token" element={ResetPasswordPage} />
                    <Route path="*" element={viewerId ? <PrivateRoot /> : <Login />} />
                  </Routes>
                  <GlobalStyles />
                </>
              </StrictMode>
            </AuthModalProvider>
          </ViewerRoleProvider>
        </ViewerContext.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default withSSR()(Root);
