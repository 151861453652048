import React, { FC, memo } from 'react';

const PS421Page: FC = () => (
  <>
    <h1>PS421 Power Supply ver. 1.00</h1>
    <br />
    <h2>Functional Description</h2>
    <br />
    <div>PS421&nbsp;FUNCTIONAL&nbsp;DESCRIPTION</div>
    <br />
    <div>
      All&nbsp;test&nbsp;commands&nbsp;for&nbsp;the&nbsp;PS421&nbsp;Power&nbsp;Supply&nbsp;consist&nbsp;of&nbsp;2-digit&nbsp;commands&nbsp;
    </div>
    <div>
      in&nbsp;the&nbsp;range&nbsp;00&nbsp;to&nbsp;99&nbsp;with&nbsp;an&nbsp;optionally&nbsp;parameter.&nbsp;Commands&nbsp;without&nbsp;parameters&nbsp;
    </div>
    <div>are&nbsp;display-only&nbsp;commands&nbsp;and&nbsp;will&nbsp;not&nbsp;make&nbsp;any&nbsp;change.</div>
    <br />
    <div>
      If&nbsp;the&nbsp;first&nbsp;two&nbsp;characters&nbsp;are&nbsp;not&nbsp;representing&nbsp;a&nbsp;valid&nbsp;number&nbsp;in&nbsp;
    </div>
    <div>the&nbsp;00&nbsp;to&nbsp;99&nbsp;range,&nbsp;an&nbsp;error&nbsp;message&nbsp;will&nbsp;be&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;PS421&nbsp;command
    </div>
    <br />
    <div>
      If&nbsp;an&nbsp;unimplemented&nbsp;command&nbsp;is&nbsp;executed,&nbsp;the&nbsp;following&nbsp;response&nbsp;is&nbsp;
    </div>
    <div>displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unimplemented&nbsp;PS421&nbsp;command
    </div>
    <br />
    <div>PARAMETERS</div>
    <br />
    <div>Two&nbsp;types&nbsp;of&nbsp;parameters&nbsp;are&nbsp;used.</div>
    <br />
    <div>
      Generally&nbsp;+&nbsp;is&nbsp;used&nbsp;as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;on,&nbsp;enable&nbsp;or&nbsp;step&nbsp;up&nbsp;and&nbsp;-&nbsp;is&nbsp;used
    </div>
    <div>as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;off,&nbsp;disable&nbsp;or&nbsp;step&nbsp;down.</div>
    <br />
    <div>
      For&nbsp;more&nbsp;complex&nbsp;functions,&nbsp;a&nbsp;/&nbsp;is&nbsp;used&nbsp;as&nbsp;separator&nbsp;after&nbsp;the&nbsp;command
    </div>
    <div>
      number&nbsp;followed&nbsp;by&nbsp;the&nbsp;necessary&nbsp;number&nbsp;of&nbsp;characters&nbsp;for&nbsp;the&nbsp;actual
    </div>
    <div>function.</div>
    <br />
    <div>
      If&nbsp;the&nbsp;parameter&nbsp;is&nbsp;not&nbsp;valid&nbsp;for&nbsp;the&nbsp;actual&nbsp;command,&nbsp;the&nbsp;following&nbsp;response
    </div>
    <div>is&nbsp;displayed:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;parameter
    </div>
    <br />
    <div>SYSTEM&nbsp;AND&nbsp;FACTORY&nbsp;CONFIGURATIONS</div>
    <br />
    <div>
      The&nbsp;System&nbsp;and&nbsp;Factory&nbsp;Configurations&nbsp;are&nbsp;stored&nbsp;residently&nbsp;in&nbsp;the&nbsp;microcontrollers&nbsp;
    </div>
    <div>
      built-in&nbsp;Flash&nbsp;memory&nbsp;and&nbsp;read&nbsp;into&nbsp;RAM&nbsp;at&nbsp;power-up.&nbsp;The&nbsp;settings&nbsp;are&nbsp;changed&nbsp;with&nbsp;
    </div>
    <div>
      the&nbsp;7x&nbsp;and&nbsp;9x&nbsp;commands&nbsp;respectively&nbsp;and&nbsp;will&nbsp;becone&nbsp;effective&nbsp;immediately.&nbsp;The&nbsp;changes&nbsp;
    </div>
    <div>
      shall&nbsp;be&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;and&nbsp;90/SAVE&nbsp;commands&nbsp;to&nbsp;be&nbsp;effective&nbsp;after&nbsp;a&nbsp;re-boot.
    </div>
    <br />
    <div>
      To&nbsp;avoid&nbsp;accidentially&nbsp;change&nbsp;of&nbsp;Factory&nbsp;settings,&nbsp;change&nbsp;access&nbsp;is&nbsp;only&nbsp;possible
    </div>
    <div>after&nbsp;the&nbsp;Factory&nbsp;Unlock&nbsp;command&nbsp;90/UNLOCK</div>
    <div>&nbsp;</div>
    <div>
      Warning:&nbsp;The&nbsp;Flash&nbsp;is&nbsp;only&nbsp;guaranteed&nbsp;to&nbsp;be&nbsp;erasured&nbsp;and&nbsp;reprogrammed&nbsp;10.000&nbsp;times.
    </div>
    <div>Do&nbsp;not&nbsp;use&nbsp;the&nbsp;O&amp;M&nbsp;Continuous&nbsp;or&nbsp;Repeat&nbsp;commands&nbsp;here.</div>
    <br />
    <div>*&nbsp;&nbsp;Functional&nbsp;description</div>
    <br />
    <br />
    <h2>General commands</h2>
    <br />
    <div>00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;PS421&nbsp;ver.&nbsp;1.00</div>
    <br />
    <div>Description:</div>
    <div>Displayes&nbsp;the&nbsp;software&nbsp;version&nbsp;number&nbsp;of&nbsp;the&nbsp;Flash.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00&nbsp;&nbsp;Display&nbsp;software&nbsp;version&nbsp;number</strong>
      </div>
      <div>00</div>
      <div />
      <div />
    </div>
    <div>02&nbsp;&nbsp;RESET</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02+&nbsp;&nbsp;Execute&nbsp;hardware&nbsp;reset</strong>
      </div>
      <div>02+</div>
      <div />
    </div>
    <div>03&nbsp;ALARMS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03&nbsp;&nbsp;Display&nbsp;alarm&nbsp;flags</strong>
      </div>
      <div>03</div>
      <div />
      <div />
    </div>
    <h2>PS commands</h2>
    <br />
    <div>10&nbsp;&nbsp;DISPLAY&nbsp;PS&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10&nbsp;Display&nbsp;PS&nbsp;Status</strong>
      </div>
      <div>10</div>
      <div />
      <div />
    </div>
    <div>11&nbsp;TR1/TR3&nbsp;POWER-OFF</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11&nbsp;Display&nbsp;TR1/TR3&nbsp;Power-Off&nbsp;Selection</strong>
      </div>
      <div>For&nbsp;SB421&nbsp;#1:&nbsp;TR1</div>
      <div>For&nbsp;SB421&nbsp;#2:&nbsp;TR3</div>
      <div>11</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11+&nbsp;Turn&nbsp;On&nbsp;TR1/TR3&nbsp;Power</strong>
      </div>
      <div>For&nbsp;SB421&nbsp;#1:&nbsp;TR1</div>
      <div>For&nbsp;SB421&nbsp;#2:&nbsp;TR3</div>
      <div>11+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11-&nbsp;Turn&nbsp;Off&nbsp;TR1/TR3&nbsp;Power&nbsp;</strong>
      </div>
      <div>For&nbsp;SB421&nbsp;#1:&nbsp;TR1</div>
      <div>For&nbsp;SB421&nbsp;#2:&nbsp;TR3</div>
      <div>11-</div>
      <div />
      <div />
    </div>
    <div>12&nbsp;TR2/TR4&nbsp;POWER-OFF</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12&nbsp;Display&nbsp;TR2/TR4&nbsp;Power-Off&nbsp;Selection</strong>
      </div>
      <div>For&nbsp;SB421&nbsp;#1:&nbsp;TR2</div>
      <div>For&nbsp;SB421&nbsp;#2:&nbsp;TR4</div>
      <div>12</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12+&nbsp;Turn&nbsp;On&nbsp;TR2/TR4&nbsp;Power</strong>
      </div>
      <div>For&nbsp;SB421&nbsp;#1:&nbsp;TR2</div>
      <div>For&nbsp;SB421&nbsp;#2:&nbsp;TR4</div>
      <div>12+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12-&nbsp;Turn&nbsp;Off&nbsp;TR2/TR4&nbsp;Power&nbsp;</strong>
      </div>
      <div>For&nbsp;SB421&nbsp;#1:&nbsp;TR2</div>
      <div>For&nbsp;SB421&nbsp;#2:&nbsp;TR4</div>
      <div>12-</div>
      <div />
      <div />
    </div>
    <h2>Rectifier commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20&nbsp;Display&nbsp;Rectifier&nbsp;status</strong>
      </div>
      <div>20</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21&nbsp;Display&nbsp;Rectifier&nbsp;Off&nbsp;selection</strong>
      </div>
      <div>21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21-&nbsp;Turn&nbsp;Off&nbsp;Rectifier</strong>
      </div>
      <div>21-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21+&nbsp;Turn&nbsp;On&nbsp;Rectifier</strong>
      </div>
      <div>21+</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22&nbsp;Display&nbsp;Rectifier&nbsp;Manual&nbsp;Voltage&nbsp;Control&nbsp;selection</strong>
      </div>
      <div>22</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22+&nbsp;Select&nbsp;Rectifer&nbsp;Manual&nbsp;Voltage&nbsp;Control</strong>
      </div>
      <div>22+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22-&nbsp;Deselect&nbsp;Rectifier&nbsp;Manual&nbsp;Voltage&nbsp;Control</strong>
      </div>
      <div>22-</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23&nbsp;Display&nbsp;Rectifier&nbsp;Output&nbsp;Voltage&nbsp;selection</strong>
      </div>
      <div>23</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23/nn.n&nbsp;Change&nbsp;Rectifier&nbsp;Output&nbsp;Voltage</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Rectifier&nbsp;Output&nbsp;Voltage&nbsp;(48.0..59.0V)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>23/__._</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>24&nbsp;Display&nbsp;Battery&nbsp;Super&nbsp;Charge&nbsp;Status</strong>
      </div>
      <div>24</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>24/n.n/nnn&nbsp;Start&nbsp;Battery&nbsp;Super&nbsp;Charge</strong>
      </div>
      <div>Command&nbsp;used&nbsp;to&nbsp;Charge&nbsp;Align&nbsp;Cells&nbsp;in&nbsp;Battery</div>
      <div>
        Is&nbsp;recommended&nbsp;to&nbsp;do&nbsp;on&nbsp;a&nbsp;new&nbsp;Battery&nbsp;and&nbsp;once&nbsp;every&nbsp;year
      </div>
      <div>
        Parameters&nbsp;shall&nbsp;be&nbsp;according&nbsp;to&nbsp;recommandation&nbsp;for&nbsp;Battery&nbsp;type
      </div>
      <div>
        Do&nbsp;not&nbsp;use&nbsp;on&nbsp;small&nbsp;Batteries,&nbsp;if&nbsp;they&nbsp;are&nbsp;not&nbsp;able&nbsp;to&nbsp;handle&nbsp;continuous
      </div>
      <div>charge&nbsp;with&nbsp;the&nbsp;max.&nbsp;output&nbsp;current&nbsp;of&nbsp;PS421.</div>
      <div>
        &nbsp;&nbsp;&nbsp;┌&nbsp;Charge&nbsp;Voltage&nbsp;Increase&nbsp;(0.1V&nbsp;to&nbsp;4.8V)(2.4V&nbsp;typ.)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Charge&nbsp;duration&nbsp;(001&nbsp;to&nbsp;999&nbsp;minutes)&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴──&nbsp;┴──</div>
      <div>24/_._/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>24-&nbsp;Stop&nbsp;Battery&nbsp;Super&nbsp;Charge</strong>
      </div>
      <div>24-</div>
      <div />
      <div />
    </div>
    <h2>Test commands</h2>
    <br />
    <div>60&nbsp;&nbsp;ADC&nbsp;VOLTMETER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60&nbsp;&nbsp;Display&nbsp;ADC&nbsp;Result</strong>
      </div>
      <div>60</div>
      <div />
      <div />
    </div>
    <div>67&nbsp;&nbsp;INPUT&nbsp;PORT</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>67&nbsp;&nbsp;Display&nbsp;Input&nbsp;Port</strong>
      </div>
      <div>Spare&nbsp;Input&nbsp;Port&nbsp;from&nbsp;Control&nbsp;Connector</div>
      <div />
      <div>67</div>
      <div />
      <div />
    </div>
    <div>68&nbsp;&nbsp;PROGRAM&nbsp;DOWNLOAD</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>68</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68/BOOT&nbsp;&nbsp;Switch&nbsp;to&nbsp;Boot&nbsp;Mode</strong>
      </div>
      <div />
      <div>
        This&nbsp;Command&nbsp;sets&nbsp;the&nbsp;Program&nbsp;Update&nbsp;Request&nbsp;Flag&nbsp;and&nbsp;afterwards&nbsp;makes&nbsp;a
      </div>
      <div>
        hardware&nbsp;reset&nbsp;of&nbsp;the&nbsp;Microcontroller.&nbsp;Afterwards&nbsp;the&nbsp;Microcontroller&nbsp;can
      </div>
      <div>accept&nbsp;new&nbsp;download&nbsp;of&nbsp;new&nbsp;Program&nbsp;Code.</div>
      <div />
      <div>
        Download&nbsp;of&nbsp;new&nbsp;Program&nbsp;Code&nbsp;can&nbsp;either&nbsp;be&nbsp;done&nbsp;automatically&nbsp;from&nbsp;BSC421
      </div>
      <div>
        or&nbsp;by&nbsp;executing&nbsp;the&nbsp;HEX&nbsp;file&nbsp;in&nbsp;the&nbsp;Editor&nbsp;of&nbsp;the&nbsp;TetraOM&nbsp;Program.
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;BOOT</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>68/____</div>
      <div />
      <div />
    </div>
    <div>69&nbsp;&nbsp;READ&nbsp;MEMORY&nbsp;LOCATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69&nbsp;&nbsp;Display&nbsp;last&nbsp;selected&nbsp;memory&nbsp;address</strong>
      </div>
      <div>69</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhh&nbsp;&nbsp;Display&nbsp;memory&nbsp;address&nbsp;hhhh</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Memory&nbsp;address&nbsp;in&nbsp;HEX</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>69/____</div>
      <div />
      <div />
    </div>
    <h2>System Configuration</h2>
    <br />
    <div>70&nbsp;SYSTEM&nbsp;CONFIGURATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70&nbsp;&nbsp;Display&nbsp;System&nbsp;Configuration</strong>
      </div>
      <div>70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70/SAVE&nbsp;&nbsp;Save&nbsp;System&nbsp;Configuration&nbsp;in&nbsp;Flash</strong>
      </div>
      <div>70/SAVE</div>
      <div />
      <div />
    </div>
    <div>71&nbsp;&nbsp;AC&nbsp;INPUT</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71&nbsp;&nbsp;Display&nbsp;AC&nbsp;input&nbsp;selection</strong>
      </div>
      <div>71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71s&nbsp;&nbsp;Change&nbsp;AC&nbsp;input&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;┌&nbsp;+:&nbsp;Select&nbsp;AC&nbsp;input</div>
      <div>&nbsp;&nbsp;│&nbsp;-:&nbsp;Deselect&nbsp;AC&nbsp;input&nbsp;</div>
      <div>&nbsp;&nbsp;┴</div>
      <div>71_</div>
      <div />
      <div />
    </div>
    <div>72&nbsp;&nbsp;BATTERY&nbsp;CHARGING</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72&nbsp;&nbsp;Display&nbsp;Battery&nbsp;charging&nbsp;selection</strong>
      </div>
      <div>72</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72s&nbsp;&nbsp;Change&nbsp;Battery&nbsp;charging&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;┌&nbsp;+:&nbsp;Select&nbsp;Battery&nbsp;charging</div>
      <div>&nbsp;&nbsp;│&nbsp;-:&nbsp;Deselect&nbsp;Battery&nbsp;charging&nbsp;</div>
      <div>&nbsp;&nbsp;┴</div>
      <div>72_</div>
      <div />
      <div />
    </div>
    <div>73&nbsp;&nbsp;BATTERY&nbsp;CHARGING&nbsp;VOLTAGE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>73&nbsp;&nbsp;Display&nbsp;Battery&nbsp;charging&nbsp;voltage&nbsp;selection</strong>
      </div>
      <div>73</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>73/nn.n&nbsp;&nbsp;Select&nbsp;Battery&nbsp;charging&nbsp;voltage</strong>
      </div>
      <div>Battery&nbsp;charging&nbsp;voltage&nbsp;at&nbsp;25&nbsp;Celsius</div>
      <div>Shall&nbsp;be&nbsp;selected&nbsp;according&nbsp;to&nbsp;the&nbsp;battery&nbsp;recommendation</div>
      <div>Typical&nbsp;charging&nbsp;voltage&nbsp;is&nbsp;54.OV&nbsp;(2.25V&nbsp;pr.&nbsp;cell)</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Charging&nbsp;voltage&nbsp;at&nbsp;25&nbsp;Celsius&nbsp;(52.8..55.2)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>73/__._</div>
      <div />
      <div />
    </div>
    <div>74&nbsp;&nbsp;BATTERY&nbsp;CHARGING&nbsp;TEMPERATURE&nbsp;COMPENSATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>74&nbsp;&nbsp;Display&nbsp;Battery&nbsp;charging&nbsp;temperature&nbsp;compensation</strong>
      </div>
      <div>74</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>74/-nnn&nbsp;&nbsp;Select&nbsp;Battery&nbsp;charging&nbsp;temperature&nbsp;compensation</strong>
      </div>
      <div>Shall&nbsp;be&nbsp;selected&nbsp;according&nbsp;to&nbsp;the&nbsp;battery&nbsp;recommendation</div>
      <div>
        Typical&nbsp;temperature&nbsp;compensation&nbsp;is&nbsp;-72&nbsp;mV/C&nbsp;(-3.0mV/C&nbsp;per&nbsp;cell)
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Charging&nbsp;temperature&nbsp;compensation&nbsp;in&nbsp;mV&nbsp;(-048..-120)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>74/-___</div>
      <div />
      <div />
    </div>
    <h2>Factory Configuration</h2>
    <br />
    <div>90&nbsp;&nbsp;FACTORY&nbsp;CONFIGURATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90&nbsp;&nbsp;Display&nbsp;Factory&nbsp;Configuration</strong>
      </div>
      <div>90</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/D&nbsp;&nbsp;Display&nbsp;factory&nbsp;unlock&nbsp;selection</strong>
      </div>
      <div>90/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/UNLOCK&nbsp;&nbsp;Select&nbsp;factory&nbsp;unlock</strong>
      </div>
      <div>90/UNLOCK</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90-&nbsp;&nbsp;Deselect&nbsp;factory&nbsp;unlock</strong>
      </div>
      <div>90-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/SAVE&nbsp;&nbsp;Save&nbsp;Factory&nbsp;Configuration&nbsp;in&nbsp;Flash</strong>
      </div>
      <div>90/SAVE</div>
      <div />
      <div />
    </div>
    <div>91&nbsp;&nbsp;RECTIFIER&nbsp;SELECTION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91&nbsp;&nbsp;Display&nbsp;Rectifier&nbsp;selection</strong>
      </div>
      <div>91</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91+&nbsp;&nbsp;Select&nbsp;Rectifier&nbsp;included</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>91+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91-&nbsp;&nbsp;Select&nbsp;No&nbsp;Rectifier&nbsp;included</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>91-</div>
      <div />
      <div />
    </div>
    <div>95&nbsp;&nbsp;TYPE&nbsp;NUMBER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>95&nbsp;&nbsp;Display&nbsp;Item&nbsp;Number</strong>
      </div>
      <div>95</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>95/ccc..c&nbsp;&nbsp;Change&nbsp;Item&nbsp;Number</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Item&nbsp;Number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────────</div>
      <div>95/____________</div>
      <div />
      <div />
    </div>
    <div>96&nbsp;&nbsp;TYPE&nbsp;NUMBER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96&nbsp;&nbsp;Display&nbsp;Type&nbsp;Number</strong>
      </div>
      <div>96</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96/ccc..c&nbsp;&nbsp;Change&nbsp;Type&nbsp;Number</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Type&nbsp;Number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────────</div>
      <div>96/____________</div>
      <div />
      <div />
    </div>
    <div>97&nbsp;&nbsp;SERIAL&nbsp;NUMBER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97&nbsp;&nbsp;Display&nbsp;Serial&nbsp;Number</strong>
      </div>
      <div>97</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97/nnnnnnnn&nbsp;&nbsp;Change&nbsp;Serial&nbsp;Number</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;Number&nbsp;(00000000..99999999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>97/________</div>
      <div />
      <div />
    </div>
    <div>98&nbsp;&nbsp;VERSION&nbsp;NUMBER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98&nbsp;&nbsp;Display&nbsp;Version&nbsp;Number</strong>
      </div>
      <div>98</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/cc..c&nbsp;&nbsp;Change&nbsp;Version&nbsp;Number</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Version&nbsp;Number</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>98/______</div>
      <div />
      <div />
    </div>
    <div>99&nbsp;&nbsp;REVISION&nbsp;NUMBER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99&nbsp;&nbsp;Display&nbsp;Revision&nbsp;Number</strong>
      </div>
      <div>99</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99/cc..c&nbsp;&nbsp;Change&nbsp;Revision&nbsp;Number</strong>
      </div>
      <div>Factory&nbsp;Unlock&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Revision&nbsp;Number</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>99/______</div>
      <div />
      <div />
    </div>
    <br />
  </>
);

export default memo(PS421Page) as typeof PS421Page;
