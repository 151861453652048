import { Navigate, Route, Routes } from '@fjedi/react-router-helpers';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { setHTMLTitle } from 'src/components/service-routes';
import { ContentCard } from 'src/components/ui-kit/card/main-content-card';
import { colorTheme } from 'src/components/ui-kit/theme';
import OrganizationsTab from './tabs/organizations';
import ProfilesTab from './tabs/profiles';
import SubscribersTab from './tabs/subscribers';

const Container = styled(ContentCard)`
  .ant-table .ant-table-row {
    cursor: pointer;

    &:hover {
      background-color: ${colorTheme.secondary};
    }
  }
`;

const SubscribersPage = memo(() => {
  const { t } = useTranslation();
  const tabList = useMemo(
    () => [
      {
        key: 'organizations',
        tab: t('Организации'),
      },
      {
        key: 'profiles',
        tab: t('Profile'),
      },
      {
        key: 'ssi',
        tab: t('SSI'),
      },
    ],
    [t],
  );

  return (
    <Container tabList={tabList} tabURLNavigation>
      {setHTMLTitle(t('Subscribers List'))}
      <Routes>
        <Route path="organizations/:organizationId*" element={<OrganizationsTab />} />
        <Route path="organizations" element={<OrganizationsTab />} />
        <Route path="profiles/:profileId*" element={<ProfilesTab />} />
        <Route path="profiles" element={<ProfilesTab />} />
        <Route path="ssi/:subscriberId*" element={<SubscribersTab />} />
        <Route path="ssi" element={<SubscribersTab />} />
        <Route path="*" element={<Navigate replace to="organizations" />} />
      </Routes>
    </Container>
  );
});

SubscribersPage.displayName = 'SubscribersPage';

export default SubscribersPage;
