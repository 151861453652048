import React, { FC, memo } from 'react';

const TR412Page: FC = () => (
  <>
    <h1>TR412 Transceiver ver. 7.73</h1>
    <br />
    <br />
    <h2>Functional Description</h2>
    <br />
    <div>FUNCTIONAL&nbsp;DESCRIPTION</div>
    <br />
    <div>
      All&nbsp;test&nbsp;commands&nbsp;for&nbsp;the&nbsp;TR412&nbsp;Base&nbsp;Station&nbsp;Transceiver&nbsp;consist&nbsp;of&nbsp;a
    </div>
    <div>
      2-digit&nbsp;command&nbsp;in&nbsp;the&nbsp;range&nbsp;00&nbsp;to&nbsp;99&nbsp;with&nbsp;an&nbsp;optionally&nbsp;parameter.&nbsp;Commands
    </div>
    <div>
      without&nbsp;parameters&nbsp;are&nbsp;display-only&nbsp;commands&nbsp;and&nbsp;will&nbsp;not&nbsp;make&nbsp;any&nbsp;change
    </div>
    <div>
      in&nbsp;the&nbsp;TR.&nbsp;Therefore,&nbsp;it&nbsp;is&nbsp;possible&nbsp;to&nbsp;"page&nbsp;through"&nbsp;the&nbsp;commands&nbsp;to&nbsp;find
    </div>
    <div>the&nbsp;right&nbsp;one&nbsp;without&nbsp;any&nbsp;risk&nbsp;to&nbsp;change&nbsp;vital&nbsp;parameters.</div>
    <br />
    <div>
      The&nbsp;commands&nbsp;are&nbsp;divided&nbsp;into&nbsp;groups,&nbsp;covering&nbsp;the&nbsp;different&nbsp;functions&nbsp;in
    </div>
    <div>
      the&nbsp;TR.&nbsp;For&nbsp;example,&nbsp;all&nbsp;the&nbsp;TX&nbsp;commands&nbsp;are&nbsp;in&nbsp;the&nbsp;10&nbsp;to&nbsp;19&nbsp;group.
    </div>
    <br />
    <div>
      If&nbsp;the&nbsp;first&nbsp;two&nbsp;characters&nbsp;are&nbsp;not&nbsp;representing&nbsp;a&nbsp;valid&nbsp;number&nbsp;in&nbsp;the&nbsp;00&nbsp;to
    </div>
    <div>99&nbsp;range,&nbsp;an&nbsp;error&nbsp;message&nbsp;will&nbsp;be&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;TR412&nbsp;command
    </div>
    <br />
    <div>
      This&nbsp;is&nbsp;however&nbsp;not&nbsp;the&nbsp;case&nbsp;if&nbsp;the&nbsp;first&nbsp;character&nbsp;is&nbsp;a&nbsp;+&nbsp;,&nbsp;which&nbsp;are&nbsp;reser-
    </div>
    <div>
      ved&nbsp;for&nbsp;commands&nbsp;to&nbsp;the&nbsp;test&nbsp;box.&nbsp;In&nbsp;this&nbsp;case&nbsp;no&nbsp;response&nbsp;will&nbsp;be&nbsp;sent.
    </div>
    <br />
    <div>
      If&nbsp;an&nbsp;unimplemented&nbsp;TR&nbsp;command&nbsp;is&nbsp;called,&nbsp;the&nbsp;following&nbsp;response&nbsp;is
    </div>
    <div>displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unimplemented&nbsp;TR412&nbsp;command
    </div>
    <br />
    <div>LOCAL&nbsp;BLOCKING&nbsp;MODE</div>
    <br />
    <div>
      After&nbsp;power-on,&nbsp;the&nbsp;TR&nbsp;always&nbsp;starts&nbsp;in&nbsp;normal&nbsp;operating&nbsp;mode.&nbsp;If&nbsp;a&nbsp;given
    </div>
    <div>
      command&nbsp;requires&nbsp;local&nbsp;blocking,&nbsp;it&nbsp;is&nbsp;shown&nbsp;in&nbsp;the&nbsp;help&nbsp;for&nbsp;the&nbsp;command.
    </div>
    <div>
      Commands&nbsp;that&nbsp;require&nbsp;local&nbsp;blocking&nbsp;are&nbsp;not&nbsp;available&nbsp;during&nbsp;normal
    </div>
    <div>
      operation&nbsp;for&nbsp;operator&nbsp;interference.&nbsp;If&nbsp;a&nbsp;protected&nbsp;command&nbsp;is&nbsp;called,
    </div>
    <div>the&nbsp;following&nbsp;response&nbsp;is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Local&nbsp;blocking&nbsp;necessary
    </div>
    <br />
    <div>
      Only&nbsp;commands&nbsp;with&nbsp;a&nbsp;parameter&nbsp;are&nbsp;will&nbsp;be&nbsp;protected.&nbsp;After&nbsp;activation&nbsp;of
    </div>
    <div>
      local&nbsp;blocking&nbsp;(01+),&nbsp;all&nbsp;commands&nbsp;except&nbsp;factory&nbsp;configuration&nbsp;commands&nbsp;are
    </div>
    <div>available.</div>
    <br />
    <br />
    <div>PARAMETERS</div>
    <br />
    <div>Two&nbsp;types&nbsp;of&nbsp;parameters&nbsp;are&nbsp;used.</div>
    <br />
    <div>
      Generally&nbsp;+&nbsp;is&nbsp;used&nbsp;as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;on,&nbsp;enable&nbsp;or&nbsp;step&nbsp;up&nbsp;and&nbsp;-&nbsp;is&nbsp;used
    </div>
    <div>as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;off,&nbsp;disable&nbsp;or&nbsp;step&nbsp;down.</div>
    <br />
    <div>
      For&nbsp;more&nbsp;complex&nbsp;functions,&nbsp;a&nbsp;/&nbsp;is&nbsp;used&nbsp;as&nbsp;separator&nbsp;after&nbsp;the&nbsp;command&nbsp;no.
    </div>
    <div>
      followed&nbsp;by&nbsp;the&nbsp;necessary&nbsp;no.&nbsp;of&nbsp;characters&nbsp;for&nbsp;the&nbsp;actual&nbsp;function.
    </div>
    <br />
    <div>
      If&nbsp;the&nbsp;parameter&nbsp;is&nbsp;not&nbsp;valid&nbsp;for&nbsp;the&nbsp;actual&nbsp;command,&nbsp;the&nbsp;following&nbsp;response
    </div>
    <div>is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;TR412&nbsp;parameter
    </div>
    <br />
    <div>CONFIGURATION</div>
    <br />
    <div>
      Many&nbsp;calibration&nbsp;and&nbsp;system&nbsp;parameters&nbsp;are&nbsp;stored&nbsp;residently&nbsp;in&nbsp;a&nbsp;sector
    </div>
    <div>
      of&nbsp;the&nbsp;Flash&nbsp;chip.&nbsp;At&nbsp;power-up&nbsp;and&nbsp;reset&nbsp;all&nbsp;settings&nbsp;are&nbsp;copied&nbsp;into&nbsp;the
    </div>
    <div>
      workspace&nbsp;RAM,&nbsp;from&nbsp;where&nbsp;the&nbsp;different&nbsp;software&nbsp;tasks&nbsp;takes&nbsp;the&nbsp;settings.
    </div>
    <br />
    <div>
      Any&nbsp;change&nbsp;in&nbsp;the&nbsp;configuration&nbsp;is&nbsp;only&nbsp;made&nbsp;in&nbsp;the&nbsp;workspace&nbsp;RAM.&nbsp;To&nbsp;store
    </div>
    <div>
      the&nbsp;new&nbsp;configuration&nbsp;residently,&nbsp;a&nbsp;save&nbsp;command&nbsp;shall&nbsp;be&nbsp;made.
    </div>
    <br />
    <div>The&nbsp;configuration&nbsp;is&nbsp;divided&nbsp;into&nbsp;two&nbsp;separate&nbsp;entities:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;System&nbsp;configuration
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;Factory&nbsp;configuration
    </div>
    <br />
    <div>
      System&nbsp;configuration&nbsp;parameters&nbsp;can&nbsp;be&nbsp;changed&nbsp;with&nbsp;the&nbsp;7x&nbsp;commands.&nbsp;To&nbsp;save
    </div>
    <div>
      the&nbsp;system&nbsp;configuration&nbsp;resistently,&nbsp;the&nbsp;command&nbsp;"70/SAVE"&nbsp;shall&nbsp;be
    </div>
    <div>entered.</div>
    <br />
    <br />
    <div>
      Factory&nbsp;configuration&nbsp;parameters&nbsp;can&nbsp;be&nbsp;changed&nbsp;with&nbsp;the&nbsp;9x&nbsp;commands.&nbsp;To
    </div>
    <div>
      protect&nbsp;the&nbsp;operator&nbsp;from&nbsp;accidentially&nbsp;changing&nbsp;the&nbsp;factory&nbsp;configuration
    </div>
    <div>
      parameters,&nbsp;an&nbsp;access&nbsp;lock&nbsp;is&nbsp;implemented,&nbsp;giving&nbsp;the&nbsp;following&nbsp;message:
    </div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Factory&nbsp;configuration&nbsp;unlocking&nbsp;necessary
    </div>
    <br />
    <div>
      To&nbsp;get&nbsp;access&nbsp;to&nbsp;factory&nbsp;configuration&nbsp;changes,&nbsp;it&nbsp;is&nbsp;necessary&nbsp;to&nbsp;enter
    </div>
    <div>
      the&nbsp;command&nbsp;"90/FACTORYUNLOCK".&nbsp;Unlocking&nbsp;can&nbsp;be&nbsp;turned&nbsp;off&nbsp;again&nbsp;with&nbsp;the
    </div>
    <div>"90-"&nbsp;command.</div>
    <br />
    <div>
      To&nbsp;save&nbsp;the&nbsp;factory&nbsp;configuration,&nbsp;the&nbsp;command&nbsp;"90/SAVE"&nbsp;shall&nbsp;be&nbsp;entered.
    </div>
    <br />
    <div>
      The&nbsp;resident&nbsp;configuration&nbsp;is&nbsp;restored&nbsp;into&nbsp;the&nbsp;workspace&nbsp;RAM&nbsp;at&nbsp;software
    </div>
    <div>
      reset&nbsp;(02+)&nbsp;or&nbsp;local&nbsp;deblocking&nbsp;(01-)&nbsp;if&nbsp;re-booting&nbsp;take&nbsp;place.
    </div>
    <br />
    <div>*&nbsp;&nbsp;Functional&nbsp;description</div>
    <br />
    <div>LED&nbsp;description</div>
    <br />
    <div>
      The&nbsp;TR412&nbsp;unit&nbsp;has&nbsp;14&nbsp;LEDS&nbsp;on&nbsp;its&nbsp;front&nbsp;plate&nbsp;with&nbsp;the&nbsp;following
    </div>
    <div>
      arrangenent&nbsp;(note,&nbsp;that&nbsp;there&nbsp;can&nbsp;be&nbsp;differences&nbsp;on&nbsp;the&nbsp;fromt&nbsp;plate&nbsp;
    </div>
    <div>print):</div>
    <br />
    <div>1+2..:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PWR&nbsp;&nbsp;Y&nbsp;G&nbsp;&nbsp;TX</div>
    <div>3+4..:&nbsp;&nbsp;&nbsp;TETRA&nbsp;&nbsp;G&nbsp;G&nbsp;&nbsp;MPT</div>
    <div>5+6..:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LB&nbsp;&nbsp;R&nbsp;R&nbsp;&nbsp;TX</div>
    <div>7+8..:&nbsp;&nbsp;&nbsp;&nbsp;RFTL&nbsp;&nbsp;R&nbsp;R&nbsp;&nbsp;RX</div>
    <div>9+10.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PCM&nbsp;&nbsp;R&nbsp;R&nbsp;&nbsp;DSP</div>
    <div>11+12:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HW&nbsp;&nbsp;R&nbsp;R&nbsp;&nbsp;ANT</div>
    <div>13+14:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;H/E&nbsp;&nbsp;R&nbsp;R&nbsp;&nbsp;RM</div>
    <br />
    <div>The&nbsp;LEDs&nbsp;have&nbsp;the&nbsp;following&nbsp;meaning:</div>
    <div>1.&nbsp;PWR&nbsp;(yellow)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Normal&nbsp;operation</div>
    <div>&nbsp;&nbsp;&nbsp;Flashing:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waiting&nbsp;for&nbsp;configuration&nbsp;from&nbsp;BSC</div>
    <div>2.&nbsp;TX&nbsp;(green)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;keyed</div>
    <div>3.&nbsp;TETRA&nbsp;(green)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TETRA&nbsp;mode&nbsp;activated</div>
    <div>4.&nbsp;NMT/MPT&nbsp;(green)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analog&nbsp;mode&nbsp;mode&nbsp;activated</div>
    <div>5.&nbsp;LB&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Local&nbsp;Blocking&nbsp;activated</div>
    <div>&nbsp;&nbsp;&nbsp;Flashing:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Local&nbsp;Blocking&nbsp;requested</div>
    <div>6.&nbsp;TX&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;PLL&nbsp;unlocked&nbsp;</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VSWR&nbsp;protection&nbsp;circuit&nbsp;trigged</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;temperature&nbsp;protection&nbsp;is&nbsp;active&nbsp;(on&nbsp;at&nbsp;+85C,&nbsp;off&nbsp;at&nbsp;+83C)&nbsp;
    </div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+26V&nbsp;supply&nbsp;outside&nbsp;+/-2V&nbsp;tolerance</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;voltage&nbsp;reference&nbsp;faulty</div>
    <div>7.&nbsp;RFTL&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RF&nbsp;Test&nbsp;Loop&nbsp;output&nbsp;activated</div>
    <div>&nbsp;&nbsp;&nbsp;Flashing:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last&nbsp;selftest&nbsp;failed</div>
    <div>8.&nbsp;RX&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LO1&nbsp;or&nbsp;LO2&nbsp;PLL&nbsp;unlocked</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LO1&nbsp;level&nbsp;out&nbsp;of&nbsp;range</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LO2&nbsp;level&nbsp;out&nbsp;of&nbsp;range</div>
    <div>9.&nbsp;PCM&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;On:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12.8&nbsp;MHz&nbsp;PLL&nbsp;unlocked&nbsp;(2.048&nbsp;MHz&nbsp;missing&nbsp;from&nbsp;backplane)
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;36.864&nbsp;MHz&nbsp;PLL&nbsp;unlocked&nbsp;(8&nbsp;kHz&nbsp;missing&nbsp;from&nbsp;backplane)
    </div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1&nbsp;sec.&nbsp;missing&nbsp;(from&nbsp;backplane)</div>
    <div>&nbsp;&nbsp;&nbsp;Flashing:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Time&nbsp;message&nbsp;from&nbsp;BSC&nbsp;missing</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;not&nbsp;time&nbsp;synchronised</div>
    <div>10.&nbsp;DSP&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DSP&nbsp;watchdog&nbsp;alarm</div>
    <div>11.&nbsp;HW&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;Currently&nbsp;not&nbsp;used</div>
    <div>12.&nbsp;ANT&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;Antenna&nbsp;L2&nbsp;(blocking)&nbsp;</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;Flashing:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;Antenna&nbsp;L1&nbsp;(warning)&nbsp;</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TX&nbsp;combiner&nbsp;TP&nbsp;alarm</div>
    <div>13.&nbsp;H/E&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;On:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any&nbsp;of&nbsp;the&nbsp;external&nbsp;alarm&nbsp;inputs&nbsp;activated
    </div>
    <div>14.&nbsp;RM&nbsp;(red)</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;On:</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Both&nbsp;RX&nbsp;signal&nbsp;paths&nbsp;faulty</div>
    <div>&nbsp;&nbsp;&nbsp;&nbsp;Flashing:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One&nbsp;of&nbsp;the&nbsp;two&nbsp;RX&nbsp;signal&nbsp;paths&nbsp;faulty
    </div>
    <br />
    <div>
      Individual&nbsp;alarm&nbsp;flags&nbsp;can&nbsp;be&nbsp;displayed&nbsp;with&nbsp;the&nbsp;03&nbsp;commands.
    </div>
    <br />
    <div>*&nbsp;&nbsp;LED&nbsp;description</div>
    <br />
    <br />
    <h2>Address commands</h2>
    <br />
    <div>ADDRESS&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A&nbsp;&nbsp;Display&nbsp;address</strong>
      </div>
      <div>A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>Ann&nbsp;&nbsp;Select&nbsp;address</strong>
      </div>
      <div>&nbsp;┌&nbsp;1..4:&nbsp;Cassette&nbsp;number</div>
      <div>&nbsp;│┌&nbsp;1..8:&nbsp;TR&nbsp;&nbsp;(Transceiver)</div>
      <div>&nbsp;││&nbsp;a..d:&nbsp;PS&nbsp;&nbsp;(Power&nbsp;Supply)</div>
      <div>&nbsp;││&nbsp;A..B:&nbsp;TCC&nbsp;(TX&nbsp;Combiner&nbsp;Controller)</div>
      <div>&nbsp;┴┴</div>
      <div>A__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A++&nbsp;&nbsp;Forced&nbsp;address&nbsp;select</strong>
      </div>
      <div>A++</div>
      <div />
      <div />
    </div>
    <h2>General commands</h2>
    <br />
    <div>00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00&nbsp;&nbsp;Display&nbsp;software&nbsp;version</strong>
      </div>
      <div>00</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00/A&nbsp;&nbsp;Display&nbsp;all&nbsp;software&nbsp;version&nbsp;numbers</strong>
      </div>
      <div>00/A</div>
      <div />
      <div />
    </div>
    <div>01&nbsp;&nbsp;LOCAL&nbsp;BLOCKING/DEBLOCKING</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01&nbsp;&nbsp;Display&nbsp;Local&nbsp;Blocking&nbsp;selection</strong>
      </div>
      <div>01</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01+&nbsp;&nbsp;Local&nbsp;Blocking&nbsp;conditional&nbsp;request</strong>
      </div>
      <div>01+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01++&nbsp;&nbsp;Local&nbsp;Blocking&nbsp;forced&nbsp;request</strong>
      </div>
      <div>01++</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01-&nbsp;&nbsp;Local&nbsp;Blocking&nbsp;off</strong>
      </div>
      <div>01-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/S&nbsp;&nbsp;Execute&nbsp;software&nbsp;reset</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>01/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>01/H&nbsp;&nbsp;Execute&nbsp;hardware&nbsp;reset</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>01/H</div>
      <div />
      <div />
    </div>
    <div>02&nbsp;&nbsp;TR&nbsp;OPERATING&nbsp;MODE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02&nbsp;&nbsp;Display&nbsp;TR&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02/c&nbsp;&nbsp;Change&nbsp;TR&nbsp;operating&nbsp;mode</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Test</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;N:&nbsp;NMT&nbsp;(analog)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;TETRA&nbsp;(digital)</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>02/_</div>
      <div />
      <div />
    </div>
    <div>03&nbsp;&nbsp;TR&nbsp;ALARMS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03&nbsp;&nbsp;Display&nbsp;TR&nbsp;Alarm&nbsp;state</strong>
      </div>
      <div>03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/A&nbsp;&nbsp;Display&nbsp;Internal&nbsp;Alarms</strong>
      </div>
      <div>03/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/B&nbsp;&nbsp;Display&nbsp;BSC&nbsp;Alarms</strong>
      </div>
      <div>03/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/E&nbsp;&nbsp;Display&nbsp;External&nbsp;Alarms</strong>
      </div>
      <div>03/E</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/C&nbsp;&nbsp;Clear&nbsp;TR&nbsp;alarms</strong>
      </div>
      <div>03/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/L&nbsp;&nbsp;Display&nbsp;alarm&nbsp;latching&nbsp;selection</strong>
      </div>
      <div>03/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/L/s&nbsp;&nbsp;Change&nbsp;alarm&nbsp;latching&nbsp;selection</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Disable&nbsp;alarm&nbsp;latching</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;+:&nbsp;Enable&nbsp;alarm&nbsp;latching&nbsp;(default)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>03/L/_</div>
      <div />
      <div />
    </div>
    <div>05&nbsp;DISPLAY&nbsp;CONFIG/STATUS&nbsp;FROM&nbsp;BSC</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>05&nbsp;&nbsp;Display&nbsp;Config/Status&nbsp;from&nbsp;BSC</strong>
      </div>
      <div>05</div>
      <div />
      <div />
    </div>
    <div>07&nbsp;DISPLAY&nbsp;DATE&nbsp;AND&nbsp;TIME</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>07&nbsp;&nbsp;Display&nbsp;date/time</strong>
      </div>
      <div>07</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>07/S&nbsp;&nbsp;Display&nbsp;date/time&nbsp;status</strong>
      </div>
      <div>07/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>07/T&nbsp;&nbsp;Display&nbsp;DSP&nbsp;TETRA&nbsp;Counters</strong>
      </div>
      <div>07/T</div>
      <div />
      <div />
    </div>
    <h2>Transmitter commands</h2>
    <br />
    <div>10&nbsp;&nbsp;TX&nbsp;KEY</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10&nbsp;&nbsp;Display&nbsp;TX&nbsp;key&nbsp;state</strong>
      </div>
      <div>10</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10+&nbsp;&nbsp;Turn&nbsp;on&nbsp;TX</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>10+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10-&nbsp;&nbsp;Turn&nbsp;off&nbsp;TX</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>10-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/T&nbsp;&nbsp;Display&nbsp;timeslot&nbsp;key</strong>
      </div>
      <div>Only&nbsp;available&nbsp;in&nbsp;Tetra&nbsp;mode</div>
      <div>10/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/T/ssss&nbsp;&nbsp;Change&nbsp;TX&nbsp;timeslot&nbsp;key</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>Only&nbsp;available&nbsp;in&nbsp;Tetra&nbsp;mode</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TS1&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;TS2&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││┌&nbsp;TS3&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│││┌&nbsp;TS4&nbsp;key&nbsp;(+,-)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴┴┴</div>
      <div>10/T/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/AUTO&nbsp;&nbsp;Display&nbsp;Auto&nbsp;TX-off&nbsp;mode</strong>
      </div>
      <div>Only&nbsp;available&nbsp;in&nbsp;Tetra&nbsp;mode</div>
      <div>10/AUTO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10/AUTO/c&nbsp;&nbsp;Change&nbsp;Auto&nbsp;TX-off&nbsp;mode</strong>
      </div>
      <div>Has&nbsp;priority&nbsp;over&nbsp;control&nbsp;from&nbsp;L3</div>
      <div>Only&nbsp;significant&nbsp;in&nbsp;Tetra&nbsp;mode&nbsp;without&nbsp;Local&nbsp;Blocking</div>
      <div>Only&nbsp;available&nbsp;in&nbsp;Tetra&nbsp;mode</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-:&nbsp;Controlled&nbsp;of&nbsp;L3</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;D:&nbsp;Automatic&nbsp;TX-off&nbsp;disabled</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;C:&nbsp;Automatic&nbsp;Carrier-off&nbsp;(TX-off&nbsp;if&nbsp;all&nbsp;Timeslots&nbsp;idle)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;Automatic&nbsp;Timeslot-off&nbsp;(TX-off&nbsp;of&nbsp;idle&nbsp;Timeslots)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>10/AUTO/_</div>
      <div />
    </div>
    <div>11&nbsp;&nbsp;DISPLAY&nbsp;TX&nbsp;OUTPUT&nbsp;AND&nbsp;REFLECTED&nbsp;POWER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11&nbsp;&nbsp;Display&nbsp;TX&nbsp;output&nbsp;and&nbsp;reflected&nbsp;power</strong>
      </div>
      <div>11</div>
      <div />
      <div />
    </div>
    <div>12&nbsp;&nbsp;DISPLAY&nbsp;TX&nbsp;COMBINER&nbsp;TESTPOINT&nbsp;VALUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12&nbsp;&nbsp;Display&nbsp;TX&nbsp;combiner&nbsp;TP&nbsp;value</strong>
      </div>
      <div>12</div>
      <div />
      <div />
    </div>
    <div>13&nbsp;&nbsp;TX&nbsp;OUTPUT&nbsp;POWER&nbsp;LEVEL&nbsp;SELECTIONS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13&nbsp;&nbsp;Display&nbsp;TX&nbsp;output&nbsp;power&nbsp;selection</strong>
      </div>
      <div>13</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/+nn.n&nbsp;&nbsp;Change&nbsp;TX&nbsp;output&nbsp;power</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>The&nbsp;selected&nbsp;power&nbsp;is&nbsp;TETRA&nbsp;mean&nbsp;power&nbsp;on&nbsp;TR&nbsp;connector</div>
      <div>Maxim&nbsp;Peak&nbsp;Envelope&nbsp;Power&nbsp;is&nbsp;3.8dB&nbsp;higher</div>
      <div>For&nbsp;NMT/MPT&nbsp;(analog&nbsp;PM)&nbsp;power&nbsp;is&nbsp;+3dB&nbsp;the&nbsp;selected&nbsp;power</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;single&nbsp;tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;equal&nbsp;to&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;-3dB&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Output&nbsp;power&nbsp;selection</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;TR412&nbsp;:&nbsp;30.0&nbsp;to&nbsp;44.0&nbsp;[dBm]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;TR412H:&nbsp;34.0&nbsp;to&nbsp;48.0&nbsp;[dBm]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>13/+__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/MIN&nbsp;&nbsp;Change&nbsp;TX&nbsp;output&nbsp;power&nbsp;to&nbsp;minimum</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>13/MIN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/MAX&nbsp;&nbsp;Change&nbsp;TX&nbsp;output&nbsp;power&nbsp;to&nbsp;maximum</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>13/MAX</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/RED/+nn.n&nbsp;&nbsp;Change&nbsp;Reduced&nbsp;TX&nbsp;output&nbsp;power</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>Power&nbsp;setting&nbsp;for&nbsp;Reduced&nbsp;Power&nbsp;(Alarm&nbsp;Input&nbsp;8&nbsp;activated)</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>The&nbsp;selected&nbsp;power&nbsp;is&nbsp;TETRA&nbsp;mean&nbsp;power&nbsp;on&nbsp;TR&nbsp;connector</div>
      <div>Maxim&nbsp;Peak&nbsp;Envelope&nbsp;Power&nbsp;is&nbsp;3.8dB&nbsp;higher</div>
      <div>For&nbsp;NMT/MPT&nbsp;(analog&nbsp;PM)&nbsp;power&nbsp;is&nbsp;+3dB&nbsp;the&nbsp;selected&nbsp;power</div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;single&nbsp;tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;equal&nbsp;to&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>
        For&nbsp;Test&nbsp;mode&nbsp;two-tone,&nbsp;mean&nbsp;power&nbsp;is&nbsp;-3dB&nbsp;of&nbsp;Peak&nbsp;Envelope&nbsp;Power
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Reduced&nbsp;Output&nbsp;power&nbsp;selection</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;TR412&nbsp;:&nbsp;30.0&nbsp;to&nbsp;44.0&nbsp;[dBm]
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;TR412H:&nbsp;34.0&nbsp;to&nbsp;48.0&nbsp;[dBm]
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>13/RED/+__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/RED/MIN&nbsp;&nbsp;Change&nbsp;Reduced&nbsp;TX&nbsp;output&nbsp;power&nbsp;to&nbsp;minimum</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>Power&nbsp;setting&nbsp;for&nbsp;Reduced&nbsp;Power&nbsp;(Alarm&nbsp;Input&nbsp;8&nbsp;activated)</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>13/RED/MIN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>13/RED/MAX&nbsp;&nbsp;Change&nbsp;Reduced&nbsp;TX&nbsp;output&nbsp;power&nbsp;to&nbsp;maximum</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>Power&nbsp;setting&nbsp;for&nbsp;Reduced&nbsp;Power&nbsp;(Alarm&nbsp;Input&nbsp;8&nbsp;activated)</div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>13/RED/MAX</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15&nbsp;Display&nbsp;TX&nbsp;test&nbsp;modulation&nbsp;selection</strong>
      </div>
      <div>15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/ZERO&nbsp;&nbsp;Select&nbsp;zero&nbsp;modulation</strong>
      </div>
      <div>
        I&nbsp;and&nbsp;Q&nbsp;DAC&nbsp;set&nbsp;to&nbsp;zero&nbsp;(with&nbsp;zero&nbsp;offset&nbsp;compensation)
      </div>
      <div>15/ZERO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation</strong>
      </div>
      <div>Power&nbsp;level&nbsp;analog-value&nbsp;of&nbsp;selected&nbsp;power&nbsp;(5/6&nbsp;of&nbsp;pep)</div>
      <div>15/CW</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/PEP&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation&nbsp;PEP&nbsp;value</strong>
      </div>
      <div>Power&nbsp;level&nbsp;is&nbsp;pep-value&nbsp;of&nbsp;selected&nbsp;power</div>
      <div>15/CW/PEP</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/IQ&nbsp;&nbsp;Display&nbsp;I/Q&nbsp;values&nbsp;for&nbsp;CW&nbsp;modulation</strong>
      </div>
      <div>15/CW/IQ</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/cs&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I:&nbsp;I&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Q:&nbsp;Q&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│┌&nbsp;+:&nbsp;Positive&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;││&nbsp;-:&nbsp;Negative&nbsp;DAC&nbsp;output</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴┴</div>
      <div>15/CW/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/CW/IQ/snnnnn/snnnnn&nbsp;&nbsp;Select&nbsp;CW&nbsp;modulation&nbsp;with&nbsp;I/Q&nbsp;values</strong>
      </div>
      <div>
        NB:&nbsp;I&nbsp;and&nbsp;Q&nbsp;values&nbsp;are&nbsp;modified&nbsp;with&nbsp;power&nbsp;setting,&nbsp;I+Q&nbsp;offset
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;quadrature&nbsp;settings&nbsp;before&nbsp;reaching&nbsp;the&nbsp;DACs.</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I-value&nbsp;(-32768..+32767)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Q-value&nbsp;(-32768..+32767)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────&nbsp;┴─────</div>
      <div>15/CW/IQ/______/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/DSB/c/n&nbsp;&nbsp;Select&nbsp;DSB&nbsp;modulation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I:&nbsp;I&nbsp;generated&nbsp;DSB&nbsp;modulation</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Q:&nbsp;Q&nbsp;generated&nbsp;DSB&nbsp;modulation</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;2:&nbsp;2.25&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;4:&nbsp;4.50&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;9:&nbsp;9.00&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>15/DSB/_/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/cSB/n&nbsp;&nbsp;Select&nbsp;SSB&nbsp;modulation</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;L:&nbsp;LSB&nbsp;(Lower&nbsp;Side&nbsp;Band)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;U:&nbsp;USB&nbsp;(Upper&nbsp;Side&nbsp;Band)</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;2:&nbsp;2.25&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;│&nbsp;4:&nbsp;4.50&nbsp;kHz</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;&nbsp;&nbsp;┴</div>
      <div>15/_SB/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/PRBS9&nbsp;&nbsp;Select&nbsp;TETRA&nbsp;PRBS-9&nbsp;modulation</strong>
      </div>
      <div>15/PRBS9</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/PRBS15&nbsp;&nbsp;Select&nbsp;TETRA&nbsp;PRBS-15&nbsp;modulation</strong>
      </div>
      <div>15/PRBS15</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/PH45&nbsp;&nbsp;Select&nbsp;TETRA&nbsp;+/-45deg.&nbsp;modulation</strong>
      </div>
      <div>15/PH45</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/FM/3&nbsp;&nbsp;Select&nbsp;FM&nbsp;1kHz&nbsp;+/-3kHz</strong>
      </div>
      <div>15/FM/3</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>15/MULTI&nbsp;&nbsp;Select&nbsp;DSB&nbsp;multitone&nbsp;modulation</strong>
      </div>
      <div>15/MULTI</div>
      <div />
      <div />
    </div>
    <div>16&nbsp;&nbsp;TX&nbsp;FILTER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>16&nbsp;&nbsp;Display&nbsp;TX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>16</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>16/n&nbsp;&nbsp;Change&nbsp;TX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;filter&nbsp;type</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;TETRA&nbsp;25kHz&nbsp;ch.&nbsp;spacing&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;Flat&nbsp;(for&nbsp;test)&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>16/_</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>17&nbsp;&nbsp;Display&nbsp;TX&nbsp;status</strong>
      </div>
      <div>17</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>17/IQ&nbsp;&nbsp;Display&nbsp;TX&nbsp;IQ&nbsp;values</strong>
      </div>
      <div>17/IQ</div>
      <div />
      <div />
    </div>
    <div>18&nbsp;&nbsp;TX&nbsp;CARTESIAN&nbsp;LOOP&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18&nbsp;&nbsp;Display&nbsp;cartesian&nbsp;loop&nbsp;selection</strong>
      </div>
      <div>18</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18-&nbsp;&nbsp;Select&nbsp;open&nbsp;cartesian&nbsp;loop</strong>
      </div>
      <div>18-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18+&nbsp;&nbsp;Select&nbsp;closed&nbsp;cartesian&nbsp;loop</strong>
      </div>
      <div>18+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/P&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase&nbsp;measurement</strong>
      </div>
      <div>18/P</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/A&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase/ampl.&nbsp;measurement</strong>
      </div>
      <div>18/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          18/FREQ&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase&nbsp;vs&nbsp;frequency&nbsp;measurement
        </strong>
      </div>
      <div>18/FREQ</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/PWR&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;loop&nbsp;phase&nbsp;vs.&nbsp;power&nbsp;measurement</strong>
      </div>
      <div>18/PWR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>18/ADJUST&nbsp;&nbsp;TX&nbsp;cartesian&nbsp;open&nbsp;loop&nbsp;phase&nbsp;adjust</strong>
      </div>
      <div>WARNING:&nbsp;Factory&nbsp;adjust&nbsp;command,&nbsp;factory&nbsp;unlock&nbsp;necessary</div>
      <div>Changes&nbsp;same&nbsp;parameter&nbsp;as&nbsp;the&nbsp;91/PH0/snnn&nbsp;command</div>
      <div>18/ADJUST</div>
      <div />
      <div />
    </div>
    <div>19&nbsp;&nbsp;TX&nbsp;PLL/KEY&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>19</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19/F&nbsp;&nbsp;Select&nbsp;fast&nbsp;TX&nbsp;PLL&nbsp;loop</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>19/F</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19/S&nbsp;&nbsp;Select&nbsp;slow&nbsp;TX&nbsp;PLL&nbsp;loop</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>19/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19/FREQ&nbsp;&nbsp;Display&nbsp;TX&nbsp;PLL&nbsp;voltage&nbsp;vs&nbsp;frequency</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>19/FREQ</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>19+&nbsp;&nbsp;Switch&nbsp;TX&nbsp;on&nbsp;and&nbsp;off&nbsp;continuously</strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>19+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          19/nnnn&nbsp;&nbsp;Switch&nbsp;TX&nbsp;channel&nbsp;continuously&nbsp;between&nbsp;current&nbsp;and&nbsp;nnnn
        </strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>19/____</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Receiver commands</h2>
    <br />
    <div>20&nbsp;&nbsp;RX&nbsp;LO</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20&nbsp;&nbsp;Display&nbsp;RX&nbsp;LO&nbsp;status</strong>
      </div>
      <div>20</div>
      <div />
      <div />
    </div>
    <div>21&nbsp;&nbsp;DISPLAY&nbsp;RX&nbsp;RSSI</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21&nbsp;&nbsp;Display&nbsp;RX&nbsp;RSSI</strong>
      </div>
      <div>With&nbsp;5&nbsp;msec.&nbsp;settling&nbsp;time</div>
      <div>21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/c&nbsp;&nbsp;Display&nbsp;RX&nbsp;RSSI</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;RSSI&nbsp;option</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;100&nbsp;msec.&nbsp;settling&nbsp;time&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;1&nbsp;sec.&nbsp;settling&nbsp;time&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;Q:&nbsp;5&nbsp;msec.&nbsp;settling&nbsp;time&nbsp;without&nbsp;header</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/NF&nbsp;&nbsp;Display&nbsp;RX&nbsp;Noise&nbsp;Figure</strong>
      </div>
      <div />
      <div>Displays&nbsp;the&nbsp;RSSI&nbsp;value&nbsp;as&nbsp;equivalent&nbsp;Noise&nbsp;Figure</div>
      <div>The&nbsp;reference&nbsp;depends&nbsp;on&nbsp;the&nbsp;actual&nbsp;IF&nbsp;filter&nbsp;</div>
      <div>bandwidth,&nbsp;see&nbsp;the&nbsp;26&nbsp;command</div>
      <div />
      <div>21/NF</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/FFT/c&nbsp;&nbsp;Display&nbsp;FFT&nbsp;result</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;path:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;RX-A&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;RX-B&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/FFT/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21/FFT/c/2&nbsp;&nbsp;Display&nbsp;2.25kHz&nbsp;FFT&nbsp;amplitude&nbsp;results</strong>
      </div>
      <div>Response&nbsp;is:</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:-6.75&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:-2.25&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;0.00&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:+2.25&nbsp;kHz&nbsp;[dBm]</div>
      <div>-nnn.n&nbsp;&nbsp;&nbsp;&nbsp;:+6.75&nbsp;kHz&nbsp;[dBm]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;path:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;RX-A&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;RX-B&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>21/FFT/_/2</div>
      <div />
      <div />
    </div>
    <div>22&nbsp;&nbsp;RX&nbsp;DIVERSITY</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22&nbsp;&nbsp;Display&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>22</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/A&nbsp;&nbsp;A-forced&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>22/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22/B&nbsp;&nbsp;B-forced&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>22/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22-&nbsp;&nbsp;Automatic&nbsp;RX&nbsp;diversity&nbsp;selection</strong>
      </div>
      <div>22-</div>
      <div />
      <div />
    </div>
    <div>23&nbsp;&nbsp;RX&nbsp;ANTENNA&nbsp;TEST</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>23</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23+&nbsp;&nbsp;RX&nbsp;antenna&nbsp;measurement&nbsp;command</strong>
      </div>
      <div>23+</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25&nbsp;Display&nbsp;RX&nbsp;test&nbsp;demodulation&nbsp;mode</strong>
      </div>
      <div>25</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/PM&nbsp;Select&nbsp;PM&nbsp;demodulation</strong>
      </div>
      <div>25/PM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>25/FFT&nbsp;Select&nbsp;FFT&nbsp;demodulation</strong>
      </div>
      <div>25/FFT</div>
      <div />
    </div>
    <div>26&nbsp;&nbsp;RX&nbsp;FILTER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26&nbsp;&nbsp;Display&nbsp;RX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>26</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>26/n&nbsp;&nbsp;Change&nbsp;RX&nbsp;filter&nbsp;type</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;filter&nbsp;type</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;0:&nbsp;TETRA&nbsp;25kHz&nbsp;ch.&nbsp;spacing&nbsp;&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;PM&nbsp;25kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-7.5kHz)&nbsp;&nbsp;&nbsp;
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;PM&nbsp;25kHz&nbsp;ch.&nbsp;spacing&nbsp;interleaved&nbsp;(+/-6.5kHz)&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;PM&nbsp;20kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-6kHz)&nbsp;&nbsp;</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;PM&nbsp;12.5kHz&nbsp;ch.&nbsp;spacing&nbsp;(+/-3.75kHz)&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;5:&nbsp;Flat&nbsp;(for&nbsp;test)&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>26/_</div>
      <div />
      <div />
    </div>
    <div>27&nbsp;&nbsp;RX&nbsp;SIGNAL&nbsp;DISPLAY&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>27&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>27</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>27/ADC&nbsp;&nbsp;Display&nbsp;RX&nbsp;ADC&nbsp;signals</strong>
      </div>
      <div>27/ADC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>27/CP/c&nbsp;&nbsp;Display&nbsp;TETRA&nbsp;constellation&nbsp;points</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;path:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;RX-A&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;B:&nbsp;RX-B&nbsp;&nbsp;&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>27/CP/_</div>
      <div />
      <div />
    </div>
    <div>28&nbsp;&nbsp;RX&nbsp;ADJUST&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>28&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>28</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>28/LO&nbsp;&nbsp;Display&nbsp;LO1&nbsp;injection</strong>
      </div>
      <div>28/LO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>28/PLL&nbsp;&nbsp;Display&nbsp;LO1&nbsp;PLL&nbsp;voltage&nbsp;vs&nbsp;frequency</strong>
      </div>
      <div>28/PLL</div>
      <div />
      <div />
      <div />
    </div>
    <div>29&nbsp;&nbsp;SWITCH&nbsp;RX&nbsp;CHANNEL&nbsp;CONTINUOUSLY</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;RX&nbsp;FS&nbsp;switch&nbsp;command&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;29&nbsp;response</div>
    <div>
      2)&nbsp;RX&nbsp;FS&nbsp;switch&nbsp;cont.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;29/nnnn&nbsp;response
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;29+&nbsp;and&nbsp;29/nnnn&nbsp;commands&nbsp;starts&nbsp;a&nbsp;continuous&nbsp;switching&nbsp;between&nbsp;two
    </div>
    <div>channels.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>29&nbsp;&nbsp;Display&nbsp;command&nbsp;29&nbsp;function</strong>
      </div>
      <div>29</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>29/F&nbsp;&nbsp;Select&nbsp;fast&nbsp;TX&nbsp;FS&nbsp;loop</strong>
      </div>
      <div>29/F</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>29/S&nbsp;&nbsp;Select&nbsp;slow&nbsp;TX&nbsp;FS&nbsp;loop</strong>
      </div>
      <div>29/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          29/nnnn&nbsp;&nbsp;Switch&nbsp;RX&nbsp;channel&nbsp;continuously&nbsp;between&nbsp;current&nbsp;and&nbsp;nnnn
        </strong>
      </div>
      <div>Local&nbsp;blocking&nbsp;necessary</div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>29/____</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Common commands</h2>
    <br />
    <br />
    <div>30&nbsp;&nbsp;CHANNEL&nbsp;COMMANDS</div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30&nbsp;&nbsp;Display&nbsp;RX+TX&nbsp;channel&nbsp;numbers</strong>
      </div>
      <div>30</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/nnnn&nbsp;&nbsp;Change&nbsp;RX+TX&nbsp;channel&nbsp;number</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>
        NB:&nbsp;Channel&nbsp;Number&nbsp;step&nbsp;size&nbsp;depends&nbsp;on&nbsp;factory&nbsp;settings&nbsp;(see&nbsp;93&nbsp;commands)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/R/nnnn&nbsp;&nbsp;Change&nbsp;RX&nbsp;channel&nbsp;number</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>
        NB:&nbsp;Channel&nbsp;Number&nbsp;step&nbsp;size&nbsp;depends&nbsp;on&nbsp;factory&nbsp;settings&nbsp;(see&nbsp;93&nbsp;commands)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/R/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/T/nnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;channel&nbsp;number</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>
        NB:&nbsp;Channel&nbsp;Number&nbsp;step&nbsp;size&nbsp;depends&nbsp;on&nbsp;factory&nbsp;settings&nbsp;(see&nbsp;93&nbsp;commands)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;channel&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>30/T/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/R/nnnn.nnnnnn&nbsp;&nbsp;Change&nbsp;RX&nbsp;frequency</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>
        NB:&nbsp;Frequency&nbsp;range&nbsp;and&nbsp;step&nbsp;size&nbsp;depends&nbsp;on&nbsp;factory&nbsp;settings&nbsp;(see&nbsp;93&nbsp;commands)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;[MHz]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────</div>
      <div>30/R/____.______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30/T/nnnn.nnnnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;frequency</strong>
      </div>
      <div>The&nbsp;setting&nbsp;is&nbsp;saved&nbsp;with&nbsp;the&nbsp;70/SAVE&nbsp;command</div>
      <div>
        NB:&nbsp;Frequency&nbsp;range&nbsp;and&nbsp;step&nbsp;size&nbsp;depends&nbsp;on&nbsp;factory&nbsp;settings&nbsp;(see&nbsp;93&nbsp;commands)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Frequency&nbsp;[MHz]</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────</div>
      <div>30/T/____.______</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>31&nbsp;&nbsp;Display&nbsp;BSC&nbsp;signals&nbsp;and&nbsp;PLL&nbsp;status</strong>
      </div>
      <div>31</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32&nbsp;&nbsp;Display&nbsp;RFTL&nbsp;selection</strong>
      </div>
      <div>32</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32+&nbsp;&nbsp;Turn&nbsp;on&nbsp;RFTL&nbsp;high</strong>
      </div>
      <div>Turns&nbsp;on&nbsp;output&nbsp;to&nbsp;-40dBr</div>
      <div>32+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32/L&nbsp;&nbsp;Turn&nbsp;on&nbsp;RFTL&nbsp;low</strong>
      </div>
      <div>Turns&nbsp;on&nbsp;output&nbsp;to&nbsp;-50dBr</div>
      <div>Not&nbsp;supported&nbsp;in&nbsp;older&nbsp;hardware&nbsp;versions</div>
      <div>32/L</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>32-&nbsp;&nbsp;Turn&nbsp;off&nbsp;RFTL</strong>
      </div>
      <div>32-</div>
      <div />
      <div />
    </div>
    <div>33&nbsp;&nbsp;PCM&nbsp;HIGHWAY</div>
    <br />
    <div>Highway&nbsp;timeslot&nbsp;numbers:</div>
    <div>Pos:&nbsp;&nbsp;x1&nbsp;x2&nbsp;x3&nbsp;x4&nbsp;x5&nbsp;x6&nbsp;x7&nbsp;x8</div>
    <div>----&nbsp;&nbsp;--&nbsp;--&nbsp;--&nbsp;--&nbsp;--&nbsp;--&nbsp;--&nbsp;--</div>
    <div>TS1:&nbsp;&nbsp;00&nbsp;04&nbsp;08&nbsp;12&nbsp;16&nbsp;20&nbsp;24&nbsp;28&nbsp;</div>
    <div>TS2:&nbsp;&nbsp;01&nbsp;05&nbsp;09&nbsp;13&nbsp;17&nbsp;21&nbsp;25&nbsp;29&nbsp;</div>
    <div>TS3:&nbsp;&nbsp;02&nbsp;06&nbsp;10&nbsp;14&nbsp;18&nbsp;22&nbsp;26&nbsp;30&nbsp;</div>
    <div>TS4:&nbsp;&nbsp;03&nbsp;07&nbsp;11&nbsp;15&nbsp;19&nbsp;23&nbsp;27&nbsp;31&nbsp;</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>33&nbsp;&nbsp;Display&nbsp;PCM&nbsp;highway&nbsp;selections</strong>
      </div>
      <div>33</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>33/c/s&nbsp;&nbsp;PCM&nbsp;highway&nbsp;loopback&nbsp;control</strong>
      </div>
      <div>When&nbsp;activated,&nbsp;the&nbsp;incoming&nbsp;timeslot&nbsp;bitstream&nbsp;is&nbsp;returned&nbsp;on</div>
      <div>the&nbsp;outgoing&nbsp;timeslot.&nbsp;</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TR&nbsp;timeslot&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;1:&nbsp;TR&nbsp;timeslot&nbsp;1</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;2:&nbsp;TR&nbsp;timeslot&nbsp;2</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;3:&nbsp;TR&nbsp;timeslot&nbsp;3</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;4:&nbsp;TR&nbsp;timeslot&nbsp;4</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;A:&nbsp;All&nbsp;4&nbsp;TR&nbsp;timeslots&nbsp;</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;+:&nbsp;Activate&nbsp;loopback</div>
      <div>&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;-:&nbsp;Stop&nbsp;loopback</div>
      <div>&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>33/_/_</div>
      <div />
      <div />
    </div>
    <div>34&nbsp;CMoIP</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34&nbsp;&nbsp;Display&nbsp;CMoIP&nbsp;status</strong>
      </div>
      <div>34</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>34/C&nbsp;&nbsp;Clear&nbsp;CMoIP&nbsp;Downlink&nbsp;statistics&nbsp;counters</strong>
      </div>
      <div>34/C</div>
      <div />
      <div />
    </div>
    <div>35&nbsp;BLOWER</div>
    <div>
      All&nbsp;blowers&nbsp;mounted&nbsp;below&nbsp;the&nbsp;TR&nbsp;cassette&nbsp;can&nbsp;run&nbsp;with&nbsp;two&nbsp;
    </div>
    <div>speeds&nbsp;(except&nbsp;for&nbsp;backplanes&nbsp;from&nbsp;before&nbsp;2001).</div>
    <br />
    <div>
      Under&nbsp;normal&nbsp;conditions,&nbsp;all&nbsp;blowers&nbsp;are&nbsp;running&nbsp;with&nbsp;slow&nbsp;speed.
    </div>
    <br />
    <div>
      When&nbsp;any&nbsp;of&nbsp;the&nbsp;mounted&nbsp;TR412&nbsp;in&nbsp;that&nbsp;cassette&nbsp;exceeds&nbsp;the&nbsp;temperature
    </div>
    <div>+60C,&nbsp;it&nbsp;will&nbsp;force&nbsp;the&nbsp;blower&nbsp;speed&nbsp;to&nbsp;fast&nbsp;speed.</div>
    <br />
    <div>
      When&nbsp;the&nbsp;temperature&nbsp;on&nbsp;that&nbsp;transceiver&nbsp;again&nbsp;drops&nbsp;below&nbsp;+55,&nbsp;it&nbsp;will
    </div>
    <div>stop&nbsp;forcing&nbsp;the&nbsp;blowers&nbsp;to&nbsp;fast&nbsp;speed.</div>
    <br />
    <div>
      Fast&nbsp;blower&nbsp;speed&nbsp;can&nbsp;also&nbsp;be&nbsp;selected&nbsp;for&nbsp;test&nbsp;purposes&nbsp;with&nbsp;O&amp;M&nbsp;
    </div>
    <div>commands.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>35&nbsp;&nbsp;Display&nbsp;blower&nbsp;state</strong>
      </div>
      <div>35</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>35+&nbsp;&nbsp;Select&nbsp;forced&nbsp;fast&nbsp;Blower</strong>
      </div>
      <div>35+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>35-&nbsp;&nbsp;Deselect&nbsp;forced&nbsp;fast&nbsp;Blower</strong>
      </div>
      <div>35-</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43&nbsp;&nbsp;Display&nbsp;Timeslot&nbsp;status</strong>
      </div>
      <div>43</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/CNTR&nbsp;&nbsp;Display&nbsp;counter&nbsp;values</strong>
      </div>
      <div>43/CNTR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/CNTR/C&nbsp;&nbsp;Clear&nbsp;counter&nbsp;values</strong>
      </div>
      <div>43/CNTR/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>43/CONST/n/c&nbsp;&nbsp;Display&nbsp;constellation&nbsp;points</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Timeslot&nbsp;number&nbsp;(1..4)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;RX&nbsp;path&nbsp;(A&nbsp;or&nbsp;B)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴</div>
      <div>43/CONST/_/_</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>48&nbsp;&nbsp;Display&nbsp;Message&nbsp;Trace</strong>
      </div>
      <div>48</div>
      <div />
      <div />
    </div>
    <br />
    <div>
      The&nbsp;49&nbsp;commands&nbsp;control&nbsp;the&nbsp;TR412&nbsp;build-in&nbsp;TETRA&nbsp;channel&nbsp;test&nbsp;features.
    </div>
    <br />
    <div>
      When&nbsp;channel&nbsp;test&nbsp;is&nbsp;activated,&nbsp;a&nbsp;test&nbsp;point&nbsp;at&nbsp;the&nbsp;output&nbsp;from&nbsp;the&nbsp;receiver
    </div>
    <div>
      or&nbsp;alternatively&nbsp;at&nbsp;the&nbsp;input&nbsp;from&nbsp;the&nbsp;PCM&nbsp;highway&nbsp;is&nbsp;selected.&nbsp;Received&nbsp;bursts
    </div>
    <div>
      including&nbsp;status&nbsp;will&nbsp;be&nbsp;send&nbsp;to&nbsp;the&nbsp;BSC&nbsp;via&nbsp;the&nbsp;HDLC&nbsp;bus,&nbsp;where&nbsp;BER/MER&nbsp;mea-
    </div>
    <div>surements&nbsp;can&nbsp;be&nbsp;made&nbsp;with&nbsp;the&nbsp;F49&nbsp;commands.</div>
    <br />
    <div>
      When&nbsp;channel&nbsp;test&nbsp;is&nbsp;activated,&nbsp;the&nbsp;TR412&nbsp;TX&nbsp;switches&nbsp;to&nbsp;uplink&nbsp;mode&nbsp;and
    </div>
    <div>
      generates&nbsp;the&nbsp;selected&nbsp;channel&nbsp;type.&nbsp;When&nbsp;TX&nbsp;is&nbsp;keyed&nbsp;and&nbsp;RFTL&nbsp;activated,
    </div>
    <div>an&nbsp;RX&nbsp;input&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;for&nbsp;the&nbsp;testing.</div>
    <br />
    <div>
      Alternatively,&nbsp;a&nbsp;test&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;from&nbsp;an&nbsp;external&nbsp;source,&nbsp;e.g.
    </div>
    <div>an&nbsp;R&amp;S&nbsp;SMIQ&nbsp;or&nbsp;Anritsu&nbsp;signal&nbsp;generator</div>
    <br />
    <div>
      The&nbsp;uplink&nbsp;test&nbsp;point&nbsp;is&nbsp;intended&nbsp;for&nbsp;normal&nbsp;receiver&nbsp;channel&nbsp;tests.
    </div>
    <br />
    <div>
      The&nbsp;downlink&nbsp;test&nbsp;point&nbsp;is&nbsp;intented&nbsp;for&nbsp;test&nbsp;of&nbsp;received&nbsp;circuit&nbsp;mode&nbsp;traffic
    </div>
    <div>
      transmitted&nbsp;via&nbsp;the&nbsp;PCM&nbsp;highway,&nbsp;and&nbsp;optionally&nbsp;an&nbsp;E1&nbsp;connection&nbsp;and&nbsp;the&nbsp;
    </div>
    <div>
      looped&nbsp;back.&nbsp;The&nbsp;signal&nbsp;can&nbsp;be&nbsp;generated&nbsp;on&nbsp;one&nbsp;TR412&nbsp;and&nbsp;monitored&nbsp;on&nbsp;a&nbsp;downlink
    </div>
    <div>testpoint&nbsp;on&nbsp;another&nbsp;TR412&nbsp;on&nbsp;the&nbsp;same&nbsp;or&nbsp;another&nbsp;BS.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49&nbsp;&nbsp;Display&nbsp;Tetra&nbsp;channel&nbsp;test&nbsp;selection</strong>
      </div>
      <div>49</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49/CHTST/nn/n&nbsp;&nbsp;Activate&nbsp;Tetra&nbsp;channel&nbsp;test</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Uplink&nbsp;test&nbsp;point&nbsp;channel&nbsp;type:
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;07:&nbsp;TCH/7.2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;08:&nbsp;SCH/F</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;09:&nbsp;STCH</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;10:&nbsp;TCH/2.4&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;11:&nbsp;SCH/HU</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;16:&nbsp;TCH/S</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;18:&nbsp;TCH/4.8&nbsp;N=1</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;Downlink&nbsp;test&nbsp;point&nbsp;channel&nbsp;type:
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;57:&nbsp;TCH/7.2</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;60:&nbsp;TCH/2.4&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;66:&nbsp;TCH/S</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;68:&nbsp;TCH/4.8&nbsp;N=1</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Timeslot&nbsp;(1..4)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴</div>
      <div>49/CHTST/__/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>49-&nbsp;&nbsp;Stop&nbsp;Tetra&nbsp;channel&nbsp;test</strong>
      </div>
      <div>49-</div>
      <div />
      <div />
    </div>
    <h2>Test commands</h2>
    <br />
    <div>60&nbsp;&nbsp;DC&nbsp;VOLTMETER</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displays&nbsp;the&nbsp;DC&nbsp;voltage&nbsp;on&nbsp;one&nbsp;of&nbsp;the&nbsp;xx&nbsp;DC&nbsp;test&nbsp;points.
    </div>
    <br />
    <div>
      The&nbsp;displayed&nbsp;reading&nbsp;is&nbsp;taken&nbsp;from&nbsp;a&nbsp;RAM&nbsp;register&nbsp;array,&nbsp;which&nbsp;is&nbsp;updated
    </div>
    <div>
      every&nbsp;24/1000&nbsp;sec.&nbsp;Therefore,&nbsp;some&nbsp;delay&nbsp;can&nbsp;be&nbsp;necessary&nbsp;in&nbsp;connection
    </div>
    <div>with&nbsp;automatic&nbsp;test&nbsp;equipment.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60&nbsp;&nbsp;Display&nbsp;DC&nbsp;test&nbsp;points</strong>
      </div>
      <div>60</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/A&nbsp;&nbsp;Display&nbsp;all&nbsp;DC&nbsp;test&nbsp;points</strong>
      </div>
      <div>60/A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/nn&nbsp;&nbsp;Display&nbsp;specific&nbsp;DC&nbsp;test&nbsp;point</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;TP&nbsp;number&nbsp;(00..16)</div>
      <div>&nbsp;&nbsp;&nbsp;┴─</div>
      <div>60/__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/T&nbsp;&nbsp;Display&nbsp;temperature</strong>
      </div>
      <div>60/T</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60/REF&nbsp;&nbsp;Display&nbsp;DC&nbsp;voltmeter&nbsp;reference&nbsp;voltage</strong>
      </div>
      <div>60/REF</div>
      <div />
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61&nbsp;&nbsp;Display&nbsp;DSP&nbsp;state</strong>
      </div>
      <div>61</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/CNTR&nbsp;&nbsp;Display&nbsp;DSP&nbsp;counters</strong>
      </div>
      <div>61/CNTR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/CNTR/C&nbsp;&nbsp;Clear&nbsp;DSP&nbsp;counters</strong>
      </div>
      <div>61/CNTR/C</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61-&nbsp;&nbsp;Stop&nbsp;DSP&nbsp;execution</strong>
      </div>
      <div>Activates&nbsp;DSP&nbsp;hardware&nbsp;reset</div>
      <div>61-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61+&nbsp;&nbsp;Restart&nbsp;DSP</strong>
      </div>
      <div>Makes:</div>
      <div>&nbsp;&nbsp;1)&nbsp;Hardware&nbsp;reset&nbsp;on</div>
      <div>&nbsp;&nbsp;2)&nbsp;Hardware&nbsp;reset&nbsp;off</div>
      <div>&nbsp;&nbsp;3)&nbsp;DSP&nbsp;memory&nbsp;clear</div>
      <div>&nbsp;&nbsp;4)&nbsp;DSP&nbsp;code&nbsp;installation</div>
      <div>&nbsp;&nbsp;5)&nbsp;Interface&nbsp;register&nbsp;init</div>
      <div>&nbsp;&nbsp;6)&nbsp;Program&nbsp;execution&nbsp;start</div>
      <div>61+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/+&nbsp;&nbsp;Release&nbsp;DSP&nbsp;reset</strong>
      </div>
      <div>DSP&nbsp;reset&nbsp;state&nbsp;necessary</div>
      <div>Afterward,&nbsp;manual&nbsp;R/W&nbsp;can&nbsp;be&nbsp;done</div>
      <div>61/+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/B&nbsp;&nbsp;Boot&nbsp;DSP&nbsp;without&nbsp;execution</strong>
      </div>
      <div>Makes:</div>
      <div>&nbsp;&nbsp;1)&nbsp;Hardware&nbsp;reset&nbsp;on</div>
      <div>&nbsp;&nbsp;2)&nbsp;Hardware&nbsp;reset&nbsp;off</div>
      <div>&nbsp;&nbsp;3)&nbsp;DSP&nbsp;memory&nbsp;clear</div>
      <div>&nbsp;&nbsp;4)&nbsp;DSP&nbsp;code&nbsp;installation</div>
      <div>&nbsp;&nbsp;5)&nbsp;Interface&nbsp;register&nbsp;init</div>
      <div>
        Afterward,&nbsp;manual&nbsp;R/W&nbsp;and&nbsp;program&nbsp;execution&nbsp;start&nbsp;can&nbsp;be&nbsp;done
      </div>
      <div>61/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/R&nbsp;&nbsp;Start&nbsp;DSP&nbsp;program&nbsp;run</strong>
      </div>
      <div>DSP&nbsp;booted&nbsp;state&nbsp;necessary</div>
      <div>61/R</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhhh&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;word</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(32-bit&nbsp;aligned&nbsp;hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhh0/B&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;byte-wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/_______0/B</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhh0/W&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;word-wise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/_______0/W</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhh0/D&nbsp;&nbsp;Display&nbsp;DSP&nbsp;memory&nbsp;doublewordwise</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(hex)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/_______0/D</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/REG/c&nbsp;&nbsp;Display&nbsp;DSP&nbsp;interface&nbsp;registers</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;D:&nbsp;DSP&nbsp;control/status&nbsp;registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;C:&nbsp;Radio&nbsp;calibration&nbsp;registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;R:&nbsp;Radio&nbsp;control&nbsp;registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;S:&nbsp;Radio&nbsp;status&nbsp;registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;M:&nbsp;Message&nbsp;queue&nbsp;registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;T:&nbsp;Tetra&nbsp;mode&nbsp;registers</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>61/REG/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/hhhhhhhh/hhhhhhhh&nbsp;&nbsp;Change&nbsp;DSP&nbsp;memory</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;DSP&nbsp;memory&nbsp;address&nbsp;(32-bit&nbsp;aligned&nbsp;hex)</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Data&nbsp;to&nbsp;write&nbsp;(hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴───────&nbsp;┴───────</div>
      <div>61/________/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/HPIC&nbsp;&nbsp;DSP&nbsp;HPIC&nbsp;read-back</strong>
      </div>
      <div>61/HPIC</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>61/HPIA/hhhhhhhh&nbsp;&nbsp;DSP&nbsp;HPIA&nbsp;write/read&nbsp;test</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Value&nbsp;to&nbsp;write&nbsp;to&nbsp;HPIA&nbsp;(hex)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>61/HPIA/________</div>
      <div />
      <div />
    </div>
    <div>65&nbsp;&nbsp;HDLC&nbsp;BUS&nbsp;MESSAGE&nbsp;QUEUE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>65</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/N&nbsp;&nbsp;Display&nbsp;next&nbsp;HDLC&nbsp;message</strong>
      </div>
      <div>65/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/C&nbsp;&nbsp;Clear&nbsp;HDLC&nbsp;test&nbsp;queue</strong>
      </div>
      <div>65/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/S&nbsp;&nbsp;Set&nbsp;HDLC&nbsp;test&nbsp;queue&nbsp;to&nbsp;start</strong>
      </div>
      <div>65/S</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/s&nbsp;&nbsp;Message&nbsp;display&nbsp;suppress&nbsp;control</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;+:&nbsp;Display&nbsp;all&nbsp;messages</div>
      <div>
        &nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Suppress&nbsp;$0A,&nbsp;$0F,&nbsp;$33,&nbsp;$8A,&nbsp;$8C,&nbsp;$8F&nbsp;and&nbsp;$B3&nbsp;messages&nbsp;(default)
      </div>
      <div>&nbsp;&nbsp;&nbsp;┴</div>
      <div>65/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/ADDR&nbsp;&nbsp;Display&nbsp;HDLC&nbsp;queue&nbsp;addresses</strong>
      </div>
      <div>65/ADDR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/OWN&nbsp;&nbsp;Display&nbsp;OWN&nbsp;bits</strong>
      </div>
      <div>65/OWN</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/BUF&nbsp;&nbsp;Display&nbsp;current&nbsp;HDLC&nbsp;descriptors</strong>
      </div>
      <div>65/BUF</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/BUF/nnn&nbsp;&nbsp;Display&nbsp;HDLC&nbsp;descriptors</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Ring&nbsp;descriptor&nbsp;index&nbsp;(000..127)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>65/BUF/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/hhhhhhhh..hh&nbsp;&nbsp;Send&nbsp;HDLC&nbsp;message&nbsp;to&nbsp;BSC</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;in&nbsp;hex</div>
      <div>
        &nbsp;&nbsp;&nbsp;│1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7&nbsp;8&nbsp;9&nbsp;0&nbsp;1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7&nbsp;8&nbsp;9&nbsp;0&nbsp;1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7&nbsp;8&nbsp;9&nbsp;0&nbsp;1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7
      </div>
      <div>&nbsp;&nbsp;&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>65/__________________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/TST&nbsp;&nbsp;Display&nbsp;HDLC&nbsp;test&nbsp;variables</strong>
      </div>
      <div>65/TST</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/TST/c&nbsp;&nbsp;Start/stop&nbsp;sending&nbsp;HDLC&nbsp;test&nbsp;messages</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;B:&nbsp;Send&nbsp;messages&nbsp;looped-back&nbsp;in&nbsp;BSC
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;I:&nbsp;Send&nbsp;messages&nbsp;looped-back&nbsp;in&nbsp;BSC&nbsp;IP&nbsp;stack
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;O:&nbsp;Send&nbsp;messages&nbsp;looped-back&nbsp;in&nbsp;other&nbsp;BSC
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;S:&nbsp;Send&nbsp;messages&nbsp;looped-back&nbsp;in&nbsp;BSS/SC
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;-:&nbsp;Stop&nbsp;sending&nbsp;messages</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>65/TST/_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/TST/C&nbsp;&nbsp;Clear&nbsp;HDLC&nbsp;test&nbsp;error&nbsp;counters</strong>
      </div>
      <div>65/TST/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/TST/nn/nnn/nn&nbsp;&nbsp;Change&nbsp;HDLC&nbsp;test&nbsp;message&nbsp;output&nbsp;parameters</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Numer&nbsp;of&nbsp;messages&nbsp;send&nbsp;each&nbsp;time&nbsp;(01..99)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;┌&nbsp;Send&nbsp;repetition&nbsp;time&nbsp;(001..999&nbsp;msec.)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;┌&nbsp;Payload&nbsp;length&nbsp;(00..99)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─&nbsp;┴──&nbsp;┴─</div>
      <div>65/TST/__/___/__</div>
      <div />
      <div />
    </div>
    <div>66&nbsp;&nbsp;CPU&nbsp;LOAD&nbsp;COMMAND</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66&nbsp;&nbsp;Display&nbsp;CPU&nbsp;load</strong>
      </div>
      <div>66</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/C&nbsp;&nbsp;Clear&nbsp;max-counter&nbsp;values</strong>
      </div>
      <div>66/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/LOAD&nbsp;&nbsp;Display&nbsp;random&nbsp;load&nbsp;value</strong>
      </div>
      <div>66/LOAD</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>66/LOAD/nnnn&nbsp;&nbsp;Change&nbsp;random&nbsp;load&nbsp;value</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Random&nbsp;load&nbsp;value&nbsp;(0000..9999)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>66/LOAD/____</div>
      <div />
      <div />
      <div />
    </div>
    <div>67&nbsp;&nbsp;INPUT/OUTPUT&nbsp;COMMANDS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>67/LEDS&nbsp;&nbsp;Display&nbsp;LED&nbsp;state</strong>
      </div>
      <div>67/LEDS</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>67/ADDRPORT&nbsp;&nbsp;Display&nbsp;address&nbsp;port</strong>
      </div>
      <div>67/ADDRPORT</div>
      <div />
      <div />
    </div>
    <div>68&nbsp;&nbsp;PROGRAM&nbsp;DOWNLOAD</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68&nbsp;&nbsp;Display&nbsp;command</strong>
      </div>
      <div>68</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68/DOWNLOAD&nbsp;&nbsp;Request&nbsp;program&nbsp;download&nbsp;from&nbsp;BSC</strong>
      </div>
      <div>1)&nbsp;Mark&nbsp;download&nbsp;request&nbsp;in&nbsp;flash</div>
      <div>2)&nbsp;Make&nbsp;hardware&nbsp;reset&nbsp;to&nbsp;enter&nbsp;download&nbsp;mode</div>
      <div>68/DOWNLOAD</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>68/CHKSUM&nbsp;&nbsp;Display&nbsp;flash&nbsp;checksum</strong>
      </div>
      <div>68/CHKSUM</div>
      <div />
      <div />
    </div>
    <div>69&nbsp;&nbsp;DISPLAY&nbsp;MEMORY</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displayes&nbsp;the&nbsp;contents&nbsp;of&nbsp;a&nbsp;memory&nbsp;area&nbsp;of&nbsp;the&nbsp;AM186CC
    </div>
    <div>microcontroller.</div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69&nbsp;&nbsp;Display&nbsp;boot&nbsp;program&nbsp;version&nbsp;number</strong>
      </div>
      <div>69</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhh0&nbsp;&nbsp;Display&nbsp;memory&nbsp;area</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Memory&nbsp;address&nbsp;segment&nbsp;($0000..$FFFF)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>69/____0</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/D/hhh0&nbsp;&nbsp;Display&nbsp;data&nbsp;segment&nbsp;area</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Data&nbsp;segment&nbsp;offset&nbsp;($0000..$FFF0)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>69/D/___0</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/ADDR&nbsp;&nbsp;Display&nbsp;memory&nbsp;addresses</strong>
      </div>
      <div>69/ADDR</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/IO/hhhh&nbsp;&nbsp;Display&nbsp;I/O&nbsp;Port</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;I/O&nbsp;Address&nbsp;($0000..$FFFF)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>69/IO/____</div>
      <div />
      <div />
      <div />
    </div>
    <h2>System configuration</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70&nbsp;&nbsp;Display&nbsp;system&nbsp;configuration&nbsp;status</strong>
      </div>
      <div>70</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70/SAVE&nbsp;&nbsp;Save&nbsp;system&nbsp;configuration&nbsp;in&nbsp;flash</strong>
      </div>
      <div>70/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70/CNFG&nbsp;&nbsp;Return&nbsp;system&nbsp;configuration</strong>
      </div>
      <div>70/CNFG</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71&nbsp;&nbsp;Display&nbsp;TX&nbsp;settings</strong>
      </div>
      <div>71</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/TCTP/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Combiner&nbsp;TP&nbsp;alarm&nbsp;threshold</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;Combiner&nbsp;TP&nbsp;alarm&nbsp;threshold&nbsp;(0.000..5.000VDC)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>71/TCTP/_.___</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72&nbsp;&nbsp;Display&nbsp;RX&nbsp;settings</strong>
      </div>
      <div>72</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/PREGAIN/n.n&nbsp;&nbsp;Change&nbsp;RX&nbsp;pre-gain&nbsp;setting</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;pre-gain&nbsp;in&nbsp;dB&nbsp;(0.0..9.9,&nbsp;nom.&nbsp;6.0)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>72/PREGAIN/_._</div>
      <div />
      <div />
    </div>
    <h2>Factory configuration</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90&nbsp;&nbsp;Display&nbsp;factory&nbsp;configuration&nbsp;status</strong>
      </div>
      <div>90</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/FACTORYUNLOCK&nbsp;&nbsp;Select&nbsp;factory&nbsp;configuration&nbsp;unlock</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;UNLOCK</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>90/FACTORY______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90-&nbsp;&nbsp;Deselect&nbsp;factory&nbsp;configuration&nbsp;unlock</strong>
      </div>
      <div>90-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/SAVE&nbsp;&nbsp;Save&nbsp;factory&nbsp;configuration&nbsp;in&nbsp;flash</strong>
      </div>
      <div>90/SAVE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/CNFG&nbsp;&nbsp;Return&nbsp;factory&nbsp;configuration</strong>
      </div>
      <div>90/CNFG</div>
      <div />
      <div />
    </div>
    <h2>TX factory configuration</h2>
    <br />
    <div>91&nbsp;&nbsp;TX&nbsp;SETTINGS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91&nbsp;Display&nbsp;TX&nbsp;factory&nbsp;configuration</strong>
      </div>
      <div>91</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/IZEROOL/snnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Open&nbsp;Loop&nbsp;I-zero</strong>
      </div>
      <div>DAC&nbsp;full&nbsp;scale&nbsp;is&nbsp;+/-32767</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-9999..+9999</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/IZEROOL/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/QZEROOL/snnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Open&nbsp;Loop&nbsp;Q-zero</strong>
      </div>
      <div>DAC&nbsp;full&nbsp;scale&nbsp;is&nbsp;+/-32767</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-9999..+9999</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/QZEROOL/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/LOOPGAIN/nnn&nbsp;&nbsp;Change&nbsp;Loop&nbsp;Gain</strong>
      </div>
      <div>Used&nbsp;to&nbsp;reduce&nbsp;DAC&nbsp;output&nbsp;level&nbsp;at&nbsp;open&nbsp;loop</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;010..200</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/LOOPGAIN/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/QAMPL/n.nnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Q-amplitude&nbsp;balance</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.9000..1.1000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>91/QAMPL/_.____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/QPHASE/sn.nnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Q-phase&nbsp;quadrature</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;-0.2500..+0.2500</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────</div>
      <div>91/QPHASE/__.____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/PH0/nnn&nbsp;&nbsp;Select&nbsp;Cartesian&nbsp;Loop&nbsp;channel&nbsp;0&nbsp;phase&nbsp;adjust&nbsp;value
        </strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;0&nbsp;phase&nbsp;adjust&nbsp;value&nbsp;in&nbsp;degree&nbsp;(000..359)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/PH0/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/PHS/snnn&nbsp;&nbsp;Change&nbsp;Cartesian&nbsp;Loop&nbsp;channel&nbsp;phase&nbsp;step&nbsp;value
        </strong>
      </div>
      <div>Typical&nbsp;value:&nbsp;</div>
      <div>&nbsp;&nbsp;400MHz:&nbsp;+090</div>
      <div>&nbsp;&nbsp;800MHz:&nbsp;+050</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Phase&nbsp;change&nbsp;per&nbsp;channel&nbsp;in&nbsp;millidegree&nbsp;(-999..+999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>91/PHS/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/MAXPWR/nnn.nn&nbsp;&nbsp;Change&nbsp;max.&nbsp;TX&nbsp;power&nbsp;setting</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;000.10..500.00&nbsp;W</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>91/MAXPWR/___.__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/PWR/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;power&nbsp;setting</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.500..2.000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/PWR/_.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/PMOUT/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Power&nbsp;Meter&nbsp;output&nbsp;setting</strong>
      </div>
      <div>Typical&nbsp;value&nbsp;for&nbsp;&nbsp;60W&nbsp;PEP&nbsp;PA:&nbsp;1.000</div>
      <div>Typical&nbsp;value&nbsp;for&nbsp;150W&nbsp;PEP&nbsp;PA:&nbsp;2.500</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.700..3.500</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/PMOUT/_.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/PMREFL/n.nnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;Power&nbsp;Meter&nbsp;reflected&nbsp;setting</strong>
      </div>
      <div>Typical&nbsp;value&nbsp;for&nbsp;&nbsp;60W&nbsp;PEP&nbsp;PA:&nbsp;1.000</div>
      <div>Typical&nbsp;value&nbsp;for&nbsp;150W&nbsp;PEP&nbsp;PA:&nbsp;2.500</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;0.700..3.500</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>91/PMREFL/_.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91/ZERO&nbsp;&nbsp;Display&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table</strong>
      </div>
      <div>91/ZERO</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/ZERO/nnn&nbsp;&nbsp;Setup&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table&nbsp;channel&nbsp;step
        </strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;max.&nbsp;channel&nbsp;number&nbsp;divided&nbsp;by&nbsp;8</div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;400MHz&nbsp;range:&nbsp;100</div>
      <div>Normal&nbsp;value&nbsp;for&nbsp;800MHz&nbsp;range:&nbsp;200</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Number&nbsp;of&nbsp;channels&nbsp;between&nbsp;table&nbsp;entries&nbsp;(010..500)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──</div>
      <div>91/ZERO/___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/ZERO/n/snnnn/snnnn&nbsp;&nbsp;Setup&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table&nbsp;value
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Table&nbsp;entry&nbsp;position&nbsp;(0..8)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;I&nbsp;zero&nbsp;adjust&nbsp;value&nbsp;(-9999..+9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Q&nbsp;zero&nbsp;adjust&nbsp;value&nbsp;(-9999..+9999)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴────&nbsp;┴────</div>
      <div>91/ZERO/_/_____/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          91/ZERO/n/snnnn/snnnn/Q&nbsp;&nbsp;Setup&nbsp;TX&nbsp;carrier&nbsp;zero&nbsp;adjust&nbsp;table&nbsp;value&nbsp;(quiet)
        </strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Table&nbsp;entry&nbsp;position&nbsp;(0..8)</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;┌&nbsp;I&nbsp;zero&nbsp;adjust&nbsp;value&nbsp;(-9999..+9999)
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;│&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Q&nbsp;zero&nbsp;adjust&nbsp;value&nbsp;(-9999..+9999)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴&nbsp;┴────&nbsp;┴────</div>
      <div>91/ZERO/_/_____/_____/Q</div>
      <div />
      <div />
      <div />
    </div>
    <h2>Common factory configuration</h2>
    <br />
    <div>93&nbsp;&nbsp;COMMON&nbsp;SETTINGS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93&nbsp;&nbsp;Display&nbsp;common&nbsp;factory&nbsp;settings</strong>
      </div>
      <div>93</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHSTEP/nn.nnn&nbsp;&nbsp;Change&nbsp;channel&nbsp;step&nbsp;size</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Channel&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;06.250:&nbsp;6.25kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;│&nbsp;&nbsp;&nbsp;12.500:&nbsp;12.5kHz</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>93/CHSTEP/__.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHRX0/nnnnnn&nbsp;&nbsp;Change&nbsp;RX&nbsp;channel&nbsp;zero</strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;(RX&nbsp;channel&nbsp;0&nbsp;frequency)/(Channel&nbsp;step&nbsp;size)</div>
      <div>Values&nbsp;for&nbsp;12.5kHz&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;300MHz:&nbsp;024000&nbsp;</div>
      <div>&nbsp;&nbsp;350MHz:&nbsp;028000</div>
      <div>&nbsp;&nbsp;380MHz:&nbsp;030400</div>
      <div>&nbsp;&nbsp;410MHz:&nbsp;032800</div>
      <div>&nbsp;&nbsp;450MHz:&nbsp;036000</div>
      <div>&nbsp;&nbsp;805MHz:&nbsp;064400</div>
      <div>Values&nbsp;for&nbsp;6.25kHz&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;300MHz:&nbsp;048000&nbsp;</div>
      <div>&nbsp;&nbsp;350MHz:&nbsp;056000</div>
      <div>&nbsp;&nbsp;380MHz:&nbsp;060800</div>
      <div>&nbsp;&nbsp;410MHz:&nbsp;065600</div>
      <div>&nbsp;&nbsp;450MHz:&nbsp;072000</div>
      <div>&nbsp;&nbsp;805MHz:&nbsp;128800</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;RX&nbsp;channel&nbsp;0</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>93/CHRX0/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHTX0/nnnnnn&nbsp;&nbsp;Change&nbsp;TX&nbsp;channel&nbsp;zero</strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;(TX&nbsp;channel&nbsp;0&nbsp;frequency)/(Channel&nbsp;step&nbsp;size)</div>
      <div>Values&nbsp;for&nbsp;12.5kHz&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;336MHz:&nbsp;026880&nbsp;</div>
      <div>&nbsp;&nbsp;360MHz:&nbsp;028800</div>
      <div>&nbsp;&nbsp;390MHz:&nbsp;031200</div>
      <div>&nbsp;&nbsp;420MHz:&nbsp;033600</div>
      <div>&nbsp;&nbsp;460MHz:&nbsp;036800</div>
      <div>&nbsp;&nbsp;850MHz:&nbsp;068000</div>
      <div>Values&nbsp;for&nbsp;6.25kHz&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;336MHz:&nbsp;053760&nbsp;</div>
      <div>&nbsp;&nbsp;360MHz:&nbsp;057600</div>
      <div>&nbsp;&nbsp;390MHz:&nbsp;062400</div>
      <div>&nbsp;&nbsp;420MHz:&nbsp;067200</div>
      <div>&nbsp;&nbsp;460MHz:&nbsp;073600</div>
      <div>&nbsp;&nbsp;850MHz:&nbsp;136000</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;channel&nbsp;0</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>93/CHTX0/______</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHIFOFS/snnnn&nbsp;&nbsp;Change&nbsp;1st&nbsp;LO&nbsp;IF&nbsp;channel&nbsp;offset</strong>
      </div>
      <div>Value&nbsp;shall&nbsp;be&nbsp;(1st&nbsp;IF&nbsp;frequency)/(Channel&nbsp;step&nbsp;size)</div>
      <div>Typical&nbsp;value:&nbsp;-3600&nbsp;for&nbsp;-45MHz</div>
      <div>Values&nbsp;for&nbsp;45MHz&nbsp;IF,&nbsp;underlying&nbsp;1st&nbsp;LO:</div>
      <div>&nbsp;&nbsp;12.5kHz&nbsp;step&nbsp;size:&nbsp;-3600</div>
      <div>&nbsp;&nbsp;6.25kHz&nbsp;step&nbsp;size:&nbsp;-7200</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;1st&nbsp;LO&nbsp;IF&nbsp;offset&nbsp;(-9999..+9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>93/CHIFOFS/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHTCOFS/snnnn&nbsp;&nbsp;Change&nbsp;TC&nbsp;channel&nbsp;offset</strong>
      </div>
      <div>Channel&nbsp;number&nbsp;offset&nbsp;between&nbsp;TX&nbsp;and&nbsp;Combiner</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;TX&nbsp;combiner&nbsp;channel&nbsp;offset&nbsp;(-9999..+9999,&nbsp;normal&nbsp;+0000)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>93/CHTCOFS/_____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHMIN/nnnn&nbsp;&nbsp;Change&nbsp;lowest&nbsp;used&nbsp;channel&nbsp;number</strong>
      </div>
      <div>Normally&nbsp;set&nbsp;to&nbsp;0000</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Lowest&nbsp;used&nbsp;channel&nbsp;number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>93/CHMIN/____</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>93/CHMAX/nnnn&nbsp;&nbsp;Change&nbsp;highest&nbsp;used&nbsp;channel&nbsp;number</strong>
      </div>
      <div>Values&nbsp;for&nbsp;12.5kHz&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;10MHz&nbsp;bandwidth&nbsp;(400MHz):&nbsp;0800</div>
      <div>&nbsp;&nbsp;20MHz&nbsp;bandwidth&nbsp;(800MHz):&nbsp;1600</div>
      <div>Values&nbsp;for&nbsp;6.25MHz&nbsp;step&nbsp;size:</div>
      <div>&nbsp;&nbsp;10MHz&nbsp;bandwidth&nbsp;(400MHz):&nbsp;1600</div>
      <div>&nbsp;&nbsp;20MHz&nbsp;bandwidth&nbsp;(800MHz):&nbsp;3200</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Highest&nbsp;used&nbsp;channel&nbsp;number&nbsp;(0000..9999)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>93/CHMAX/____</div>
      <div />
      <div />
    </div>
    <h2>Test circuit configuration</h2>
    <br />
    <div>96&nbsp;&nbsp;TEST&nbsp;CIRCUIT&nbsp;SETTINGS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96&nbsp;&nbsp;Display&nbsp;Test&nbsp;circuit&nbsp;settings</strong>
      </div>
      <div>96</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96/DCVM/n.nnn&nbsp;&nbsp;Change&nbsp;DC&nbsp;Voltmeter&nbsp;calibration&nbsp;constant</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Calibration&nbsp;constant&nbsp;(4.700..5.300,&nbsp;nom.&nbsp;5.000)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>96/DCVM/_.___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96/THERM/snn.n&nbsp;&nbsp;Change&nbsp;Thermometer&nbsp;calibration&nbsp;offset</strong>
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Calibration&nbsp;offset&nbsp;(-99.9..+99.9,&nbsp;nom.&nbsp;+00.0)
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴────</div>
      <div>96/THERM/___._</div>
      <div />
      <div />
    </div>
    <h2>Hardware identity</h2>
    <br />
    <div>98&nbsp;&nbsp;HARDWARE&nbsp;ID</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98&nbsp;&nbsp;Display&nbsp;hardware&nbsp;ID</strong>
      </div>
      <div>98</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/TYPE&nbsp;&nbsp;Display&nbsp;type&nbsp;number</strong>
      </div>
      <div>98/TYPE</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/ITEM&nbsp;&nbsp;Display&nbsp;item&nbsp;number</strong>
      </div>
      <div>98/ITEM</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/SER&nbsp;&nbsp;Display&nbsp;serial&nbsp;number</strong>
      </div>
      <div>98/SER</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/VER&nbsp;&nbsp;Display&nbsp;version&nbsp;number</strong>
      </div>
      <div>98/VER</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/REV&nbsp;&nbsp;Display&nbsp;revision&nbsp;number</strong>
      </div>
      <div>98/REV</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/ITEM/cc..c&nbsp;&nbsp;Change&nbsp;item&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Item&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴──────────────</div>
      <div>98/ITEM/_______________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/SER/cc..c&nbsp;&nbsp;Change&nbsp;serial&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>98/SER/________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/VER/n.nn&nbsp;&nbsp;Change&nbsp;version&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Version&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴───</div>
      <div>98/VER/_.__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/REV/n&nbsp;&nbsp;Change&nbsp;revision&nbsp;number</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌&nbsp;Revision&nbsp;number&nbsp;(0..9)</div>
      <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┴</div>
      <div>98/REV/_</div>
      <div />
    </div>
    <br />
  </>
);

export default memo(TR412Page) as typeof TR412Page;
