import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useNodeIndicationQuery } from 'src/graphql/generated';

const COLORS = {
  black: '#000000',
  gray: '#c0c0c0',
  yellow: 'rgb(253, 233, 16)',
  green: 'green',
  red: 'red',
};

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const Column = styled.div`
  margin-right: 16px;
`;

const Row = styled.div`
  height: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const TitleRow = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: ${props => (!!props.center ? 'center' : 'flex-start')};
  font-weight: bold;
`;

const Indicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${props => `${props.color || COLORS.gray}`};
`;

export type NodeIndicationsProps = {
  children?: React.ReactNode | React.ReactNode[];
  nodeNumber: string;
};

const NodeIndications: React.FC<NodeIndicationsProps> = props => {
  const { nodeNumber, children } = props;

  const { data: queryResult, loading } = useNodeIndicationQuery({
    variables: { nodeNumber },
    pollInterval: 3000,
    skip: !nodeNumber,
  });

  const { bscLink = false, bscActive = false, bscAlarm = false } = (queryResult && queryResult.nodeIndication) || {};

  const getColor = useCallback(
    (value: boolean, undef: string, yes: string, no: string) => {
      if (!queryResult || !queryResult.nodeIndication) {
        return undef;
      }

      return value ? yes : no;
    },
    [queryResult],
  );

  return (
    <div>
      <Columns>
        <Column>
          <TitleRow>BSC</TitleRow>
          <Row>Link</Row>
          <Row>Active</Row>
          <Row>Alarm</Row>
        </Column>
        <Column>
          <TitleRow center>1</TitleRow>
          <Row>
            <Indicator color={getColor(bscLink, COLORS.black, COLORS.yellow, COLORS.black)} />
          </Row>
          <Row>
            <Indicator color={getColor(bscActive, COLORS.black, COLORS.green, COLORS.black)} />
          </Row>
          <Row>
            <Indicator color={getColor(bscAlarm, COLORS.black, COLORS.red, COLORS.black)} />
          </Row>
        </Column>
        <div>{children}</div>
      </Columns>
      <Columns>
        <Column>
          <TitleRow>TR</TitleRow>
          <Row>Link</Row>
          <Row>Active</Row>
          <Row>TX</Row>
          <Row>Alarm</Row>
        </Column>
        {['11', '12', '13', '14', '15', '16', '17', '18'].map(key => {
          let linkColor = COLORS.gray;
          let activeColor = COLORS.gray;
          let txColor = COLORS.gray;
          let alarmColor = COLORS.gray;

          if (queryResult && queryResult.nodeIndication) {
            const tr = queryResult.nodeIndication.tr.find((t: any) => t.key === key);
            if (tr) {
              const { link, active, tx, alarm } = tr;
              linkColor = getColor(link, COLORS.black, COLORS.yellow, COLORS.black);
              activeColor = getColor(active, COLORS.black, COLORS.green, COLORS.black);
              txColor = getColor(tx, COLORS.black, COLORS.green, COLORS.black);
              alarmColor = getColor(alarm, COLORS.black, COLORS.red, COLORS.black);
            }
          }

          return (
            <Column>
              <TitleRow center>{key}</TitleRow>
              <Row>
                <Indicator color={linkColor} />
              </Row>
              <Row>
                <Indicator color={activeColor} />
              </Row>
              <Row>
                <Indicator color={txColor} />
              </Row>
              <Row>
                <Indicator color={alarmColor} />
              </Row>
            </Column>
          );
        })}
      </Columns>
      <Columns>
        <Column>
          <TitleRow>PS</TitleRow>
          <Row>Link</Row>
          <Row>Mains</Row>
          <Row>Charge</Row>
          <Row>Disch.</Row>
          <Row>Alarm</Row>
        </Column>
        {['11', '12', '13', '14', '15', '16', '17'].map(key => {
          let linkColor = COLORS.gray;
          let mainsColor = COLORS.gray;
          let chargeColor = COLORS.gray;
          let dischargeColor = COLORS.gray;
          let alarmColor = COLORS.gray;

          if (queryResult && queryResult.nodeIndication) {
            const ps = queryResult.nodeIndication.ps.find((t: any) => t.key === key);
            if (ps) {
              const { link, mains, charge, discharge, alarm } = ps;
              linkColor = getColor(link, COLORS.black, COLORS.yellow, COLORS.black);
              mainsColor = getColor(mains, COLORS.black, COLORS.green, COLORS.black);
              chargeColor = getColor(charge, COLORS.black, COLORS.yellow, COLORS.black);
              dischargeColor = getColor(discharge, COLORS.black, COLORS.yellow, COLORS.black);
              alarmColor = getColor(alarm, COLORS.black, COLORS.red, COLORS.black);
            }
          }
          return (
            <Column>
              <TitleRow center>{key}</TitleRow>
              <Row>
                <Indicator color={linkColor} />
              </Row>
              <Row>
                <Indicator color={mainsColor} />
              </Row>
              <Row>
                <Indicator color={chargeColor} />
              </Row>
              <Row>
                <Indicator color={dischargeColor} />
              </Row>
              <Row>
                <Indicator color={alarmColor} />
              </Row>
            </Column>
          );
        })}
      </Columns>
    </div>
  );
};

NodeIndications.displayName = 'NodeIndications';

export default memo(NodeIndications);
