/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { SearchOutlined } from '@ant-design/icons';
import { useApolloError, logger } from '@fjedi/graphql-react-components';
import { FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setHTMLTitle } from 'src/components/service-routes';
import Button from 'src/components/ui-kit/buttons';
import DeleteButton from 'src/components/ui-kit/buttons/delete';
import GlassButton from 'src/components/ui-kit/buttons/glass';
import { ContentCard } from 'src/components/ui-kit/card/main-content-card';
import CustomTable, { StyledSpinner as TableSpinner, StyledTable } from 'src/components/ui-kit/table';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';
import { Title } from 'src/components/ui-kit/typography';
import {
  NodeSubscriber,
  NodeSubscriberGroup,
  NodeSubscriberPartial,
  NodeSubscribersQueryVariables,
  useAddSubscriberToGroupMutation,
  useGetNodeSubscriberGroupQuery,
  useNodeSubscriberQuery,
  useRemoveSubscriberFromGroupMutation,
} from 'src/graphql/generated';
import getNodeSubscribersQuery from 'src/graphql/queries/get-node-subscribers.graphql';
import nodeSubscriberChangedSubscription from 'src/graphql/subscriptions/node-subscriber-changed.graphql';
import styled from 'styled-components';
import { time } from 'src/helpers/time';
import ChangeGroupNameInput from 'src/components/routes/private/dgna/change-group-name-input';
import { numberSorter, ssiKindOptions } from '../subscribers/tabs/drawer-helpers';

// ! SUB FOR TESTING: 1789577;

const Container = styled(ContentCard)`
  min-width: calc(100% - 1rem) !important;
  --title-height: 2rem;

  & > .ant-card-body {
    flex-wrap: wrap;
    position: relative;

    ${Title} {
      flex: 0 1 var(--title-height);
    }

    .ant-table-wrapper {
      flex: 1 0 calc(100% - var(--title-height) - 0.875rem);

      &:first-of-type {
        max-width: calc(50% - 1rem);
      }

      tbody.ant-table-tbody > tr.ant-table-row > td.ant-table-cell,
      thead.ant-table-thead > tr > th.ant-table-cell {
        &:last-child {
          padding: 0 0.5rem;
        }

        &.ant-table-selection-column {
          //padding-left: 0;
          //padding-right: 0;
          //text-align: center;

          //label.ant-checkbox-wrapper {
          //  margin: auto -0.25rem -0.125rem auto;
          //}
        }
      }

      & + .details {
        position: fixed;
        top: 10rem;
        right: 4rem;
        width: calc(50% - 7.5rem);

        h4 {
          padding-bottom: 13px;
        }

        .actions {
          display: flex;
          padding-bottom: 1rem;
          justify-content: flex-end;

          & > .ant-btn {
            &:not(:last-of-type) {
              margin-right: 1rem;
            }
          }
          & > :not(.ant-btn) {
            flex-grow: 1;
          }
        }

        & > .ant-table-wrapper {
          width: 100%;

          label.ant-checkbox-wrapper {
            .ant-checkbox-inner {
              width: 2rem;
              height: 2rem;

              ::after {
                margin-left: 0.25rem;
              }
            }

            &::after,
            & > .ant-checkbox,
            .ant-checkbox-inner,
            .ant-checkbox-checked::after {
              border-radius: 0.5rem;
            }
          }

          td.ant-table-cell:last-of-type {
            text-align: center !important;
          }
        }
      }
    }
  }
`;

const SmallButton = styled(Button).attrs({ type: 'primary', htmlType: 'button', size: 'small' })`
  border: 0;
`;

type GroupData = NodeSubscriberGroup & { id: string };
type GroupMember = NodeSubscriberGroup['groupIncludeTable'][number];

const DGNAPage: FC = () => {
  const { t } = useTranslation();

  const [selectedSubscriberId, setSelectedSubscriberId] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedGroupData, setSelectedGroupData] = useState<NodeSubscriber>();
  const attachedSubscribers = useMemo(() => selectedGroupData?.attachedSubscribers ?? [], [selectedGroupData]);

  const onError = useApolloError();
  const { data, loading, refetch } = useNodeSubscriberQuery({
    variables: { id: selectedSubscriberId! },
    skip: !selectedSubscriberId,
    onError,
  });
  const reloadPageContent = useCallback(() => {
    setSelectedRowKeys([]);
    refetch()
      .then(d => {
        setSelectedGroupData(d.data.nodeSubscriber);
      })
      .catch(logger);
  }, [refetch]);
  const [removeSubFromGroup, { loading: updating }] = useRemoveSubscriberFromGroupMutation({
    onError,
  });
  const [addSubToGroup, { loading: adding }] = useAddSubscriberToGroupMutation({
    onError,
  });

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (selectedKeys: React.Key[]) => {
        setSelectedRowKeys(selectedKeys);
      },
    }),
    [selectedRowKeys],
  );

  const onView = useCallback(
    (nodeSubscriber: NodeSubscriber) => () => {
      if (nodeSubscriber) {
        setSelectedSubscriberId(nodeSubscriber.id);
      }
    },
    [],
  );

  useEffect(() => {
    if (selectedSubscriberId && data?.nodeSubscriber) {
      setSelectedGroupData(data.nodeSubscriber);
    }
  }, [selectedSubscriberId, data]);

  const onAdd = useCallback(
    (nodeSubscriber: NodeSubscriber) => () => {
      if (selectedGroupData) {
        const updatedMembers = [...attachedSubscribers];
        updatedMembers.push(nodeSubscriber as NodeSubscriberPartial);
        setSelectedGroupData(d => {
          if (d) {
            return { ...d, attachedSubscribers: updatedMembers };
          }
          return undefined;
        });
      }
    },
    [selectedGroupData, attachedSubscribers],
  );

  const onDelete = useCallback(
    (nodeSubscriber: NodeSubscriberPartial) => () => {
      const updatedMembers = attachedSubscribers.filter(({ ssi }) => ssi !== nodeSubscriber.ssi) ?? [];
      setSelectedGroupData(d => {
        if (d) {
          return { ...d, attachedSubscribers: updatedMembers };
        }
        return undefined;
      });
    },
    [attachedSubscribers],
  );

  const filterIcon = useMemo(
    () => (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    [],
  );

  const filterDropdown = useMemo(() => (filterProps: FilterDropdownProps) => <FilterDropdown {...filterProps} />, []);

  const columns = useMemo(
    () => [
      {
        title: t('SSI Type'),
        key: 'ssiKind',
        dataIndex: 'ssiKind',
        sorterKey: 'ssiKind',
        columnKey: 'ssiKind',
        filters: ssiKindOptions,
        filterMultiple: false,
        width: 180,
        sorter: numberSorter,
        render: (ssiType: NodeSubscriber['ssiKind']) =>
          ssiKindOptions.find(({ value }) => value === ssiType)?.text ?? ssiType,
      },
      {
        title: t('SSI'),
        key: 'ssi',
        dataIndex: 'ssi',
        sorterKey: 'ssi',
        columnKey: 'ssi',
        width: 180,
        sorter: numberSorter,
        filterDropdown,
        filterIcon,
      },
      {
        title: t('Description'),
        key: 'description',
        dataIndex: 'description',
        sorterKey: 'description',
        columnKey: 'description',
        width: 360,
        filterDropdown,
        filterIcon,
        render(description: string) {
          return description || 'Не указано';
        },
      },
      {
        dataIndex: 'group',
        width: 90,
        style: {
          textAlign: 'center',
          maxWidth: '90px',
          minWidth: '90px',
          width: '90px',
        },
        render: (group: NodeSubscriberGroup, nodeSubscriber: NodeSubscriber) => {
          if (group) {
            return <GlassButton onClick={onView(nodeSubscriber)} />;
          }
          if (!selectedGroupData) {
            return null;
          }
          if (attachedSubscribers?.length) {
            const isAssociatedWithCurrentGroup = (attachedSubscribers ?? []).some(s => s.ssi === nodeSubscriber.ssi);
            if (isAssociatedWithCurrentGroup) {
              return null;
            }
          }
          const hasWaitingCommand = time().diff((nodeSubscriber.lastCommandId ?? 0) * 1000, 'minutes') <= 3;
          return (
            <SmallButton disabled={hasWaitingCommand} onClick={onAdd(nodeSubscriber)}>
              {t('Add')}
            </SmallButton>
          );
        },
      },
    ],
    [filterDropdown, filterIcon, onAdd, onView, t, selectedGroupData, attachedSubscribers],
  );

  const groupColumns = useMemo(
    () => [
      {
        title: t('Номер'),
        key: 'ssi',
        dataIndex: 'ssi',
        width: 180,
      },
      {
        title: t('Description'),
        key: 'description',
        dataIndex: 'description',
        width: 360,
        render: (description: string) => description ?? 'Не указано',
      },
      {
        dataIndex: 'userNo',
        key: 'userNo',
        width: 90,
        render: (_: any, subscriber: any) => <DeleteButton onClick={onDelete(subscriber)} />,
      },
    ],
    [onDelete, t],
  );

  const groupPaginationConfig = useMemo(
    () =>
      selectedGroupData
        ? ({
            total: selectedGroupData.attachedSubscribers?.length,
            responsive: true,
            size: 'small',
            pageSize: 10,
          } as TablePaginationConfig)
        : false,
    [selectedGroupData],
  );

  const handleDGNAAccept = useCallback(() => {
    if (!selectedGroupData || !data?.nodeSubscriber) {
      return;
    }
    const addedSubscribers = attachedSubscribers.filter(
      s => !data!.nodeSubscriber!.attachedSubscribers!.some(({ ssi }) => ssi === s.ssi),
    );
    const removedSubscribers = data!.nodeSubscriber!.attachedSubscribers!.filter(
      s => !attachedSubscribers.some(({ ssi }) => ssi === s.ssi),
    );
    Promise.all([
      ...addedSubscribers.map(s =>
        addSubToGroup({
          variables: { input: { groupId: selectedGroupData.id, subscriberId: s.id } },
        }),
      ),
      ...removedSubscribers.map(s =>
        removeSubFromGroup({
          variables: { input: { groupId: selectedGroupData.id, subscriberId: s.id } },
        }),
      ),
    ]).catch(console.error);
  }, [addSubToGroup, removeSubFromGroup, selectedGroupData, attachedSubscribers, data]);

  const handleDGNARemove = useCallback(() => {
    const updatedMembers = attachedSubscribers.filter(({ ssi }) => !selectedRowKeys.includes(ssi)) ?? [];
    setSelectedGroupData(d => {
      if (d) {
        return { ...d, attachedSubscribers: updatedMembers };
      }
      return undefined;
    });
  }, [selectedRowKeys, attachedSubscribers]);

  const subscribersTableQueryVariables: NodeSubscribersQueryVariables = useMemo(
    () => ({
      filter: {
        isOnline: true,
      },
    }),
    [],
  );

  return (
    <Container>
      {setHTMLTitle(t('DGNA'))}
      <Title level={4}>{t('Node Subscribers')}</Title>
      <CustomTable
        rowKey="id"
        dataType="NodeSubscriber"
        query={getNodeSubscribersQuery}
        variables={subscribersTableQueryVariables}
        subscriptionQueries={[nodeSubscriberChangedSubscription]}
        columns={columns}
        pageSize={10}
        pagination
      />
      {!!selectedGroupData && (
        <section className="details">
          <Title level={4}>
            {selectedGroupData?.description
              ? `${selectedGroupData?.ssi} (${selectedGroupData?.description})`
              : t('DGNA Group')}
          </Title>
          {!!selectedGroupData?.group && (
            <ChangeGroupNameInput
              value={selectedGroupData?.group?.groupText ?? ''}
              subscriberId={selectedGroupData.id}
            />
          )}
          <div className="actions">
            <SmallButton onClick={reloadPageContent}>{t('Обновить список')}</SmallButton>

            <div />
            <SmallButton onClick={handleDGNARemove}>{t('Remove')}</SmallButton>
            <SmallButton onClick={handleDGNAAccept} loading={updating}>
              {t('Apply')}
            </SmallButton>
          </div>
          {loading ? (
            <TableSpinner spinning={loading} />
          ) : (
            <StyledTable
              size="small"
              rowKey="ssi"
              dataSource={attachedSubscribers}
              columns={groupColumns}
              pagination={groupPaginationConfig}
              rowSelection={rowSelection}
              loading={loading}
              bordered
            />
          )}
        </section>
      )}
    </Container>
  );
};

export default memo(DGNAPage) as typeof DGNAPage;
