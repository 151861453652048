import React, { FC, memo } from 'react';

const PS411Page: FC = () => (
  <>
    <h1>PS411 Power Supply ver. 1.00</h1>
    <br />
    <br />
    <h2>Functional Description</h2>
    <br />
    <br />
    <div>PS411&nbsp;FUNCTIONAL&nbsp;DESCRIPTION</div>
    <br />
    <div>
      All&nbsp;test&nbsp;commands&nbsp;for&nbsp;the&nbsp;PS411&nbsp;Power&nbsp;Supply&nbsp;consist&nbsp;of&nbsp;2-digits&nbsp;
    </div>
    <div>
      in&nbsp;the&nbsp;range&nbsp;00&nbsp;to&nbsp;99&nbsp;with&nbsp;an&nbsp;optionally&nbsp;parameter.&nbsp;Commands&nbsp;
    </div>
    <div>
      without&nbsp;parameters&nbsp;are&nbsp;display-only&nbsp;commands&nbsp;and&nbsp;will&nbsp;not&nbsp;make&nbsp;
    </div>
    <div>any&nbsp;change.</div>
    <br />
    <div>
      If&nbsp;the&nbsp;first&nbsp;two&nbsp;characters&nbsp;are&nbsp;not&nbsp;representing&nbsp;a&nbsp;valid&nbsp;number&nbsp;in&nbsp;
    </div>
    <div>the&nbsp;00&nbsp;to&nbsp;99&nbsp;range,&nbsp;an&nbsp;error&nbsp;message&nbsp;will&nbsp;be&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;PS411&nbsp;command
    </div>
    <br />
    <div>
      This&nbsp;is&nbsp;however&nbsp;not&nbsp;the&nbsp;case&nbsp;if&nbsp;the&nbsp;first&nbsp;character&nbsp;is&nbsp;a&nbsp;+&nbsp;,&nbsp;which&nbsp;are&nbsp;
    </div>
    <div>
      reserved&nbsp;for&nbsp;commands&nbsp;to&nbsp;the&nbsp;test&nbsp;box.&nbsp;In&nbsp;this&nbsp;case&nbsp;no&nbsp;response&nbsp;will&nbsp;be&nbsp;
    </div>
    <div>sent.</div>
    <br />
    <div>
      If&nbsp;an&nbsp;unimplemented&nbsp;command&nbsp;is&nbsp;made,&nbsp;the&nbsp;following&nbsp;response&nbsp;is&nbsp;
    </div>
    <div>displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unimplemented&nbsp;PS411&nbsp;command
    </div>
    <br />
    <div>PARAMETERS</div>
    <br />
    <div>Two&nbsp;types&nbsp;of&nbsp;parameters&nbsp;are&nbsp;used.</div>
    <br />
    <div>
      Generally&nbsp;+&nbsp;is&nbsp;used&nbsp;as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;on,&nbsp;enable&nbsp;or&nbsp;step&nbsp;up&nbsp;and&nbsp;-&nbsp;is&nbsp;used
    </div>
    <div>as&nbsp;parameter&nbsp;to&nbsp;turn&nbsp;off,&nbsp;disable&nbsp;or&nbsp;step&nbsp;down.</div>
    <br />
    <div>
      For&nbsp;more&nbsp;complex&nbsp;functions,&nbsp;a&nbsp;/&nbsp;is&nbsp;used&nbsp;as&nbsp;separator&nbsp;after&nbsp;the&nbsp;command
    </div>
    <div>
      number&nbsp;followed&nbsp;by&nbsp;the&nbsp;necessary&nbsp;number&nbsp;of&nbsp;characters&nbsp;for&nbsp;the&nbsp;actual
    </div>
    <div>function.</div>
    <br />
    <div>
      If&nbsp;the&nbsp;parameter&nbsp;is&nbsp;not&nbsp;valid&nbsp;for&nbsp;the&nbsp;actual&nbsp;command,&nbsp;the&nbsp;following&nbsp;response
    </div>
    <div>is&nbsp;displayed:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invalid&nbsp;parameter
    </div>
    <br />
    <div>SETTINGS</div>
    <br />
    <div>
      Calibration&nbsp;and&nbsp;system&nbsp;parameters&nbsp;are&nbsp;stored&nbsp;residently&nbsp;in&nbsp;the&nbsp;micro-
    </div>
    <div>
      processors&nbsp;built-in&nbsp;EEPROM.&nbsp;If&nbsp;the&nbsp;programming&nbsp;succeeded,&nbsp;the&nbsp;following
    </div>
    <div>response&nbsp;is&nbsp;displayed:</div>
    <br />
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setting&nbsp;stored&nbsp;in&nbsp;EEPROM
    </div>
    <br />
    <div>
      If&nbsp;a&nbsp;failure&nbsp;during&nbsp;programming&nbsp;is&nbsp;detected,&nbsp;the&nbsp;following&nbsp;response&nbsp;is
    </div>
    <div>displayed:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EEPROM&nbsp;programming&nbsp;error
    </div>
    <br />
    <div>
      Warning:&nbsp;The&nbsp;any&nbsp;EEPROM&nbsp;byte&nbsp;is&nbsp;only&nbsp;guaranteed&nbsp;accept&nbsp;erasure&nbsp;and&nbsp;reprogram-
    </div>
    <div>
      ming&nbsp;up&nbsp;to&nbsp;10.000&nbsp;times.&nbsp;Do&nbsp;not&nbsp;use&nbsp;the&nbsp;O&amp;M&nbsp;Continuous&nbsp;and&nbsp;Repeat&nbsp;commands
    </div>
    <div>here.</div>
    <br />
    <div>*&nbsp;&nbsp;Functional&nbsp;description</div>
    <br />
    <h2>Address commands</h2>
    <br />
    <div>ADDRESS&nbsp;COMMANDS</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Address:&nbsp;nc</div>
    <br />
    <div>Description:</div>
    <div>The&nbsp;address&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;address&nbsp;the&nbsp;PS411</div>
    <br />
    <div>
      The&nbsp;'A'&nbsp;command&nbsp;returns&nbsp;the&nbsp;PS411&nbsp;address&nbsp;if&nbsp;the&nbsp;unit&nbsp;has&nbsp;previously&nbsp;
    </div>
    <div>been&nbsp;selected&nbsp;with&nbsp;a&nbsp;Anc&nbsp;command.</div>
    <br />
    <div>
      The&nbsp;'Anc'&nbsp;command&nbsp;selects&nbsp;the&nbsp;PS411&nbsp;if&nbsp;nc&nbsp;both&nbsp;matches&nbsp;the&nbsp;address&nbsp;
    </div>
    <div>input&nbsp;pins.&nbsp;If&nbsp;match&nbsp;it&nbsp;returns&nbsp;the&nbsp;address&nbsp;string.</div>
    <br />
    <div>
      The&nbsp;'Anc'&nbsp;command&nbsp;deselects&nbsp;the&nbsp;PS411&nbsp;if&nbsp;nc&nbsp;does&nbsp;not&nbsp;match&nbsp;the&nbsp;address
    </div>
    <div>input&nbsp;pins.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A&nbsp;&nbsp;Display&nbsp;address</strong>
      </div>
      <div>A</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>Anc&nbsp;&nbsp;Select&nbsp;address</strong>
      </div>
      <div>&nbsp;┌&nbsp;Cassette&nbsp;number&nbsp;(1..4)</div>
      <div>&nbsp;│┌&nbsp;Position&nbsp;number&nbsp;(a..g&nbsp;for&nbsp;posotion&nbsp;x1..x7)</div>
      <div>&nbsp;┴┴</div>
      <div>A__</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>A++&nbsp;&nbsp;Forced&nbsp;address&nbsp;select</strong>
      </div>
      <div>Used&nbsp;to&nbsp;force&nbsp;UART&nbsp;address&nbsp;selection&nbsp;at&nbsp;factory&nbsp;test</div>
      <div>NB:&nbsp;This&nbsp;command&nbsp;will&nbsp;not&nbsp;pass&nbsp;the&nbsp;BSC</div>
      <div>A++</div>
      <div />
      <div />
    </div>
    <h2>General commands</h2>
    <br />
    <br />
    <div>00&nbsp;&nbsp;DISPLAY&nbsp;SOFTWARE&nbsp;VERSION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;DAMM&nbsp;PS411&nbsp;ver.&nbsp;1.00</div>
    <br />
    <div>Description:</div>
    <div>Displayes&nbsp;the&nbsp;software&nbsp;version&nbsp;number&nbsp;of&nbsp;the&nbsp;EPROM.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>00&nbsp;&nbsp;Display&nbsp;software&nbsp;version&nbsp;number</strong>
      </div>
      <div>00</div>
      <div />
      <div />
    </div>
    <div>02&nbsp;&nbsp;RESET</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;reset&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;bring&nbsp;the&nbsp;PS411&nbsp;back&nbsp;to&nbsp;a&nbsp;known&nbsp;state.
    </div>
    <br />
    <div>
      The&nbsp;02+&nbsp;software&nbsp;reset&nbsp;command&nbsp;forces&nbsp;the&nbsp;processor&nbsp;to&nbsp;start&nbsp;executing&nbsp;codes
    </div>
    <div>
      from&nbsp;the&nbsp;power-on&nbsp;reset&nbsp;entry&nbsp;point.&nbsp;Complete&nbsp;initialization&nbsp;is&nbsp;made,&nbsp;except
    </div>
    <div>that&nbsp;blocking&nbsp;is&nbsp;maintained.</div>
    <br />
    <div>
      The&nbsp;02++&nbsp;hardware&nbsp;reset&nbsp;command&nbsp;will&nbsp;pull&nbsp;the&nbsp;CPU&nbsp;reset&nbsp;output&nbsp;low&nbsp;and
    </div>
    <div>
      make&nbsp;full&nbsp;hardware&nbsp;reset.&nbsp;Note,&nbsp;that&nbsp;blocking&nbsp;will&nbsp;be&nbsp;off&nbsp;after&nbsp;hardware
    </div>
    <div>reset.</div>
    <br />
    <div>
      The&nbsp;hardware&nbsp;reset&nbsp;is&nbsp;made&nbsp;by&nbsp;stopping&nbsp;the&nbsp;trigging&nbsp;of&nbsp;the&nbsp;watchdog.
    </div>
    <div>
      Therefore&nbsp;0.5&nbsp;sec.&nbsp;will&nbsp;elapse&nbsp;from&nbsp;the&nbsp;command&nbsp;until&nbsp;reset&nbsp;is&nbsp;made.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>02</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02+&nbsp;&nbsp;Execute&nbsp;software&nbsp;reset</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>02+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>02++&nbsp;&nbsp;Execute&nbsp;hardware&nbsp;reset</strong>
      </div>
      <div>Blocking&nbsp;necessary</div>
      <div />
      <div>02++</div>
      <div />
    </div>
    <div>03&nbsp;ALARM</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03&nbsp;&nbsp;Display&nbsp;alarm&nbsp;status</strong>
      </div>
      <div>03</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>03/C&nbsp;&nbsp;Alarm&nbsp;reset</strong>
      </div>
      <div>03/C</div>
      <div />
      <div />
    </div>
    <h2>Output commands</h2>
    <br />
    <br />
    <div>10&nbsp;&nbsp;DISPLAY&nbsp;OUTPUT&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>10&nbsp;Display&nbsp;output&nbsp;values</strong>
      </div>
      <div>10</div>
      <div />
      <div />
    </div>
    <div>11&nbsp;&nbsp;14V&nbsp;OUTPUT&nbsp;CONTROL</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11&nbsp;Display&nbsp;14V&nbsp;output&nbsp;selection</strong>
      </div>
      <div>11</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11-&nbsp;Turn&nbsp;off&nbsp;14V&nbsp;output</strong>
      </div>
      <div>11-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>11+&nbsp;Turn&nbsp;on&nbsp;14V&nbsp;output</strong>
      </div>
      <div>11+</div>
      <div />
      <div />
    </div>
    <div>12&nbsp;&nbsp;26V&nbsp;OUTPUT&nbsp;CONTROL</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12&nbsp;Display&nbsp;26V&nbsp;output&nbsp;selection</strong>
      </div>
      <div>12</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12-&nbsp;Turn&nbsp;off&nbsp;26V&nbsp;output</strong>
      </div>
      <div>12-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>12+&nbsp;Turn&nbsp;on&nbsp;26V&nbsp;output</strong>
      </div>
      <div>12+</div>
      <div />
    </div>
    <h2>Rectifier commands</h2>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>20&nbsp;Display&nbsp;Rectifier&nbsp;status</strong>
      </div>
      <div>20</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21&nbsp;Display&nbsp;Rectifier&nbsp;selection</strong>
      </div>
      <div>21</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21-&nbsp;Turn&nbsp;off&nbsp;Rectifier</strong>
      </div>
      <div>21-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>21+&nbsp;Turn&nbsp;on&nbsp;Rectifier</strong>
      </div>
      <div>21+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22&nbsp;Display&nbsp;Rectifier&nbsp;manual&nbsp;voltage&nbsp;control&nbsp;selection</strong>
      </div>
      <div>22</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22+&nbsp;Select&nbsp;Rectifer&nbsp;manual&nbsp;voltage&nbsp;control</strong>
      </div>
      <div>22+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>22-&nbsp;Deselect&nbsp;Rectifier&nbsp;manual&nbsp;voltage&nbsp;control</strong>
      </div>
      <div>22-</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23&nbsp;Display&nbsp;Rectifier&nbsp;output&nbsp;voltage&nbsp;selection</strong>
      </div>
      <div>23</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>23/nn.n&nbsp;Change&nbsp;Rectifier&nbsp;output&nbsp;voltage</strong>
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Rectifier&nbsp;output&nbsp;voltage&nbsp;(46.0..58.7V)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>23/__._</div>
      <div />
      <div />
    </div>
    <h2>Battery charging commands</h2>
    <br />
    <div>30&nbsp;&nbsp;BATTERY&nbsp;STATUS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>30&nbsp;Display&nbsp;battery&nbsp;status</strong>
      </div>
      <div>30</div>
      <div />
      <div />
    </div>
    <h2>Test commands</h2>
    <br />
    <div>60&nbsp;&nbsp;TEMPERATURE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>60&nbsp;&nbsp;Display&nbsp;temperature</strong>
      </div>
      <div>60</div>
      <div />
    </div>
    <div>63&nbsp;&nbsp;TIMER</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>63&nbsp;&nbsp;Display&nbsp;timer</strong>
      </div>
      <div>63</div>
      <div />
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65&nbsp;&nbsp;Display&nbsp;command&nbsp;function</strong>
      </div>
      <div>65</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/N&nbsp;&nbsp;Display&nbsp;next&nbsp;message</strong>
      </div>
      <div>65/N</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/C&nbsp;&nbsp;Clear&nbsp;test&nbsp;queue</strong>
      </div>
      <div>65/C</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/hhhh..hh&nbsp;&nbsp;Send&nbsp;message&nbsp;to&nbsp;BSC</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Message&nbsp;in&nbsp;hex</div>
      <div>
        &nbsp;&nbsp;&nbsp;│1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7&nbsp;8&nbsp;9&nbsp;0&nbsp;1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7&nbsp;8&nbsp;9&nbsp;0&nbsp;1&nbsp;2&nbsp;3&nbsp;4&nbsp;5&nbsp;6&nbsp;7&nbsp;8&nbsp;9&nbsp;0&nbsp;1&nbsp;2&nbsp;3&nbsp;4&nbsp;5
      </div>
      <div>&nbsp;&nbsp;&nbsp;├┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐┌┐</div>
      <div>65/______________________________________________________________________</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>65/L&nbsp;&nbsp;Enable&nbsp;loop-back&nbsp;reception</strong>
      </div>
      <div>65/L</div>
      <div />
      <div />
    </div>
    <div>67&nbsp;&nbsp;DISPLAY&nbsp;BOARD&nbsp;ADDRESS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>67&nbsp;&nbsp;Display&nbsp;Board&nbsp;Address</strong>
      </div>
      <div>67</div>
      <div />
      <div />
    </div>
    <div>69&nbsp;&nbsp;READ&nbsp;MEMORY&nbsp;LOCATION</div>
    <br />
    <div>
      Response:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remark:
    </div>
    <div>
      1)&nbsp;$hhhh=$hh&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Normal&nbsp;response
    </div>
    <div>
      2)&nbsp;$hhhh=Protected&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1000&nbsp;to&nbsp;$1FFF&nbsp;address&nbsp;response
    </div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;command&nbsp;displays&nbsp;the&nbsp;content&nbsp;of&nbsp;a&nbsp;memory&nbsp;location.&nbsp;The&nbsp;I/O&nbsp;area
    </div>
    <div>
      $1000&nbsp;to&nbsp;$1FFF&nbsp;is&nbsp;protected&nbsp;in&nbsp;order&nbsp;not&nbsp;to&nbsp;disturb&nbsp;read&nbsp;handshake&nbsp;on&nbsp;I/O
    </div>
    <div>ports.</div>
    <br />
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69&nbsp;&nbsp;Display&nbsp;last&nbsp;selected&nbsp;memory&nbsp;address</strong>
      </div>
      <div>69</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>69/hhhh&nbsp;&nbsp;Display&nbsp;memory&nbsp;address&nbsp;hhhh</strong>
      </div>
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Memory&nbsp;address&nbsp;($0000..$0FFF&nbsp;and&nbsp;$2000..$FFFF)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>69/____</div>
      <div />
      <div />
    </div>
    <h2>System settings</h2>
    <br />
    <div>70&nbsp;SYSTEM&nbsp;SETTINGS</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>70&nbsp;&nbsp;Display&nbsp;system&nbsp;settings</strong>
      </div>
      <div>70</div>
      <div />
      <div />
    </div>
    <div>71&nbsp;&nbsp;AC&nbsp;INPUT</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71&nbsp;&nbsp;Display&nbsp;AC&nbsp;input&nbsp;selection</strong>
      </div>
      <div>71</div>
      <div />
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71s&nbsp;&nbsp;Change&nbsp;AC&nbsp;input&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;┌&nbsp;+:&nbsp;Select&nbsp;AC&nbsp;input</div>
      <div>&nbsp;&nbsp;│&nbsp;-:&nbsp;Deselect&nbsp;AC&nbsp;input&nbsp;</div>
      <div>&nbsp;&nbsp;┴</div>
      <div>71_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>71/EE++++&nbsp;&nbsp;Save&nbsp;AC&nbsp;input&nbsp;selection&nbsp;in&nbsp;EEPROM</strong>
      </div>
      <div>71/EE++++</div>
      <div />
      <div />
    </div>
    <div>72&nbsp;&nbsp;BATTERY&nbsp;CHARGING</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72&nbsp;&nbsp;Display&nbsp;Battery&nbsp;charging&nbsp;selection</strong>
      </div>
      <div>72</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72s&nbsp;&nbsp;Change&nbsp;Battery&nbsp;charging&nbsp;selection</strong>
      </div>
      <div>&nbsp;&nbsp;┌&nbsp;+:&nbsp;Select&nbsp;Battery&nbsp;charging</div>
      <div>&nbsp;&nbsp;│&nbsp;-:&nbsp;Deselect&nbsp;Battery&nbsp;charging&nbsp;</div>
      <div>&nbsp;&nbsp;┴</div>
      <div>72_</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>72/EE++++&nbsp;&nbsp;Save&nbsp;Battery&nbsp;charging&nbsp;selection&nbsp;in&nbsp;EEPROM</strong>
      </div>
      <div>72/EE++++</div>
      <div />
    </div>
    <div>73&nbsp;&nbsp;BATTERY&nbsp;CHARGING&nbsp;VOLTAGE</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>73&nbsp;&nbsp;Display&nbsp;Battery&nbsp;charging&nbsp;voltage&nbsp;selection</strong>
      </div>
      <div>73</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>73/nn.n&nbsp;&nbsp;Select&nbsp;Battery&nbsp;charging&nbsp;voltage</strong>
      </div>
      <div>Battery&nbsp;charging&nbsp;voltage&nbsp;at&nbsp;25&nbsp;Celsius</div>
      <div>Shall&nbsp;be&nbsp;selected&nbsp;according&nbsp;to&nbsp;the&nbsp;battery&nbsp;recommendation</div>
      <div>Typical&nbsp;charging&nbsp;voltage&nbsp;is&nbsp;54.OV&nbsp;(2.25V&nbsp;pr.&nbsp;cell)</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Charging&nbsp;voltage&nbsp;at&nbsp;25&nbsp;Celsius&nbsp;(52.8..55.2)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>73/__._</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>73/EE++++&nbsp;&nbsp;Save&nbsp;Battery&nbsp;charging&nbsp;voltage&nbsp;in&nbsp;EEPROM</strong>
      </div>
      <div>73/EE++++</div>
      <div />
    </div>
    <div>74&nbsp;&nbsp;BATTERY&nbsp;CHARGING&nbsp;TEMPERATURE&nbsp;COMPENSATION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>74&nbsp;&nbsp;Display&nbsp;Battery&nbsp;charging&nbsp;temperature&nbsp;compensation</strong>
      </div>
      <div>74</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>74/-nnn&nbsp;&nbsp;Select&nbsp;Battery&nbsp;charging&nbsp;temperature&nbsp;compensation</strong>
      </div>
      <div>Shall&nbsp;be&nbsp;selected&nbsp;according&nbsp;to&nbsp;the&nbsp;battery&nbsp;recommendation</div>
      <div>
        Typical&nbsp;temperature&nbsp;compensation&nbsp;is&nbsp;-72&nbsp;mV/C&nbsp;(-3.0mV/C&nbsp;per&nbsp;cell)
      </div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Charging&nbsp;temperature&nbsp;compensation&nbsp;in&nbsp;mV&nbsp;(-048..-120)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───</div>
      <div>74/-___</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>
          74/EE++++&nbsp;&nbsp;Save&nbsp;Battery&nbsp;charging&nbsp;temperature&nbsp;compensation&nbsp;in&nbsp;EEPROM
        </strong>
      </div>
      <div>74/EE++++</div>
      <div />
    </div>
    <h2>Factory settings</h2>
    <br />
    <div>90&nbsp;&nbsp;FACTORY&nbsp;UNLOCK</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90&nbsp;&nbsp;Display&nbsp;factory&nbsp;unlock&nbsp;selection</strong>
      </div>
      <div>90</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90/UNLOCK&nbsp;&nbsp;Select&nbsp;factory&nbsp;unlock</strong>
      </div>
      <div>90/UNLOCK</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>90-&nbsp;&nbsp;Deselect&nbsp;factory&nbsp;unlock</strong>
      </div>
      <div>90-</div>
      <div />
      <div />
    </div>
    <div>91&nbsp;&nbsp;PS&nbsp;RECTIFIER&nbsp;SELECTION</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91&nbsp;&nbsp;Display&nbsp;PS&nbsp;Rectifier&nbsp;selection</strong>
      </div>
      <div>91</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91+&nbsp;&nbsp;Select&nbsp;Rectifier&nbsp;included</strong>
      </div>
      <div>91+</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>91-&nbsp;&nbsp;Select&nbsp;No&nbsp;Rectifier&nbsp;included</strong>
      </div>
      <div>91-</div>
      <div />
      <div />
    </div>
    <div>95&nbsp;&nbsp;TYPE&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Item&nbsp;no.:&nbsp;cccccccccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;item&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;item&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;12&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall&nbsp;be
    </div>
    <div>
      setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;type&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>95&nbsp;&nbsp;Display&nbsp;Item&nbsp;number</strong>
      </div>
      <div>95</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>95/ccc..c&nbsp;&nbsp;Change&nbsp;Item&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Item&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────────</div>
      <div>95/____________</div>
      <div />
      <div />
    </div>
    <div>96&nbsp;&nbsp;TYPE&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Type&nbsp;no.:&nbsp;cccccccccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;type&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;type&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;12&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall&nbsp;be
    </div>
    <div>
      setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;type&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96&nbsp;&nbsp;Display&nbsp;Type&nbsp;number</strong>
      </div>
      <div>96</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>96/ccc..c&nbsp;&nbsp;Change&nbsp;Type&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Type&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────────</div>
      <div>96/____________</div>
      <div />
      <div />
    </div>
    <div>97&nbsp;&nbsp;SERIAL&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Serial&nbsp;no.:&nbsp;nnnnnnnn</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;serial&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;serial&nbsp;number&nbsp;shall&nbsp;consist&nbsp;of&nbsp;8&nbsp;digits&nbsp;and&nbsp;shall&nbsp;be&nbsp;setup&nbsp;to&nbsp;be&nbsp;equal
    </div>
    <div>to&nbsp;the&nbsp;serial&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.</div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97&nbsp;&nbsp;Display&nbsp;Serial&nbsp;number</strong>
      </div>
      <div>97</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>97/nnnnnnnn&nbsp;&nbsp;Change&nbsp;serial&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Serial&nbsp;number&nbsp;(00000000..99999999)</div>
      <div>&nbsp;&nbsp;&nbsp;┴───────</div>
      <div>97/________</div>
      <div />
      <div />
    </div>
    <div>98&nbsp;&nbsp;VERSION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Version&nbsp;no.:&nbsp;cccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;version&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;version&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;6&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall
    </div>
    <div>
      be&nbsp;setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;version&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98&nbsp;&nbsp;Display&nbsp;Version&nbsp;number</strong>
      </div>
      <div>98</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>98/cc..c&nbsp;&nbsp;Change&nbsp;Version&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Version&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>98/______</div>
      <div />
      <div />
    </div>
    <div>99&nbsp;&nbsp;REVISION&nbsp;NUMBER</div>
    <br />
    <div>Response:</div>
    <div>1)&nbsp;Revision&nbsp;no.:&nbsp;cccccc</div>
    <br />
    <div>Description:</div>
    <div>
      The&nbsp;commands&nbsp;are&nbsp;used&nbsp;to&nbsp;enter&nbsp;and&nbsp;display&nbsp;the&nbsp;revision&nbsp;number.
    </div>
    <br />
    <div>
      The&nbsp;revision&nbsp;number&nbsp;can&nbsp;consist&nbsp;of&nbsp;from&nbsp;1&nbsp;to&nbsp;6&nbsp;ASCII&nbsp;characters&nbsp;and&nbsp;shall
    </div>
    <div>
      be&nbsp;setup&nbsp;to&nbsp;be&nbsp;equal&nbsp;to&nbsp;the&nbsp;revision&nbsp;number&nbsp;on&nbsp;the&nbsp;type&nbsp;plate.
    </div>
    <br />
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99&nbsp;&nbsp;Display&nbsp;Revision&nbsp;number</strong>
      </div>
      <div>99</div>
      <div />
    </div>
    <div style={{ margin: 16, padding: 16, border: '1px black solid' }}>
      <div>
        <strong>99/cc..c&nbsp;&nbsp;Change&nbsp;Revision&nbsp;number</strong>
      </div>
      <div>Blocking&nbsp;and&nbsp;unlocking&nbsp;necessary</div>
      <div />
      <div>&nbsp;&nbsp;&nbsp;┌&nbsp;Revision&nbsp;number</div>
      <div>&nbsp;&nbsp;&nbsp;┴─────</div>
      <div>99/______</div>
      <div />
      <div />
    </div>
    <br />
  </>
);

export default memo(PS411Page) as typeof PS411Page;
