import React from 'react';
import styled from 'styled-components';
import { Input as AntInput, InputNumber as AntInputNumber } from 'antd';
import AntMaskedInput from 'antd-mask-input';

export const MaskedInput = styled(AntMaskedInput)`
  .ant-input,
  &.ant-input {
    // border: solid 1px #bce0fd;
    background-color: #ffffff;
    // color: #2699fb;
    font-family: ${props => props.theme['font-family']};
    font-size: 14px;
    padding: 0 17px;
    min-height: 42px;

    border-radius: 0.5rem;
    &:hover,
    &:focus {
      border-color: ${props => props.theme.token.colorPrimary};
    }

    input:-webkit-autofill,
    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        color: #2699fb !important;
        background: #ffffff !important;
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #555555 !important;
      }
    }

    ::placeholder {
      color: #bce0fd;
    }
  }
  .ant-input-group-addon {
    background-color: ${props => props.theme.token.colorPrimary};
    border-radius: 0.5rem 0 0 0.5rem;
    border-color: ${props => props.theme.token.colorPrimary};
    padding: 0;
    color: #fff;
    font-size: 1rem;
  }
`;

//  https://imask.js.org/guide.html#masked-pattern
export const DEFAULT_PHONE_NUMBER_PREFIX = '7';
export const PHONE_NUMBER_MASK = `+{${DEFAULT_PHONE_NUMBER_PREFIX}(000)000-00-00`;
export const PhoneNumberMaskedInput = props => <MaskedInput mask={PHONE_NUMBER_MASK} {...props} />;

export const Input = styled(AntInput)`
  .ant-input,
  &.ant-input {
    // border: solid 1px #bce0fd;
    background-color: #ffffff;
    // color: #2699fb;
    font-family: ${props => props.theme['font-family']};
    font-size: 14px;
    //padding: 0 17px;
    //min-height: 42px;

    border-radius: 0.5rem;
    &:hover,
    &:focus {
      border-color: ${props => props.theme.token.colorPrimary};
    }

    input:-webkit-autofill,
    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        color: #2699fb !important;
        background: #ffffff !important;
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #555555 !important;
      }
    }

    ::placeholder {
      color: #bce0fd;
    }

    & + .ant-input-group-addon {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }

  .ant-input-group-addon {
    background-color: ${props => props.theme.token.colorPrimary};
    border-color: ${props => props.theme.token.colorPrimary};
    padding: 0;
    color: #fff;
    font-size: 1rem;

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }
  }
`;

const Label = styled.div`
  padding: 0 1rem;
`;

export const InputPrefixLabel = ({ children, ...props }) => <Label {...props}>{children}</Label>;

export const TextArea = styled(AntInput.TextArea)`
  &.ant-input {
  }
`;

export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    // border: solid 1px #bce0fd;
    background-color: #ffffff;
    // color: #2699fb;
    font-family: ${props => props.theme['font-family']};
    font-size: 14px;
    padding: 9px 10px;
    min-height: 50px;

    border-radius: 0.5rem;
    &:hover,
    &:focus {
      border-color: ${props => props.theme.token.colorPrimary};
    }

    ::placeholder {
      color: #bce0fd;
    }
  }
`;

export const Search = styled(AntInput.Search)``;
export const PasswordInput = styled(AntInput.Password)`
  &.ant-input-password {
    // border: solid 1px #bce0fd;
    background-color: #ffffff;
    // color: #2699fb;
    font-family: ${props => props.theme['font-family']};
    font-size: 14px;
    padding: 0 17px;
    min-height: 42px;

    border-radius: 0.5rem;
    &:hover,
    &:focus {
      border-color: ${props => props.theme.token.colorPrimary};
    }

    input:-webkit-autofill,
    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        color: #2699fb !important;
        background: #ffffff !important;
        -webkit-box-shadow: 0 0 0 1000px white inset !important;
        -webkit-text-fill-color: #555555 !important;
      }
    }

    ::placeholder {
      color: #bce0fd;
    }
  }
  .ant-input-group-addon {
    background-color: ${props => props.theme.token.colorPrimary};
    border-radius: 0.5rem 0 0 0.5rem;
    border-color: ${props => props.theme.token.colorPrimary};
    padding: 0;
    color: #fff;
    font-size: 1rem;
  }
`;
