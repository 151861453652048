import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, Navigate, NotFound } from '@fjedi/react-router-helpers';
import UsersContainer from 'src/components/routes/private/admin/users';
import ContentCard from 'src/components/ui-kit/card/main-content-card';

const AdminPage = () => {
  const { t } = useTranslation();

  const tabList = useMemo(
    () => [
      {
        key: 'users',
        tab: t('Users'),
      },
    ],
    [t],
  );

  return (
    <ContentCard tabList={tabList} tabURLNavigation>
      <Routes>
        <Route path="users/:userId" element={<UsersContainer />} />
        <Route path="users" element={<UsersContainer />} />
        <Route path="/" element={<Navigate replace to="users" />} />
        <Route element={<NotFound />} />
      </Routes>
    </ContentCard>
  );
};

AdminPage.propTypes = {};
AdminPage.defaultProps = {};

export default memo(AdminPage);
